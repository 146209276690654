define('frontend/models/business-event', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    eventName: _emberData.default.attr("string"),
    eventDate: _emberData.default.attr("date"),
    patient: _emberData.default.belongsTo('patient'),
    provider: _emberData.default.belongsTo('provider'),
    user: _emberData.default.belongsTo('user'),
    eventData: _emberData.default.attr(),
    institute: _emberData.default.belongsTo('institute'),
    patientSession: _emberData.default.belongsTo('patientSession'),
    relatedPerson: _emberData.default.belongsTo('relatedPerson'),
    assessmentReport: _emberData.default.belongsTo('assessment/report'),
    assessmentInvitation: _emberData.default.belongsTo('assessment/invitation'),
    isTest: _emberData.default.attr("boolean")
  });
});