define('frontend/models/assessment/user-settings', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    reportMode: _emberData.default.attr('string', { defaultValue: 'full' }),
    currentNorm: _emberData.default.attr('string', { defaultValue: 'outpatient' })
  });
});