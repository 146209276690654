define('frontend/pods/related-person/assessment/invitation/exit/route', ['exports', 'frontend/mixins/related-person-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _relatedPersonRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_relatedPersonRouteMixin.default, _metricsMixin.default, {
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    model: function model() {
      return this.modelFor("related-person.assessment.invitation");
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Trigger the update to in progress
      model.set("statusEvent", 'assessment_opened');
      var assessmentUrl = model.get("assessmentUrl");

      var doRedirect = function doRedirect() {
        _this.get("metrics").trackEvent({ event: "relatedPerson.assessment.invitation.goToAssessment" });

        if (!assessmentUrl) {
          // In certain unit tests, this is only available here, rather than earlier. Not sure why, but this fixes it for now
          assessmentUrl = model.get("assessmentUrl");
        }

        var host = document.location.origin;
        var reflink = '' + host + _this.get("router").generate("related-person.assessment.invitation.return", model);

        // Explicitly generate urls for our local survey capability which lets us transition quicker.
        if (assessmentUrl && assessmentUrl.indexOf('/') >= 0) {
          var remoteUrl = assessmentUrl + '&reflink=' + reflink;
          window.location.replace(remoteUrl);
        } else {
          //this is a local url -- it just has params
          // TODO change to having a related person route
          var localUrl = _this.get("router").generate("survey.taking.patient.begin") + '?' + assessmentUrl + '&reflink=' + reflink;
          //someday we will want to replace this with an ember transition -- we don't now both because we don't want to detail every query param in the route controller and I couldn't figure out how to pass url params otherwise
          // window.location.replace(localUrl);
          _this.get("router").transitionTo(localUrl);
        }
      };

      var handleError = function handleError() {
        _this.get("metrics").trackEvent({ event: "related-person.assessment.invitation.assessmentError" });
        _this.get("router").transitionTo("related-person.assessment.invitation.error");
      };

      switch (model.get('availability')) {
        case 'tooEarly':
          return this.get("router").transitionTo("related-person.assessment.invitation.early");
        case 'tooLate':
          return this.get("router").transitionTo("related-person.assessment.invitation.expired");
      }

      model.set('referrer', sessionStorage.getItem('referrer'));
      return model.save().then(doRedirect, handleError);
    }
  });
});