define('frontend/pods/components/common/help-tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    help: Ember.inject.service(),
    mode: 'info',

    show: Ember.computed('help.stateUpdated', 'tipName', function () {
      var help = this.get("help");

      return help.showHelpTooltip(this.get('tipName'));
    }),

    showDismiss: false,

    actions: {
      dismissTip: function dismissTip() {
        this.get("help").dismissTip(this.get('tipName'));
        this.set('showDismiss', true);
      },
      hideTip: function hideTip() {
        var help = this.get("help");
        this.setProperties({ show: false, showDismiss: help.showHelpTooltip(this.get('tipName')) });
      }
    }
  });
});