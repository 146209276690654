define('frontend/pods/components/related-person/assessment/invitation-login/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })]
    }
  });

  /**
   * expects: invitationId
   */
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var relatedPersonDetails = Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        email: null
      }).create(Ember.getOwner(this).ownerInjection());

      this.set("relatedPersonDetails", relatedPersonDetails);
    },

    isInvalid: Ember.computed.alias("relatedPersonDetails.validations.isInvalid"),
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');
        this.set('session.silentInvalidate', true);
        this.set('session.noTransitionOnAuthenticate', true);

        var _get$getProperties = this.get("relatedPersonDetails").getProperties("email"),
            email = _get$getProperties.email,
            invitationId = _get$getProperties.invitationId;

        var trackLoginEvent = function trackLoginEvent(event) {
          return _this.get('metrics').trackEvent({
            event: 'relatedPerson.invitation.' + event,
            invitationId: invitationId,
            email: email
          });
        };

        trackLoginEvent('signInAttempt');

        var onSuccess = function onSuccess() {
          trackLoginEvent('signInSucceeded');
          var attemptedTransition = _this.get('session.attemptedTransition');

          if (attemptedTransition) {
            attemptedTransition.retry();
            _this.set('session.attemptedTransition', null);
          } else {
            _this.get("router").transitionTo("related-person.assessment.invitation.begin", invitationId);
          }
        };

        var onFailure = function onFailure() {
          _this.get('messages').danger(intl.t('patient.kiosk.failed'));

          trackLoginEvent('signInFailed');
        };

        var params = {
          institute_id: this.get("institute.current.id"),
          invitation_id: this.get('invitationId'),
          email: email
        };

        return this.get('session').authenticate('authenticator:devise-related-person', params).then(onSuccess, onFailure);
      }
    }
  });
});