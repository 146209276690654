define('frontend/pods/components/provider-admin/payors/edit-contract/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      onSave: function onSave() {
        var _this = this;

        var intl = this.get('intl');
        var success = function success() {
          _this.get('messages').success(intl.t('providerAdmin.payors.contractSaved'));
          _this.get('metrics').trackEvent({ event: 'provider.payors.payorContractUpdated' });
        };

        var failure = function failure(reason) {
          _this.get('messages').danger(_this.printError(reason));
        };

        return this.get("model").save().then(success).catch(failure);
      }
    }
  });
});