define("frontend/pods/components/provider/assessment/report-v2/full-answer-detail/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    showAllAnswers: true,
    isCopying: false,
    isShowingFormatDialog: false,

    showFullQuestionText: false,
    showScaleDescriptions: false,

    isHtmlView: Ember.computed.alias("reportSettings.note.isHtmlView"),
    isShown: Ember.computed.alias("reportSettings.note.isShown"),

    textMode: Ember.computed.oneWay("institute.configuration.reportDefaultTextMode"),

    //return norse and standard measures in a single collection
    scaleValues: Ember.computed("report", "reportSettings.currentPopulation", function () {
      var report = this.get("report");
      if (!report) {
        return [];
      }

      //we have to get the answers through the normed computed values, and we only want one per mnemonic -- the norm is irrelevant
      var cvs = report.getWithDefault("norseValidValues", []).filterBy("population.mnemonic", this.get("reportSettings.currentPopulation.mnemonic"));

      cvs.pushObjects(report.getWithDefault("peabodyValidValues", []).filter(function (cv) {
        //filter out the A & B, as well as the patient-scaled values
        return ["sfssA", "sfssB", "sfssA_C", "sfssB_C"].indexOf(cv.get("scale.mnemonic")) < 0 && cv.get("domain") === "raw";
      }));

      // We need to actually dedupe these as we go: we might end up with a situation from scorers and populations where
      // we actually have three or more computed values, IE one raw and two normed. We only want the raw values displayed.

      cvs.pushObjects(report.getWithDefault("standardValidValues", []).filter(function (standardValidValues) {
        return ["raw", "interpreted"].includes(standardValidValues.get("domain"));
      }));

      var independentCustomCVs = report.getWithDefault("customValidValues", []).filterBy("scale.independent", true);
      cvs.pushObjects(independentCustomCVs);

      var scalesToReject = this.get("scalesToReject");

      return cvs.reject(function (cv) {
        return scalesToReject.indexOf(cv.get("scale.baseMnemonic")) >= 0;
      });
    }),

    // This is currently a quick fix to allow Mclean to hide alliance scales during research.
    // this logic is duplicated in longitudinal-main
    scalesToReject: Ember.computed("institute.configuration.mcleanLegacyMode", "sessionUser.activeProvider.defaultRelationship", function () {
      var hideAllianceScales = this.get("institute.configuration.mcleanLegacyMode");
      var role = this.get("sessionUser.activeProvider.defaultRelationship");

      if (hideAllianceScales && ["psychiatrist", "psychologist", "therapist", "clinician"].indexOf(role) >= 0) {
        return ["waisrt", "waisr", "waisrGoal", "waisrTask", "waisrBond", "waisr_C", "waisrGoal_C", "waisrTask_C", "waisrBond_C", "react", "emapSessionEvaluation", "emapClientSatisfaction"];
      } else {
        return [];
      }
    }),

    //returns scales transformed by the appropriate catalog
    transformedScales: Ember.computed("scaleValues", function () {
      //for now, return CAMS default
      return this.get("scaleValues");
    }),

    answerMap: Ember.computed("scaleValues", function () {
      var scaleValues = this.get("scaleValues");
      var answerMap = {};
      scaleValues.forEach(function (s) {
        Ember.assign(answerMap, s.get("answerMap"));
      });
      return answerMap;
    }),

    didRender: function didRender() {
      if (this.get("isCopying")) {
        this.copyText();
        this.set("isCopying", false);
      }
    },

    copyText: function copyText() {
      // CHC's eclinical works notes cannot accept endashes or emdashes, replace with hyphen
      this.replaceText($(".synopsis .panel-body")[0], /–|—/, "-");
      //Before we copy, we are going to select the text.
      var textEl = $(".synopsis .panel-body")[0];
      var selection = window.getSelection();
      var range = document.createRange();
      range.selectNodeContents(textEl);
      selection.removeAllRanges();
      selection.addRange(range);
      //add to clipboard.
      document.execCommand("copy");
    },

    replaceText: function replaceText(el, pattern, replacement) {
      var _this = this;

      if (el.nodeType === HTMLElement.TEXT_NODE) {
        el.textContent = el.textContent.replace(pattern, replacement);
      } else {
        el.childNodes.forEach(function (kid) {
          return _this.replaceText(kid, pattern, replacement);
        });
      }
    },

    actions: {
      show: function show() {
        this.set("isShown", true);
      },

      showText: function showText(showHtml) {
        //make sure the panel is open
        this.set("isShown", true);
        this.set("isHtmlView", showHtml);
      },

      copy: function copy() {
        if (this.get("isShown") && !this.get("isHtmlView")) {
          this.copyText();
        } else {
          //we have to rerender something first, so wait to copy until after the render.
          this.set("isShown", true);
          this.set("isHtmlView", false);
          this.set("isCopying", true);
        }
      },

      scalesGroupBySeverity: function scalesGroupBySeverity() {
        this.set("textMode", "severity");
      },
      scalesAlphabetically: function scalesAlphabetically() {
        this.set("textMode", "alphabetic");
      }
    }
  });
});