define('frontend/pods/related-person/assessment/invitation/return/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    session: Ember.inject.service(),
    model: function model(params, transition) {
      var status = transition.params['related-person.assessment.invitation.return.status'].status;
      return {
        status: status
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var invitation = this.modelFor("related-person.assessment.invitation");

      var referrer = sessionStorage.getItem("referrer");
      sessionStorage.removeItem("referrer");

      var closeOnLogout = referrer === 'email';

      this.trackMetricNow({ event: "relatedPerson.assessment.invitation." + model.status, invitationId: invitation.get("id") });

      if (invitation && model.status === 'complete') {
        invitation.statusEvent = 'assessment_complete';
        invitation.save().then(function () {
          _this.get("router").transitionTo('related-person.logout', { queryParams: { closeOnLogout: closeOnLogout, reason: model.status } });
        });
      } else {
        this.get("router").transitionTo('related-person.logout', { queryParams: { closeOnLogout: closeOnLogout, reason: model.status } });
      }
    }
  });
});