define("frontend/mixins/with-custom-actions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),

    callCollectionAction: function callCollectionAction(type, action, params) {
      var _this = this;

      var data = { data: { attributes: params } };

      return this.get("store").createRecord(type)[action](data).then(function (result) {
        if (!result.data) {
          return null;
        }

        _this.get("store").pushPayload(result);

        if (Ember.isArray(result.data)) {
          return result.data.map(_this._fetchFromStore.bind(_this));
        } else {
          return _this._fetchFromStore(result.data);
        }
      });
    },
    _fetchFromStore: function _fetchFromStore(object) {
      if (object.type) {
        return this.get('store').peekRecord(object.type, object.id);
      } else {
        return null;
      }
    }
  });
});