define('frontend/pods/components/provider/assessment/report-v2/custom-measures/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var componentMapping = {
    kristiHouseBrokerMetrics: 'provider/assessment/report-v2/kristi-house-broker-metrics'
  };

  exports.default = Ember.Component.extend({
    classNames: ["standard-measures"],
    measureType: 'custom',
    titleKey: 'provider.assessments.report.customMeasures.title',

    customScalesToShow: Ember.computed('measureType', 'scales', function () {
      var measureType = this.get("measureType");
      return this.getWithDefault('scales', []).filter(function (s) {
        return s.get('measureType') === measureType;
      }).sortBy('mnemonic').map(function (scale) {
        return {
          scale: scale,
          component: componentMapping[scale.get('displayData.customDisplayType')]
        };
      });
    })
  });
});