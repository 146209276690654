define('frontend/validators/presence-or', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank,
      isEqual = Ember.isEqual,
      isPresent = Ember.isPresent,
      typeOf = Ember.typeOf;
  exports.default = _base.default.extend({
    keyPresent: function keyPresent(model, key) {
      var value = key ? model.get(key) : model;
      if (isBlank(value)) {
        return false;
      }
      // If an object is present, it may be an empty `PromiseObject` or `PromiseArray` from an Ember-Data relationship
      var constructor = value.constructor;
      if (isEqual(typeOf(value.get), 'function') && isPresent(constructor) && isEqual(typeOf(constructor.toString), 'function')) {
        var type = constructor.toString();
        if (['DS.PromiseObject', 'DS.PromiseArray'].indexOf(type) !== -1) {
          return isPresent(value.get('content'));
        }
      }
      return true;
    },
    _keysPresent: function _keysPresent(value, keys, model) {
      var presentCount = 0;
      for (var i = 0; i < keys.length; i++) {
        // for-of loops fail in phantomJS, falling back to regular for-loop
        if (this.keyPresent(model, keys[i])) {
          presentCount++;
        }
      }
      if (this.keyPresent(value)) {
        presentCount++;
      }
      return presentCount;
    },
    keysPresent: function keysPresent(value, options, model) {
      var keysPresent = this._keysPresent(value, options.dependentKeys || [], model);
      return !(!keysPresent || options.exclusive && keysPresent > 1);
    },
    defaultMessage: function defaultMessage(type, value, options) {
      var attributeList = this.get('attribute');
      var keys = options.dependentKeys;
      if (Ember.isPresent(keys)) {
        attributeList = attributeList + ', ' + keys.join(', ');
      }
      var prefix = void 0;
      if (!options.exclusive) {
        if (Ember.isEqual(type, 'present')) {
          prefix = 'At least one';
        } else {
          prefix = 'All';
        }
      } else {
        if (Ember.isEqual(type, 'present')) {
          prefix = 'Exactly one';
        } else {
          prefix = 'More than one or none';
          type = 'present';
        }
      }
      return prefix + ' of these attributes must be ' + type + ': ' + attributeList;
    },
    validate: function validate(value, options, model) {
      var present = this.keysPresent(value, options, model);
      options.message = options.message || this.defaultMessage;

      if ((options.presence === true || options.presence === undefined) && !present) {
        return this.createErrorMessage('present', value, options);
      }

      if (options.presence === false && present) {
        return this.createErrorMessage('blank', value, options);
      }

      return true;
    }
  });
});