define("frontend/mixins/dirty-checker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _markAsDirty: function _markAsDirty() {
      this.set("unsavedChanges", true);
      if (this.get("dirtyChecker")) {
        this.set("dirtyChecker.preventTransition", true);
      }
    },
    _discardChanges: function _discardChanges() {
      this._rollbackAllProperties();
      this.set("unsavedChanges", false);
      if (this.get("dirtyChecker")) {
        this.set("dirtyChecker.preventTransition", false);
      }
    },
    _dirtyCheckerSaveSuccess: function _dirtyCheckerSaveSuccess() {
      this.set("unsavedChanges", false);
      if (this.get("dirtyChecker")) {
        this.set("dirtyChecker.preventTransition", false);
      }
    }
  });
});