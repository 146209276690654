define('frontend/pods/provider/billing/charges/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    preload: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get("preload").ensurePreloads();
    }
  });
});