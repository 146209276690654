define('frontend/helpers/format-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function formatNumber(number, optionOverrides, locale) {
    var options = Ember.merge({
      style: 'decimal',
      maximumFractionDigits: 1
    }, optionOverrides);

    var formatter = new Intl.NumberFormat(locale, options);

    var formatted = formatter.format(number);

    // If the value provided cannot be formatted we want to display something a bit nicer than "NaN"
    if (formatted === 'NaN') {
      return '\u2014'; // &mdash;
    } else {
      return formatted;
    }
  }

  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    // To pass a whole object in, we need to pass it in as a subobject then merge.
    compute: function compute(_ref, optionOverrides) {
      var _ref2 = _slicedToArray(_ref, 1),
          number = _ref2[0];

      if (optionOverrides && optionOverrides.format) {
        optionOverrides = Ember.merge(optionOverrides.format, optionOverrides);
      }
      return formatNumber(number, optionOverrides, this.get('intl.locale'));
    }
  });
});