define('frontend/pods/components/reporting/date-selector/date-picker/component', ['exports', 'ember-date-components/components/date-picker-inline', 'ember-date-components/templates/components/date-picker-inline'], function (exports, _datePickerInline, _datePickerInline2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _datePickerInline.default.extend({
    layout: _datePickerInline2.default,

    _setDateRange: function _setDateRange(date) {
      var _this = this;

      var isToStep = get(this, 'isToStep');

      if (!isToStep) {
        // Clear the end date so we don't get funky behavior with month swapping
        Ember.changeProperties(function () {
          _this._setToDate(null);
          _this._setFromDate(date);
        });

        this._moveToToStep();
      } else {
        this._setToDate(date);
        this._close();
      }
    },


    /* Disable "closing" when the user clicks outside of the date element. We handle this in the
     * tabular-params component */
    _setupOutsideListener: function _setupOutsideListener() {},
    _destroyOutsideListener: function _destroyOutsideListener() {}
  });
});