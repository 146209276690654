define('frontend/pods/components/provider-admin/payors/contract-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "name",
        title: intl.t('common.name')
      }, {
        template: "components/provider-admin/payors/contract-table/action-list",
        title: intl.t('common.actions'),
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
    }
  });
});