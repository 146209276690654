define('frontend/pods/components/common/graphs/scale-lines/component', ['exports', 'd3', 'moment', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3', 'lodash'], function (exports, _d, _moment, _d3Support, _marginConvention, _d2, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {

    defaultOpts: {
      showYAxis: true,
      maxValue: 6,
      minValue: -4,
      leftAxisMargin: 60,
      leftAxisIntegerMargin: 100,
      rightAxisMargin: 26,
      textMargin: 30,
      legendMargin: 20,
      dashArraySamples: [[4, 4], [8, 6], [18, 4], [12, 4, 4, 4]]
    },
    opts: Ember.computed('graphOptions', function () {
      return $.extend({}, this.get('defaultOpts'), this.get('graphOptions'));
    }),

    classNames: ['symptom-line'],
    defaultMargin: 20,

    intl: Ember.inject.service(),
    requiredProperties: ['model'],

    currentSymptom: null,
    //showDetail: false,

    model: null, //array of data with score and scoreClass properties
    events: null, //array of events

    graphData: Ember.computed('model', function () {
      return this.transformData(this.get('model'));
    }),

    userColorScale: Ember.computed('model', function () {
      return _d.default.scale.category10().domain(_d.default.range([1, this.get('model').length]));
    }),

    leftAxisMargin: Ember.computed('isPercentileGraph', function () {
      return this.get('isPercentileGraph') ? this.get('opts').leftAxisMargin : this.get('opts').leftAxisIntegerMargin;
    }),

    bottomAxisMargin: Ember.computed('isCompressed', function () {
      return this.get('isCompressed') ? 0 : this.get('opts').textMargin;
    }),

    isPercentileGraph: Ember.computed('graphData', function () {
      if (this.get("opts.isPercentileGraph")) {
        return true;
      }

      return false;
    }),

    yMin: Ember.computed('graphData', 'graphDomains', 'currentPopulation.mnemonic', function () {
      var graphData = this.get('graphData');
      var graphDomains = this.get("graphDomains");
      var populationMnemonic = this.get('currentPopulation.mnemonic');
      return this.get('isPercentileGraph') || !graphData[0] ? this.get('opts').maxValue : graphData[0].datum.get('maxValue').call(graphData[0].datum, graphDomains, populationMnemonic);
    }),

    yMax: Ember.computed('graphData', 'graphDomains', 'currentPopulation.mnemonic', function () {
      var graphData = this.get('graphData');
      var graphDomains = this.get("graphDomains");
      var populationMnemonic = this.get('currentPopulation.mnemonic');
      return this.get('isPercentileGraph') || !graphData[0] ? this.get('opts').minValue : graphData[0].datum.get('minValue').call(graphData[0].datum, graphDomains, populationMnemonic);
    }),

    xScale: Ember.computed('contentWidth', 'graphData', function () {
      var width = this.get('contentWidth');
      var graphData = this.get('graphData');
      var x = _d.default.time.scale().range([this.get('leftAxisMargin'), width - this.get('opts').rightAxisMargin]);
      if (this.get('dateRange')) {
        x.domain([this.get('dateRange').minDate, this.get('dateRange').maxDate]);
      } else if (graphData[0]) {
        x.domain(_d.default.extent(graphData.mapBy("items").flatten().mapBy("x")));
      }

      return x;
    }).readOnly(),

    yScale: Ember.computed('contentHeight', 'yMin', 'yMax', function () {
      var height = this.get('contentHeight');
      var yHeight = height - this.get('bottomAxisMargin');
      var y = _d.default.scale.linear().range([0, yHeight]);
      y.domain([this.get('yMin'), this.get('yMax')]).clamp(true);
      return y;
    }).readOnly(),

    // gets a unique id for the gradient based on its parent container, which will have an ember
    // unique component id
    gradientId: Ember.computed(function () {
      return 'grad-' + $(this.svg.get('element')).parent()[0].id;
    }),

    axes: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var axis = selection.append("g").classed("axis", true);

        axis.append("g").attr("class", "baraxis y");

        axis.append("g").attr("class", "baraxis x");
      },
      update: function update(selection) {
        var intl = this.get('intl');
        var opts = this.get('opts');
        var component = this;
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var yMin = this.get('yMin');
        var yMax = this.get('yMax');
        var height = this.get('contentHeight');
        var width = this.get('contentWidth');
        var data = this.get('graphData');
        var isPercentileGraph = this.get('isPercentileGraph');
        var isCompressed = this.get('isCompressed');
        var bottomAxisMargin = this.get('bottomAxisMargin');

        var graphDomains = this.get("graphDomains");
        var populationMnemonic = this.get('currentPopulation.mnemonic');

        var thresholdData = data[0] ? data[0].datum.get('filteredThresholds').call(data[0].datum, graphDomains, populationMnemonic) : [];

        this.axisSelection = selection;

        var yAxis = _d.default.svg.axis().scale(yScale).orient('left');

        if (isPercentileGraph) {
          yAxis.ticks(yMin - yMax);
        } else {
          var tickValues = void 0;
          // Sometimes we get threshold data which we want to use the 'middle' of so we can get the nice text. But only
          // if it has a mnemonic, otherwise, use the tick values which are the actual numbers.
          if (thresholdData.length > 0 && thresholdData[0].mnemonic) {
            tickValues = _lodash.default.map(thresholdData, function (datum) {
              return (datum.max_value + datum.min_value) / 2;
            });
          } else {
            tickValues = yScale.domain();
          }

          yAxis.tickValues(tickValues);
        }

        selection.selectAll('g.baraxis.y').attr("transform", "translate(" + component.get('leftAxisMargin') + ",0)").call(yAxis).selectAll("text").attr("dx", "-.2em").attr("class", "barlabel").text(function (d) {
          if (!isPercentileGraph) {
            if (thresholdData.length > 0) {
              var datum = _lodash.default.find(thresholdData, function (datum) {
                return datum.min_value <= d && datum.max_value >= d;
              });
              // TODO: we only this component for old-school hard coded scales
              if (!datum) {
                // We shouldn't really get here, but sometimes a scale is miscoded, so in that case just return the value 
                return d;
              }
              var _intlKey = 'provider.scales.interpretation.' + datum.mnemonic + '.graphLabel';
              if (intl.exists(_intlKey)) {
                return intl.t(_intlKey);
              } else if (datum.graph_label) {
                return datum.graph_label;
              } else if (datum.mnemonic) {
                return datum.mnemonic;
              } else {
                // In the worst case, just use the actual number provided.
                return d;
              }
            } else {
              return d;
            }
          }

          var intlKey = 'score.percentileLabel.' + d;
          return d !== opts.minValue && d !== opts.maxValue && intl.exists(intlKey) ? intl.t(intlKey) : '';
        });

        if (!data[0]) {
          return;
        }

        if (isPercentileGraph) {
          //0 line
          selection.selectAll("line.zeroLine").data([1]).enter().append("line").attr("class", "zeroLine").attr("x1", component.get('leftAxisMargin')).attr("y1", yScale(0)).attr("x2", width - opts.rightAxisMargin).attr("y2", yScale(0));
        } else {
          selection.selectAll("line.zeroLine").data([]).exit().remove();
        }

        var latestValue = data[0] ? data[0].items[data[0].items.length - 1].y : undefined;

        //thresholds
        var thresholdBars = selection.selectAll("rect.thresholdBar").data(thresholdData);

        thresholdBars.enter().append("rect");

        thresholdBars.attr('class', function (d) {
          var isHighlighted = latestValue >= d.min_value && latestValue <= d.max_value;
          return (d.class || 'unknown') + ' thresholdBar' + (isHighlighted ? ' highlight' : '');
        });
        thresholdBars.attr('fill', function (d) {
          if (d.class === 'continuous') {
            return 'url(#' + component.get('gradientId') + ')';
          }
        });

        thresholdBars.attr('x', function () {
          return component.get('leftAxisMargin');
        }).attr('y', function (d) {
          return yScale(d.max_value);
        }).attr('width', function () {
          return width - opts.rightAxisMargin - component.get('leftAxisMargin');
        }).attr('height', function (d) {
          return yScale(d.min_value) - yScale(d.max_value);
        });

        thresholdBars.exit().remove();

        //x axis
        var xAxis = _d.default.svg.axis().scale(xScale).tickValues(data[0] && !isCompressed ? [data[0].items[0].x, data[0].items[data[0].items.length - 1].x] : []) //.map(function(i) {return i.x;}) : [])
        .orient("bottom");

        selection.selectAll('g.baraxis.x').attr("transform", "translate(0," + (height - bottomAxisMargin) + ")").call(xAxis).selectAll("text")
        //.style("text-anchor", "end")
        .attr("class", "barlabel")
        //.attr("dx", "-.8em")
        //.attr("dy", "-0.15em")
        //.attr("transform", "rotate(-65)")
        .text(function (d) {
          return (0, _moment.default)(d).format("MMM D");
        });
      }
    }),

    lines: (0, _d2.join)([0], 'g.lineGraph', {
      enter: function enter(selection) {
        selection.append("g").classed("lineGraph", true);
      },
      update: function update(selection) {
        // const intl = this.get('intl');
        //draw the bars and attach event handlers
        var component = this;
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        //let width = this.get('contentWidth');
        var data = this.get('graphData');
        var isSingleTimeGraph = data && data[0] && data[0].items.length < 2;
        var dotRadius = isSingleTimeGraph ? 8 : 5;
        var dotOffset = isSingleTimeGraph ? 10 : 0;

        var valueLine = _d.default.svg.line().x(function (d) {
          return xScale(d.x);
        }).y(function (d) {
          return yScale(d.y);
        });

        var symptomLines = selection.selectAll(".symptomLine").data(data, function (d) {
          return d.color;
        });

        var validDataFilter = function validDataFilter(d) {
          return d.y != null && d.valid;
        };

        symptomLines.enter().append("g").attr('class', 'symptomLine show');
        //.attr('stroke-dasharray', function(d, i) {return [i+1,i+1];})
        symptomLines.attr('fill', function (d) {
          return d.items[0].color;
        }).attr('stroke', function (d) {
          return d.color;
        });

        symptomLines.exit().remove();

        var paths = symptomLines.selectAll("path.line").data(function (d) {
          return [d];
        });

        paths.enter().append("path").attr('class', 'line');

        //paths.attr('d', function(d) {return valueLine(d.items);});
        paths.transition().duration(250).attr("d", function (d) {
          return valueLine(d.items.filter(validDataFilter));
        });

        paths.exit().remove();

        //draw the container for the dots and arrows, clickable for detail
        // invalid values are left out and handled separately
        var dataPoints = symptomLines.selectAll(".dot").data(function (d) {
          return d.items.filter(validDataFilter);
        });

        dataPoints.enter().append("g").classed("dot", true).attr("transform", function (d) {
          return "translate(" + xScale(d.x) + "," + yScale(d.y) + ")";
        });

        dataPoints.transition().duration(250).attr("transform", function (d) {
          return "translate(" + xScale(d.x) + "," + yScale(d.y) + ")";
        });

        dataPoints.exit().remove();

        var circles = dataPoints.selectAll("circle").data(function (d) {
          return [d];
        });

        circles.enter().append("circle");

        circles.attr("cy", 0).attr("r", dotRadius).attr("cx", dotOffset).on('click', function (item) {
          component.actions.setCurrentValue.call(component, item, this, circles);
        });

        var circleHoverFunction = this.get("circleHoverFunction");

        if (circleHoverFunction) {
          var div = _d.default.select("body").selectAll('div.tooltip').data([true]);

          div.enter().append("div").attr("class", "tooltip icon-tip").style("opacity", 0);

          circles.on('mouseover', function (d) {
            div.transition().duration(50).style("opacity", .9);
            div.html(circleHoverFunction(d)).style("left", _d.default.event.pageX - 100 + "px").style("top", _d.default.event.pageY - 60 + "px").style("z-index", 'auto');
          }).on("mouseout", function () {
            div.transition().duration(50).style("opacity", 0).style("z-index", '-1000');
          });
        }

        var invalidDataPoints = symptomLines.selectAll(".invalid").data(function (d) {
          return d.items.reject(validDataFilter);
        });

        invalidDataPoints.enter().append("g").classed("invalid", true).attr("transform", function (d) {
          return "translate(" + xScale(d.x) + "," + 0 + ")";
        }).on('click', function (item) {
          component.actions.setCurrentValue.call(component, item, this, circles);
        });

        invalidDataPoints.exit().remove();

        invalidDataPoints.selectAll("text").data(function (d) {
          return [d];
        });

        var invalidCrosses = invalidDataPoints.selectAll("text.invalidCross").data(function (d) {
          return [d];
        });

        invalidCrosses.enter().append("text").attr('x', 0).attr('y', 0).attr('font-family', 'FontAwesome').text(function () {
          return '\uF059';
        }).append('title').text("Invalid Result");

        invalidCrosses.exit().remove();
      }
    }),

    eventIcons: (0, _d2.join)([0], 'g.events', {
      enter: function enter(selection) {
        selection.append("g").classed("events", true);
      },
      update: function update(selection) {
        if (this.get('isCompressed')) {
          return;
        }
        var opts = this.get('opts');
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var events = this.get('events') || [];
        //define the div where we render all tooltips

        var div = _d.default.select("body").selectAll('div.tooltip').data([true]);

        div.enter().append("div").attr("class", "tooltip icon-tip").style("opacity", 0);

        var formatTime = _d.default.time.format("%b. %e");
        var eventGroups = selection.selectAll('.event').data(events);

        // Start the events at the right place
        eventGroups.enter().append("g").attr('class', 'event').attr('transform', function (d) {
          return "translate(" + (xScale(d.date) - 10) + "," + (yScale(opts.minValue) - 5) + ")";
        });
        //show icons along the bottom axis
        eventGroups.transition().duration(250).attr('transform', function (d) {
          return "translate(" + (xScale(d.date) - 10) + "," + (yScale(opts.minValue) - 5) + ")";
        });
        eventGroups.exit().remove();

        var eventIcons = eventGroups.selectAll(".eventIcon").data(function (d) {
          return d.events.map(function (e) {
            return {
              event: e,
              date: d.date
            };
          });
        }).enter().append("g").attr('class', 'eventIcon').attr("transform", function (d, i) {
          return "translate(0,-" + i * 20 + ")";
        });
        eventIcons.append("text").attr('x', 0).attr('y', 0).attr('font-family', 'FontAwesome').attr('title', function (d) {
          return d.event.name;
        }).text(function (d) {
          return d.event.code;
        }).on('mouseover', function (d) {
          div.transition().duration(50).style("opacity", .9);
          div.html(formatTime(d.date) + "<br/>" + d.event.name).style("left", _d.default.event.pageX - 100 + "px").style("top", _d.default.event.pageY - 60 + "px").style("z-index", 'auto');
        }).on("mouseout", function () {
          div.transition().duration(50).style("opacity", 0).style("z-index", '-1000');
        });
      }
    }),

    etrCurves: (0, _d2.join)([0], 'g.etr', {
      enter: function enter(selection) {
        selection.append("g").classed("etr", true);
      },
      update: function update(selection) {
        if (this.get('isCompressed')) {
          return;
        }
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var curves = this.get('transformedEtrCurveData');
        var valueLine = _d.default.svg.line().x(function (d) {
          return xScale(d.x);
        }).y(function (d) {
          return yScale(d.y);
        });

        var curvePaths = selection.selectAll('path.curve-path').data(curves ? [curves] : []);

        curvePaths.enter().append("path").classed('curve-path', true);

        curvePaths.attr('d', function (d) {
          return valueLine(d.pathData);
        });
        curvePaths.transition().duration(250).attr("d", function (d) {
          return valueLine(d.pathData);
        });

        curvePaths.exit().remove();

        var curveMean = selection.selectAll('path.curve-line').data(curves ? [curves] : []);

        curveMean.enter().append("path").classed('curve-line', true);

        //paths.attr('d', function(d) {return valueLine(d.items);});
        curveMean.transition().duration(250).attr("d", function (d) {
          return valueLine(d.lineData);
        });

        curveMean.exit().remove();
      }
    }),

    // We need to transform into one line for the mean, and then a path for the limits
    transformedEtrCurveData: Ember.computed('etrCurve.sessionData.[]', function () {
      var curve = this.get('etrCurve.sessionData');

      if (!curve) {
        return null;
      }

      var lineData = curve.map(function (item) {
        return {
          x: item.date,
          y: item.mean
        };
      });

      // Move through the top then back through the bottom to get the area
      var topPathData = curve.map(function (item) {
        return {
          x: item.date,
          y: item.high
        };
      });

      var bottomPathData = curve.reverse().map(function (item) {
        return {
          x: item.date,
          y: item.low
        };
      });

      return {
        lineData: lineData,
        pathData: topPathData.addObjects(bottomPathData)
      };
    }),

    //transform an [time][symptom] array  into a  [symptom][time] array
    transformData: function transformData(data) {
      var _this = this;

      var colorSettings = this.get("colorSettings");

      var transformed = data.map(function (scaleData, i) {
        var scale = scaleData[0] ? scaleData[0].get("scale") : null;
        var user = scaleData[0].get("user");

        var color;

        if (colorSettings) {
          color = colorSettings.colorFor(scale, user);
        } else {
          if (_this.get('showUserLegend')) {
            color = _this.get('reportSettings').getColorForUser(scaleData[0].get("user"));
          } else {
            color = _this.get('reportSettings').getColorForScale(scale, i);
          }
        }

        return {
          datum: scale,
          index: i,
          lineStyle: scale ? scale.get("mnemonic").charCodeAt(0) : 0,
          color: color,
          items: scaleData.map(function (cv) {
            return {
              datum: cv,
              color: color,
              x: cv.get("report.patientSession.targetDate"),
              y: cv.get("value"),
              valid: cv.get("resultValid")
            };
          })
        };
      });

      return transformed;
    },

    //gets the low value for the bar
    getBarMinYCoord: function getBarMinYCoord(d, yscale) {
      var barMin = _d.default.min(d, function (d) {
        return d.score;
      });
      return yscale(barMin);
    },

    //gets the high value for the bar
    getBarMaxYCoord: function getBarMaxYCoord(d, yscale) {
      var barMax = _d.default.max(d, function (d) {
        return d.score;
      });
      return yscale(barMax);
    },

    // Draws a gradient for supporting continuous scales.  Supports scales for which high is bad.
    makeGradients: function makeGradients() {
      var context = this;
      this.get("svg.defs").selection.each(function () {
        var selection = _d.default.select(this);
        var yScale = context.get("yScale");
        var gradientId = context.get('gradientId');
        var gradTemplate = selection.selectAll("linearGradient#" + gradientId).data([0]);

        var gradData = [{ offset: "0%", color: "rgb(235,0,0)", opacity: 0.7 }, { offset: "30%", color: "rgb(255,100,0)", opacity: 0.6 }, { offset: "70%", color: "rgb(205,205,0)", opacity: 0.5 }, { offset: "85%", color: "rgb(0,175,0)", opacity: 0.7 }];

        gradTemplate.enter().append("linearGradient").attr("id", gradientId);

        gradTemplate.attr("y1", _d.default.min(yScale.range())).attr("y2", _d.default.max(yScale.range())).attr("x1", "0%").attr("x2", "0%").attr("gradientUnits", "userSpaceOnUse");

        var stops = gradTemplate.selectAll("stop").data(gradData);

        stops.enter().append("stop").attr("offset", function (d) {
          return d.offset;
        }).attr("stop-color", function (d) {
          return d.color;
        }).attr("stop-opacity", function (d) {
          return d.opacity;
        });
      });
    },

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');

      this.makeGradients();

      selection.each(function () {
        var selection = _d.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;

        //context.events(selection); // Has to go first so brush can bind
        //context.brush(selection);
        context.axes(selection);
        context.etrCurves(selection);
        context.eventIcons(selection);
        context.lines(selection);
      });
    },


    actions: {
      //todo: refactor so points and otherpoints don't have to be passed-in
      setCurrentValue: function setCurrentValue(item, point, otherPoints) {
        if (otherPoints) {
          otherPoints.classed("selected", false);
        }
        if (point) {
          _d.default.select(point).classed("selected", true);
        }

        this.set('currentValue', item.datum);
        this.sendAction('onValueSelect', item.datum);
      }
    }
  });
});