define('frontend/models/assessment/scale-comparison', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    latestValue: null,
    latestValueTruncated: Ember.computed('latestValue', function () {
      return this.get('latestValue.value').toFixed(1);
    }),
    comparisonValue: null,
    scale: Ember.computed.oneWay("latestValue.scale"),
    norm: Ember.computed.oneWay("latestValue.norm"),
    delta: Ember.computed("latestValue.value", "comparisonValue.value", function () {
      var latestValue = this.get("latestValue.value");
      var comparisonValue = this.get("comparisonValue.value");
      return latestValue - comparisonValue;
    }),
    deltaTruncated: Ember.computed('delta', function () {
      return Math.abs(this.get('delta').toFixed(1));
    }),

    isWorsening: Ember.computed('delta', function () {
      var delta = this.get('delta');
      return delta > 0 && !this.get('scale.isBeneficial') || delta < 0 && this.get('scale.isBeneficial');
    }),

    isImproving: Ember.computed('delta', function () {
      var delta = this.get('delta');
      return delta > 0 && this.get('scale.isBeneficial') || delta < 0 && !this.get('scale.isBeneficial');
    }),

    severity: Ember.computed("latestValue.value", "scale.isBeneficial", function () {
      var value = this.get("latestValue.value");
      return _analytics.default.getSeverityMnemonic(value, this.get("scale"));
    }),

    isNorse: Ember.computed("scale", function () {
      return this.get('scale.measureType') === 'norse';
    })
  });
});