define('frontend/models/billing/coverage', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RelationshipOptionBuilder = exports.Relationships = exports.Validations = undefined;


  var Relationships = ['self', 'none', 'child', 'parent', 'spouse', 'common', 'injured', 'other'];

  var RelationshipOptionBuilder = function RelationshipOptionBuilder(intl) {
    return Relationships.map(function (option) {
      return {
        id: option,
        name: intl.t('model.coverage.relationships.' + option)
      };
    });
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    institutePayor: (0, _emberCpValidations.validator)('presence', true),
    patient: (0, _emberCpValidations.validator)('presence', true),
    startDateString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        allowBlank: false,
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    endDateString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        allowBlank: true,
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    }
  });

  exports.Validations = Validations;
  exports.Relationships = Relationships;
  exports.RelationshipOptionBuilder = RelationshipOptionBuilder;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    patient: _emberData.default.belongsTo('patient'),
    institutePayor: _emberData.default.belongsTo('institutePayor'),

    relationship: _emberData.default.attr('string'),
    subscriberNumber: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    isPrimary: _emberData.default.attr('boolean'),

    displayName: Ember.computed('institutePayor', 'subscriberNumber', function () {

      var name = this.get("institutePayor.name");

      if (this.get("subscriberNumber")) {
        name += " - " + this.get('subscriberNumber');
      }

      return name;
    }),

    startDateString: Ember.computed('startDate', {
      get: function get() {
        if (this.get('startDate')) {
          return moment(this.get('startDate')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("startDate", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    endDateString: Ember.computed('endDate', {
      get: function get() {
        if (this.get('endDate')) {
          return moment(this.get('endDate')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("endDate", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    })
  });
});