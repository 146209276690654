define('frontend/pods/components/reporting/columns/scale-threshold-breakdown/component', ['exports', 'lodash', 'frontend/pods/components/reporting/columns/scale-value/component'], function (exports, _lodash, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'percent-bar',
    attributeBindings: ['percentBarStyle:style', 'barTitle:title'],

    data: Ember.computed("column.dataMapping.[]", "row", "comparisonRow", function () {
      var row = this.get('row');
      var comparisonRow = this.get('comparisonRow');
      return this.get('column.dataMapping').flatMap(function (threshold) {
        var value = row[threshold.id];
        var comparisonValue = comparisonRow ? comparisonRow[threshold.id] : null;

        if (value) {
          return [{
            value: value,
            comparisonValue: comparisonValue,
            name: threshold.name,
            class: threshold.class
          }];
        } else {
          return [];
        }
      });
    }),

    hasData: Ember.computed("data", function () {
      return _lodash.default.some(this.get("data"), function (item) {
        return !!item.value;
      });
    }),

    barTitle: Ember.computed('data.[]', function () {
      var data = this.get('data');

      return data.map(function (item) {
        if (item.comparisonValue) {
          return item.name + ': ' + item.value + ' (was ' + item.comparisonValue + ')';
        } else {
          return item.name + ': ' + item.value;
        }
      }).join('\n');
    }),

    percentBarStyle: Ember.computed("data.[]", function () {
      var data = this.get('data');
      var total = data.reduce(function (result, item) {
        return result + item.value;
      }, 0);

      if (total === 0) {
        return '';
      }

      var startSum = 0;
      var endSum = 0;

      var breaks = data.map(function (item) {
        var color = _component.interpretationMap[item.class];
        startSum = endSum;
        endSum = startSum + Math.round(item.value / total * 100);

        if (startSum === 0) {
          return color + ', ' + color + ' ' + endSum + '%';
        } else if (endSum === 100) {
          return color + ' ' + startSum + '%, ' + color;
        } else {
          return color + ' ' + startSum + '%, ' + color + ' ' + endSum + '%';
        }
      });

      return Ember.String.htmlSafe('min-height: 1em; background:linear-gradient(to right, ' + breaks.join(',') + ')');
    })
  });
});