define('frontend/pods/provider/assessment/dashboard/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    screen: Ember.inject.service(),
    institute: Ember.inject.service(),

    useResearchDashboard: Ember.computed.equal('institute.configuration.mcleanLegacyMode', true)
  });
});