define('frontend/pods/components/provider/patient-sessions/session-list-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    statusClass: Ember.computed('record.status', function () {
      var status = this.get('record.status');
      return this.get('statusClassMap')[status];
    }),
    statusClassMap: {
      feedback_complete: 'good',
      complete: 'good',
      closed: 'bad',
      scheduled: 'neutral'
    }
  });
});