define('frontend/models/survey/invite-bundle', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    invitation: _emberData.default.belongsTo('assessment/invitation'),
    invite: _emberData.default.belongsTo('survey/invite'),
    response: _emberData.default.belongsTo('survey/response'),
    responseItems: _emberData.default.hasMany('survey/responseItem'),
    overriddenItems: _emberData.default.hasMany('survey/responseItem')
  });
});