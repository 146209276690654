define('frontend/pods/components/provider/assessment/dashboard/dashboard-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['side-bar'],
    tagName: 'section'
  });
});