define('frontend/models/assessment/invitation', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = exports.FormatOptionBuilder = exports.PhaseOptionBuilder = exports.PhaseMap = undefined;


  var statusMap = {
    future: 0,
    new: 1,
    scheduled: 2,
    sent: 3,
    begun: 4,
    complete: 5
  };

  var PhaseMap = {
    before: -1,
    during: 0,
    after: 1
  };

  var validationOptions = {
    textMessage: {
      validators: [(0, _emberCpValidations.validator)('length', {
        is: 110,
        allowBlank: true
      })]
    }
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  var Phases = ['before', 'during', 'after'];
  var PhaseOptionBuilder = function PhaseOptionBuilder(intl) {
    return Phases.map(function (phase) {
      return {
        id: phase,
        name: intl.t('model.invitation.phases.' + phase)
      };
    });
  };

  var Formats = ['self_report', 'interview'];
  var FormatOptionBuilder = function FormatOptionBuilder(intl) {
    return Formats.map(function (format) {
      return {
        id: format,
        name: intl.t('model.invitation.formats.' + format)
      };
    });
  };

  exports.PhaseMap = PhaseMap;
  exports.PhaseOptionBuilder = PhaseOptionBuilder;
  exports.FormatOptionBuilder = FormatOptionBuilder;
  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    MUTABLE_STATES: [null, "future", "new", "scheduled", "sent"],
    configuration: Ember.inject.service(),

    assessment: _emberData.default.belongsTo('assessment/assessment', { async: true }),
    report: _emberData.default.belongsTo('assessment/report', { async: true }),
    patient: _emberData.default.belongsTo('patient', { async: true }),
    user: _emberData.default.belongsTo("user", { inverse: null }),
    takingUser: _emberData.default.belongsTo("user", { inverse: null }),
    participant: _emberData.default.belongsTo("participant", { polymorphic: true }),
    reportScaleParticipants: _emberData.default.hasMany("assessment/reportScaleParticipant"),
    reportScales: _emberData.default.hasMany("assessment/reportScales"),
    activeReportScaleParticipants: Ember.computed.filterBy("reportScaleParticipants", "isDeleted", false),
    activeVisibleReportScaleParticipants: Ember.computed.filterBy("enabledReportScaleParticipants", "blinded", false),
    blindedReportScaleParticipants: Ember.computed.filterBy("enabledReportScaleParticipants", "blinded", true),
    enabledReportScaleParticipants: Ember.computed('activeReportScaleParticipants.[]', function () {
      return this.get("activeReportScaleParticipants").filter(function (rsp) {
        return !rsp.get('reportScale.disabled');
      });
    }),
    surveyInvite: _emberData.default.belongsTo('survey/invite', { inverse: null }),
    email: _emberData.default.attr("string"),
    emailUrl: _emberData.default.attr("string"),
    assessmentUrl: _emberData.default.attr("string"),
    dateEmailed: _emberData.default.attr("date"),
    dateCompleted: _emberData.default.attr("date"),
    dateBegun: _emberData.default.attr("date"),
    dateTexted: _emberData.default.attr("date"),
    sendEmailAt: _emberData.default.attr("date"), // Used to send email in the future
    sendNotifications: _emberData.default.attr("boolean", { defaultValue: false }), // Used to send email right now
    emailMessage: _emberData.default.attr('string'),
    textMessage: _emberData.default.attr('string'),

    invitationNotifications: _emberData.default.hasMany('assessment/invitationNotification'),
    triggerImmediateNotification: _emberData.default.attr('boolean'),
    activeNotifications: Ember.computed('invitationNotifications.[]', function () {
      return this.get("invitationNotifications").filter(function (notification) {
        var status = notification.get('status');
        return ['sent', 'scheduled', 'missing_details', 'forbidden'].includes(status);
      });
    }),

    willSendEmail: Ember.computed('format', 'sendNotifications', 'sendEmailAt', function () {
      return this.get('format') !== 'interview' && (this.get('sendEmailAt') || this.get('sendNotifications'));
    }),
    sendTextAt: _emberData.default.attr("date"), // Used to send text in the future
    phoneNumber: _emberData.default.attr('string'),
    willSendText: Ember.computed('format', 'sendNotifications', 'sendTextAt', function () {
      return this.get('format') !== 'interview' && (this.get('sendTextAt') || this.get('sendNotifications'));
    }),
    status: _emberData.default.attr("string"),
    statusUpdatedAt: _emberData.default.attr("date"),
    allQuestions: _emberData.default.attr("boolean"),
    statusEvent: _emberData.default.attr("string"),
    takingTimeSeconds: _emberData.default.attr('number'),
    takingTimeMinutes: Ember.computed('takingTimeSeconds', function () {
      return Math.round(this.get('takingTimeSeconds') / 60);
    }),
    takingLanguage: _emberData.default.attr('string'),
    offeredScales: _emberData.default.attr(''),
    phase: _emberData.default.attr('string'),
    format: _emberData.default.attr('string'),
    referrer: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr("date"),
    updatedAt: _emberData.default.attr("date"),

    opensAt: _emberData.default.attr("date"),
    closesAt: _emberData.default.attr("date"),

    availability: Ember.computed('status', function () {
      var status = this.get("status");

      switch (status) {
        case 'future':
          return 'tooEarly';
        case 'expired':
          return 'tooLate';
        default:
          return 'open';
      }
    }),

    allowProviderActions: Ember.computed('status', function () {
      var status = this.get("status");

      return status !== 'future' && status !== 'expired' && status !== 'completed';
    }),

    patientFirstName: _emberData.default.attr('string'),
    patientLastName: _emberData.default.attr('string'),
    patientName: Ember.computed('patientFirstName', 'patientLastName', function () {
      var firstName = this.get('patientFirstName');
      var lastName = this.get('patientLastName');

      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (firstName) {
        return firstName;
      } else {
        return "Anonymous Patient";
      }
    }),

    metadata: _emberData.default.attr(''),

    resendNotifications: function resendNotifications() {
      this.setProperties({ triggerImmediateNotification: true });
      return this.save();
    },


    supportsEmail: Ember.computed.alias("user.allowsEmail"),
    supportsText: Ember.computed.alias("user.allowsText"),
    supportsNotifications: Ember.computed('supportsText', 'supportsEmail', 'format', function () {
      if (this.get("format") === 'interview') {
        return false;
      }

      return this.get('supportsEmail') || this.get('supportsText');
    }),

    emailWithDefault: Ember.computed('email', 'user.email', function () {
      var overriddenEmail = this.get("email");

      if (overriddenEmail) {
        return overriddenEmail;
      }

      var participantEmail = this.get("participant.email");

      if (participantEmail) {
        return participantEmail;
      }

      return this.get("user.email");
    }),

    phoneNumberWithDefault: Ember.computed('phoneNumber', 'user.phoneNumber', function () {
      return this.get("phoneNumber") || this.get("user.phoneNumber");
    }),

    hasContactDetails: Ember.computed.or('emailWithDefault', 'phoneNumberWithDefault'),

    wasSent: Ember.computed('dateEmailed', 'dateTexted', function () {
      return !!this.get('dateEmailed') || !!this.get('dateTexted');
    }),
    isCompleted: Ember.computed('status', function () {
      return this.get("status") === "complete";
    }),

    isPreliminary: Ember.computed('report.status', function () {
      return this.get("status") === "processing";
    }),

    isEmailScheduled: Ember.computed('status', 'sendEmailAt', 'dateEmailed', function () {
      return !this.get("dateEmailed") && this.get('sendEmailAt') && (this.get('status') === 'future' || this.get('status') === 'scheduled' || this.get('status') === 'new');
    }),

    isTextScheduled: Ember.computed('status', 'sendTextAt', 'dateTexted', function () {
      return !this.get("dateTexted") && this.get('sendTextAt') && (this.get('status') === 'future' || this.get('status') === 'scheduled' || this.get('status') === 'new');
    }),

    isMutable: Ember.computed('status', function () {
      return this.get('MUTABLE_STATES').indexOf(this.get('status')) >= 0;
    }),

    maxStatus: Ember.computed("status", function () {
      return statusMap[this.get("status")];
    }),

    hasData: Ember.computed('status', function () {
      var status = this.get("status");
      return status === 'begun' || status === 'complete';
    }),

    requiresCompletion: Ember.computed('status', function () {
      var status = this.get("status");

      return ['new', 'scheduled', 'sent', 'begun'].includes(status);
    }),

    name: Ember.computed('format', 'participant', function () {
      var name = this.get("participant.name");

      var format = this.get("format");

      if (format === 'interview') {
        return name + ' (interview)';
      } else {
        return name;
      }
    }),

    forceReopen: function forceReopen() {
      this.setProperties({ statusEvent: 'force_reopen', closesAt: moment().add(24, 'hours').toDate() });
      return this.save();
    },


    // If the user's preferences have updated since the invitation was created, it might be necessary to
    // update defaults.
    updateNotificationsToPreferences: function updateNotificationsToPreferences(enable, targetDate) {
      var _this = this;

      if (enable && this.get("supportsNotifications")) {
        return Ember.run.later(function () {
          return _this.get('report.patientSession.activeConfiguration').then(function (configurationValues) {
            if (_this.get('isDestroyed')) {
              return;
            }
            if (!configurationValues.enableAssessmentNotifications) {
              return _this.setProperties({
                "disableNotifications": !configurationValues.enableAssessmentNotifications,
                "sendNotifications": false,
                "sendEmailAt": null,
                "sendTextAt": null
              });
            }

            // If time to send notifications, hit send now
            var sendDate = _this.defaultNotificationSendDate(targetDate, configurationValues);

            if (sendDate < new Date()) {
              _this.setProperties({
                "disableNotifications": false,
                "sendNotifications": true,
                "sendEmailAt": null,
                "sendTextAt": null
              });
            } else {
              var sendEmailAt = _this.get('supportsEmail') ? sendDate : null;
              var sendTextAt = _this.get('supportsText') ? sendDate : null;

              _this.setProperties({
                "disableNotifications": false,
                "sendNotifications": false,
                sendEmailAt: sendEmailAt,
                sendTextAt: sendTextAt
              });
            }
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          if (_this.get('isDestroyed') || _this.get('isDeleted')) {
            resolve(true);
          } else {
            _this.setProperties({
              "disableNotifications": null,
              "sendNotifications": false,
              "sendEmailAt": null,
              "sendTextAt": null
            });
            resolve(true);
          }
        });
      }
    },


    defaultNotificationSendDate: function defaultNotificationSendDate(targetDate, configurationValues) {
      if (!this.get('supportsNotifications') || !configurationValues.enableAssessmentNotifications) {
        return null;
      }
      var startDate = targetDate || new Date();
      var phase = this.get("phase");
      if (phase === 'before') {
        return moment(startDate).subtract(configurationValues.notificationMinutesBeforeAppointment[0], 'minutes').toDate();
      } else if (phase === 'during') {
        return startDate;
      } else if (phase === 'after') {
        return moment(startDate).add(configurationValues.notificationMinutesAfterAppointment[0], 'minutes').toDate();
      }
    }
  });
});