define('frontend/models/care/future-session-measurement-estimate', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    careEpisode: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    careEpisode: _emberData.default.belongsTo("careEpisode"),
    results: _emberData.default.attr(),

    hydratedResults: Ember.computed("results.[]", function () {
      var _this = this;

      return this.get("results").map(function (result) {
        return Ember.merge(result, {
          scales: result.scale_ids.map(function (id) {
            return _this.get('store').peekRecord('assessment/scale', id);
          }),
          patientSession: _this.get('store').peekRecord('patientSession', result.session_id),
          planning: result.planning_details.map(function (scaleDetails) {
            return Ember.merge(scaleDetails, { scale: _this.get('store').peekRecord('assessment/scale', scaleDetails.scale_id) });
          })
        });
      });
    })
  });
});