define('frontend/initializers/component-router-injector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    // Injects all Ember components with a router object:
    application.inject('component', 'router', 'router:main');
    application.inject('metrics-adapter:mirah', 'router', 'router:main');
    application.inject('service:institute', 'router', 'router:main');
    application.inject('service:help', 'router', 'router:main');
  }

  exports.default = {
    name: 'component-router-injector',
    initialize: initialize
  };
});