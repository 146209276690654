define('frontend/models/experiment', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    enabled: _emberData.default.attr("boolean"),
    target: _emberData.default.attr("string"),
    triggerEvents: _emberData.default.attr(),
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode'),

    author: _emberData.default.belongsTo('user'),
    consent: _emberData.default.belongsTo('consent', { async: false }),
    experimentVariants: _emberData.default.hasMany('experimentVariant'),
    experimentDecisions: _emberData.default.hasMany('experimentDecisions')
  });
});