define("frontend/locales/en/provider-admin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    users: {
      navTitle: 'Manage Users',
      list: {
        title: "Providers",
        description: "List of staff users on the platform",
        showProvider: "Details"
      },
      create: {
        title: "Create New Staff User",
        description: "Add a new staff user to the system",
        hideDisabled: 'Hide Disabled',
        showDisabled: 'Show Disabled'
      },
      edit: {
        title: "Edit {providerName}",
        description: "Edit details for a provider"
      },
      show: {
        description: "Details for a provider",
        infoTitle: "User details",
        resendWelcomeEmail: "Resend Welcome Email",
        resendWelcomeEmailSuccess: "Welcome Email has been sent",
        activateProvider: "Activate Provider and Send Welcome Email",
        disableProvider: "Disable Provider",
        disableProviderPermanently: "Disable Provider",
        reenableProvider: "Re-enable Provider",
        providerDisabled: "This provider is disabled. They will not be able to log in, see data, or take assessments but will continue to show on some analytics reports. Currently assigned clients will remain assigned until those clients are assigned to a different provider. New sessions will not be created from the EMR for this provider.",
        providerDisabledInactivity: "This provider has been suspended for security reasons due to 60 days of inactivity. They will not be able to log in, see data, or take assessments but will continue to show on some analytics reports. New sessions will not be created from the EMR for this provider. They will still count as 'enabled' for the purposes of tracking expected provider activity. If this provider has left the organization, you should disable them.",
        providerAwaitingConfirmation: "This provider has been enabled but has not yet logged into the system. Until they log on, they will not be able complete clinician assessments or have measurements for their clients.",
        providerPending: "This provider is pending enablement. They will not be able to log in, see data, or take assessments. New sessions will not be created from the EMR for this provider."
      },
      editForm: {
        submit: "Save"
      },
      editProvider: "Edit",
      backToUserList: 'Back to user list'
    },
    help: {
      guides: 'Measure Library',
      contactSupport: 'Contact Support',
      usingMirah: 'Using Mirah',
      measures: 'Clinical measures',
      providers: {
        title: 'Providers'
      },
      staff: {
        title: 'Staff'
      },
      superusers: {
        title: 'Superusers'
      },
      providerQuickGuide: {
        title: 'Provider Quick Guide',
        description: 'finding and creating clients'
      },
      staffQuickGuide: {
        title: 'Staff Quick Guide',
        description: 'finding and helping clients log in'
      },
      superuserQuickGuide: {
        title: 'Superuser Quick Guide',
        description: 'Creating new users, managing settings'
      }
    },
    organizations: {
      navTitle: 'Manage Units'
    },
    bulkExport: {
      navTitle: 'Bulk Export Data',
      listTitle: 'Data Dumps',
      beginDataExport: 'Begin Data Export',
      exportInfo: "Exports may take several minutes to generate, and will be generated as an encrypted zip file. To begin the download once the export is ready, click the 'download' link on the following page.",
      password: "Zip Password",
      submitMessage: "Your export may take several minutes to complete. This page will refresh automatically.",
      beginDownload: "Your download should start now",
      researchModeInfo: "This exports the questionnaire based research data set. To add additional data sets, use 'Advanced options'",
      showAdvanced: "Advanced options"
    },
    mirahInternal: "Mirah Internal",
    payors: {
      navTitle: 'Payer Setup',
      show: {
        infoTitle: 'Payer Information',
        contractsTitle: 'Contracts'
      },
      list: {
        title: 'Payors'
      },
      create: {
        title: 'Add New Payer',
        description: 'Add a new payer',
        submit: 'Add Payer',
        missingPayor: 'If the payer you are looking for is not on this list, please contact <a href="mailto:support@mirah.com">support@mirah.com</a>'
      },
      addPayor: 'Add payer',
      addContract: 'Add new contract',
      contractSaved: 'Contract saved',
      contractCreated: 'Contract saved',
      contract: {
        codesTitle: 'Reimbursements'
      }
    },
    configuration: {
      navTitle: 'Configuration Manager',
      chooseFilters: 'Showing configuration for...',
      usingDefault: 'This is the default value',
      newValue: 'New Value',
      settingAffects: 'This setting will take affect for:',
      existingAffectedWarning: 'Pre-existing events may not be automatically updated to use the new setting.',
      supersedingConfig: 'Configuration Overrides',
      supersedingConfigExplanation: 'Configuration settings that are more specific, e.g. specify a lower level facility or are patient- or provider- specific, will take preference to the settings above if they apply. If you want to edit a value, click on the "path" of the setting, or select it in the controls at the top of the page.',
      supersedingConfigWarning: 'There may be configuration settings at a lower level that might be used.',
      textEn: 'English text',
      textEs: 'Spanish text'
    },
    experiments: {
      navTitle: 'Experiments'
    }
  };
});