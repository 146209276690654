define('frontend/pods/components/provider/assessment/common/symptom-name/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    enableUnansweredAlert: true,

    showUnansweredAlert: Ember.computed('enableUnansweredAlert', 'computedValue', function () {
      return this.get('enableUnansweredAlert') && this.get('computedValue.hasUnansweredQuestions');
    })
  });
});