define('frontend/pods/components/reporting/columns/adherence-history/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sessionStatusMap = {
    new: 'fa fa-calendar-o',
    completed: 'fa fa-check-circle good',
    failed: 'fa fa-close bad',
    aborted: 'fa fa-circle',
    active: 'fa fa-adjust',
    unknown: 'fa fa-question-circle'
  };

  var adherenceThreshold = function adherenceThreshold(value) {
    if (!value) {
      return 'unknown';
    } else if (value < 0.5) {
      return 'bad';
    } else if (value < 0.75) {
      return 'moderate';
    } else {
      return 'good';
    }
  };

  exports.default = Ember.Component.extend({
    showClass: Ember.computed.notEmpty("column.dataMapping.adherence"),
    adherence: Ember.computed("column.dataMapping.adherence", "row", function () {
      return this.get("row")[this.get("column.dataMapping.adherence")];
    }),
    adherencePercent: Ember.computed("adherence", function () {
      return this.getWithDefault("adherence", 0) * 100;
    }),
    adherenceClass: Ember.computed("adherence", function () {
      return adherenceThreshold(this.get("adherence"));
    }),

    sessionStatuses: Ember.computed("column.dataMapping.history", "row", function () {
      var key = 'row.' + this.get('column.dataMapping.history');

      var history = this.get(key);

      if (!history) {
        return [];
      }

      return history.map(function (status) {
        return sessionStatusMap.hasOwnProperty(status) ? status : 'unknown';
      });
    }),

    // Truncate session history 6 backwards, and 3 forwards, from the first 'new' entry.
    sessionHistory: Ember.computed("sessionStatuses.[]", function () {
      var all = this.get("sessionStatuses");

      var truncated;

      var index = all.indexOf('new');

      if (index > -1) {
        truncated = all.slice(Math.max(index - 6, 0), index).concat(all.slice(index, index + 3));
      } else {
        truncated = all.slice(Math.max(all.length - 6, 0));
      }

      return truncated.map(function (status) {
        return {
          className: sessionStatusMap[status],
          title: status[0].toUpperCase() + status.slice(1)
        };
      });
    })
  });
});