define('frontend/pods/components/provider/plan/session-scale-schedule/component', ['exports', 'frontend/pods/components/provider/assessment/scale-info-drawer/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.ScaleDrawerMixin, {
    actions: {
      showScale: function showScale(scale) {
        this.set('focusedScale.scale', scale);
      }
    }
  });
});