define('frontend/locales/en/survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    pageHeader: {
      title: "Assessment"
    },
    dialogs: {
      help: {
        button: 'Help',
        message: '<p><strong>Trouble logging in?</strong>  Contact your provider to ensure your information is correct.</p>\n                  <p><strong>Concerned about privacy?</strong>  Your data is protected by HIPAA and Mirah and your provider\'s Privacy Policies.</p>\n                  <p><strong>Having trouble understanding a question?</strong> Give your best answer, and then you can ask your provider about it at your next opportunity. </p>',
        okButton: 'Ok'
      },
      contactDetails: {
        button: 'Notifications',
        title: 'How would you like to receive notifications for future assessments?',
        saveAndClose: 'Save & Close',
        cancelAndClose: 'Cancel',
        privacyWarning: 'By checking either box(es), you acknowledge and agree that Mirah will be sending your health information via an unencrypted means and there is some risk of disclosure or interception of the email or text message.'
      },
      exit: {
        button: 'Exit',
        message: '<p>If you leave, your progress so far will be saved, and you can come back later.</p>',
        stayButton: 'Stay',
        exitButton: 'Exit'
      },
      noBack: {
        message: '<p>You are already at the beginning of the measure.\n             <br> For security reasons, you cannot go back.\n             <br> To exit, use the "Exit" button</p>',
        okButton: 'Ok'
      }
    },
    controls: {
      back: 'Back',
      next: 'Next',
      skip: 'Skip',
      required: 'Required Question',
      exit: 'Exit',
      finish: 'Finish'
    },
    finished: {
      title: "Thank you!",
      description: '<p>Your responses are vital in understanding your mental health story and helping to guide your treatment and therapy.</p>\n                  <p>Ask your clinician for their insights at your next appointment.</p>',
      logoutWarning: 'For your security, you have been logged out.',
      redirect: "You will be redirected in 5 seconds.",
      redirectNow: "Redirect now"
    },
    exit: {
      title: "You have exited the survey",
      description: '<p>Your responses have been saved, and you can resume taking the survey at any time.</p>\n                  <p>If you have any concerns about taking this assessment, please talk to your clinician.</p>'
    },
    timeout: {
      title: "Your session has timed out.",
      description: '<p>Your responses have been saved, and you can resume taking the survey at any time.</p>'
    },
    error: {
      title: "An error has occurred",
      description: "We are having problems communicating with the Mirah servers. In order to ensure that all the data you enter is saved to our systems, the survey has been halted. Please close this browser window and start again to complete your assessment."
    },
    contactDetails: {
      noDetailsText: 'You can choose to take these assessments on your mobile phone or computer next time',
      confirmDetailsText: 'Confirm your contact info below:',
      notifyMeBy: 'Send assessments to me by:',
      email: "Email",
      phoneNumber: "Mobile Phone",
      text: "Text"
    },
    timeoutWarning: {
      title: "Are you still there?",
      description: "Your responses have been saved. You will automatically be logged out after 60 seconds.",
      continue: "Continue Assessment",
      logout: "Log out"
    },
    questionHeaders: {
      clientCrisisWarning: 'Crisis warning',
      clientFinish: 'Finish',
      clientFinishRelatedPerson: 'Finish',
      clientResponse: 'Your response',
      clientWelcome: 'Welcome',
      contactDetails: 'Contact details',
      default: 'Question',
      hipaaConsent: 'Consent',
      relatedPersonWelcome: 'Welcome'
    }
  };
});