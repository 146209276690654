define('frontend/pods/provider/patients/show/plan/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      providerId: 'providerId',
      careEpisodeId: 'careEpisodeId',
      organizationId: 'organizationId'
    }
  });
});