define('frontend/utils/assessment/cams', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CAMS = {
    SIGNIFICANT_CHANGE_THRESHOLD: 0.5,

    isSignificantChange: function isSignificantChange(computedValue, compareValue) {
      var diff = computedValue.get('value') - compareValue.get('value');
      return Math.abs(diff) > CAMS.SIGNIFICANT_CHANGE_THRESHOLD;
    }
  };

  exports.default = CAMS;
});