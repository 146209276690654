define('frontend/models/billing/payor-contract-code', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    payorContract: _emberData.default.belongsTo('billing/payorContract'),
    chargeItemCode: _emberData.default.belongsTo('billing/chargeItemCode'),
    maxUnitsPerYear: _emberData.default.attr('number'),
    maxUnitsPerVisit: _emberData.default.attr('number'),
    maxVisitsPerYear: _emberData.default.attr('number'),

    contractedUnitPriceCents: _emberData.default.attr('number'),
    listUnitPriceCents: _emberData.default.attr('number'),

    usageDescription: Ember.computed('maxUnitsPerYear', 'maxUnitsPerVisit', 'maxVisitsPerYear', function () {
      var _getProperties = this.getProperties('maxUnitsPerYear', 'maxUnitsPerVisit', 'maxVisitsPerYear'),
          maxUnitsPerVisit = _getProperties.maxUnitsPerVisit,
          maxVisitsPerYear = _getProperties.maxVisitsPerYear,
          maxUnitsPerYear = _getProperties.maxUnitsPerYear;

      if (!maxUnitsPerVisit && !maxVisitsPerYear && !maxUnitsPerYear) {
        return "Unlimited usage";
      }

      var parts = [];

      if (maxUnitsPerVisit) {
        parts.addObject(maxUnitsPerVisit + ' units per visit');
      }

      if (maxVisitsPerYear) {
        parts.addObject(maxVisitsPerYear + ' visits per year');
      }

      if (maxUnitsPerYear) {
        parts.addObject('up to ' + maxUnitsPerYear + ' units per year');
      }

      return parts.compact().join(", ");
    })
  });
});