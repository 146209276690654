define('frontend/pods/components/provider/assessment/report-v2/emap-wordbank/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var bankQuestions = Array(12).fill().map(function (_, idx) {
    return 'wordBank' + (idx + 1);
  });

  var cellConfiguration = [{
    stem: 'UsedLastWeek',
    type: 'boolean'
  }, {
    stem: 'HowManyThreadsLastWeek',
    type: 'number'
  }, {
    stem: 'HowManyTimesUsedLastWeek',
    type: 'number'
  }, {
    stem: 'EmotionalState',
    type: 'emotion',
    subquestions: ['PositiveEmotionalContext', 'NegativeEmotionalContext', 'BothEmotionalContext', 'AmbiguousEmotionalContext']
  }, {
    stem: 'Emoji',
    type: 'boolean',
    subquestions: ['EmojiyDescription']
  }];

  exports.default = Ember.Component.extend({
    classNames: ['mcq-table', 'overflowed-table'],

    computedValue: Ember.computed('activeReport.computedValues.[]', function () {
      return this.get("activeReport.computedValues").findBy('scale.id', this.get("scale.id"));
    }),

    didRender: function didRender() {
      $('[data-toggle="popover"]').popover();
    },


    allAnswers: Ember.computed.mapBy('allComputedValues', 'answers'),

    // Return an object of:
    // {
    //   depressed: [
    //     {
    //       report,
    //       computedValue,
    //       question: 'wordBank1',
    //     }
    //   ]
    // }
    answerColumns: Ember.computed('activeReport', 'dataLoaded', 'allAnswers.[]', function () {
      var allComputedValues = this.get("allComputedValues");

      var allData = {};

      allComputedValues.forEach(function (cv) {
        bankQuestions.forEach(function (question) {
          var termAnswers = cv.get('answers').filterBy('question.shortname', question);

          termAnswers.forEach(function (termAnswer) {
            var value = termAnswer.get('value').replace(/^clinicianWriteIn\d: /, '').replace(/^subjectWriteIn\d: /, '');
            var displayTerm = value;

            if (!allData[value]) {
              allData[value] = {
                term: value,
                displayTerm: displayTerm,
                data: [] };
            }

            allData[value].data.addObject({
              term: value,
              displayTerm: displayTerm,
              bankQuestion: question,
              computedValue: cv,
              report: termAnswer.get('report'),
              answer: termAnswer
            });
          });
        });
      });

      return Object.values(allData).sortBy('term');
    }),

    // Returns a list of rows of:
    // {
    //   question,
    //   cells: [
    //     {
    //       term: 'scared',
    //       latestValue: 3,
    //       latestAnswer,
    //       comparisonValue: 2,
    //       comparisonAnswer,
    //       allAnswers: [...]
    //     }
    //   ]
    // }
    answerRows: Ember.computed('activeReport', 'dataLoaded', 'answerColumns.[]', 'allAnswers.[]', function () {
      var columns = this.get("answerColumns");
      var activeReport = this.get('activeReport');
      var comparisonReport = this.get('comparisonReport');

      return cellConfiguration.flatMap(function (config) {
        var stem = config.stem;
        var cells = columns.map(function (column) {
          // Find answers for column/stem combo
          var allAnswers = column.data.map(function (datum) {
            return datum.computedValue.get('answers').find(function (answer) {
              return answer.get('question.shortname') === datum.bankQuestion + stem;
            });
          }).compact();

          var subAnswers = void 0,
              subAnswerText = void 0;

          if (config.subquestions) {
            subAnswers = column.data.map(function (datum) {
              var questionsToLookFor = config.subquestions.map(function (q) {
                return datum.bankQuestion + q;
              });
              return datum.computedValue.get('answers').filter(function (answer) {
                return questionsToLookFor.indexOf(answer.get('question.shortname')) >= 0;
              });
            }).flatten().compact();

            var currentSubAnswers = subAnswers.filterBy('report.id', activeReport.get('id'));

            if (currentSubAnswers.length > 0) {
              subAnswerText = subAnswers.mapBy('value').join('\n');

              if (Ember.isEmpty(subAnswerText)) {
                subAnswerText = null;
              }
            }
          } // TODO: deal with multiple sessions

          var lastAnswer = allAnswers.get('lastObject');
          var lastValue = lastAnswer ? lastAnswer.get('value') : null;

          var currentAnswer = void 0,
              currentValue = void 0,
              diff = void 0,
              hasDiff = void 0;

          if (lastAnswer && lastAnswer.belongsTo("report").id() === activeReport.get('id')) {
            currentAnswer = lastAnswer;
            currentValue = currentAnswer.get('value');
          }

          var comparisonAnswer = void 0,
              comparisonValue = void 0;

          if (comparisonReport) {
            comparisonAnswer = allAnswers.findBy('report.id', comparisonReport.get('id'));
            comparisonValue = comparisonAnswer ? comparisonAnswer.get('value') : null;

            if (config.type === 'number' && currentValue) {
              hasDiff = true;
              diff = parseInt(currentValue) - parseInt(comparisonValue);
            }
          }

          return {
            term: column.term,
            config: config,
            allAnswers: allAnswers,
            currentAnswer: currentAnswer,
            currentValue: currentValue,
            lastAnswer: lastAnswer,
            lastValue: lastValue,
            subAnswers: subAnswers,
            subAnswerText: subAnswerText,
            comparisonAnswer: comparisonAnswer,
            comparisonValue: comparisonValue,
            hasDiff: hasDiff,
            diff: diff
          };
        });

        var question = cells.mapBy('allAnswers.firstObject.question').compact().get('firstObject');

        if (question) {
          return [{
            question: question,
            cells: cells
          }];
        } else {
          return [];
        }
      });
    })
  });
});