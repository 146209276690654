define('frontend/pods/components/provider-admin/configuration-active-values/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['entity-active-plans'],
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    showSuperseding: false,
    pathFilterType: Ember.computed('showSuperseding', function () {
      return this.get("showSuperseding") ? 'supersedesPath' : 'activeFor';
    }),

    activeConfigurationValues: Ember.computed('forceRefresh', 'path', 'entityTreeNode', 'entityTreeNode.path', 'filterTerm', function () {
      this.get("forceRefresh"); // Trigger the actual property binding.

      var filters = { deprecated: false };
      filters[this.get('pathFilterType')] = this.get('entityTreeNode.path');

      return Ember.Object.create({
        store: this.get('store'),
        modelName: 'activeConfigurationValue',
        query: {
          filter: filters,
          include: 'configuration-property,user,entity-tree-node,configuration-value'
        }
      });
    }),

    columns: Ember.computed(function () {
      var intl = this.get('intl');

      var columns = [{
        propertyName: 'configurationProperty.name',
        title: intl.t('model.configurationProperty.name')
      }, {
        propertyName: 'configurationProperty.category',
        title: intl.t('model.configurationProperty.category')
      }, {
        propertyName: 'displayValue',
        title: intl.t('model.configurationValue.value'),
        disableSorting: true
      }, {
        template: 'components/provider-admin/configuration-active-values/action-buttons',
        title: ''
      }];

      return columns;
    }),

    actions: {
      onConfigurationChange: function onConfigurationChange() {
        this.notifyPropertyChange('activeConfigurationValues');
      }
    }
  });
});