define('frontend/pods/components/provider/help-scout-beacon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    beacon: Ember.inject.service('help-scout-beacon'),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('beacon').setUser(this.id, this.name, this.email, this.signature);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.get('beacon').setUser(this.id, this.name, this.email, this.signature);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('beacon').shutDown();
      this._super.apply(this, arguments);
    }
  });
});