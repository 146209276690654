define('frontend/services/reports/adherence-drilldown-v2', ['exports', 'frontend/models/reporting/report'], function (exports, _report) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _report.default.create({
    // internal: true, qqHRB set this when done
    shortcode: 'adherence_drilldown_v2',
    role: 'executive',
    defaultLimit: 15,
    showDateRangeControl: true,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    icon: 'rulerMeasure.svg',
    mockData: null,
    showFlagFilter: true,
    showConditionFilter: true,
    columnProperties: {
      repeatPatients: {
        shown: false
      },
      measuredPatients: {
        shown: false
      },
      canceledPercent: {
        shown: false,
        lowerIsBetter: true
      },
      totalCanceled: {
        lowerIsBetter: true
      },
      providerRole: {
        featured: true
      },
      totalComplete: {
        featured: true,
        headerIcon: 'fa fa-check',
        className: 'primary',
        headline: true
      },
      totalFulfilled: {
        featured: true,
        headerIcon: 'fa fa-calendar'
      },
      completePercent: {
        featured: true,
        displayType: 'percent-bar',
        headline: true
      },
      reportViews: {
        featured: true
      },
      notViewedByPrimary: {
        displayType: 'alert-nonzero',
        lowerIsBetter: true
      },
      viewedOnTimePercent: {
        shown: false
      }
    },
    mockSummaryData: {
      stat: 0.85,
      statString: '85%',
      statLabel: 'sessions measured',
      trend: '5%',
      trendIcon: 'fa fa-arrow-up'
    },
    mockDial: {
      compute: function compute(requestData) {
        var startDate = requestData && requestData.startDate ? moment(requestData.startDate) : moment().subtract(1, 'years');
        var endDate = requestData && requestData.endDate ? moment(requestData.endDate) : moment();

        var startValue = getMockDataForDate(startDate.toDate());
        var endValue = getMockDataForDate(endDate.toDate());
        return (startValue + endValue) / 2;
      }
    }
  });


  /////////////////// MOCK GRAPH FUNCTIONS ///////////////////////

  function getMockDataForDate(date) {
    // some might say it's inefficient to redefine the history & scale every time,
    // but this both keeps them defensively scoped and doesn't clutter production initial execution
    var adherenceHistory = [0.35, 0.33, 0.43, 0.47, 0.56, 0.68, 0.69, 0.65, 0.53, 0.47, 0.49, 0.61, 0.65, 0.81, 0.83, 0.84, 0.84, 0.84];
    var timeScale = d3.time.scale().clamp(true).domain([moment().subtract(12, 'months').toDate(), moment().toDate()]).range([0, adherenceHistory.length - 1]);

    var dataIndex = Math.round(timeScale(date));
    return adherenceHistory[dataIndex] + (Math.random() - 0.5) * 0.1;
  }
});