define('frontend/pods/components/provider/assessment/scale-browser/list-component/selected-scale-column/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    validParticipants: Ember.computed('record', 'rowContext.allParticipants', function () {
      return;
    }),
    disableField: Ember.computed('record', 'rowContext.allParticipants', 'rowContext.selectedScaleIds', function () {
      var hasValidParticipants = this.get("record").hasValidParticipants(this.get("rowContext.allParticipants"));
      var alreadyCompleted = this.get("rowContext.completedScaleIds").indexOf(this.get('record.id')) >= 0;
      return alreadyCompleted || !hasValidParticipants;
    }),
    actions: {
      onScaleChange: function onScaleChange(scale) {
        this.get("actionHandler")('onScaleChange', scale);
      }
    }
  });
});