define('frontend/pods/components/provider/organizations/single-org-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    plugins: 'search',
    themes: {
      name: 'proton',
      icons: false,
      url: '/jstree-themes/proton-mirah/style.min.css'
    },

    providerOrgsOnly: true,

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Note that we reset jsTreeData here rather than in a computed property, because otherwise any selection/deselection of nodes
      // will cause the tree to refresh instantly, causing a blinking effect.

      var orgs = this.getWithDefault("organizations", []);
      var orgIds = orgs.mapBy("id");

      var current = this.get("model").get(this.get("property"));

      var defaultOpen = orgs.get("length") < 10;

      var jsTreeData = orgs.map(function (org) {
        var containsParent = orgIds.includes(org.get("parent.id"));
        return Ember.Object.create({
          id: org.get("id"),
          organization: org,
          text: org.get("name"),
          state: {
            selected: current && org.get("id") === current.get("id"),
            open: defaultOpen
          },
          parent: containsParent ? org.getWithDefault("parent.id", "#") : "#" // # = root node
        });
      });

      this.set("jsTreeData", jsTreeData);
    },


    actions: {
      handleJstreeEventDidChange: function handleJstreeEventDidChange(event) {
        var model = this.get("model");
        var property = this.get("property");

        switch (event.action) {
          case 'select_node':
            model.set(property, this.get("organizations").findBy('id', event.node.original.id));
            break;
          case 'deselect_node':
            model.set(property, null);
            break;
        }
      }
    }
  });
});