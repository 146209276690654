define('frontend/pods/provider/patients/lookup/create/route', ['exports', 'frontend/mixins/workflow-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/pods/base-route'], function (exports, _workflowRoute, _authenticatedRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_workflowRoute.default, _authenticatedRouteMixin.default, {
    sessionUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        patient: this.get("store").createRecord("patient", Ember.merge({ doctor: this.get("sessionUser.currentUser") }, this.get("workflow.hiddenParams"))),
        workflow: this.get("workflow")
      });
    }
  });
});