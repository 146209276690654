define('frontend/pods/components/provider/profile/edit-form/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    sessionUser: Ember.inject.service(),
    messages: Ember.inject.service(),
    institute: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.get("sessionUser.currentUser")
      });
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var success = function success() /* patient */{
          _this.get('messages').success("Profile saved");
          _this.get("metrics").trackEvent({ event: "provider.profile.updated" });
        };

        var failure = function failure(reason) {
          _this.get('messages').danger(_this.printError(reason, 'user'));
        };

        return this.get("user").save().then(success).catch(failure);
      }
    }

  });
});