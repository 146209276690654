define('frontend/pods/components/provider/entities/entity-path/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    includeFilter: true,
    hideInstitute: true,
    autoFormatCareEpisode: true,
    tagName: 'span',

    careEpisodeUseShortname: Ember.computed('autoFormatCareEpisode', 'entityTreeNode.segments.[]', function () {
      var segments = this.get("entityTreeNode.segments");
      return this.get("autoFormatCareEpisode") && segments.findBy('type', 'care-episode') && segments.findBy('type', 'patient');
    }),

    segments: Ember.computed('hideInstitute', 'entityTreeNode.segments.[]', function () {
      var segments = this.get("entityTreeNode.segments");

      if (this.get('hideInstitute') && segments && segments.length > 1) {
        segments = segments.rejectBy('type', 'institute');
      }

      return segments;
    })
  });
});