define('frontend/models/assessment/symptom', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr('string'),
    score: _emberData.default.attr('number'),
    qualified: _emberData.default.attr('boolean'),
    screenShown: _emberData.default.attr('boolean'),
    assessShown: _emberData.default.attr('boolean'),
    sortedAnswers: _emberData.default.hasMany('answer'),
    textAnswers: _emberData.default.hasMany('answer'),
    scoreClass: Ember.computed('score', function () {
      var score = this.get('score');
      var scoreClass = 'none';
      if (score > 6) {
        scoreClass = 'severe';
      } else if (score > 4) {
        scoreClass = 'moderate';
      } else if (score >= 2) {
        scoreClass = 'low';
      } else {
        scoreClass = 'none';
      }
      return new Ember.Handlebars.SafeString(scoreClass);
    })
  });
});