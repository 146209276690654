define('frontend/pods/provider/profile/edit/route', ['exports', 'frontend/pods/base-route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _baseRoute, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_authenticatedRouteMixin.default, {
    breadCrumb: {
      title: 'Edit Profile',
      linkable: true
    }
  });
});