define('frontend/pods/components/provider/assessment/patient-timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['patient-timeline'],

    actions: {
      playTimeline: function playTimeline() {
        this.set("timelineModel.shouldPlay", true);
      },
      pauseTimeline: function pauseTimeline() {
        this.set("timelineModel.shouldPause", true);
      }
    },
    reportsArray: Ember.computed("reports", function () {
      var reports = this.get("reports");
      if (reports) {
        return reports.toArray();
      } else {
        return [];
      }
    }),

    showTimeline: Ember.computed("reports", function () {
      var reports = this.get("reports");
      if (reports) {
        return reports.length > 1;
      } else {
        return false;
      }
    })
  });
});