define('frontend/models/assessment/question', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    answers: _emberData.default.hasMany('assessment/question', { async: true }),
    title: _emberData.default.attr(''),
    //strips markup from question title
    text: Ember.computed('title', function () {
      var title = this.get('title');
      return title.substring(0, title.indexOf('<'));
    }),
    questionType: _emberData.default.attr('string'),
    subtype: _emberData.default.attr('string'),
    shortname: _emberData.default.attr('string'),
    varname: _emberData.default.attr('string'),
    displayData: _emberData.default.attr(),
    options: _emberData.default.attr(''),
    domain: _emberData.default.attr('string'),

    reportText: Ember.computed('title', 'displayData.report_text', function () {
      return this.get("displayData.report_text") ? this.get('displayData.report_text') : this.get("title");
    }),

    optional: Ember.computed('displayData.optional', function () {
      return this.get("displayData.optional");
    })
  });
});