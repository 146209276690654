define("frontend/pods/components/provider-admin/payors/contract-code-table/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get("intl");

      var tableColumns = [{
        propertyName: "chargeItemCode.code",
        title: intl.t('model.chargeItemCode.code')
      }, {
        propertyName: "usageDescription",
        title: intl.t('model.payorContractCode.usageDescription'),
        disableSorting: true
      }, {
        template: "components/provider-admin/payors/contract-code-table/contracted-unit-price-column",
        title: intl.t('model.payorContractCode.contractedUnitPrice')
      }, {
        template: "components/provider-admin/payors/contract-code-table/list-unit-price-column",
        title: intl.t('model.payorContractCode.listUnitPrice')
      }];

      this.set("tableColumns", tableColumns);
    }
  });
});