define('frontend/pods/components/reporting/report-dashboard/component', ['exports', 'ember-data', 'moment', 'frontend/mixins/drawer-enabled', 'frontend/utils/date-range-presets', 'frontend/models/reporting/report-params'], function (exports, _emberData, _moment, _drawerEnabled, _dateRangePresets, _reportParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var intervalForDateRange = function intervalForDateRange(start, end) {
    var diff = (0, _moment.default)(end).diff((0, _moment.default)(start), 'days');

    if (diff <= 7) {
      return 'day';
    } else if (diff <= 31) {
      return 'week';
    } else if (diff <= 365) {
      return 'month';
    } else {
      return 'year';
    }
  };

  var mergeGraphRequest = function mergeGraphRequest(graph) {
    var summation = 'thisPeriod';

    return function (_ref) {
      var breakdown = _ref.breakdown,
          preset = _ref.preset,
          startDate = _ref.startDate,
          endDate = _ref.endDate,
          providerId = _ref.providerId,
          organizationId = _ref.organizationId,
          scale = _ref.scale,
          scaleScorerId = _ref.scaleScorerId;

      if (preset) {
        return Ember.merge({
          breakdown: breakdown,
          scale: scale,
          scaleScorerId: scaleScorerId,
          preset: preset,
          summation: summation,
          providerId: providerId,
          organizationId: organizationId,
          interval: 'month'
        }, graph);
      } else if (startDate && endDate) {
        return Ember.merge({
          breakdown: breakdown,
          scale: scale,
          scaleScorerId: scaleScorerId,
          startDate: startDate,
          endDate: endDate,
          summation: summation,
          providerId: providerId,
          organizationId: organizationId,
          interval: intervalForDateRange(startDate, endDate)
        }, graph);
      }
    };
  };

  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    reports: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    tagName: 'section',
    classNames: ['report-dashboard'],

    report: null,
    reportParams: _reportParams.default.create({}),
    showSettingsDialog: false,

    dialOptions: {
      strokeWidth: 10,
      trailColor: '#eee',
      trailWidth: 10,
      duration: 100,
      svgStyle: null,
      text: {
        value: '',
        style: {
          position: 'absolute',
          left: '50%',
          top: '25%',
          padding: 0,
          margin: 0,
          transform: {
            prefix: true,
            value: 'translate(-50%, 25%)'
          }
        }
      },
      from: { color: '#E85D75' },
      to: { color: '#379392' },
      // Set default step function for all animate calls
      step: function step(state, bar) {
        var subtitle = '';
        var value = Math.round(bar.value() * 100);
        if (value === 0) {
          subtitle = 'At Risk';
          bar.setText(value + '%');
          bar.text.style.color = '#E85D75';
        } else {
          if (value <= 33) {
            subtitle = 'At Risk';
            bar.path.setAttribute('stroke', '#E85D75');
            bar.text.style.color = '#E85D75';
          } else if (value >= 34 && value <= 66) {
            subtitle = "Moderate";
            bar.path.setAttribute('stroke', '#FF715B');
            bar.text.style.color = '#FF715B';
          } else if (value >= 67) {
            bar.path.setAttribute('stroke', '#379392');
            bar.text.style.color = '#379392';
            subtitle = "High";
          }
        }
        var text = '<p class="gauge__text-percent">' + value + '%</p>  <p class="gauge__text-subtitle">' + subtitle + '</p>';
        bar.setText(text);
      }
    },

    isInternalUser: Ember.computed.alias('sessionUser.currentUser.isInternal'),

    showHeader: Ember.computed.notEmpty('report.headerComponent'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('reports.activeReport', this.get('report.shortcode'));
    },
    willDestroyElement: function willDestroyElement() {
      this.set('reports.activeReport', undefined);

      this._super.apply(this, arguments);
    },


    reportRequestData: Ember.computed('report.shortcode', 'reportParams.preset', 'reportParams.startTime', 'reportParams.endTime', 'reportParams.breakdown', 'reportParams.scale', 'reportParams.scaleScorer', 'reportParams.provider', 'reportParams.organization', 'reportParams.flagIds.[]', 'reportParams.conditionIds.[]', 'reportParams.sort', 'reportParams.sortDirection', 'reportParams.limit', function () {
      var requestData = {
        shortcode: this.get('report.shortcode'),
        providerId: this.get('reportParams.provider.id'),
        organizationId: this.get('reportParams.organization.id'),
        scaleScorerId: this.get('reportParams.scaleScorer.id'),
        sort: this.get('reportParams.sort'),
        sortDirection: this.get('reportParams.sortDirection'),
        limit: this.get('reportParams.limit')
      };

      if (this.get("reportParams.flagIds.length") > 0) {
        requestData['flagIds'] = this.get('reportParams.flagIds');
      }

      if (this.get("reportParams.conditionIds.length") > 0) {
        requestData['conditionIds'] = this.get('reportParams.conditionIds');
      }

      if (this.get('reportParams.breakdown') !== 'none') {
        requestData.breakdown = this.get('reportParams.breakdown');
      }

      if (this.get('reportParams.scale') !== 'none') {
        requestData.scale = this.get('reportParams.scale');
      }

      var preset = this.get('reportParams.preset');
      if (this.get('report.hasDateRange') && preset !== 'allTime') {
        requestData.startDate = this.get('reportParams.startTime');
        requestData.endDate = this.get('reportParams.endTime');

        if (!(requestData.startDate && requestData.endDate)) {

          if (!preset) {
            preset = this.get('report.defaultPreset');
          }
          if (preset === 'custom') {
            // Don't fetch the report if the required start and end date haven't been set!
            return;
          } else {
            // Something weird with routing prevents this from happening the usual way.
            // TODO refactor routing/filter handling to prevent this issue
            var dates = (0, _dateRangePresets.default)(preset);
            requestData.startDate = dates.startTime;
            requestData.endDate = dates.endTime;
          }
        }
      } else {
        requestData.preset = 'allTime';
      }

      return requestData;
    }),

    reportData: Ember.computed('reportRequestData', function () {
      var requestData = this.get('reportRequestData');

      if (requestData) {
        return this.store.createRecord('tabular-metric', requestData).save();
      }
    }),

    graphRequestData: Ember.computed('features.showMockAnalytics', 'report.graph', 'report.mockGraph', 'reportRequestData', function () {
      var graphConfig = this.get('report.graph');

      if (this.get("features.showMockAnalytics") && this.get("report.mockGraph")) {
        graphConfig = this.get("report.mockGraph");
      }

      var requestData = this.get('reportRequestData');

      if (requestData && graphConfig) {
        return mergeGraphRequest(graphConfig)(requestData);
      }
    }),

    graphInterval: Ember.computed('graphRequestData', function () {
      var graphRequest = this.getWithDefault('graphRequestData', {});

      return graphRequest.interval || 'month';
    }),

    graphRequest: Ember.computed('graphRequestData', function () {
      var _this = this;

      var graphRequest = this.get('graphRequestData');

      if (graphRequest) {
        var graph = this.store.createRecord('metric-datum', graphRequest);

        if (typeof graphRequest.compute === 'function') {
          var compute = graphRequest.compute.bind(graphRequest);
          var promise = new Ember.RSVP.Promise(function (resolve, reject) {
            _this.get('reportData').then(function (reportData) {
              graph.set('results', compute(reportData));

              resolve(graph);
            }, reject);
          });

          return _emberData.default.PromiseObject.create({
            promise: promise
          });
        } else {
          return graph.save();
        }
      }
    }),
    graphData: Ember.computed.alias('graphRequest.results'),

    dialCompleteValue: Ember.computed('report.dial', 'reportData.isFulfilled', 'reportData.footerData', function () {
      if (this.get("features.showMockAnalytics") && this.get("report.mockDial")) {
        var reqData = this.get('reportRequestData');
        return this.get("report.mockDial").compute(reqData);
      }

      if (!this.get('reportData.isFulfilled')) {
        return;
      }

      var column = this.get('report.dial.footerColumn');
      if (column) {
        return this.get('reportData.footerData.' + column) || 0.0;
      }
    }),
    dialCompleteClass: Ember.computed('dialCompleteValue', function () {
      var value = this.get('dialCompleteValue');

      if (value <= 0.4) {
        return 'danger';
      } else if (value < 0.7) {
        return 'caution';
      } else {
        return 'good';
      }
    }),

    showCompleteDial: Ember.computed.notEmpty('dialCompleteValue'),

    serverSideSort: Ember.computed.alias('report.serverSideSort'),

    actions: {
      toggleSettingsDialog: function toggleSettingsDialog() {
        this.set('showSettingsDialog', !this.get('showSettingsDialog'));
      },

      // The drilldown report is set at a higher level so we need to do this to make the help available
      setDrilldownReport: function setDrilldownReport(report) {
        this.set("drilldownReport", report);
      }
    }
  });
});