define('frontend/models/active-configuration-value', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    value: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    code: _emberData.default.attr('string'),
    configurationProperty: _emberData.default.belongsTo('configurationProperty'),
    configurationValue: _emberData.default.belongsTo('configurationValue'),
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode'),
    isDefault: Ember.computed.empty('configurationValue.id'),
    displayValue: Ember.computed('value', 'detailsOnExpand', function () {
      if (this.get('detailsOnExpand')) {
        return 'Expand to view';
      }

      return this.get('value');
    }),
    detailsOnExpand: Ember.computed('configurationProperty.valueType', function () {
      var valueType = this.get('configurationProperty.valueType');

      return valueType === 'translated_text' || valueType === 'json';
    })
  });
});