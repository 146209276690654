define('frontend/pods/components/reporting/report-table-client-sort/component', ['exports', 'frontend/pods/components/reporting/report-table/component', 'frontend/pods/components/reporting/report-table/complex-sort-comparator', 'frontend/pods/components/reporting/report-table/template'], function (exports, _component, _complexSortComparator, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,

    // This observer approach to a computed property resolves an IE 11 bug where sorting would crash
    // the browser. The issue appears to be related to Ember re-rendering row components and either
    // getting confused with the sorted data, or encountering a memory leak of some sort. An
    // incredibly hacky solution to this problem is to immediately set the sorted to an empty array
    // and schedule a function to update with the real data (sorted or not).
    sortedDataObserver: Ember.on('init', Ember.observer('data', 'sortOn', 'sortDirection', function () {
      var _this = this;

      var sortOn = this.get('sortOn');
      var direction = this.get('sortDirection');
      var data = this.getWithDefault('data', []);
      if (!sortOn) {
        sortOn = this.getWithDefault('report.headers', []).findBy('propertyName', this.get('reportDefinition.defaultSort'));
        direction = this.get('reportDefinition.defaultSortDirection');
      }

      this.setProperties({
        sortedData: [],
        isSorting: true
      });

      Ember.run.next(this, function () {
        var sortedData = data;

        if (sortOn) {
          // We slice before sorting so the original order is saved (sort mutates the array directly).
          sortedData = data.slice().sort((0, _complexSortComparator.default)(sortOn, direction));
        }

        _this.setProperties({
          sortedData: sortedData,
          isSorting: false
        });
      });
    })),

    onSort: function onSort(column) {
      if (this.get('sortOn.id') === column.id) {
        if (this.get('sortDirection') === 'asc') {
          this.set('sortDirection', 'desc');
        } else {
          this.set('sortDirection', 'asc');
        }
      } else {
        this.setProperties({
          sortOn: column,
          sortDirection: 'asc'
        });
      }
    }
  });
});