define('frontend/pods/components/common/graphs/horizontal-bar-legend/component', ['exports', 'd3', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3'], function (exports, _d, _d3Support, _marginConvention, _d2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    classNames: ['bar-chart'],
    defaultMargin: 0,

    intl: Ember.inject.service(),
    screen: Ember.inject.service(),

    legend: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var legend = selection.append("g").classed("legend", true);

        var circleEntry = legend.append('g').attr('class', 'circleEntry');
        circleEntry.append("circle").attr("class", "symbol prior dot");
        circleEntry.append('text').attr('class', 'description');

        var barEntry = legend.append('g').attr('class', 'currentValue');
        barEntry.append("polygon").attr("class", "symbol");
        barEntry.append('text').attr('class', 'description');
      },
      update: function update(selection) {
        var intl = this.get('intl');

        var points = '0,0 8,0 1,8 1,10 8,18 -8,18 -1,10 -1,10 -8,0';

        selection.selectAll(".currentValue .symbol").attr('points', points).attr('transform', 'translate(10, 0)');
        selection.selectAll(".currentValue .description").attr('x', 26).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.bar'));

        selection.selectAll(".circleEntry .symbol").attr('transform', 'translate(135, 0)').attr('cx', 10).attr('cy', 10).attr('r', 6);
        selection.selectAll(".circleEntry .description").attr('transform', 'translate(135, 0)').attr('x', 20).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.comparison'));
      }
    }),

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');

      selection.each(function () {
        var selection = _d.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');

        context.legend(selection);
      });
    }
  });
});