define('frontend/mixins/patient-unauthenticated-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session').get('isAuthenticated')) {
        this.set('session.silentInvalidate', true);
        this.get('sessionUser').logout();
      }

      return this._super.apply(this, arguments);
    }
  });
});