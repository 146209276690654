define('frontend/pods/components/provider/patient-sessions/alter-scales-form/component', ['exports', 'frontend/pods/components/provider/assessment/scale-info-drawer/component', 'frontend/mixins/error-printer'], function (exports, _component, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.ScaleDrawerMixin, _errorPrinter.default, {
    classNames: ['text-center'],
    metrics: Ember.inject.service(),
    metricEventName: 'provider.assessment.alterScales.selectScale',
    preload: Ember.inject.service(),
    alteredScales: [],
    scales: Ember.computed("rowContext.scales", 'preload.independentScales', function () {
      if (this.get('rowContext.scales')) {
        return this.get("rowContext.scales");
      }

      return this.get('preload.independentScales');
    }),
    usedScales: Ember.computed.alias('session.assessmentReport.cachedScales'),
    buttonsDisabled: Ember.computed('alteredScales.[]', 'isSaving', function () {
      return Ember.isEmpty(this.get("alteredScales")) || this.get("isSaving");
    }),

    _alterScales: function _alterScales(collectionToChange, metric, alterPlan) {
      var _this = this;

      var requestParams = { patientSession: this.get('session'), alterPlan: alterPlan };
      requestParams[collectionToChange] = this.get('alteredScales');

      var request = this.get("store").createRecord('assessment/alterReportScalesRequest', requestParams);
      this.set('errorMessage', null);

      var onSuccess = function onSuccess(result) {
        _this.set('isSaving', false);
        _this.get("metrics").trackEvent({ event: metric, patientSessionId: _this.get('session.id'), scaleIds: _this.get('alteredScales').mapBy('id') });
        _this.get('onSuccess')(result, alterPlan);
      };

      var onFailure = function onFailure(reason) {
        _this.set('isSaving', false);
        _this.set('errorMessage', _this.printError(reason));
      };

      this.setProperties({ errorMessage: null, isSaving: true });

      return request.save().then(onSuccess, onFailure);
    },


    actions: {
      setScales: function setScales(scales) {
        this.set("alteredScales", scales);
      },
      cancel: function cancel() {
        this.get('onCancel')();
      },
      addToSession: function addToSession() {
        return this._alterScales('scalesToAdd', 'provider.assessment.alterScales.scalesAdded', false);
      },
      createPlan: function createPlan() {
        return this._alterScales('scalesToAdd', 'provider.assessment.alterScales.scalesAddedWithPlan', true);
      },
      removeFromSession: function removeFromSession() {
        return this._alterScales('scalesToRemove', 'provider.assessment.alterScales.scalesRemoved', false);
      },
      removeFromPlan: function removeFromPlan() {
        return this._alterScales('scalesToRemove', 'provider.assessment.alterScales.scalesRemovedFromPlan', true);
      }
    }
  });
});