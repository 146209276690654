define('frontend/pods/components/survey/taking-local/common/questions/checkbox-question/component', ['exports', 'lodash', 'frontend/mixins/survey-metric', 'frontend/mixins/survey/question-component-mixin'], function (exports, _lodash, _surveyMetric, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CheckItem = Ember.Object.extend({
    isChecked: Ember.computed('responseItem.active', function () {
      return this.get("responseItem.active");
    })
  });

  exports.default = Ember.Component.extend(_surveyMetric.default, _questionComponentMixin.default, {
    tagName: '',
    optionResponses: [],
    createResponsesAutomatically: false,
    otherResponseItem: undefined,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      //we don't auto-create response-items here
      var responseSet = this.get('response').getResponseSetForQuestion(this.get('question'), this.get('module'));
      this.set('responseSet', responseSet);
      //we make an array of ember objects to represent each option and whether it is checked
      this.set('optionResponses', []);

      // For now, the 'other' option is a bit hacky. We're looking for an option which isn't one of the given values.
      var mainValues = this.get("question.options").mapBy('value');

      var otherResponseItem = _lodash.default.find(responseSet.responseItems, function (item) {
        return !item.get('optionId') && !mainValues.includes(item.get('value')) && !item.get("overridden") && !item.get("isDeleted");
      });

      $.each(this.get('question').options, function (index, option) {

        var responseItem = _lodash.default.find(responseSet.responseItems, function (item) {
          //don't use strict equality since we're converting ints to strings
          if (option.id) {
            return item.get('optionId') == option.id && !item.get("overridden") && !item.get("isDeleted"); // jshint ignore:line
          } else {
            return item.get('value') === option.value && !item.get("overridden") && !item.get("isDeleted");
          }
        });
        if (option.properties && option.properties.other) {
          if (responseItem) {
            otherResponseItem = responseItem;
          } else {
            responseItem = otherResponseItem;
          }
        }

        _this.get('optionResponses').pushObject(CheckItem.create({
          opt: option,
          responseItem: responseItem
        }));
      });

      this.set('otherResponseItem', otherResponseItem);
    },
    _updateSimpleValue: function _updateSimpleValue() {
      var responseSet = this.get('responseSet');

      var newValues = responseSet.responseItems.filterBy('active').mapBy('value');

      Ember.set(responseSet, "simpleValue", newValues);
    },
    _toggleOptionNormal: function _toggleOptionNormal(optionResponse) {
      var responseSet = this.get('responseSet');
      if (!optionResponse.get('isChecked')) {
        var responseItem = optionResponse.get("responseItem");

        // If you just unclick and click again, only make one item.
        if (responseItem && responseItem.get("isDeleted") && responseItem.get("hasDirtyAttributes")) {
          responseItem.rollbackAttributes();
        } else {
          responseItem = this.store.createRecord('survey/response-item', {
            response: this.get('response'),
            questionId: this.get('question').id,
            optionId: optionResponse.get('opt.id'),
            questionMnemonic: this.get('question').shortname,
            questionDomain: this.get('module.question_domain'),
            value: optionResponse.get('opt.value')
          });
          if (optionResponse.get('opt.properties.other')) {
            this.set('otherResponseItem', responseItem);
            responseItem.set('value', '');
          }
        }

        if (this.get("trackTime")) {
          responseItem.startTrackingTime();
        }

        responseSet.responseItems.push(responseItem);
        optionResponse.set('responseItem', responseItem);
      } else {
        var _responseItem = optionResponse.get('responseItem');

        if (!_responseItem.get("isDeleted")) {
          _responseItem.deleteRecordWithDebounce();
        }
        optionResponse.notifyPropertyChange('isChecked');
      }

      this._updateSimpleValue();
    },
    _toggleOptionBulk: function _toggleOptionBulk(optionResponse) {
      // When unchecking,
      // If persisted: do not delete, instead mark as overridden
      // if not persisted: normal.

      var responseSet = this.get('responseSet');
      if (!optionResponse.get('isChecked')) {
        // When checking:
        // if overridden item exists with modifications, use that.
        // else go as normal
        var responseItem = optionResponse.get("responseItem");

        if (responseItem && responseItem.get("overridden") && responseItem.get("hasDirtyAttributes")) {
          responseItem.rollbackAttributes();
        } else {
          responseItem = this.store.createRecord('survey/response-item', {
            response: this.get('response'),
            questionId: this.get('question').id,
            optionId: optionResponse.get('opt.id'),
            questionMnemonic: this.get('question').shortname,
            questionDomain: this.get('module.question_domain'),
            value: optionResponse.get('opt.value')
          });
          if (optionResponse.get('opt.properties.other')) {
            this.set('otherResponseItem', responseItem);
            responseItem.set('value', '');
          }
        }

        responseSet.responseItems.push(responseItem);
        optionResponse.set('responseItem', responseItem);
      } else {
        var _responseItem2 = optionResponse.get('responseItem');

        if (_responseItem2.get("isNew")) {
          _responseItem2.deleteRecordWithDebounce();
        } else {
          _responseItem2.set("overridden", true);
        }
      }

      this._updateSimpleValue();
    },


    actions: {
      toggleOption: function toggleOption(optionResponse) {
        if (this.get("mode") === 'bulk') {
          this._toggleOptionBulk(optionResponse);
        } else {
          this._toggleOptionNormal(optionResponse);
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      next: function next() {
        var _this2 = this;

        $.each(this.get('responseSet').responseItems, function (index, item) {
          if (_this2.get("trackTime")) {
            item.stopTrackingTime();
          }

          if (_this2.get("saveOnSubmit")) {
            // There is a bug in ember data <= 2.14.1 which means that any persisted deleted records stop the save of other
            // records. Therefore for now, don't save any records we already know are deleted.
            // TODO: remove after we upgrade to ember 2.14.1
            if (item.get("isDeleted") && !item.get("hasDirtyAttributes")) {
              return;
            }

            if (!_this2.get("previewMode")) {
              item.saveWithDebounce();
            }
          }
        });
        this.sendAction('next');
      }
    }
  });
});