define("frontend/adapters/care/entity-measurement-plan", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=measurement-plan.measurement-plan-scales";
      return value;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=measurement-plan.measurement-plan-scales";
      return value;
    }
  });
});