define('frontend/helpers/moment-duration-exact', ['exports', 'moment', 'ember-moment/helpers/moment-duration'], function (exports, _moment, _momentDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeExact = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var humanizeExact = function humanizeExact(duration) {
    var hWas = _moment.default.relativeTimeThreshold('h');
    var dWas = _moment.default.relativeTimeThreshold('d');
    var mWas = _moment.default.relativeTimeThreshold('M');

    // Use internal values so we know if we were set at the day or month level.
    var days = duration._days;
    var months = duration._months;

    if (duration.asMilliseconds() === 0) {
      return "immediately";
    }

    if (days) {
      if (days % 7 === 0) {
        if (days === 7) {
          return '1 week';
        } else {
          return days / 7 + ' weeks';
        }
      }
      _moment.default.relativeTimeThreshold('d', 999999);
    }

    if (months) {
      _moment.default.relativeTimeThreshold('M', 999999);
    }

    _moment.default.relativeTimeThreshold('h', 75);

    var result = duration.humanize();

    _moment.default.relativeTimeThreshold('h', hWas);
    _moment.default.relativeTimeThreshold('d', dWas);
    _moment.default.relativeTimeThreshold('M', mWas);

    return result;
  };

  exports.humanizeExact = humanizeExact;
  exports.default = _momentDuration.default.extend({
    compute: function compute(params, _ref) {
      var locale = _ref.locale,
          timeZone = _ref.timeZone;

      this._super.apply(this, arguments);
      var momentService = Ember.get(this, 'moment');

      if (!params || params && params.length > 2) {
        throw new TypeError('ember-moment: Invalid Number of arguments, at most 2');
      }

      var hWas = _moment.default.relativeTimeThreshold('h');
      var dWas = _moment.default.relativeTimeThreshold('d');
      var mWas = _moment.default.relativeTimeThreshold('M');

      _moment.default.relativeTimeThreshold('h', 75);
      _moment.default.relativeTimeThreshold('d', 999999);
      _moment.default.relativeTimeThreshold('M', 999999);

      var result = momentService.moment(_moment.default.duration.apply(_moment.default, _toConsumableArray(params)));

      var finished = humanizeExact(this.morphMoment(result._i, { locale: locale, timeZone: timeZone }));

      _moment.default.relativeTimeThreshold('h', hWas);
      _moment.default.relativeTimeThreshold('d', dWas);
      _moment.default.relativeTimeThreshold('M', mWas);

      return finished;
    }
  });
});