define('frontend/utils/assessment/analytics', ['exports', 'frontend/utils/assessment/scoremaps', 'frontend/utils/assessment/symptoms'], function (exports, _scoremaps, _symptoms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var analytics = {

    truncate: function truncate(number) {
      return number > 0 ? Math.floor(number * 100.0) / 100 : Math.ceil(number * 100.0) / 100;
    },

    formatPercentile: function formatPercentile(value) {
      return Math.min(99, (value * 100).toFixed(0));
    },


    getSymptomScoreClass: function getSymptomScoreClass(symptom) {
      var score = symptom.score;
      if (symptom.isSkill) {
        if (score < -1.5) {
          return 'severe';
        } else if (score < -0.5) {
          return 'moderate';
        } else if (score < 0.5) {
          return 'low';
        } else {
          return 'none';
        }
      }
      if (score > 1.5) {
        return 'severe';
      } else if (score > 0.5) {
        return 'moderate';
      } else {
        return 'none';
      }
    },

    getAnswerScoreClass: function getAnswerScoreClass(answer) {
      var score = answer.get('score').genericScore;
      if (answer.get('shownButNotAnswered')) {
        return 'severe';
      }
      if (score > 6) {
        return 'severe';
      } else if (score > 5) {
        return 'modsevere';
      } else if (score > 3) {
        return 'moderate';
      } else if (score > 1) {
        return 'low';
      } else {
        return 'none';
      }
    },

    getAnswerScoreClassNew: function getAnswerScoreClassNew(answer, scale) {
      var questionShortname = answer.get('question').get('shortname');
      var questionDisplayData = answer.get("question.displayData");

      if (answer.get('shownButNotAnswered')) {
        return 'severe';
      }

      var value = answer.get("value");

      var score = null;

      if (answer.get('normalizedScore')) {
        score = answer.get('normalizedScore');
      } else if (answer.get('option.normalizedScore')) {
        score = answer.get('option.normalizedScore');
      } else if (questionDisplayData && questionDisplayData.answer_class_map) {
        // New style inline score map
        score = questionDisplayData.answer_class_map[value];

        if (!score) {
          console.log('WARNING: question ' + questionShortname + ' has an inline score map with no value for: ' + value);
        }
      } else if (questionDisplayData && questionDisplayData.answer_class_definition) {
        // Reference to a resuable scale score map.

        var scoreMap = scale.get('displayData.answer_class_maps')[questionDisplayData.answer_class_definition];

        if (!scoreMap) {
          console.log('WARNING: question ' + questionShortname + ' references non-existant scale map called: ' + questionDisplayData.answer_class_definition);
        }

        score = scoreMap[value];

        if (!score) {
          console.log('WARNING: question ' + questionShortname + ' using score map with no value for: ' + value);
        }
      } else {
        // There are no score maps
        return '';
      }

      // If you use a mnemonic directly, just return it
      if (typeof score === 'string') {
        return score;
      }

      if (score > 6) {
        return 'severe';
      } else if (score > 5) {
        return 'modsevere';
      } else if (score > 3) {
        return 'moderate';
      } else if (score > 1) {
        return 'low';
      } else {
        return 'none';
      }
    },

    computeQuestionScore: function computeQuestionScore(answer) {
      var qScore = { answered: false };
      for (var symptom in _symptoms.default) {
        qScore[symptom] = 0;
      }
      if (!answer) {
        return qScore;
      }
      var genericScore = 0; //if the question doesn't have a symptom-specific map, we give it a generic score across all symptoms  
      var value = answer.get('value');
      if (typeof value === 'undefined' || value === null || value === "") {
        return qScore;
      }
      qScore.answered = true;
      var questionId = answer.get('question').get('shortname');
      var questionScoreMap = _scoremaps.default[questionId];
      var questionType = answer.get('question').get('subtype');
      if (questionType === 'checkbox') {
        if (questionScoreMap) {
          $.each(value, function () {
            if (questionScoreMap[this]) {
              //add the value for each symptom to the score 
              for (symptom in questionScoreMap[this]) {
                qScore[symptom] += questionScoreMap[this][symptom] ? questionScoreMap[this][symptom] : 0;
              }
            } else {
              console.log('WARNING: no score map for checkbox answer ' + questionId + ': ' + this);
            }
          });
        } else {
          console.log('WARNING: no score map for checkbox question ' + questionId);
          genericScore = value.length * 3;
        }
        console.log("CHECKBOX SCORE" + questionId + ":  " + qScore.value);
      } else {
        if (questionScoreMap) {
          if (typeof questionScoreMap[value] !== 'undefined') {
            if (typeof questionScoreMap[value] === 'number') {
              genericScore = questionScoreMap[value];
            } else {
              for (symptom in questionScoreMap[value]) {
                qScore[symptom] += questionScoreMap[value][symptom] ? questionScoreMap[value][symptom] : 0;
              }
            }
          } else {
            console.log('WARNING: question ' + questionId + ' has a score map with no value for: ' + value);
          }
        } else {
          var scoreMap = _scoremaps.default.default;
          if (scoreMap[value] || scoreMap[value] === 0) {
            genericScore = scoreMap[value];
          } else {
            console.log('WARNING: question ' + questionId + ' has no score map and no default exists for: ' + value);
          }
        }
      }
      if (genericScore) {
        qScore.genericScore = genericScore;
        for (symptom in _symptoms.default) {
          qScore[symptom] = genericScore;
        }
      }
      return qScore;
    },

    getSeverityMnemonic: function getSeverityMnemonic(value, scale) {
      var isBeneficial = scale.get("isBeneficial");

      if (isBeneficial) {
        if (value < -1.5) {
          return 'severe';
        } else if (value < -0.5) {
          return 'moderate';
        } else if (value < 0.5) {
          return 'low';
        } else {
          return 'none';
        }
      } else {
        if (value > 1.5) {
          return 'severe';
        } else if (value > 0.5) {
          return 'moderate';
        } else {
          return 'none';
        }
      }
    },

    GetZPercent: function GetZPercent(z) {
      //z == number of standard deviations from the mean

      //if z is greater than 6.5 standard deviations from the mean
      //the number of significant digits will be outside of a reasonable 
      //range
      if (z < -6.5) {
        return 0.0;
      }
      if (z > 6.5) {
        return 1.0;
      }

      var factK = 1;
      var sum = 0;
      var term = 1;
      var k = 0;
      var loopStop = Math.exp(-23);
      while (Math.abs(term) > loopStop) {
        term = 0.3989422804 * Math.pow(-1, k) * Math.pow(z, k) / (2 * k + 1) / Math.pow(2, k) * Math.pow(z, k + 1) / factK;
        sum += term;
        k++;
        factK *= k;
      }
      sum += 0.5;

      return sum;
    },

    ordinalSuffix: function ordinalSuffix(i) {
      var j = i % 10,
          k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    }

  };

  exports.default = analytics;
});