define('frontend/pods/provider/admin/users/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    queryParams: {
      hideDisabled: {}
    }
  });
});