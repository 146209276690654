define('frontend/pods/provider/billing/charges/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedReportParam(name) {
    return Ember.computed('reportParam', 'reportParams.' + name, {
      get: function get() {
        return this.get('reportParams.' + name);
      },
      set: function set(key, value) {
        return this.get("reportParams").set(name, value);
      }
    });
  }

  exports.default = Ember.Controller.extend({
    queryParams: {
      reportTimePreset: 'preset',
      reportTimeStart: 'start',
      reportTimeEnd: 'end',
      reportPath: 'path',
      reportStatuses: 'statuses',
      reportSort: 'sort',
      reportSortDirection: 'sortDirection',
      reportPage: 'sortDirection'
    },

    reportParams: Ember.computed(function () {
      // Set defaults here
      // Default path is set differently because it depends on data loading of current institute
      return Ember.Object.create({
        statuses: ['billable'],
        preset: 'allTime'
      });
    }),

    entityTreeNode: Ember.computed('reportPath', {
      get: function get() {
        var path = this.get('reportPath');

        if (!path) {
          return this.get("rootPath");
        }

        return this.get("store").findRecord('entityTreeNode', path);
      },
      set: function set(key, value) {
        Ember.set(this, 'reportPath', value.get('path'));
        return value;
      }
    }),

    reportPath: computedReportParam('path'),
    reportStatuses: computedReportParam('statuses'),
    reportTimePreset: computedReportParam('preset'),
    reportTimeStart: computedReportParam('start'),
    reportTimeEnd: computedReportParam('end'),

    resetQueryParams: function resetQueryParams() {
      this.setProperties({
        noRefresh: true,
        reportTimePreset: null,
        reportTimeStart: null,
        reportTimeEnd: null,
        reportSort: null,
        reportSortDirection: null,
        reportLimit: null,
        reportPage: null,
        reportPath: null
      });
      this.notifyPropertyChange('reportParams');
    },
    _parseArray: function _parseArray(key, defaultValue) {
      var value = this.get(key);

      if (!value) {
        return defaultValue;
      }

      if (value.split) {
        return value.split(',');
      }

      return value;
    },


    actions: {
      reportParamsRefresh: function reportParamsRefresh() {
        var params = this.get('reportParams');

        this.setProperties({
          noRefresh: true,
          reportTimePreset: params.get('preset'),
          reportTimeStart: params.get('startTime'),
          reportTimeEnd: params.get('endTime'),
          reportSort: params.get('sort'),
          reportSortDirection: params.get('sortDirection'),
          reportLimit: params.get('limit'),
          reportPage: params.get('page'),
          reportPath: params.get('path')
        });
      },
      randomButton: function randomButton() {
        this.set("paramTest", Math.floor(Math.random() * 100));
      },
      changeAltParam: function changeAltParam() {
        this.set("paramOtherTest", Ember.Object.create({ alt: Math.floor(Math.random() * 100) }));
      }
    }
  });
});