define('frontend/models/institute-payor', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    name: _emberData.default.attr('string'),
    primaryId: _emberData.default.attr('string'),
    institute: _emberData.default.belongsTo('currentInstitute'),
    payor: _emberData.default.belongsTo('payor', { inverse: null }),
    billingPayorContracts: _emberData.default.hasMany('billing/payorContract')
  });
});