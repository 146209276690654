define('frontend/pods/components/internal/scales/scale-table/scale-filters/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    options: ['draft', 'awaiting_review', 'rejected', 'review_in_progress', 'awaiting_publication', 'published', 'obsolete'],
    actions: {
      setValue: function setValue(value) {
        this.get("onFilterChange")(value);
        this.set("value", value);
      }
    }
  });
});