define('frontend/pods/components/reporting/table-column/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function percentSuccessClass(value) {
    var num = parseInt(value, 10);

    if (isNaN(num)) {
      return undefined;
    }

    if (num < 33) {
      return 'threshold-low';
    } else if (num < 66) {
      return 'threshold-med';
    } else {
      return 'threshold-high';
    }
  }

  function userActivityClass(value, className) {
    var num = parseInt(value, 10);

    if (isNaN(num)) {
      return undefined;
    }

    // Make the weekends darker
    if (className === 'grey-column') {
      return 'activity-inactive';
    }

    if (num > 0) {
      return 'activity-good';
    } else {
      return 'activity-bad';
    }
  }

  function alertNonzeroClass(value) {
    var num = parseInt(value, 10);

    if (isNaN(num)) {
      return undefined;
    }
    if (num == 0) {
      return 'activity-good';
    } else {
      return 'activity-bad';
    }
  }

  function positiveCountClass(value) {
    var num = parseInt(value, 10);

    if (isNaN(num)) {
      return 'activity-bad';
    }

    if (num > 0) {
      return 'activity-good';
    } else {
      return 'activity-bad';
    }
  }

  var componentMapping = {
    adherence: 'reporting/columns/adherence-history',
    'date-range': 'reporting/columns/date-range',
    'classified-bar': 'reporting/columns/classified-bar',
    'patient-link': 'reporting/columns/patient-link',
    'care-episode-status': 'reporting/columns/care-episode-status',
    'outcome-progress': 'reporting/columns/outcome-progress',
    'outcome-delta-session': 'reporting/columns/outcome-delta-session',
    'attendance-rate': 'reporting/columns/attendance-rate',
    'patient-flags': 'reporting/columns/patient-flags',
    'condition-badges': 'reporting/columns/condition-badges',
    'combined-badges': 'reporting/columns/combined-badges',
    'attendance-history': 'reporting/columns/attendance-history',
    'badged-indicator': 'reporting/columns/badged-indicator',
    'provider-role': 'reporting/columns/provider-role',
    'percent-bar': 'reporting/columns/percent-bar',
    'action-links': 'reporting/columns/action-links',
    'scale-value': 'reporting/columns/scale-value',
    'scale-threshold-breakdown': 'reporting/columns/scale-threshold-breakdown'
  };

  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNameBindings: ['typeClass', 'columnStyle'],
    attributeBindings: ['statsStyle:style', 'statsTitle:title'],

    statsStyle: Ember.computed(function () {
      var data = this.get("statData");

      if (!data) {
        return null;
      }

      var svg = this._createSvg(data);
      var encoded = this._encodeSVG(svg);

      return Ember.String.htmlSafe('background-image: url("data:image/svg+xml,' + encoded + '"); background-repeat: no-repeat;');
    }),

    _createSvg: function _createSvg(parts) {
      var indexes = parts.map(function (p, i) {
        return i;
      });
      //const xScale = d3.scale.linear.domain([0, parts.length]).range([0, 100]);
      var xScale = d3.scale.ordinal().domain(indexes).rangeRoundBands([0, 100], .1);
      //const xScale = d3.scale.linear().domain(parts).rangeRoundBands([0, 100], .1);
      var width = xScale.rangeBand();
      var yScale = d3.scale.linear().domain([0, Math.max.apply(Math, _toConsumableArray(parts))]).range([0, 28]);
      var rects = parts.map(function (part, index) {
        return '<rect x=\'' + xScale(index) + '\' width=\'' + width + '\' y=\'' + (30 - yScale(part)) + '\' height=\'' + yScale(part) + '\'></rect>';
      });

      return '\n      <svg style=\'fill:#ddd;\' xmlns=\'http://www.w3.org/2000/svg\' preserveAspectRatio=\'none\' viewBox=\'0 0 100 30\'>\n        ' + rects.join('') + '\n      </svg>\n    ';
    },
    _encodeSVG: function _encodeSVG(data) {
      var symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
      data = data.replace(/>\s{1,}</g, "><");
      data = data.replace(/\s{2,}/g, " ");

      return data.replace(symbols, encodeURIComponent);
    },


    statsTitle: Ember.computed(function () {
      var data = this.get("statData");
      var breaks = this.get("statDataBreaks");

      if (!data || !breaks) {
        return null;
      }

      var lastBreak = null;
      var lines = _lodash.default.zip(data, breaks).map(function (_ref, index) {
        var _ref2 = _slicedToArray(_ref, 2),
            datum = _ref2[0],
            br = _ref2[1];

        var text = null;
        if (lastBreak === null) {
          text = '< ' + br + ': ' + datum;
        } else if (index >= data.length - 1) {
          text = '> ' + lastBreak + ': ' + datum;
        } else {
          text = lastBreak + ' to ' + br + ': ' + datum;
        }

        lastBreak = br;
        return text;
      });

      return lines.join("\n");
    }),

    typeClass: Ember.computed('column.type', 'value', 'column.className', function () {
      var type = this.get('column.type');
      var value = this.get('value');
      var className = this.get('column.className');
      var displayType = this.getWithDefault('column.displayType', type);
      switch (displayType) {
        case 'percent-success':
          return percentSuccessClass(value);
        case 'user-activity':
          return userActivityClass(value, className);
        case 'positive-count':
          return positiveCountClass(value);
        case 'alert-nonzero':
          return alertNonzeroClass(value);
      }
    }),

    statData: Ember.computed('column.statColumn', 'row', function () {
      var statColumn = this.get("column.statColumn");
      var row = this.get('row');

      if (statColumn && row) {
        return row[statColumn];
      } else {
        return null;
      }
    }),

    statDataBreaks: Ember.computed('column.statColumn', 'row', function () {
      var statColumn = this.get("column.statColumn");
      var row = this.get('row');

      if (statColumn && row) {
        return row[statColumn + 'Breaks'];
      } else {
        return null;
      }
    }),

    showStats: Ember.computed.notEmpty('statData'),

    columnStyle: Ember.computed.alias('column.className'),

    customComponent: Ember.computed('column.type', function () {
      var displayType = this.getWithDefault('column.displayType', this.get('column.type'));
      return componentMapping[displayType];
    }),

    value: Ember.computed('row', 'column.propertyName', function () {
      var row = this.get("row");

      if (row) {
        return row[this.get("column.propertyName")];
      } else {
        return null;
      }
    }),

    comparisonValue: Ember.computed('comparisonRow', 'column.propertyName', function () {
      var row = this.get("comparisonRow");

      if (row) {
        return row[this.get("column.propertyName")];
      } else {
        return null;
      }
    }),

    comparisonChange: Ember.computed('value', 'comparisonValue', function () {
      var _getProperties = this.getProperties('value', 'comparisonValue'),
          value = _getProperties.value,
          comparisonValue = _getProperties.comparisonValue;

      if (value && comparisonValue) {
        return value - comparisonValue;
      } else {
        return null;
      }
    }),

    valueNotApplicable: Ember.computed('value', function () {
      var value = this.get('value');
      return value === undefined || value === null;
    })
  });
});