define('frontend/models/severity-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    hasCurrentComplexity: Ember.computed('currentStats.complexity', function () {
      return this.get('currentStats.complexity') === 0 || this.get('currentStats.complexity');
    }),

    hasCurrentSeverityTrend: Ember.computed('currentStats.severity_trend', function () {
      return this.get('currentStats.severity_trend') === 0 || this.get('currentStats.severity_trend');
    }),

    complexityClass: Ember.computed('currentStats.complexity', function () {
      var complexity = this.get('currentStats.complexity');
      if (complexity <= 0) {
        return 'none';
      } else if (complexity <= 2) {
        return 'low';
      } else if (complexity <= 6) {
        return 'moderate';
      } else if (complexity <= 10) {
        return 'severe';
      }
      return 'undefined';
    }),

    // This is currently duplicated in patient-current-session.
    severityClass: Ember.computed('currentStats.severity', function () {
      var severity = this.get('currentStats.severity');
      if (severity === null || typeof severity === 'undefined') {
        return 'undefined';
      } else if (severity <= 0) {
        return 'none';
      } else if (severity <= 4) {
        return 'low';
      } else if (severity <= 7) {
        return 'moderate';
      } else if (severity <= 10) {
        return 'severe';
      }
      return 'undefined';
    }),

    absoluteSeverityTrend: Ember.computed('currentStats.severity_trend', function () {
      return Math.abs(this.get('currentStats.severity_trend'));
    }),

    severityTrendClass: Ember.computed('currentStats.severity_trend', function () {
      var trend = this.get('currentStats.severity_trend');
      if (trend <= -1) {
        return 'decreasing';
      } else if (trend <= 1) {
        return 'stable';
      } else if (trend > 1) {
        return 'increasing';
      }
      return 'none';
    })
  });
});