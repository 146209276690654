define('frontend/pods/patient/details-login/route', ['exports', 'frontend/mixins/patient-unauthenticated-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _patientUnauthenticatedRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, _patientUnauthenticatedRouteMixin.default, {
    queryParams: {
      invitationId: {}
    },
    model: function model(params) {
      return Ember.Object.create({
        invitationId: params.invitationId
      });
    }
  });
});