define('frontend/metrics-adapters/mirah', ['exports', 'ember-metrics/metrics-adapters/base', 'lodash', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _base, _lodash, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),
    toStringExtension: function toStringExtension() {
      return 'mirah';
    },
    init: function init() {
      // Note there is an initializer to inject the router in elsewhere.
    },
    identify: function identify() {},
    trackEvent: function trackEvent(params /*, options */) {
      var userId = params.userId;
      var providerId = params.providerId;
      var patientId = params.patientId;
      var relatedPersonId = params.relatedPersonId;

      var route = this.get("router.currentRouteName");
      var url = document.location.pathname;

      var remainingKeys = _lodash.default.without(Object.keys(params), 'instituteId', 'sessionId', 'event', 'userId', 'data', 'url', 'route', 'patientId');

      var data = Ember.merge(_lodash.default.pick(params, remainingKeys), params.data);

      var analyticsEventPackage = {
        instituteId: params.instituteId,
        sessionId: params.sessionId,
        eventDate: new Date(),
        eventName: params.event,
        eventUuid: (0, _uuidHelpers.default)(),
        userId: userId,
        providerId: providerId,
        patientId: patientId,
        relatedPersonId: relatedPersonId,
        url: url,
        route: route,
        data: data
      };

      Ember.$.ajax('/analytics', {
        method: "POST",
        data: analyticsEventPackage
      });
    },
    trackPage: function trackPage(params) {
      var remainingKeys = _lodash.default.without(Object.keys(params), 'page', 'title');
      this.trackEvent(Ember.merge({ event: "pageview" }, _lodash.default.pick(params, remainingKeys)));
    },
    alias: function alias() {},
    willDestroy: function willDestroy() {
      // Do nothing
    }
  });
});