define('frontend/pods/components/scale-creation/subscale-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['templated-form-component'],
    participantOptions: [{ id: 'patient', name: 'Patient' }, { id: 'provider', name: 'Provider' }, { id: 'guardian', name: 'Guardian' }, { id: 'teacher', name: 'Teacher' }],
    questionsChanged: false,
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.isEmpty(this.get("scale.properties.questions"))) {
        this.set("scale.properties.customizeQuestions", true);
      }
    },

    actions: {
      enableCustomQuestions: function enableCustomQuestions() {
        if (!this.get("scale.properties.customizeQuestions")) {
          this.set("scale.properties.questions", null);
        }
      }
    }
  });
});