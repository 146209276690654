define('frontend/pods/provider/patients/show/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    breadCrumb: {},
    preload: Ember.inject.service(),

    metrics: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this._super.apply(this, arguments)) {
        return null;
      }

      return this.get("preload").ensurePreloads('scaleMap');
    },
    model: function model(params) {
      var patient = this.store.findRecord('patient', params.patient_id, { include: 'user,provider.user,patient-stat,care-unit-child-memberships.parent,active-care-episodes,care-episodes.organization' });

      // Ember data does a strange thing where if querying the other side of the relationship, it will get confused with the link.
      // This means if you then question the relationship from this side, it does not have a link and will instead try to refresh
      // all items in the existing collection. This hack makes sure the patient session relationship is loaded.
      patient.then(function (patientRecord) {
        patientRecord.hasMany('patientSessions').link();

        console.log("activeCareEpisodes", patientRecord.get("careEpisodes"));
      });

      return patient;
    },
    afterModel: function afterModel(model) {
      this.set("metrics.context.patientId", model.get("id"));
      this.set('breadCrumb', { title: model.get('fullName'), linkable: true, isRaw: true, crumbClass: 'root-crumb', icon: 'fa-user-circle' });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName.indexOf(this.get('routeName')) < 0) {
          var context = this.get("metrics.context");
          delete context.patientId;
        }
      }
    }
  });
});