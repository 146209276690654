define('frontend/pods/components/provider/plan/measurement-plan-scale-alternating-row/component', ['exports', 'frontend/models/care/measurement-plan-scale'], function (exports, _measurementPlanScale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var setMultiProperty = function setMultiProperty(name) {
    return Ember.computed({
      get: function get() {
        return this.get('measurementPlanScales.firstObject.' + name);
      },
      set: function set(key, value) {
        this.get("measurementPlanScales").forEach(function (mps) {
          mps.set(name, value);
        });
        return value;
      }
    });
  };

  var Blinding = ['unblinded', 'export_only'];
  var BlindingOptionBuilder = function BlindingOptionBuilder(intl) {
    return Blinding.map(function (blinding) {
      return {
        id: blinding,
        name: intl.t('model.blinding.' + blinding)
      };
    });
  };

  // This is used to set all the properties on all the rows simultaneously.
  var DispatchModel = Ember.Object.extend({
    schedulePreset: setMultiProperty('schedulePreset'),
    onsetSession: setMultiProperty('onsetSession'),
    onsetTime: setMultiProperty('onsetTime'),
    repeatSessions: setMultiProperty('repeatSessions'),
    maxRepeatInterval: setMultiProperty('maxRepeatInterval'),
    discontinueSession: setMultiProperty('discontinueSession'),
    discontinueTime: setMultiProperty('discontinueTime'),
    blinding: setMultiProperty('blinding')
  });

  exports.default = Ember.Component.extend({
    tagName: '',
    institute: Ember.inject.service(),
    isExpanded: Ember.computed('measurementPlanScale.firstObject.isNew', 'measurementPlanScale.firstObject.isClone', function () {
      return this.get('measurementPlanScale.firstObject.isNew') && !this.get("measurementPlanScale.firstObject.isClone");
    }),
    presetOptions: _measurementPlanScale.SchedulePresets,
    blindingOptions: Ember.computed(function () {
      return BlindingOptionBuilder(this.get('intl'));
    }),

    showAdvanced: Ember.computed.equal("multimodel.schedulePreset.id", 'custom'),
    advancedObserver: Ember.observer('multimodel.schedulePreset.id', function () {
      // Once we've triggered custom mode, don't allow going back to non custom mode.
      if (this.get('multimodel.schedulePreset.id') === 'custom') {
        this.set('showAdvanced', true);
      }
    }),
    actions: {
      addNewScale: function addNewScale() {
        var measurementPlan = this.get("measurementPlan");
        var priority = measurementPlan.get("measurementPlanScales.length") + 1;
        var existingScale = measurementPlan.get("measurementPlanScales.firstObject");
        var scheduleProperties = existingScale ? existingScale.getProperties('schedulePreset', 'onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime') : {};
        var newRow = this.get('store').createRecord('care/measurementPlanScale', Ember.merge(scheduleProperties, {
          measurementPlan: measurementPlan,
          alternationGroup: this.get("groupName"),
          alternationPriority: priority
        }));
        this.get('onShowScaleBrowser')(newRow);
      },
      removeScale: function removeScale(measurementPlanScale) {
        measurementPlanScale.deleteRecord();
      },
      removeGroup: function removeGroup() {
        var records = this.get("measurementPlanScales");
        records.forEach(function (record) {
          return record.deleteRecord();
        });
        if (this.get('onDelete')) {
          this.get('onDelete')(records);
        }
      }
    },

    multimodel: Ember.computed(function () {
      return DispatchModel.create({ measurementPlanScales: this.get('measurementPlanScales') });
    })
  });
});