define('frontend/routes/provider/response-list', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    initialLoad: false,
    earliestResponseDate: null,
    surveyId: 2331017,

    model: function model(params) {

      this.earliestResponseDate = params.earliestResponseDate;
      if (this.earliestResponseDate == null) {
        this.earliestResponseDate = new Date();
        this.earliestResponseDate.setDate(this.earliestResponseDate.getDate() - 3);
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('earliestResponseDate', this.earliestResponseDate);
    },

    actions: {
      loading: function loading() {
        alert("Loading data, go make some coffee.");
      }
    }
  });
});