define('frontend/pods/components/common/graphs/patient-timeline/component', ['exports', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3', 'lodash'], function (exports, _d3Support, _marginConvention, _d2, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {

    defaultMargin: 20,

    // Start the brush selecting the last 3 weeks.
    defaultBrushExtent: Ember.computed('model', 'xScale', function () {
      // If we have only 3 reports, always select everything.
      if (this.get("model").length < 4) {
        return this.get("xScale").domain();
      }

      var _get$domain = this.get("xScale").domain(),
          _get$domain2 = _slicedToArray(_get$domain, 2),
          min = _get$domain2[0],
          max = _get$domain2[1];

      return [new Date((max.getTime() + min.getTime()) / 2), max];
    }).readOnly(),

    xScale: Ember.computed('contentWidth', 'model', function () {
      var width = this.get('contentWidth');
      var extent = d3.extent(this.get('model'), function (r) {
        return r.get("effectiveDate");
      });

      var marginExtent = [d3.time.day.offset(extent[0], -3), d3.time.day.offset(extent[1], 3)];

      return d3.time.scale().domain(marginExtent).range([0, width]);
    }).readOnly(),

    yScale: Ember.computed('contentHeight', function () {
      var height = this.get('contentHeight');

      return function () {
        return height / 2;
      };
    }).readOnly(),

    playingObserver: Ember.observer('timelineModel.shouldPlay', function () {
      if (this.get("timelineModel.shouldPlay")) {
        this.set('timelineModel.playing', true);
        this.set('timelineModel.shouldPlay', false);
        this.get('select').call(this);
      }
    }),

    pausingObserver: Ember.observer('timelineModel.shouldPause', function () {
      if (this.get("timelineModel.shouldPause")) {
        this.set('timelineModel.playing', false);
        this.set('timelineModel.shouldPause', false);
        this.get('select').call(this);
      }
    }),

    axis: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        selection.append("g").classed("axis", true);
      },
      update: function update(selection) {
        this.axisSelection = selection;
        var xScale = this.get("xScale");
        var height = this.get('contentHeight');

        var axis = d3.svg.axis().scale(xScale).orient("bottom");
        //.ticks(d3.time.days, 1)
        //.tickFormat(d3.time.format('%a %d'));

        selection.attr("transform", "translate(0," + height + ")").call(axis);
      }
    }),

    brush: (0, _d2.join)([0], 'g.brush', {
      enter: function enter(selection) {
        var context = this;
        var xScale = this.get("xScale");

        selection.append("g").attr("class", "brush");

        if (selection.size() > 0) {
          context.brushComponent = d3.svg.brush();
          var brushstart = function brushstart() {
            context.chartSelection.classed("selecting", true);

            if (context.playLineSelection) {
              context.playLineSelection.remove();
              context.set("timelineModel.currentDate", null);
            }
          };

          var brushmove = function brushmove() {
            var s = context.brushComponent.extent();
            var timelineModel = context.get("timelineModel");
            timelineModel.setProperties({
              rangeStartDate: s[0],
              rangeEndDate: s[1]
            });
            context.eventsSelection.classed("selected", function (d) {
              return s[0] <= d.get("effectiveDate") && d.get("effectiveDate") <= s[1];
            });
            if (context.playLineSelection) {
              context.playLineSelection.remove();
              context.set("timelineModel.currentDate", null);
            }
          };

          var brushend = function brushend() {
            context.chartSelection.classed("selecting", !d3.event.target.empty());
            if (context.playLineSelection) {
              context.playLineSelection.remove();
              context.set("timelineModel.currentDate", null);
            }

            if (d3.event.target.empty()) {
              var timelineModel = context.get("timelineModel");
              timelineModel.set("rangeStartDate", null);
              timelineModel.set("rangeEndDate", null);
            }
          };

          context.brushComponent.x(xScale).extent(context.get("defaultBrushExtent")).on("brushstart", brushstart).on("brush", brushmove).on("brushend", brushend);

          brushstart();
          brushmove();
        }
      },
      update: function update(selection) {
        this.brushSelection = selection;

        var xScale = this.get("xScale");
        var height = this.get('contentHeight');

        var brush = this.brushComponent;

        brush.x(xScale);

        var arc = d3.svg.arc().outerRadius(height / 2).startAngle(0).endAngle(function (d, i) {
          return i ? -Math.PI : Math.PI;
        });

        selection.call(brush);

        selection.selectAll(".resize").append("path").attr("transform", "translate(0," + height / 2 + ")").attr("d", arc);

        selection.selectAll("rect").attr("height", height);
      }
    }),

    events: (0, _d2.join)('model', '.event', {
      enter: function enter(selection) {
        selection.append('text').classed('event', true);
      },
      update: function update(selection) {
        this.eventsSelection = selection;
        var xScale = this.get("xScale");
        var height = this.get('contentHeight');
        selection.attr('font-family', 'FontAwesome').attr('font-size', function () {
          return "4em";
        }).text(function () {
          return '\uF041';
        }).attr("transform", function (d) {
          return "translate(" + xScale(d.get("effectiveDate")) + "," + (height - 6) + ")";
        });
      }
    }),

    playLine: (0, _d2.join)([0], '.playLine', {
      enter: function enter(selection) {
        selection.append('rect').classed('playLine', true);
      },
      update: function update(selection) {
        var context = this;
        this.playLineSelection = selection;

        var height = this.get('contentHeight');

        var _brushComponent$exten = this.brushComponent.extent(),
            _brushComponent$exten2 = _slicedToArray(_brushComponent$exten, 2),
            dateMin = _brushComponent$exten2[0],
            dateMax = _brushComponent$exten2[1];

        var x = this.get("xScale")(dateMin);
        var d = this.get("xScale")(dateMax);
        var denominator = dateMax - dateMin;

        var width = d - x;

        selection.attr('x', x).attr('height', height);

        if (this.get("timelineModel.playing")) {
          var thresholds = context.get("model").map(function (model) {
            var numerator = model.get("effectiveDate") - dateMin;

            return numerator / denominator;
          });

          selection.attr('width', 0);
          var cleanup = function cleanup() {
            context.set('timelineModel.playing', false);
            context.set("timelineModel.currentDate", null);
            selection.remove();
          };
          selection.transition().duration(context.get("timelineModel.animationMillis")).attr('width', width).each("end", cleanup).tween("propertyUpdate", function () {
            // To prevent too many updates, we only push the model update on
            // a meaningful change.
            // t is 0 to 1 here, so we can grab the brush extend and work out what
            // what corresponds to.
            return function (t) {
              var results = _lodash.default.remove(thresholds, function (tr) {
                return t > tr;
              });
              if (results.length > 0) {
                var newDate = new Date(dateMin.getTime() + t * denominator);
                context.set("timelineModel.currentDate", newDate);
              }
            };
          });
        } else {
          selection.transition(); // Stops the transition as is.
        }
      }
    }),

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');

      selection.each(function () {
        var selection = d3.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;

        // Player needs to be ahead of brush so that brush
        // is on top for visibility, but player needs brush for
        // data. So put a group in.
        var playerGroup = selection.selectAll("g.player").data([0]);
        playerGroup.enter().append('g').classed("player", true);

        context.events(selection); // Has to go first so brush can bind
        context.brush(selection);
        context.playLine(playerGroup); // Play 

        context.axis(selection);
      });
    }
  });
});