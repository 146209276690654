define('frontend/locales/es/survey', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    pageHeader: {
      title: "Evaluación"
    },
    dialogs: {
      help: {
        button: 'Ayuda',
        message: '<p><strong> Tiene problema entendiendo la pregunta?</strong>  Responda lo mejor que pueda, y aseg\xFArese de pedirle a su proveedor que le clarifique cuando tenga una oportunidad de hablar con el/ella.',
        okButton: 'Ok'
      },
      contactDetails: {
        button: 'Notificaciones',
        title: '¿Cómo le gustaría recibir notificaciones para futuras evaluaciones?',
        saveAndClose: 'Guardar y Cerrar',
        cancelAndClose: 'Cancelar'
      },
      exit: {
        button: 'Salir',
        message: '<p>Si necesita abandonar la sesi\xF3n, sus respuestas ser\xE1n guardadas, y puede volver a continuar la sesi\xF3n m\xE1s tarde.</p>',
        stayButton: 'Permanecer',
        exitButton: 'Salir'
      },
      noBack: {
        message: '<p> Ya ha comenzado el instrumento de medida.\n             <br> Por razones de seguridad, no puede volver hacia atr\xE1s.\n             <br> Para salir, use el bot\xF3n de \u201CSalir\u201D</p>',
        okButton: 'Ok'
      }
    },
    controls: {
      back: 'Atrás',
      next: 'Próximo',
      skip: 'Saltar respuesta',
      required: 'Esta pregunta es obligatoria',
      exit: 'Salir',
      finish: 'Terminar'
    },
    finished: {
      title: "Gracias!",
      description: '<p>Sus respuestas son clave para entender su salud mental, y sirven como gu\xEDa para su tratamiento y psicoterapia.</p>\n                  <p>Aseg\xFArese de preguntarle a su m\xE9dico por su entendimiento en su proxima visita.</p>',
      logoutWarning: 'Por su seguridad, se ha cerrado la sesión.',
      redirect: "Va a ser reorientado(a) a la proxima pagina en 5 segundos.",
      redirectNow: "Reorientar ahora"
    },
    exit: {
      title: "Ha abandonado la encuesta",
      description: '<p>Sus respuestas se han guardado, puede continuar esta encuesta en cualquier momento.</p>\n                  <p>Si tiene alguna preocupaci\xF3n acerca de esta evaluaci\xF3n, por favor hable con su medico.</p>'
    },
    timeout: {
      title: " Su sesión se ha cerrado .",
      description: '<p>Sus respuestas se han guardado, puede continuar esta encuesta en cualquier momento.</p>'
    },
    contactDetails: {
      noDetailsText: 'Puede elegir tomar esta evaluación en su teléfono celular o su computadora la próxima vez.',
      confirmDetailsText: 'Confirme su información del contacto a continuación:',
      notifyMeBy: 'Mándeme la evaluación por medio de mí:',
      email: "Correo Electrónico",
      phoneNumber: "Número de Teléfono",
      text: "Mensaje de Texto"
    },
    timeoutWarning: {
      title: "¿Sigues ahí?",
      description: "Tus respuestas se han guardado. Se cerrará automáticamente la sesión después de 60 segundos. ",
      continue: "Continuar la evaluación",
      logout: "Cerrar sesión"
    },
    questionHeaders: {
      clientCrisisWarning: 'Advertencia de crisis',
      clientFinish: 'Terminar',
      clientFinishRelatedPerson: 'Terminar',
      clientResponse: 'Tu respuesta',
      clientWelcome: 'Bienvenido(a)',
      contactDetails: 'Detalles de contacto',
      default: 'Pregunta',
      hipaaConsent: 'Consentimiento',
      relatedPersonWelcome: 'Bienvenido(a)'
    }
  };
});