define('frontend/mixins/provider/plan/active-plans', ['exports', 'lodash', 'frontend/models/care/entity-measurement-plan'], function (exports, _lodash, _entityMeasurementPlan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    pathFilterType: 'availableForPath',
    sortedPlans: Ember.computed('entityMeasurementPlans.[]', function () {
      return this.get('entityMeasurementPlans').sortBy('precedence');
    }),

    groupedPlans: Ember.computed('entityMeasurementPlans.[]', function () {
      var byPlan = _lodash.default.groupBy(this.get('entityMeasurementPlans').toArray(), function (p) {
        return p.get('measurementPlan.id');
      });

      return Object.values(byPlan).map(function (plans) {
        return _entityMeasurementPlan.PlanCollection.create({ entityMeasurementPlans: plans });
      }).sortBy('precedence');
    }),

    hideDisabled: false,

    // We want to remove the plans which are never going to be administered, i.e. they're completely covered by another
    // plan. Right now, this only applies to core plans.
    groupedAndFilteredPlans: Ember.computed('groupedPlans.[]', function () {
      var allPlans = this.get("groupedPlans");

      // Right now this is very inefficient, but not really supposed to go up against a large n so it should be fine.
      var corePlans = allPlans.filterBy('planType', 'core');

      var hideDisabled = this.get("hideDisabled");

      return allPlans.reject(function (plan) {
        if (plan.get('planType') !== 'core') {
          return false;
        }

        if (!plan.get('active') && hideDisabled) {
          return true;
        }

        return corePlans.any(function (comparison) {
          return comparison !== plan && (0, _entityMeasurementPlan.isCoveredBy)(comparison, plan);
        });
      });
    }),

    otherFilters: {},

    _planQuery: function _planQuery(filters, includeDemographics, name) {
      var _this = this;

      if (includeDemographics) {
        var age = this.get("demographics.age");

        if (age && age !== 'all') {
          filters.age = age;
        }

        var gender = this.get("demographics.gender");

        if (gender && gender !== 'all') {
          filters.gender = gender;
        }
      }
      var results = this.get('store').query('care/entityMeasurementPlan', {
        include: 'entity-tree-node,measurement-plan.measurement-plan-scales',
        filter: filters,
        page: { limit: 100 }
      });

      results.then(function (results) {
        if (_this.get('onQueryResults')) {
          _this.get('onQueryResults')(name, results);
        }
      });

      return results;
    },


    entityMeasurementPlans: Ember.computed('forceRefresh', 'otherFilters', 'entityTreeNode.demographicsPresent', 'entityTreeNode.path', 'demographics.age', 'demographics.gender', function () {
      this.get("forceRefresh"); // Trigger the actual property binding.
      var filters = Ember.merge({ preset: 'current_and_future' }, this.get('otherFilters'));
      filters[this.get('pathFilterType')] = this.get('entityTreeNode.path');

      var includeDemographics = !this.get("entityTreeNode.demographicsPresent");

      return this._planQuery(filters, includeDemographics, 'plans');
    })
  });
});