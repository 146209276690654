define('frontend/pods/components/provider/patient-sessions/edit-session-form/component', ['exports', 'frontend/mixins/back-action-mixin', 'frontend/mixins/rollback-mixin', 'moment', 'frontend/mixins/error-printer', 'frontend/pods/components/provider/assessment/scale-info-drawer/component'], function (exports, _backActionMixin, _rollbackMixin, _moment, _errorPrinter, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_backActionMixin.default, _rollbackMixin.default, _errorPrinter.default, _component.ScaleDrawerMixin, {
    messages: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    report: Ember.computed.oneWay("session.assessmentReport"),
    userTaskService: Ember.inject.service('provider/taskService'),
    rollbackProperties: ['session.schedulingAppointment', 'report.reportScales.reportScaleParticipants', 'report.reportScales', 'report.invitations', 'session.careEpisode', 'session'],

    institute: Ember.inject.service(),
    showCareEpisodeSelector: Ember.computed.bool('institute.configuration.showCareEpisodeSelector'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('patient.email')) {
        this.set('emailChoice', false);
      }

      if (!this.get("session.provider.id")) {
        this.set("session.provider", this.get("sessionUser.activeProvider"));
      }

      if (this.get("session.schedulingAppointment.id") && !this.get("session.schedulingAppointment.provider.id")) {
        this.set("session.schedulingAppointment.provider", this.get("session.provider"));
      }
    },


    nearbyPatientSessions: Ember.computed('activePatientSessions', function () {
      var _this = this;

      return this.getWithDefault('activePatientSessions', []).filter(function (s) {
        return s.get('id') !== _this.get('session.id');
      });
    }),

    _submit: function _submit() {
      var targetDate = (0, _moment.default)(this.get('session.targetDate'));
      if (targetDate.isAfter((0, _moment.default)().startOf("day")) && targetDate.isBefore((0, _moment.default)().endOf("day"))) {
        this.set("session.statusEvent", "measure_now");
      }

      if (!this.get('session.isNew') && this.get('session.schedulingRecurrence.repeatMnemonic')) {
        return this.send('toggleSeriesDialog');
      }
      if (this.get('session.isNew') && this.get('session.schedulingRecurrence.repeatMnemonic')) {
        this.set('session.updateSeries', true);
      }

      return this.send('performSave');
    },


    actions: {
      updateSeriesAndSave: function updateSeriesAndSave() {
        this.set('session.updateSeries', true);
        return this.send('performSave');
      },
      onCreateInvitation: function onCreateInvitation(invitation) {
        invitation.updateNotificationsToPreferences(true, this.get("session.schedulingAppointment.startDate"));
      },
      performSave: function performSave() {
        var _this2 = this;

        // When the patient-session serializer is invoked, all the sub-resources are serialized at the same time and created in one go.
        // So we simply save this and let the serializer do the all sidecar stuff.
        var planOnSubmit = this.get("planOnSubmit");

        var onSuccess = function onSuccess(session) {
          _this2.get('userTaskService').refreshTaskCount();
          _this2.set("isSaving", false);

          if (_this2.get("onSave")) {
            _this2.get("onSave")(session);
          } else if (_this2.get("onSaveRoute")) {
            _this2.get("router").transitionTo(_this2.get("onSaveRoute"), session);
          } else if (planOnSubmit) {
            _this2.get("router").transitionTo('provider.patients.show.plan');
          } else {
            _this2.get("router").transitionTo('provider.patients.show.sessions.show', session);
          }
        };

        var onFailure = function onFailure(error) {
          _this2.set("isSaving", false);
          _this2.get('messages').danger(_this2.printError(error));
        };

        this.set("isSaving", true);

        return this.get("session").save().then(onSuccess, onFailure);
      },
      toggleSeriesDialog: function toggleSeriesDialog() {
        this.toggleProperty('isShowingSeriesModal');
      },
      submitAndPlan: function submitAndPlan() {
        this.set("planOnSubmit", true);
        return this._submit();
      },
      submit: function submit() {
        this.set("planOnSubmit", false);
        return this._submit();
      }
    }
  });
});