define('frontend/models/patient-condition', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    instituteCondition: _emberData.default.belongsTo('instituteCondition'),
    patient: _emberData.default.belongsTo('patient'),
    status: _emberData.default.attr("string"),
    onsetDate: _emberData.default.attr("date"),
    abatementDate: _emberData.default.attr("date")
  });
});