define('frontend/pods/provider/plan/entities/index/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),
    queryParams: {
      path: {},
      gender: {},
      age: {}
    },

    rootPath: Ember.computed('institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations"),
        // I'm not sure why this is necessary to preload but there's a weird timing bug where the list doesn't load
        // if it's only in model()
        treatmentTrackEntities: this.get("preload.activeTreatmentTrackEntities"),
        treatmentServiceEntities: this.get("preload.treatmentServiceEntities"),
        panelEntities: this.get("preload.panelEntities")
      });
    },
    model: function model(params) {
      var path = params.path || this.get('rootPath');
      return Ember.RSVP.hash({
        entityTreeNode: this.get("store").findRecord('entityTreeNode', path),
        providerEntities: this.get("preload.providerEntities"),
        organizationEntities: this.get("preload.organizationEntities"),
        treatmentTrackEntities: this.get("preload.activeTreatmentTrackEntities"),
        treatmentServiceEntities: this.get("preload.treatmentServiceEntities"),
        panelEntities: this.get("preload.panelEntities")
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ path: undefined, gender: undefined, age: undefined });
      }
    },


    breadCrumb: null
  });
});