define('frontend/pods/provider/login/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    breadCrumb: null,
    queryParams: {
      username: {},
      targetUrl: {}
    },
    model: function model(params) {
      var defaultTarget = "provider.assessment.dashboard";
      // TODO: if the roll out for schedule page completes,
      // set the defaultTarget in the login template.hbs and remove the if clause
      if (this.get("features.enableDashboardSchedule")) {
        defaultTarget = "/app/providers/dashboard/schedule";
      }
      if (this.get('institute.current.shortcode') === 'internal') {
        defaultTarget = "/app/ops";
      }
      // TODO: This is wrong - for an institute that uses both collaborative care and MBC, users should only default to
      // the collaborative care page if they have the appropriate roles/permissions. This is solved correctly in the
      // provider-ui login flow, but not here. If we are close to rolling out collaborative care for real before
      // we remove the ember login flow make sure to fix this.
      if (this.get('features.enableCollaborativeCare')) {
        defaultTarget = "/app/cocm/dashboard";
      }
      return {
        username: params.username,
        targetUrl: params.targetUrl,
        defaultTarget: defaultTarget
      };
    }
  });
});