define('frontend/pods/components/provider/plan/measurement-plan-selector/filter-options/component', ['exports', 'ember-power-select/components/power-select/before-options'], function (exports, _beforeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _beforeOptions.default.extend({
    actions: {
      showAllMeasurementPlans: function showAllMeasurementPlans() {
        this.get('extra.onClearFilter')();
      },
      showFilteredPlans: function showFilteredPlans() {
        this.get('extra.onSetFilter')();
      }
    }
  });
});