define('frontend/initializers/tab-id', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /*application*/{
    sessionStorage.setItem("tabId", (0, _uuidHelpers.default)());
  }

  exports.default = {
    name: 'tab-id',
    initialize: initialize
  };
});