define('frontend/pods/components/reporting/report-table/paging-control/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaultLimit: Ember.computed.alias('reportDefinition.defaultLimit'),

    hasMoreData: Ember.computed('reportParams.limit', 'data.length', function () {
      return this.get("data.length") >= this.get("reportDefinition.defaultLimit");
    }),

    showLimit: Ember.computed.and('showLimitControls', 'hasMoreData'),

    hasLimit: Ember.computed('reportParams.limit', function () {
      var limit = this.get("reportParams.limit");
      return limit && limit !== 'none';
    }),

    actions: {
      removeLimit: function removeLimit() {
        this.set("reportParams.limit", 'none');
        this.sendAction("reportParamsRefresh");
      },
      addLimit: function addLimit() {
        this.set("reportParams.limit", this.get('defaultLimit'));
        this.sendAction("reportParamsRefresh");
      }
    }

  });
});