define('frontend/pods/components/provider/assessment/common/answer-detail/component', ['exports', 'frontend/utils/assessment/analytics', 'frontend/mixins/assessment/question-text'], function (exports, _analytics, _questionText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var answerComponentMapping = {
    fullslider: 'provider/assessment/common/fullslider-answer',
    likert: 'provider/assessment/common/likert-answer'
  };

  exports.default = Ember.Component.extend(_questionText.default, {
    MAX_QUESTION_LENGTH: 70,
    tagName: '',
    cachedText: null,
    cachedFullText: null,
    intl: Ember.inject.service(),
    isQuestionDetailShown: false,

    showUnshownQuestions: false,

    textOnly: false,

    showAnswer: Ember.computed('answer', 'answer.shownButNotAnswered', 'hideIfUnanswered', function () {
      var answer = this.get('answer');

      if (!answer) {
        return false;
      }

      return !(answer.get("unanswered") && answer.get("hideIfUnanswered"));
    }),

    question: Ember.computed('answer', function () {
      return this.get('answer.question');
    }),

    answer: Ember.computed('answers', function () {
      return this.getWithDefault('answers', []).objectAt(0);
    }),

    answerScoreClass: Ember.computed('answer', 'scale', function () {
      if (this.get('scale.hasOldScoreMaps')) {
        return _analytics.default.getAnswerScoreClass(this.get('answer'));
      } else {
        return _analytics.default.getAnswerScoreClassNew(this.get('answer'), this.get('scale'));
      }
    }),

    isCheckbox: Ember.computed('question', function () {
      return this.get('question.questionType') === 'checkbox' || this.get('question.subtype') === 'checkbox';
    }),

    isTable: Ember.computed('question', function () {
      return this.get('question.questionType') === 'table' || this.get('question.subtype') === 'table' || this.get('question.questionType') === 'multi_textbox' || this.get('question.subtype') === 'multi_textbox';
    }),

    answerDisplayMode: Ember.computed.oneWay('question.displayData.details.valueModes.firstObject'),

    answerComponent: Ember.computed('answerDisplayMode', function () {
      var displayMode = this.get("answerDisplayMode");

      if (displayMode) {
        return answerComponentMapping[displayMode];
      }
    }),

    actions: {
      toggleQuestionDetail: function toggleQuestionDetail() {
        if (this.get('isTable') || this.get("showFullQuestionText")) {
          //for now, we don't support his for grid questions
          return;
        }
        this.set('isQuestionDetailShown', !this.get('isQuestionDetailShown'));
      }
    }
  });
});