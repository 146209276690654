define('frontend/pods/components/provider/plan/single-scale-form/component', ['exports', 'frontend/mixins/drawer-enabled', 'frontend/mixins/rollback-mixin', 'frontend/models/care/measurement-plan-scale', 'frontend/mixins/error-printer'], function (exports, _drawerEnabled, _rollbackMixin, _measurementPlanScale, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, _drawerEnabled.default, _errorPrinter.default, {
    rollbackProperties: ['measurementPlan', 'measurementPlanScale', 'entityMeasurementPlan'],
    init: function init() {
      this._super.apply(this, arguments);
      this._createMeasurementPlan();
    },

    intl: Ember.inject.service(),
    messages: Ember.inject.service(),
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    schedulePresets: _measurementPlanScale.SchedulePresets,
    showAdvanced: Ember.computed.equal("measurementPlanScale.schedulePreset.id", 'custom'),
    advancedObserver: Ember.observer('measurementPlanScale.schedulePreset.id', function () {
      // Once we've triggered custom mode, don't allow going back to non custom mode.
      if (this.get('measurementPlanScale.schedulePreset.id') === 'custom') {
        this.set('showAdvanced', true);
      }
    }),
    demographics: Ember.computed("patient.age", "patient.gender", function () {
      return Ember.Object.create({
        age: this.get('patient.age'),
        gender: this.get('patient.gender')
      });
    }),
    autoOpen: true,
    _showBrowser: function _showBrowser() {
      this.setProperties({
        showScaleBrowser: true
      });
      this.send('drawer', '#scaleChooserSelectorDrawer');
      Ember.run.later(function () {
        $('.globalSearch input').focus();
      });
    },
    _createMeasurementPlan: function _createMeasurementPlan() {
      var _this = this;

      var measurementPlan = this.get('store').createRecord('care/measurementPlan', { autogenerated: true, shared: false, defaultPlanType: 'supplemental', author: this.get("sessionUser.currentUser") });
      var measurementPlanScale = this.get('store').createRecord('care/measurementPlanScale', { measurementPlan: measurementPlan, schedulePreset: null });

      var entityTreeNode = this.get("entityTreeNode");
      var entityMeasurementPlan = this.get('store').createRecord('care/entityMeasurementPlan', {
        measurementPlan: measurementPlan, entityTreeNode: entityTreeNode, planType: 'supplemental', startDate: moment().startOf("day").toDate()
      });

      this.setProperties({ measurementPlan: measurementPlan, measurementPlanScale: measurementPlanScale, entityMeasurementPlan: entityMeasurementPlan, entityTreeNode: entityTreeNode });

      if (this.get('autoOpen')) {
        Ember.run.later(function () {
          _this._showBrowser(measurementPlanScale);
        });
      }
    },
    _scaleIsSelected: function _scaleIsSelected(scale) {
      return this.get("measurementPlanScale.scale") === scale;
    },
    _selectScale: function _selectScale(scale) {
      this.set('measurementPlanScale.scale', scale);
      this.set('measurementPlan.name', this.get("measurementPlan.automaticName"));
    },
    _toggleScale: function _toggleScale(scale) {
      if (this._scaleIsSelected(scale)) {
        this.set('measurementPlanScale.scale', null);
      } else {
        this._selectScale(scale);
      }
    },
    _setScaleFocus: function _setScaleFocus(scale) {
      var _this2 = this,
          _arguments = arguments;

      this.get('focusedScale').setProperties({
        scale: scale, showActions: true,
        selected: this._scaleIsSelected(scale),
        onScaleChange: function onScaleChange() {
          _this2._toggleScale.apply(_this2, _arguments);
        } });
    },


    actions: {
      cancelAddScale: function cancelAddScale() {
        this._rollbackAllProperties();
        this.get("onCancel")();
      },
      onScaleChange: function onScaleChange(scale, shouldClose) {
        this._selectScale(scale);

        if (shouldClose === false) {
          // Don't hide if explicitly set to false
        } else {
          this.send('drawer', '#scaleChooserSelectorDrawer');
          this.setProperties({
            showScaleBrowser: false
          });
        }
      },
      hideScaleBrowser: function hideScaleBrowser() {
        this.setProperties({
          showScaleBrowser: false
        });
      },
      showScaleBrowser: function showScaleBrowser() {
        this._showBrowser();
      },
      scaleDetailSelected: function scaleDetailSelected(scale) {
        this._setScaleFocus(scale);
      },
      doAddScale: function doAddScale() {
        var _this3 = this;

        var onSuccess = function onSuccess(result) {
          _this3.get("messages").success(_this3.get('intl').t('provider.patientMeasurementPlans.scaleAdded'));
          if (_this3.get('onSave')) {
            _this3.get("onSave")(result);
          }
        };

        var onFailure = function onFailure(reason) {
          _this3.get('messages').danger(_this3.printError(reason));
          _this3.set('disableRollback', false);
        };

        // For some reason, it rolls back before the new updated measurement plan has been loaded from the store.
        // So disable rollback
        this.set('disableRollback', true);

        return this.get('entityMeasurementPlan').save().then(onSuccess, onFailure);
      }
    }
  });
});