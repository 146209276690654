define('frontend/locales/es/related-person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    measure: "medida",
    login: {
      participantFirstName: 'Tu nombre',
      patientLastName: 'Apellido del cliente',
      patientDob: 'Fecha de nacimiento del cliente',
      patientLastSsn: 'Últimos 4 dígitos ssn del cliente',
      email: "Correo electrónico",
      submit: "Iniciar sesión",
      description: "Elegir el método de inicio de sesión",
      username: "Conozco mi nombre de usuario y mi contraseña",
      noUsername: "Iniciar sesión sin nombre de usuario",
      pleaseSignIn: "Por favor regístrese",
      forgottenPassword: "¿Contraseña olvidada?",
      otherMechanisms: "Otras formas de iniciar sesión",
      yourDetails: "Su información",
      clientDetails: "información del cliente"
    },
    assessment: {
      patientName: "Nombre del cliente",
      date: "Fecha",
      takeAssessment: "Evaluar",
      confirm: "Usted está a punto de responder preguntas relacionadas con {patientName}",
      chooseClient: "Selecciona la persona que estás completando preguntas sobre ahora.",
      confirmButton: "Sí, empieza",
      upToDate: "¡Todos están al día!",
      logout: "Cerrar sesión"
    }
  };
});