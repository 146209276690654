define('frontend/pods/components/provider-admin/payors/contract-form/component', ['exports', 'frontend/mixins/rollback-mixin'], function (exports, _rollbackMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, {
    rollbackProperties: ["model"],
    actions: {
      submit: function submit() {
        var action = this.get('onSave');
        if (typeof action === 'function') {
          return action();
        } else {
          console.warn("No action function was passed");
        }
      }
    }
  });
});