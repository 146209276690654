define('frontend/mixins/related-person-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    exceptions: ['related-person.login', 'related-person.user.password-reset', 'related-person.user.password-reset', 'related-person.user.confirm', 'related-person.user.confirm-error'],
    beforeModel: function beforeModel(transition) {
      sessionStorage.setItem("authenticationType", "related_person");
      if (!this.get('session.isAuthenticated')) {
        if (this.get("exceptions").includes(transition.targetName)) {
          return this._super.apply(this, arguments);
        }

        transition.abort();
        this.transitionTo('related-person.login');
      } else {
        if (this.get("session").get("data.authenticated.user_type") !== "related_person") {
          this.set("session.invalidationUrl", this.get("router").generate('patient.welcome'));
          this.get("metrics").trackEvent({ event: "user.logout", reason: "wrongRole" });
          this.get("sessionUser").logout();
        } else {
          return this._super.apply(this, arguments);
        }
      }
    }
  });
});