define('frontend/pods/components/provider/assessment/scale-preview/component', ['exports', 'frontend/pods/components/internal/scales/edit-form/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _generatePreviewModel: function _generatePreviewModel() {
      var moduleData = this.get("modules").toArray().map(function (m) {
        var data = m.getProperties('mnemonic', 'content', 'questionDomain');

        // Unfortunately, this is camel cased as it usually comes straight out of the db.
        data.question_domain = data.questionDomain;
        return data;
      });
      var invite = this.get("store").createRecord('survey/invite', {
        sections: [{
          modules: this.get('modules').mapBy('mnemonic').addObject('previewFinish')
        }],
        preloadedModules: moduleData.addObject(_component.previewFinish),
        moduleIds: this.get('modules').mapBy('id').addObject('previewFinish')
      });

      var previewModel = this.get("store").createRecord('survey/response', {
        invite: invite
      });

      previewModel.beginInterviewPreview();

      return previewModel;
    },


    // Generate an initial preview that can be reset.
    previewModel: Ember.computed(function () {
      return this._generatePreviewModel();
    }),

    previewMode: 'selfReport',
    actions: {
      onInterviewDataChange: function onInterviewDataChange() {
        this.get('previewModel').updateAllDisplayLogic();
      },
      showInterview: function showInterview() {
        this.set('previewMode', 'interview');
      },
      showSelfReport: function showSelfReport() {
        this.set('previewMode', 'selfReport');
      }
    }
  });
});