define('frontend/transforms/interval', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return _moment.default.duration(serialized);
      } else {
        return null;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return deserialized.toString();
      } else {
        return null;
      }
    }
  });
});