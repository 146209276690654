define('frontend/models/preload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    raceMap: _emberData.default.attr(),
    scales: _emberData.default.hasMany('assessment/scale'),
    norms: _emberData.default.hasMany('assessment/norm'),
    populations: _emberData.default.hasMany('assessment/population'),

    providerRoles: _emberData.default.hasMany('role'),
    // These are not currently used as they may vary depending on role.
    // providers: DS.hasMany('provider'),
    // organizations: DS.hasMany('organization'),
    providerOrganizations: _emberData.default.hasMany('providerOrganization'),
    instituteConditions: _emberData.default.hasMany('instituteCondition'),
    flags: _emberData.default.hasMany('flag'),
    patientSessionTypes: _emberData.default.hasMany('patientSessionType'),

    careUnitTypes: _emberData.default.hasMany('careUnitType'),

    enabledPayors: _emberData.default.hasMany('payor')
  });
});