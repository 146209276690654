define('frontend/pods/components/provider/assessment/dashboard/research-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dashboard', 'page'],
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service()
  });
});