define('frontend/pods/components/provider/patients/edit-patient/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    actions: {
      savePatient: function savePatient() {
        var _this = this;

        var success = function success(patient) {
          _this.get('messages').success("Patient saved");
          _this.get("metrics").trackEvent({ event: "provider.patient.updated" });

          if (_this.get("onSave")) {
            _this.get("onSave")();
          } else if (_this.get("onSaveRoute")) {
            _this.get("router").transitionTo(_this.get("onSaveRoute"), patient);
          }
        };

        var failure = function failure(reason) {
          var msgString = reason.errors.reduce(function (str, e, index) {
            return str + (index ? '; ' : '') + e.detail;
          }, '');
          _this.get('messages').danger("Error: " + msgString);
        };

        return this.get("patient").save().then(success).catch(failure);
      }
    }
  });
});