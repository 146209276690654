define('frontend/pods/components/provider/plan/entity-active-plans/filter-summary/component', ['exports', 'frontend/helpers/assessment/scale-age'], function (exports, _scaleAge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    hasFilters: Ember.computed.notEmpty('entityTreeNode.filterData'),

    entityTreeNode: Ember.computed.oneWay('record.entityTreeNode'),

    demographicText: Ember.computed('entityTreeNode.filterData', function () {
      var filterData = this.get('entityTreeNode.filterData');

      var minAge = Ember.get(filterData, 'min-age.value');
      var maxAge = Ember.get(filterData, 'max-age.value');
      var gender = Ember.get(filterData, 'gender.value');

      var parts = [];

      if (minAge || maxAge) {
        parts.addObject((0, _scaleAge.assessmentScaleAge)([minAge, maxAge]));
      }

      if (gender) {
        parts.addObject(gender);
      }

      if (parts.length > 0) {
        return parts.join(', ');
      }
    }),

    filterEntities: Ember.computed.alias('entityTreeNode.filterEntities')

  });
});