define('frontend/pods/components/provider/plan/edit-entity-form/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var serializeDate = function serializeDate(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return date;
    }
  };

  exports.default = Ember.Component.extend(_errorPrinter.default, {
    classNames: 'entity-tree page__wide-content',
    institute: Ember.inject.service(),
    messages: Ember.inject.service(),

    entityTreeNode: Ember.computed('path', function () {
      var path = this.get('path') || this.get('rootPath');
      return this.get("store").findRecord('entityTreeNode', path);
    }),

    ageOptions: [].concat(_toConsumableArray(Array(100).keys())).map(function (s) {
      return s.toString();
    }), // Make them strings to be searchable

    genderOptions: ['M', 'F'],

    path: Ember.computed.oneWay('rootPath'),

    rootPath: Ember.computed('institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    warnAboutReplan: false,

    _calculateAffectedSessions: function _calculateAffectedSessions() {
      var _this = this;

      return this.get("entityTreeNode.collections").then(function (collections) {
        var sessions = collections.find(function (collection) {
          return collection.get('collection') === 'patient-sessions';
        });

        if (sessions) {
          var filters = {
            session_start_date: serializeDate(_this.get('entityMeasurementPlan.startDate')),
            session_end_date: serializeDate(_this.get('entityMeasurementPlan.endDate')),
            preset: 'active'
          };

          return sessions.query('childEntities', { filter: filters }).then(function (result) {
            var count = result['meta'] ? result['meta']['record-count'] : Ember.get(result, 'length');
            _this.set('affectedSessionCount', count);
            _this.set('affectedSessions', result);
          }).catch(function (reason) {
            _this.get('messages').danger(_this.printError(reason));
          });
        } else {
          return [];
        }
      });
    },


    demographicsReset: Ember.observer('entityTreeNode.demographicsPresent', function () {
      if (this.get('entityTreeNode.demographicsPresent')) {
        this.get('entityMeasurementPlan').setProperties({ minAge: null, maxAge: null, gender: null });
      }
    }),

    replanWarningObserver: Ember.observer('entityTreeNode', 'demographics.minAge', 'demographics.maxAge', 'demographics.gender', 'entityMeasurementPlan.startDate', 'entityMeasurementPlan.endDate', function () {
      this.set('warnAboutReplan', false); // Therefore it will recalculate on submit again.
    }),

    demographics: Ember.computed('entityMeasurementPlan.gender', 'entityMeasurementPlan.minAge', 'entityMeasurementPlan.maxAge', 'entityTreeNode.demographics', function () {
      if (this.get('entityTreeNode.demographicsPresent')) {
        return this.get('entityTreeNode.demographics');
      } else {
        return this.get('entityMeasurementPlan').getProperties('minAge', 'maxAge', 'gender');
      }
    }),

    _setEntityTreeNode: function _setEntityTreeNode(path) {
      var _this2 = this;

      this.get("store").findRecord('entityTreeNode', path).then(function (result) {
        _this2.set('entityTreeNode', result);
        _this2.set('entityMeasurementPlan.entityTreeNode', result);
      });
    },

    actions: {
      resetRoot: function resetRoot() {
        this.set('path', this.get('rootPath'));
        this._setEntityTreeNode(this.get('rootPath'));
      },
      pathSelected: function pathSelected(path) {
        if (!path) {
          path = this.get('rootPath');
        }

        this.set('path', path);
        this._setEntityTreeNode(path);
      },
      entityTreeNodeSelected: function entityTreeNodeSelected(entity) {
        entity = entity.content ? entity.content : entity;
        this.setProperties({ path: entity.get('path'), entityTreeNode: entity });
      },
      startDateChanged: function startDateChanged(newDate) {
        this.set('entityMeasurementPlan.startDate', newDate);
      },
      endDateChanged: function endDateChanged(newDate) {
        this.set('entityMeasurementPlan.endDate', newDate);
      },
      submitAndReplan: function submitAndReplan() {
        var model = this.get('entityMeasurementPlan');
        return this.get('onSubmit')(model);
      },


      // This will first check if there are existing sessions affected by this plan.
      submit: function submit() {
        var model = this.get('entityMeasurementPlan');

        // There are quite difficult to diagnose performance problems with the 'affected sessions' count query.
        // For now we simply disable the check and disable right away to make it usable.
        return this.get('onSubmit')(model);

        // return this._calculateAffectedSessions().then(() => {
        //   if (this.get('affectedSessionCount') > 0) {
        //     this.set('warnAboutReplan', true);
        //     Ember.run.later(() => {
        //       this.$('.btn-submit')[0].scrollIntoView({alignToTop: false, behavior: 'smooth'});
        //     });

        //   } else {
        //     return this.get('onSubmit')(model);
        //   }
        // });
      }
    }
  });
});