define('frontend/pods/components/provider/assessment/edit-participant-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //  In order to stop rerendering when we change things or when data gets validated, we take out the loop into a separate properlty and then grab the data back 
    activeParticipants: Ember.computed('report.activeParticipantsWithInvitations.[]', function () {
      return this.get('report.activeParticipantsWithInvitations').mapBy('participant');
    })
  });
});