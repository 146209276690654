define('frontend/models/care/patient-session-measurement-plan', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    sessionNumber: _emberData.default.attr('number'),
    patientSession: _emberData.default.belongsTo('patientSession'),
    entityMeasurementPlan: _emberData.default.belongsTo('care/entityMeasurementPlan')
  });
});