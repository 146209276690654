define('frontend/models/user', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('format', { allowBlank: false, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('international-phone-number', { allowBlank: true })
  });

  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    username: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    phoneNumber: _emberData.default.attr("string"),
    role: _emberData.default.attr("string"),
    language: _emberData.default.attr("string"),
    name: _emberData.default.attr("string", { readOnly: true }),
    isInternal: _emberData.default.attr('boolean'),
    institute: _emberData.default.belongsTo('institute', { async: true }),
    userHelpState: _emberData.default.belongsTo('userHelpState', { async: true }),
    patients: _emberData.default.hasMany('patients', { async: true }),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    preferredFirstName: _emberData.default.attr("string"),
    preferredLastName: _emberData.default.attr("string"),
    legalFirstName: _emberData.default.attr("string"),
    legalLastName: _emberData.default.attr("string"),
    legalName: _emberData.default.attr("string"),
    preferredName: _emberData.default.attr("string"),
    suffix: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    userType: _emberData.default.attr("string"),
    notificationPreference: _emberData.default.attr('string'),
    notificationPreferenceWithDefault: _emberData.default.attr('string'),

    allowsEmail: _emberData.default.attr("boolean"),
    allowsText: _emberData.default.attr("boolean"),
    allowsNotifications: Ember.computed.or('allowsEmail', 'allowsText'),

    // Different authetication modes
    provider: _emberData.default.belongsTo("provider"),
    patient: _emberData.default.belongsTo("patient"),
    relatedPeople: _emberData.default.hasMany("relatedPeople"),

    // Roles
    roles: _emberData.default.hasMany("role"),

    // Assessment methods
    activeInvitation: _emberData.default.belongsTo("assessment/invitation", { inverse: null }),
    pendingSessions: _emberData.default.hasMany('patientSessions', { async: true, inverse: null }),

    userConsents: _emberData.default.hasMany("userConsent", { async: true, inverse: "user" }),
    userSubscriptions: _emberData.default.hasMany("userSubscription", { async: true, inverse: "user" }),

    // Remote in PROD-1220
    newReportSwitchingEnabled: _emberData.default.attr('boolean')
  });
});