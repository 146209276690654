define('frontend/pods/components/reporting/help-drawer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    shortcode: Ember.computed('report.shortcode', function () {
      return this.get('report.shortcode');
    }),

    columnsToShow: Ember.computed('columns', function () {
      var _this = this;

      var foo = this.getWithDefault('columns', []).filter(function (column) {
        var helpKey = ['reporting', 'reps', _this.get('shortcode'), column.translationKey, 'helpDescription'].join('.');
        return _this.get('intl').exists(helpKey);
      }).sort(function (a, b) {
        return !!a.featured < !!b.featured;
      });
      return foo;
    }),

    isOutcomesExplorer: Ember.computed('shortcode', function () {
      return this.get('shortcode') === 'clinical.outcomes_explorer';
    })
  });
});