define("frontend/locales/es/model", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    patient: {
      label: "Cliente",
      firstName: "Nombre de pila",
      lastName: "Apellido",
      gender: "Sexo",
      email: "Correo electrónico",
      dob: "Fecha de nacimiento",
      dobShort: "Fecha de nacimiento"
    },
    participant: {
      participantType: {
        provider: "Terapeuta",
        patient: "Cliente",
        guardian: "Padre/guardian",
        teacher: "Profesor",
        father: "Padre",
        mother: "Madre"
      }
    },
    gender: {
      M: "masculino",
      F: "hembra",
      O: "otro"
    }
  };
});