define('frontend/pods/components/onboarding-introjs/component', ['exports', 'ember-introjs/components/intro-js'], function (exports, _introJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _introJs.default.extend({
    'exit-on-esc': true,
    'show-step-numbers': false,
    'next-label': 'next &#xf061;',
    'prev-label': ' &#xf060; back',
    'skip-label': 'skip &#xf00d;',
    'done-label': 'done &#xf00d;',

    // We overwrite this to remove the 'didInsertElement' hook that seems to break it for some reason.
    setupIntroJS: Ember.observer('start-if', function () {
      Ember.run.scheduleOnce('afterRender', this, this.startIntroJS);
    })
  });
});