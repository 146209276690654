define('frontend/services/messages', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    content: Ember.A(),
    timeout: _environment.default.alertMessageDuration,
    pushObject: function pushObject(object) {
      object.typeClass = 'alert-' + object.type;
      this.content.pushObject(object);
    },
    removeObject: function removeObject(object) {
      this.content.removeObject(object);
    },
    danger: function danger(message) {
      this.pushObject({
        type: 'danger',
        message: message
      });
    },
    warning: function warning(message) {
      this.pushObject({
        type: 'warning',
        message: message
      });
    },
    info: function info(message) {
      this.pushObject({
        type: 'info',
        message: message
      });
    },
    success: function success(message) {
      this.pushObject({
        type: 'success',
        message: message
      });
    },
    clear: function clear() {
      this.content.clear();
    }
    // http://jsbin.com/luhoquxi/7/embed?html,css,js,output
    // add in auto removal

  });
});