define('frontend/pods/unsupported-pii/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sessionUser: Ember.inject.service(),
    actions: {
      disablePiiAndRetry: function disablePiiAndRetry() {
        localStorage.removeItem('piiLevel');
        window.history.go(-1);
      }
    }
  });
});