define('frontend/pods/components/reporting/columns/no-show-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['single-line'],
    badgeClass: Ember.computed("column.dataMapping.badgeClass", "row", function () {
      return this.get("row")[this.get("column.dataMapping.badgeClass")];
    }),
    badgeText: Ember.computed("column.dataMapping.badgeText", "row", function () {
      return this.get("row")[this.get("column.dataMapping.badgeText")];
    }),
    badgeScore: Ember.computed("column.dataMapping.badgeScore", "row", function () {
      return this.get("row")[this.get("column.dataMapping.badgeScore")];
    })
  });
});