define('frontend/pods/components/provider/assessment/report-v2/patient-goal-graph/component', ['exports', 'd3'], function (exports, _d) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var formatTime = _d.default.time.format("%b. %e");

  exports.default = Ember.Component.extend({
    tagName: '',
    graphOptions: Ember.computed('goalData.firstObject', function () {
      if (this.get("goalData.firstObject")) {
        var goal = this.get("goalData.firstObject").goal;
        return {
          showYAxis: true,
          maxValue: goal.get("maxValue"),
          minValue: goal.get("minValue")
        };
      }
    }),

    filteredGoalData: Ember.computed('goalData.@each.hidden', function () {
      return this.get("goalData").reject(function (g) {
        return g.hidden;
      });
    }),

    hasGoalData: Ember.computed.gte('goalData.length', 0),

    dateRange: Ember.computed('activeReport.patientSession.targetDate', 'filteredGoalData', function () {
      var minDate = this.getWithDefault('filteredGoalData', []).reduce(function (acc, curr) {
        if (curr.answerData[0] && !acc || curr.answerData[0].get("report.patientSession.targetDate") < acc) {
          return curr.answerData[0].get("report.patientSession.targetDate");
        } else {
          return acc;
        }
      }, null);

      return {
        minDate: minDate,
        maxDate: this.get('activeReport.patientSession.targetDate')
      };
    }),

    circleHoverFunction: function circleHoverFunction(d) {
      return d.datum.get("goal.patientText") + "<br/>" + formatTime(d.datum.get('answerDate')) + ": " + d.datum.get('value');
    }
  });
});