define('frontend/models/assessment/report-scale', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isSelected: true, // Set to true so that db persisted items are shown.
    report: _emberData.default.belongsTo("assessment/report"),
    scaleParticipants: _emberData.default.hasMany("assessment/reportScaleParticipant"),
    invitations: _emberData.default.hasMany("assessment/invitation"),
    scale: _emberData.default.belongsTo("assessment/scale"),
    phase: _emberData.default.attr('string'),
    format: _emberData.default.attr('string'),
    manual: _emberData.default.attr('boolean'),
    disabled: _emberData.default.attr('boolean'),
    blinding: _emberData.default.attr('string', { defaultValue: 'unblinded' }),
    displayOrder: _emberData.default.attr('number'),
    conditionalLogic: _emberData.default.attr(),
    planningDecision: _emberData.default.attr('string'),
    planningReason: _emberData.default.attr('string'),

    // Old report scales will not necessarily have the planning decision set, especially if the scale
    // is entirely manual. So fill anything unknown as appropriate on the basis of its disabled status.
    planningDecisionWithDefault: Ember.computed('planningDecision', 'disabled', function () {
      var decision = this.get("planningDecision");

      if (decision) {
        return decision;
      }

      if (this.get('disabled')) {
        return 'no_longer_in_plan';
      } else {
        return 'administer';
      }
    }),

    blinded: Ember.computed('blinding', function () {
      return this.get('blinding') == 'export_only';
    }),

    // Set this flag if you want all default participants to be used.
    defaultParticipants: _emberData.default.attr("boolean", { default: true }),

    independent: Ember.computed.alias('scale.independent'),

    associatedInvitations: Ember.computed("phase", "format", "report.invitations.[]", function () {
      return this.get("report.invitations").filterBy("phase", this.get("phase")).filterBy("format", this.get("format"));
    }),

    isEditable: Ember.computed("isNew", "isSelected", "maxInvitationStatus", function () {
      if (this.get("isNew") || !this.get("isSelected")) {
        return true;
      }

      var maxInvitationStatus = this.get("maxInvitationStatus");

      var isEditable = true;

      switch (maxInvitationStatus) {
        case "begun":
        case "complete":
          isEditable = false;
          break;
      }

      return isEditable;
    }),

    maxInvitationStatus: Ember.computed("associatedInvitations", function () {
      if (this.get("isNew") || !this.get("isSelected")) {
        return null;
      }

      var invitations = this.getWithDefault("associatedInvitations", []);

      if (invitations.length === 0) {
        return null;
      }

      return invitations.reduce(function (furthest, invitation) {
        if (invitation.get("maxStatus") > furthest.get("maxStatus")) {
          return invitation;
        } else {
          return furthest;
        }
      }).get("status");
    }),

    // Work out if the scale is suitable for the participants.
    scaleDemographicMismatch: Ember.computed('scale', 'report.patient', function () {
      var scale = this.get("scale");

      if (scale.content) {
        scale = scale.content;
      }

      return !scale.hasCorrectDemographics(this.get('report.patient'));
    })
  });
});