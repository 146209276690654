define('frontend/pods/components/reporting/report-table/complex-sort-comparator', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = complexSortComparator;
  var get = Ember.get,
      isNone = Ember.isNone;


  var COMPARISONS = {
    string: function string(a, b) {
      return ('' + a).localeCompare(b);
    },
    numeric: function numeric(a, b) {
      return a - b;
    },
    date: function date(a, b) {
      return (0, _moment.default)(a).diff(b);
    }
  };

  COMPARISONS.percent = COMPARISONS.numeric;
  COMPARISONS.adherence = COMPARISONS.numeric;
  COMPARISONS['date-range'] = COMPARISONS.date;
  COMPARISONS['patient-link'] = COMPARISONS.string;
  COMPARISONS['outcome-progress'] = COMPARISONS.numeric;
  COMPARISONS['attendance-rate'] = COMPARISONS.numeric;
  COMPARISONS['badged-indicator'] = COMPARISONS.numeric;

  // Returns a comparator function for the column type and direction requested for sorting. Null
  // values are always sunk to the bottom for all comparisons.
  function complexSortComparator(column, direction) {
    var comparison = COMPARISONS[column.type] || COMPARISONS.string;
    var modifier = direction === 'asc' ? 1 : -1;

    var getValue = function getValue(obj) {
      return obj;
    };
    // Temporary hack for complex outcome-progress column. With the modifier usage here we cannot
    // reuse sinkNullValues in the comparison function.
    if (column.type === 'outcome-progress') {
      getValue = function getValue(obj) {
        return get(obj, 'current.value') || get(obj, 'intake.value');
      };
    } else if (column.type === 'date-range') {
      getValue = function getValue(obj) {
        return obj[0];
      };
    }

    var key = column.sortBy ? column.sortBy : column.propertyName;
    var read = function read(row) {
      return get(row, key) && getValue(get(row, key));
    };

    return function (a, b) {
      var left = read(a);
      var right = read(b);

      var leftPresent = !isNone(left);
      var rightPresent = !isNone(right);

      if (leftPresent && rightPresent) {
        return comparison(left, right) * modifier;
      } else if (leftPresent) {
        return -1;
      } else if (rightPresent) {
        return 1;
      } else {
        return 0;
      }
    };
  }
});