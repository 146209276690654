define('frontend/mixins/application-route-session', ['exports', 'lodash', 'moment', 'frontend/utils/plugin-redirect'], function (exports, _lodash, _moment, _pluginRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var DEFAULT_LANGUAGE = 'en';

  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    sessionUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    institute: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    configurationByType: {
      provider: {
        routeAfterAuthentication: "provider.assessment.dashboard",
        routeAfterInvalidation: "provider.login",
        routeInactiveTab: "provider.inactive",
        routeExpiredPassword: "provider.user.update-password"
      },
      executive: {
        routeAfterAuthentication: "executive.assessment.dashboard",
        routeAfterInvalidation: "executive.login",
        routeInactiveTab: "executive.inactive",
        routeExpiredPassword: "provider.user.update-password"
      },
      patient: {
        routeAfterAuthentication: "patient.assessment.latest",
        routeAfterInvalidation: "patient.welcome",
        routeInactiveTab: "patient.inactive",
        routeExpiredPassword: "patient.user.update-password",
        routeIdConfirmation: "patient.user.confirm-identity"
      },
      related_person: {
        routeAfterAuthentication: "related-person.assessment.list",
        routeAfterInvalidation: "patient.welcome",
        routeInactiveTab: "patient.inactive",
        routeExpiredPassword: "related-person.user.update-password",
        routeIdConfirmation: "related-person.user.confirm-identity"
      },
      internal: {
        routeAfterAuthentication: "provider.assessment.dashboard",
        routeAfterInvalidation: "provider.login",
        routeInactiveTab: "provider.inactive",
        routeExpiredPassword: "provider.user.update-password"
      }
    },

    _subscribeToSessionEvents: function _subscribeToSessionEvents() {
      var _this = this,
          _arguments = arguments;

      Ember.A([['authenticationSucceeded', 'sessionAuthenticated'], ['invalidationSucceeded', 'sessionInvalidated']]).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            event = _ref2[0],
            method = _ref2[1];

        _this.get('session').on(event, Ember.run.bind(_this, function () {
          _this[method].apply(_this, _arguments);
        }));
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var userType = this.get("session.data.authenticated.user_type");

      sessionStorage.setItem("authenticationType", userType);

      this.get("metrics").identify({ distinctId: this.get("session.data.authenticated.id") });
      this.set('metrics.context.userId', this.get("session.data.authenticated.id"));
      var activeRoleType = this.get("session.data.authenticated.active_role_type");
      var activeRoleId = this.get("session.data.authenticated.active_role_id");
      var requiresIdConfirmation = this.get("session.data.authenticated.requires_id_confirmation");

      switch (activeRoleType) {
        case "provider":
          this.set("metrics.context.providerId", activeRoleId);

          break;
        case "patient":
          this.set("metrics.context.patientId", activeRoleId);
          break;
        case "related_person":
          this.set("metrics.context.relatedPersonId", activeRoleId);
          break;
      }

      var language = this.get("session.data.authenticated.language") || 'en';

      // This triggers the load of the current user and sentry context so that we keep track
      // of who is actually logged in.
      this.get("sessionUser.currentUser");

      this.get('intl').setLocale([language, DEFAULT_LANGUAGE]);

      this.get("metrics").trackEvent({ event: userType + ".login" });

      var passwordExpiryDate = this.get("session.data.authenticated.password_expired_at");

      if (passwordExpiryDate && (0, _moment.default)(passwordExpiryDate).isBefore(new Date())) {
        this.transitionTo(this.get("configurationByType")[userType].routeExpiredPassword);
        return;
      }

      if (requiresIdConfirmation && this.get("configurationByType")[userType].routeIdConfirmation) {
        this.transitionTo(this.get("configurationByType")[userType].routeIdConfirmation);
        return;
      }

      this._redirectAfterAuthentication();

      return this.get("session.data.authenticated");
    },
    _redirectAfterAuthentication: function _redirectAfterAuthentication(force) {
      var userType = this.get("session.data.authenticated.user_type");
      var attemptedTransition = this.get('session.attemptedTransition');

      if (!force && this.get("session.noTransitionOnAuthenticate")) {
        this.set("session.noTransitionOnAuthenticate", null);
      } else if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else if (this.get("session.targetUrl")) {
        var target = this.get("session.targetUrl");
        this.set('session.targetUrl', null);
        window.location = target;
      } else if (this.get("session.defaultTarget")) {
        // if userType is internal, and this is the internal institute, redirect to ops tools page
        // if the defaultTarget is a dashboard page
        // (defaultTarget is set based on the Dashboard feature flag in provider/login/route.js)
        if (!(0, _pluginRedirect.internalInstituteLoginRedirect)(this)) {
          var _target = this.get("session.defaultTarget");
          this.set('session.defaultTarget', null);
          this.transitionTo(_target);
        }
      } else {
        // if userType is internal, and this is the internal institute, redirect to ops tools page, not dashboard
        // otherwise, check to see if the schedule page is rolled out and redirect there instead of transitionTo ember dashboard
        if (!(0, _pluginRedirect.internalInstituteLoginRedirect)(this)) {
          var routeAfterAuth = this.get("configurationByType")[userType].routeAfterAuthentication;
          var scheduleRoute = "/app/providers/dashboard/schedule";
          if (routeAfterAuth === "provider.assessment.dashboard" && this.get("features.enableDashboardSchedule")) {
            this.transitionTo(scheduleRoute);
          } else {
            this.transitionTo(routeAfterAuth);
          }
        }
      }
    },
    sessionInvalidated: function sessionInvalidated() {

      if (!Ember.testing) {
        var lastAuthenticationType = sessionStorage.getItem("authenticationType") || "provider";
        var logoutReason = this.get('session.logoutReason');
        var attemptedTransition = this.get("session.attemptedTransition");

        this.get("metrics").identify({ distinctId: null });
        this.set('metrics.context.userId', null);
        this.set('metrics.context.providerId', null);
        this.set('metrics.context.relatedPersonId', null);
        this.set('metrics.context.patientId', null);

        localStorage.removeItem('impersonatingUserId');

        var params = "";

        if (logoutReason === "timeout") {
          params = "?autoLogout=true";
        }
        this.set('session.logoutReason', null);
        this.set('session.attemptedTransition', null);

        if (this.get("session.silentInvalidate")) {
          // Do nothing
          this.set("session.silentInvalidate", null);
        } else if (attemptedTransition) {
          if (logoutReason === "timeout") {
            this.get("router").transitionTo("expired");
          }
          window.location.replace(this._generateUrl(attemptedTransition));
        } else {
          if (logoutReason === "timeout") {
            this.get("router").transitionTo("expired");
          }
          var defaultUrl = this.get("router").generate(this.get("configurationByType")[lastAuthenticationType].routeAfterInvalidation);
          var url = this.getWithDefault('session.invalidationUrl', defaultUrl);

          window.location.replace('' + url + params);
        }
      }
    },
    _generateUrl: function _generateUrl(transition) {
      var routing = this.get('routing');
      var params = _lodash.default.values(transition.params).filter(function (param) {
        return _lodash.default.values(param).length;
      });
      return routing.generateURL(transition.targetName, params, transition.queryParams);
    },


    actions: {
      requestRedirectAfterAuthentication: function requestRedirectAfterAuthentication() {
        this._redirectAfterAuthentication();
      }
    }
  });
});