define('frontend/models/assessment/scale-templates', ['exports', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'npm:handlebars', 'frontend/models/assessment/automatic-report-question-calculator'], function (exports, _emberCpValidations, _validationHelper, _npmHandlebars, _automaticReportQuestionCalculator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.QuestionConstruct = exports.ScaleGroup = exports.FactoryMap = exports.Subscale = exports.Scale = exports.RawSurvey = exports.Question = exports.Page = exports.Survey = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  _npmHandlebars.default.registerHelper('json', function (context) {
    if (context || context === 0) {
      return JSON.stringify(context);
    } else {
      return 'null';
    }
  });

  _npmHandlebars.default.registerHelper('withNull', function (context) {
    if (context || context === 0) {
      return JSON.stringify(context);
    } else {
      return 'null';
    }
  });

  _npmHandlebars.default.registerHelper('translatedText', function (context) {
    var translated = {};

    if (context && context.en) {
      translated.en = context.en;
    }

    if (context && context.es) {
      translated.es = context.es;
    }

    return JSON.stringify(translated);
  });

  // For now, just hardcode the available dimensions. This should really be pulled from the API.
  var dimensionOptions = [{
    "id": "ta",
    "name": "Therapeutic Alliance"
  }, {
    "id": "motivation",
    "name": "Motivation for Treatment"
  }, {
    "id": "symptoms",
    "name": "Symptoms"
  }, {
    "id": "qol",
    "name": "Quality of Life"
  }, {
    "id": "hope",
    "name": "Hope"
  }, {
    "id": "goalsProblems",
    "name": "Goals / Problems"
  }, {
    "id": "family",
    "name": "Family / Caregiver Factors"
  }, {
    "id": "feedbackUse",
    "name": "Feedback Use"
  }];

  function deletePropertyPath(obj, path) {
    if (!obj || !path) {
      return;
    }

    if (typeof path === 'string') {
      path = path.split('.');
    }

    for (var i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
      if (typeof obj === 'undefined') {
        return;
      }
    }

    if (obj) {
      delete obj[path.pop()];
    }
  }

  function transformDatum(value, definition) {
    if (typeof value === 'string' && value.length === 0 && !definition.keepBlank) {
      value = null;
    }

    // For now, only transform the number.
    switch (definition.type) {
      case 'integer':
        return value !== null && typeof value !== 'undefined' ? parseInt(value) : null;
      case 'number':
        return value !== null && typeof value !== 'undefined' ? parseFloat(value) : null;
      case 'array':
        if (value && !Array.isArray(value)) {
          value = [value];
        }

        if (Ember.isEmpty(value) && !definition.keepEmpty) {
          return null;
        }

        return value;
      case 'string':
      case 'text':
        return value;
      case 'translatedText':
        {
          var translated = {};

          if (!value) {
            return {};
          }

          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = Object.entries(value)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var _ref = _step.value;

              var _ref2 = _slicedToArray(_ref, 2);

              var lang = _ref2[0];
              var text = _ref2[1];

              translated[lang] = transformDatum(text, { type: 'string' });
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          return translated;
        }
      case 'subList':
        return value.map(function (entry) {
          return transformListItem(entry, definition.columns);
        });
      default:
        break;
    }

    return value;
  }

  function transformData(data, definitions) {
    var transformed = {};

    if (!definitions) {
      return transformed;
    }

    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = Object.entries(definitions)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var _ref3 = _step2.value;

        var _ref4 = _slicedToArray(_ref3, 2);

        var key = _ref4[0];
        var definition = _ref4[1];

        var value = transformDatum(Ember.get(data, key), definition);

        transformed[key] = value;
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    return transformed;
  }

  function transformListItem(data, definitions) {
    var transformed = {};

    if (!definitions) {
      return transformed;
    }

    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = Object.values(definitions)[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var definition = _step3.value;

        var value = transformDatum(Ember.get(data, definition.property), definition);

        transformed[definition.property] = value;
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    return transformed;
  }

  function makeValidations(definitions, path) {
    var options = {};
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = Object.entries(definitions)[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var _ref5 = _step4.value;

        var _ref6 = _slicedToArray(_ref5, 2);

        var key = _ref6[0];
        var definition = _ref6[1];

        var validation = makeValidation(definition);
        if (path) {
          options[path + '.' + key] = validation; // TODO is this necessary
        } else {
          options[key] = validation;
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    return (0, _emberCpValidations.buildValidations)(options);
  }

  function makeValidation(definition) {
    if (definition.required) {
      return (0, _emberCpValidations.validator)('presence', true);
    }
  }

  function defaultParameterValue(parameter) {
    switch (_typeof(parameter.defaultValue)) {
      case "undefined":
        return null;
      case "function":
        return parameter.defaultValue();
      default:
        return parameter.defaultValue;
    }
  }

  var FactoryMap = {};

  var BaseClass = function BaseClass(name, defaults) {
    defaults.collections = defaults.collections || {};
    defaults.propertyDefinitions = defaults.propertyDefinitions || {};
    defaults.models = defaults.models || {};
    defaults.emptyContents = defaults.emptyContents || function () {
      return {};
    };

    var defaultProperties = {};

    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;

    try {
      for (var _iterator5 = Object.entries(defaults.propertyDefinitions)[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var _ref7 = _step5.value;

        var _ref8 = _slicedToArray(_ref7, 2);

        var key = _ref8[0];
        var value = _ref8[1];

        switch (_typeof(value.defaultValue)) {
          case "undefined":
            defaultProperties[key] = null;
            break;
          case "function":
            defaultProperties[key] = value.defaultValue();
            break;
          default:
            defaultProperties[key] = value.defaultValue;
        }
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }

    var propertyValidations = makeValidations(defaults.propertyDefinitions);

    var propertyObject = Ember.Object.extend(propertyValidations, _validationHelper.default, {});

    // TODO write validator for object which validates template options and properties
    var baseValidationOptions = {
      properties: (0, _emberCpValidations.validator)('belongs-to')
    };

    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = Object.keys(defaults.collections)[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var _key = _step6.value;

        baseValidationOptions[_key] = (0, _emberCpValidations.validator)('has-many');
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    var _iteratorNormalCompletion7 = true;
    var _didIteratorError7 = false;
    var _iteratorError7 = undefined;

    try {
      for (var _iterator7 = Object.keys(defaults.models)[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
        var _key2 = _step7.value;

        baseValidationOptions[_key2] = (0, _emberCpValidations.validator)('belongs-to');
      }
    } catch (err) {
      _didIteratorError7 = true;
      _iteratorError7 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion7 && _iterator7.return) {
          _iterator7.return();
        }
      } finally {
        if (_didIteratorError7) {
          throw _iteratorError7;
        }
      }
    }

    var baseValidation = (0, _emberCpValidations.buildValidations)(baseValidationOptions);

    return {
      name: name,
      counter: 0,
      blankObject: Ember.Object.extend(baseValidation, _validationHelper.default, {
        scaleTemplates: Ember.inject.service(),
        templateClassName: name,
        toJson: function toJson() {
          var contents = this.get("contents");
          var propertyData = this.get("properties");
          var templateOptions = this.get("templateOptions");
          var base = contents ? JSON.parse(JSON.stringify(contents)) : defaults.emptyContents(); // Easiest way of getting a clone
          var postprocessors = [];
          if (defaults.ensureStructure) {
            defaults.ensureStructure(base);
          }

          if (templateOptions) {
            base._templateOptions = JSON.parse(JSON.stringify(templateOptions));
          }

          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = Object.entries(defaults.propertyDefinitions)[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var _ref9 = _step8.value;

              var _ref10 = _slicedToArray(_ref9, 2);

              var _key3 = _ref10[0];
              var _value = _ref10[1];

              var result = Ember.get(propertyData, _key3);

              if (_value.toJson) {
                result = _value.toJson(result);
              } else {
                // For now, only transform the number.
                result = transformDatum(result, _value);
              }

              var sendKey = false;

              // Send the key if it's present, and not an empty object.
              if (result !== null && typeof result !== 'undefined') {
                sendKey = (typeof result === 'undefined' ? 'undefined' : _typeof(result)) !== 'object' || Object.keys(result).length > 0;
              } else if (_value.sendNullKey) {
                sendKey = true;
              }

              if (sendKey) {
                Ember.set(base, _value.path || _key3, result);
              }

              if (_value.postprocess) {
                postprocessors.addObject(_value.postprocess);
              }
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }

          var _iteratorNormalCompletion9 = true;
          var _didIteratorError9 = false;
          var _iteratorError9 = undefined;

          try {
            for (var _iterator9 = Object.entries(defaults.collections)[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
              var _ref11 = _step9.value;

              var _ref12 = _slicedToArray(_ref11, 2);

              var _key4 = _ref12[0];
              var _value2 = _ref12[1];

              var childJson = this.get(_key4).map(function (c) {
                return c.toJson();
              });
              Ember.set(base, _value2.path, childJson);
            }
          } catch (err) {
            _didIteratorError9 = true;
            _iteratorError9 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion9 && _iterator9.return) {
                _iterator9.return();
              }
            } finally {
              if (_didIteratorError9) {
                throw _iteratorError9;
              }
            }
          }

          var _iteratorNormalCompletion10 = true;
          var _didIteratorError10 = false;
          var _iteratorError10 = undefined;

          try {
            for (var _iterator10 = Object.entries(defaults.models)[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
              var _ref13 = _step10.value;

              var _ref14 = _slicedToArray(_ref13, 2);

              var _key5 = _ref14[0];
              var _value3 = _ref14[1];

              if (this.get(_key5)) {
                Ember.set(base, _value3.path, this.get(_key5).toJson());
              }
            }
          } catch (err) {
            _didIteratorError10 = true;
            _iteratorError10 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion10 && _iterator10.return) {
                _iterator10.return();
              }
            } finally {
              if (_didIteratorError10) {
                throw _iteratorError10;
              }
            }
          }

          postprocessors.forEach(function (processor) {
            processor(base);
          });

          return base;
        },
        updateContent: function updateContent() {
          var templateParameters = this.get("templateParameters");
          var templateOptions = this.get("templateOptions");
          var template = this.get("scaleTemplates").lookup(this.get("templateClassName"), templateOptions.templateId);
          var transformedParameters = transformData(templateParameters, template.parameters);
          Ember.set(templateOptions, 'parameters', transformedParameters);

          if (template.writeContent) {
            this.set("contents", template.writeContent(transformedParameters, this.get('properties')));
          } else {
            var handlebarsTemplate = _npmHandlebars.default.compile(template.template);
            var contentJson = JSON.parse(handlebarsTemplate(transformedParameters));
            this.set("contents", contentJson);
          }
        },
        updateContentAndNotify: function updateContentAndNotify() {
          this.updateContent();
          // TODO: add in validation for templates
          this.notifyPropertyChange('dataHasChanged');
        },
        updatePropertiesAndNotify: function updatePropertiesAndNotify() {
          this.notifyPropertyChange('dataHasChanged');
        },
        regenerateTemplates: function regenerateTemplates() {
          var _this = this;

          Object.keys(this.get('models')).forEach(function (model) {
            _this.get(model).regenerateTemplates();
          });

          Object.keys(this.get('collections')).forEach(function (collection) {
            _this.get(collection).forEach(function (colObj) {
              colObj.regenerateTemplates();
            });
          });

          if (this.get("templateParameters")) {
            this.updateContent();
          }
        }
      }, defaults),
      fromJson: function fromJson(json, registry, container) {
        // Quickest way to get a deep clone of the data is to json stringify and parse.
        var contents = JSON.parse(JSON.stringify(json));

        var templateOptions = contents._templateOptions;
        var templateParameters = null;
        delete contents._templateOptions;

        if (templateOptions && templateOptions.parameters) {
          var scaleTemplates = container.lookup("service:scale-templates");
          var template = scaleTemplates.lookup(name, templateOptions.templateId);

          if (template.parameters) {
            var templateValidations = makeValidations(template.parameters);

            // If they have a different number of keys, assume we need to upgrade the missing data with some new field.
            if (Object.keys(templateOptions.parameters).length !== Object.keys(template.parameters).length) {
              var _iteratorNormalCompletion11 = true;
              var _didIteratorError11 = false;
              var _iteratorError11 = undefined;

              try {
                for (var _iterator11 = Object.entries(template.parameters)[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
                  var _ref15 = _step11.value;

                  var _ref16 = _slicedToArray(_ref15, 2);

                  var _key6 = _ref16[0];
                  var definition = _ref16[1];

                  if (typeof templateOptions.parameters[_key6] === 'undefined') {
                    templateOptions.parameters[_key6] = defaultParameterValue(definition);
                  }
                }
              } catch (err) {
                _didIteratorError11 = true;
                _iteratorError11 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion11 && _iterator11.return) {
                    _iterator11.return();
                  }
                } finally {
                  if (_didIteratorError11) {
                    throw _iteratorError11;
                  }
                }
              }
            }

            templateParameters = Ember.Object.extend(templateValidations).create(container.ownerInjection(), templateOptions.parameters);
          }
        }

        var propertyData = propertyObject.create(container.ownerInjection());

        var _iteratorNormalCompletion12 = true;
        var _didIteratorError12 = false;
        var _iteratorError12 = undefined;

        try {
          for (var _iterator12 = Object.entries(defaults.propertyDefinitions)[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
            var _ref17 = _step12.value;

            var _ref18 = _slicedToArray(_ref17, 2);

            var _key7 = _ref18[0];
            var _definition = _ref18[1];

            var _value4 = Ember.get(contents, _definition.path || _key7);

            if (typeof _value4 === 'undefined') {
              _value4 = defaultParameterValue(_definition);
            }

            propertyData.set(_key7, _value4);
            deletePropertyPath(contents, _definition.path || _key7);
          }
        } catch (err) {
          _didIteratorError12 = true;
          _iteratorError12 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion12 && _iterator12.return) {
              _iterator12.return();
            }
          } finally {
            if (_didIteratorError12) {
              throw _iteratorError12;
            }
          }
        }

        var obj = this.blankObject.create(container.ownerInjection(), {
          templateOptions: templateOptions,
          contents: contents,
          properties: propertyData,
          templateParameters: templateParameters
        });

        var _loop = function _loop(_key8, _definition2) {
          var childJsonCollection = Ember.get(json, _definition2.path);

          if (childJsonCollection) {
            var objCollection = childJsonCollection.map(function (childJson) {
              return FactoryMap[_definition2.factory].fromJson(childJson, registry, container);
            });
            obj.set(_key8, objCollection);
          } else {
            obj.set(_key8, []);
          }
          deletePropertyPath(contents, _definition2.path || _key8);
        };

        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = Object.entries(defaults.collections)[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var _ref19 = _step13.value;

            var _ref20 = _slicedToArray(_ref19, 2);

            var _key8 = _ref20[0];
            var _definition2 = _ref20[1];

            _loop(_key8, _definition2);
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }

        var _iteratorNormalCompletion14 = true;
        var _didIteratorError14 = false;
        var _iteratorError14 = undefined;

        try {
          for (var _iterator14 = Object.entries(defaults.models)[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
            var _ref21 = _step14.value;

            var _ref22 = _slicedToArray(_ref21, 2);

            var _key9 = _ref22[0];
            var _definition3 = _ref22[1];

            if (Ember.get(json, _definition3.path)) {
              obj.set(_key9, FactoryMap[_definition3.factory].fromJson(Ember.get(json, _definition3.path), registry, container));
              deletePropertyPath(contents, _definition3.path || _key9);
            } else {
              obj.set(_key9, null);
            }
          }
        } catch (err) {
          _didIteratorError14 = true;
          _iteratorError14 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion14 && _iterator14.return) {
              _iterator14.return();
            }
          } finally {
            if (_didIteratorError14) {
              throw _iteratorError14;
            }
          }
        }

        if (registry && defaults.registryKey) {
          registry.register(obj, Ember.get(obj, 'properties.' + defaults.registryKey), name);
        }

        return obj;
      },
      nextMnemonic: function nextMnemonic() {
        return '' + name + this.counter++;
      },
      createNew: function createNew(container) {
        var obj = this.blankObject.create(container.ownerInjection(), { properties: propertyObject.create(container.ownerInjection(), defaultProperties) });

        var _iteratorNormalCompletion15 = true;
        var _didIteratorError15 = false;
        var _iteratorError15 = undefined;

        try {
          for (var _iterator15 = Object.keys(defaults.collections)[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
            var _key10 = _step15.value;

            obj.set(_key10, Ember.A());
          }
        } catch (err) {
          _didIteratorError15 = true;
          _iteratorError15 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion15 && _iterator15.return) {
              _iterator15.return();
            }
          } finally {
            if (_didIteratorError15) {
              throw _iteratorError15;
            }
          }
        }

        var _iteratorNormalCompletion16 = true;
        var _didIteratorError16 = false;
        var _iteratorError16 = undefined;

        try {
          for (var _iterator16 = Object.entries(defaults.models)[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
            var _ref23 = _step16.value;

            var _ref24 = _slicedToArray(_ref23, 2);

            var _key11 = _ref24[0];
            var _value5 = _ref24[1];

            obj.set(_key11, FactoryMap[_value5.factory].createNew(container));
          }
        } catch (err) {
          _didIteratorError16 = true;
          _iteratorError16 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion16 && _iterator16.return) {
              _iterator16.return();
            }
          } finally {
            if (_didIteratorError16) {
              throw _iteratorError16;
            }
          }
        }

        return obj;
      },
      duplicate: function duplicate(object, registry) {
        return this.fromJson(object.toJson(), registry, Ember.getOwner(object));
      },
      createNewFromTemplate: function createNewFromTemplate(template, registry, container, defaultParameters) {
        if (!defaultParameters) {
          defaultParameters = {};
        }

        var templateValidations = {};
        var baseValidationsForTemplate = {};

        if (template.parameters) {
          baseValidationsForTemplate = (0, _emberCpValidations.buildValidations)({
            "templateParameters": (0, _emberCpValidations.validator)("belongs-to")
          });
          templateValidations = makeValidations(template.parameters);
          Object.keys(template.parameters).forEach(function (parameterKey) {
            var parameter = template.parameters[parameterKey];
            if (typeof defaultParameters[parameterKey] === 'undefined') {
              defaultParameters[parameterKey] = defaultParameterValue(parameter);
            }
          });
        }

        var obj = this.blankObject.extend(baseValidationsForTemplate).create(container.ownerInjection(), {
          properties: propertyObject.create(container.ownerInjection(), defaultProperties),
          mnemonic: this.nextMnemonic(),
          templateParameters: Ember.Object.extend(templateValidations).create(container.ownerInjection(), defaultParameters),
          templateOptions: {
            templateId: template.id,
            parameters: defaultParameters
          },
          content: null,
          contentModified: false
        });

        var _iteratorNormalCompletion17 = true;
        var _didIteratorError17 = false;
        var _iteratorError17 = undefined;

        try {
          for (var _iterator17 = Object.keys(defaults.collections)[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
            var _key12 = _step17.value;

            obj.set(_key12, Ember.A());
          }
        } catch (err) {
          _didIteratorError17 = true;
          _iteratorError17 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion17 && _iterator17.return) {
              _iterator17.return();
            }
          } finally {
            if (_didIteratorError17) {
              throw _iteratorError17;
            }
          }
        }

        obj.updateContent();

        if (registry && defaults.registryKey) {
          registry.register(obj, Ember.get(obj, 'properties.' + defaults.registryKey), name);
        }

        return obj;
      },
      replaceTemplate: function replaceTemplate(template, object, registry, container) {
        var existingJson = object.toJson();
        var newJson = this.createNewFromTemplate(template, registry, container);

        if (registry) {
          registry.unregister(object, Ember.get(object, 'properties.' + defaults.registryKey), name);
        }

        var oldJsonTemplateParameters = existingJson._templateOptions.parameters;

        var _iteratorNormalCompletion18 = true;
        var _didIteratorError18 = false;
        var _iteratorError18 = undefined;

        try {
          for (var _iterator18 = Object.keys(oldJsonTemplateParameters)[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
            var _key13 = _step18.value;

            if (typeof newJson.templateOptions.parameters[_key13] !== 'undefined') {
              newJson.templateOptions.parameters[_key13] = oldJsonTemplateParameters[_key13];
            }
          }
        } catch (err) {
          _didIteratorError18 = true;
          _iteratorError18 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion18 && _iterator18.return) {
              _iterator18.return();
            }
          } finally {
            if (_didIteratorError18) {
              throw _iteratorError18;
            }
          }
        }

        existingJson._templateOptions = newJson.templateOptions;
        var finishedObject = this.fromJson(existingJson, registry, container);
        finishedObject.updateContent();

        if (registry && defaults.registryKey) {
          registry.register(finishedObject, Ember.get(finishedObject, 'properties.' + defaults.registryKey), name);
        }
        return finishedObject;
      }
    };
  };

  var QuestionConstruct = BaseClass('questionConstruct', {
    registryKey: 'mnemonic',
    defaultSummaryComponent: 'scale-creation/question-construct-summary',
    propertyDefinitions: {
      mnemonic: {
        name: "Mnemonic",
        description: "The variable name for this question, to be used when doing scoring, etc.",
        type: "text"
      },
      report_text: {
        name: "Report Text",
        path: 'report_text',
        description: "A shorter version of the question used in the provider report",
        type: "text"
      },
      display_data: {
        advanced: true,
        name: 'Display data',
        type: 'json'
      }
    }
  });

  var Question = BaseClass('question', {
    registryKey: 'shortname',
    defaultSummaryComponent: 'scale-creation/question-summary',
    propertyDefinitions: {
      shortname: {
        name: "Variable Name",
        description: "The variable name for this question, to be used when doing scoring, etc.",
        type: "text"
      },
      report_text: {
        name: "Report Text",
        path: 'display_data.report_text',
        description: "A shorter version of the question used in the provider report",
        type: "text"
      },
      report_display: {
        name: "Report Display",
        advanced: true,
        path: 'display_data.details',
        description: "How the question should be displayed in the report",
        defaultValue: function defaultValue() {
          return {
            automatic: true
          };
        },
        valueIsDefault: function valueIsDefault(currentValue) {
          return currentValue && currentValue.automatic;
        },
        postprocess: function postprocess(document) {
          if (!document.display_data.details.automatic) {
            return;
          }

          return (0, _automaticReportQuestionCalculator.default)(document);
        },

        type: "questionDisplayData"
      },
      beneficial: {
        name: "Beneficial",
        path: 'properties.beneficial',
        description: "If higher score on this question is a good thing",
        type: "checkbox"
      },
      question_construct: {
        advanced: true,
        name: "Question Construct",
        path: 'properties.question_construct',
        description: "Which question construct this question belongs to",
        type: 'entitySelect',
        registryType: 'questionConstruct',
        registryKey: 'mnemonic',
        defaultValue: null,
        toJson: function toJson(value) {
          // Ensure we don't send empty question bank
          if (Ember.isEmpty(value)) {
            return null;
          }

          return value;
        }
      },
      show_rule: {
        advanced: true,
        name: "Show Rule",
        path: 'properties.showRule',
        description: "JSON object of advanced show rules",
        type: "json"
      },
      units: {
        advanced: true,
        name: "Units",
        path: 'display_data.units',
        description: "A unit indication to be put on the report answer",
        type: "text"
      },
      beforeText: {
        advanced: true,
        name: "Before Text",
        path: "properties.beforeText",
        description: "Introductory text that goes before the question, e.g. instructions for interview administration",
        type: 'translatedText'
      },
      afterText: {
        advanced: true,
        name: "After Text",
        path: "properties.afterText",
        description: "Text that goes after the question, e.g. instructions for interview scoring",
        type: 'translatedText'
      },
      iconMap: {
        advanced: true,
        path: "display_data.answer_icons",
        name: 'Icon Map',
        description: "A map of answer values to icons",
        type: "json"
      }
    },
    emptyContents: function emptyContents() {
      return { display_data: {}, properties: {} };
    },
    ensureStructure: function ensureStructure(object) {
      if (!object.display_data) {
        object.display_data = {};
      }
      if (!object.properties) {
        object.properties = {};
      }
    }
  });

  var Page = BaseClass('page', {
    defaultSummaryComponent: 'scale-creation/page-summary',
    collections: {
      questions: {
        path: 'questions',
        factory: 'question'
      }
    },
    propertyDefinitions: {
      show_rule: {
        advanced: true,
        name: "Show Rule",
        path: 'properties.showRule',
        description: "JSON object of advanced show rules",
        type: "json"
      },
      footer: {
        advanced: true,
        name: "Page Footer",
        path: 'properties.footer',
        description: "Page footer, placed persistently at the top of the page",
        type: "translatedText"
      },
      dependentVariables: {
        advanced: true,
        name: "Dependent Variables",
        path: 'properties.dependent_variables',
        description: "Which variables this page is dependent on to display.",
        type: "array"
      }
    }
  });

  var Survey = BaseClass('survey', {
    defaultSummaryComponent: 'scale-creation/survey-summary',
    registryKey: 'mnemonic',
    propertyDefinitions: {
      name: {
        name: "Name",
        description: "Shown during bulk editing",
        type: "text"
      },
      mnemonic: {
        name: "Mnemonic",
        description: "Uniquely identifies this survey component",
        type: "text"
      },
      color_scheme: {
        name: 'Color Scheme',
        path: 'content.color_scheme',
        advanced: true,
        type: 'select',
        options: [{ id: 'default', name: 'Default' }, { id: 'white', name: 'Black on White Background' }]
      },
      dependent_properties: {
        name: 'Dependent Properties',
        type: 'array',
        advanced: true
      },
      after_module_hook: {
        name: 'After Module Hook',
        type: 'text',
        advanced: true
      }
    },
    collections: {
      pages: {
        path: 'content.pages',
        factory: 'page'
      }
    },
    emptyContents: function emptyContents() {
      return { content: {} };
    }
  });

  var Scorer = BaseClass('scorer', {
    propertyDefinitions: {
      domain: {
        name: "Score domain",
        type: 'select',
        options: [{ id: 'raw', name: 'Raw' }, { id: 'normed', name: 'Normed' }, { id: 'interpreted', name: 'Interpreted' }]
      },
      population_mnemonic: {
        name: "Population Mnemonic",
        type: 'text',
        defaultValue: null
      },
      score_type: {
        name: "Score type",
        type: 'select',
        options: [{ id: 'numerical', name: 'Numerical' }, { id: 'categorical', name: 'Categorical' }, { id: 'unscored', name: 'Unscored' }],
        defaultValue: 'numerical'
      },
      min_value: {
        name: 'Min value',
        type: 'number'
      },
      min_interpretation: {
        name: 'Min interpretion',
        type: 'text'
      },
      min_interpretation_short: {
        name: 'Min interpretion short',
        type: 'text'
      },
      max_value: {
        name: 'Max value',
        type: 'number'
      },
      max_interpretation: {
        name: 'Max interpretion',
        type: 'text'
      },
      max_interpretation_short: {
        name: 'Max interpretion short',
        type: 'text'
      },
      threshold_data: {
        name: 'Thresholds',
        type: 'thresholds',
        defaultValue: function defaultValue() {
          return [];
        },
        toJson: function toJson(value) {
          if (value) {
            return value.map(function (r) {
              var base = {
                max_value: parseFloat(r.max_value),
                min_value: parseFloat(r.min_value),
                mnemonic: r.mnemonic,
                class: r.class,
                graph_label: transformDatum(r.graph_label, { type: 'string' })
              };
              return base;
            });
          }
        }
      },
      display_data: {
        advanced: true,
        name: 'Display data',
        type: 'json'
      },
      value_map: {
        name: 'Value map',
        description: "Supply a JSON map of what values need to be transformed to before they are scored.",
        type: 'json',
        path: 'params.value_map',
        advanced: true
      },
      recode_map: {
        name: 'Recode map',
        description: "Supply a JSON map on a per-question basis to work out what the score for each option for each question is",
        type: 'json',
        path: 'params.recode_map',
        advanced: true
      }
    },
    ensureStructure: function ensureStructure(object) {
      if (!object.params) {
        object.params = {};
      }
    }
  });

  var Norm = BaseClass('norm', {
    propertyDefinitions: {
      population_mnemonic: {
        name: "Population Mnemonic",
        type: 'text',
        defaultValue: "general"
      }
    }
  });

  var Subscale = BaseClass('subscale', {
    defaultSummaryComponent: 'scale-creation/scale-summary',
    collections: {
      scorers: {
        path: 'scorers',
        factory: 'scorer'
      },
      norms: {
        path: 'norms',
        factory: 'norm'
      }
    },
    propertyDefinitions: {
      mnemonic: {
        name: "Mnemonic",
        required: true,
        type: 'text',
        description: 'Unique key for this subscale. Must be globally unique. Generally do not use spaces'
      },
      family_mnemonic: {
        name: "Family Mnemonic",
        required: true,
        type: 'text',
        description: 'Unique key for this "family" of measure. E.g. if you have a load of different versions with different ages, keep this constant.'
      },
      name: {
        name: "Name",
        required: true,
        type: 'text',
        description: 'Long name to be displayed where we have the most space. E.g. "Patient Health Questionnaire - 9 Item"'
      },
      shortname: {
        name: "Shortname",
        required: true,
        type: 'text',
        description: 'Shorter name to be displayed where we have a medium amount of space. E.g. "PHQ-9"'
      },
      nanoname: {
        name: "Nanoname (< 16 letters)",
        type: 'text',
        description: 'Shortest name. Can only be 15 characters. Used where space is very tight. E.g. "PHQ-9"'
      },
      measure_type: {
        name: "Measure type",
        type: 'text',
        defaultValue: 'standard',
        description: 'This is a legacy internal concept that should be set to "standard" unless you talk to engineering'
      },
      questions: {
        name: 'Question subset',
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: null,
        description: 'If there are more questions than are on the form than belong in this subscale - e.g. this is only 4 items of the 12 - then set them here. This affects what shows up in viewing the results, and the scoring',
        toJson: function toJson(value) {
          // Ensure we don't send empty question bank
          if (Ember.isEmpty(value)) {
            return null;
          }

          return value;
        }
      },
      display_data: {
        name: 'Display data',
        type: 'json',
        description: 'Leave this alone unless you talk to Engineering'
      },
      beneficial: {
        name: "Beneficial?",
        type: 'checkbox',
        defaultValue: false,
        description: 'Is a higher value on this scale good? If checked, scale is a symptom scale and higher scores are bad. If unchecked, its a strength and high scores are good'
      },
      role: {
        name: "Scale Role",
        type: 'select',
        options: [{ id: 'administrative', name: 'Administrative' }, { id: 'combined_total', name: 'Combined Total' }, { id: 'per_respondent_total', name: 'Per Respondent Total' }, { id: 'combined_subscale', name: 'Combined Subscale' }, { id: 'per_respondent_subscale', name: 'Per Respondent Subscale' }, { id: 'intermediate', name: 'Intermediate' }, { id: 'alert', name: 'Alert' }, { id: 'aggregate', name: 'Aggregate' }],
        description: '\n        Select what this scale does. You almost always want \'Per Respondent Total\' or \'Per Respondent Subscale\'\n        <ul>\n          <li>Administrative - unscored and basically just a set of administrative forms</li>\n          <li>Combined Total - aggregates many respondents data into a total score</li>\n          <li>Per Respondent Total - per respondent, and is a total score (i.e. not a subscale)</li>\n          <li>Per Respondent Subscale - per respondent, and is a subscale, i.e. a breakdown of a higher order scale</li>\n          <li>Combined Subscale - aggregates many respondents of a subscale</li>\n          <li>Intermediate - never to be seen by a user but just holds a calculation</li>\n          <li>Alert - used for risk alerting only</li>\n          <li>Aggregate - used for cross scale norm benchmarking</li>\n        </ul>\n      '
      },
      default_dimension: {
        name: "Default Dimension",
        type: 'select',
        options: dimensionOptions
      },
      construct: {
        name: "Construct",
        type: 'select',
        options: dimensionOptions
      },
      scoring_text: {
        name: "Scoring Text",
        type: 'text'
      },
      report_short_description: {
        name: "Report Short Description",
        type: "text"
      },
      acute_change_type: {
        name: 'Acute Change',
        type: 'select',
        options: [{ id: 'rci', name: 'RCI' }, { id: 'categorical', name: 'Categorical' }]
      },
      acute_change_params: {
        name: "Acute Change Params",
        description: "JSON object of acute change config",
        type: "json"
      },
      trend_type: {
        name: 'Trend Change',
        type: 'select',
        options: [{ id: 'avg', name: 'Average' }, { id: 'categorical', name: 'Categorical' }, { id: 'change_since_intake', name: 'Change Since Intake' }]
      },
      trend_params: {
        name: "Trend Params",
        description: "JSON object of trend config",
        type: "json"
      },
      clinical_category_type: {
        name: 'Clinical Category',
        type: 'select',
        options: [{ id: 'threshold', name: 'Threshold' }]
      },
      clinical_category_params: {
        name: "Clinical Category Params",
        description: "JSON object of clinical category config",
        type: "json"
      },
      severity_category_type: {
        name: 'Severity Category',
        type: 'select',
        options: [{ id: 'threshold', name: 'Threshold' }]
      },
      severity_category_params: {
        name: "Severity Category Params",
        description: "JSON object of severity category config",
        type: "json"
      },
      administration_scheme_class_name: {
        name: "Advanced Administration Scheme",
        description: "Class name of the advanced administrations scheme, e.g. for the SFSS.",
        type: "text"
      },
      unadministrable: {
        name: 'Unadministrable',
        type: 'checkbox',
        defaultValue: false
      },
      survey_mnemonics: {
        name: 'Survey Mnemonics',
        type: 'entitySubset',
        registryType: 'survey',
        registryKey: 'mnemonic',
        defaultValue: null,
        toJson: function toJson(value) {
          // Ensure we don't send empty bank
          if (Ember.isEmpty(value)) {
            return null;
          }

          return value;
        }
      },
      allowed_participants: {
        name: "Allowed participants",
        type: 'multipleSelect',
        options: [{ id: 'patient', name: 'Patient' }, { id: 'provider', name: 'Provider' }, { id: 'guardian', name: 'Guardian' }, { id: 'teacher', name: 'Teacher' }],
        defaultValue: function defaultValue() {
          return null;
        }
      },
      norse_threshold_open: {
        name: 'NORSE Threshold Open',
        type: 'number',
        advanced: true
      },
      norse_threshold_close: {
        name: 'NORSE Threshold Close',
        type: 'number',
        advanced: true
      },
      norse_patient_adjustment: {
        name: 'NORSE Patient Adjustment',
        type: 'number',
        advanced: true
      },
      norse_trigger_question: {
        name: 'NORSE Trigger Question',
        type: 'entitySelect',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: null,
        advanced: true
      }
    }
  });

  var Scale = BaseClass('scale', {
    defaultSummaryComponent: 'scale-creation/scale-summary',
    collections: {
      scales: {
        path: 'scales',
        factory: 'scale'
      },
      subscales: {
        path: 'subscales',
        factory: 'subscale'
      },
      scorers: {
        path: 'scorers',
        factory: 'scorer'
      },
      norms: {
        path: 'norms',
        factory: 'norm'
      }
    },
    propertyDefinitions: {
      mnemonic: {
        name: "Mnemonic",
        required: true,
        type: 'text',
        description: 'Unique key for this subscale. Must be globally unique. Generally do not use spaces'
      },
      family_mnemonic: {
        name: "Family Mnemonic",
        required: true,
        type: 'text',
        description: 'Unique key for this "family" of measure. E.g. if you have a load of different versions with different ages, keep this constant.'
      },
      name: {
        name: "Name",
        required: true,
        type: 'text',
        description: 'Long name to be displayed where we have the most space. E.g. "Patient Health Questionnaire - 9 Item"'
      },
      shortname: {
        name: "Shortname",
        required: true,
        type: 'text',
        description: 'Shorter name to be displayed where we have a medium amount of space. E.g. "PHQ-9"'
      },
      nanoname: {
        name: "Nanoname (< 16 letters)",
        type: 'text',
        description: 'Shortest name. Can only be 15 characters. Used where space is very tight. E.g. "PHQ-9"'
      },
      description: {
        name: "Description",
        type: 'text',
        description: "This text appears as information on the scale when selecting it"
      },
      guide_url: {
        name: "Guide URL",
        type: 'text',
        description: "You can supply a URL that takes you to a link or PDF, but this only works on the new report so we've mostly just left it"
      },
      independent: {
        name: 'Independent',
        type: 'checkbox',
        defaultValue: true,
        description: "Can this scale be selected directly for administration independently. If false, it can't be selected and must be administered as part of something else"
      },
      estimated_time: {
        name: "Estimated time",
        type: 'integer',
        description: "Estimated number of seconds it will take to complete. Traditionally I've just guessed at 10 seconds per question."
      },
      billable_units: {
        name: "Billable Units",
        type: 'integer',
        description: "Number of units this scale can be billed as. This should almost always be 1, if it's validated and published, or 0 if it's a custom or administrative measure."
      },
      measure_type: {
        name: "Measure type",
        type: 'text',
        defaultValue: 'standard',
        description: 'This is a legacy internal concept that should be set to "standard" unless you talk to engineering'
      },
      parent_mnemonic: {
        name: "Parent mnemonic (deprecated)",
        type: 'text',
        description: "Deprecated - ignore"
      },
      allowed_participants: {
        name: "Allowed participants",
        type: 'multipleSelect',
        options: [{ id: 'patient', name: 'Patient' }, { id: 'provider', name: 'Provider' }, { id: 'guardian', name: 'Guardian' }, { id: 'teacher', name: 'Teacher' }],
        description: "Which kind of participants can take this scale. This is slightly different from default participants below in that it says what can be done, not what is done if you select it without changing anything.",
        defaultValue: function defaultValue() {
          return ['patient'];
        }
      },
      default_participants: {
        name: "Default participants",
        type: 'multipleSelect',
        options: [{ id: 'patient', name: 'Patient' }, { id: 'provider', name: 'Provider' }, { id: 'guardian', name: 'Guardian' }, { id: 'teacher', name: 'Teacher' }],
        description: "Which kind of participants will be default take this scale. This is slightly different from allowed participants above. In the vast majority of cases set this to the same as allowed_participants",
        defaultValue: function defaultValue() {
          return ['patient'];
        }
      },
      role: {
        name: "Scale Role",
        type: 'select',
        options: [{ id: 'administrative', name: 'Administrative' }, { id: 'combined_total', name: 'Combined Total' }, { id: 'per_respondent_total', name: 'Per Respondent Total' }, { id: 'combined_subscale', name: 'Combined Subscale' }, { id: 'per_respondent_subscale', name: 'Per Respondent Subscale' }, { id: 'intermediate', name: 'Intermediate' }, { id: 'alert', name: 'Alert' }],
        description: '\n        Select what this scale does. You almost always want \'Per Respondent Total\' or \'Per Respondent Subscale\'\n        <ul>\n          <li>Administrative - unscored and basically just a set of administrative forms</li>\n          <li>Combined Total - aggregates many respondents data into a total score</li>\n          <li>Per Respondent Total - per respondent, and is a total score (i.e. not a subscale)</li>\n          <li>Per Respondent Subscale - per respondent, and is a subscale, i.e. a breakdown of a higher order scale</li>\n          <li>Combined Subscale - aggregates many respondents of a subscale</li>\n          <li>Intermediate - never to be seen by a user but just holds a calculation</li>\n          <li>Alert - used for risk alerting only</li>\n          <li>Aggregate - used for cross scale norm benchmarking</li>\n        </ul>\n      '
      },
      default_dimension: {
        name: "Default Dimension",
        type: 'select',
        options: dimensionOptions
      },
      construct: {
        name: "Construct",
        type: 'select',
        options: dimensionOptions
      },
      gender: {
        name: 'Gender',
        type: 'select',
        options: [{ id: 'M', name: 'Male' }, { id: 'F', name: 'Female' }]
      },
      min_age: {
        name: 'Min Age',
        type: 'number',
        description: "This is the min age in years, inclusive"
      },
      max_age: {
        name: 'Max Age',
        type: 'number',
        description: "This is the max age in years, inclusive"
      },
      locale: {
        name: 'Locale',
        type: 'array',
        description: "Which languages this measure supported. The only valid values for this are en_US and es_US."
      },
      tags: {
        name: 'Tags',
        type: 'multipleSelect',
        options: [{ id: 'adult', name: 'Adult' }, { id: 'child', name: 'Child' }, { id: 'MBC', name: 'MBC' }, { id: 'Outpatient', name: 'Outpatient' }, { id: 'Inpatient', name: 'Inpatient' }, { id: 'Intake', name: 'Intake' }, { id: 'Primary Care', name: 'Primary Care' }, { id: 'Screener', name: 'Screener' }, { id: 'SDOH', name: 'SDOH' }]
      },
      questions: {
        name: 'Question subset',
        type: 'entitySubset',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: null,
        toJson: function toJson(value) {
          // Ensure we don't send empty question bank
          if (Ember.isEmpty(value)) {
            return null;
          }

          return value;
        }
      },
      subscale_selection: {
        name: 'Subscale Select',
        type: 'select',
        options: [{ id: 'all', name: 'All' }, { id: 'best', name: 'Best Effort - No Default Gender' }, { id: 'best_default_male', name: 'Best Effort - Default to Male' }, { id: 'best_default_female', name: 'Best Effort - Default to Female' }, { id: 'all_matching', name: 'All Matching Scales' }],
        defaultValue: 'all',
        description: "Talk to Eng before you use this. This allows you to do 'best effort demographic' selection which will attempt to select the correct version of the scale on the basis of the demographics of the patient"
      },
      display_data: {
        name: 'Display data (eng only)',
        type: 'json'
      },
      beneficial: {
        name: "Beneficial?",
        type: 'checkbox',
        defaultValue: false,
        description: 'Is a higher value on this scale good? If checked, scale is a symptom scale and higher scores are bad. If unchecked, its a strength and high scores are good'
      },
      scoring_text: {
        name: "Scoring Text",
        type: 'text',
        description: "This free text can be used to explain how the scale is scored"
      },
      report_short_description: {
        name: "Report Short Description",
        type: "text"
      },
      category: {
        name: 'Category',
        type: 'select',
        options: [{ id: 'Depression', name: 'Depression' }, { id: 'Avoidance', name: 'Avoidance' }, { id: 'General', name: 'General' }, { id: 'Anxiety', name: 'Anxiety' }, { id: 'Addiction', name: 'Addiction' }, { id: 'PTSD', name: 'PTSD' }, { id: 'Resilience', name: 'Resilience' }, { id: 'Trauma', name: 'Trauma' }, { id: 'Mood', name: 'Mood' }, { id: 'Eating', name: 'Eating' }, { id: 'Alliance', name: 'Alliance' }, { id: 'Borderline', name: 'Borderline' }, { id: 'Suicide', name: 'Suicide' }, { id: 'Social Determinants', name: 'Social Determinants' }, { id: 'Early Childhood', name: 'Early Childhood' }, { id: 'Client Satisfaction', name: 'Client Satisfaction' }, { id: 'Research', name: 'Research' }, { id: 'Emotion', name: 'Emotion' }, { id: 'DBT', name: 'DBT' }, { id: 'ADHD', name: 'ADHD' }, { id: 'Recent Events', name: 'Recent Events' }, { id: 'Gender', name: 'Gender' }, { id: 'Perfectionism', name: 'Perfectionism' }, { id: 'OCD', name: 'OCD' }, { id: 'Expectations', name: 'Expectations' }, { id: 'Stress', name: 'Stress' }, { id: 'Life Satisfaction', name: 'Life Satisfaction' }, { id: 'Schizophrenia', name: 'Schizophrenia' }, { id: 'Mania', name: 'Mania' }, { id: 'Psychosis', name: 'Psychosis' }, { id: 'Sleep', name: 'Sleep' }, { id: 'Substance Use', name: 'Substance Use' }]
      },
      default_phase: {
        name: 'Default Phase',
        type: 'select',
        options: [{ id: 'before', name: 'before' }, { id: 'during', name: 'during' }, { id: 'after', name: 'after' }]
      },
      default_format: {
        name: 'Default Format',
        type: 'select',
        options: [{ id: 'self_report', name: 'Self Report' }, { id: 'interview', name: 'Interview' }]
      },
      acute_change_type: {
        name: 'Acute Change',
        type: 'select',
        options: [{ id: 'rci', name: 'RCI' }, { id: 'categorical', name: 'Categorical' }]
      },
      acute_change_params: {
        name: "Acute Change Params",
        description: "JSON object of acute change config",
        type: "json"
      },
      trend_type: {
        name: 'Trend Type',
        type: 'select',
        options: [{ id: 'avg', name: 'Average' }, { id: 'categorical', name: 'Categorical' }, { id: 'change_since_intake', name: 'Change Since Intake' }]
      },
      trend_params: {
        name: "Trend Params",
        description: "JSON object of trend config",
        type: "json"
      },
      clinical_category_type: {
        name: 'Clinical Category',
        type: 'select',
        options: [{ id: 'threshold', name: 'Threshold' }]
      },
      clinical_category_params: {
        name: "Clinical Category Params",
        description: "JSON object of clinical category config",
        type: "json"
      },
      severity_category_type: {
        name: 'Severity Category',
        type: 'select',
        options: [{ id: 'threshold', name: 'Threshold' }]
      },
      severity_category_params: {
        name: "Severity Category Params",
        description: "JSON object of severity category config",
        type: "json"
      },
      administration_scheme_class_name: {
        name: "Advanced Administration Scheme",
        description: "Class name of the advanced administrations scheme, e.g. for the SFSS.",
        type: "text"
      },
      unadministrable: {
        name: 'Unadministrable',
        type: 'checkbox',
        defaultValue: false
      },
      survey_mnemonics: {
        name: 'Survey Mnemonics',
        type: 'entitySubset',
        registryType: 'survey',
        registryKey: 'mnemonic',
        defaultValue: null,
        toJson: function toJson(value) {
          // Ensure we don't send empty bank
          if (Ember.isEmpty(value)) {
            return null;
          }

          return value;
        }
      },
      norse_threshold_open: {
        name: 'NORSE Threshold Open',
        type: 'number',
        advanced: true
      },
      norse_threshold_close: {
        name: 'NORSE Threshold Close',
        type: 'number',
        advanced: true
      },
      norse_patient_adjustment: {
        name: 'NORSE Patient Adjustment',
        type: 'number',
        advanced: true
      },
      norse_trigger_question: {
        name: 'NORSE Trigger Question',
        type: 'entitySelect',
        registryType: 'question',
        registryKey: 'shortname',
        defaultValue: null,
        advanced: true
      }
    }
  });

  var RawSurvey = BaseClass('rawSurvey', {
    collections: {
      scales: {
        path: 'scales',
        factory: 'scale'
      },
      surveys: {
        path: 'surveys',
        factory: 'survey'
      },
      questionConstructs: {
        path: 'question_constructs',
        factory: 'questionConstruct'
      }
    },
    models: {
      scaleGroup: {
        path: 'scale_group',
        factory: 'scaleGroup'
      }
    },
    propertyDefinitions: {
      dependencies: {
        name: "Dependencies",
        type: 'array'
      },
      review_notes: {
        name: "Review Notes",
        type: 'text'
      },
      version_notes: {
        name: "Version Notes",
        type: 'text'
      },
      version: {
        name: "Version",
        type: 'text'
      },
      notes: {
        name: "Notes",
        type: 'text'
      },
      author_name: {
        name: "Author Name",
        type: 'text'
      },
      author_email: {
        name: "Author Email",
        type: 'text'
      }

    }
  });

  var ScaleGroup = BaseClass('scaleGroup', {
    propertyDefinitions: {
      name: {
        name: "Name",
        type: 'text'
      },
      shortname: {
        name: "Shortname",
        type: 'text'
      },
      description: {
        name: "Description",
        type: 'text'
      },
      display_mode: {
        name: "Display Mode",
        type: 'select',
        options: [{ id: 'individual', name: 'Individual' }, { id: 'interpreted_with_subscales', name: 'Interpreted with subscales' }, { id: 'graph_with_subscales', name: 'Graph with subscales' }, { id: 'combined', name: 'Combined Graph' }, { id: 'sfss', name: 'SFSS' }, { id: 'alert', name: 'Alert' }]
      }
    }
  });

  FactoryMap['survey'] = Survey;
  FactoryMap['page'] = Page;
  FactoryMap['question'] = Question;
  FactoryMap['questionConstruct'] = QuestionConstruct;
  FactoryMap['rawSurvey'] = RawSurvey;
  FactoryMap['norm'] = Norm;
  FactoryMap['scale'] = Scale;
  FactoryMap['subscale'] = Subscale;
  FactoryMap['scorer'] = Scorer;
  FactoryMap['scaleGroup'] = ScaleGroup;

  exports.Survey = Survey;
  exports.Page = Page;
  exports.Question = Question;
  exports.RawSurvey = RawSurvey;
  exports.Scale = Scale;
  exports.Subscale = Subscale;
  exports.FactoryMap = FactoryMap;
  exports.ScaleGroup = ScaleGroup;
  exports.QuestionConstruct = QuestionConstruct;
  exports.default = {
    Survey: Survey,
    Page: Page,
    Question: Question,
    RawSurvey: RawSurvey,
    Scale: Scale,
    Scorer: Scorer,
    Subscale: Subscale,
    ScaleGroup: ScaleGroup,
    QuestionConstruct: QuestionConstruct
  };
});