define('frontend/pods/components/provider/plan/entity-superseding-plans/component', ['exports', 'frontend/pods/components/provider/plan/entity-active-plans/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    showDetails: true,
    classNames: ['superseding-plans'],
    pathFilterType: 'supersedesPath',
    hasSupersedingPlans: Ember.computed.gte('entityMeasurementPlans.length', 1),
    patientPlansIncluded: Ember.computed.alias('entityTreeNode.demographicsPresent'),

    // Do not show patient level plans unless we are already at the patient level, or
    // we have been asked.
    // At the patient level, do no filtering on plans so that e.g. provider plans that
    // do not specify a patient can still be seen.
    otherFilters: Ember.computed("entityTreeNode.demographicsPresent", function () {
      if (this.get("entityTreeNode.demographicsPresent")) {
        return {};
      } else {
        return {
          patient_plans: false
        };
      }
    }),

    patientPlans: Ember.computed('showPatientPlans', 'entityTreeNode.path', function () {
      var filters = { preset: 'current_and_future', patient_plans: true };
      return this._planQuery(filters, false, 'patientPlans'); // No demographics
    })
  });
});