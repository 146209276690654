define("frontend/pods/components/common/page-header-section/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pageHeader: Ember.inject.service(),
    title: Ember.computed.oneWay("pageHeader.title"),
    description: Ember.computed.oneWay("pageHeader.description"),
    visible: Ember.computed.oneWay("pageHeader.visible"),
    className: Ember.computed.oneWay("pageHeader.className")
  });
});