define('frontend/helpers/survey/language-text', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getMatches(string, regex, index) {
    index || (index = 1); // default to the first capturing group
    var matches = [];
    var match = regex.exec(string);
    while (match) {
      matches.push(match[index]);
      match = regex.exec(string);
    }
    return matches;
  }
  /* eslint-disable no-useless-escape */
  var placeholderRegex = new RegExp(/(?:^|\W){{([\w\.]+?)}}(?:\W|$)/g);
  /* eslint-enable no-useless-escape */
  //takes in a hash of translations by locale, a locale, and returns the translation.  If it doesn't exist, defaults to 'en'
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var hash = params[0];
      var locale = params[1];
      var substitutions = params[2];
      if (!hash) {
        return undefined;
      }
      var text = hash[locale];
      if (typeof text === 'undefined') {
        text = hash['en'];
      }

      if (substitutions && text && text.indexOf('{{') > -1) {
        // Only do substitutions if the key has special character.
        var placeholders = getMatches(text, placeholderRegex, 0);

        placeholders.forEach(function (placeholder) {
          // Look for a per-module text before the global.
          var replacement = void 0;

          if (substitutions.module) {
            replacement = _lodash.default.get(substitutions.module, placeholder);
          }

          if (!replacement) {
            replacement = _lodash.default.get(substitutions, placeholder);
          }
          text = text.replace(new RegExp('{{' + placeholder + '}}', 'g'), replacement);
        });
      }
      return text;
    }
  });
});