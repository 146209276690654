define('frontend/pods/components/provider/patient-sessions/session-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    hideFiltered: true,
    preload: Ember.inject.service(),
    showAddMeasureDialog: false,

    statusClassMap: {
      feedback_complete: 'good',
      complete: 'good',
      closed: 'bad',
      scheduled: 'neutral'
    },

    //total time in minutes
    totalEstimatedTime: Ember.computed('record.assessmentReport.cachedScales.[]', function () {
      var scales = this.get("record.assessmentReport.cachedScales");

      if (scales) {
        // There's an odd loading bug where it doesn't pick up the scales first time
        if (!scales[0]) {
          return 'loading';
        }

        var totalSeconds = scales.reduce(function (sum, scale) {
          return scale.getWithDefault('estimatedTime', 0) + sum;
        }, 0);
        return Math.max(1, Math.round(totalSeconds / 60));
      } else {
        return 'unknown';
      }
    }),
    actions: {
      expand: function expand() {
        if (typeof event !== 'undefined') {
          //don't expand/contract on button clicks
          if ($(event.target).closest('a').length || $(event.target).closest('button').length) {
            return;
          }
        }
        this.set('record.isExpanded', !this.get('record.isExpanded'));
      },
      toggleScaleDetail: function toggleScaleDetail(scale) {
        this.get('actionHandler')('onFocusScale', scale);
      },
      toggleAddMeasure: function toggleAddMeasure() {
        this.set('showAddMeasureDialog', !this.get('showAddMeasureDialog'));
      },
      beginAddScaleFromDrag: function beginAddScaleFromDrag(scale) {
        this.setProperties({
          showAlterScaleDialog: true,
          scaleAlterMode: 'add',
          alteredScales: [scale]
        });
      },
      beginAddScale: function beginAddScale() {
        this.setProperties({
          showAlterScaleDialog: true,
          scaleAlterMode: 'add'
        });
      },
      cancelAlterScale: function cancelAlterScale() {
        this.setProperties({
          showAlterScaleDialog: false,
          alteredScales: null
        });
      },
      onScalesAltered: function onScalesAltered(session, alterPlan) {
        this.setProperties({
          showAlterScaleDialog: false,
          alteredScales: null
        });
        this.get('actionHandler')('onScalesAltered', session, alterPlan);
      },
      beginRemoveScale: function beginRemoveScale(scale) {
        this.setProperties({
          showAlterScaleDialog: true,
          scaleAlterMode: 'remove',
          alteredScales: [scale]
        });
      }
    }
  });
});