define('frontend/pods/components/provider/assessment/report-v2/emap-mcq/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedAnswer(question) {
    return Ember.computed('activeComputedValue', 'dataLoaded', function () {
      return this.getWithDefault('activeComputedValue.answers', []).findBy('question.shortname', question);
    });
  }

  var summaryQuestions = ['messagesAccuratelyPortrayMood', 'additionalTermsForLowMood', 'changeFromLastWeek', 'postContentReviewInstructions', 'comfortableWithStudyStaffLookingAtMessaging', 'generalReactionToReviewWithResearchAssistant'];

  exports.default = Ember.Component.extend({
    classNames: ['emap'],
    showComparisonSelector: Ember.computed.not('isFirstReport'),
    comparisonReport: Ember.computed.alias('reportSettings.comparisonReport'),
    scale: Ember.computed.alias('scales.firstObject'),

    allComputedValues: Ember.computed('reports.[]', function () {
      var _this = this;

      var answerFutures = [];
      var cvs = this.get("reports").flatMap(function (report) {
        var computedValue = report.get('computedValues').findBy('scale.id', _this.get("scale.id"));

        if (!computedValue) {
          return [];
        }

        // Nasty hack to load answers and then retry everything.
        answerFutures.addObject(computedValue.query('answers', { include: 'question', page: { limit: 300 } }));
        return [computedValue];
      });

      Ember.RSVP.all(answerFutures).then(function () {
        _this.set('dataLoaded', 'loaded');
      });

      return cvs;
    }),

    activeComputedValue: Ember.computed('activeReport', function () {
      return this.get("activeReport.computedValues").findBy('scale.id', this.get("scale.id"));
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      return this.get("allComputedValues");
    },


    anyConcerns: computedAnswer('anyConcerns'),
    concernsConversationWith: computedAnswer('concernsConversationWith'),
    consideredBringingConcernsToClinician: computedAnswer("consideredBringingConcernsToClinician"),
    primaryPlatformLastWeek: computedAnswer("primaryPlatformLastWeek"),

    summaryAnswerGroup: Ember.computed('dataLoaded', 'activeComputedValue', function () {
      var _this2 = this;

      return summaryQuestions.map(function (mnemonic) {
        _this2.get("activeComputedValue.answers");
        var answers = _this2.getWithDefault("activeComputedValue.answers", []).filterBy('question.shortname', mnemonic);
        return {
          answers: answers,
          mnemonic: mnemonic
        };
      });
    })
  });
});