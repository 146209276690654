define("frontend/mixins/workflow-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      workflowId: {}
    },
    // This causes problems with timing, so for now you have to call save manually.
    // actions: {
    //   willTransition(transition) {
    //     this.get("workflow").save();
    //   }
    // },

    beforeModel: function beforeModel(transition) {
      return this.fetchWorkflow(transition.state.queryParams);
    },


    // If you want to provide a model object as well, you must call super
    model: function model() /*params*/{
      return this.get("workflow");
    },
    fetchWorkflow: function fetchWorkflow(queryParams) {
      var _this = this;

      if (queryParams.workflowId) {
        return this.store.find("workflow", queryParams.workflowId).then(function (w) {
          _this.set("workflow", w);
        }, function () /*error*/{
          _this.transitionTo("assessment.kiosk.expired");
        });
      } else {

        var workflow = this.store.createRecord("workflow", this.workflowParams);
        this.set('workflow', workflow);
        return workflow;
      }
    },
    completeWorkflow: function completeWorkflow() {
      this.get("workflow").complete();
    },
    completeWorkflowWithRedirect: function completeWorkflowWithRedirect(object) {
      this.get("workflow").completeWith(object, this.get("router"));
    }
  });
});