define('frontend/pods/components/provider/assessment/report-v2/patient-goal-details/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    messages: Ember.inject.service(),
    classNameBindings: ['goalStatusClass'],
    isCompressed: true,
    goal: Ember.computed.alias("goalData.goal"),

    showHide: Ember.computed("goalData.goal.status", function () {
      return this.get("goalData.goal.status") !== 'new';
    }),
    showPause: Ember.computed.equal("goalData.goal.status", "active"),
    showResume: Ember.computed.equal("goalData.goal.status", "paused"),
    showDelete: Ember.computed.equal("goalData.goal.status", "new"),

    goalStatusClass: Ember.computed("goal.status", function () {
      return 'goal-' + this.get("goal.status");
    }),

    colorStyle: Ember.computed("goalData.color", function () {
      return Ember.String.htmlSafe('color:' + this.get("goalData.color"));
    }),

    actions: {
      expand: function expand() {
        this.set('isCompressed', false);
      },
      compress: function compress() {
        this.set('isCompressed', true);
      },
      pauseGoal: function pauseGoal() {
        var goal = this.get("goal");
        goal.set("statusEvent", 'pause');
        return this._saveGoal(goal);
      },
      resumeGoal: function resumeGoal() {
        var goal = this.get("goal");
        goal.set("statusEvent", 'resume');
        return this._saveGoal(goal);
      },
      deleteGoal: function deleteGoal() {
        var _this = this;

        var goal = this.get("goal");
        goal.set("statusEvent", 'cancel');
        return this._saveGoal(goal).then(function () {
          return _this.get('onCancel')(goal);
        });
      }
    },

    _saveGoal: function _saveGoal(goal) {
      var _this2 = this;

      var onSuccess = function onSuccess() {
        _this2.set("savingGoal", false);
      };

      var onFailure = function onFailure(reason) {
        _this2.set("savingGoal", false);
        _this2.get('messages').danger(_this2.printError(reason));
      };

      this.set("savingGoal", true);

      return goal.save().then(onSuccess, onFailure);
    }
  });
});