define('frontend/pods/components/provider/assessment/report-v2/standard-measures/interpretation-history/component', ['exports', 'frontend/mixins/drawer-enabled'], function (exports, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    screen: Ember.inject.service(),
    history: Ember.computed('reports.[]', 'scale', function () {
      var scale = this.get("scale");
      return this.get("reports").flatMap(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get("domain") === 'interpreted' && cv.get("scale.id") === scale.get("id");
        });
      });
    }),
    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});