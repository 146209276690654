define('frontend/pods/provider/patients/show/consents/create/route', ['exports', 'frontend/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    queryParams: {
      userId: {},
      consentAgreementId: {}
    },

    model: function model(params) {
      var patient = this.modelFor("provider.patients.show");
      var user = this.store.findRecord('user', params.userId);
      var consentAgreement = this.store.findRecord('consentAgreement', params.consentAgreementId, { include: 'consent' });

      return Ember.RSVP.hash({
        user: user,
        consentAgreement: consentAgreement,
        patient: patient
      });
    }
  });
});