define('frontend/helpers/is-equal-month', ['exports', 'ember-date-components/helpers/is-equal-month'], function (exports, _isEqualMonth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isEqualMonth.default;
    }
  });
  Object.defineProperty(exports, 'isEqualMonth', {
    enumerable: true,
    get: function () {
      return _isEqualMonth.isEqualMonth;
    }
  });
});