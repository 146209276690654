define('frontend/pods/components/provider/assessment/report-v2/interpreted-scale-group/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled', 'lodash'], function (exports, _component, _drawerEnabled, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_drawerEnabled.default, {
    primaryScales: Ember.computed("scales", function () {
      return this.getWithDefault("scales", []).filter(function (s) {
        return s.get("displayData.groupDisplayData");
      });
    }),

    primaryScale: Ember.computed.alias("primaryScales.firstObject"),

    subscales: Ember.computed("primaryScale", "scales", function () {
      return this.getWithDefault("scales", []).filter(function (s) {
        return !s.get("displayData.groupDisplayData");
      });
    }),

    graphDomains: ['interpreted'],

    // Note that scales is passed in through parameter.

    currentValuesByScale: Ember.computed("currentValues.[]", function () {
      return _lodash.default.groupBy(this.get("currentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),
    mostRecentValuesByScale: Ember.computed("mostRecentValues.[]", function () {
      return _lodash.default.groupBy(this.get("mostRecentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),

    mostRecentPrimaryValues: Ember.computed("mostRecentValues.[]", function () {
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');

      return this.get("mostRecentValues").filter(function (cv) {
        return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
      });
    }),

    currentPrimaryValues: Ember.computed("currentValues.[]", function () {
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');

      return this.get("currentValues").filter(function (cv) {
        return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
      });
    }),

    historyByScale: Ember.computed('reports.[]', 'scale', function () {
      var scaleMnemonics = this.get("scales").mapBy('mnemonic');
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');
      return this.get("reports").flatMap(function (report) {
        var filtered = report.get("computedValues").filter(function (cv) {
          return cv.get("domain") === 'interpreted' && scaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
        });

        if (filtered.length > 0) {
          return [{
            primary: filtered.filter(function (cv) {
              return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
            }),
            all: _lodash.default.groupBy(filtered, function (cv) {
              return cv.get("scale.mnemonic");
            })
          }];
        } else {
          return [];
        }
      });
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});