define('frontend/pods/components/provider/assessment/report-v2/text-synopsis/dsm5-summary/dsm-scale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    mnemonic: null,
    isBeneficial: false,
    isClinicianFocused: false,
    independent: true,
    measureType: 'dsm',
    allQuestionMnemonics: [],
    scales: [],
    codes: null,
    answers: [],
    //return whether there were notable/atypical answers or scales in this scale
    isAbnormal: Ember.computed('abnormalScales', function () {
      return this.get('abnormalScales.length') > 0 || this.get('abnormalAnswers.length') > 0;
    }),

    abnormalScales: Ember.computed('scales', function () {
      var scales = this.get('scales');
      return scales.filter(function (s) {
        return s.get('severity') !== 'none' && s.get('severity') !== 'subclinical';
      });
    }),

    normalScales: Ember.computed('scales', function () {
      var scales = this.get('scales');
      return scales.filter(function (s) {
        return s.get('severity') === 'none' || s.get('severity') === 'subclinical';
      });
    }),

    abnormalAnswers: Ember.computed('answers', function () {
      var answers = this.get('answers');
      return answers.filter(function (s) {
        return s && s.get('scoreClass') !== 'unanswered' && s.get('scoreClass') !== 'none';
      });
    }),

    normalAnswers: Ember.computed('answers', function () {
      var answers = this.get('answers');
      return answers.filter(function (s) {
        return s && s.get('scoreClass') === 'unanswered' || s.get('scoreClass') === 'none';
      });
    })

  });
});