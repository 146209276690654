define('frontend/pods/components/provider-admin/users/edit-user-form/component', ['exports', 'frontend/models/provider'], function (exports, _provider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    institute: Ember.inject.service(),
    relationshipOptions: Ember.computed(function () {
      var _this = this;

      return _provider.Relationships.map(function (r) {
        return {
          id: r,
          name: _this.get('intl').t('model.careEpisodeProvider.relationships.' + r)
        };
      });
    }),
    accessFrequencyOptions: Ember.computed(function () {
      return [{
        id: 'frequent',
        name: this.get('intl').t('model.provider.accessFrequencies.frequent')
      }, {
        id: 'rare',
        name: this.get('intl').t('model.provider.accessFrequencies.rare')
      }];
    }),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var action = this.get('submitAction');
        if (typeof action === 'function') {
          return action();
        } else {
          console.warn("No action function was passed to edit-user-form");
        }
      }
    }
  });
});