define('frontend/models/export/data-dump', ['exports', 'ember-data', 'frontend/models/application', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _emberData, _application, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var validationOptions = {
    name: (0, _emberCpValidations.validator)('presence', true),
    user: (0, _emberCpValidations.validator)('presence', true)
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    expiresAt: _emberData.default.attr("date"),
    name: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    exportType: _emberData.default.attr("string", {
      defaultValue: function defaultValue() {
        return "csv";
      }
    }),
    configuration: _emberData.default.attr("object", {
      defaultValue: function defaultValue() {
        return { resources: ['researchSet'] };
      }
    }),
    password: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr("date"),
    updatedAt: _emberData.default.attr("date"),

    institute: _emberData.default.belongsTo("institute"),
    user: _emberData.default.belongsTo("user")
  });
});