define('frontend/pods/components/reporting/columns/care-episode-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var iconMapping = {
    active: 'fa fa-calendar-o',
    inactive: 'fa fa-minus',
    unknown: 'fa fa-question-circle'
  };

  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['detail'],
    icon: Ember.computed("value", function () {
      var value = this.get("value");
      return iconMapping[value];
    })
  });
});