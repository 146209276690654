define('frontend/pods/components/internal/scales/edit-scale/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    classNames: ['edit-scale'],

    actions: {
      save: function save(scale) {
        var _this = this;

        var success = function success() {
          _this.get('messages').success("Scale updated");
          _this.get("metrics").trackEvent({ event: "internal.scale.updated" });

          if (_this.get("onSave")) {
            _this.get("onSave")(scale);
          } else if (_this.get("onSaveRoute")) {
            _this.get("router").transitionTo(_this.get("onSaveRoute"));
          }
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      }
    }
  });
});