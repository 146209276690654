define('frontend/pods/components/reporting/columns/outcome-progress/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['outcome-delta', 'text-nowrap'],
    intakeData: Ember.computed.alias("value.intake"),
    intakeValue: Ember.computed.alias("intakeData.value"),
    intakeClass: Ember.computed.alias("intakeData.threshold_class"),
    currentData: Ember.computed.alias("value.current"),
    currentValue: Ember.computed.alias("currentData.value"),
    outcomeClass: Ember.computed.alias("currentData.threshold_class"),

    deltaClass: Ember.computed("value.differential", function () {
      var delta = this.get("value.differential");

      if (!delta) {
        return 'unknown';
      } else if (delta < 0) {
        return 'good';
      } else if (delta > 0) {
        return 'bad';
      } else {
        return 'unknown';
      }
    })
  });
});