define('frontend/models/care-episode', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'frontend/models/entity'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createNewCareEpisode = exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: (0, _emberCpValidations.validator)('presence', true),
    periodStartString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        allowBlank: true,
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    }
  });

  function createNewCareEpisode(store, patient, organizations, provider) {

    var defaultNewOrg = organizations.get("firstObject");
    var careEpisode = store.createRecord("careEpisode", { organization: defaultNewOrg, patient: patient });

    if (provider && provider.get("id")) {
      store.createRecord('careEpisodeProvider', { careEpisode: careEpisode, provider: provider, relationship: provider.get('defaultRelationship') });
      var orgOverride = provider.get('defaultOrganization');

      if (orgOverride) {
        careEpisode.set('organization', orgOverride);
      }
    }

    if (patient.get('provider.id') && (!provider || provider.get('id') !== patient.get('provider.id'))) {
      store.createRecord('careEpisodeProvider', { careEpisode: careEpisode, provider: patient.get('provider'), relationship: patient.get('provider.defaultRelationship') });
      var _orgOverride = patient.get('provider.defaultOrganization');

      if (_orgOverride) {
        careEpisode.set('organization', _orgOverride);
      }
    }

    return careEpisode;
  }

  exports.Validations = Validations;
  exports.createNewCareEpisode = createNewCareEpisode;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    intl: Ember.inject.service(),

    entityType: 'care-episode',

    status: _emberData.default.attr('string'),
    patient: _emberData.default.belongsTo('patient'),
    organization: _emberData.default.belongsTo('organization'),
    treatmentService: _emberData.default.belongsTo('treatmentService'),
    patientSessions: _emberData.default.hasMany('patientSession'),
    careEpisodeProviders: _emberData.default.hasMany('careEpisodeProvider'),

    periodStart: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    periodEnd: _emberData.default.attr('date'),
    statusUpdatedAt: _emberData.default.attr('date'),
    statusEvent: _emberData.default.attr("string"),

    assessmentGoals: _emberData.default.hasMany("assessment/goal", { inverse: 'careEpisode' }),
    activeAssessmentGoals: _emberData.default.hasMany("assessment/goal", { inverse: null }),

    // Don't use this directly as it doesn't automatically update when you add a new care team provider.
    participants: _emberData.default.hasMany("participant"),

    // When the care episode is new, you don't automatically get the participants correctly, so make a
    // property which correctly works out even for a new object what the participants are.
    calculatedParticipants: Ember.computed("participants.[]", "patient.clientParticipants.[]", "careEpisodeProviders.[]", function () {
      if (this.get("isNew") || this.get("careEpisodeProviders").filterBy("isNew").get("length") > 0) {
        var clients = this.getWithDefault("patient.clientParticipants", []);
        var providers = this.getWithDefault("careEpisodeProviders", []).mapBy("provider");

        var ret = Ember.A();
        ret.addObjects(clients);
        ret.addObjects(providers);
        return ret;
      } else {
        return this.get("participants");
      }
    }),

    editable: Ember.computed("status", function () {
      return this.get("status") === 'active';
    }),

    name: Ember.computed('organization.name', 'treatmentService.name', 'status', 'periodStart', 'periodEnd', function () {

      if (this.get("treatmentService.name")) {
        var _params = {
          startDateString: moment(this.get('periodStart')).format('MMM. D'),
          endDateString: this.get('periodEnd') ? moment(this.get('periodEnd')).format('MMMD') : 'ongoing',
          status: this.get('intl').t('model.careEpisode.statuses.' + this.get('status')),
          treatmentServiceName: this.get("treatmentService.name")
        };
        return this.get('intl').t('provider.careEpisodes.calculatedNameWithTreatmentService', _params);
      }

      var params = {
        startDateString: moment(this.get('periodStart')).format('MMM. D'),
        endDateString: this.get('periodEnd') ? moment(this.get('periodEnd')).format('MMMD') : 'ongoing',
        status: this.get('intl').t('model.careEpisode.statuses.' + this.get('status')),
        organizationName: this.get("organization.name")
      };
      return this.get('intl').t('provider.careEpisodes.calculatedName', params);
    }),

    periodStartString: Ember.computed('periodStart', {
      get: function get() {
        if (this.get('periodStart')) {
          return moment(this.get('periodStart')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("periodStart", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    periodEndString: Ember.computed('periodEnd', {
      get: function get() {
        if (this.get('periodEnd')) {
          return moment(this.get('periodEnd')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("periodEnd", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    // NOTE: there is a nestedData property which is serialized to allow us to save the entire session on one call
    saveNested: _emberData.default.attr("boolean"),

    toEntity: function toEntity() {
      return (0, _entity.fromModel)(this, this.get('store'));
    }
  });
});