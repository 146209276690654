define("frontend/mixins/workflow-enabled", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    hasWorkflow: Ember.computed.notEmpty("workflowId"),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get("workflow") === undefined && this.get("workflowId") === undefined && this.get("workflowRequired")) {
        console.warn("No workflow data or id detected for this component");
      }

      if (this.get("workflowId")) {
        this.set("workflow", this.store.find('workflow', this.get("workflowId")));
      }

      if (this.get("workflow")) {
        this.set("workflowId", this.get("workflow.id"));
      }
    },
    updateWorkflow: function updateWorkflow(data) {
      var workflow = this.get("workflow");
      for (var key in data) {
        workflow.set(key, data[key]);
      }
      workflow.save();
    },
    paramsWithWorkflow: function paramsWithWorkflow() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return Ember.merge(params, { workflowId: this.get("workflowId") });
    },
    completeWorkflow: function completeWorkflow() {
      this.get("workflow").complete();
    },
    completeWorkflowWithRedirect: function completeWorkflowWithRedirect(object) {
      this.get("workflow").completeWith(object, this.get("router"));
    }
  });
});