define('frontend/pods/components/provider/assessment/common/alert-badge/component', ['exports', 'frontend/mixins/drawer-enabled'], function (exports, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['risk'],
    classNameBindings: ['riskClass', 'messageKey'],
    riskIcon: 'fa fa-exclamation-triangle',
    riskClass: Ember.computed('isRiskPresent', function () {
      return this.get('isRiskPresent') ? 'possible-risk' : 'minimal-risk';
    }),
    metricEventData: Ember.computed('isRiskPresent', 'riskValue.scale.id', 'riskValue.value', function () {
      return {
        isRiskPresent: this.get('isRiskPresent'),
        riskValue: this.get('riskValue.value'),
        scaleId: this.get('riskValue.scale.id')
      };
    }),
    progressBarStyle: Ember.computed('riskPercentile', function () {
      return Ember.String.htmlSafe("width: " + this.get("riskPercentile") + "%");
    }),

    // Most implementations only have a single risk value.
    allRiskValues: Ember.computed('riskValue', function () {
      return [this.get('riskValue')];
    })
  });
});