define('frontend/pods/components/provider/login-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    username: (0, _emberCpValidations.validator)('presence', true),
    password: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    messages: Ember.inject.service(),
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model', Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        username: this.get("username"),
        password: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },


    actions: {
      authenticate: function authenticate(isPrivacyMode) {
        var _this = this;

        var intl = this.get('intl');

        if (this.get("targetUrl")) {
          this.set("session.targetUrl", this.get("targetUrl"));
        }

        if (this.get("defaultTarget")) {
          this.set("session.defaultTarget", this.get("defaultTarget"));
        }

        sessionStorage.setItem("authenticationType", this.get("type"));
        if (isPrivacyMode === true) {
          sessionStorage.setItem('privacyMode', true);
        } else {
          sessionStorage.removeItem('privacyMode');
        }

        this.get('messages').clear();

        var onFailure = function onFailure(reason) {
          if (reason && reason.error === 'Last attempt') {
            _this.get('messages').danger(intl.t('provider.login.lastAttempt'));
          } else if (reason && reason.error === 'Account locked') {
            _this.get('messages').danger(intl.t('provider.login.accountLocked'));
          } else if (reason && reason.error === 'Account disabled') {
            _this.get('messages').danger(intl.t('provider.login.accountDisabled'));
          } else {
            _this.get('messages').danger(reason.error || reason);
          }
        };

        return this.get('session').authenticate('authenticator:devise', this.get("model.username"), this.get("model.password")).catch(onFailure);
      }
    }

  });
});