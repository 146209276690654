define('frontend/pods/components/provider/assessment/measurement-plan-scale-chooser/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/models/entity-tree-node'], function (exports, _rollbackMixin, _entityTreeNode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, {
    // Lock simplemode depending on if you started autoplanning or not.
    simpleMode: Ember.computed(function () {
      return this.get('session.autoplan');
    }),
    rollbackProperties: ['report.reportScales', 'reportScaleParticipants', 'report.invitations'],
    report: Ember.computed.alias('session.assessmentReport'),

    dates: Ember.computed('session.targetDate', function () {
      return [this.get('session.targetDate')];
    }),

    activeMeasurementPlans: Ember.computed('session.targetDate', 'session.currentEntityTreeNode', function () {
      return this.store.query('care/entityMeasurementPlan', {
        filter: {
          active_for_session_details: {
            date: moment(this.get("session.targetDate")).format(),
            path: this.get("session.currentEntityTreeNode.path")
          }
        },
        include: 'measurement-plan.measurement-plan-scales,entity-tree-node',
        page: { limit: 100 }
      });
    }),

    showManualScales: Ember.computed('report.reportScales.[]', function () {
      return this.get('report.reportScales').filterBy('manual').get('length') > 0;
    }),

    editPlanOptions: Ember.computed('session.patient', 'session.provider', 'session.careEpisode.organization', function () {
      var _this = this;

      var patient = this.get("session.patient");
      var provider = this.get("session.provider");
      var organization = this.get("session.careEpisode.organization");

      var options = [[patient], [provider, patient], [organization, provider, patient]];

      return options.map(function (entities) {
        return (0, _entityTreeNode.createFromEntities)(entities, _this.store);
      }).uniq();
    }),

    actions: {
      disableSimpleMode: function disableSimpleMode() {
        this.setProperties({
          simpleMode: false,
          'session.autoplan': false
        });
      },
      showScaleDetails: function showScaleDetails(scale) {
        this.get('focusedScale').setProperties({
          scale: scale,
          showActions: false
        });
      },
      setCustomScales: function setCustomScales() {
        if (!this.get("session.isNew")) {
          this.get('report.reportScales').toArray().forEach(function (rs) {
            if (!rs.get('manual')) {
              rs.get('scaleParticipants').toArray().forEach(function (rsp) {
                rsp.deleteRecord();
              });
              rs.deleteRecord();
            }
          });
        }
      },
      setAutoplan: function setAutoplan() {
        // Undo any deleted autoplan scales
        this.get('report.reportScales').forEach(function (rs) {
          if (!rs.get('manual')) {
            rs.rollbackAttributes();
            rs.get('scaleParticipants').forEach(function (rsp) {
              rsp.rollbackAttributes();
            });
          }
        });
      },
      onCalculateSchedule: function onCalculateSchedule(resultPromise) {
        var _this2 = this;

        var onSuccess = function onSuccess(results) {
          _this2.setProperties({ scheduleIsLoading: false, scheduleIsError: false, scheduleResults: results });
        };

        var onError = function onError() {
          _this2.setProperties({ scheduleIsLoading: false, scheduleIsError: true, scheduleResults: null });
        };

        this.setProperties({ scheduleIsLoading: true, scheduleIsError: false, scheduleResults: null });

        resultPromise.then(onSuccess, onError);
      }
    }
  });
});