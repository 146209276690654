define('frontend/pods/components/reporting/columns/percent-bar/component', ['exports', 'frontend/helpers/format-number'], function (exports, _formatNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),

    colorMap: Ember.computed.alias('displayParams.colorMap'),

    percentBarStyle: Ember.computed('value', function () {
      var value = this.get('value');

      var barColor = "#babfd4";
      var colorMap = this.get("colorMap");

      if (colorMap) {
        barColor = colorMap.find(function (pair) {
          return value <= pair[0];
        })[1];
      } else {
        barColor = value > 0.4 ? "#babfd4" : "#E85D75";
      }

      var valueString = (0, _formatNumber.formatNumber)(value, { style: 'percent' }, this.get('intl.locale'));
      return Ember.String.htmlSafe("background:linear-gradient(to right, " + barColor + " " + valueString + ", #fff " + valueString + ")");
    }),

    showNull: Ember.computed('value', function () {
      var value = this.get("value");
      return !value && value !== 0;
    })
  });
});