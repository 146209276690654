define('frontend/models/provider-organization', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    provider: _emberData.default.belongsTo("provider"),
    organization: _emberData.default.belongsTo("organization")
  });
});