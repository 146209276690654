define('frontend/pods/components/survey/taking-local/common/questions/radio-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DISPLAY_CLASS_MAP = {
    'radio-likert': 'radio-container',
    grid: 'grid-radio-container'
  };

  exports.default = Ember.Component.extend(_questionComponentMixin.default, {

    displayType: Ember.computed('question', function () {
      if (this.get("question.properties.type")) {
        return this.get("question.properties.type");
      }
      if (this.get('question.properties.map_key')) {
        return this.get('question.properties.map_key');
      }
    }),

    buttonsDisabled: Ember.computed('currentlyActive', 'mode', function () {
      return !(this.get("mode") === 'bulk' || this.get('currentlyActive'));
    }),

    showOther: Ember.computed('responseItem.value', 'question.options.[]', function () {
      var currentValue = this.get('responseItem.value');

      if (!currentValue) {
        return false;
      }

      var currentOption = this.get("question.options").findBy('value', currentValue);

      if (currentOption) {
        return currentOption && currentOption.properties && currentOption.properties.other;
      }

      return true;
    }),

    displayClass: Ember.computed('displayType', function () {
      return DISPLAY_CLASS_MAP[this.get("displayType")];
    }),

    primaryOptions: Ember.computed.filter("question.options", function (option) {
      return !(option.properties && option.properties.secondary);
    }),

    secondaryOptions: Ember.computed.filter("question.options", function (option) {
      return !!(option.properties && option.properties.secondary);
    }),

    hasSecondaryOptions: Ember.computed.notEmpty("secondaryOptions"),

    actions: {
      radioClick: function radioClick(option) {
        var responseItem = this._prepareForNewValue();
        responseItem.set('value', option.value);
        responseItem.set('optionId', option.id);
        this.send('submitAnswer');
      }
    }
  });
});