define('frontend/pods/components/provider/plan/measurement-plan-scale-row/component', ['exports', 'frontend/models/care/measurement-plan-scale', 'frontend/models/assessment/invitation'], function (exports, _measurementPlanScale, _invitation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Blinding = ['unblinded', 'export_only'];
  var BlindingOptionBuilder = function BlindingOptionBuilder(intl) {
    return Blinding.map(function (blinding) {
      return {
        id: blinding,
        name: intl.t('model.blinding.' + blinding)
      };
    });
  };

  exports.default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    institute: Ember.inject.service(),
    isExpanded: Ember.computed('measurementPlanScale.isNew', 'measurementPlanScale.isClone', function () {
      return this.get('measurementPlanScale.isNew') && !this.get("measurementPlanScale.isClone");
    }),
    presetOptions: _measurementPlanScale.SchedulePresets,
    showAdvanced: Ember.computed.equal("measurementPlanScale.schedulePreset.id", 'custom'),
    advancedObserver: Ember.observer('measurementPlanScale.schedulePreset.id', function () {
      // Once we've triggered custom mode, don't allow going back to non custom mode.
      if (this.get('measurementPlanScale.schedulePreset.id') === 'custom') {
        this.set('showAdvanced', true);
      }
    }),
    phaseOptions: Ember.computed(function () {
      return (0, _invitation.PhaseOptionBuilder)(this.get('intl'));
    }),
    phasePrompt: Ember.computed('phaseOptions', 'measurementPlanScale.scale.defaultPhase', function () {
      var intl = this.get('intl');
      var defaultPhase = this.get('measurementPlanScale.scale.defaultPhase');

      if (defaultPhase) {
        var defaultPhaseLabel = this.get("phaseOptions").findBy('id', defaultPhase);

        if (defaultPhaseLabel) {
          return intl.t('model.measurementPlanScale.defaultPhaseNamed', { phase: defaultPhaseLabel.name });
        }
      }

      return intl.t('model.measurementPlanScale.defaultPhase');
    }),
    formatOptions: Ember.computed(function () {
      return (0, _invitation.FormatOptionBuilder)(this.get('intl'));
    }),
    formatPrompt: Ember.computed('formatOptions', 'measurementPlanScale.scale.defaultFormat', function () {
      var intl = this.get('intl');
      var defaultFormat = this.get('measurementPlanScale.scale.defaultFormat');

      if (defaultFormat) {
        var defaultFormatLabel = this.get("formatOptions").findBy('id', defaultFormat);

        if (defaultFormatLabel) {
          return intl.t('model.measurementPlanScale.defaultFormatNamed', { format: defaultFormatLabel.name });
        }
      }

      return intl.t('model.measurementPlanScale.defaultFormat');
    }),

    blindingOptions: Ember.computed(function () {
      return BlindingOptionBuilder(this.get('intl'));
    }),

    measureOnsetFromOptions: [{
      id: 'care_episode_period_start',
      name: 'Start of treatment'
    }, {
      id: 'next_calendar_quarter',
      name: 'Start of calendar quarter after treatment start'
    }],

    actions: {
      removeScale: function removeScale() {
        var mps = this.get("measurementPlanScale");
        mps.deleteRecord();
        if (this.get('onDelete')) {
          this.get('onDelete')(mps);
        }
      },
      showScaleBrowser: function showScaleBrowser() {
        this.get('onShowScaleBrowser')(this.get('measurementPlanScale'));
      }
    }
  });
});