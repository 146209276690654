define('frontend/pods/survey/taking/demo/begin/route', ['exports', 'frontend/mixins/survey/begin-route-mixin', 'frontend/pods/base-route'], function (exports, _beginRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_beginRouteMixin.default, {
    requestedUrlCodes: function requestedUrlCodes(allQueryParams) {
      return Object.keys(allQueryParams).filter(function (x) {
        return allQueryParams[x] === '1';
      });
    }
  });
});