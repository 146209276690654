define('frontend/pods/components/reporting/columns/patient-flags/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    preload: Ember.inject.service(),
    activeFlags: Ember.computed('value.[]', 'preload.flags.[]', function () {
      var flagIds = this.getWithDefault("value", []);

      if (!flagIds) {
        return [];
      }

      return this.getWithDefault("preload.flags", []).filter(function (flag) {
        return flagIds.indexOf(flag.get('id')) >= 0;
      });
    })
  });
});