define("frontend/locales/customers/testc", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: {
      "common.patient": "Student"
    }
  };
});