define('frontend/serializers/provider', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      relationship: { serialize: false },
      stats: { serialize: false },
      user: { serialize: false }, // We do not serialize the user. It shouldn't be possible to update the relationship, and fields on user are serialized directly on provider instead.
      organizationSubtrees: { serialize: false },
      providerSurveyPatient: { serialize: false },
      headlineSession: { serialize: false },
      name: { serialize: false },
      orderedName: { serialize: false }
    },
    sessionUser: Ember.inject.service(),

    /*
     * The JSON API spec does not as of v1.0 support multiple simulataneous operations, which includes compound create operations
     * like is necessary for session creation. In order to support this for now, there is a custom specification where a 'nested data'
     * parameter gets encoded on the frontend side, and then reinflated on the backend. All interactions otherwise are done via the correct
     * infrastructure. The intent is that once JSONAPI supports multi-put, we can transparently turn off the hack. Note that in order to
     * work properly, the adapter needs to be changed to include all these sidecar'd resources, otherwise they will not be correctly updated
     * after the save operation.
     *
     * The one wrinkle here is deleted records: we don't delete them directly, but rather
     * the back end will remove them once they are no longer used. Because we don't actually
     * issue a delete, they will stay in state isDeleted=true locally, so we have to filter them out.
     */
    serialize: function serialize(snapshot) {
      // Example format
      // const exampleData = {
      //   user: {
      //     roles: [
      //       {
      //         id: '123',
      //       }
      //     ]
      //   }
      // }

      var result = this._super.apply(this, arguments);
      var model = snapshot.record;
      var nestedData = {};
      var saveNested = false;

      if (this.get("sessionUser").hasPermission("editProviderRoles")) {
        var roleData = model.getWithDefault("user.roles", []).map(function (role) {
          return {
            id: role.get("id")
          };
        });

        nestedData.user = {
          roles: roleData
        };

        saveNested = true;
      }

      if (this.get("sessionUser").hasPermission("editProviderOrganizations")) {
        var orgData = model.getWithDefault("organizations", []).map(function (organization) {
          return {
            id: organization.get("id")
          };
        });

        nestedData.organizations = orgData;
        saveNested = true;
      }

      if (saveNested) {
        result.data.attributes['nested-data'] = nestedData;
        result.data.attributes['save-nested'] = true;
      }

      return result;
    }
  });
});