define('frontend/pods/components/reporting/threshold-dial/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showLabel: true,
    //for now, this is super-hard coded, we can pull out the bits we need to be configurable as we go.
    dialOptions: Ember.computed(function () {
      var _this = this;

      return {
        strokeWidth: 10,
        trailColor: '#eee',
        trailWidth: 10,
        easing: 'easeInOut',
        duration: 1400,
        svgStyle: {
          'max-height': '150px'
        },
        text: {
          value: '',
          style: {
            position: 'absolute',
            left: '50%',
            top: '25%',
            padding: 0,
            margin: 0,
            transform: {
              prefix: true,
              value: 'translate(-50%, 25%)'
            }
          }
        },
        from: { color: '#E85D75' },
        to: { color: '#379392' },
        // Set default step function for all animate calls
        step: function step(state, bar) {
          var subtitle = '';
          var value = Math.round(bar.value() * 100);
          if (value === 0) {
            subtitle = 'At Risk';
            bar.setText(value + '%');
            bar.text.style.color = '#E85D75';
          } else {
            if (value <= 33) {
              subtitle = 'At Risk';
              bar.path.setAttribute('stroke', '#E85D75');
              bar.text.style.color = '#E85D75';
            } else if (value >= 34 && value <= 66) {
              subtitle = "Moderate";
              bar.path.setAttribute('stroke', '#FF715B');
              bar.text.style.color = '#FF715B';
            } else if (value >= 67) {
              bar.path.setAttribute('stroke', '#379392');
              bar.text.style.color = '#379392';
              subtitle = "High";
            }
          }
          var text = '';
          if (_this.get('showLabel')) {
            text = '<p class="gauge__text-percent">' + value + '%</p> <p class="gauge__text-subtitle">' + subtitle + '</p>';
          } else {
            text = '<p class="gauge__text-percent-large">' + value + '%</p>';
          }
          bar.setText(text);
        }
      };
    })
  });
});