define('frontend/pods/provider/admin/users/show/notifications/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      var provider = this.modelFor("provider.admin.users.show");
      var user = provider.get("user");
      return Ember.RSVP.hash({
        user: user,
        subjects: this.store.query('subscriptionSubject', {}),
        userSubscriptions: user.get('userSubscriptions')
      });
    }
  });
});