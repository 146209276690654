define('frontend/pods/components/provider/plan/plan-patient-measurement/component', ['exports', 'frontend/pods/components/provider/assessment/scale-info-drawer/component', 'frontend/models/entity-tree-node', 'ember-cli-uuid/utils/uuid-helpers', 'frontend/mixins/provider/plan/active-plans'], function (exports, _component, _entityTreeNode, _uuidHelpers, _activePlans) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.ScaleDrawerMixin, _activePlans.default, {
    intl: Ember.inject.service(),
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    refreshData: false,
    hideDisabled: true, // setting in ActivePlans

    // For this page, we don't want to see speculative paths, e.g. if a provider has a supplemental
    // protocol, so uses only the current matching data.
    pathFilterType: 'matchesPath',

    planAtCareEpisodeLevel: true,

    allowEditActions: Ember.computed('sessionUser.currentUser', 'institute.configuration.paceLegacyMode', function () {
      if (this.get('institute.configuration.paceLegacyMode')) {
        return this.get('sessionUser.currentUser.isInternal');
      }

      return this.get("sessionUser").hasPermission("editEntityMeasurementPlan");
    }),

    selectedCareEpisode: Ember.computed('careEpisodeId', 'activeCareEpisodes.[]', function () {
      if (this.get('careEpisodeId')) {
        return this.get('activeCareEpisodes').findBy('id', this.get('careEpisodeId'));
      } else {
        return this.get('activeCareEpisodes.firstObject');
      }
    }),

    fallbackOrganization: Ember.computed('organizationId', 'patient.defaultProvider', function () {
      if (this.get('organizationId')) {
        return this.get('organizations').findBy('id', this.get('organizationId'));
      } else {
        return this.get("patient.defaultProvider.defaultOrganization");
      }
    }),

    // To make the manipulation of treatment tracks easier, we don't want to accidentally only change things for one
    // provider. So unset provider here to start with so actions like 'remove a scale' by default affect the entire
    // episode of care.
    selectedProvider: null,

    // selectedProvider: Ember.computed('providerId', 'patient.defaultProvider', 'upcomingSessions.firstObject.provider', function() {
    //   if (this.get('providerId')) {
    //     return this.get('providers').findBy('id', this.get('providerId'));
    //   }

    //   let provider = this.get('upcomingSessions.firstObject.provider')
    //   if (provider) {
    //     return provider;
    //   }

    //   return this.get("patient.defaultProvider");
    // }),

    selectedOrganization: Ember.computed('fallbackOrganization', 'selectedCareEpisode', function () {
      if (this.get("selectedCareEpisode")) {
        return this.get("selectedCareEpisode.organization");
      } else {
        return this.get("fallbackOrganization");
      }
    }),

    nextSessionDate: Ember.computed('upcomingSessions.[]', function () {
      var upcomingSessions = this.get('upcomingSessions');

      if (upcomingSessions) {
        return upcomingSessions.filterBy('isDeletable').get('firstObject.targetDate');
      } else {
        return null;
      }
    }),

    showAddScale: false,
    patientPath: Ember.computed("selectedOrganization.id", 'selectedProvider.id', 'patient.id', 'selectedCareEpisode.id', function () {

      var providerSegment = this.get('selectedProvider.id') ? 'provider/' + this.get('selectedProvider.id') + '/' : '';
      if (this.get("selectedCareEpisode.id")) {
        return 'institute/' + this.get("institute.current.id") + '/organization/' + this.get("selectedOrganization.id") + '/' + providerSegment + 'patient/' + this.get("patient.id") + '/care-episode/' + this.get('selectedCareEpisode.id');
      } else {
        return 'institute/' + this.get("institute.current.id") + '/organization/' + this.get("selectedOrganization.id") + '/' + providerSegment + 'patient/' + this.get("patient.id");
      }
    }),
    entityTreeNode: Ember.computed('selectedCareEpisode', 'planAtCareEpisodeLevel', 'selectedOrganization', 'patient', 'selectedProvider', function () {
      if (this.get("selectedCareEpisode") && this.get("planAtCareEpisodeLevel")) {
        return (0, _entityTreeNode.createFromEntities)([this.get("institute.current"), this.get("selectedOrganization"), this.get('selectedProvider'), this.get("patient"), this.get('selectedCareEpisode')], this.get('store'));
      } else {
        return (0, _entityTreeNode.createFromEntities)([this.get("institute.current"), this.get("selectedOrganization"), this.get('selectedProvider'), this.get("patient")], this.get('store'));
      }
    }),
    planContext: Ember.computed('entityTreeNode', function () {
      return {
        entityTreeNode: this.get("entityTreeNode"),
        editCurrentNodeOnly: true,
        disableLinks: true,
        disableEdit: true
      };
    }),
    sessionRowContext: Ember.computed('selectedCareEpisode', 'selectedProvider', function () {
      return {
        warnOnManualPlan: true,
        activeProvider: this.get('selectedProvider'),
        activeCareEpisode: this.get('selectedCareEpisode'),
        scales: this.get('scales'),
        focusedScale: this.get('focusedScale')
      };
    }),
    futureSessionColumns: Ember.computed(function () {
      var intl = this.get('intl');

      return [{
        propertyName: "status",
        component: "components/provider/patient-sessions/session-list-status",
        title: intl.t('model.patientSession.effectiveStart'),
        className: 'session-status'
      }, {
        propertyName: "targetDate",
        component: "components/provider/patient-sessions/session-list-item",
        title: intl.t('model.patientSession.effectiveStart'),
        sortPrecedence: 1,
        sortDirection: "asc"
      }, {
        className: 'session-actions',
        template: "components/provider/patient-sessions/session-list-actions",
        title: intl.t('model.patientSession.effectiveStart')
      }];
    }),

    activeScales: Ember.computed('entityMeasurementPlans.[]', function () {
      return this.get('groupedAndFilteredPlans').toArray().flatMap(function (group) {
        return group.get('measurementPlan.measurementPlanScales').mapBy('scale');
      }).uniqBy('id');
    }),

    actions: {
      beginAddScale: function beginAddScale() {
        this.set('showAddScale', true);
      },
      beginChangeMeasurementPlan: function beginChangeMeasurementPlan() {
        this.set('showChangeMeasurementPlan', true);
      },
      onFocusScale: function onFocusScale(scale) {
        this.set("focusedScale.scale", scale);
      },
      onScaleCancel: function onScaleCancel() {
        this.setProperties({
          showAddScale: false,
          showRemoveScale: false
        });
      },
      onScalesAltered: function onScalesAltered(session, alterPlan) {
        if (alterPlan) {
          this.set('refreshData', (0, _uuidHelpers.default)()); // Set to uuid to force refresh
          this.get("onPlanChange")();
        }
      },
      onPlanChange: function onPlanChange() {
        this.set('refreshData', (0, _uuidHelpers.default)()); // Set to uuid to force refresh
        this.get("onPlanChange")();
      },
      onScaleAdd: function onScaleAdd() {
        this.set("showAddScale", false);
        this.set('refreshData', (0, _uuidHelpers.default)()); // Set to uuid to force refresh
        this.get("onPlanChange")();
      },
      onChangeMeasurementPlanCancel: function onChangeMeasurementPlanCancel() {
        this.set('showChangeMeasurementPlan', false);
      },
      onChangeMeasurementPlan: function onChangeMeasurementPlan() {
        this.set("showChangeMeasurementPlan", false);
        this.set('refreshData', (0, _uuidHelpers.default)()); // Set to uuid to force refresh
        this.get("onPlanChange")();
      },
      updateCareEpisode: function updateCareEpisode(careEpisode) {
        this.set("selectedCareEpisode", careEpisode);
        this.set('careEpisodeId', careEpisode ? careEpisode.get('id') : null); // For query params
      },
      updateOrganization: function updateOrganization(organization) {
        this.set("selectedOrganization", organization);
        this.set('organizationId', organization ? organization.get('id') : null); // For query params
      },
      updateProvider: function updateProvider(provider) {
        this.set("selectedProvider", provider);
        this.set('providerId', provider ? provider.get('id') : null); // For query params
      },
      beginRemoveScale: function beginRemoveScale() {
        this.set("showRemoveScale", true);
      },
      onScaleRemove: function onScaleRemove() {
        this.set("showRemoveScale", false);
        this.set('refreshData', (0, _uuidHelpers.default)()); // Set to uuid to force refresh
        this.get("onPlanChange")();
      },
      showPredictions: function showPredictions() {
        var careEpisode = this.get("upcomingSessions.firstObject.careEpisode");
        var thisRequest = this.get('store').createRecord('care/futureSessionMeasurementEstimate', { careEpisode: careEpisode });

        thisRequest.save().then(function (results) {
          results.get("hydratedResults").forEach(function (result) {
            if (result.patientSession) {
              Ember.set(result.patientSession, 'predictedScales', result.scales);
            }
          });
        });
      }
    }
  });
});