define('frontend/pods/components/survey/taking-local/component', ['exports', 'frontend/mixins/survey-metric', 'frontend/mixins/scheduler'], function (exports, _surveyMetric, _scheduler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_surveyMetric.default, _scheduler.default, {
    intl: Ember.inject.service(),
    classNames: ['measure-local'],
    classNameBindings: ['currentModuleStyling'],

    metrics: Ember.inject.service(),

    previewMode: false,

    keepalive: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    currentModuleStyling: Ember.computed.alias('response.currentModule.content.color_scheme'),

    ADisplayStatus: "previous",
    BDisplayStatus: "current",
    CDisplayStatus: "next",
    backsteps: 0,

    /**
     * @override: ember lifecycle
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.get("response.currentAnchor"); // Make the observer work

      //sync the overall lanugage to the survey language to cover the case of reloads, etc...
      if (this.get('response.currentLanguage')) {
        this.get('intl').setLocale([this.get('response.currentLanguage') || 'en', 'en']);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.get('response').begin();

      this.set('APage.page', this.get('response.prevPage'));
      this.set('BPage.page', this.get('response.currentPage'));
      this.set('CPage.page', this.get('response.nextPage'));

      // Snake case on user_agent matches Survey Gizmo format.
      this.set("response.takingMetadata.user_agent", window.navigator.userAgent);
    },
    didInsertElement: function didInsertElement() {
      this._resetPageTranslation();
    },
    _resetPageTranslation: function _resetPageTranslation() {
      var animationSequence = [{ e: $('div.survey-page.next-page'), p: { translateX: "+=100%" }, o: { duration: 0, queueSequence: false } }, { e: $('div.survey-page.previous-page'), p: { translateX: "-=100%" }, o: { duration: 0, queueSequence: false } }];
      $.Velocity.RunSequence(animationSequence);
    },


    /**
     * @override: ember lifecycle
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },


    // We are having intermittent problems where it appears that we are losing answers. We hypothesize that it could
    // be network related, so for now we are sending are heartbeat, the lack of which would indicate a connectivity
    // problem.
    scheduleGuard: true,
    scheduleFrequency: 15000,
    scheduleExecute: function scheduleExecute() {
      this.trackSurveyMetric({ event: "survey.heartbeat", actionCount: this.actionCount });
    },


    APage: {
      displayStatus: "previous",
      page: undefined
    },
    BPage: {
      displayStatus: "current",
      page: undefined
    },
    CPage: {
      displayStatus: "next",
      page: undefined
    },

    browserBackButtonObserver: Ember.observer('response.isBrowserBackPressed', function () {
      if (this.get('response.hasPrevItem')) {
        this.send('back');
      } else {
        this.send('toggleNoBack');
      }
    }),

    currentPageController: Ember.computed('currentDisplayedPage', function () {
      return this.get('BPage').pageController;
    }),

    currentLanguage: Ember.computed("intl.primaryLocale", function () {
      return this.get('intl.primaryLocale');
    }),

    // take the properties for the given module and 'promote' them so they are available as 'module.<blah>'
    inviteProperties: Ember.computed('response.invite.paramData', 'response.currentModule.mnemonic', function () {
      var allProperties = this.get('response.invite.paramData');
      if (!allProperties) {
        allProperties = {};
      }
      allProperties.inviteProperties = this.get('response.invite.paramData.inviteProperties');
      if (allProperties.inviteProperties) {
        allProperties.module = allProperties.inviteProperties[this.get('response.currentModule.mnemonic')];
      }

      return allProperties;
    }),

    showNextButton: Ember.computed('response.hasNextItem', 'backsteps', function () {
      return this.get('response.hasNextItem') && this.get('backsteps') > 0;
    }),

    showBackButton: Ember.computed('response.hasPrevItem', function () {
      return this.get('response.hasPrevItem');
    }),

    showLanguageButton: true,

    showFinishButton: Ember.computed('response.hasNextItem', function () {
      return !this.get('response.hasNextItem');
    }),

    defaultActionIsNext: Ember.computed('response.hasPrevItem', function () {
      return !this.get('response.hasPrevItem');
    }),

    nextPageTransitionStart: function nextPageTransitionStart() {
      if (!this.get("isTransitioning")) {
        this.set("isTransitioning", true);
        var animationSequence = [{ e: $('div.survey-page.next-page'), p: { translateX: "-=100%" }, o: { duration: 400, sequenceQueue: false } }, { e: $('div.survey-page.current-page'), p: { translateX: "-=100%" }, o: { duration: 400, sequenceQueue: false, complete: $.proxy(this.nextPageTransitionComplete, this) } }];
        $.Velocity.RunSequence(animationSequence);
      }
    },
    nextPageTransitionComplete: function nextPageTransitionComplete() {
      this.get('response').goToNextPage();
      this.set('BPage.page', this.get('response.currentPage'));
      this.set("isTransitioning", false);

      var animationSequence = [{ e: $('div.survey-page.current-page'), p: { translateX: "+=200%" }, o: { duration: 0 } }, { e: $('div.survey-page.current-page'), p: { translateX: "-=100%" }, o: { duration: 400 } }, { e: $('div.survey-page.next-page'), p: { translateX: "+=100%" }, o: { duration: 0 } }];
      //run this later to make sure the response computed properties have updated the page first
      Ember.run.later(function () {
        $.Velocity.RunSequence(animationSequence);
      });
    },
    prevPageTransitionStart: function prevPageTransitionStart() {
      if (!this.get("isTransitioning")) {
        this.set("isTransitioning", true);
        var animationSequence = [{ e: $('div.survey-page.current-page'), p: { translateX: "+=100%" }, o: { duration: 400, sequenceQueue: false, complete: $.proxy(this.prevPageTransitionComplete, this) } }, { e: $('div.survey-page.previous-page'), p: { translateX: "+=100%" }, o: { duration: 400, sequenceQueue: false } }];
        $.Velocity.RunSequence(animationSequence);
      }
    },
    prevPageTransitionComplete: function prevPageTransitionComplete() {
      this.get('response').goToPrevPage();
      this.set('BPage.page', this.get('response.currentPage'));
      this.set("isTransitioning", false);

      var animationSequence = [{ e: $('div.survey-page.current-page'), p: { translateX: "-=200%" }, o: { duration: 0 } }, { e: $('div.survey-page.current-page'), p: { translateX: "+=100%" }, o: { duration: 400 } },
      // {e: $('div.survey-page.previous-page'), p: {opacity: 0}, o: {duration: 200}},
      { e: $('div.survey-page.previous-page'), p: { translateX: "-=100%" }, o: { duration: 0 } }];
      //run this later to make sure the response computed properties have updated the page first
      Ember.run.later(function () {
        $.Velocity.RunSequence(animationSequence);
      });
    },


    // For now, rather than deal with the ember run loop for simple increment operation, just implement a normal property.
    // This should be converted to use an ember property if the scope of the task expands.
    actionCount: 0,
    _incrementActionCount: function _incrementActionCount() {
      this.actionCount += 1;
    },


    updateAnchorObserver: Ember.observer("response.currentAnchor", function () {
      if (!this.get("previewMode") && window.history) {
        window.history.replaceState({}, '', window.location.pathname + '#' + this.get("response.currentAnchor"));
      }
    }),

    actions: {
      nextPage: function nextPage() {
        if (this.get('onActivity')) {
          this.get('onActivity')();
        }
        if (this.get('response.hasNextPageOrModule')) {
          this.nextPageTransitionStart();
        }
        this._incrementActionCount();
      },
      prevPage: function prevPage() {
        if (this.get('onActivity')) {
          this.get('onActivity')();
        }
        this.prevPageTransitionStart();
        this._incrementActionCount();
      },


      // for page architecture purposes, the back/next buttons should be thought of as living on the page-content controller,
      // but for display reasons they need to be here, so we live with sending actions down. (and then having them potentially bubble back if the page needs advancing)
      back: function back() {
        this.get('currentPageController').send('prevQuestion');
        this._incrementActionCount();
      },
      didMoveBack: function didMoveBack() {
        this.set('backsteps', this.get('backsteps') + 1);
        this.trackSurveyMetric({ event: "survey.backButton" });
      },
      next: function next() {
        this.get('currentPageController').send('nextQuestionWithoutReset');
        this._incrementActionCount();
      },
      didMoveForward: function didMoveForward() {
        this.set('backsteps', this.get('backsteps') - 1);
        this.trackSurveyMetric({ event: "survey.forwardButton" });
      },
      finish: function finish() {
        if (this.get("previewMode")) {
          this.get("onPreviewExit")();
        } else {
          this.trackSurveyMetric({ event: "survey.finished" });
          this.get('response').finish();
          this.get('router').transitionTo('/survey/finished');
          this._incrementActionCount();
        }
      },
      onFinishScreen: function onFinishScreen() {
        if (!this.get("previewMode")) {
          if (!this.get("response.takingMetadata.finishScreenShown")) {
            this.set("response.takingMetadata.finishScreenShown", true);
            this.get("response").save();
          }
        }
      },
      exit: function exit() {
        this.trackSurveyMetric({ event: "survey.exit" });
        this.get('response').exit();
        this.get('router').transitionTo('/survey/exit');
        this._incrementActionCount();
      },
      resetBacksteps: function resetBacksteps() {
        this.set('backsteps', 0);
        this._incrementActionCount();
      },
      registerPageController: function registerPageController(page, pageController) {
        this.get(page).pageController = pageController;
      },
      toggleHelp: function toggleHelp() {
        var _this = this;

        var showHelpDialog = this.toggleProperty('showHelpDialog');

        // This section handles the user pressing escape to autoclose the modal.
        var toggleHelpEvent = function toggleHelpEvent(e) {
          if (e.key == "Escape") {
            _this.set('showHelpDialog', false);
            _this.element.removeEventListener('keydown', toggleHelpEvent);
          }
        };
        if (showHelpDialog) {
          this.element.addEventListener('keydown', toggleHelpEvent);
        } else {
          this.element.removeEventListener('keydown', toggleHelpEvent);
        }

        this.trackSurveyMetric({ event: "survey.toggleHelp", visible: this.get("showHelpDialog") });
        this._incrementActionCount();
      },
      toggleContactDetails: function toggleContactDetails() {
        var _this2 = this;

        var showContactDetailsDialog = this.toggleProperty('showContactDetailsDialog');

        // This section handles the user pressing escape to autoclose the modal.
        var toggleHelpEvent = function toggleHelpEvent(e) {
          if (e.key == "Escape") {
            _this2.set('showContactDetailsDialog', false);
            _this2.element.removeEventListener('keydown', toggleHelpEvent);
          }
        };
        if (showContactDetailsDialog) {
          this.element.addEventListener('keydown', toggleHelpEvent);
        } else {
          this.element.removeEventListener('keydown', toggleHelpEvent);
        }

        this.trackSurveyMetric({ event: "survey.toggleHelp", visible: this.get("showContactDetailsDialog") });
        // If we've seen the notifications at all, make sure we skip the contact details confirmation later
        this.set('response.skipContactDetails', true);
        this._incrementActionCount();
      },
      toggleExit: function toggleExit() {
        var _this3 = this;

        var showExitDialog = this.toggleProperty('showExitDialog');

        // This section handles the user pressing escape to autoclose the modal.
        var toggleExitEvent = function toggleExitEvent(e) {
          if (e.key == "Escape") {
            _this3.set('showExitDialog', false);
            _this3.element.removeEventListener('keydown', toggleExitEvent);
          }
        };
        if (showExitDialog) {
          this.element.addEventListener('keydown', toggleExitEvent);
        } else {
          this.element.removeEventListener('keydown', toggleExitEvent);
        }

        this.trackSurveyMetric({ event: "survey.toggleExit", visible: this.get("showExitDialog") });
        this._incrementActionCount();
      },
      toggleNoBack: function toggleNoBack() {
        this.toggleProperty('showNoBackDialog');
        this._incrementActionCount();
      },
      changeLanguage: function changeLanguage(locale) {
        this.get('intl').setLocale([locale || 'en', 'en']);
        this.get("router").transitionTo({ queryParams: { locale: locale } });
        this.set('response.currentLanguage', this.get('intl.primaryLocale'));
        this.get('response').save();
      },
      previewExit: function previewExit() {
        if (this.get("onPreviewExit")) {
          this.get("onPreviewExit")();
        }
      }
    }
  });
});