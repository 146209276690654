define('frontend/pods/provider/user/password-reset/route', ['exports', 'frontend/mixins/unauthenticated-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _unauthenticatedRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_unauthenticatedRouteMixin.default, _metricsMixin.default, {
    breadCrumb: null,
    metrics: Ember.inject.service(),
    model: function model(params) {
      // If the token is invalid, it will go to the error route
      var onSuccess = function onSuccess() {
        return Ember.Object.create({
          token: params.token
        });
      };

      return this.store.findRecord('passwordResetToken', params.token).then(onSuccess);
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "passwordReset" });
      }
    }
  });
});