define("frontend/pods/components/provider/entities/entity-path-selector/component", ["exports", "lodash", "npm:query-string"], function (exports, _lodash, _npmQueryString) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var filterConfig = [{ name: "experiment", featureGate: "enableExperiments" }, { name: "experiment-variant", featureGate: "enableExperiments" }, { name: "min-age", featureGate: null }, { name: "max-age", featureGate: null }, { name: "gender", featureGate: null }, { name: "care-unit-type", featureGate: "enableCareUnits" }];

  var filterPropertyMap = {
    experiment: "experiment",
    "experiment-variant": "experimentVariant",
    "care-unit-type": "careUnitType"
  };

  exports.default = Ember.Component.extend({
    classNames: ["entity-path-selector"],
    preload: Ember.inject.service(),
    debounceTimeout: 600,
    institute: Ember.inject.service(),

    filterPropertyMap: filterPropertyMap,

    allFilters: Ember.computed(function () {
      var _this = this;

      return filterConfig.map(function (filterConfig) {
        if (!filterConfig.featureGate || _this.get("features").get(filterConfig.featureGate)) {
          return filterConfig.name;
        }
      }).compact();
    }),

    ageOptions: [].concat(_toConsumableArray(Array(100).keys())).map(function (s) {
      return s.toString();
    }), // Make them strings to be searchable

    genderOptions: ["M", "F"],

    // This gets overwritten once a filter has been added because it still won't be part of the entity tree node
    // until something is actually selected.
    // qqHRB this skips around oddly
    activeFilters: Ember.computed("entityTreeNode.filterData", function () {
      return Object.keys(this.getWithDefault("entityTreeNode.filterData", {}));
    }),

    instituteConditionOptions: Ember.computed.oneWay("preload.instituteConditions"),

    unusedFilters: Ember.computed("allFilters", "activeFilters.[]", function () {
      var activeFilters = this.get("activeFilters");
      return this.get("allFilters").reject(function (f) {
        return activeFilters.includes(f);
      });
    }),

    instituteEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("institute");
    }),

    instituteOptions: Ember.computed("instituteEntity", function () {
      return [this.get("instituteEntity")].compact();
    }),

    organizationEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("organization");
    }),

    organizationOptions: Ember.computed("entityTreeNode", "organizationEntity", function () {
      return [this.get("organizationEntity")].compact();
    }),

    providerEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("provider");
    }),

    providerOptions: Ember.computed("providerEntity", function () {
      return [this.get("providerEntity")].compact();
    }),

    patientEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("patient");
    }),

    patientOptions: Ember.computed("patientEntity", function () {
      return [this.get("patientEntity")].compact();
    }),

    treatmentTrackEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("treatment-track");
    }),

    treatmentTrackOptions: Ember.computed("treatmentTrackOptions", function () {
      return [this.get("treatmentTrackEntity")].compact();
    }),

    treatmentServiceEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("treatment-service");
    }),

    treatmentServiceOptions: Ember.computed("treatmentServiceOptions", function () {
      return [this.get("treatmentTrackService")].compact();
    }),

    panelEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("panel");
    }),

    panelOptions: Ember.computed("panelOptions", function () {
      return [this.get("panel")].compact();
    }),

    careEpisodeEntity: Ember.computed("entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("care-episode");
    }),

    careEpisodeOptions: Ember.computed("patientEntity", "careEpisodeEntity", function () {
      var _this2 = this;

      if (this.get("patientEntity")) {
        return this.get("store").findRecord("patient", this.get("patientEntity.id"), {
          include: "active-care-episodes"
        }).then(function (result) {
          _this2.set("activeCareEpisodes", result.get("activeCareEpisodes"));
          return result.get("activeCareEpisodes").map(function (ce) {
            return ce.toEntity();
          });
        });
      } else {
        return [this.get("careEpisodeEntity")];
      }
    }),

    instituteConditionEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("institute-condition");
    }),

    experimentEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("experiment");
    }),

    experimentVariantEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("experiment-variant");
    }),

    careUnitTypeEntity: Ember.computed("entityTreeNode", "entityTreeNode.path", function () {
      return this.get("entityTreeNode").entityForType("care-unit-type");
    }),

    minAge: Ember.computed("entityTreeNode.path", function () {
      return this.get("entityTreeNode").filterValue("min-age");
    }),

    maxAge: Ember.computed("entityTreeNode.path", function () {
      return this.get("entityTreeNode").filterValue("max-age");
    }),

    gender: Ember.computed("entityTreeNode.path", function () {
      return this.get("entityTreeNode").filterValue("gender");
    }),

    showFilters: true,

    _calculatePath: function _calculatePath(overrides) {
      var current = {
        institute: this.get("instituteEntity.id"),
        organization: this.get("organizationEntity.id"),
        panel: this.get("panelEntity.id"),
        provider: this.get("providerEntity.id"),
        patient: this.get("patientEntity.id"),
        treatmentTrack: this.get("treatmentTrackEntity.id"),
        treatmentService: this.get("treatmentServiceEntity.id"),
        careEpisode: this.get("careEpisodeEntity.id"),
        instituteCondition: this.get("instituteConditionEntity.id")
      };

      var entities = Ember.merge(current, overrides);

      var path = "";
      if (entities.institute) {
        path += "/institute/" + entities.institute;
      }
      if (entities.treatmentService) {
        path += "/treatment-service/" + entities.treatmentService;
      }
      if (entities.organization) {
        path += "/organization/" + entities.organization;
      }
      if (entities.panel) {
        path += "/panel/" + entities.panel;
      }
      if (entities.provider) {
        path += "/provider/" + entities.provider;
      }
      if (entities.patient) {
        path += "/patient/" + entities.patient;
      }
      if (entities.treatmentTrack) {
        path += "/treatment-track/" + entities.treatmentTrack;
      }
      if (entities.careEpisode) {
        path += "/care-episode/" + entities.careEpisode;
      }
      if (entities.instituteCondition) {
        path += "/institute-condition/" + entities.instituteCondition;
      }

      if (path[0] === "/") {
        path = path.substr(1);
      }

      var filters = this._calculateFilter(overrides);

      if (path.length === 0) {
        return filters ? "?" + filters : null;
      } else {
        return filters ? path + "?" + filters : path;
      }
    },
    _calculateFilter: function _calculateFilter(overrides) {
      var current = {
        experiment: this.get("experimentEntity.id"),
        "experiment-variant": this.get("experimentVariantEntity.id"),
        "care-unit-type": this.get("careUnitTypeEntity.id"),
        gender: this.get("gender"),
        "min-age": this.get("minAge"),
        "max-age": this.get("maxAge")
      };

      var filterSet = _lodash.default.pick(Ember.merge(current, overrides), this.get("allFilters"));

      // We want to remove now-nulled keys to ensure they don't get sent to the back end.
      for (var key in filterSet) {
        if (filterSet[key] === null) {
          delete filterSet[key];
        }
      }

      return _npmQueryString.default.stringify(filterSet);
    },


    actions: {
      organizationSelected: function organizationSelected(organization) {
        this.get("onPathChange")(this._calculatePath({
          organization: organization ? organization.get("id") : null
        }));
      },
      treatmentServiceSelected: function treatmentServiceSelected(treatmentService) {
        this.get("onPathChange")(this._calculatePath({
          treatmentService: treatmentService ? treatmentService.get("id") : null
        }));
      },
      panelSelected: function panelSelected(panel) {
        this.get("onPathChange")(this._calculatePath({
          panel: panel ? panel.get("id") : null
        }));
      },
      providerSelected: function providerSelected(provider) {
        this.get("onPathChange")(this._calculatePath({ provider: provider ? provider.get("id") : null }));
      },
      patientSelected: function patientSelected(patient) {
        this.get("onPathChange")(this._calculatePath({ patient: patient ? patient.get("id") : null }));
      },
      treatmentTrackSelected: function treatmentTrackSelected(treatmentTrack) {
        this.get("onPathChange")(this._calculatePath({
          treatmentTrack: treatmentTrack ? treatmentTrack.get("id") : null
        }));
      },
      instituteConditionSelected: function instituteConditionSelected(instituteCondition) {
        this.get("onPathChange")(this._calculatePath({
          instituteCondition: instituteCondition ? instituteCondition.get("id") : null
        }));
      },
      careEpisodeSelected: function careEpisodeSelected(careEpisode) {
        // We might be handed an entity, which doesn't have this data, so look up from
        if (careEpisode) {
          var fullCareEpisode = this.getWithDefault("activeCareEpisodes", []).findBy("id", careEpisode.get("id"));

          var orgId = fullCareEpisode ? fullCareEpisode.belongsTo("organization").id() : null;

          var params = {
            careEpisode: careEpisode ? careEpisode.get("id") : null,
            organization: orgId
          };

          this.get("onPathChange")(this._calculatePath(params));
        } else {
          this.get("onPathChange")(this._calculatePath({ careEpisode: null }));
        }
      },
      addFilter: function addFilter(filter) {
        this.get("activeFilters").addObject(filter);
      },
      removeFilter: function removeFilter(filter) {
        var filterChange = {};
        filterChange[filter] = null;
        this.get("onPathChange")(this._calculatePath(filterChange));
      },
      filterSelected: function filterSelected(filter, value) {
        var filterChange = {};
        filterChange[filter] = value ? value.get("id") : null;
        this.get("onPathChange")(this._calculatePath(filterChange));
      },
      updateGender: function updateGender(newGender) {
        var filterChange = { gender: newGender };
        this.get("onPathChange")(this._calculatePath(filterChange));
      },
      updateMinAge: function updateMinAge(newAge) {
        var filterChange = { "min-age": parseInt(newAge) };
        this.get("onPathChange")(this._calculatePath(filterChange));
      },
      updateMaxAge: function updateMaxAge(newAge) {
        var filterChange = { "max-age": parseInt(newAge) };
        this.get("onPathChange")(this._calculatePath(filterChange));
      }
    }
  });
});