define('frontend/models/assessment/assessment', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr('string'),
    internal_title: _emberData.default.attr('string'),
    pages: _emberData.default.hasMany('assessment/pages'),
    reports: _emberData.default.hasMany('assessment/reports', { async: true }),
    questions: _emberData.default.attr('')
  });
});