define('frontend/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _raven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _raven.default.extend({
    ignoreError: function ignoreError(error) {
      try {
        if (error) {
          if (error.errors === 'Token expired' || error.errors === 'Token required') {
            return true;
          }
        }

        return this._super.apply(this, arguments);
      } catch (ex) {
        console.log("Failed to check ignoreError", error, ex);
        return false;
      }
    }
  });
});