define('frontend/pods/components/reporting/columns/patient-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    name: Ember.computed("column.dataMapping.name", "row", function () {
      return this.get("row")[this.get("column.dataMapping.name")];
    }),
    patientId: Ember.computed("column.dataMapping.id", "row", function () {
      return this.get("row")[this.get("column.dataMapping.id")];
    })
  });
});