define('frontend/pods/components/reporting/columns/action-links/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var baseParamMap = {
    providerId: 'provider.id',
    organizationId: 'organization.id',
    preset: 'preset',
    start: 'startTime',
    end: 'endTime'
  };

  exports.default = Ember.Component.extend({
    linksWithParams: Ember.computed('column.links.[]', function () {
      var row = this.get("row");
      var reportParams = this.get("reportParams");

      return this.get("column.links").map(function (link) {
        var baseParams = link.baseParams.reduce(function (acc, field) {
          acc[field] = reportParams.get(baseParamMap[field]);
          return acc;
        }, {});

        // https://discuss.emberjs.com/t/passing-dynamic-query-params-as-an-object-in-link-to-helper/9136/3
        // For each link, we want to take the params which it has declared sticky, e.g. provider id and dates,
        // and put them in the URL.
        // We can get these values one of two ways:
        //   If it's a rowProperty, that means that e.g. you're clicking on a row about the provider and you want
        //   to get the provider id from the row.
        //   If it's a report params property, it'll take whatever the report is currently set to globally.
        return {
          report: link.report,
          icon: link.icon,
          linkToParams: ['provider.reports.show', link.report, {
            isQueryParams: true,
            values: link.params.reduce(function (acc, param) {
              if (param.rowProperty) {
                acc[param.param] = row[param.rowProperty];
              } else {
                acc[param.param] = reportParams.get(param.reportParamsProperty);
              }

              return acc;
            }, baseParams)
          }]
        };
      });
    })
  });
});