define('frontend/pods/components/common/controls/spinner-button/async-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      observer = Ember.observer,
      deprecate = Ember.deprecate,
      getWithDefault = Ember.getWithDefault,
      Component = Ember.Component;


  var positionalParamsMixin = {
    positionalParams: 'params'
  };

  var ButtonComponent = Component.extend(positionalParamsMixin, {
    tagName: 'button',
    textState: 'default',
    reset: false,
    classNames: ['async-button'],
    classNameBindings: ['textState'],
    attributeBindings: ['disabled', 'type', '_href:href', 'tabindex', 'title'],

    type: 'submit',
    disabled: computed('textState', 'disableWhen', function () {
      var textState = get(this, 'textState');
      var disableWhen = get(this, 'disableWhen');
      return disableWhen || textState === 'pending';
    }),

    click: function click() {
      var _this = this;

      var params = getWithDefault(this, 'params', []);
      var callbackHandler = function callbackHandler(promise) {
        set(_this, 'promise', promise);
      };

      if (typeof this.attrs.action === 'function') {
        var _attrs;

        var deprecatingCallbackHandler = function deprecatingCallbackHandler(promise) {
          deprecate('When using closure style actions with ember-async-button,\nplease return the promise instead of using the callback argument.\nThe callback for closure actions will be removed in future versions.', false, { id: 'ember-async-button.action-callback', until: '0.8.0' });

          callbackHandler(promise);
        };

        var promise = (_attrs = this.attrs).action.apply(_attrs, [deprecatingCallbackHandler].concat(_toConsumableArray(params)));

        if (promise && typeof promise.then === 'function') {
          callbackHandler(promise);
        }
      } else {
        var actionArguments = ['action', callbackHandler].concat(_toConsumableArray(params));

        this.sendAction.apply(this, _toConsumableArray(actionArguments));
      }

      set(this, 'textState', 'pending');

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    },


    text: computed('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected', function () {
      return getWithDefault(this, this.textState, get(this, 'default'));
    }),

    resetObserver: observer('textState', 'reset', function () {
      var states = ['resolved', 'rejected', 'fulfilled'];
      var found = false;
      var textState = get(this, 'textState');

      for (var idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }

      if (get(this, 'reset') && found) {
        set(this, 'textState', 'default');
      }
    }),

    handleActionPromise: observer('promise', function () {
      var _this2 = this;

      var promise = get(this, 'promise');

      if (!promise) {
        return;
      }

      promise.then(function () {
        if (!_this2.isDestroyed) {
          set(_this2, 'textState', 'fulfilled');
        }
      }).catch(function () {
        if (!_this2.isDestroyed) {
          set(_this2, 'textState', 'rejected');
        }
      });
    }),

    setUnknownProperty: function setUnknownProperty(key, value) {
      if (key === 'resolved') {
        deprecate('The \'resolved\' property is deprecated. Please use \'fulfilled\'', false);
        key = 'fulfilled';
      }

      this[key] = null;
      this.set(key, value);
    },


    _href: computed('href', function () {
      var href = get(this, 'href');
      if (href) {
        return href;
      }

      var tagName = get(this, 'tagName').toLowerCase();
      if (tagName === 'a' && href === undefined) {
        return '';
      }
    })
  });

  // Ember 1.13.6 will deprecate specifying `positionalParams` on the
  // instance in favor of class level property
  //
  // Having both defined keeps us compatible with Ember 1.13+ (all patch versions)
  ButtonComponent.reopenClass(positionalParamsMixin);

  exports.default = ButtonComponent;
});