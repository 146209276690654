define('frontend/pods/provider/plan/entities/create/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      path: 'path'
    }
  });
});