define('frontend/locales/customers/map', ['exports', 'frontend/locales/customers/testc', 'frontend/locales/customers/test', 'frontend/locales/customers/opg', 'frontend/locales/customers/varesearch', 'frontend/locales/customers/navigate'], function (exports, _testc, _test, _opg, _varesearch, _navigate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    testc: _testc.default,
    test: _test.default,
    vapilot: _varesearch.default,
    vatest: _varesearch.default,
    navigate: _navigate.default,
    custom: _navigate.default,
    opg: _opg.default
  };
});