define('frontend/pods/components/provider/assessment/report-v2/graph-scale-group/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled', 'lodash'], function (exports, _component, _drawerEnabled, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_drawerEnabled.default, {
    primaryScale: Ember.computed("scales", function () {
      return this.getWithDefault("scales", []).find(function (s) {
        return s.get("displayData.groupDisplayData");
      });
    }),

    subscales: Ember.computed("primaryScale", "scales", function () {
      return this.getWithDefault("scales", []).filter(function (s) {
        return !s.get("displayData.groupDisplayData");
      });
    }),

    focusedScale: Ember.computed.oneWay('primaryScale'),

    unfocusedScales: Ember.computed('focusedScale', 'scales', function () {
      return this.get('scales').rejectBy('id', this.get("focusedScale.id"));
    }),

    focusedScaleArray: Ember.computed('focusedScale', function () {
      return [this.get('focusedScale')];
    }),

    graphDomains: ['raw', 'normed', 'interpreted'],

    // Note that scales is passed in through parameter.

    currentValuesByScale: Ember.computed("currentValues.[]", function () {
      return _lodash.default.groupBy(this.get("currentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),
    mostRecentValuesByScale: Ember.computed("mostRecentValues.[]", function () {
      return _lodash.default.groupBy(this.get("mostRecentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),

    currentFocusedValue: Ember.computed("focusedScale", "currentValues.[]", function () {
      return this.get("currentValues").filterBy('scale', this.get('focusedScale'));
    }),

    historyByScale: Ember.computed('reports.[]', 'scale', function () {
      var scaleMnemonics = this.get("scales").mapBy('mnemonic');
      return this.get("reports").flatMap(function (report) {
        var filtered = report.get("computedValues").filter(function (cv) {
          return cv.get("domain") === 'interpreted' && scaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
        });

        if (filtered.length > 0) {
          return [_lodash.default.groupBy(filtered, function (cv) {
            return cv.get("scale.mnemonic");
          })];
        } else {
          return [];
        }
      });
    }),

    mostRecentValuesFocusedValues: Ember.computed("focusedScale", "mostRecentValues.[]", function () {
      return this.get("mostRecentValues").filterBy('scale', this.get('focusedScale'));
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});