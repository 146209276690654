define('frontend/utils/plugin-redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // We need to replace the subdomain with internal to make the redirect
  // work correctly. This is sadly not elegant to do.
  var getDomain = exports.getDomain = function getDomain(hostname) {
    var domains = hostname.split('.');
    var relevantDomains = {
      1: function _() {
        // localhost
        return domains;
      },
      2: function _() {
        if (domains.includes('localhost')) {
          return [domains[1]];
        } else {
          // mirah.com
          return domains;
        }
      },
      3: function _() {
        // sub.mirah.com
        return [domains[1], domains[2]];
      },
      4: function _() {
        // sub.staging.mirah.dev
        return [domains[1], domains[2], domains[3]];
      }
    };
    return relevantDomains[domains.length]().join('.');
  };

  // Handles redirecting internal users.
  var redirectInternalUser = exports.redirectInternalUser = function redirectInternalUser(router) {
    var instituteId = router.get("institute.current.content.id");

    // This preserves the original intended destination even though
    // the current url should be pointing to the login page.
    var intendedDestination = '' + window.location.pathname + window.location.search;

    var protocol = window.location.protocol;
    var host = getDomain(window.location.hostname) + ':' + window.location.port;
    var path = protocol + '//internal.' + host + '/app/ops/redirect';

    var redirectUrl = path + '?destination=' + encodeURIComponent(intendedDestination) + '&institute=' + encodeURIComponent(instituteId);

    window.location.assign(redirectUrl);
    return;
  };

  var redirectToOpsTools = function redirectToOpsTools() {
    var internalOpsPage = '/app/ops';
    window.location.assign(internalOpsPage);
    return;
  };

  var internalInstituteLoginRedirect = exports.internalInstituteLoginRedirect = function internalInstituteLoginRedirect(router) {
    if (router.get('institute.current.shortcode') === 'internal' && router.get('session.data.authenticated.is_internal')) {
      redirectToOpsTools();
      return true;
    }
    return false;
  };

  // Checks to see if this is already an internal redirect.
  // We want to let the normal auth flow if so.
  var isInternalRedirect = function isInternalRedirect() {
    var redirect = new URLSearchParams(window.location.search).get('internal_redirect');
    return redirect;
  };

  // Checks for the div inserted by our chrome plugin that reports
  // the version number of the plugin.
  var hasPlugin = function hasPlugin() {
    var pluginDiv = document.getElementById('mirah-plugin-version');
    if (pluginDiv) {
      return true;
    }
    return false;
  };

  // Whether or not we should do a redirect for internal users.
  var shouldInternalRedirect = exports.shouldInternalRedirect = function shouldInternalRedirect() {
    var isInternalDomain = window.location.hostname.split('.')[0] == 'internal';
    return hasPlugin() && !isInternalDomain && !isInternalRedirect();
  };
});