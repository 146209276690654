define("frontend/pods/components/common/dirty-changes-warning/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      cancelDirtyTransition: function cancelDirtyTransition() {
        if (this.get("dirtyChecker")) {
          this.set("dirtyChecker.attemptedTransition", null);
        }
      },
      confirmDirtyTransition: function confirmDirtyTransition() {
        this.sendAction("discardChanges");
        if (this.get("dirtyChecker")) {
          var transition = this.get("dirtyChecker.attemptedTransition");
          this.set("dirtyChecker.confirmExit", true);
          transition.retry();
        }
      }
    }
  });
});