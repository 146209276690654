define('frontend/pods/components/provider/assessment/dashboard/failed-logins/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    filter: Ember.inject.service("provider/filter"),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "eventDate",
        title: intl.t('provider.assessments.failedLogins.columns.time'),
        template: "components/common/server-side-table/date-from-now"
      }, {
        title: intl.t('provider.assessments.failedLogins.columns.lastName'),
        template: "components/provider/assessment/dashboard/failed-logins/columns/patient-name",
        disableSorting: true
      }, {
        title: intl.t('provider.assessments.failedLogins.columns.birthDate'),
        template: "components/provider/assessment/dashboard/failed-logins/columns/patient-dob",
        disableSorting: true
      }, {
        title: intl.t('provider.assessments.failedLogins.columns.lastSSN'),
        template: "components/provider/assessment/dashboard/failed-logins/columns/patient-ssn",
        disableSorting: true
      }, {
        title: intl.t('provider.assessments.failedLogins.columns.session'),
        template: "components/provider/assessment/dashboard/failed-logins/columns/patient-session",
        disableSorting: true
      }];
      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // Note that the patient search term is passed straight through to the component.
      // We pass in the details for the request rather than the request itself to make sure
      // the filtering is done correctly first time round.
      if (!this.get("data")) {
        var data = Ember.Object.create({
          store: this.get("store"),
          modelName: 'business-event',
          query: { include: "patient,patient-session", filter: { preset: "with_recent_failed_logins" }, page_size: 25 }
        });
        this.set("data", data);
      }
    }
  });
});