define('frontend/pods/components/provider/assessment/scale-list/component', ['exports', 'frontend/pods/components/provider/assessment/scale-info-drawer/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.ScaleDrawerMixin, {
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),

    scales: Ember.computed.filterBy('preload.scales', 'independent', true),

    actions: {
      scaleDetailSelected: function scaleDetailSelected(scale) {
        this.set('focusedScale.scale', scale);
      }
    }
  });
});