define('frontend/models/care-episode-message', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    careEpisode: _emberData.default.belongsTo("careEpisode"),
    user: _emberData.default.belongsTo("careEpisode"),
    status: _emberData.default.attr("string"),
    statusEvent: _emberData.default.attr("string"),
    completedAt: _emberData.default.attr("date"),
    content: _emberData.default.attr(),
    value: _emberData.default.attr("string")
  });
});