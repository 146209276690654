define('frontend/models/role', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    intl: Ember.inject.service(),
    name: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),

    translatedName: Ember.computed('name', function () {
      var intlString = 'model.role.' + this.get('name') + '.name';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString);
      } else {
        return this.get("name");
      }
    })

  });
});