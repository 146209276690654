define('frontend/serializers/survey/response-item', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(snapshot) {
      var result = this._super.apply(this, arguments);
      var model = snapshot.record;

      var ids = model.getWithDefault('overriddenItems', []).mapBy('id');

      result.data.attributes['overridden-item-ids'] = ids;

      return result;
    }
  });
});