define("frontend/pods/internal/institutes/show/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.get("store").findRecord("institute", params.institute_id, { include: 'institute-scales.internal-scale' });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('name'), linkable: true, isRaw: true });
    }
  });
});