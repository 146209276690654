define("frontend/instance-initializers/session-id", ["exports", "ember-cli-uuid/utils/uuid-helpers"], function (exports, _uuidHelpers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    var metrics = app.lookup("service:metrics");
    var raven = app.lookup("service:raven");

    var sessionId = (0, _uuidHelpers.default)();

    metrics.set('context.sessionId', sessionId);
    raven.callRaven('setExtraContext', {
      sessionId: sessionId
    });
  }

  exports.default = {
    name: 'session-id',
    after: 'sentry-raven',
    initialize: initialize
  };
});