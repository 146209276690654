define('frontend/pods/components/reporting/drilldown-table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isExpanded: false,
    type: "generic",

    columns: Ember.A(),
    row: undefined,

    showChildren: false,

    hasChildren: Ember.computed.notEmpty('collections'),

    entityTreeNode: Ember.computed.alias('reportEntityTreeNode.entityTreeNode'),

    typeClassName: Ember.computed("type", function () {
      return "report-row-" + this.get("type");
    }),

    // We can't just use the collection directly as it'll disappear during refreshes.
    // So only update it when we have new data.
    collectionsUpdated: Ember.observer('reportEntityTreeNode.entityTreeCollections.[]', function () {
      if (this.get("reportEntityTreeNode.entityTreeCollections.isFulfilled")) {
        this.set("collections", this.get("reportEntityTreeNode.entityTreeCollections"));
      }
    }),

    filteredCollections: Ember.computed('collections.[]', 'features.enablePayors', 'features.enableConditions', 'features.enableFidelity', function () {
      var activeCollections = this.getWithDefault("collections", []);

      if (!this.get('features.enableExperiments')) {
        activeCollections = activeCollections.reject(function (collection) {
          return collection.get("collection") === 'experiments' || collection.get("collection") === 'experiment-variants';
        });
      }

      if (!this.get('features.enableTreatmentServices')) {
        activeCollections = activeCollections.reject(function (collection) {
          return collection.get("collection") === 'treatment-services';
        });
      }

      if (!this.get('features.enablePayors')) {
        activeCollections = activeCollections.reject(function (collection) {
          return collection.get("collection") === 'payors' || collection.get("collection") === 'institute-payors';
        });
      }

      if (!this.get('features.enableConditions')) {
        activeCollections = activeCollections.reject(function (collection) {
          return collection.get("collection") === 'institute-conditions';
        });
      }

      if (!this.get('features.enableTeamsAndPanels')) {
        activeCollections = activeCollections.reject(function (collection) {
          return collection.get("collection") === 'panels';
        });
      }

      return activeCollections;
    }),

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.set("collections", this.get("reportEntityTreeNode.entityTreeCollections"));
    },


    actions: {
      expand: function expand() {
        if (this.get('reportViewData.detailColumns.length')) {
          //don't expand/contract on clicking the 
          if (typeof event !== 'undefined' && event && event.target) {
            if ($(event.target).closest('a').length || $(event.target).closest('button').length) {
              return;
            }
          }
          this.toggleProperty('isExpanded');
        }
      },
      zoom: function zoom(newRoot) {
        this.get("onZoom")(newRoot);
      },
      toggleChildren: function toggleChildren() {
        this.toggleProperty("showChildren");
      }
    }
  });
});