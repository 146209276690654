define('frontend/helpers/rounded-percentage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roundedPercentage = roundedPercentage;
  function roundedPercentage(value /*, hash*/) {
    if (value[0] !== 0 && !value[0]) {
      return '';
    }
    return Math.min(100, value[0]).toFixed(0);
  }

  exports.default = Ember.Helper.helper(roundedPercentage);
});