define('frontend/pods/components/common/controls/text-expander/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    isExpanded: false,
    abbreviationLength: 50,
    abbreviationCut: Ember.computed('abbreviationLength', function () {
      return Math.max(0, this.get('abbreviationLength') - 7); //the cut is different than the length, since the '...more' takes up space
    }),
    text: Ember.computed('textKey', function () {
      return this.get('intl').t(this.get('textKet'));
    }),

    needsAbbreviation: Ember.computed('text', 'abbreviationLength', function () {
      return this.get('text.length') > this.get('abbreviationLength');
    }),

    abbreviatedText: Ember.computed('text', function () {
      var text = this.get('text');
      if (!this.get('needsAbbreviation')) {
        return text;
      }
      return text.slice(0, this.get('abbreviationCut'));
    }),

    actions: {
      toggleExpand: function toggleExpand() {
        this.set('isExpanded', !this.get('isExpanded'));
      }
    }
  });
});