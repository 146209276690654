define('frontend/pods/components/provider/assessment/report-v2/longitudinal-report/component', ['exports', 'frontend/models/assessment/report-settings', 'lodash'], function (exports, _reportSettings, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaultPopulationMnemonic: "general",

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("reportSettings")) {
        var selectedReport = this.get("selectedReport");

        var reports = this.get("reports");

        if (!selectedReport && reports) {
          selectedReport = _lodash.default.last(reports.sortBy("patientSession.targetDate"));
        }
        var reportSettings = _reportSettings.default.create({ populations: this.get("populations"), selectedReport: selectedReport, allReports: reports });

        this.set("reportSettings", reportSettings);
      }
    },


    activeReport: Ember.computed.alias('reportSettings.selectedReport'),

    isIntake: Ember.computed("reportSettings.selectedReport", "reports", function () {
      if (this.get("reports.length") === 1) {
        return true;
      }
      var sortedReports = this.getWithDefault("reports", []).sortBy("patientSession.targetDate").toArray();

      return sortedReports.indexOf(this.get("reportSettings.selectedReport")) === 0;
    }),

    //needed to update breadcrumb -- this should be replaced in a later release by just modifying model.selectedReport directly
    selectedReportChanged: Ember.observer('activeReport', function () {
      // deal with the change
      this.set('model.activeReport', this.get('activeReport'));
    }),

    // Show the frameheader if we're embedded
    showFrameHeader: Ember.computed(function () {
      return !(window.location === window.parent.location);
    })
  });
});