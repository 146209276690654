define('frontend/pods/components/provider/plan/change-core-plan-form/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/error-printer'], function (exports, _rollbackMixin, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, _errorPrinter.default, {
    rollbackProperties: ['entityMeasurementPlan'],
    messages: Ember.inject.service(),
    // Start at the start of today automatically unless there is a session in the past that is still open,
    // in which case start then.
    startDate: Ember.computed('nextSessionDate', function () {
      var nextSessionDate = this.get('nextSessionDate');
      var effectiveDate = nextSessionDate && nextSessionDate < new Date() ? nextSessionDate : new Date();
      return moment(effectiveDate).startOf('day').toDate();
    }),

    // Don't automatically refresh on new data
    changeRequest: Ember.computed(function () {
      return this.get('store').createRecord('care/alterMeasurementPlanRequest', {
        entityTreeNode: this.get('entityTreeNode'),
        effectiveDate: this.get('startDate'),
        measurementPlan: null,
        planType: 'core',
        mode: 'change'
      });
    }),

    actions: {
      confirmPlanChange: function confirmPlanChange() {
        var _this = this;

        var plan = this.get("changeRequest");

        var onFailure = function onFailure(error) {
          _this.get('messages').danger(_this.printError(error));
        };

        return plan.save().then(this.get('onSave'), onFailure);
      },
      cancel: function cancel() {
        this._rollbackAllProperties();
        this.get("onCancel")();
      }
    }
  });
});