define('frontend/pods/provider/patients/show/related-people/create/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      var patient = this.modelFor("provider.patients.show");
      var relatedPerson = this.store.createRecord('relatedPerson', { patient: patient });
      return Ember.RSVP.hash({
        patient: patient,
        relatedPerson: relatedPerson
      });
    }
  });
});