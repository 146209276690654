define('frontend/pods/components/common/controls/date-range/preset-operations', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = calculateTimePreset;


  var operations = {
    yesterday: _moment.default.duration(1, 'days'),
    today: _moment.default.duration(0, 'days'),

    lastWeek: {
      duration: _moment.default.duration(1, 'weeks'),
      padding: 'week'
    },
    thisWeek: 'week',
    lastMonth: {
      duration: _moment.default.duration(1, 'months'),
      padding: 'month'
    },
    thisMonth: 'month',
    lastYear: {
      duration: _moment.default.duration(1, 'years'),
      padding: 'year'
    },
    thisYear: 'year'
  };

  function calculateTimePreset(preset) {
    // With no operations defined we set null dates
    if (!Object.prototype.hasOwnProperty.call(operations, preset)) {
      return {
        startDate: null,
        endDate: null
      };
    }

    var operation = operations[preset];
    if (_moment.default.isDuration(operation)) {
      // If the operation is a duration convert to object form. The default padding is added below.
      operation = { duration: operation };
    } else if (typeof operation === 'string') {
      // String values are used for padding with no duration calculation (eg for thisWeek)
      operation = { padding: operation };
    }

    var _operation = operation,
        _operation$duration = _operation.duration,
        duration = _operation$duration === undefined ? 0 : _operation$duration,
        _operation$padding = _operation.padding,
        padding = _operation$padding === undefined ? 'day' : _operation$padding;


    return {
      startDate: (0, _moment.default)().subtract(duration).startOf(padding).toDate(),
      endDate: (0, _moment.default)().subtract(duration).endOf(padding).toDate()
    };
  }
});