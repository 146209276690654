define('frontend/mixins/fixed-modal-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    showModal: false,
    actions: {
      toggleModal: function toggleModal() {
        var _this = this;

        this.set('showModal', !this.get('showModal'));
        if (this.get('showModal')) {
          // ember-modal dialog is wonky when deployed from fixed-position elements
          // so we have to do the event handling for it.
          // we need to close the dialog on clicks outside the modal, but we can't
          // use direct binding since the dialog overlay might not exist yet
          $(document).on('click', '.ember-modal-overlay', function (e) {
            if ($(e.target).hasClass('ember-modal-overlay')) {
              _this.send('toggleModal');
            }
          });
        } else {
          $(document).off('click', '.ember-modal-overlay');
        }
      }
    }
  });
});