define("frontend/pods/components/common/json-pretty-print/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    prettyPrinted: Ember.computed("data", function () {
      return JSON.stringify(this.get("data"), null, 2);
    })
  });
});