define("frontend/pods/components/provider/patients/doctor-with-claim-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    showClaimButton: Ember.computed("doctor", "isYourPatient", function () {
      return !this.get("doctor.id") || !this.get("isYourPatient");
    }),
    isYourPatient: Ember.computed("sessionUser.currentUser", "doctor", function () {
      return this.get("sessionUser.currentUser.id") && this.get("sessionUser.currentUser.id") === this.get("doctor.id");
    })
  });
});