define("frontend/locales/customers/opg", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: {
      // OPG wants everything to refer to case managers rather than therapists so as not to get confused on the services they provide.
      "question.understandTreatmentHelp.report": "Understands how coaching will help them",
      "question.understandTreatmentHelp.fullText": "I understand how coaching will help me",
      "question.therapistUnderstandsMe.report": "Feel their case manager understands them",
      "question.therapistUnderstandsMe.fullText": "I feel that my case manager understands me and understands why I am engaging in coaching now",
      "question.therapistAcceptsAsPerson.report": 'Feel their case manager accepts them as a person',
      "question.therapistAcceptsAsPerson.fullText": 'I feel that my case manager accepts me as a person',
      "question.therapistTechniqueExerciseQuantity.fullText": 'I would like my case manager to use more/fewer techniques and exercises',
      "question.therapistHumorFormalQuantity.fullText": 'I would like my case manager to show their personality and humour more / be more formal',
      "question.therapistFeelingCognitionFocus.fullText": 'I would like my case manager to focus more on my feelings / more on my thoughts',
      "question.focusOnTherapistRelationship.fullText": 'I would like my case manager to focus more on the relationship between us / less on the relationship'
    }
  };
});