define('frontend/pods/components/common/collapsible-breadcrumbs/component', ['exports', 'ember-crumbly/components/bread-crumbs'], function (exports, _breadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _breadCrumbs.default.extend({
    screen: Ember.inject.service(),

    _lookupBreadCrumb: function _lookupBreadCrumb() {
      var crumbs = this._super.apply(this, arguments);

      // If we're in compressed mode, make some changes:
      //   Only ever allow the first and last crumbs.
      if (this.get("screen.isCompressed")) {
        if (crumbs && crumbs.length > 2) {
          crumbs = [crumbs[0], crumbs[crumbs.length - 1]];
        }
      }
      return crumbs;
    }
  });
});