define("frontend/pods/components/provider/related-people/edit-related-person-form/component", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    classNames: ["edit-related-person-form"],
    noHipaa: _environment.default.noHipaa,
    relationshipOptions: Ember.A([{ id: "guardian", name: "Guardian" }, { id: "teacher", name: "Teacher" }, { id: "mother", name: "Mother" }, { id: "father", name: "Father" }]),
    languageOptions: Ember.A([{ id: "en", name: "English" }, { id: "es", name: "Spanish" }]),

    actions: {
      submit: function submit() {
        var action = this.get("submitAction");
        if (typeof action === "function") {
          return action();
        } else {
          console.warn("No action function was passed to edit-related-person-form");
        }
      }
    },
    didDestroyElement: function didDestroyElement() {
      if (this.get("relatedPerson.isNew")) {
        this.get("relatedPerson").rollbackAttributes();
      }
    }
  });
});