define('frontend/models/patient-session', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'moment', 'frontend/models/entity-tree-node', 'frontend/models/care-episode'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper, _moment, _entityTreeNode, _careEpisode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Utils = exports.Validations = undefined;


  var validationOptions = {
    careEpisode: (0, _emberCpValidations.validator)('presence', true),
    provider: (0, _emberCpValidations.validator)('presence', true),
    patient: (0, _emberCpValidations.validator)('presence', true)
  };
  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);
  exports.Validations = Validations;


  var Utils = {
    create: function create(store, patient, careEpisode, provider, doCreateAppt) {
      var newSession = store.createRecord('patientSession', { patient: patient, careEpisode: careEpisode, targetDate: new Date(), provider: provider, status: 'new' });
      var report = store.createRecord('assessment/report', { patient: patient, patientSession: newSession });
      if (doCreateAppt) {
        store.createRecord('scheduling/appointment', { patient: patient, patientSession: newSession, provider: provider, source: 'manual' });
      }
      report.autopopulateScales();
      return newSession;
    }
  };
  exports.Utils = Utils;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    intl: Ember.inject.service(),
    MUTABLE_STATES: ["new", "scheduled", "awaiting_assessment", "assessment_in_progress", "feedback_available", "feedback_complete", "report_previewed", "report_reviewed", "complete"],
    UNVIEWED_STATES: ["feedback_available", "feedback_complete"],
    DELETABLE_STATES: ["new", "scheduled", "awaiting_assessment"],
    status: _emberData.default.attr('string'),
    patient: _emberData.default.belongsTo('patient'),
    provider: _emberData.default.belongsTo('provider', { inverse: null }),
    careEpisode: _emberData.default.belongsTo('careEpisode'),
    patientSessionType: _emberData.default.belongsTo('patientSessionType'),
    assessmentReport: _emberData.default.belongsTo('assessment/report'),
    schedulingAppointment: _emberData.default.belongsTo('scheduling/appointment'),
    schedulingRecurrence: _emberData.default.belongsTo('scheduling/recurrence'),
    sessionActiveMeasurementPlans: _emberData.default.hasMany('care/sessionActiveMeasurementPlan'),
    patientSessionMeasurementPlans: _emberData.default.hasMany('care/patientSessionMeasurementPlan'),
    updateSeries: _emberData.default.attr('boolean', { defaultValue: false }),
    reportAvailable: _emberData.default.attr('boolean', { readOnly: true }),
    isReportComplete: _emberData.default.attr('boolean', { readOnly: true }),
    targetDate: _emberData.default.attr('date'),
    statusUpdatedAt: _emberData.default.attr('date'),
    statusEvent: _emberData.default.attr("string"),
    expiresAt: _emberData.default.attr('date'),
    patientSendEmailAt: _emberData.default.attr('date'),
    patientDateEmailed: _emberData.default.attr('date'),
    patientSendTextAt: _emberData.default.attr('date'),
    patientDateTexted: _emberData.default.attr('date'),
    autoplan: _emberData.default.attr('boolean'),
    needsReplanning: _emberData.default.attr('boolean'),
    forceAutoplan: _emberData.default.attr('boolean'),
    sessionNumber: _emberData.default.attr('number'),
    sessionNumberOfType: _emberData.default.attr('number'),

    //this will get more advanced and moved to server-side, but this will do for now
    isMutable: Ember.computed('status', function () {
      return this.get('MUTABLE_STATES').indexOf(this.get('status')) >= 0;
    }),
    isDeletable: Ember.computed('status', function () {
      return this.get('DELETABLE_STATES').indexOf(this.get('status')) >= 0;
    }),
    isUnviewed: Ember.computed('status', function () {
      return this.get('UNVIEWED_STATES').indexOf(this.get('status')) >= 0;
    }),

    // NOTE: there is a nestedData property which is serialized to allow us to save the entire session on one call

    saveNested: _emberData.default.attr("boolean"),

    isWaitingOnPatientCompletion: Ember.computed.equal('status', 'awaiting_assessment'),

    // Note that this is exactly a slight guess: we don't have an actual direct patient session status
    // for 'notifications sent' any more. So instead of looking at the invitation level we just assume that the notificadtions
    // will go out at the time given.
    hasSentNotifications: Ember.computed('status', 'patientDateEmailed', 'patientDateTexted', function () {
      return this.get('patientDateEmailed') || this.get('patientDateTexted');
    }),

    isWaitingToSendNotification: Ember.computed('status', 'patientSendEmailAt', 'patientSendTextAt', function () {
      var status = this.get('status');
      var notificationDate = this.get('patientSendEmailAt') || this.get('patientSendTextAt');
      if (status === 'new' || status === 'scheduled' || status === 'awaiting_assessment') {
        return notificationDate && notificationDate > new Date();
      }
    }),

    nextNotificationDate: Ember.computed('patientSendEmailAt', 'patientSendTextAt', function () {
      var emailDate = this.get("patientSendEmailAt");
      var textDate = this.get("patientSendTextAt");

      if (emailDate && textDate) {
        return emailDate < textDate ? emailDate : textDate;
      } else {
        return emailDate || textDate;
      }
    }),

    lastNotificationDate: Ember.computed('patientDateTexted', 'patientDateEmailed', function () {
      var emailDate = this.get("patientDateEmailed");
      var textDate = this.get("patientDateTexted");

      if (emailDate && textDate) {
        return emailDate > textDate ? emailDate : textDate;
      } else {
        return emailDate || textDate;
      }
    }),

    isInErrorState: Ember.computed('status', function () {
      return this.get('status') === 'locked_out';
    }),

    hasBeenPlanned: Ember.computed('status', 'autoplan', function () {
      // All non-autoplans are automatically considered to have been planned.
      if (!this.get("autoplan")) {
        return true;
      }

      // New and scheduled are not considered planned states.
      return this.get('status') !== 'new' && this.get('status') !== 'scheduled';
    }),

    allowPatientActions: Ember.computed("status", function () {
      var status = this.get("status");
      return status !== 'complete' && status !== 'canceled' && status !== 'feedback_complete';
    }),

    defaultName: Ember.computed('status', 'targetDate', function () {
      var params = {
        dateString: (0, _moment.default)(this.get('targetDate')).calendar(),
        statusString: this.get('intl').t('model.patientSession.statuses.' + this.get('status'))
      };
      return this.get('intl').t('model.patientSession.defaultName', params);
    }),

    // Take the standard participants from the care episode, but also add this session's provider if different.
    allParticipants: Ember.computed("careEpisode.calculatedParticipants.[]", "provider", function () {
      var clone = this.getWithDefault("careEpisode.calculatedParticipants", []).toArray();

      var thisSessionProvider = this.get("provider");

      if (!Ember.get(thisSessionProvider, 'practicing')) {
        return clone;
      }

      if (thisSessionProvider && !clone.find(function (p) {
        return p.get("id") === thisSessionProvider.get("id");
      })) {
        clone.addObject(thisSessionProvider);
      }

      return clone;
    }),

    //return whether this session occurred yesterday or before
    isPastSession: Ember.computed('targetDate', function () {
      return !(0, _moment.default)(this.get('targetDate')).isSame((0, _moment.default)(), 'day') && !(0, _moment.default)(this.get('targetDate')).isAfter((0, _moment.default)(), 'day');
    }),

    statusClass: Ember.computed('status', function () {
      return 'status-' + this.get('status');
    }),

    autopopulateCareEpisode: function autopopulateCareEpisode(careEpisodes, organizations, provider) {
      if (careEpisodes.get("length") === 1) {
        this.set("careEpisode", careEpisodes.get("firstObject"));
      } else if (careEpisodes.get("length") === 0) {
        this.set("careEpisode", this.createNewCareEpisode(organizations, provider));
      } else if (careEpisodes.get('length') >= 2) {
        // TODO: make this do something better.
        var defaultEpisode = careEpisodes.get("firstObject");
        this.set("careEpisode", defaultEpisode);
      }
    },
    createNewCareEpisode: function createNewCareEpisode(organizations, provider) {
      return (0, _careEpisode.createNewCareEpisode)(this.get('store'), this.get('patient'), organizations, provider);
    },


    currentEntityTreeNode: Ember.computed('careEpisode.organization', 'provider', 'patient', 'careEpisode', function () {
      var segments = [this.get('careEpisode.organization'), this.get('provider'), this.get('patient'), this.get('careEpisode')];

      return (0, _entityTreeNode.createFromEntities)(segments, this.get('store'));
    }),

    configuration: Ember.inject.service(),

    activeConfiguration: Ember.computed('currentEntityTreeNode.path', function () {
      var node = this.get('currentEntityTreeNode');

      if (!node) {
        return Ember.Object.create({});
      }
      return this.get('configuration').configurationFor(node);
    })
  });
});