define('frontend/pods/components/provider/assessment/invitation-notification-preference/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),

    allowText: Ember.computed.bool('institute.configuration.sendSmsReminders'),

    shouldSendNotifications: Ember.computed.oneWay('defaultNotification'),

    defaultNotification: Ember.computed('invitation.disableNotifications', 'invitation.supportsNotifications', 'invitation.sendNotifications', 'invitation.sendEmailAt', 'invitation.sendTextAt', function () {
      if (this.get("invitation.isNew")) {
        return this.get("invitation.supportsNotifications") && !this.get('invitation.disableNotifications');
      } else {
        return this.get("invitation.sendNotifications") || !!this.get("invitation.sendEmailAt") || !!this.get("invitation.sendTextAt");
      }
    }),

    showCustomEmailMessage: Ember.computed.notEmpty('invitation.emailMessage'),
    showCustomTextMessage: Ember.computed.notEmpty('invitation.textMessage'),

    shouldSendEmail: Ember.computed("shouldSendNotifications", "invitation.supportsEmail", "invitation.sendEmailAt", function () {
      return this.get("shouldSendNotifications") && (this.get("invitation.supportsEmail") || !!this.get("invitation.sendEmailAt"));
    }),

    shouldSendText: Ember.computed("allowText", "shouldSendNotifications", "invitation.supportsText", "invitation.sendTextAt", function () {
      if (!this.get("allowText")) {
        return false;
      }
      return this.get("shouldSendNotifications") && (this.get("invitation.supportsText") || !!this.get("invitation.sendTextAt"));
    }),

    appointmentDataChanged: Ember.observer("session.activeConfiguration", "appointmentType", "appointment.startDate", function () {
      this._updateNotifications();
    }),

    _updateNotifications: function _updateNotifications() {
      var targetDate = this.getWithDefault("appointment.startDate", new Date());
      this.get("invitation").updateNotificationsToPreferences(this.get('shouldSendNotifications'), targetDate);
    },


    actions: {
      selectEmailMode: function selectEmailMode(value) {
        this.set("shouldSendNotifications", value);
        this._updateNotifications();
      },
      resetEmailMessage: function resetEmailMessage() {
        this.set("showCustomEmailMessage", false);
        this.set("invitation.emailMessage", null);
      },
      resetTextMessage: function resetTextMessage() {
        this.set("showCustomTextMessage", false);
        this.set("invitation.textMessage", null);
      }
    }
  });
});