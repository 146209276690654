define('frontend/models/tabular-metric', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    shortcode: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("string"),
    endDate: _emberData.default.attr("string"),
    preset: _emberData.default.attr("string"),
    breakdown: _emberData.default.attr("string"),
    scale: _emberData.default.attr("string"),
    providerId: _emberData.default.attr("string"),
    organizationId: _emberData.default.attr("string"),
    flagIds: _emberData.default.attr(),
    instituteConditionIds: _emberData.default.attr(),
    contextData: _emberData.default.attr(),
    headers: _emberData.default.attr(),
    rowData: _emberData.default.attr(),
    footerData: _emberData.default.attr(),
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),

    sort: _emberData.default.attr('string'),
    sortDirection: _emberData.default.attr('string'),
    limit: _emberData.default.attr('number')
  });
});