define("frontend/pods/components/patient/assessment/confirm-details-form/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set("fields", Object.keys(this.get("detailsToConfirm") || {}));
    },

    session: Ember.inject.service(),
    messages: Ember.inject.service(),
    actions: {
      confirm: function confirm() {
        var _this = this;

        var patient = this.get("patient");
        var detailsToConfirm = this.get("detailsToConfirm");
        for (var key in detailsToConfirm) {
          patient.set(key, detailsToConfirm[key]);
        }

        var onSuccess = function onSuccess() /*result*/{

          var attemptedTransition = _this.get('session.attemptedTransition');

          if (attemptedTransition) {
            attemptedTransition.retry();
            _this.set('session.attemptedTransition', null);
          } else {
            _this.get("router").transitionTo("patient.assessment.invitation.begin", _this.get("invitationId"));
          }
        };

        var onFailure = function onFailure(error) {
          _this.get('messages').danger(error);
        };

        return patient.save().then(onSuccess, onFailure);
      }
    },
    detailsAreCorrect: true
  });
});