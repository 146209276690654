define('frontend/pods/components/provider/generate-login-code-button/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    tagName: 'span',
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    buttonClasses: 'btn-sm',

    showText: false,

    buttonText: Ember.computed('showText', function () {
      if (this.get("showText")) {
        return this.get('intl').t('provider.loginCodes.generateLoginCodes');
      } else {
        return "";
      }
    }),

    isShowingModal: Ember.computed.and("showCodeModal", "loginCode"),

    actions: {
      generateLoginCode: function generateLoginCode() {
        return this._generateLoginCode();
      },
      closeModal: function closeModal() {
        this.set('showCodeModal', false);
      }
    },

    _generateLoginCode: function _generateLoginCode() {
      var _this = this;

      var details = this.get("session") ? { patientSession: this.get("session") } : { patientSessionId: this.get("sessionId") };
      var request = this.get("store").createRecord("userLoginCode", details);

      var onSuccess = function onSuccess(result) {
        _this.setProperties({
          loginCode: result,
          showCodeModal: true
        });
      };

      var onFailure = function onFailure(error) {
        _this.get('messages').danger(_this.printError(error));
      };

      return request.save().then(onSuccess, onFailure);
    }
  });
});