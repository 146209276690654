define('frontend/mixins/back-action-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      back: function back() {
        history.back();
      }
    }
  });
});