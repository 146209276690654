define('frontend/pods/components/provider/assessment/report-v2/badges/medication-thoughts/component', ['exports', 'frontend/mixins/drawer-enabled', 'lodash'], function (exports, _drawerEnabled, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    tagName: '',
    messageKey: 'medications',
    riskImage: '/images/medication.svg',
    isBadgeShown: Ember.computed.alias('activeReport.includesComputedMedication'),

    isRiskPresent: Ember.computed.not('isMinimalRisk'),

    allRiskValues: Ember.computed('activeReport.computedValues.[]', "activeReport", function () {
      var activeReport = this.get('activeReport');
      if (!activeReport) {
        return 0;
      }

      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');

      var ptpbValues = [];

      // For the old report, we don't want to duplicate the items, so if you find either the child med or caregiver med,
      // don't also display the combined result.
      if (computedValuesByScale['ptpbMed'] || computedValuesByScale['ptpbMed_C']) {
        ptpbValues = [computedValuesByScale['ptpbMed'], computedValuesByScale['ptpbMed_C']];
      } else {
        ptpbValues = [computedValuesByScale['ptpbMedCombined']];
      }

      return ptpbValues.addObjects([computedValuesByScale['medicationThoughts'], computedValuesByScale['medConcerns']]).compact().flatten().filterBy('domain', 'raw');
    }),

    primaryRiskValue: Ember.computed("allRiskValues", function () {
      var values = this.getWithDefault("allRiskValues", []);
      return _lodash.default.maxBy(values, function (cv) {
        return cv.get('value');
      });
    }),

    isMinimalRisk: Ember.computed("allRiskValues.[]", function () {
      var values = this.getWithDefault("allRiskValues", []);
      return _lodash.default.max(values.mapBy('value')) !== 1;
    }),

    isRisk: Ember.computed("allRiskValues", function () {
      if (this.get('isMinimalRisk')) {
        return 'minimal-risk';
      } else {
        return 'possible-risk';
      }
    })
  });
});