define('frontend/pods/components/common/tour-tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: null,
    help: Ember.inject.service(),

    show: Ember.computed('help.stateUpdated', 'tourName', function () {
      var help = this.get("help");

      return help.showTourTooltip(this.get('tourName'));
    }),

    tour: Ember.computed('tourName', function () {
      return this.get('help.tours').findBy('key', this.get('tourName'));
    }),

    actionText: Ember.computed('tour', function () {
      var override = this.get("tour.tooltipCta");

      if (override) {
        return override;
      }

      return "View";
    }),

    actions: {
      showTour: function showTour() {
        this.get("help").showTour(this.get('tour'));
      },
      dismissTour: function dismissTour() {
        this.get("help").dismissTour(this.get('tour'));
        this.set('show', false);
      }
    }
  });
});