define('frontend/pods/components/provider/reports/outcomes-explorer-mock/appt-history/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    statusMap: {
      P: { iconName: "fa-adjust", className: "moderate" },
      Y: { iconName: "fa-check-circle", className: "good" },
      C: { iconName: "fa-close", className: 'bad' },
      I: { iconName: "fa-circle-o", className: 'bad' }
    }
  });
});