define('frontend/models/reporting/report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    shortcode: null, // natural key for this report
    breakdowns: [], //any breakdowns
    role: 'provider', // what roles have access to this report
    icon: 'betterHealth.svg', //icon for the report in lists
    dateRange: true,
    internalOnly: false,
    graph: null, // object with compute() and map() functions to specify a graph
    mockGraph: null, // object with compute() and map() functions to specify a graph
    mockSummaryData: null, // mock data used for rendering the graph in the demo list

    // hash of column propertyNames to properties.
    // currently recognized properties are:
    // featured:  whether or not this appears in the table by default
    // icon: icon classes to display next to the column value
    columnProperties: {},
    // specifies what to render in the dial graph at the top of the report
    dial: null,
    controls: [], // list of control/filter components the graph supports
    defaultPreset: 'lastWeek',

    hasBreakdowns: Ember.computed.notEmpty('breakdowns'),
    hasDateRange: Ember.computed.bool('dateRange'),
    hasParams: Ember.computed.or('hasBreakdowns', 'hasDateRange'),

    showDateRangeControl: false,
    showBreakdownControl: false,
    showProviderFilter: false,
    showOrganizationFilter: false,
    showFlagFilter: false,
    showScaleSelector: false,
    showScaleScorerSelector: false,

    defaultSort: null,
    defaultSortDirection: 'desc'
  });
});