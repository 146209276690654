define('frontend/pods/components/common/controls/play-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      play: function play() {
        this.set("playing", true);
        var onPlay = this.get('onPlay');

        if (typeof onPlay === 'function') {
          onPlay();
        } else {
          console.warn("Missing onPlay action for play button");
        }
      },
      pause: function pause() {
        this.set("playing", false);
        var onPause = this.get('onPause');

        if (typeof onPause === 'function') {
          onPause();
        } else {
          console.warn("Missing onPause action for play button");
        }
      }
    }
  });
});