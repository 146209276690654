define('frontend/pods/components/provider/assessment/common/therapy-pref-detail/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['response-row'],

    answerValenceString: Ember.computed('answer.value', function () {
      return parseInt(this.getWithDefault('answer.value', 0)) > 0 ? 'Positive' : 'Negative';
    })
  });
});