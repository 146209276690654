define('frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isCompressed: true,
    tagName: '',
    graphDomains: ['raw', 'interpreted', 'normed'],

    actions: {
      expand: function expand() {
        this.set('isCompressed', false);
      },
      compress: function compress() {
        this.set('isCompressed', true);
      },
      hideScaleUserPair: function hideScaleUserPair(cv) {
        this.get("hiddenItems").addObject(cv.get('scaleUserPair'));
      },
      showScaleUserPair: function showScaleUserPair(cv) {
        this.get("hiddenItems").removeObject(cv.get('scaleUserPair'));
      }
    },

    displayType: Ember.computed.alias('scale.displayData.type'),

    scale: Ember.computed('scales', function () {
      return this.get('scales').objectAt(0);
    }),

    scales: Ember.computed('scale', function () {
      return [this.get('scale')];
    }),

    scaleMnemonics: Ember.computed('scales', function () {
      return this.get('scales').mapBy('mnemonic');
    }),

    isMultiUser: Ember.computed('currentValues.[]', function () {
      var currentUsers = this.get('currentValues').mapBy('user.id');
      var previousUsers = this.get('mostRecentValues').mapBy('user.id');
      return currentUsers.addObjects(previousUsers).uniq().length > 1;
    }),

    activeDomain: Ember.computed('availableDomains.[]', function () {
      var domains = this.get('availableDomains');

      // Always default to raw if available
      return domains.find(function (d) {
        return d.domain === 'raw';
      }) || domains[0];
    }),

    activeGraphDomain: Ember.computed('activeDomain', function () {
      // Has to be wrapped in an array;
      return [this.get("activeDomain.domain")];
    }),

    interviewInvitations: Ember.computed('activeReport', 'scale.id', function () {
      var reportScales = this.getWithDefault("activeReport.reportScales", []).filterBy("scale.id", this.get("scale.id")).filterBy('format', 'interview');

      var invitationIdsForScale = reportScales.flatMap(function (rs) {
        return rs.get("invitations").mapBy('id');
      });
      return this.getWithDefault("activeReport.incompleteInterviewInvitations", []).filter(function (inv) {
        return invitationIdsForScale.includes(inv.get('id'));
      });
    }),

    isMultiDomain: Ember.computed.gt('availableDomains.length', 1),

    enableFilter: Ember.computed.alias('isMultiUser'),

    hiddenItems: [],

    currentValuesAllDomains: Ember.computed('activeReport', function () {
      var _this = this;

      var graphDomains = this.get('graphDomains');
      var cvs = this.getWithDefault('activeReport.computedValues', []).filter(function (cv) {
        var mnemonic = cv.get('scale.mnemonic');
        var mnemonicIndex = _this.get('scaleMnemonics').indexOf(mnemonic);
        //include it if both the scale and the domain for the scale are a match
        return mnemonicIndex >= 0 && graphDomains.includes(cv.get('domain'));
      }).sort(function (cv) {
        return !cv.get('isPatientReported');
      });
      return cvs;
    }),

    availableDomains: Ember.computed('currentValuesAllDomains.[]', 'mostRecentValuesAllDomains.[]', function () {
      var _this2 = this;

      var objects = {};

      this.get("currentValuesAllDomains").forEach(function (value) {
        _this2._createDomainObject(value, objects);
      });

      var mostRecent = this.get('mostRecentValuesAllDomains');

      if (mostRecent) {
        mostRecent.forEach(function (value) {
          _this2._createDomainObject(value, objects);
        });
      }

      return Object.values(objects);
    }),

    _createDomainObject: function _createDomainObject(value, map) {
      var domain = value.get("domain");
      var population = value.get("population");
      var populationId = value.get("population.id");
      var key = domain + '-' + populationId;

      if (!map[key]) {
        var name = populationId ? domain + ' - ' + population.get("name") : domain;
        map[key] = Ember.Object.create({
          id: key,
          population: population,
          populationId: populationId,
          domain: domain,
          name: name
        });
      }
    },


    currentPopulation: Ember.computed.alias("activeDomain.population"),

    currentValues: Ember.computed('activeDomain', 'currentValuesAllDomains.[]', function () {
      var activeDomain = this.get('activeDomain');

      return this.get("currentValuesAllDomains").filter(function (v) {
        return activeDomain.domain === v.get("domain") && v.get("population.id") === activeDomain.populationId;
      });
    }),

    mostRecentValues: Ember.computed('activeDomain', 'mostRecentValuesAllDomains.[]', function () {
      var activeDomain = this.get('activeDomain');

      return this.get("mostRecentValuesAllDomains").filter(function (v) {
        return v.get("domain") === activeDomain.domain && v.get("population.id") === activeDomain.populationId;
      });
    }),

    mostRecentValuesAllDomains: Ember.computed('reports', function () {
      var _this3 = this;

      var reports = this.getWithDefault('reports', []);
      var graphDomains = this.get('graphDomains');

      //use old school for loop for easier short-circuiting
      for (var i = reports.get('length') - 1; i >= 0; i--) {
        var cvs = reports.objectAt(i).get('computedValues').filter(function (cv) {
          return _this3.get('scaleMnemonics').includes(cv.get('scale.mnemonic')) && graphDomains.includes(cv.get('domain'));
        }).sort(function (cv) {
          return !cv.get('isPatientReported');
        });

        if (cvs.length >= 1) {
          for (var j = 0; j < cvs.length; j++) {
            cvs[j].set('measuredAt', reports.objectAt(i).get('patientSession.targetDate'));
          }
          return cvs;
        }
      }
      return [];
    }),

    etrCurve: Ember.computed('reportSettings.otherSettings.showEtrCurves', 'reportSettings.etrCurves', function () {
      if (!this.get('reportSettings.otherSettings.showEtrCurves')) {
        return null;
      }
      var curves = this.get('reportSettings.etrCurves');

      if (curves) {
        return curves[this.get('scale.mnemonic')];
      }
    })
  });
});