define('frontend/models/assessment/report-scale-participant', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    reportScale: _emberData.default.belongsTo("assessment/reportScale"),
    participant: _emberData.default.belongsTo("participant"),
    user: _emberData.default.belongsTo("user"),
    scale: _emberData.default.belongsTo("assessment/scale"),
    invitation: _emberData.default.belongsTo("assessment/invitation"),

    blinded: Ember.computed.alias('reportScale.blinded')
  });
});