define('frontend/initializers/inject-store-int-components', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('component', 'store', 'service:store');
    application.inject('service:help', 'store', 'service:store');
    application.inject('component', 'metrics', 'service:metrics');
  }

  exports.default = {
    name: 'inject-store-into-components',
    initialize: initialize
  };
});