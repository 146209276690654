define('frontend/models/user-subscription', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    active: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    subject: _emberData.default.belongsTo('subscriptionSubject', { inverse: null })
  });
});