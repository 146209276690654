define('frontend/pods/components/provider/assessment/report-v2/sidebar-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleNoteShown: function toggleNoteShown() {
        var _this = this;

        this.get("metrics").trackEvent({ event: this.get('reportSettings.note.isShown') ? 'provider.assessment.noteHidden' : 'provider.assessment.noteShown' });

        this.set('reportSettings.note.isShown', !this.get('reportSettings.note.isShown'));
        Ember.run.later(function () {
          if (_this.get('reportSettings.note.isShown')) {
            $('html').animate({
              scrollTop: $('#synopsisScrollTarget').offset().top - 120
            }, 500);
          }
        });
      },
      setReportMetricContext: function setReportMetricContext(value) {
        if (value) {
          this.set("metrics.context.patientSessionId", value.get("patientSession.id"));
          this.set("metrics.context.reportId", value.get("id"));
        } else {
          var context = this.get("metrics.context");
          delete context.patientSessionId;
          delete context.reportId;
        }
      }
    }
  });
});