define('frontend/serializers/metric-datum', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      results: { serialize: false },
      summation: { translateUnderscore: true },
      denominatorSummation: { translateUnderscore: true },
      atLeastOnceBy: { translateUnderscore: true },
      group: { translateUnderscore: true }
    }
  });
});