define('frontend/models/configuration-value', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationOptions = {
    entityTreeNode: (0, _emberCpValidations.validator)('presence', true),
    value: (0, _emberCpValidations.validator)('presence', true),
    configurationProperty: (0, _emberCpValidations.validator)('presence', true)
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    value: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    configurationProperty: _emberData.default.belongsTo('configurationProperty'),
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode'),
    displayValue: Ember.computed('value', 'detailsOnExpand', function () {
      if (this.get('detailsOnExpand')) {
        return 'Expand to view';
      }

      return this.get('value');
    }),
    detailsOnExpand: Ember.computed('configurationProperty.valueType', function () {
      var valueType = this.get('configurationProperty.valueType');

      return valueType === 'translated_text' || valueType === 'json';
    }),

    // Allow us to directly access translated properties when necessary as a quick
    // fix
    enText: Ember.computed('value.en', {
      get: function get() {
        return this.get('value.en');
      },
      set: function set(key, value) {
        var text = this.get("value");

        if (text) {
          Ember.set(text, 'en', value);
        } else {
          this.set('value', { en: value });
        }

        return value;
      }
    }),

    esText: Ember.computed('value.es', {
      get: function get() {
        return this.get('value.es');
      },
      set: function set(key, value) {
        var text = this.get("value");

        if (text) {
          Ember.set(text, 'es', value);
        } else {
          this.set('value', { es: value });
        }

        return value;
      }
    })
  });
});