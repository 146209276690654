define('frontend/pods/components/provider/assessment/dashboard/session-list-item/component', ['exports', 'frontend/mixins/provider-assessment-redirect'], function (exports, _providerAssessmentRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_providerAssessmentRedirect.default, {
    tagName: '',
    showStatsDetails: false
  });
});