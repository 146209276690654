define("frontend/pods/components/common/navigation-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    screen: Ember.inject.service(),
    help: Ember.inject.service(),

    showExecutive: Ember.computed("sessionUser.currentUser.isInternal", "session.data.authenticated.roles", function () {
      return !this.get("features.useProviderLoginAsHome") && this.get("sessionUser.currentUser.isInternal") || this.getWithDefault("session.data.authenticated.roles", []).indexOf("executive") >= 0;
    }),

    enableExecutiveEventLog: Ember.computed("sessionUser.currentUser.isInternal", "sessionUser.currentUser", function () {
      return this.get("sessionUser.currentUser.isInternal") || this.features && this.features.get("enableExecutiveEventLog");
    }),

    showInstituteOptions: Ember.computed("sessionUser.currentUser", "features.enableCollaborativeCare", function () {
      return this.get("sessionUser").hasPermissions(["bulkUpload", "editProviders"]) || this.get('features.enableCollaborativeCare');
    }),
    homeLink: Ember.computed(function () {
      return this.get("features.useProviderLoginAsHome") ? "provider.assessment.dashboard" : "landing";
    }),

    navLogoImage: Ember.computed("institute", function () {
      return "/images/mirahLogoShort.png";
    }),

    showMeasurementPlan: Ember.computed.alias("features.enableMeasurementPlans"),

    showPayors: Ember.computed("sessionUser.currentUser", "features.enablePayors", function () {
      return this.get("features.enablePayors") && this.get("sessionUser").hasPermissions(["editInstitutePayors"]);
    }),

    showBilling: Ember.computed("sessionUser.currentUser", "features.enablePayors", function () {
      return this.get("features.enableBilling") && this.get("sessionUser").hasPermissions(["viewPatientBilling"]);
    }),

    showExperiments: Ember.computed("sessionUser.currentUser", "features.enableExperiments", function () {
      return this.get("features.enableExperiments") && this.get("sessionUser").hasPermissions(["editExperiments"]);
    }),

    showBulkExport: Ember.computed("sessionUser.currentUser", "institute.configuration.allowBulkExport", function () {
      return this.get("institute.configuration.allowBulkExport") && this.get("sessionUser").hasPermissions(["bulkExport"]);
    }),

    showQualityIssues: Ember.computed("sessionUser.currentUser", "features.enableQualityIssues", function () {
      return this.get("features.enableQualityIssues") && this.get("sessionUser").hasPermissions(["viewQualityIssues"]);
    }),

    showTreatmentServices: Ember.computed("sessionUser.currentUser", "features.enableTreatmentServices", function () {
      return this.get("features.enableTreatmentServices") && this.get("sessionUser").hasPermissions(["editTreatmentServices"]);
    }),

    showOrganizations: Ember.computed("sessionUser.currentUser", function () {
      return this.get("sessionUser").hasPermissions(["viewOrganizations"]);
    }),

    showPanels: Ember.computed("features.enableTeamsAndPanels", function () {
      return this.get("features.enableTeamsAndPanels");
    }),

    // TODO: once roll out of the complete is complete
    // REMOVE this feature gate
    enableDashboard: Ember.computed("features.enableDashboardSchedule", function () {
      return this.get("features.enableDashboardSchedule");
    }),

    providerNotificationsTotalCount: Ember.computed("sessionUser.currentUser.provider.providerNotificationsTotalCount", function () {
      return this.get("sessionUser.currentUser.provider.providerNotificationsTotalCount");
    }),

    enableDecisionSupport: Ember.computed("features.enableDecisionSupport", function () {
      return this.get("features.enableDecisionSupport");
    }),

    enableCollaborativeCare: Ember.computed('features.enableCollaborativeCare', function () {
      return this.get('features.enableCollaborativeCare');
    }),

    lastSignInDate: Ember.computed("session.data.authenticated.last_sign_in_at", function () {
      var signInDate = this.get("session.data.authenticated.last_sign_in_at");

      if (signInDate) {
        return moment(signInDate).format("MM/DD hh:mma");
      }
    }),

    actions: {
      invalidateSession: function invalidateSession() {
        this.set("session.logoutReason", "userLogout");
        this.get("sessionUser").logout();
      },
      showTour: function showTour(tour) {
        this.get("help").showTour(tour);
      },
      endTour: function endTour() {
        this.get("help").endTour();
      },
      tourChange: function tourChange(prevStep, nextStep) {
        this.get("help").tourStepChange(prevStep, nextStep);
      },
      showContactSupport: function showContactSupport() {
        this.set("showContactSupport", true);
      },
      hideContactSupport: function hideContactSupport() {
        this.set("showContactSupport", false);
      },
      togglePrivacyMode: function togglePrivacyMode() {
        this.get("sessionUser").togglePrivacyMode();
        if (this.get("sessionUser.privacyMode")) {
          this.get("router").transitionTo("provider.assessment.dashboard");
        }
      }
    }
  });
});