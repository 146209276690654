define('frontend/mixins/reports/controller-mixin', ['exports', 'frontend/models/reporting/report-params'], function (exports, _reportParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),
    queryParams: {
      reportTimePreset: 'preset',
      reportTimeStart: 'start',
      reportTimeEnd: 'end',
      reportTimeComparisonPreset: 'cpreset',
      reportTimeComparisonStart: 'cstart',
      reportTimeComparisonEnd: 'cend',
      reportBreakdown: 'breakdown',
      reportProviderId: 'providerId',
      reportOrganizationId: 'organizationId',
      reportInstituteConditionIds: 'instituteConditionIds',
      reportFlagIds: 'flagIds',
      reportScale: 'scale',
      reportScaleScorerId: 'scaleScorerId',
      reportSort: 'sort',
      reportSortDirection: 'sortDirection',
      reportLimit: 'limit',
      reportPath: 'path'
    },

    reportTimePresetWithDefault: Ember.computed('reportTimeStart', 'reportTimeEnd', 'reportTimePreset', {
      get: function get() {
        var _getProperties = this.getProperties('reportTimeStart', 'reportTimeEnd', 'reportTimePreset'),
            reportTimeStart = _getProperties.reportTimeStart,
            reportTimeEnd = _getProperties.reportTimeEnd,
            reportTimePreset = _getProperties.reportTimePreset;

        if (reportTimePreset) {
          return reportTimePreset;
        } else if (reportTimeStart && reportTimeEnd) {
          return null;
        } else {
          return this.get('model.report.defaultPreset');
        }
      },
      set: function set(key, value) {
        return this.set("reportTimePreset", value);
      }
    }),

    reportTimeComparisonPresetWithDefault: Ember.computed('reportTimeComparisonStart', 'reportTimeComparisonEnd', 'reportTimeComparisonPreset', {
      get: function get() {
        var _getProperties2 = this.getProperties('reportTimeComparisonStart', 'reportTimeComparisonEnd', 'reportTimeComparisonPreset'),
            reportTimeComparisonStart = _getProperties2.reportTimeComparisonStart,
            reportTimeComparisonEnd = _getProperties2.reportTimeComparisonEnd,
            reportTimeComparisonPreset = _getProperties2.reportTimeComparisonPreset;

        if (reportTimeComparisonPreset) {
          return reportTimeComparisonPreset;
        } else if (reportTimeComparisonStart && reportTimeComparisonEnd) {
          return null;
        } else {
          return this.get('model.report.defaultComparisonPreset');
        }
      },
      set: function set(key, value) {
        return this.set("reportTimeComparisonPreset", value);
      }
    }),

    breakdownWithDefault: Ember.computed('reportBreakdown', {
      get: function get() {
        if (this.get("reportBreakdown")) {
          return this.get("reportBreakdown");
        } else {
          return this.get("model.report.defaultBreakdown");
        }
      },
      set: function set(key, value) {
        return this.set("reportBreakdown", value);
      }
    }),

    scaleWithDefault: Ember.computed('reportScale', {
      get: function get() {
        if (this.get("reportScale")) {
          return this.get("reportScale");
        } else {
          return this.get("model.report.defaultScale");
        }
      },
      set: function set(key, value) {
        return this.set("reportScale", value);
      }
    }),

    scaleScorer: Ember.computed('reportScaleScorerId', {
      get: function get() {
        return this.get("reportScaleScorerId") ? this.get('store').peekRecord('assessment/scaleScorer', this.get("reportScaleScorerId")) : undefined;
      },
      set: function set(key, value) {
        this.set("reportScaleScorerId", value ? value.get('id') : undefined);
        return value;
      }
    }),

    reportParams: Ember.computed('scaleScorer', function () {
      var report = this.get("model.report");
      var defaultProvider = report.get("autoFilterCurrentProvider") ? this.get('sessionUser.activeProvider') : undefined;
      var provider = this.get("reportProviderId") ? this.get('preload.clinicians').findBy('id', this.get("reportProviderId")) : defaultProvider;
      var organization = this.get("reportOrganizationId") ? this.get('preload.organizations').findBy('id', this.get("reportOrganizationId")) : undefined;
      var flagIds = this._parseArray('reportFlagIds');
      var instituteConditionIds = this._parseArray("reportInstituteConditionIds");

      var sort = this.get("reportSort") || report.get("defaultSort");
      var sortDirection = this.get("reportSortDirection") || report.get("defaultSortDirection");
      var limit = this.get("reportLimit") || report.get("defaultLimit");

      // We support 3 ways of setting path:
      //  - if supplied by url, use it
      //  - if there is a function that calculates it on the report, use that
      //  - else use the institutional base
      var path = this.get("reportPath");

      if (!path && report.get('drilldown')) {
        if (report.get('defaultPathFn')) {
          path = report.get('defaultPathFn').call(this);
        } else {
          path = this.get('institute.current.rootPath');
        }
      }

      return _reportParams.default.create({
        preset: this.get('reportTimePresetWithDefault'),
        startTime: this.get('reportTimeStart'),
        endTime: this.get('reportTimeEnd'),
        comparisonPreset: this.get('reportTimeComparisonPresetWithDefault'),
        comparisonStartTime: this.get('reportTimeComparisonStart'),
        comparisonEndTime: this.get('reportTimeComparisonEnd'),
        breakdown: this.get('breakdownWithDefault'),
        scale: this.get('scaleWithDefault'),
        scaleScorer: this.get('scaleScorer'),
        provider: provider,
        organization: organization,
        instituteConditionIds: instituteConditionIds,
        flagIds: flagIds,
        sort: sort,
        sortDirection: sortDirection,
        limit: limit,
        path: path
      });
    }),

    resetQueryParams: function resetQueryParams() {
      this.setProperties({
        noRefresh: true,
        reportTimePreset: null,
        reportTimeStart: null,
        reportTimeEnd: null,
        reportTimeComparisonPreset: null,
        reportTimeComparisonStart: null,
        reportTimeComparisonEnd: null,
        reportBreakdown: null,
        reportScale: null,
        reportScaleScorerId: null,
        reportProviderId: null,
        reportOrganizationId: null,
        reportInstituteConditionIds: null,
        reportFlagIds: null,
        reportSort: null,
        reportSortDirection: null,
        reportLimit: null,
        reportPath: null
      });
      this.notifyPropertyChange('reportParams');
    },


    // If you change multiple query params at once, this gets fired once for each, so debounce.
    queryParamsChanged: Ember.observer('reportTimePreset', 'reportTimeStart', 'reportTimeEnd', 'reportTimeComparisonPreset', 'reportTimeComparisonStart', 'reportTimeComparisonEnd', 'reportBreakdown', 'reportScale', 'reportScaleScorerId', 'reportProviderId', 'reportOrganizationId', 'reportInstituteConditionIds', 'reportFlagIds', 'reportSort', 'reportSortDirection', 'reportLimit', 'reportPath', function () {
      Ember.run.debounce(this, this._queryParamsChangedDebounce, 100);
    }),

    _queryParamsChangedDebounce: function _queryParamsChangedDebounce() {
      if (!this.get('noRefresh')) {
        this.notifyPropertyChange('reportParams');
      }
      this.set('noRefresh', null);
    },
    _parseArray: function _parseArray(key) {
      var value = this.get(key);

      if (!value) {
        return [];
      }

      if (value.split) {
        return value.split(',');
      }

      return value;
    },


    actions: {
      reportParamsRefresh: function reportParamsRefresh() {
        var params = this.get('reportParams');

        var instituteConditionIds = params.get("instituteConditionIds.length") > 0 ? params.get("instituteConditionIds") : undefined;
        var flagIds = params.get("flagIds.length") > 0 ? params.get("flagIds") : undefined;
        this.setProperties({
          noRefresh: true,
          reportTimePreset: params.get('preset'),
          reportTimeStart: params.get('startTime'),
          reportTimeEnd: params.get('endTime'),
          reportTimeComparisonPreset: params.get('comparisonPreset'),
          reportTimeComparisonStart: params.get('comparisonStartTime'),
          reportTimeComparisonEnd: params.get('comparisonEndTime'),
          reportBreakdown: params.get('breakdown'),
          reportScale: params.get('scale'),
          scaleScorer: params.get('scaleScorer'),
          reportScaleScorerId: params.get('scaleScorer.id'),
          reportProviderId: params.get('provider.id'),
          reportOrganizationId: params.get('organization.id'),
          reportInstituteConditionIds: instituteConditionIds,
          reportFlagIds: flagIds,
          reportSort: params.get('sort'),
          reportSortDirection: params.get('sortDirection'),
          reportLimit: params.get('limit'),
          reportPath: params.get('path')
        });
      },

      // Used to reset report specific settings so as not to break other reports.
      reportParamsChangeReport: function reportParamsChangeReport() {
        this.setProperties({
          reportBreakdown: null,
          reportSort: null,
          reportSortDirection: null
        });
        this.notifyPropertyChange('reportParams');
      }
    }
  });
});