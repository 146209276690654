define('frontend/pods/components/reporting/comparison-selector/component', ['exports', 'frontend/mixins/fixed-modal-mixin'], function (exports, _fixedModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fixedModalMixin.default, {
    classNames: ['date-selector'],

    presets: ['priorPeriod', 'priorYear', 'none'],

    comparisonNotApplicable: Ember.computed.equal('mainPreset', 'allTime'),

    values: Ember.computed('startTime', 'endTime', function () {
      return [this.get('startTime'), this.get('endTime')].filter(function (date) {
        return !!date;
      }).map(function (date) {
        return moment(date);
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },
    _applyPreset: function _applyPreset(preset) {
      this.setProperties({
        preset: preset,
        startTime: null,
        endTime: null
      });
    },
    _sendDateRange: function _sendDateRange() {
      var props = this.getProperties('startTime', 'endTime', 'preset');

      this.sendAction('setDates', props);
    },


    actions: {
      sendDateRange: function sendDateRange() {
        this.send('toggleModal');
        this._sendDateRange();
      },
      selectDate: function selectDate(dates) {
        this.setProperties({
          preset: null,
          startTime: dates[0],
          endTime: dates[1]
        });
      },
      setPreset: function setPreset(preset) {
        var _this = this;

        this._applyPreset(preset);

        Ember.run.once(function () {
          _this.send('toggleModal');
          _this._sendDateRange();
        });
      }
    }
  });
});