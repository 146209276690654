define('frontend/services/help', ['exports', 'frontend/config/environment', 'ember-data'], function (exports, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sessionUser: Ember.inject.service(),
    session: Ember.inject.service(),
    screen: Ember.inject.service(),
    features: Ember.inject.service(),

    userHelpState: Ember.computed("sessionUser.currentUser.id", "sessionUser.currentUser.userHelpState", function () {
      var currentUser = this.get("sessionUser.currentUser");

      if (!currentUser) {
        return null;
      }

      if (!this.get("sessionUser.currentUser.userHelpState.id")) {
        var uhs = this.get("store").createRecord("userHelpState", { user: currentUser, adviceLevel: "announcements" });
        // If we don't wrap this, we have errors handling the difference between it being a promise and a class later on.
        return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function (resolve) {
            resolve(uhs);
          }) });
      }

      return this.get("sessionUser.currentUser.userHelpState");
    }),

    availableTours: Ember.computed("router.currentRouteName", "tours", function () {
      var _this = this;

      if (_environment.default.disableAllWalkthroughs) {
        return [];
      }

      var currentRouteName = this.get("router.currentRouteName");

      return this.get("tours").filter(function (tour) {
        return tour.route === currentRouteName && (!tour.demoOnly || _environment.default.enableDemoWalkthroughs) && (!tour.showIf || tour.showIf.call(_this));
      });
    }),

    autostartTours: Ember.computed.filterBy('availableTours', 'autostart', true),

    announcements: Ember.computed.filterBy('autostartTours', 'announcement', true),

    shouldShowTour: Ember.computed.notEmpty("activeTour"),

    autostartTour: Ember.computed("availableTours", "userHelpState.state", "userHelpState.adviceLevel", function () {
      var uhs = this.get("userHelpState");

      if (!uhs || !uhs.get("id")) {
        return null;
      }

      var adviceLevel = uhs.get("adviceLevel");
      var seenTours = uhs.getWithDefault("state", {});

      if (adviceLevel === 'none') {
        return null;
      } else if (adviceLevel === 'full') {
        return this.get("availableTours.firstObject");
      } else if (adviceLevel === 'once_per_session') {
        var seenToursThisSession = uhs.getWithDefault("sessionState", {});
        return this.get("availableTours").find(function (tour) {
          return !seenToursThisSession || seenToursThisSession[tour.key] !== 'viewed';
        });
      } else if (adviceLevel === 'announcements') {
        return this._findUnviewedTour(this.get('announcements'), seenTours);
      } else {
        return this._findUnviewedTour(this.get('autostartTours'), seenTours);
      }
    }),

    _findUnviewedTour: function _findUnviewedTour(tours, seenTours) {
      return tours.find(function (tour) {
        return !seenTours || seenTours[tour.key] !== 'viewed';
      });
    },


    resetDismissed: Ember.observer("router.currentRouteName", function () {
      this.set("tourViewed", false);
      this.set("requestedTour", null);
    }),

    tourStepChange: function tourStepChange(prevStep, nextStep) {
      if (nextStep && nextStep.onEnter) {
        nextStep.onEnter();
      }
    },
    showTourTooltip: function showTourTooltip(tourName) {
      // Yes if it has a tooltip, advice is set to at least announcement, and it's not been seen.
      var tour = this.get('tours').findBy('key', tourName);

      if (!tour || !tour.tooltip && !tour.tooltipHtml) {
        return false;
      }

      var uhs = this.get("userHelpState");

      if (!uhs || !uhs.get("id")) {
        return false;
      }

      var adviceLevel = uhs.get("adviceLevel");

      var seenTours = uhs.getWithDefault("state", {});

      if (adviceLevel === 'none') {
        return false;
      } else if (adviceLevel === 'full') {
        return true;
      } else if (adviceLevel === 'once_per_session') {
        var seenToursThisSession = uhs.getWithDefault("sessionState", {});
        return !seenToursThisSession[tourName];
      } else if (adviceLevel === 'new') {
        return !seenTours[tourName];
      } else if (adviceLevel === 'announcements') {
        return !seenTours[tourName] && tour.announcement;
      } else {
        return true;
      }
    },


    activeTour: Ember.computed("autostartTour", "requestedTour", "tourViewed", function () {
      var _getProperties = this.getProperties("requestedTour", "tourViewed"),
          requestedTour = _getProperties.requestedTour,
          tourViewed = _getProperties.tourViewed;

      if (tourViewed) {
        return null;
      }

      if (requestedTour) {
        return requestedTour;
      }

      return this.get("autostartTour");
    }),

    stateUpdated: false, // Triggered when tours end

    showTour: function showTour(tour) {
      this.set("requestedTour", tour);
      this.set("tourViewed", false);
      this.propertyDidChange("shouldShowTour");
    },
    dismissTour: function dismissTour(tour) {
      this.set("requestedTour", null);
      this._markTourAsViewed(tour);
    },
    endTour: function endTour() {
      this._markTourAsViewed(this.get("activeTour"));
      this.set("requestedTour", null);
      this.set("tourViewed", true);
      this.notifyPropertyChange('stateUpdated');
    },
    _markTourAsViewed: function _markTourAsViewed(tour) {
      if (!tour) {
        return;
      }

      this.get("userHelpState").then(function (uhs) {
        var state = uhs.getWithDefault("state", {});
        var sessionState = uhs.getWithDefault("sessionState", {});
        state[tour.key] = "viewed";
        sessionState[tour.key] = "viewed";

        uhs.set("state", state);
        uhs.set("sessionState", sessionState);
        uhs.save();
      });
    },
    showHelpTooltip: function showHelpTooltip(name) {
      var state = this.get("userHelpState.state");
      return !state || state[name] !== 'viewed';
    },
    dismissTip: function dismissTip(name) {
      if (!name) {
        return;
      }

      this.get("userHelpState").then(function (uhs) {
        var state = uhs.getWithDefault("state", {});
        state[name] = "viewed";
        uhs.set("state", state);
        uhs.save();
      });
    },


    // Warning, you need to make the keys be underscore cased for now, as otherwise they get translated on the server side anyway and
    // you'll have a mismatch.
    tours: Ember.computed(function () {
      return [Ember.Object.create({
        key: "assessment_walkthrough",
        route: "provider.assessment.dashboard",
        name: "Measurement Dashboard Walkthrough",
        demoOnly: false,
        autostart: true,
        steps: [{
          intro: '\n                <div class="onboarding__banner">\n                  <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n                  <h3>Measurement Dashboard</h3>\n                  <p>This is the measurement dashboard. It highlights the most recent measurements for clients.</p>            \n                </div>'
        }, {
          element: 'li.dashboard-completed',
          intro: '\n              <div class="onboarding__banner">\n                The recently completed tab links to the most recent completed assessment for a client.  You can see the severity and trend for each patient in the colored icons\n              </div>'
        }, {
          element: 'li.dashboard-waiting',
          intro: '\n              <div class="onboarding__banner">\n                The awaiting response tab shows measurements that are currently waiting to be filled out by the client, either via email or in-office\n              </div>'
        }, {
          element: 'li.dashboard-myTasks',
          intro: '\n             <div class="onboarding__banner">\n                The tasks tab indicates measurements that need to be completed by you\n             </div>\n            '
        }, {
          element: 'div.patient-provider-filter',
          intro: '\n             <div class="onboarding__banner">\n                You can filter the clients displayed using this control\n             </div>\n            '
        }, {
          element: '.patients-nav',
          intro: '\n             <div class="onboarding__banner">\n               If you want to see a full list of clients, or create clients of your own to try out, click here to go to the clients tab.\n             </div>\n            '
        }, {
          element: '.menu-help',
          intro: '\n             <div class="onboarding__banner">\n               This tutorial, and others relevant to specific pages, are all available from the help menu here.\n             </div>\n            '
        }, {
          intro: '\n             <div class="onboarding__banner">\n               <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n               <p>That\'s it! If you have any questions, don\'t hestitate to email <a href="mailto:support@mirah.com">support@mirah.com</a> and we\'ll be in touch.</p>\n             </div>\n            '
        }]
      }), Ember.Object.create({
        key: "mobile_dashboard",
        route: "provider.assessment.dashboard",
        name: "Mobile Dashboard",
        demoOnly: false,
        announcement: true,
        autostart: true,
        showIf: function showIf() {
          return this.get("screen.isCompressed");
        },
        steps: [{
          intro: '\n                <div class="onboarding__banner">\n                  <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n                  <h3>New Mobile Dashboard</h3>\n                  <p>We have made a few changes to make our dashboard better on smaller screens.</p>\n                </div>'
        }, {
          element: 'li.dashboard-today',
          intro: '\n              <div class="onboarding__banner">\n                All clients with appointments today are now displayed on this tab, ordered by the appointment time.\n              </div>'
        }, {
          element: 'li.dashboard-all',
          intro: '\n              <div class="onboarding__banner">\n                You can always see all clients with recent appointments beyond today on the \'All\' tab.\n              </div>'
        }, {
          element: '.navbar-compressed-search',
          intro: '\n              <div class="onboarding__banner">\n                If you want to find a client quickly, just click the search button and enter their name.\n              </div>'
        }, {
          intro: '\n              <div class="onboarding__banner">\n                That\'s it! You can always see this tutorial again by clicking on \'Help\' in the menu.\n              </div>'
        }]
      }), Ember.Object.create({
        key: "report_walkthrough",
        route: "provider.patients.show.longitudinal",
        demoOnly: false,
        autostart: true,
        name: "Measurement Report Walkthrough",
        steps: [{
          intro: '\n          <div class="onboarding__banner">\n            <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n            <h3>Patient Report</h3>\n            <p>View risks, symptoms, trends, and item-level responses, as well as generate session notes.  We\'ll walk you through the major areas</p>\n              </div>'
        }, {
          element: '.risk-banner',
          intro: '\n              <div class="onboarding__banner">\n                 The top of the report highlights any suicide risk or risk to the therapy alliance.  Click on a section to view item-level detail behind the alert.\n              </div>'
        }, {
          element: '.recent-changes',
          intro: '\n             <div class="onboarding__banner">\n               This section highlights symptoms and skills that have changed in a meaningful way (more than 1 standard deviation).  Again, you can click to pull up detail. \n             </div>\n            '
        }, {
          element: '.symptoms-explorer .symptoms-overview .scoreGraph:not(.strengths)',
          intro: '\n             <div class="onboarding__banner">\n               The overivew graph gives you an at-a-glance look at the patient across all categories, relative to norms.  The bar shows where the patient is now, the dots show past measurements.  And as you may have guessed, clicking will bring up detail.  \n             </div>\n            '
        }, {
          element: '.symptoms-explorer #trends',
          intro: '\n             <div class="onboarding__banner">\n               In viewing trends over time, use the select box on the left to pick individual scales to show\n             </div>\n            '
        }, {
          element: '.panel.panel-primary.synopsis',
          intro: '\n             <div class="onboarding__banner">\n               You can generate either an html report summary, or export a text version of the report\n             </div>\n            '
        }]
      }), Ember.Object.create({
        key: "scale_chooser_walkthrough",
        route: "provider.patients.show.sessions.create",
        name: "Scale Chooser Walkthrough",
        demoOnly: false,
        autostart: false,
        announcement: true,
        tooltip: 'We have made some changes to our scale chooser to make it easier to view. Click here to find out more.',
        steps: [{
          intro: '\n                <div class="onboarding__banner">\n                  <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n                  <h3>Scale Chooser</h3>\n                  <p>As we\'ve been expanding our measure library, we\'ve had to come up with a new way of choosing scales.</p>            \n                  <p>Instead of seeing all available scales here on the page, now you can browse and search through our full library</p>            \n                </div>'
        }, {
          element: '.active-scales',
          intro: '\n              <div class="onboarding__banner">\n               Here you\'ll see the scales already selected for the assessment. You can remove them by pressing the cross.\n             </div>\n            '
        }, {
          element: '.open-scale-chooser',
          intro: '\n              <div class="onboarding__banner">\n               If you want to find new scales to add, click the \'Edit\' button.\n             </div>\n            ',
          onEnter: function onEnter() {
            $('.close-drawer a').click();
          }
        }, {
          element: '.scale-browser-select',
          intro: '\n              <div class="onboarding__banner">\n                You can add or remove scales by checking the box on the left hand side.\n             </div>\n            ',
          onEnter: function onEnter() {
            if ($('#scaleChooserSelectorDrawer .drawer-body').length === 0) {
              $('.open-scale-chooser').click();
            }
          }
        }, {
          intro: '\n              <div class="onboarding__banner">\n                <p>By default, it only shows scales matching the current patient\'s age, language and other characteristics. You can change this by clicking on \'filters\', or you can search by scale\'s name or category in the top left.</p>\n             </div>\n            '
        }, {
          intro: '\n              <div class="onboarding__banner">\n                That\'s it! You can always see this tutorial again by clicking on \'Help\' in the menu.\n              </div>\n            '
        }]
      }), Ember.Object.create({
        key: "plan_patient_measurement",
        route: "provider.patients.show.plan",
        name: "Measurement Planning",
        demoOnly: false,
        announcement: true,
        autostart: true,
        showIf: function showIf() {
          return this.get("features.enableMeasurementPlans");
        },
        steps: [{
          intro: '\n                <div class="onboarding__banner">\n                  <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n                  <h3>Measurement Planning</h3>\n                  <p>From this page you can control what measures the client will get throughout treatment. You can tailor assessments on the basis of which provider is seeing the client, which facility they are being treated in, how far through treatment they are, and other factors.</p>\n                </div>'
        }, {
          element: '.entity-active-plans',
          intro: '\n              <div class="onboarding__banner">\n                There are already plans set up for measuring this client. They have been set up by your leadership to represent a good default. You can modify these to better fit the situation. Plans come in three types:\n                <ul>\n                  <li>Each assessment has only one <b>core</b> plan.</li>\n                  <li><b>Supplemental</b> plans can be used to add individual scales to add more detail as you progress throughout treatment.</li>\n                  <li><b>Compliance</b> plans represent scales which need to be taken for compliance or regulatory reasons, and cannot be disabled.</li>\n                </ul>\n              </div>'
        }, {
          element: '.add-scale',
          intro: '\n              <div class="onboarding__banner">\n                You can add a scale, for one session, or repeated or intermittent measurement. It will automatically replan all upcoming sessions to use it.\n              </div>'
        }, {
          element: '.remove-scale',
          intro: '\n              <div class="onboarding__banner">\n                You can remove a scale from all active plans and upcoming sessions. Some scales, for example those required by a compliance plan, cannot be removed.\n              </div>'
        },
        // {
        //   element: '.change-measurement-plan',
        //   intro: `
        //     <div class="onboarding__banner">
        //       You can also change to an entirely different core protocol, or create a new one. For example, if you wanted an adolescent to get the adult protocol rather than the child protocol, you could do so here.
        //     </div>`
        // },
        {
          intro: '\n              <div class="onboarding__banner">\n                That\'s it! You can always see this tutorial again by clicking on \'Help\' in the menu.\n              </div>'
        }]
      })];
    })
  });
});