define('frontend/pods/components/scale-creation/templated-collection/component', ['exports', 'frontend/models/assessment/scale-templates'], function (exports, _scaleTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scaleTemplates: Ember.inject.service(),
    templatesByName: Ember.computed.alias('scaleTemplates.templatesByName'),
    availableTemplates: Ember.computed('scaleTemplates.templates', 'factory.name', function () {
      return this.get('scaleTemplates.templates')[this.get("factory")];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get("selectedTemplate")) {
        this.set("selectedTemplate", this.get('availableTemplates.firstObject'));
      }
    },


    actions: {
      templateSelected: function templateSelected(template) {
        this.set('selectedTemplate', template);
      },
      addObject: function addObject() {
        var template = this.get("selectedTemplate");
        var newObject = _scaleTemplates.FactoryMap[this.get('factory')].createNewFromTemplate(template, this.get('registry'), Ember.getOwner(this));
        this.set("lastCreatedObject", newObject);

        var collection = this.get("collection");
        if (!collection) {
          collection = this.set('collection', Ember.A());
          this.notifyPropertyChange('collection');
        }

        collection.addObject(newObject);
      },
      onDelete: function onDelete(object) {
        if (confirm("Confirm delete?")) {
          this.get('collection').removeObject(object);
        }
      },
      onDuplicate: function onDuplicate(object) {
        var placeToInsert = this.get("collection").indexOf(object);
        var duplicate = _scaleTemplates.FactoryMap[this.get("factory")].duplicate(object, this.get("registry"));
        this.get("collection").insertAt(placeToInsert + 1, duplicate);
      },
      onMoveUp: function onMoveUp(object) {
        var collection = this.get("collection");
        var idx = collection.indexOf(object);

        if (idx === 0) {
          return;
        }

        collection.removeObject(object);
        collection.insertAt(idx - 1, object);
      },
      onMoveDown: function onMoveDown(object) {
        var collection = this.get("collection");
        var idx = collection.indexOf(object);

        if (idx === collection.length - 1) {
          return;
        }

        collection.removeObject(object);
        collection.insertAt(idx + 1, object);
      },
      onChangeTemplate: function onChangeTemplate(object, template) {
        var collection = this.get("collection");
        var idx = collection.indexOf(object);

        var newObject = _scaleTemplates.FactoryMap[this.get('factory')].replaceTemplate(template, object, this.get('registry'), Ember.getOwner(this));

        collection[idx] = newObject;
        this.notifyPropertyChange('collection');
      }
    }
  });
});