define('frontend/pods/components/provider/care-episodes/edit-form/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/error-printer'], function (exports, _rollbackMixin, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, _errorPrinter.default, {
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    mode: "edit",
    rollbackProperties: ["careEpisode"],

    multiOrgMode: Ember.computed.gt("organizations.length", 1),

    conflictingCareEpisode: Ember.computed("careEpisode.organization", function () {
      var orgId = this.get("careEpisode.organization.id");

      return this.getWithDefault("activeCareEpisodes", []).findBy("organization.id", orgId);
    }),

    saveDisabled: Ember.computed("conflictingCareEpisode", "careEpisode.validations.isInvalid", function () {
      return this.get("conflictingCareEpisode") || this.get("careEpisode.validations.isInvalid");
    }),

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get("multiOrgMode") && !this.get("careEpisode.organization.id")) {
        this.set("careEpisode.organization", this.get("organizations.firstObject"));
      }
    },


    actions: {
      submit: function submit() {
        var _this = this;

        var careEpisode = this.get("careEpisode");

        var onSuccess = function onSuccess(saved) {
          _this.get('messages').success("Episode of care updated");
          _this.get("metrics").trackEvent({ event: "provider.careEpisode" + _this.get("mode") });

          if (_this.get("onSave")) {
            _this.get("onSave")();
          } else if (_this.get("onSaveRoute")) {
            _this.get("router").transitionTo(_this.get("onSaveRoute"), saved);
          } else {
            _this.get("router").transitionTo("provider.patients.show", saved.get("patient.id"));
          }
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason));
        };

        return careEpisode.save().then(onSuccess).catch(onFailure);
      }
    }
  });
});