define('frontend/pods/components/provider/assessment/report-v2/sdq-explorer/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/symptoms-explorer/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var scaleSections = {
    summary: {
      title: 'SDQ Summary',
      baseScales: ['sdqTotal']
    },
    symptoms: {
      titleKey: 'provider.scales.symptoms',
      baseScales: ['sdqConductProblems', 'sdqEmotionalProblems', 'sdqHyperactivity', 'sdqPeerProblems', 'sdqImpact']
    },
    strengths: {
      titleKey: 'provider.scales.strengths',
      baseScales: ['sdqProsocial']
    }
  };

  var allBaseMnemonics = ['sdqConductProblems', 'sdqEmotionalProblems', 'sdqHyperactivity', 'sdqPeerProblems', 'sdqImpact', 'sdqProsocial', 'sdqTotal'];

  exports.default = _component.default.extend({
    computedValueDomain: 'raw',
    graphDomains: ['raw'],
    scaleSections: scaleSections,
    trendScales: Ember.computed('scales', function () {
      return this.getWithDefault('scales', []).filter(function (s) {
        return allBaseMnemonics.indexOf(s.get("baseMnemonic")) >= 0;
      }).sortBy('mnemonic');
    }),
    actions: {
      // Has no populations, so don't match against it even when other population-basee scales are present.
      computedValueSelected: function computedValueSelected(value) {
        this.setProperties({
          selectedScale: value ? value.get("scale") : null,
          selectedReport: value ? value.get("report") : null,
          activeComputedValue: value
        });
      }
    }
  });
});