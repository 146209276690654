define('frontend/pods/components/provider/assessment/dashboard/user-tasks/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userTaskService: Ember.inject.service('provider/taskService'),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      // Note that the patient search term is passed straight through to the component.
      // We pass in the details for the request rather than the request itself to make sure
      // the filtering is done correctly first time round.
      if (!this.get("data")) {
        var data = this.get('userTaskService').pendingInvitations(true);
        if (data) {
          data.then(function (result) {
            if (_this.get('updateCount')) {
              _this.get('updateCount')(result.get('meta.record-count'));
            }
            if (!result.isFulfilled) {
              result.set('isFulfilled', true);
            }

            _this.set("data", result);
          });
        }
        this.set("data", data);
      }
    }
  });
});