define('frontend/pods/components/common/graphs/scale-overview-chart/component', ['exports', 'd3', 'lodash', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'frontend/utils/assessment/analytics', 'ember-cli-d3/utils/d3'], function (exports, _d2, _lodash, _d3Support, _marginConvention, _analytics, _d3) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    opts: {
      maxValue: 5,
      minValue: -4,
      textMargin: 20,
      labelFactor: 0.95
    },
    classNames: ['bar-chart'],
    defaultMargin: 5,
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    requiredProperties: ['model'],
    chartDots: null,
    chartLabels: null,
    chartBars: null,
    currentSymptom: null,
    showDetail: false,
    displayMode: 'norse',

    model: null, //array of data with score and scoreClass properties
    isSkills: false, //whether this is graphing skills or symptoms

    graphData: Ember.computed('model', function () {
      return this.transformData(this.get('model'));
    }),

    modeData: {
      norse: {
        // We use the existing z-score.
        valueTransform: function valueTransform(value) {
          return value;
        },
        zeroLine: 0,
        xScale: function xScale() {
          var width = this.get('contentWidth');
          var leftAxisMargin = this.get("currentModeData").leftAxisMargin(width);
          var x = _d2.default.scale.linear().range([leftAxisMargin, leftAxisMargin + (width - leftAxisMargin) / 3, width]).clamp(true);
          if (this.get("isSkills")) {
            x.domain([this.opts.maxValue, 0, this.opts.minValue]);
          } else {
            x.domain([this.opts.minValue, 0, this.opts.maxValue]);
          }

          return x;
        },
        leftAxisMargin: function leftAxisMargin(width) {
          // We'll use nano mode (i.e. shorter headings) if it's small, so we need less space.
          return width < 450 ? 130 : 145;
        },
        xAxis: function xAxis(xScale, selection) {
          var intl = this.get('intl');
          var opts = this.opts;
          var height = this.get('contentHeight');
          var width = this.get('contentWidth');
          var isSkills = this.get("isSkills");
          var xAxis = _d2.default.svg.axis().scale(xScale).tickValues(isSkills ? [-5, -2, 0] : [0, 2, 4]) //opts.maxValue - opts.minValue)
          .orient("bottom").innerTickSize(5);

          var labelBase = width < 400 ? 'score.shortPercentileLabel.' : 'score.percentileLabel.';

          var xLabelPrefix = this.get('isSkills') ? labelBase + 'skill.' : labelBase + 'symptom.';

          selection.selectAll('g.baraxis.x').attr("transform", "translate(0," + (height - opts.textMargin) + ")").call(xAxis).selectAll("text")
          // .style("text-anchor", "end")
          .attr("dx", "-10%")
          // .attr("dy", ".2em")
          // .attr("transform", "rotate(-25)")
          .attr("class", "barlabel").text(function (d) {
            return d !== opts.minValue && d !== opts.maxValue ? intl.t(xLabelPrefix + d) : '';
          });
        },
        gradients: [{ offset: "0%", color: "rgb(200,0,0)", opacity: 0.9 }, { offset: "35%", color: "rgb(235,0,0)", opacity: 0.7 }, { offset: "50%", color: "rgb(255,100,0)", opacity: 0.6 }, { offset: "70%", color: "rgb(205,205,0)", opacity: 0.5 }, { offset: "85%", color: "rgb(0,175,0)", opacity: 0.7 }],
        useNanoText: function useNanoText(width) {
          // Use nano text when things are narrow.
          return width < 450;
        }
      },
      ptpb: {
        // We use the existing z-score.
        valueTransform: function valueTransform(value) {
          if (value) {
            return _analytics.default.GetZPercent(value) * 100;
          } else {
            return null;
          }
        },
        zeroLine: 50,
        xScale: function xScale() {
          var width = this.get('contentWidth');
          var leftAxisMargin = this.get("currentModeData").leftAxisMargin(width);
          var x = _d2.default.scale.linear().range([leftAxisMargin, width]).clamp(true);

          if (this.get("isSkills")) {
            x.domain([100, 0]);
          } else {
            x.domain([0, 100]);
          }

          return x;
        },
        // We'll use nano mode (i.e. shorter headings) if it's small, so we need less space.
        leftAxisMargin: function leftAxisMargin(width) {
          return width < 400 ? 130 : 190;
        },
        xAxis: function xAxis(xScale, selection) {
          var intl = this.get('intl');
          var opts = this.opts;
          var height = this.get('contentHeight');
          var width = this.get('contentWidth');
          var xAxis = _d2.default.svg.axis().scale(xScale).tickValues([25, 50, 75]) //opts.maxValue - opts.minValue)
          .orient("bottom").innerTickSize(5);

          var xLabelPrefix = this.get("currentModeData").useNanoText(width) ? 'score.shortPercentileLabel.ptpb.' : 'score.percentileLabel.ptpb.';

          selection.selectAll('g.baraxis.x').attr("transform", "translate(0," + (height - opts.textMargin) + ")").call(xAxis).selectAll("text")
          //.attr("dx", "-10%")
          .attr("class", "barlabel").text(function (d) {
            return d !== opts.minValue && d !== opts.maxValue ? intl.t(xLabelPrefix + d) : '';
          });
        },
        gradients: [{ offset: "0%", color: "rgb(200,0,0)", opacity: 0.9 }, { offset: "20%", color: "rgb(235,0,0)", opacity: 0.7 }, { offset: "40%", color: "rgb(255,100,0)", opacity: 0.6 }, { offset: "60%", color: "rgb(205,205,0)", opacity: 0.5 }, { offset: "85%", color: "rgb(0,175,0)", opacity: 0.7 }],
        useNanoText: function useNanoText(width) {
          // Use nano text when things are narrow.
          return width < 400;
        }
      }
    },

    currentModeData: Ember.computed("modeData", "displayMode", function () {
      return this.get("modeData")[this.get('displayMode')];
    }),

    // gets a unique id for the gradient based on its parent container, which will have an ember
    // unique component id
    gradientId: Ember.computed(function () {
      return 'grad-' + $(this.svg.get('element')).parent()[0].id;
    }),

    actions: {
      setCurrentSymptom: function setCurrentSymptom(item, d3this, index) {
        // If the scale wasn't answered its possible for item to be undefined. Most likely a PTPB
        // specific issue if the RelatedPerson hasn't completed their assessment.
        if (!item) {
          return;
        }

        var bars = this.get('chartBars');
        var dots = this.get('chartDots');
        var labels = this.get('chartLabels');
        var datum = item.datum;

        this.set('currentSymptom', datum);

        this.get("metrics").trackEvent({ event: 'provider.assessment.reportScaleClicked', clickedReportId: datum.get("report.id"), value: datum.get("value"), scaleId: datum.get("scale.id") });

        this.sendAction('onValueSelect', item.datum, function () {
          bars.classed('selected', false);
          dots.classed('selected', false);
          labels.classed('selected', false);
        });

        bars.classed("selected", function (x) {
          return x === item;
        });
        dots.classed("selected", function (x) {
          return x === item;
        });
        labels.classed("selected", function (x, i) {
          return i === index;
        });
      }
    },

    xScale: Ember.computed('contentWidth', 'graphData', 'displayMode', function () {
      return this.get("currentModeData").xScale.call(this);
    }).readOnly(),

    // The yscale has 2 parts: the 'good' side is compressed to take up 1/3, and 2/3rds go to the 'bad' side
    yScale: Ember.computed('contentHeight', function () {

      var height = this.get('contentHeight');
      var yHeight = height - this.opts.textMargin;
      var refData = this.get('graphData');
      var y = _d2.default.scale.ordinal().rangeBands([0, yHeight]);
      y.domain(refData.map(function (d) {
        return d.mnemonic;
      }));

      return y;
    }).readOnly(),

    area: Ember.computed('graphData', 'xScale', 'yScale', function () {
      var height = this.get('contentHeight');
      var yHeight = height - this.opts.textMargin;
      var xScale = this.get('xScale');
      var yScale = this.get('yScale');
      var centerDistance = yHeight / this.get("graphData").length / 2;

      return _d2.default.svg.area().y(function (d) {
        return yScale(d.mnemonic) + centerDistance;
      }).x0(xScale(0)).x1(function (d) {
        return xScale(d.items[d.items.length - 1].y);
      });
    }).readOnly(),

    axes: (0, _d3.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var axis = selection.append("g").classed("axis", true);

        axis.append("rect").attr("class", "background");

        axis.append("g").attr("class", "baraxis y");

        axis.append("g").attr("class", "baraxis x");

        axis.append("line").attr("class", "zeroLine");

        axis.append("rect").attr("class", "gradientLine");
      },
      update: function update(selection) {
        var intl = this.get('intl');
        var component = this;
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var width = this.get('contentWidth');
        var height = this.get('contentHeight');
        var data = this.get('graphData');
        var offeredScales = this.get('offeredScales');
        var useNanoText = this.get("currentModeData").useNanoText(width);
        var leftAxisMargin = this.get("currentModeData").leftAxisMargin(width);
        this.axisSelection = selection;

        var yAxis = _d2.default.svg.axis().scale(yScale).ticks(data.length).orient('left');

        var labelText = selection.selectAll('g.baraxis.y').attr("transform", "translate(" + leftAxisMargin + ",0)").call(yAxis).selectAll("text").attr("dx", "-.2em").attr("class", function (d) {
          var isOffered = true;
          if (offeredScales && offeredScales.length && offeredScales.indexOf(d) < 0) {
            isOffered = false;
          }
          return "barlabel action" + (isOffered ? " offered" : " notOffered");
        }).text("");

        labelText.on('click', function (symptomName, i) {
          var symptomData = _lodash.default.find(data, function (d) {
            return d.mnemonic === symptomName;
          });
          component.actions.setCurrentSymptom.call(component, symptomData.items[symptomData.items.length - 1], this, i);
        });
        labelText.append('tspan').attr('class', 'icon').text(function (d) {
          var symptomData = _lodash.default.find(data, function (s) {
            return s.mnemonic === d;
          });
          return symptomData.isTargeted ? '\uF192 ' : "";
        });
        labelText.append('tspan').text(function (d) {
          // TODO: we only support this component for old-school hard coded scales
          var nanoString = 'provider.scales.' + d + '.nanoname';
          if (useNanoText && intl.exists(nanoString)) {
            return intl.t(nanoString);
          } else {
            return intl.t('provider.scales.' + d + '.shortname');
          }
        });
        this.set('chartLabels', labelText);
        //0 line
        var zeroLine = xScale(this.get("currentModeData").zeroLine);
        selection.selectAll("line.zeroLine").attr("x1", zeroLine).attr("y1", 0).attr("x2", zeroLine).attr("y2", height - this.opts.textMargin);

        this.get("currentModeData").xAxis.call(this, xScale, selection);
      }
    }),

    mainGraph: (0, _d3.join)([0], 'g.dotGraph', {
      enter: function enter(selection) {
        selection.append("g").classed("dotGraph", true);
      },
      update: function update(selection) {
        //draw the bars and attach event handlers
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var data = this.get('graphData');
        var height = this.get('contentHeight');
        var yHeight = height - this.opts.textMargin;
        var offeredScales = this.get('offeredScales');
        var isSkills = this.get('isSkills');

        var centerDistance = yHeight / data.length / 2;

        // symptomRegions are the areas of the graph in which each symptom is rendered
        var symptomRegions = this.drawSymptomRegions(data, selection, yScale, offeredScales, isSkills);

        //draw the bars that show the current value of the symptoms
        var symptomBars = this.drawCurrentValueBars(symptomRegions, xScale, centerDistance);
        this.set('chartBars', symptomBars);

        //draw the dots that show all past values
        var dataPoints = this.drawDataPoints(symptomRegions, xScale, centerDistance);
        this.set('chartDots', dataPoints);

        //draw the indicators of change
        this.drawDeltaIndicators(symptomRegions, xScale, centerDistance, isSkills);

        //draw indicators of symptoms with insufficient data to score
        this.drawUnansweredMarks(symptomRegions, xScale, centerDistance);
      }
    }),

    drawSymptomRegions: function drawSymptomRegions(data, selection, yScale, offeredScales) {
      var symptomRegions = selection.selectAll(".symptomLine").data(data);

      symptomRegions.enter().append("g");

      symptomRegions.transition().duration(250).attr("class", function (d) {
        var isOffered = true;

        if (offeredScales && offeredScales.length && offeredScales.indexOf(d.mnemonic) < 0) {
          isOffered = false;
        }
        var offeredClass = isOffered ? " offered" : " notOffered";
        if (d.items.length) {
          var deltaClass = d.sigDelta ? " sigDelta" : "";
          var deltaDirClass = d.goodDelta ? " posDelta" : " negDelta";
          return "symptomLine" + offeredClass + deltaClass + deltaDirClass;
        }
        return "symptomLine" + offeredClass;
      });

      symptomRegions.attr("transform", function (d) {
        return "translate(0, " + yScale(d.datum.get("baseMnemonic")) + ")";
      });

      symptomRegions.exit().remove();
      return symptomRegions;
    },

    drawCurrentValueBars: function drawCurrentValueBars(symptomRegions, xScale, centerDistance) {
      var minXCoord = xScale.range()[0];
      var component = this;

      var symptomBars = symptomRegions.selectAll("rect.symptomBar").data(function (d) {
        return [d.lastAnswered];
      });

      symptomBars.enter().append("rect").classed("symptomBar", true).on('click', function (d, i, v) {
        component.actions.setCurrentSymptom.call(component, d, this, v);
      });
      symptomBars.classed("answerNotCurrent", function (d) {
        return d && d.age < -1;
      }).transition().duration(250).attr("x", minXCoord).attr("width", function (d) {
        var width = 0;
        if (d && d.score != null) {
          width = xScale(d.score) - minXCoord;
        }
        return Math.max(0, width);
      }).attr("y", 1).attr("height", centerDistance * 2 - 2).attr("fill", 'url(#' + this.get('gradientId') + ')');

      symptomBars.exit().remove();
      return symptomBars;
    },

    drawDataPoints: function drawDataPoints(symptomRegions, xScale, centerDistance) {
      var component = this;

      // The first point should move, so bind to 0, else bind repeatably to the id, so they don't move.
      var pointGroup = symptomRegions.selectAll("g.pointGroup").data(function (d) {
        return [d];
      });
      pointGroup.enter().append("g").classed("pointGroup", true);
      pointGroup.exit().remove();

      var dataPoints = pointGroup.selectAll("g.point")
      // if they're comparing to intake, render oldest items on top
      .data(function (d) {
        return component.get('compareTo') === 'previous' ? d.items : d.items.slice().reverse();
      }, function (d) {
        return d.age === -1 ? d.age : d.reportId;
      });

      dataPoints.enter().append("g").classed("point", true).attr("transform", function (d) {
        return "translate(" + xScale(d.score) + "," + centerDistance + ")";
      }); // New data points come in at the correct location


      dataPoints.on('click', function (item, i, v) {
        component.actions.setCurrentSymptom.call(component, item, this, v);
      }).each(function () {
        this.parentNode.appendChild(this); // Now we have every item, redraw them in the right order.
      });

      dataPoints.transition().duration(250).attr("transform", function (d) {
        return "translate(" + xScale(d.score) + "," + centerDistance + ")";
      });

      dataPoints.exit().remove();

      var dots = dataPoints.selectAll("circle.dot").data(function (d) {
        return [d];
      });

      dots.enter().append("circle").classed("dot", true);

      dots.attr("cx", 1);

      dots.transition().duration(250).attr("cy", 0).attr("r", function (d) {
        if (d.age === -1 || d.score == null) {
          return 0;
        }
        //make the prior report bigger
        if (d.isCompare) {
          return 7;
        }
        return 4;
      }).attr("class", function (d) {
        if (d.age === -1) {
          return 'current dot';
        }
        if (d.isCompare) {
          return 'prior dot';
        }
        return 'dot';
      });

      dots.exit().remove();
      return dataPoints;
    },

    // delta indicators are lines drawn from the most recent value to the comparison point
    // they are drawn inside the comparison dot's point so they are always on top.
    drawDeltaIndicators: function drawDeltaIndicators(symptomRegions, xScale, centerDistance, isSkills) {
      // MAKE THE DELTA ARROWS
      var rightPointedTriangle = "0,0 10,8 10,-8";
      var leftPointedTriangle = "0,0 -10,8 -10,-8";

      var _ref = isSkills ? [leftPointedTriangle, rightPointedTriangle] : [rightPointedTriangle, leftPointedTriangle],
          _ref2 = _slicedToArray(_ref, 2),
          negativeTriangle = _ref2[0],
          positiveTriangle = _ref2[1];

      var deltaGroup = symptomRegions.selectAll("g.deltaGroup").data(function (d) {
        return [d];
      });
      deltaGroup.enter().append('g').classed('deltaGroup', true);
      deltaGroup.exit().remove();

      var polygons = deltaGroup.selectAll("polygon").data(function (d) {
        return [d];
      });

      var drawPoly = function drawPoly(selection) {
        return selection.attr("points", function (d) {
          return d.delta > 0 ? positiveTriangle : negativeTriangle;
        }).attr("transform", function (d) {
          return "translate(" + xScale(d.currentScore) + "," + centerDistance + ")";
        }).attr("class", "delta");
      };

      polygons.enter().insert("polygon", ":first-child").filter(function (d) {
        return d.sigDelta;
      }).call(drawPoly);
      polygons.transition().duration(250).call(drawPoly);

      // There does not seem to be a more efficient way to compute this than doing the filter twice.
      polygons.filter(function (d) {
        return !d.sigDelta;
      }).remove();
      polygons.exit().remove();

      //MAKE THE LINES for the arrows
      var deltaLines = deltaGroup.selectAll("line.deltaLine").data(function (d) {
        return [d];
      });

      var drawDeltaLine = function drawDeltaLine(selection) {
        return selection.attr("class", "deltaLine").attr("x1", function (d) {
          var offset = d.goodDelta ? 5 : -5;
          return xScale(d.currentScore) + offset;
        }).attr("y1", centerDistance).attr("x2", function (d) {
          var offset = d.goodDelta ? -6 : 6;
          return xScale(d.compareScore) + offset; // we can't just use xScale(d.delta) since the scale is nonlinear
        }).attr("y2", centerDistance);
      };

      deltaLines.enter().insert('line', ":first-child").call(drawDeltaLine);
      deltaLines.transition().duration(250).filter(function (d) {
        return d.sigDelta;
      }).call(drawDeltaLine);

      deltaLines.filter(function (d) {
        return !d.sigDelta;
      }).remove();
      deltaLines.exit().remove();
    },

    drawUnansweredMarks: function drawUnansweredMarks(symptomRegions, xScale, centerDistance) {
      var minXCoord = xScale.range()[0];
      var component = this;
      var unansweredMarks = symptomRegions.selectAll("text.unanswered").data(function (d) {
        return [d.neverAnswered];
      });

      unansweredMarks.enter().append("text").classed("unanswered", true).on('click', function (d, i, v) {
        component.actions.setCurrentSymptom.call(component, d[d.length - 1], this, v);
      });
      unansweredMarks.text('');
      unansweredMarks.transition().duration(250).filter(function (d) {
        return d;
      }).attr("x", minXCoord + centerDistance).attr("y", centerDistance + 4).text("?");

      unansweredMarks.exit().remove();
    },

    //transform an [time][symtpom] array  into a  [symptom][time] array
    transformData: function transformData(data) {
      var _this = this;

      if (!data[0]) {
        return [];
      }

      var valueTransform = this.get("currentModeData").valueTransform;
      var isSkills = this.get('isSkills');

      return data.map(function (scaleData, i) {
        var length = scaleData.items.length;

        // the index of the report we're comparing to
        var compareIndex = _this.get('compareTo') === 'previous' ? Math.max(0, length - 2) : 0;
        var currentIndex = Math.max(0, length - 1);
        var neverAnswered = true;
        var lastAnswered = null;
        var items = scaleData.items.map(function (cv, j) {
          var thisValue = cv.get("value");
          var datum = {
            datum: cv,
            x: cv.get("report.patientSession.targetDate"),
            score: cv.get('notRespondedTo') ? null : valueTransform(cv.get("value")),
            isCompare: j === compareIndex,
            reportId: cv.get("report.id"),
            age: j - length
          };

          if (typeof thisValue === 'number') {
            neverAnswered = false;
            lastAnswered = datum;
          }
          return datum;
        });

        // now compute symptom deltas where appropriate
        var currentScore = null;
        var compareScore = null;
        var sigDelta = false;
        var delta = null;
        var goodDelta = null;

        if (scaleData.items[currentIndex] && scaleData.items[compareIndex]) {
          currentScore = valueTransform(scaleData.items[currentIndex].get('value'));
          compareScore = valueTransform(scaleData.items[compareIndex].get('value'));
          sigDelta = scaleData.items[currentIndex].isSignificantChangeFrom(scaleData.items[compareIndex]);
          delta = sigDelta ? currentScore - compareScore : 0;
          goodDelta = delta < 0 !== isSkills;
        }

        return {
          index: i,
          mnemonic: scaleData.scale.get("baseMnemonic"),
          datum: scaleData.scale,
          isTargeted: _this.get('patientScaleTargets').any(function (t) {
            return t.get('scale.id') === scaleData.scale.get('id');
          }),
          items: items,
          lastAnswered: lastAnswered,
          neverAnswered: neverAnswered,
          delta: delta,
          goodDelta: goodDelta,
          sigDelta: sigDelta,
          currentScore: currentScore,
          compareScore: compareScore
        };
      });
    },

    //gets the low value for the bar
    getBarMinXCoord: function getBarMinXCoord(d, xscale) {
      return _d2.default.min(d, function (d) {
        return xscale(d.score);
      });
    },

    //gets the high value for the bar
    getBarMaxXCoord: function getBarMaxXCoord(d, xscale) {
      return _d2.default.max(d, function (d) {
        return xscale(d.score);
      });
    },

    // Draws a gradient for supporting continuous scales.  Supports scales for which high is bad.
    makeGradients: function makeGradients() {
      var context = this;
      this.get("svg.defs").selection.each(function () {
        var selection = _d2.default.select(this);
        var xScale = context.get("xScale");
        var gradientId = context.get('gradientId');
        var gradTemplate = selection.selectAll("linearGradient#" + gradientId).data([0]);

        var gradData = context.get('currentModeData').gradients;

        gradTemplate.enter().append("linearGradient").attr("id", gradientId);

        gradTemplate.attr("x1", _d2.default.max(xScale.range())).attr("x2", _d2.default.min(xScale.range())).attr("y1", "0%").attr("y2", "0%").attr("gradientUnits", "userSpaceOnUse");

        var stops = gradTemplate.selectAll("stop").data(gradData);

        stops.enter().append("stop").attr("offset", function (d) {
          return d.offset;
        }).attr("stop-color", function (d) {
          return d.color;
        }).attr("stop-opacity", function (d) {
          return d.opacity;
        });
      });
    },

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');
      this.makeGradients();

      selection.each(function () {
        var selection = _d2.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;

        context.axes(selection);
        context.mainGraph(selection);
      });
    }
  });
});