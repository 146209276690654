define('frontend/pods/provider/assessment/scales/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('assessment/scale', params.scale_id);
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('name'), linkable: false, isRaw: true });
    }
  });
});