define('frontend/pods/provider/user/password-reset-error/route', ['exports', 'frontend/mixins/unauthenticated-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _unauthenticatedRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_unauthenticatedRouteMixin.default, _metricsMixin.default, {
    breadCrumb: null,
    metrics: Ember.inject.service(),
    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "passwordReset.badToken" });
      }
    }
  });
});