define('frontend/pods/components/survey/taking-local/common/questions/textbox-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin', 'frontend/mixins/survey/text-question-mixin'], function (exports, _questionComponentMixin, _textQuestionMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, _textQuestionMixin.default, {
    inputType: Ember.computed("question.properties.type", function () {
      return this.getWithDefault("question.properties.type", 'text');
    }),

    min: Ember.computed.alias("question.properties.min"),
    max: Ember.computed.alias("question.properties.max"),

    // Autofocus the input when the question becomes active.
    currentlyActiveObserver: Ember.observer('currentlyActive', function () {
      if (this.get("currentlyActive")) {
        // This does not have a tag, so go back up to the parent view to find the input.
        this.parentView.$('input').focus();
      }
    })
  });
});