define('frontend/pods/components/common/controls/notification-preference-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var optionValues = ['both', 'email', 'text', 'none'];

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    valueKey: 'common.notificationPreference',
    options: Ember.computed(function () {
      var intl = this.get('intl');
      var valueKey = this.get("valueKey");
      return optionValues.map(function (value) {
        return {
          value: value,
          name: intl.t(valueKey + '.' + value)
        };
      });
    })
  });
});