define("frontend/adapters/billing/charge", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForUpdateRecord: function urlForUpdateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=charge-items";
      return value;
    }
  });
});