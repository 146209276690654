define('frontend/pods/components/internal/scales/scale-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),

    filterContext: Ember.computed(function () {
      return { status: ['draft', 'awaiting_review', 'awaiting_publication', 'review_in_progress'] };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        "propertyName": "mnemonic",
        "title": "Mnemonic"
      }, {
        "propertyName": "version",
        "title": "Version"
      }, {
        "propertyName": "status",
        "title": "Status"
      }, {
        "propertyName": "updatedAt",
        "template": "components/common/server-side-table/date-no-time-zone",
        "title": "Updated"
      }, {
        "propertyName": "publishedAt",
        "template": "components/common/server-side-table/date-no-time-zone",
        "title": "Published At"
      }, {
        "title": intl.t('common.actions'),
        "template": "components/internal/scales/scale-table/action-buttons",
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        this.resetData();
      }
    },
    resetData: function resetData() {
      var filterContext = this.get("filterContext");

      var filters = filterContext.status ? filterContext : {};

      this.set("data", this.get("store").query("assessment/rawScaleDatum", { filter: filters }));
    },

    actions: {
      newVersion: function newVersion(record) {
        var _this = this;

        record.set("createNewVersion", "true");
        return record.save(function (result) {
          var newVersionId = result.get("successorId");

          _this.transitionTo("internal.scales.show.edit", newVersionId);
        });
      },
      updateFilters: function updateFilters(newStatus) {
        this.set("filterContext", { status: newStatus });
        this.resetData();
      }
    }

  });
});