define('frontend/pods/provider/patients/index/route', ['exports', 'frontend/mixins/provider-filter-route', 'frontend/pods/base-route'], function (exports, _providerFilterRoute, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerFilterRoute.default, {
    preload: Ember.inject.service(),
    breadCrumb: {
      title: 'client details'
    },
    queryParams: {
      searchId: { refreshModel: true // Refresh so that if we're on the patients page we still refresh
      } },
    beforeModel: function beforeModel() {
      return this.get("preload").ensure("clinicians");
    },
    model: function model(params) {
      if (params.searchId) {
        return this.get("store").peekRecord('patientSearch', params.searchId);
      } else {
        // Dummy object we can write the search term to.
        return Ember.Object.create({});
      }
    },

    actions: {
      // Clear the searchId on transition.  We do this in here rather than using resetController because this preserves back button capability better.
      willTransition: function willTransition(transition) {
        if (transition.targetName !== 'provider.patients.index') {
          this.controller.set('searchId', undefined);
        }
      }
    }
  });
});