define('frontend/pods/components/provider/session-selector/component', ['exports', 'frontend/models/patient-session'], function (exports, _patientSession) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    useDefault: true, //if true, and defaults exist, will hide full selection/creation utilities
    defaultCareEpisode: undefined, //if specified, a care episode will be auto-selected
    defaultSession: undefined, //if specified, a session will be auto-selected
    activeCareEpisodes: undefined, // current episodes of care to choose from

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('defaultSession')) {
        this.set('patientSession', this.get('defaultSession'));
      }
    },


    showOrganizations: Ember.computed.gt("providerOrganizations.length", 1),

    availableOrganizations: Ember.computed("providerOrganizations", "activeCareEpisodes", function () {
      var takenOrgIds = this.getWithDefault("activeCareEpisodes", []).mapBy("organization.id");
      return this.getWithDefault("providerOrganizations", []).reject(function (org) {
        return takenOrgIds.includes(org.get("id"));
      });
    }),

    canCreateCareEpisode: Ember.computed.notEmpty("availableOrganizations"),

    // Because groupValue does an === comparison, we can't compare selected episode directly because
    // sometimes you'll have a promise and other times the object directly. So instead use the id
    // to compare against.
    selectedPatientSessionId: Ember.computed("patientSession.id", "showCreateCareEpisode", {
      get: function get() {
        if (this.get("showCreateCareEpisode")) {
          return 'new';
        }

        // If we're creating a new session for an existing care episode, return the care episode.
        var session = this.get("patientSession");
        if (session && session.get("isNew")) {
          return session.get("careEpisode.content");
        }

        return session;
      }
    }),

    actions: {
      declineDefault: function declineDefault() {
        this.set('useDefault', false);

        if (this.get("patientSession.careEpisode.isNew")) {
          this.set("showCreateCareEpisode", true);
          this.set("newCareEpisode", this.get("patientSession.careEpisode"));
        }
      },
      patientSessionSelected: function patientSessionSelected(session) {
        this.set("selectedPatientSession", session);
        this.set("showCreateCareEpisode", false);

        if (this.get("newCareEpisode")) {
          this.get("newCareEpisode").rollbackAttributes();
          this.set("newCareEpisode", null);
        }

        if (this.get("newPatientSession")) {
          this.get("newPatientSession").rollbackAttributes();
          this.set("newPatientSession", null);
        }

        this.sendAction('onSessionSelect', session);
      },
      createNewEpisodeAndSession: function createNewEpisodeAndSession() {
        this.set("showCreateCareEpisode", true);
        var defaultNewOrg = this.get("availableOrganizations.firstObject");
        var careEpisode = this.set("newCareEpisode", this.get("store").createRecord('careEpisode', { patient: this.get("patient"), organization: defaultNewOrg }));

        var newSession = this._createNewSession(careEpisode);
        this.sendAction('onSessionSelect', newSession);
      },
      careEpisodeForSessionSelected: function careEpisodeForSessionSelected(careEpisode) {
        this.set("showCreateCareEpisode", false);
        if (this.get("newCareEpisode")) {
          this.get("newCareEpisode").rollbackAttributes();
          this.set("newCareEpisode", null);
        }

        var newSession = this._createNewSession(careEpisode);

        this.sendAction('onSessionSelect', newSession);
      }
    },

    _createNewSession: function _createNewSession(careEpisode) {
      var store = this.get("store");
      var patient = this.get("patient");

      var newSession = _patientSession.Utils.create(store, patient, careEpisode, this.get("activeProvider"), true);

      this.set("newPatientSession", newSession);
      this.set("patientSession", newSession);

      return newSession;
    },


    /**
     * @override: ember lifecycle
     */
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get("newCareEpisode.isNew")) {
        var model = this.get("newCareEpisode");

        if (model.content) {
          model.content.rollbackAttributes();
        } else {
          model.rollbackAttributes();
        }
      }

      if (this.get("newPatientSession.isNew")) {
        var _model = this.get("newPatientSession");

        if (_model.content) {
          _model.content.rollbackAttributes();
          _model.get("assessmentReport").rollbackAttributes();
          _model.get("schedulingAppointment").rollbackAttributes();
        } else {
          _model.rollbackAttributes();
        }
      }
    }
  });
});