define("frontend/pods/settings-url/service", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    url: null,
    getUrl: function getUrl() {
      return this.get("url");
    },
    setUrl: function setUrl(value) {
      this.set("url", value);
    },
    clearUrl: function clearUrl() {
      this.set("url", null);
    }
  });
});