define('frontend/pods/components/provider/billing/charge-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'charge-list',
    intl: Ember.inject.service(),
    columns: Ember.computed(function () {
      var intl = this.get('intl');

      var columns = [{
        template: 'components/provider/billing/charge-list/patient-name-column',
        sortedBy: ['patient.user.lastName', 'patient.user.firstName'],
        title: intl.t('common.patient')
      }, {
        propertyName: 'institutePayor.name',
        title: intl.t('model.charge.insurancePlan')
      }, {
        template: 'components/provider/billing/charge-list/service-date-column',
        title: intl.t('model.charge.serviceDate'),
        sortedBy: 'dateOfService',
        sortPrecedence: 0,
        sortDirection: 'asc'
      }, {
        template: 'components/provider/billing/charge-list/provider-name-column',
        // For now there is a jsonapi resources bug making this error, so simply disable it.
        // sortedBy: ['patientSession.provider.user.lastName', 'patientSession.provider.user.firstName'],
        disableSorting: true,
        title: intl.t('model.charge.providerName')
      }, {
        template: 'components/provider/billing/charge-list/facility-name-column',
        sortedBy: 'organization.name',
        title: intl.t('model.charge.organizationName')
      }, {
        propertyName: 'payorContractCode.chargeItemCode.code',
        title: intl.t('model.chargeItemCode.code'),
        disableSorting: true
      }, {
        propertyName: 'billableUnits',
        title: intl.t('model.charge.expectedUnits')
      }, {
        template: 'components/provider/billing/charge-list/expected-price-column',
        sortedBy: 'expectedAmountCents',
        title: intl.t('model.charge.expectedAmount')
      }, {
        template: 'components/provider/billing/charge-list/list-price-column',
        sortedBy: 'billableAmountCents',
        title: intl.t('model.charge.billableAmount')
      }, {
        component: 'components/provider/billing/charge-list/charge-status',
        sortedBy: 'status',
        title: intl.t('model.charge.status')
      }, {
        component: 'components/provider/billing/charge-list/charge-actions',
        disableSorting: true,
        title: ''
      }];

      return columns;
    })
  });
});