define('frontend/pods/components/common/multiple-date-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var recurrenceOptionMap = {
    'daily': [1, 'day'],
    'weekdays': [1, 'day'],
    'weekly': [1, 'week'],
    'biweekly': [2, 'weeks'],
    'monthly': [1, 'month'],
    'quarterly': [3, 'months'],
    'biannually': [6, 'months']
  };

  var weekendMap = {
    'daily': true,
    'weekdays': false,
    'weekly': true,
    'biweekly': true,
    'monthly': true,
    'quarterly': true,
    'biannually': true
  };

  function isWeekend(date) {
    return date.isoWeekday() === 6 || date.isoWeekday() === 7;
  }

  var generateRecurrenceDates = function generateRecurrenceDates(startDate, repeatMnemonic, endDate) {
    var interval = recurrenceOptionMap[repeatMnemonic];
    var includeWeekends = weekendMap[repeatMnemonic];
    var date = moment(startDate);
    var dates = [];

    while (date <= endDate) {
      var _date;

      if (includeWeekends || !isWeekend(date)) {
        dates.addObject(date.toDate());
      }

      date = (_date = date).add.apply(_date, _toConsumableArray(interval));
    }

    return dates;
  };

  exports.generateRecurrenceDates = generateRecurrenceDates;
  exports.default = Ember.Component.extend({
    classNames: ['form-subgroup'],
    setTime: true,
    hourOptions: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    minuteOptions: [0, 15, 30, 45],
    recurrenceOptions: ['none', 'daily', 'weekdays', 'weekly', 'biweekly', 'monthly', 'quarterly', 'biannually'],

    startDateMinutes: Ember.computed('startDate', function () {
      return Math.floor(this.getWithDefault('startDate', new Date()).getMinutes() / 15) * 15;
    }),

    startDateHours: Ember.computed('startDate', function () {
      var hourOffset = this.getWithDefault('startDate', new Date()).getHours() % 12;
      return hourOffset === 0 ? 12 : hourOffset;
    }),

    startDateIsPm: Ember.computed('startDate', function () {
      return this.getWithDefault('startDate', new Date()).getHours() >= 12;
    }),

    showRecurrenceEndDate: Ember.computed('repeatMnemonic', function () {
      var mnemonic = this.get('repeatMnemonic');
      return mnemonic && mnemonic !== 'none';
    }),

    recurrenceDetails: Ember.computed('startDate', 'endDate', 'repeatMnemonic', function () {
      var _getProperties = this.getProperties('startDate', 'endDate', 'repeatMnemonic'),
          startDate = _getProperties.startDate,
          endDate = _getProperties.endDate,
          repeatMnemonic = _getProperties.repeatMnemonic;

      return {
        startDate: startDate,
        endDate: endDate,
        repeatMnemonic: repeatMnemonic,
        dates: generateRecurrenceDates(startDate, repeatMnemonic, endDate)
      };
    }),

    _broadcastRecurrenceChange: function _broadcastRecurrenceChange() {
      var onRecurrenceChange = this.get('onRecurrenceChange');

      if (onRecurrenceChange) {
        onRecurrenceChange(this.get('recurrenceDetails'));
      }
    },


    actions: {
      updateHours: function updateHours(value) {
        var startDate = this.get('startDate');
        //this.set('session.schedulingAppointment.startDate', startDate + )
        var hour = (this.get('startDateIsPm') ? 12 : 0) + parseInt(value) % 12;
        startDate.setHours(hour);
        this.set('startDate', new Date(startDate));
      },
      updateMinutes: function updateMinutes(value) {
        var startDate = this.get('startDate');
        startDate.setMinutes(value);
        this.set('startDate', new Date(startDate));
      },
      updateAmPm: function updateAmPm(value) {
        var startDate = this.get('startDate');
        if (value === 'PM') {
          startDate.setHours(startDate.getHours() % 12 + 12);
        } else {
          startDate.setHours(startDate.getHours() % 12);
        }
        this.set('startDate', new Date(startDate));
      },
      startDateChanged: function startDateChanged(value) {
        if (!value) {
          this.set('startDate', null);
        }
        var date = this.get('startDate');
        value.setHours(date.getHours());
        value.setMinutes(date.getMinutes());
        this.set('startDate', new Date(value));
      },
      endDateChanged: function endDateChanged(value) {
        if (!value) {
          return;
        }
        var date = this.get('startDate');
        value.setHours(date.getHours());
        value.setMinutes(date.getMinutes());
        this.set('endDate', new Date(value));

        this._broadcastRecurrenceChange();
      },
      changeRecurrence: function changeRecurrence(value) {
        var onRecurrenceTypeChange = this.get('onRecurrenceTypeChange');

        if (onRecurrenceTypeChange) {
          onRecurrenceTypeChange(value);
        }

        this.set('repeatMnemonic', value);
        if (!this.get('endDate')) {
          var newEndDate = moment(this.get('startDate')).add(3, 'months').toDate();
          this.set('endDate', newEndDate);
        }

        this._broadcastRecurrenceChange();
      }
    }
  });
});