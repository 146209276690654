define('frontend/pods/components/provider/assessment/report-v2/symptoms-explorer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["symptoms-explorer"],
    computedValueDomain: 'normed',

    selectedScale: null,
    selectedReport: null, // Selected report !== activeReport, because you can choose a data point back in history.
    activeComputedValue: Ember.computed("selectedScale", "selectedReport", "reportSettings.currentPopulation", function () {
      var _this = this;

      var selectedScaleId = this.get("selectedScale.id");
      var cvs = this.getWithDefault("selectedReport.computedValues", []);
      var currentPopulation = this.get("reportSettings.currentPopulation");

      if (!selectedScaleId || !cvs) {
        return null;
      }

      var candidates = cvs.filter(function (cv) {
        return cv.get('scale.id') === selectedScaleId && cv.get('domain') === _this.computedValueDomain && (!currentPopulation || currentPopulation.get("id") === cv.get("population.id"));
      });

      // I can't remember why we decided to filter on scale and report rather than using the computed
      // value directly, but it breaks when you have the same scale with multiple users on a single set of values.
      // Rather than fully replumb this, just add an additional check here so that if it's ambiguous, use the
      // selected value.
      // I think it's because if you change e.g. norms etc, the selected computed value may no longer be the right one.
      if (candidates.length > 1 && this.get("selectedComputedValue")) {
        return this.get("selectedComputedValue");
      } else if (candidates.length === 1) {
        return candidates[0];
      } else {
        // If you can't find a computed value, and there's one which was directly clicked on, just return it. This might
        // break in cases where e.g. the norms have changed, but in that case it's probably the fact that the norms
        // aren't actually present for this scale so you might as well just display what you've got.
        // This is at best a hack, but it fixes the problem for now while we try to retire this report.
        return this.get("selectedComputedValue");
      }
    }),

    //don't include incomplete reports in the graph
    completeReports: Ember.computed("reports.[]", function () {
      var reports = this.get('reports');
      if (!reports) {
        return [];
      }
      return reports.filterBy('hasData', true);
    }),

    dateRange: Ember.computed('reports.[]', function () {
      return {
        minDate: this.get('reports.firstObject.patientSession.targetDate'),
        maxDate: this.get('reports.lastObject.patientSession.targetDate')
      };
    }),

    showRecentChanges: Ember.computed('isFirstReport', function () {
      return !this.get('isFirstReport') && this.get('activeReport.includesComputedNorse');
    }),

    selectedReportObserver: Ember.observer("activeReport", function () {
      this.set("selectedReport", null);
      this.set("selectedScale", null);
      this.set("selectedComputedValue", null);
    }),

    validPopulation: Ember.computed('reportSettings.currentPopulation.mnemonic', function () {
      return ['inpatient', 'outpatient', 'general'].indexOf(this.get("reportSettings.currentPopulation.mnemonic")) >= 0;
    }),

    showComparisonSelector: Ember.computed.not('isFirstReport'),

    showTrends: Ember.computed.not('isFirstReport'),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedScale", value ? value.get("scale") : null);
        this.set("selectedReport", value ? value.get("report") : null);
        this.set("selectedComputedValue", value);
      }
    },

    didInsertElement: function didInsertElement() {
      this._tabShownHandler = function () {
        return $(document).trigger("resize");
      };

      // qqHRB this produces a whole load of ugly errors due to negative scales, but at least it works.
      //http://stackoverflow.com/questions/21563609/nvd3-charts-not-rendering-correctly-in-hidden-tab
      $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', this._tabShownHandler);
    },
    willDestroyElement: function willDestroyElement() {
      $(document).off('shown.bs.tab', 'a[data-toggle="tab"]', this._tabShownHandler);
    }
  });
});