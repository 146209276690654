define('frontend/pods/components/provider/plan/measurement-plan-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterPlansByType: Ember.computed.notEmpty('planType'),
    classNames: ['measurement-plan-selector'],
    selectedPlan: Ember.computed('model', 'property', function () {
      return Ember.get(this.get('model'), this.get('property'));
    }),
    filteredMeasurementPlans: Ember.computed('measurementPlans.[]', 'planType', 'filterPlansByType', function () {
      if (this.get('filterPlansByType')) {
        return this.get("measurementPlans").filterBy('defaultPlanType', this.get('planType'));
      } else {
        return this.get('measurementPlans');
      }
    }),
    actions: {
      planSelected: function planSelected(plan) {
        Ember.set(this.get('model'), this.get('property'), plan);
        this.notifyPropertyChange('selectedPlan');
        if (this.get("onChange")) {
          this.get('onChange')(plan);
        }
      }
    },
    filterActions: Ember.computed('filterPlansByType', function () {
      var _this = this;

      return {
        planType: this.get('planType'),
        filterPlansByType: this.get('filterPlansByType'),
        onSetFilter: function onSetFilter() {
          _this.set('filterPlansByType', true);
          _this.notifyPropertyChange('filterPlansByType');
        },
        onClearFilter: function onClearFilter() {
          _this.set('filterPlansByType', false);
          _this.notifyPropertyChange('filterPlansByType');
        }
      };
    }),
    didDestroyElement: function didDestroyElement() {
      if (this.get("selectedPlan.isNew")) {
        this.get("selectedPlan.measurementPlanScales").forEach(function (s) {
          return s.rollbackAttributes();
        });
        this.get("selectedPlan").rollbackAttributes();
      }
    }
  });
});