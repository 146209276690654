define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    rootURL: _environment.default.rootURL,
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    beacon: Ember.inject.service('help-scout-beacon'),

    willTransition: function willTransition() {
      this._super.apply(this, arguments);
    },
    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = _this.getWithDefault('currentRouteName', 'unknown');
        _this.get('beacon').trackPageView();
        Ember.get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('provider', function () {
      this.route('user', function () {
        this.route('password-reset', { path: "password-reset/:token" });
        this.route('forgotten-password');
        this.route('confirm');
        this.route('confirm-error');
        this.route('update-password');
        this.route('password-reset-error');
      });

      this.route('profile', function () {
        this.route('edit', function () {
          this.route('notifications');
        });
      });

      this.route('assessment', function () {
        this.route('dashboard');
        this.route('dashboard-loading');

        this.route('invitation', { path: "invitation/:invitation_id" }, function () {
          this.route('exit');

          this.route('return', function () {
            this.route('status', { path: ":status" });
          });
          this.route('begin');
          this.route('complete');
          this.route('wrong-provider');
          this.route('edit');
          this.route('edit-loading');
        });
        this.route('scales', function () {
          this.route('show', { path: ":scale_id" }, function () {
            this.route('preview');
          });
        });
      });

      this.route('patients', function () {
        this.route('lookup', function () {
          this.route('search');
          this.route('create');
        });
        this.route('create');
        this.route('show', { path: ":patient_id" }, function () {
          this.route('edit', { path: "edit" });
          this.route('reset-password', { path: "reset-password" });
          this.route('longitudinal');
          this.route('longitudinal-loading');

          this.route('sessions', { path: "sessions" }, function () {
            this.route('create');
            this.route('show', { path: ":patient_session_id" }, function () {
              this.route('edit', { path: "edit" });
              this.route('edit-loading');
            });
          });

          this.route('related-people', { path: "related-people" }, function () {
            this.route('create');
            this.route('edit', { path: ":related_person_id/edit" });
            this.route('reset-password', { path: ":related_person_id/reset-password" });
          });

          this.route('care-episodes', { path: "care-episodes" }, function () {
            this.route('create');
            this.route('show', { path: ":care_episode_id" }, function () {
              this.route('edit');
            });
          });

          this.route('measure-now', { path: "measure-now" });
          this.route('measure-now-loading');
          this.route('flags');
          this.route('plan');

          this.route('coverages', function () {
            this.route('create');

            this.route('show', { path: ':coverage_id' }, function () {
              this.route('edit');
            });
          });

          this.route('consents', function () {
            this.route('create');
          });
          this.route('report-v2');
          this.route('report-v3');
        });

        this.route('reports', function () {
          this.route('care-episode', { path: ":care_episode_id" });
          this.route('session', { path: ":patient_session_id" });
        });
      });

      this.route('login');
      this.route('exit-to-kiosk');
      this.route('inactive');
      this.route('exit-to-patient', { path: "exit-to-patient/:user_id" });
      this.route('admin', function () {
        this.route('users', function () {
          this.route('create');
          this.route('show', { path: ":provider_id" }, function () {
            this.route('edit');
            this.route('notifications');
          });
        });
        this.route('export', function () {
          this.route('create');
        });
        this.route('payors', function () {
          this.route('show', { path: ":institute_payor_id" }, function () {
            this.route('contracts', function () {
              this.route('show', { path: ":payor_contract_id" }, function () {
                this.route('edit');
              });
              this.route('create');
            });
          });
          this.route('create');
        });
        this.route('configuration', function () {});
      });
      this.route('reports', function () {
        this.route('show', { path: ":report_code" });
      });

      this.route('external', function () {
        this.route('patient', function () {
          this.route('report');
        });
      });

      if (_environment.default.enableDemoLoop) {
        this.route('demo-user-create');
      }
      this.route('help');

      this.route('plan', function () {
        this.route('entities', function () {
          this.route('show', { path: ":entity_plan_id" }, function () {
            this.route('edit');
          });
          this.route('create');
        });
      });

      this.route('patient', function () {
        this.route('show', function () {});
      });

      this.route('billing', function () {
        this.route('charges', function () {});
      });
      this.route('experiments', function () {
        this.route('show', { path: ":experiment_id" }, function () {});
      });
      this.route('sso-auth');
    });

    this.route('application-error');
    this.route('logout');

    this.route('patient', function () {
      this.route('welcome');

      this.route('assessment', function () {
        this.route('invitation', { path: "invitation/:invitation_id" }, function () {
          this.route('exit');

          this.route('return', function () {
            this.route('status', { path: ":status" });
          });
          this.route('begin');
          this.route('complete');

          this.route('error');
          this.route('expired');
          this.route('early');
        });
        this.route('logout');
        this.route('kiosk', function () {
          this.route('inactive');
        });
        this.route('confirm-details');
        this.route('latest');
        this.route('up-to-date');
      });
      this.route('inactive');
      this.route('login');

      this.route('details-login');

      this.route('user', function () {
        this.route('confirm-error');
        this.route('confirm-identity');
      });
      this.route('code-login');
    });

    this.route('related-person', function () {
      this.route('login');
      this.route('assessment', function () {
        this.route('up-to-date');
        this.route('list');
        this.route('invitation', { path: "invitation/:invitation_id" }, function () {
          this.route('exit');

          this.route('return', function () {
            this.route('status', { path: ":status" });
          });
          this.route('begin');
          this.route('complete');
          this.route('error');
          this.route('early');
          this.route('expired');
        });
        this.route('invitation-login');
      });
      this.route('logout');
      this.route('details-login');
      this.route('user', function () {
        this.route('confirm-error');
        this.route('confirm-identity');
      });
      this.route('code-login');
    });

    this.route('expired');

    this.route('unsupported-pii');
    this.route('not-found', { path: "*path" });
    this.route('invalid');
    this.route('internal', function () {
      this.route('institutes', function () {
        this.route('create');
        this.route('show', { path: ":institute_id" }, function () {
          this.route('edit');
          this.route('scales');
        });
      });
      this.route('import');
      this.route('event-tracer');
      this.route('scales', function () {
        this.route('create');
        this.route('show', { path: ":raw_scale_datum_id" }, function () {
          this.route('edit');
        });
        this.route('import');
      });
      this.route('impersonate');
    });

    this.route('demo', function () {});

    this.route('survey', function () {
      this.route('finished');

      this.route('taking', function () {
        this.route('demo', function () {
          this.route('begin-loading');
          this.route('begin-form');
          this.route('begin');
          this.route('response', { path: "response/:response_id" });
          this.route('response-form', { path: "response-form/:response_id" });
          this.route('response-loading');
          this.route('landing');
        });
        this.route('patient', function () {
          this.route('begin-loading');
          this.route('begin');
          this.route('response', { path: "response/:response_id" });
          this.route('response-loading');
        });
        this.route('provider', function () {
          this.route('begin-loading');
          this.route('begin');
          this.route('response', { path: "response/:response_id" });
          this.route('response-loading');

          this.route('patients', function () {
            this.route('show', function () {
              this.route('related-people', function () {});
            });
          });
          this.route('reports', function () {
            this.route('show');
          });
        });
      });
      this.route('exit');
      this.route('timeout');
      this.route('demo-expired');
      this.route('error');
    });

    this.route('landing');
    this.route('app', { path: '/app/*' });
    this.route('embed', { path: '/embed/*' });
    this.route('widgets', { path: '/widgets/*' });
  });

  exports.default = Router;
});