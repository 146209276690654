define('frontend/pods/internal/institutes/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      return this.store.findAll('institute');
    }
  });
});