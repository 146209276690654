define('frontend/models/survey/bulk-survey-edit', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    response: _emberData.default.belongsTo("survey/response"),
    responseData: _emberData.default.attr()
  });
});