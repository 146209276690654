define('frontend/models/current-institute', ['exports', 'ember-data', 'frontend/models/application', 'frontend/models/entity'], function (exports, _emberData, _application, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    entityType: 'institute',
    shortcode: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    ssoSettings: _emberData.default.attr("object"),
    helpscoutBeaconKey: _emberData.default.attr("object"),
    featureSet: _emberData.default.attr("object"),
    configuration: _emberData.default.attr("object"),
    enabled: _emberData.default.attr("boolean"),
    patientAuthenticationMethod: _emberData.default.attr("string"),
    requiredPatientFields: Ember.computed("patientAuthenticationMethod", function () {
      var method = this.getWithDefault('patientAuthenticationMethod', 'full');

      // Note that this is more relaxed than the actual authentication required because
      // you can leave the ssn field to get the patient to enter it and use it on
      // subsequent attempts
      var requiredMap = {
        full: ["dob", "dobString"],
        mrn_only: [],
        mrn_ssn: ["lastSsn"],
        dob_only: ["dob"]
      };

      return requiredMap[method];
    }),

    defaultAuthenticationMechanism: _emberData.default.attr("string"),

    scaleCreatorMode: _emberData.default.attr("string"),

    rootPath: Ember.computed('id', function () {
      return 'institute/' + this.get('id');
    }),
    toEntity: function toEntity() {
      return (0, _entity.fromModel)(this, this.get('store'));
    }
  });
});