define('frontend/pods/components/provider/keepalive-sender/component', ['exports', 'frontend/config/environment', 'frontend/adapters/application'], function (exports, _environment, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onActivity: function onActivity() {
        if (this.get("session.isAuthenticated")) {
          this._sendToServer();
        }
      }
    },
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    keepalive: Ember.inject.service(),
    init: function init() {
      this.set("sendKeepalive", _environment.default.sendKeepalive);
      this._super.apply(this, arguments);
      this.get('keepalive').start({ onActivity: this.get("actions.onActivity").bind(this) });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('keepalive').stop();
    },
    _sendToServer: function _sendToServer() {
      var _this = this;

      if (this.get("sendKeepalive")) {
        $.ajax({
          url: "/api/keepalive",
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            var data = _this.get('session.data.authenticated');
            (0, _application.setAuthenticationHeaders)(xhr, data);
          }
        }).then(function () /*data*/{
          // Do nothing on success
        }, function (jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          if (_this.get("session.isAuthenticated") && (jqXHR.status === 498 || jqXHR.status === 499)) {
            console.log("Session is no longer valid, logging out");
            _this.get("metrics").trackEvent({ event: "user.logout", reason: "activityTimeout" });
            _this.get('sessionUser').logout();
          }
        });
      }
    }
  });
});