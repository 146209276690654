define('frontend/pods/internal/scales/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('assessment/rawScaleDatum', params.raw_scale_datum_id);
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('mnemonic'), isRaw: true, crumbClass: 'root-crumb' });
    }
  });
});