define('frontend/models/assessment/goal', ['exports', 'ember-data', 'frontend/models/application', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _emberData, _application, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Utils = exports.Validations = exports.goalTypes = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var goalTypes = ['goal_attainment_scaling', 'top_problem', 'goal_likert'];

  var validationOptions = {
    careEpisode: (0, _emberCpValidations.validator)('presence', true),
    patientText: (0, _emberCpValidations.validator)('presence', true),
    startDate: (0, _emberCpValidations.validator)('presence', true),
    user: (0, _emberCpValidations.validator)('presence-or', {
      presence: true,
      dependentKeys: ['participant']
    }),
    participant: (0, _emberCpValidations.validator)('presence-or', {
      presence: true,
      dependentKeys: ['user']
    }),
    goalType: (0, _emberCpValidations.validator)('inclusion', { in: goalTypes })
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  var Utils = {
    create: function create(store, patient, careEpisode) {
      return store.createRecord('assessment/goal', { careEpisode: careEpisode, patient: patient, user: patient.get('user'), startDate: new Date(), status: 'new', goalType: 'goal_attainment_scaling' });
    }
  };

  exports.goalTypes = goalTypes;
  exports.Validations = Validations;
  exports.Utils = Utils;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    createdAt: _emberData.default.attr("date"),
    careEpisode: _emberData.default.belongsTo('careEpisode', { async: true }),
    patient: _emberData.default.belongsTo('patient', { async: true }),

    goalAnswers: _emberData.default.hasMany('assessment/goalAnswer'),

    status: _emberData.default.attr("string"),
    statusEvent: _emberData.default.attr("string"),
    patientText: _emberData.default.attr("string"),
    isBeneficial: _emberData.default.attr("string", { defaultValue: false }),
    goalType: _emberData.default.attr("string", { defaultValue: 'goal_attainment_scaling' }),
    targets: _emberData.default.attr(),
    thresholdData: _emberData.default.attr(),
    startDate: _emberData.default.attr("date"),
    endDate: _emberData.default.attr("date"),

    user: _emberData.default.belongsTo("user", { inverse: null }),
    participant: _emberData.default.belongsTo("participant", { polymorphic: true }),

    filteredThresholds: Ember.computed('thresholdData', function () {
      var _this = this;

      return function () {
        return _this.get("thresholdData");
      };
    }),

    // Problems scales should be inverted so that they have the same
    // 'upwards is good' semantics as goals. This is untidy but we aim
    // to do a refresh of the goals functionality anyway.
    maxValue: Ember.computed('thresholdData', function () {
      var _this2 = this;

      if (!this.get('thresholdData')) {
        return undefined;
      }

      if (this.get('goalType') === 'top_problem') {
        return function () {
          return Math.min.apply(Math, _toConsumableArray(_this2.get('thresholdData').mapBy('min_value')));
        };
      } else {
        return function () {
          return Math.max.apply(Math, _toConsumableArray(_this2.get('thresholdData').mapBy('max_value')));
        };
      }
    }),
    minValue: Ember.computed('thresholdData', function () {
      var _this3 = this;

      if (!this.get('thresholdData')) {
        return undefined;
      }

      if (this.get('goalType') === 'top_problem') {
        return function () {
          return Math.max.apply(Math, _toConsumableArray(_this3.get('thresholdData').mapBy('max_value')));
        };
      } else {
        return function () {
          return Math.min.apply(Math, _toConsumableArray(_this3.get('thresholdData').mapBy('min_value')));
        };
      }
    })
  });
});