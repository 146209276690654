define('frontend/pods/components/reporting/graphs/time-series/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showTitle: true,
    graphType: Ember.computed('graphConfig.type', function () {
      return this.getWithDefault('graphConfig.type', 'line');
    }),

    dateFormatMap: {
      day: 'll',
      week: 'll',
      month: 'MMM YYYY',
      year: 'YYYY'
    },

    // Chart.js does not do a good job at automatically upscaling the size, because we send data in weeks rather
    // than months to get better granularity, which is not seen as a 'major' time unit so is not automatically selected.
    // So we override the unit in some specific cases to get a nicer graph.
    calculateUnits: function calculateUnits(interval, values) {
      if (interval === 'month') {
        if (values.length > 15) {
          return 'quarter';
        } else {
          return 'month';
        }
      } else if (interval === 'week') {
        if (values.length > 20) {
          return 'quarter';
        } else if (values.length > 10) {
          return 'month';
        } else {
          return 'week';
        }
      } else {
        return null;
      }
    },


    hasData: Ember.computed.notEmpty('data'),

    chartOptions: Ember.computed("data", "comparisonData", function () {
      var xValues = this.get("data").mapBy("x");
      var interval = this.get("interval");
      var dateFormatMap = this.dateFormatMap;
      var options = {
        scales: {
          xAxes: [{
            type: 'time',
            // The timestamps we provide are at the start of intervals, but actually represent whole intervals.
            // If we do not set the minUnit to the interval, it thinks there is missing data and makes bar graphs
            // very sparse.
            time: {
              round: interval,
              minUnit: interval,
              unit: this.calculateUnits(interval, xValues)
            },
            ticks: {
              autoSkip: true
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }

          }]
        },
        tooltips: {
          callbacks: {
            title: function title(items, obj) {
              var activeItem = items[0];
              var currentObj = obj.datasets[activeItem.datasetIndex].data[activeItem.index];

              var startDate = (0, _moment.default)(currentObj.originalX ? currentObj.originalX : currentObj.x);

              return startDate.format(dateFormatMap[interval]);
            }
          }
        },
        elements: {
          point: {
            pointStyle: 'line'
          }
        }
      };

      if (Ember.isEmpty(this.get("comparisonData"))) {
        options.legend = false;
      }

      return options;
    }),

    chartData: Ember.computed("data", "comparisonData", function () {
      var mainData = this.get("data");
      var comparisonData = this.get("comparisonData");

      var datasets = [{
        label: 'This period',
        order: 0,
        data: mainData,
        borderColor: '#37a',
        fill: null,
        backgroundColor: '#6ad',
        cubicInterpolationMode: 'monotone' // Ensure that 'cumulative' graphs don't go down if flat.
      }];

      if (comparisonData) {
        datasets.addObject({
          label: 'Comparison Period',
          order: 1,
          data: comparisonData,
          fill: null,
          pointStyle: 'dash',
          borderDash: [10],
          borderColor: '#ddcc77',
          cubicInterpolationMode: 'monotone'
        });
      }

      return {
        datasets: datasets
      };
    })
  });
});