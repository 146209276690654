define('frontend/pods/components/provider/assessment/common/table-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isTableHeader: Ember.computed('question', function () {
      return this.get('question.questionType') === 'table' || this.get('question.subtype') === 'table';
    })
  });
});