define("frontend/pods/components/scale-creation/array-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    array: Ember.computed("model", "property", function () {
      return this.get("model." + this.get('property'));
    }),
    collection: Ember.computed('array.@each', {
      get: function get() {
        var array = this.get("array");

        if (!array) {
          return null;
        }

        return array.map(function (item) {
          return {
            value: item
          };
        });
      }
    }),
    _updateArray: function _updateArray(collection) {
      // Ember doesn't like doing this on primitives, so we can't just overwrite the original array each time.
      var newItems = collection.mapBy('value');

      var existingArray = this.get('array');

      existingArray.clear();
      existingArray.addObjects(newItems);
    },

    actions: {
      addRow: function addRow() {
        var array = this.get("array");
        if (!array) {
          array = this.get("model").set(this.get("property"), []);
          this.notifyPropertyChange('array');
        }
        array.addObject(null);
      },
      deleteRow: function deleteRow(row) {
        this.get("collection").removeObject(row);
        this._updateArray(this.get('collection'));
      },
      updateArray: function updateArray() {
        this._updateArray(this.get('collection'));
      }
    }
  });
});