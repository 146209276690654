define('frontend/models/assessment/computed-value', ['exports', 'ember-data', 'frontend/models/application', 'frontend/mixins/computed-value-type'], function (exports, _emberData, _application, _computedValueType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_computedValueType.default, {
    value: _emberData.default.attr("number"),
    thresholdClass: _emberData.default.attr('string'),
    thresholdMnemonic: _emberData.default.attr('string'),
    totalQuestions: _emberData.default.attr('number'),
    unansweredQuestions: _emberData.default.attr('number'),
    scale: _emberData.default.belongsTo('assessment/scale', { async: true }),
    patient: _emberData.default.belongsTo('patient', { async: true }),
    report: _emberData.default.belongsTo('assessment/report', { async: true }),
    answers: _emberData.default.hasMany('assessment/answer', { async: true }),
    properties: _emberData.default.attr('object'),
    domain: _emberData.default.attr('string'),
    population: _emberData.default.belongsTo('assessment/population', { async: true }),
    declinedQuestions: _emberData.default.attr('number'),
    user: _emberData.default.belongsTo('user'),
    participant: _emberData.default.belongsTo('participant', { polymorphic: true }),
    resultValid: _emberData.default.attr('boolean'),

    scaleUserPair: Ember.computed(function () {
      return this.belongsTo('scale').id() + '-' + this.belongsTo('user').id();
    }),

    // If it's not a screener, i.e. it has no adaptive logic, this is always 'false'.
    metScreenThreshold: Ember.computed('properties', function () {
      return this.get('properties.threshold_met');
    }),
    scaleWasOpen: Ember.computed('report', 'metScreenThreshold', function () {
      return this.get('scale.measureType') !== 'norse' || this.get('metScreenThreshold') || this.get('scale.baseMnemonic') === 'alliance' || //hack for now to keep warning from appearing -- this will be tied to other things later 
      !this.get('report.offeredPatientScales') || //to preserve backwards compatibility so reports appear correctly before we started tracking offeredScales
      this.get('report.offeredPatientScales').indexOf(this.get('scale.mnemonic')) >= 0;
    }),

    translatedScaleNameWithParticipant: Ember.computed('scale.translatedShortname', 'user.name', 'isPatientReported', function () {
      if (this.get('isPatientReported')) {
        return this.get("scale.translatedShortname");
      } else {
        return this.get("scale.translatedShortname") + ' (' + this.get("user.name") + ')';
      }
    }),

    translatedScaleNameWithParticipantReport: Ember.computed('scale.translatedSynopsisName', 'user.name', 'isPatientReported', function () {
      if (this.get('isPatientReported')) {
        return this.get("scale.translatedSynopsisName");
      } else {
        return this.get("scale.translatedSynopsisName") + ' (' + this.get("user.name") + ' reporting)';
      }
    }),

    translatedCHCScaleNameWithParticipant: Ember.computed('scale.translatedCHCShortname', 'user.name', 'isPatientReported', function () {
      if (this.get('isPatientReported')) {
        return this.get("scale.translatedCHCShortname");
      } else {
        return this.get("scale.translatedCHCShortname") + ' (' + this.get("user.name") + ')';
      }
    }),

    translatedCHCShortname: Ember.computed('scale.translatedCHCShortname', function () {
      if (this.get("scale.translatedCHCShortname")) {
        return this.get("scale.translatedCHCShortname");
      } else {
        return '';
      }
    })
  });
});