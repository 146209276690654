define('frontend/pods/widgets/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      window.location = transition.intent.url;
    }
  });
});