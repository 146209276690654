define('frontend/serializers/user', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      role: { serialize: false },
      roles: { serialize: false },
      name: { serialize: false },
      isInternal: { serialize: false },
      institute: { serialize: false },
      userHelpState: { serialize: false },
      patients: { serialize: false },
      userType: { serialize: false },
      provider: { serialize: false },
      patient: { serialize: false },
      activeInvitation: { serialize: false },
      pendingSessions: { serialize: false },
      allowsEmail: { serialize: false },
      allowsText: { serialize: false },
      notificationPreferenceWithDefault: { serialize: false },
      newReportSwitchingEnabled: { serialize: false }
    }
  });
});