define('frontend/models/drilldown-metric', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    path: _emberData.default.attr("string"),
    shortcode: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("string"),
    endDate: _emberData.default.attr("string"),
    preset: _emberData.default.attr("string"),
    comparisonStartDate: _emberData.default.attr("string"),
    comparisonEndDate: _emberData.default.attr("string"),
    comparisonPreset: _emberData.default.attr("string"),
    flagIds: _emberData.default.attr(),
    instituteConditionIds: _emberData.default.attr(),
    includeGraph: _emberData.default.attr('boolean'),
    breakdown: _emberData.default.attr("string"),
    scale: _emberData.default.attr("string"),
    scaleScorerId: _emberData.default.attr("string"),
    scaleScorer: _emberData.default.belongsTo('assessment/scaleScorer'),

    sort: _emberData.default.attr('string'),
    sortDirection: _emberData.default.attr('string'),
    limit: _emberData.default.attr('number'),

    headers: _emberData.default.attr(),

    reportEntityTreeNodes: _emberData.default.hasMany('reportEntityTreeNode')
  });
});