define('frontend/pods/components/survey/taking-local/page-components/contact-details/component', ['exports', 'frontend/mixins/survey/question-component-mixin', 'ember-rapid-forms/mixins/input-errors', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'frontend/mixins/survey/scrollable'], function (exports, _questionComponentMixin, _inputErrors, _emberCpValidations, _validationHelper, _scrollable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationOptions = {
    contactDetailsEmail: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'email' }),
    contactDetailsPhoneNumber: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'phone' })
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  //Note that this component is not intended for use in bulk-editing mode
  exports.default = Ember.Component.extend(_questionComponentMixin.default, _scrollable.default, {
    tagName: 'div',
    classNames: ['question-wrapper', 'full-width'],
    scrollableSelector: '.question-content',
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      //create the response items, and set their defaults to any existing info
      var contactDetailInfo = this.getWithDefault('response.invite.paramData.contactDetails', {});

      var isConfirmingDetails = contactDetailInfo.email || contactDetailInfo.phoneNumber;

      var notificationPreference = contactDetailInfo.notificationPreference || contactDetailInfo.notificationDefault;

      var model = this.get("model");

      if (!model) {
        var emailChecked = ['both', 'email'].includes(notificationPreference);
        var textChecked = ['both', 'text'].includes(notificationPreference);
        model = Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
          contactDetailsEmail: contactDetailInfo.email,
          contactDetailsPhoneNumber: contactDetailInfo.phoneNumber,
          contactDetailsPref: contactDetailInfo.notificationPreference,
          email: emailChecked,
          text: textChecked
        }).create(Ember.getOwner(this).ownerInjection());
        this.set("model", model);
      }

      var responseItems = Ember.A();

      this.get("response.currentShownQuestions").map(function (question) {
        var responseItem = _this._createResponseSetForQuestion(question, _this.get('module'), true, false); // we do not support this in bulk mode.

        if (responseItem.get("value")) {
          model.set(question.shortname, responseItem.get("value"));
        }

        responseItems.addObject({
          shortname: question.shortname,
          responseItem: responseItem
        });

        if (_this.get("trackTime")) {
          // Start tracking the new question
          if (responseItem) {
            responseItem.startTrackingTime();
          }
        }
      });

      this.setProperties({ responseItems: responseItems, model: model, isConfirmingDetails: isConfirmingDetails });
    },
    _getPreferenceFromCheckboxes: function _getPreferenceFromCheckboxes() {
      var _get$getProperties = this.get("model").getProperties('email', 'text'),
          email = _get$getProperties.email,
          text = _get$getProperties.text;

      if (email && text) {
        return 'both';
      } else if (email) {
        return 'email';
      } else if (text) {
        return 'text';
      } else {
        return 'none';
      }
    },


    actions: {
      emailStartEditing: function emailStartEditing() {
        this.set("model.email", true);
      },
      textStartEditing: function textStartEditing() {
        this.set("model.text", true);
      },
      submitAnswers: function submitAnswers() {
        var _this2 = this;

        this.set("model.contactDetailsPref", this._getPreferenceFromCheckboxes());
        this.get("responseItems").forEach(function (obj) {
          _this2._submitAnswer(obj.responseItem, _this2.get("model").get(obj.shortname));
        });

        this.sendAction('next');

        return true;
      }
    },

    _submitAnswer: function _submitAnswer(item, value) {
      item.set("value", value);
      if (this.get("trackTime")) {
        item.stopTrackingTime();
      }

      if (this.get("saveOnSubmit")) {
        // There is a bug in ember data <= 2.14.1 which means that any persisted deleted records stop the save of other
        // records. Therefore for now, don't save any records we already know are deleted.
        // TODO: remove after we upgrade to ember 2.14.1
        if (item.get("isDeleted") && !item.get("hasDirtyAttributes")) {
          return;
        }

        item.saveWithDebounce();
      }
    }
  });
});