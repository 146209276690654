define('frontend/pods/components/provider/patient-flags/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['flag-icons'],
    classNameBindings: ['invertColors'],
    isShowingFlagModal: false,
    allowModal: true,
    editText: 'edit',
    preload: Ember.inject.service(),
    screen: Ember.inject.service(),
    activeFlags: Ember.computed('flagIds.[]', 'flags.[]', function () {
      var flagIds = this.getWithDefault("flagIds", []);

      if (!flagIds) {
        return [];
      }

      return this.getWithDefault("flags", []).filter(function (flag) {
        return flagIds.indexOf(flag.get('id')) >= 0;
      });
    }),

    modalContainerClassNames: Ember.computed("screen.isCompressed", function () {
      return this.get("screen.isCompressed") ? 'full-screen-modal' : undefined;
    }),

    modalOverlayClassNames: Ember.computed("screen.isCompressed", function () {
      return this.get("screen.isCompressed") ? 'full-screen-overlay' : undefined;
    }),

    modalTargetAttachment: Ember.computed("screen.isCompressed", function () {
      return this.get("screen.isCompressed") ? 'none' : 'center';
    }),

    modalTranslucentOverlay: Ember.computed("screen.isCompressed", function () {
      return !this.get("screen.isCompressed");
    }),

    // If we get passed a patient current session, start the async patient request to get the full record.
    patientRecord: Ember.computed("patient", "patientCurrentSession.patient", function () {
      return this.get("patient") || this.get("patientCurrentSession.patient");
    }),

    actions: {
      toggleFlagDialog: function toggleFlagDialog() {
        this.toggleProperty('isShowingFlagModal');
      }
    }
  });
});