define('frontend/models/billing/charge-item-code', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    description: _emberData.default.attr('string')
  });
});