define('frontend/pods/provider/reports/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    preload: Ember.inject.service(),
    afterModel: function afterModel() {
      // Nothing on the actual page uses them, but this saves a long load when
      // clicking on the first report.
      this.get('preload').ensurePreloads();
    }
  });
});