define('frontend/models/reporting/report-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    preset: null,
    startTime: null,
    endTime: null,
    comparisonPreset: null,
    comparisonStartTime: null,
    comparisonEndTime: null,
    breakdown: null,
    scale: null,
    scaleScorer: null,
    provider: null,
    organization: null,
    flagIds: [],
    instituteConditionIds: [],
    sort: null,
    sortDirection: null,
    limit: null,
    path: null
  });
});