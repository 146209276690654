define("frontend/pods/components/provider/patients/edit-form/component", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    tagName: "",
    classNames: ["edit-form"],
    noHipaa: _environment.default.noHipaa,

    isInvalid: Ember.computed.alias("patient.validations.isInvalid"),
    genderOptions: Ember.A([{ id: "M", name: "Male" }, { id: "F", name: "Female" }, { id: "O", name: "Other" }]),
    genderIdentityOptions: Ember.A([{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "non_binary", name: "Non-Binary" }, { id: "transgender_male", name: "Transgender Male" }, { id: "transgender_female", name: "Transgender Female" }, { id: "non_disclose", name: "Did Not Disclose" }, { id: "other", name: "Other" }]),
    languageOptions: Ember.A([{ id: "en", name: "English" }, { id: "es", name: "Spanish" }]),
    raceOptions: Ember.A([{ id: "2028-9", name: "Asian" }, { id: "2054-5", name: "Black or African American" }, { id: "1002-5", name: "Native American or Alaska Native" }, { id: "2076-8", name: "Native Hawaiian or Pacific Islander" }, { id: "2106-3", name: "White" }, { id: "2131-1", name: "Other" }]),
    // This needs to be kept in sync with the server. Preferably we'd use JSON API
    // to cross this barrier.
    // We're keeping the null option here as select to not confuse people, but it
    // really means "inherit from institute".
    timezoneOptions: Ember.A([{ id: null, name: "-select-" }, { id: "America/Chicago", name: "America/Chicago" }, { id: "America/Denver", name: "America/Denver" }, {
      id: "America/Indiana/Indianapolis",
      name: "America/Indiana/Indianapolis"
    }, { id: "America/Juneau", name: "America/Juneau" }, { id: "America/Los_Angeles", name: "America/Los Angeles" }, { id: "America/New_York", name: "America/New York" }, { id: "America/Phoenix", name: "America/Phoenix" }]),
    // Note this is currently hard coded for the Mclean research pilot. Can be expanded as necessary.
    researchPrimaryDiagnosisOptions: Ember.A([{ id: "Depression", name: "Depression" }, { id: "Anxiety", name: "Anxiety" }]),

    showMrn: Ember.computed("institute.configuration.hideMrn", function () {
      return !this.get("institute.configuration.hideMrn");
    }),

    existingPatientTestChange: Ember.computed("patient.isNew", "patient.changedAttributes", "patient.isText", function () {
      if (this.get("patient.isNew")) {
        return false;
      }

      return !!this.get("patient").changedAttributes().isTest;
    }),

    showLegalFirstName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("legal_first_name");
    }),

    showLegalLastName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("legal_last_name");
    }),

    showLegalName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("legal_name");
    }),

    showPreferredFirstName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("preferred_first_name");
    }),

    showPreferredLastName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("preferred_last_name");
    }),

    showPreferredName: Ember.computed("institute.configuration.enabledNameFields", function () {
      return this.get("institute.configuration.enabledNameFields").includes("preferred_name");
    }),

    _doSubmit: function _doSubmit() {
      var action = this.get("submitAction");
      if (typeof action === "function") {
        return action();
      } else {
        console.warn("No action function was passed to edit-form");
      }
    },


    actions: {
      submit: function submit() {
        if (this.get("existingPatientTestChange")) {
          this.set("showTestChangeWarning", true);
          return new Ember.RSVP.Promise(function (resolve) {
            resolve();
          });
        } else {
          return this._doSubmit();
        }
      },
      submitNoTestCheck: function submitNoTestCheck() {
        return this._doSubmit();
      }
    }
  });
});