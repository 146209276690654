define('frontend/models/assessment/goal-answer', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    goal: _emberData.default.belongsTo('assessment/goal', { async: true }),
    report: _emberData.default.belongsTo('assessment/report', { async: true }),
    user: _emberData.default.belongsTo('user', { async: true }),

    value: _emberData.default.attr("number"),
    answerDate: _emberData.default.attr('date')
  });
});