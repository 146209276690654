define('frontend/models/assessment/etr-curve', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // For now, just make this an in-memory object and stuff it with data.
  // This only contains a curve for Steve smith, assumed to be starting at
  var curveData = [{
    sessionNumber: 1,
    mean: 21,
    high: 22,
    low: 20
  }, {
    sessionNumber: 2,
    mean: 20,
    high: 22,
    low: 18
  }, {
    sessionNumber: 3,
    mean: 18,
    high: 22,
    low: 16
  }, {
    sessionNumber: 4,
    mean: 16,
    high: 19,
    low: 14
  }, {
    sessionNumber: 5,
    mean: 13,
    high: 19,
    low: 10
  }, {
    sessionNumber: 6,
    mean: 9,
    high: 16,
    low: 5
  }, {
    sessionNumber: 7,
    mean: 6,
    high: 13,
    low: 1
  }, {
    sessionNumber: 8,
    mean: 4,
    high: 8,
    low: 0
  }, {
    sessionNumber: 9,
    mean: 4,
    high: 7,
    low: 0
  }, {
    sessionNumber: 10,
    mean: 3,
    high: 7,
    low: 0
  }, {
    sessionNumber: 11,
    mean: 3,
    high: 6,
    low: 0
  }, {
    sessionNumber: 12,
    mean: 1,
    high: 5,
    low: 0
  }, {
    sessionNumber: 13,
    mean: 1,
    high: 5,
    low: 0
  }, {
    sessionNumber: 14,
    mean: 1,
    high: 4,
    low: 0
  }, {
    sessionNumber: 15,
    mean: 1,
    high: 3,
    low: 0
  }, {
    sessionNumber: 16,
    mean: 1,
    high: 2,
    low: 0
  }];

  exports.default = Ember.Object.extend({
    name: 'Severe Depression Response',
    curveData: curveData,

    sessionData: Ember.computed('measuredValues.[]', 'curveData.[]', function () {
      return _lodash.default.zip(this.get("measuredValues").toArray(), this.get('curveData').toArray()).flatMap(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            value = _ref2[0],
            curve = _ref2[1];

        if (!curve || !value) {
          return [];
        }

        return [{
          sessionNumber: curve.sessionNumber,
          date: value.get('report.patientSession.targetDate'),
          value: value.get('value'),
          mean: curve.mean,
          high: curve.high,
          low: curve.low,
          onTrack: value.get('value') <= curve.high
        }];
      });
    })
  });
});