define('frontend/pods/components/provider/assessment/report-v2/custom-measures/measure-item/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    scaleMnemonic: Ember.computed('scales', function () {
      return this.get('scale.mnemonic');
    }),

    computedValues: Ember.computed('reports', function () {
      var _this = this;

      var reports = this.getWithDefault('reports', []);

      var filtered = reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get("scale.id") === _this.get('scale.id') && cv.get("value");
        });
      });
      return _lodash.default.flatten(filtered);
    }),

    activeComputedValue: null,

    actions: {
      setComputedValue: function setComputedValue(cv) {
        this.set('activeComputedValue', cv);
      }
    }

  });
});