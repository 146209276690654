define('frontend/pods/provider/billing/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultHistoricalParams = {
    shortcode: 'billable_charge_drilldown',
    preset: 'allTime',
    includeGraph: true,
    comparisonPreset: 'none'
  };

  var defaultEfficiencyParams = {
    shortcode: 'charge_efficiency_drilldown',
    preset: 'allTime',
    includeGraph: true,
    comparisonPreset: 'none'
  };

  exports.default = Ember.Route.extend({
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),

    queryParams: {
      path: {}
    },

    historicalDataRequest: function historicalDataRequest(params) {
      return this.get('store').createRecord('drilldown-metric', Ember.merge(defaultHistoricalParams, { path: params.path })).save();
    },
    efficiencyDataRequest: function efficiencyDataRequest(params) {
      return this.get('store').createRecord('drilldown-metric', Ember.merge(defaultEfficiencyParams, { path: params.path })).save();
    },
    model: function model(params) {
      var reimbursementSummary = this.get('store').createRecord('billing/reimbursementSummaryRequest', { path: params.path }).save();
      return Ember.RSVP.hash({
        reimbursementSummary: reimbursementSummary,
        historicalData: this.historicalDataRequest(params),
        efficiencyData: this.efficiencyDataRequest(params)
      });
    }
  });
});