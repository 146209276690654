define('frontend/pods/components/provider-admin/configuration-superseding-values/expanded-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'config-expanded-row',
    actions: {
      onEntityTreeNodeSelected: function onEntityTreeNodeSelected(node) {
        this.get('actionHandler')('onEntityTreeNodeSelected', node);
      }
    }
  });
});