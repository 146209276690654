define('frontend/services/session-user', ['exports', 'lodash', 'frontend/adapters/application'], function (exports, _lodash, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    raven: Ember.inject.service(),
    preload: Ember.inject.service(),

    togglePrivacyMode: function togglePrivacyMode() {
      if (sessionStorage.getItem('privacyMode')) {
        sessionStorage.removeItem('privacyMode');
      } else {
        sessionStorage.setItem('privacyMode', true);
      }

      this.notifyPropertyChange('privacyMode');
    },


    privacyMode: Ember.computed(function () {
      return sessionStorage.getItem('privacyMode');
    }),

    currentUser: Ember.computed("session.data.authenticated.id", function () {
      var userId = this.get('session.data.authenticated.id');
      var userType = this.get('session.data.authenticated.user_type');
      var email = this.get('session.data.authenticated.email');
      var include = userType;

      if (userType === "provider") {
        include = include + ",patient,user-help-state,provider.organizations,provider.organization-subtrees";
      } else if (userType === 'internal') {
        include = "user-help-state";
      }

      if (!Ember.isEmpty(userId)) {
        // Update the userContext whenever the session changes.
        this.get("raven").callRaven("setUserContext", {
          id: userId,
          type: userType,
          email: email
        });

        if (userType === 'related_person') {
          return this.get('store').findRecord('user', userId, { include: "related-people" });
        } else if (userType === 'internal') {
          return this.get('store').findRecord('user', userId, { include: include });
        } else if (userType !== 'kiosk') {
          return this.get('store').findRecord('user', userId, { include: include });
        } else {
          return null;
        }
      }
    }),

    currentUserName: Ember.computed("currentUser.name", "impersonatingUser.name", function () {
      if (this.get("impersonatingUser.name")) {
        return '<span class="fa fa-eye"></span>&nbsp;' + this.get("impersonatingUser.name");
      }

      return this.get("currentUser.name");
    }),

    // If we're impersonating a user, we pull it out of local storage.
    // Note we don't do any triggers on it having been updated directly in local storage etc
    impersonatingUserId: Ember.computed(function () {
      return localStorage.getItem('impersonatingUserId');
    }),

    impersonatingUser: Ember.computed("impersonatingUserId", function () {
      if (!this.get("impersonatingUserId")) {
        return null;
      }

      return this.get('store').findRecord('user', this.get("impersonatingUserId"));
    }),

    // PII Level is a concept that lets us reduce the amount of visible PII. It is currently not supported in
    // ember, so right now we just check
    piiLevel: Ember.computed(function () {
      return localStorage.getItem('piiLevel');
    }),

    piiSupported: Ember.computed("piiLevel", function () {
      var piiLevel = this.get("piiLevel");

      return !piiLevel || piiLevel === "full_access";
    }),

    // If we think there's a session test its validity against keepalive and silently invalidate the
    // session if it fails. This prevents preload from sending out several requests that will fail
    // before invalidating the session and redirecting to the login form.
    //
    // This is primarily used by the application route as the very first step in the initial
    // application "boot".
    validateSession: function validateSession() {
      var _this = this;

      if (!this.get("session.isAuthenticated")) {
        return Ember.RSVP.resolve();
      }

      var beforeSend = function beforeSend(xhr) {
        var data = _this.get('session.data.authenticated');
        (0, _application.setAuthenticationHeaders)(xhr, data);
      };

      return new Ember.RSVP.Promise(function (resolve) {
        var onFailure = function onFailure(jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises

          if (jqXHR.status === 498 || jqXHR.status === 499) {
            console.log('Session validation failed');
            _this.silentLogout();
          }

          resolve();
        };

        return $.ajax({
          url: "/api/keepalive",
          dataType: "json",
          beforeSend: beforeSend
        }).then(resolve, onFailure);
      });
    },


    activeProvider: Ember.computed.readOnly("currentUser.provider"),

    activeOrganizations: Ember.computed("currentUser.provider.organizationSubtrees", "session.data.authenticated.user_type", "preload.organizations", function () {
      if (this.get("session.data.authenticated.user_type") === 'internal') {
        return this.get("preload.organizations");
      } else {
        return this.get("currentUser.provider.organizationSubtrees");
      }
    }),

    activePatient: Ember.computed.readOnly("currentUser.patient"),

    activeRelatedPerson: Ember.computed("currentUser.relatedPeople", "session.data.authenticated.active_role_id", function () {
      var id = "session.data.authenticated.active_role_id";

      if (id) {
        return this.get("currentUser.relatedPeople").findBy('id', id);
      } else {
        return null;
      }
    }),

    permissionMap: Ember.computed('session.data.authenticated', function () {
      var perms = this.get('session.data.authenticated.scoped_permissions');
      var permMap = {};
      _lodash.default.each(perms, function (perm) {
        permMap[perm.substring(0, perm.indexOf(':'))] = perm.substring(perm.indexOf(':') + 1);
      });
      return permMap;
    }),

    hasPermission: function hasPermission(permissionName) {
      return this.get('session.data.authenticated.is_internal') || !!this.get('permissionMap')[permissionName];
    },
    hasPermissions: function hasPermissions(permissionNames) {
      var _this2 = this;

      return _lodash.default.some(permissionNames, function (name) {
        return _this2.hasPermission(name);
      });
    },
    hasRole: function hasRole(roleName) {
      return this.get('session.data.authenticated.is_internal') || this.getWithDefault("session.data.authenticated.roles", []).indexOf(roleName) >= 0;
    },
    hasAnyRole: function hasAnyRole(roleNames) {
      var _this3 = this;

      return _lodash.default.some(roleNames, function (name) {
        return _this3.hasRole(name);
      });
    },
    logout: function logout() {
      var _this4 = this;

      if (this.get("session.isAuthenticated")) {
        // Note that you need to be authenticated to sign out. This is to prevent DOS attacks where people without
        // a token can log others out.
        $.ajax({
          url: "/api/sign_out",
          type: "DELETE",
          beforeSend: function beforeSend(xhr) {
            var data = _this4.get('session.data.authenticated');
            (0, _application.setAuthenticationHeaders)(xhr, data);
          }
        });
        this.get("session").invalidate();
      }
    },
    silentLogout: function silentLogout() {
      this.set("session.silentInvalidate", true);
      this.logout();
    },


    // Convenience security methods

    allowScheduling: Ember.computed(function () {
      return this.hasPermission('scheduleAppointment');
    }),

    allowPatientEdit: Ember.computed(function () {
      return this.hasPermission('editPatient');
    })
  });
});