define("frontend/services/keepalive", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set("enabled", false);
      this.set("allowInTesting", false);
      this.set("idlePeriod", 5 * 60000);
      this.activityDetected = false;
    },
    start: function start() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          timeout = _ref.timeout,
          onActivity = _ref.onActivity,
          onTimeout = _ref.onTimeout;

      if (!this.get("enabled")) {

        if (timeout) {
          this.set("idlePeriod", timeout);
        }

        this.set("enabled", true);
        this.activityDetected = false;

        this.registerActivityHelpers();

        var timerId = setInterval(this.checkKeepalive.bind(this), this.get("idlePeriod"));

        this.set("timerId", timerId);
      }

      this.set("onActivity", onActivity);
      this.set("onTimeout", onTimeout);
    },
    stop: function stop() {
      this.set("enabled", false);
      this.activityDetected = false;
      clearInterval(this.get("timerId"));
    },
    registerActivityHelpers: function registerActivityHelpers() {
      var _this = this;

      // Do not register these in test mode as they will cause
      // 'calling set on destroyed objects' after the test run has
      // finished
      if (Ember.testing && !this.get("allowInTesting")) {
        return;
      }

      $("body").on("mousemove.keepalive", function () {
        _this.activityDetected = true;
        _this.unregisterActivityHelpers();
      });

      $("body").on("touchstart.keepalive", function () {
        _this.activityDetected = true;
        _this.unregisterActivityHelpers();
      });

      $("body").on("keyup.keepalive", function () {
        _this.activityDetected = true;
        _this.unregisterActivityHelpers();
      });
    },
    unregisterActivityHelpers: function unregisterActivityHelpers() {
      $("body").off("mousemove.keepalive");
      $("body").off("touchstart.keepalive");
      $("body").off("keyup.keepalive");
    },
    checkKeepalive: function checkKeepalive() {
      if (this.get("activityDetected")) {
        console.log("Activity detected");
        if (this.get("onActivity")) {
          this.get("onActivity")();
        }
      } else {
        console.log("No activity");
        if (this.get("onTimeout")) {
          this.get("onTimeout")();
        }
      }
      this.set("activityDetected", false);
      this.unregisterActivityHelpers();
      this.registerActivityHelpers();
    },
    willDestroy: function willDestroy() {
      this.unregisterActivityHelpers();
      clearTimeout(this.get("timerId"));
    }
  });
});