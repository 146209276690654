define('frontend/mixins/metrics-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    // tracks a metric. Convenience method to schedule it at the right time
    // so it triggers after the loading screen etc has transitioned.
    // Pass in a function which will run to get the correct params.
    trackMetric: function trackMetric(paramsObj) {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var params = paramsObj;

        if (typeof paramsObj === 'function') {
          params = paramsObj.call(_this);
        }

        _this.get("metrics").trackEvent(params);
      });
    },


    // Sometimes you want to track the metric right now. E.g.
    // if you're about to log out or do something that would otherwise
    // invalidate the context.
    trackMetricNow: function trackMetricNow(paramsObj) {

      var params = paramsObj;

      if (typeof paramsObj === 'function') {
        params = paramsObj.call(this);
      }

      this.get("metrics").trackEvent(params);
    }
  });
});