define('frontend/pods/components/provider/assessment/participant-scale-chooser-v2/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/drawer-enabled', 'frontend/pods/components/provider/assessment/scale-info-drawer/component'], function (exports, _rollbackMixin, _drawerEnabled, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, _rollbackMixin.default, _component.ScaleDrawerMixin, {
    classNames: ['participant-scale-chooser'],
    help: Ember.inject.service(),
    rollbackProperties: ['report.reportScales', 'reportScaleParticipants', 'report.invitations'],
    showCompletedAssessmentWarning: Ember.computed("report.invitations.@each.status", function () {
      return this.getWithDefault("report.invitations", []).any(function (invitation) {
        var status = invitation.get("status");

        return status === 'complete' || status === 'begun';
      });
    }),

    reportScales: Ember.computed.alias('report.reportScales'),

    activeReportScales: Ember.computed.filterBy('manualReportScales', 'isDeleted', false),

    manualReportScales: Ember.computed.filterBy('independentReportScales', 'manual', true),

    independentReportScales: Ember.computed.filterBy('reportScales', 'independent', true),

    selectedScales: Ember.computed('activeReportScales.[]', function () {
      return this.get("activeReportScales").mapBy('scale');
    }),

    anyScaleHasMismatch: Ember.computed('reportScales.[]', function () {
      return this.getWithDefault("reportScales", []).any(function (rs) {
        return rs.get('scaleDemographicMismatch');
      });
    }),

    focusedScaleSelected: Ember.computed('focusedScale.scale', 'selectedScales.[]', function () {
      return this._scaleIsSelected(this.get('focusedScale.scale'));
    }),

    _scaleIsSelected: function _scaleIsSelected(scale) {
      return this.get("selectedScales").mapBy('id').includes(Ember.get(scale, "id"));
    },
    _toggleScale: function _toggleScale(scale) {
      var report = this.get("report");

      if (report.content) {
        report = report.content;
      }

      report.toggleScale(scale);

      var currentfocusedScale = this.get('focusedScale.scale');

      if (currentfocusedScale) {
        this.set('focusedScale.selected', this._scaleIsSelected(currentfocusedScale));
      }
    },
    _setScaleFocus: function _setScaleFocus(scale) {
      var _this = this,
          _arguments = arguments;

      this.get('focusedScale').setProperties({
        scale: scale, showActions: true,
        selected: this._scaleIsSelected(scale),
        onScaleChange: function onScaleChange() {
          _this._toggleScale.apply(_this, _arguments);
        } });
    },


    actions: {
      showScaleBrowser: function showScaleBrowser() {
        this.set("showScaleBrowser", true);
        this.send('drawer', '#scaleChooserSelectorDrawer');
        Ember.run.later(function () {
          $('.globalSearch input').focus();
        });
      },
      hideScaleBrowser: function hideScaleBrowser() {
        this.setProperties({
          'focusedScale.scale': null,
          showScaleBrowser: false
        });
      },
      focusScale: function focusScale(scale) {
        this._setScaleFocus(scale);
      },
      unfocusScale: function unfocusScale() {
        this.set("focusedScale", null);
        this.send('drawer', '#scaleChooserInfoDrawer', '.site-overlay.overlay-level-2');
      },
      onScaleChange: function onScaleChange(scale) {
        this._toggleScale(scale);
      },
      scaleDetailSelected: function scaleDetailSelected(scale) {
        this._setScaleFocus(scale);
        // if (this.get('isDrawerShown')) {
        //   // fixes race/conflict condition between drawers
        //   this.set('isDrawerShown', false);
        // }
        // this.send('drawer', '#scaleChooserInfoDrawer', '.site-overlay.overlay-level-2');
      }
    }
  });
});