define('frontend/models/patient', ['exports', 'frontend/models/participant', 'ember-data', 'moment', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'frontend/models/severity-mixin', 'frontend/models/entity'], function (exports, _participant, _emberData, _moment, _emberCpValidations, _validationHelper, _severityMixin, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var validationOptions = {
    legalLastName: (0, _emberCpValidations.validator)('presence-or', { presence: true, dependentKeys: ['preferredLastName'] }),
    preferredLastName: (0, _emberCpValidations.validator)('presence-or', { presence: true, dependentKeys: ['legalLastName'] }),
    email: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('international-phone-number', { allowBlank: true }),
    educationYears: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      integer: true
    }),
    lastSsn: {
      validators: [(0, _emberCpValidations.validator)('requiredPatientField'), (0, _emberCpValidations.validator)('length', {
        is: 4,
        allowBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: true,
        integer: true
      })]
    },
    dobString: {
      validators: [(0, _emberCpValidations.validator)('requiredPatientField'), (0, _emberCpValidations.validator)('date', {
        before: 'now',
        precision: 'day',
        format: 'MM/DD/YYYY',
        allowBlank: true,
        errorFormat: 'MM/DD/YYYY'
      })]
    }
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  exports.Validations = Validations;
  exports.default = _participant.default.extend(Validations, _validationHelper.default, _severityMixin.default, {
    intl: Ember.inject.service(),

    entityType: 'patient',

    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    preferredFirstName: _emberData.default.attr(),
    preferredLastName: _emberData.default.attr(),
    preferredPronouns: _emberData.default.attr(),
    legalFirstName: _emberData.default.attr(),
    legalLastName: _emberData.default.attr(),
    legalName: _emberData.default.attr(),
    preferredName: _emberData.default.attr(),
    orderedName: _emberData.default.attr('string', { readOnly: true }),
    name: _emberData.default.attr('string', { readOnly: true }),
    mrn: _emberData.default.attr(),
    isTest: _emberData.default.attr('boolean'),
    gender: _emberData.default.attr('string'),
    genderIdentity: _emberData.default.attr('string'),
    genderIdentityFhir: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    notificationPreference: _emberData.default.attr('string'),
    dob: _emberData.default.attr('dateonly'),
    lastSsn: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    demographics: _emberData.default.attr(),
    role: _emberData.default.attr('string'), // Used when patient logs into kiosk as their 'role'
    patientSessions: _emberData.default.hasMany('patientSessions', { async: true, inverse: 'patient' }),
    sessionsToday: _emberData.default.hasMany('patientSessions', { async: true, inverse: null }),
    relatedPeople: _emberData.default.hasMany('relatedPeople', { async: true, inverse: 'patient' }),
    currentStats: _emberData.default.attr('object'),
    identifiers: _emberData.default.attr('object'),

    timezone: _emberData.default.attr('string'),

    careUnitChildMemberships: _emberData.default.hasMany('careUnitMembership', { async: true, inverse: null }),
    careUnitParentMemberships: _emberData.default.hasMany('careUnitMembership', { async: true, inverse: null }),

    // Research columns
    race: _emberData.default.attr('string'),
    educationYears: _emberData.default.attr('number'),
    researchPrimaryDiagnosis: _emberData.default.attr('string'),
    researchOtherDiagnoses: _emberData.default.attr('string'),
    researchOther: _emberData.default.attr('string'),

    allowsEmail: _emberData.default.attr("boolean"),
    allowsText: _emberData.default.attr("boolean"),
    enableNotifications: _emberData.default.attr('boolean', { defaultValue: true }),

    institute: _emberData.default.belongsTo('institute', { async: true }),

    provider: _emberData.default.belongsTo('provider', { async: true, inverse: 'patients' }),

    user: _emberData.default.belongsTo('user', { inverse: 'patient' }),

    // These are only the client participants - the patient themselves and related people.
    clientParticipants: _emberData.default.hasMany('participant'),
    participantType: "patient",

    careEpisodes: _emberData.default.hasMany("careEpisode", { inverse: 'patient' }),
    activeCareEpisodes: _emberData.default.hasMany("careEpisode", { inverse: null }),

    patientConditions: _emberData.default.hasMany("patientCondition", { inverse: 'patient' }),

    patientStat: _emberData.default.belongsTo('patientStat'),

    patientFlags: _emberData.default.hasMany('patientFlag'),

    hasEmrLink: Ember.computed('identifiers', function () {
      return this.getWithDefault('identifiers.length', 0) > 0;
    }),

    legalFullName: Ember.computed('legalName', 'legalFirstName', 'legalLastName', 'email', function () {
      var _getProperties = this.getProperties('legalName', 'legalFirstName', 'legalLastName'),
          legalName = _getProperties.legalName,
          legalFirstName = _getProperties.legalFirstName,
          legalLastName = _getProperties.legalLastName;

      if (legalName) {
        return legalName;
      } else if (legalFirstName && legalLastName) {
        return legalFirstName + ' ' + legalLastName;
      } else if (legalFirstName) {
        return '' + legalFirstName;
      }
    }),

    preferredFullName: Ember.computed('preferredName', 'preferredFirstName', 'preferredLastName', 'email', function () {
      var _getProperties2 = this.getProperties('preferredName', 'preferredFirstName', 'preferredLastName'),
          preferredName = _getProperties2.preferredName,
          preferredFirstName = _getProperties2.preferredFirstName,
          preferredLastName = _getProperties2.preferredLastName;

      if (preferredName) {
        return preferredName;
      } else if (preferredFirstName && preferredLastName) {
        return preferredFirstName + ' ' + preferredLastName;
      } else if (preferredFirstName) {
        return '' + preferredFirstName;
      }
    }),

    // Choose the primary name that's available
    primaryFirstName: Ember.computed('preferredFirstName', 'legalFirstName', function () {
      var _getProperties3 = this.getProperties('preferredFirstName', 'legalFirstName'),
          preferredFirstName = _getProperties3.preferredFirstName,
          legalFirstName = _getProperties3.legalFirstName;

      if (preferredFirstName) {
        return preferredFirstName;
      } else {
        return legalFirstName;
      }
    }),

    // Only display if the preferred name AND legal name are present
    secondaryFirstName: Ember.computed('preferredFirstName', 'legalFirstName', function () {
      var _getProperties4 = this.getProperties('preferredFirstName', 'legalFirstName'),
          preferredFirstName = _getProperties4.preferredFirstName,
          legalFirstName = _getProperties4.legalFirstName;

      if (preferredFirstName && legalFirstName) {
        return legalFirstName;
      }
    }),

    // Choose the primary name that's available
    primaryLastName: Ember.computed('preferredLastName', 'legalLastName', function () {
      var _getProperties5 = this.getProperties('preferredLastName', 'legalLastName'),
          preferredLastName = _getProperties5.preferredLastName,
          legalLastName = _getProperties5.legalLastName;

      if (preferredLastName) {
        return preferredLastName;
      } else {
        return legalLastName;
      }
    }),

    // Only display if the preferred name AND legal name are present
    secondaryLastName: Ember.computed('preferredLastName', 'legalLastName', function () {
      var _getProperties6 = this.getProperties('preferredLastName', 'legalLastName'),
          preferredLastName = _getProperties6.preferredLastName,
          legalLastName = _getProperties6.legalLastName;

      if (preferredLastName && legalLastName) {
        return legalLastName;
      }
    }),

    fullName: Ember.computed('preferredFirstName', 'preferredLastName', 'legalFirstName', 'legalLastName', 'email', function () {
      var _getProperties7 = this.getProperties('preferredFirstName', 'preferredLastName', 'legalFirstName', 'legalLastName', 'email'),
          preferredFirstName = _getProperties7.preferredFirstName,
          preferredLastName = _getProperties7.preferredLastName,
          legalFirstName = _getProperties7.legalFirstName,
          legalLastName = _getProperties7.legalLastName,
          email = _getProperties7.email;

      var firstName = preferredFirstName || legalFirstName;
      var lastName = preferredLastName || legalLastName;

      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (firstName) {
        return '' + firstName;
      } else if (email) {
        return '' + email;
      } else {
        return "Anonymous Patient";
      }
    }),
    age: Ember.computed('dob', function () {
      var dob = this.get("dob");

      if (dob) {
        return (0, _moment.default)().diff(dob, 'years');
      } else {
        return "Unknown";
      }
    }),
    isChild: Ember.computed('age', function () {
      return this.get('age') < 18;
    }),
    notificationPrefereceWithoutContactInformation: Ember.computed('email', 'phoneNumber', 'notificationPreference', function () {
      if (this.get('notificationPreference') == 'email' && !this.get('email')) {
        return true;
      } else if (this.get('notificationPreference') == 'text' && !this.get('phoneNumber')) {
        return true;
      } else {
        return false;
      }
    }),
    notificationPreferenceNone: Ember.computed('notificationPreference', function () {
      return this.get('notificationPreference') == 'none';
    }),
    housing: "Unknown",
    dobString: Ember.computed('dob', {
      get: function get() {
        if (this.get('dob')) {
          return (0, _moment.default)(this.get('dob')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("dob", (0, _moment.default)(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    yobString: Ember.computed('dob', {
      get: function get() {
        if (this.get('dob')) {
          return (0, _moment.default)(this.get('dob')).format("YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("dob", (0, _moment.default)(value, "YYYY").toDate());
          return value;
        }
      }
    }),

    genderString: Ember.computed('gender', function () {
      var intl = this.get('intl');
      return intl.t('model.gender.' + this.get('gender'));
    }),

    hasGenderIdentity: Ember.computed('genderIdentity', 'genderIdentityFhir', function () {
      return this.get('genderIdentity') || this.get('genderIdentityFhir');
    }),

    // Use, preferentially: the free text provided by genderIdentity, followed by
    // the enum provided by genderIdentityFhir
    genderIdentityString: Ember.computed('genderIdentity', function () {
      if (this.get('genderIdentity')) {
        return this.get('genderIdentity');
      }

      var intl = this.get('intl');
      if (!this.get('genderIdentityFhir')) {
        return null;
      }

      return intl.t('model.genderIdentity.' + this.get('genderIdentityFhir'));
    }),

    // Grab a best guess at a default provider.
    defaultProvider: Ember.computed('activeCareEpisodes.@each.careEpisodeProvider', function () {
      if (this.get("provider")) {
        return this.get("provider");
      }

      return this.get("activeCareEpisodes").mapBy('careEpisodeProviders').flatten().mapBy('provider').get("firstObject");
    }),

    experimentDecisions: _emberData.default.hasMany('experimentDecision', { inverse: 'target', async: true }),

    // Assessment relations
    defaultScales: _emberData.default.hasMany('assessment/scale', { async: true }),
    assessmentReports: _emberData.default.hasMany('assessment/reports', { async: true }),
    lastAssessmentReport: _emberData.default.belongsTo('assessment/report', { async: true, inverse: null }),
    assessmentPatientScaleTargets: _emberData.default.hasMany('assessment/patientScaleTarget', { async: true }),

    // Scheduling relations
    schedulingAppointments: _emberData.default.hasMany('scheduling/appointments', { async: true }),

    // Billing relations
    billingCoverages: _emberData.default.hasMany('billing/coverage'),

    // return our best guess at the current most recent active care episode
    currentActiveCareEpisode: Ember.computed('careEpisodes.[]', function () {
      // hack for now -- just return the first
      // Also, for reasons which I can't determine, if you instead go via 'activeCareEpisodes' it will permanently
      // set the care episodes collection incorrectly, ignoring inactive care episodes.
      return this.getWithDefault('careEpisodes', []).filterBy('status', 'active').get("firstObject");
    }),

    rootPath: Ember.computed('id', function () {
      return 'patient/' + this.get('id');
    }),

    // These fields are only used by Cambridge Public Schools (see institute.configuration.cambridgeSchoolsMode)
    grade: _emberData.default.attr('string'),
    learningCommunity: _emberData.default.attr('string'),
    hasIep: _emberData.default.attr('string'),
    // If we name this attr the conventional way, has504Plan, ember incorrectly serializes it as 'has504-plan', which
    // the server rejects. So instead, we force the correct hyphenization here and then make a computed attr that's
    // spelled normally.
    'has-504-plan': _emberData.default.attr('string'),
    has504Plan: Ember.computed('has-504-plan', function () {
      return this.get('has-504-plan');
    }),
    homeroomTeacher: _emberData.default.attr('string'),
    mllStatus: _emberData.default.attr('string'),
    lowIncome: _emberData.default.attr('boolean'),
    disabilityCategories: _emberData.default.attr('string'),
    caseManager: _emberData.default.attr('string'),
    homeLanguage: _emberData.default.attr('string'),

    toEntity: function toEntity() {
      return (0, _entity.fromModel)(this, this.get('store'));
    }
  });
});