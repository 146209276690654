define('frontend/helpers/t-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    reports: Ember.inject.service(),

    compute: function compute(keys, interpolations) {
      var intl = this.get('intl');
      var code = this.get('reports.activeReport');
      var key = ['reporting.reps', code].concat(keys).join('.');

      return intl.t(key, interpolations);
    }
  });
});