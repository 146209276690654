define("frontend/serializers/patient-session", ["exports", "frontend/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      assessmentReport: { serialize: false },
      schedulingAppointment: { serialize: false },
      status: { serialize: false },
      reportAvailable: { serialize: false },
      statusUpdatedAt: { serialize: false },
      patientSendEmailAt: { serialize: false },
      patientDateEmailed: { serialize: false },
      patientSendTextAt: { serialize: false },
      patientDateTexted: { serialize: false },
      sessionNumber: { serialize: false },
      sessionNumberOfType: { serialize: false }
    },

    /*
     * The JSON API spec does not as of v1.0 support multiple simulataneous operations, which includes compound create operations
     * like is necessary for session creation. In order to support this for now, there is a custom specification where a 'nested data'
     * parameter gets encoded on the frontend side, and then reinflated on the backend. All interactions otherwise are done via the correct
     * infrastructure. The intent is that once JSONAPI supports multi-put, we can transparently turn off the hack. Note that in order to
     * work properly, the adapter needs to be changed to include all these sidecar'd resources, otherwise they will not be correctly updated
     * after the save operation.
     *
     * The one wrinkle here is deleted records: we don't delete them directly, but rather
     * the back end will remove them once they are no longer used. Because we don't actually
     * issue a delete, they will stay in state isDeleted=true locally, so we have to filter them out.
     */
    serialize: function serialize(snapshot) {
      // Example format
      // const customFormat = {
      //   schedulingAppointment: {
      //     startDate: null,
      //     endDate: null,
      //     minutesDuration: null,
      //     comment: null,
      //   },
      //   assessmentReport: {
      //     reportScales: [{
      //       id: "blah",
      //       scaleId: 'blah',
      //.      phase: "before",
      //       reportScaleParticipants: {
      //         id: "blah",
      //         userId: "bleh"
      //       }
      //     }]
      //   },
      //   careEpisode: { // Only if new, otherwise handled normally.
      //.    id: "bleh",
      //     periodStart: new Date(),
      //.    organizationId: "orgid"
      //   }
      // };
      var result = this._super.apply(this, arguments);
      var model = snapshot.record;
      var appointment = model.get("schedulingAppointment");
      var recurrence = model.get("schedulingRecurrence");
      var careEpisode = model.get("careEpisode");

      var nestedData = {};

      if (appointment) {
        nestedData.schedulingAppointment = appointment.getProperties("id", "comment", "startDate", "endDate", "minutesDuration", "source", "status");

        if (appointment.get("provider.id")) {
          nestedData.schedulingAppointment.providerId = appointment.get("provider.id");
        }
      }

      if (recurrence) {
        nestedData.schedulingRecurrence = recurrence.getProperties("id", "repeatMnemonic", "endDate");
      }

      var report = model.get("assessmentReport");

      if (report) {
        var reportScales = report.getWithDefault("reportScales", []).rejectBy('isDeleted').filterBy('isSelected').map(function (rs) {
          var scaleParticipants = rs.getWithDefault("scaleParticipants", []).rejectBy('isDeleted').map(function (rsp) {
            return {
              id: rsp.get("id"),
              userId: rsp.get("user.id"),
              participantId: rsp.get("participant.id"),
              invitationId: rsp.get("invitation.id")
            };
          });

          return {
            id: rs.get("id"),
            scaleId: rs.get("scale.id"),
            defaultParticipants: rs.get("defaultParticipants"),
            phase: rs.get("phase"),
            format: rs.get("format"),
            manual: rs.get("manual"),
            disabled: rs.get("disabled"),
            scaleParticipants: scaleParticipants
          };
        });

        var invitations = report.getWithDefault("invitations", []).rejectBy('isDeleted').map(function (invitation) {
          return {
            id: invitation.get("id"),
            userId: invitation.get("user.id"),
            participantId: invitation.get("participant.id"),
            sendEmailAt: invitation.get("sendEmailAt"),
            sendTextAt: invitation.get("sendTextAt"),
            sendNotifications: invitation.get("sendNotifications"),
            email: invitation.get("email"),
            phoneNumber: invitation.get("phoneNumber"),
            phase: invitation.get("phase"),
            format: invitation.get("format"),
            emailMessage: invitation.get('emailMessage'),
            textMessage: invitation.get('textMessage')
          };
        });

        nestedData.assessmentReport = {
          id: report.get("id"),
          reportScales: reportScales,
          invitations: invitations
        };
      }

      if (careEpisode && careEpisode.get("isNew")) {
        var careEpisodeSerialized = typeof careEpisode.serialize === 'function' ? careEpisode.serialize() : careEpisode.content.serialize();

        nestedData.newCareEpisode = {
          id: careEpisode.get("id"),
          periodStart: careEpisode.get("periodStart"),
          organizationId: careEpisode.get("organization.id"),
          saveNested: true,
          nestedData: careEpisodeSerialized.data.attributes['nested-data']
        };
      }

      result.data.attributes['nested-data'] = nestedData;
      result.data.attributes['save-nested'] = true;

      return result;
    }
  });
});