define('frontend/pods/components/provider/reports/report-summary/report-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    institute: Ember.inject.service()
  });
});