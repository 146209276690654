define('frontend/models/related-person', ['exports', 'frontend/models/participant', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _participant, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)("presence", true),
    lastName: (0, _emberCpValidations.validator)("presence", true),
    relationship: (0, _emberCpValidations.validator)("presence", true),
    email: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'phone' })
  });

  exports.Validations = Validations;
  exports.default = _participant.default.extend(Validations, _validationHelper.default, {
    patient: _emberData.default.belongsTo('patient'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    relationship: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    notificationPreference: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', { inverse: 'relatedPeople' }),
    participantType: Ember.computed.alias("relationship"),
    active: _emberData.default.attr('boolean', { defaultValue: true }),
    allowsText: _emberData.default.attr('boolean'),
    allowsEmail: _emberData.default.attr('boolean'),
    fullName: Ember.computed('firstName', 'lastName', 'email', function () {
      var firstName = this.get('firstName');
      var lastName = this.get('lastName');
      var email = this.get('email');

      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (email) {
        return '' + email;
      } else {
        return "Anonymous Respondent";
      }
    }),
    name: Ember.computed.oneWay("fullName")
  });
});