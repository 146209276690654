define('frontend/serializers/configuration-value', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    }
  });
});