define('frontend/pods/components/scale-creation/translated-text/component', ['exports', 'ember-rapid-forms/mixins/input-component'], function (exports, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputComponent.default, {
    didReceiveAttrs: function didReceiveAttrs() {
      var property = this.get("property");
      if (!this.get('model.' + property)) {
        this.set('model.' + property, { en: null, es: null });
      }
    }
  });
});