define('frontend/pods/components/provider/patient-sessions/session-list-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('index') === 0) {
        this.set('record.isExpanded', true);
      }
    },

    filtered: Ember.computed('rowContext.activeProvider', 'rowContext.activeCareEpisode', 'record', function () {
      var record = this.get('record');

      if (record && record.content) {
        record = record.content;
      }

      var rowContext = this.getWithDefault('rowContext', {});
      if (rowContext.activeProvider && rowContext.activeProvider.get('id') !== record.belongsTo('provider').id()) {
        return true;
      }

      if (rowContext.activeCareEpisode && rowContext.activeCareEpisode.get('id') !== record.belongsTo('careEpisode').id()) {
        return true;
      }

      return false;
    }),
    actions: {
      expand: function expand() {
        // only works in chrome
        if (typeof event !== 'undefined') {
          //don't expand/contract on button clicks
          if ($(event.target).closest('a').length || $(event.target).closest('button').length) {
            return;
          }
        }

        this.set('record.isExpanded', !this.get('record.isExpanded'));
      }
    }
  });
});