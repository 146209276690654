define('frontend/utils/survey/survey-hooks', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
     collection of hooks that can be run at various places inside a survey.
     all hooks should take two arguments:
      1.  the response object
      2.  the paramData from the invite
  
      obviously, hooks are stupid powerful and not strongly typed, so they should be used sparingly and only with extensive test coverage
    */

  var surveyHooks = {
    runHook: function runHook(hookName) {
      if (this.HOOKS[hookName]) {
        var _HOOKS$hookName;

        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        (_HOOKS$hookName = this.HOOKS[hookName]).call.apply(_HOOKS$hookName, [this].concat(_toConsumableArray(args)));
      } else {
        throw "Unknown survey hook: " + hookName;
      }
    },
    _filterCAMSquestions: function _filterCAMSquestions(response, paramData) {
      var _this = this;

      var camsScales = paramData.camsScales;
      var openedScales = [];
      var surveyModule = response.get('allModules').findBy('mnemonic', 'camsMain');
      if (paramData.openScales) {
        openedScales.push.apply(openedScales, _toConsumableArray(paramData.openScales));
      }

      //now figure out the patient adjustment
      var patientAdjustment = this._computePatientAdjustmentFactor(response, camsScales);

      //add the triggered scales 
      for (var scaleName in camsScales) {
        var scaleTriggered = false;
        var scale = camsScales[scaleName];
        if (scale) {
          var answerValue = this._getTriggerAnswerForScale(response, scale);
          if (answerValue) {
            var thresholdOpen = parseFloat(scale.thresholdOpen);
            var scaleAdjustment = parseFloat(scale.patientAdjustment ? scale.patientAdjustment : 0);
            var adjustVal = patientAdjustment * scaleAdjustment;
            if (scale.isBeneficial) {
              scaleTriggered = answerValue <= Math.round(thresholdOpen - adjustVal);
            } else {
              scaleTriggered = answerValue >= Math.round(thresholdOpen + adjustVal);
            }
          }
        }
        if (scaleTriggered) {
          openedScales.push(scaleName);
        }
      }

      $.each(openedScales, function (index, scaleName) {
        if (camsScales[scaleName]) {
          _this._showScaleQuestions(surveyModule, camsScales[scaleName]);
        }
      });
      response.get('takingMetadata')['openedScales'] = openedScales;
    },
    _showScaleQuestions: function _showScaleQuestions(surveyModule, scale) {
      $.each(scale.questions, function (index, question) {
        // Temporary hack for now: put the question to display in all pages rather than finding the right one.
        surveyModule.get('pages').forEach(function (page) {
          page.get('questionsToDisplay')[question] = true;
        });
      });
    },
    _computeCamsScreenQAverage: function _computeCamsScreenQAverage(response, camsScales) {
      var screenQSum = 0;
      var screenQsAnswered = 0;
      for (var scaleName in camsScales) {
        var scale = camsScales[scaleName];
        if (scale) {
          var answerValue = this._getTriggerAnswerForScale(response, scale);
          if (answerValue) {
            screenQSum += scale.isBeneficial ? 8 - answerValue : answerValue;
            screenQsAnswered++;
          }
        }
      }
      return screenQSum / screenQsAnswered;
    },
    _getTriggerAnswerForScale: function _getTriggerAnswerForScale(response, scale) {
      if (!scale.triggerQuestion) {
        return null;
      }
      var answerValue = response.get('responseItemMap.' + scale.triggerQuestion + '.simpleValue');

      if (!answerValue) {
        answerValue = response.get('responseItemMap.norse#' + scale.triggerQuestion + '.simpleValue');
      }
      if (answerValue || answerValue === 0) {
        return parseInt(answerValue);
      }
      return null;
    },
    _computePatientAdjustmentFactor: function _computePatientAdjustmentFactor(response, camsScales) {
      var average = this._computeCamsScreenQAverage(response, camsScales);
      if (average >= 4) {
        return 2;
      } else if (average >= 3) {
        return 1;
      }
      return 0;
    },
    _createGoalPages: function _createGoalPages(module, response, data) {
      var _this2 = this;

      if (data.goals) {
        var byGoalType = _lodash.default.groupBy(data.goals, 'goalType');

        var pagesToRemove = module.content.pages.filter(function (page) {
          var goalType = page.properties.goalType;
          if (byGoalType[goalType]) {
            Ember.set(page, 'questions', byGoalType[goalType].map(function (goalData) {
              return _this2._createGoalQuestion(goalData);
            }));

            return false;
          } else {
            return true; // Do not use the page.
          }
        });

        module.content.pages.removeObjects(pagesToRemove);
      } else {
        module.content.pages.clear(); // No goals = no pages.
      }
    },
    _createGoalQuestion: function _createGoalQuestion(goal) {
      switch (goal.goalType) {
        case 'goal_attainment_scaling':
          return this._createGoalAttainmentScalingQuestion(goal);
        case 'top_problem':
          return this._createTopProblemQuestion(goal);
        case 'goal_likert':
          return this._createGoalLikertQuestion(goal);
        default:
          return null;
      }
    },
    _createGoalAttainmentScalingQuestion: function _createGoalAttainmentScalingQuestion(goal) {
      var goalOptions = [];
      for (var i = -2; i <= 2; i++) {
        goalOptions.push({
          id: i,
          title: {
            en: i.toString(),
            es: i.toString()
          },
          value: i.toString()
        });
      }

      return {
        base_type: 'Question',
        type: "RADIO",
        properties: {
          "option_sort": false,
          "required": false,
          "hidden": false,
          "orientation": "STACKED",
          "labels_right": true,
          "map_key": "radio-likert",
          "show_title": false,
          "likertHeaders": {
            "left": {
              "en": "below expected",
              "es": "nada"
            },
            "right": {
              "en": "better than expected",
              "es": "logrado"
            }
          }
        },
        title: {
          en: goal.text,
          es: goal.text
        },
        shortname: goal.mnemonic,
        options: goalOptions
      };
    },
    _createTopProblemQuestion: function _createTopProblemQuestion(goal) {
      var goalOptions = [];
      for (var i = 0; i <= 4; i++) {
        goalOptions.push({
          id: i,
          title: {
            en: i.toString(),
            es: i.toString()
          },
          value: i.toString()
        });
      }

      return {
        base_type: 'Question',
        type: "RADIO",
        properties: {
          "option_sort": false,
          "required": false,
          "hidden": false,
          "orientation": "STACKED",
          "labels_right": true,
          "map_key": "radio-likert",
          "show_title": false,
          "likertHeaders": {
            "left": {
              "en": "not a problem at all",
              "es": "no es problema en absoluto"
            },
            "right": {
              "en": "a very big problem",
              "es": "un gran problema"
            }
          }
        },
        title: {
          en: goal.text,
          es: goal.text
        },
        shortname: goal.mnemonic,
        options: goalOptions
      };
    },
    _createGoalLikertQuestion: function _createGoalLikertQuestion(goal) {
      var goalOptions = [];
      for (var i = 1; i <= 7; i++) {
        goalOptions.push({
          id: i,
          title: {
            en: i.toString(),
            es: i.toString()
          },
          value: i.toString()
        });
      }

      return {
        base_type: 'Question',
        type: "RADIO",
        properties: {
          "option_sort": false,
          "required": false,
          "hidden": false,
          "orientation": "STACKED",
          "labels_right": true,
          "map_key": "radio-likert",
          "show_title": false,
          "likertHeaders": {
            "left": {
              "en": "not at all"
            },
            "right": {
              "en": "completely"
            }
          }
        },
        title: {
          en: goal.text,
          es: goal.text
        },
        shortname: goal.mnemonic,
        options: goalOptions
      };
    },
    _createConsentQuestion: function _createConsentQuestion(consent) {
      var options = [{
        "id": 10001,
        "title": consent.agree_text,
        "value": "yes"
      }, {
        "id": 10002,
        "title": consent.decline_text,
        "value": "no"
      }];

      if (consent.allow_defer && consent.defer_text) {
        options.push({
          "id": 10003,
          "title": consent.defer_text,
          "value": "defer"
        });
      }

      return {
        base_type: 'Question',
        type: "RADIO",
        properties: {},
        title: consent.content,
        shortname: consent.mnemonic,
        options: options
      };
    },
    _createConsentPage: function _createConsentPage(consent) {
      return {
        properties: {
          component: "consent-form",
          data: consent
        },
        title: {
          en: "Consent Forms"
        },
        questions: [this._createConsentQuestion(consent)]
      };
    },
    _createConsentPages: function _createConsentPages(module, response, data) {
      var _this3 = this;

      if (data.consents) {
        var pages = data.consents.map(function (consent) {
          return _this3._createConsentPage(consent);
        });
        Ember.set(module.content, 'pages', pages);
      } else {
        module.content.pages.clear();
      }
    },
    _welcomeText: function _welcomeText(module, response, data) {
      if (data.text && data.text.welcome) {
        Ember.set(module.content.pages[0].questions[0], 'title', data.text.welcome);
      }
    },
    _crisisWarningText: function _crisisWarningText(module, response, data) {
      if (data.text && data.text.crisis_warning) {
        Ember.set(module.content.pages[0].questions[0], 'title', data.text.crisis_warning);
      }
    },
    _finishText: function _finishText(module, response, data) {
      if (data.text && data.text.finish) {
        Ember.set(module.content.pages[0].questions[0], 'title', data.text.finish);
      }
    },
    opgCamsAlliance: function opgCamsAlliance(module /*, response*/) {
      if (!module.mnemonic == 'camsAlliance') {
        return;
      }

      // Directly modify the text for some questions.
      module.content.pages.forEach(function (page) {
        page.questions.forEach(function (question) {
          switch (question.shortname) {
            case "understandTreatmentHelp":
              question.title.en = "I understand how coaching will help me";
              break;
            case "therapistUnderstandsMe":
              question.title.en = "I feel that my case manager understands me and understands why I am engaging in coaching now";
              break;
            case "therapistAcceptsAsPerson":
              question.title.en = "I feel that my case manager accepts me as a person";
              break;
            case "therapistTechniqueExerciseQuantity":
              question.title.en = "I would like my case manager to use more/fewer techniques and exercises";
              break;
            case "therapistHumorFormalQuantity":
              question.title.en = "I would like my case manager to show their personality and humour more / be more formal";
              break;
            case "therapistFeelingCognitionFocus":
              question.title.en = "I would like my case manager to focus more on my feelings / more on my thoughts";
              break;
            case "focusOnTherapistRelationship":
              question.title.en = "I would like my case manager to focus more on the relationship between us / less on the relationship";
              break;
          }
        });
      });
    }
  };

  surveyHooks.HOOKS = {
    /**
      parses the screener question responses and already open scales to show the appropriate CAMS questions
      */
    FILTER_CAMS: surveyHooks._filterCAMSquestions,
    CREATE_GOAL_PAGES: surveyHooks._createGoalPages,
    CREATE_CONSENT_PAGES: surveyHooks._createConsentPages,
    OPG_CAMS_ALLIANCE: surveyHooks._opgCamsAlliance,
    WELCOME_TEXT: surveyHooks._welcomeText,
    CRISIS_WARNING_TEXT: surveyHooks._crisisWarningText,
    FINISH_TEXT: surveyHooks._finishText
  };

  exports.default = surveyHooks;
});