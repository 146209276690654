define('frontend/pods/provider/assessment/invitation/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    breadCrumb: {},
    model: function model(params) {
      return this.store.findRecord('assessment/invitation', params.invitation_id, { reload: true, adapterOptions: { fieldset: 'all' }, include: 'patient,participant,report.patient-session' });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('report.patient.name'), linkable: true, isRaw: true, crumbClass: 'root-crumb' });
    }
  });
});