define('frontend/serializers/export/data-dump', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      institute: { serialize: false },
      status: { serialize: false },
      expiresAt: { serialize: false },
      createdAt: { serialize: false }, // never send server-side timestamps
      updatedAt: { serialize: false } // never send server-side timestamps
    }
  });
});