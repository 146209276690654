define('frontend/pods/logout/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      sessionStorage.clear();
      localStorage.clear();
    }
  });
});