define("frontend/helpers/score-to-bar-width", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var score = params[0];
    score = Math.max(score, -4);
    score = Math.min(score, 4);

    //compress the low ranges
    if (score < 0) {
      score = score / 2;
    }
    score = Math.abs(score);
    console.log("CONVERT: " + params[0] + " to " + score);
    return new Ember.Handlebars.SafeString(parseInt(score / 6.0 * 100.0));
  });
});