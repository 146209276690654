define('frontend/pods/components/internal/scales/scale-draft/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        var scale = this.get("scale");
        scale.updateRawContent();
        scale.set('publish', false);

        var success = function success() {
          _this.get('messages').success("Draft saved.");
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      },
      saveAndPublish: function saveAndPublish() {
        var _this2 = this;

        var scale = this.get("scale");
        scale.updateRawContent();
        var success = function success(result) {
          if (result.get("publishSuccess")) {
            _this2.get('messages').success("Draft saved and scale pushed to system");
          } else {
            _this2.get('messages').danger("Draft saved but scale publish has errors");
          }
        };

        var failure = function failure(reason) {
          _this2.get('messages').danger("Error updating scale: " + reason);
        };

        scale.set('publish', true);

        return scale.save().then(success).catch(failure);
      },
      sendForReview: function sendForReview() {
        var _this3 = this;

        var value = confirm("Send this scale for review? You will be unable to make further changes without beginning a new draft version");

        if (!value) {
          return Ember.RSVP.resolve();
        }

        var scale = this.get("scale");
        scale.updateRawContent();
        scale.set('publish', false);
        scale.set('statusEvent', 'send_for_review');

        var success = function success() {
          _this3.get('messages').success("Sent for review");
        };

        var failure = function failure(reason) {
          _this3.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      }
    }
  });
});