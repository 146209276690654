define('frontend/pods/provider/patients/show/report-v3/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      activeReportId: {},
      activeSessionId: {},
      careEpisodeId: {}
    },

    model: function model(queryParams) {
      var patient = this.modelFor("provider.patients.show");

      var activeReport = null;
      var activeSession = null;

      if (queryParams.activeReportId) {
        activeReport = this.store.findRecord('assessment/report', queryParams.activeReportId, { include: 'patient-session.care-episode' });
      }

      if (queryParams.activeSessionId) {
        activeSession = this.store.findRecord('patientSession', queryParams.activeSessionId, { include: 'care-episode' });
      }

      return Ember.RSVP.hash({
        patient: patient,
        activeReport: activeReport,
        activeSession: activeSession,
        careEpisodeId: queryParams.careEpisodeId
      });
    },
    afterModel: function afterModel(model, transition) {
      // Abort the transition so we never get a history item. If you don't do this, you end up in a permanent redirect
      // when trying to go backwards.
      transition.abort();

      if (model.careEpisodeId) {
        window.location = '/app/care-units/' + model.patient.get('id') + '/care-episodes/' + model.careEpisodeId + '/feedback-report';
      } else if (model.activeSession) {
        window.location = '/app/care-units/' + model.patient.get('id') + '/care-episodes/' + model.activeSession.get("careEpisode.id") + '/feedback-report';
      } else if (model.activeReport) {
        window.location = '/app/care-units/' + model.patient.get('id') + '/care-episodes/' + model.activeReport.get("patientSession.careEpisode.id") + '/feedback-report';
      } else {
        window.location = '/app/care-units/' + model.patient.get('id') + '/report';
      }
    }
  });
});