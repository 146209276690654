define('frontend/pods/components/provider/reports/outcomes-explorer-mock/outcome-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    outcome: Ember.computed('patient', 'outcomeType', function () {
      var outcomeType = this.get('outcomeType');
      return this.get('patient.outcomes')[outcomeType];
    })
  });
});