define('frontend/pods/provider/patients/show/sessions/show/index/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    preload: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this._super.apply(this, arguments);
      //return this.get("preload").ensure("scales");
    },
    model: function model() {
      return this.modelFor("provider.patients.show.sessions.show");
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.session.show" });
      }
    }
  });
});