define("frontend/pods/provider/sso-auth/route", ["exports", "frontend/pods/base-route", "frontend/mixins/application-route-session"], function (exports, _baseRoute, _applicationRouteSession) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_applicationRouteSession.default, {
    breadCrumb: null,
    queryParams: { ssoToken: {}, destination: {} },
    sessionUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    messages: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model(params) {
      return {
        token: params.ssoToken,
        destination: params.destination
      };
    },
    afterModel: function afterModel(serverParams) {
      var _this = this;

      // if we are already authenticated we should logout quietly
      // we need to re-auth anyway
      if (this.get("session").get("isAuthenticated")) {
        this.get("sessionUser").silentLogout();
      }

      Ember.set(this.get("session"), "noTransitionOnAuthenticate", true);
      this.get("session").authenticate("authenticator:sso", serverParams.token).then(function () {
        // If we have a destination we think we want to go to, set it now.
        if (serverParams.destination) {
          _this.set("session.targetUrl", serverParams.destination);
        }
        // ...then do any redirections.
        _this._redirectAfterAuthentication(true);
      }).catch(function () {
        _this.get("sessionUser").silentLogout();
        _this.get("messages").danger(_this.get("intl").t("provider.login.failed"));
      });
    }
  });
});