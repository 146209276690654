define('frontend/pods/components/internal/institutes/edit-scales-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    scaleData: Ember.computed('allInternalScales.[]', 'instituteScales.[]', function () {
      var allInternalScales = this.get("allInternalScales");
      var instituteScales = this.get("instituteScales");

      return allInternalScales.sortBy('translatedShortname').map(function (scale) {
        var instituteScale = instituteScales.findBy('internalScale.id', scale.get('id'));

        return {
          id: scale.get('id'),
          scale: scale,
          instituteScale: instituteScale
        };
      });
    }),

    toggleDisableAdministration: function toggleDisableAdministration(instituteScale, value) {
      var _this = this;

      var onSuccess = function onSuccess() {
        // Do nothing
      };

      var onFailure = function onFailure(error) {
        _this.get('messages').danger(error);
      };

      instituteScale.set("disableAdministration", value);

      return instituteScale.save().then(onSuccess, onFailure);
    },


    actions: {
      allowAdministration: function allowAdministration(instituteScale) {
        this.toggleDisableAdministration(instituteScale, false);
      },
      disableAdministration: function disableAdministration(instituteScale) {
        this.toggleDisableAdministration(instituteScale, true);
      },
      enableScale: function enableScale(scale) {
        var _this2 = this;

        var newRecord = this.get('store').createRecord('assessment/instituteScale', { institute: this.get('institute'), internalScale: scale.scale });

        var onSuccess = function onSuccess() {
          // Do nothing
        };

        var onFailure = function onFailure(error) {
          _this2.get('messages').danger(error);
          newRecord.destroyRecord();
        };

        return newRecord.save().then(onSuccess, onFailure);
      },
      disableScale: function disableScale(scale) {
        var _this3 = this;

        var result = confirm("If this scale has been used aleady, this may cause reports to break. Are you sure you want to do this?");

        if (result) {
          var onSuccess = function onSuccess() {
            // Do nothing
          };

          var onFailure = function onFailure(error) {
            _this3.get('messages').danger(error);
          };

          return scale.instituteScale.destroyRecord().then(onSuccess, onFailure);
        } else {
          return new Ember.RSVP.Promise(function (resolve) {
            resolve();
          });
        }
      }
    }
  });
});