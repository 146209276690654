define('frontend/models/billing/charge', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // We do not send this information down from the back end, so needs to be kept up to date here.
  var allowedEvents = {
    billed: ['mark_as_finalized'],
    billable: ['mark_as_billed', 'mark_as_not_billable']
  };

  exports.default = _application.default.extend({
    patientSession: _emberData.default.belongsTo('patientSession', { async: false }),
    coverage: _emberData.default.belongsTo('billing/coverage', { async: false }),
    payorContractCode: _emberData.default.belongsTo('billing/payorContractCode', { async: false }),
    payorContract: _emberData.default.belongsTo('billing/payorContract', { async: false }),
    chargeItems: _emberData.default.hasMany('billing/chargeItem', { async: false }),
    patient: _emberData.default.belongsTo('patient', { async: false }),
    provider: _emberData.default.belongsTo('provider', { async: false }),
    organization: _emberData.default.belongsTo('organization', { async: false }),
    chargeItemCode: _emberData.default.belongsTo('billing/chargeItemCode', { async: false }),
    institutePayor: _emberData.default.belongsTo('institutePayor', { async: false }),

    status: _emberData.default.attr('string'),
    statusEvent: _emberData.default.attr('string'),

    billableUnits: _emberData.default.attr('number'),
    billableAmountCents: _emberData.default.attr('number'),

    billedUnits: _emberData.default.attr('number'),
    billedAmountCents: _emberData.default.attr('number'),

    receivedUnits: _emberData.default.attr('number'),
    receivedAmountCents: _emberData.default.attr('number'),

    expectedUnits: _emberData.default.attr('number'),
    expectedAmountCents: _emberData.default.attr('number'),

    plannedUnits: _emberData.default.attr('number'),
    plannedAmountCents: _emberData.default.attr('number'),

    maxUnits: _emberData.default.attr('number'),
    maxAmountCents: _emberData.default.attr('number'),

    algorithmStatus: _emberData.default.attr('string'),

    submittedAt: _emberData.default.attr('dateonly'),
    receivedDate: _emberData.default.attr('dateonly'),
    dateOfService: _emberData.default.attr('dateonly'),

    allowedEvents: Ember.computed('status', function () {
      return allowedEvents[this.get('status')];
    }),

    sendStatusEvent: function sendStatusEvent(event) {
      this.set("statusEvent", event);

      return this.save();
    }
  });
});