define('frontend/models/care/alter-measurement-plan-request', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    effectiveDate: (0, _emberCpValidations.validator)('presence', true),
    measurementPlan: { validators: [(0, _emberCpValidations.validator)('belongsTo', true), (0, _emberCpValidations.validator)('presence', true)] },
    entityTreeNode: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    entityMeasurementPlan: _emberData.default.belongsTo('care/entityMeasurementPlan', { inverse: null }),
    measurementPlan: _emberData.default.belongsTo('care/measurementPlan', { inverse: null }),
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode', { inverse: null }),

    effectiveDate: _emberData.default.attr('date'),
    mode: _emberData.default.attr('string')
  });
});