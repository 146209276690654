define('frontend/pods/components/provider/assessment/report-v2/dsm-main/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/symptoms-explorer/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var scaleSectionsByMode = {
    dsm5Level1Adult: {
      dsm: {
        baseScales: ["dsm5Level1AdultDepression", "dsm5Level1AdultAnger", "dsm5Level1AdultMania", "dsm5Level1AdultAnxiety", "dsm5Level1AdultSomatic", "dsm5Level1AdultSuicide", "dsm5Level1AdultPsychosis", "dsm5Level1AdultSleep", "dsm5Level1AdultMemory", "dsm5Level1AdultRepetitive", "dsm5Level1AdultDissociation", "dsm5Level1AdultPersonality", "dsm5Level1AdultSubstanceUse"]
      }
    },
    dsm5Level1Adolescent: {
      dsm: {
        baseScales: ["dsm5Level1AdolescentSomatic", "dsm5Level1AdolescentSleep", "dsm5Level1AdolescentInattention", "dsm5Level1AdolescentDepression", "dsm5Level1AdolescentAnger", "dsm5Level1AdolescentIrritability", "dsm5Level1AdolescentMania", "dsm5Level1AdolescentAnxiety", "dsm5Level1AdolescentPsychosis", "dsm5Level1AdolescentRepetitive", "dsm5Level1AdolescentSubstanceUse", "dsm5Level1AdolescentSuicide"]
      }
    },
    dsm5Level1Child: {
      dsm: {
        baseScales: ["dsm5Level1ChildSomatic", "dsm5Level1ChildSleep", "dsm5Level1ChildInattention", "dsm5Level1ChildDepression", "dsm5Level1ChildAnger", "dsm5Level1ChildIrritability", "dsm5Level1ChildMania", "dsm5Level1ChildAnxiety", "dsm5Level1ChildPsychosis", "dsm5Level1ChildRepetitive", "dsm5Level1ChildSubstanceUse", "dsm5Level1ChildSuicide"]
      }
    }
  };

  exports.default = _component.default.extend({
    computedValueDomain: 'raw',
    graphDomains: ['raw'],
    scaleSections: Ember.computed('mode', function () {
      return scaleSectionsByMode[this.get("mode")];
    }),
    trendScales: Ember.computed('mode', 'scales', function () {
      var scalesToShow = this.get("scaleSections").dsm.baseScales;

      return this.getWithDefault('scalesToShow', []).filter(function (s) {
        return scalesToShow.indexOf(s.get("baseMnemonic")) >= 0;
      }).sortBy('baseMnemonic');
    })
  });
});