define("frontend/mixins/internal-route-mixin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    authenticationRoute: "provider.login",
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      sessionStorage.setItem("authenticationType", "provider");
      if (!this.get('session.isAuthenticated')) {
        transition.abort();
        this.set('session.attemptedTransition', transition);
        this.transitionTo(this.get('authenticationRoute'));
        return false;
      } else if (!this.get('session.data.authenticated.is_internal')) {
        // Silently log out, then hard redirect to the url given.
        transition.abort();
        this.get("sessionUser").logout();
        return false;
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});