define('frontend/pods/components/reporting/graphs/stacked-bar/component', ['exports', 'frontend/pods/components/reporting/columns/scale-value/component', 'frontend/pods/components/reporting/graphs/time-series/component'], function (exports, _component, _component2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component2.default.extend({
    showTitle: true,
    hasData: Ember.computed.notEmpty('data'),

    chartOptions: Ember.computed("data", function () {
      // They all have the same length, so we can just take the first mapping item and get the
      // items to work out what interval to use.
      var mapping = this.get('dataMapping');
      var xValues = this.get("data", [])[mapping[0].id];
      var interval = this.get("interval");
      var dateFormatMap = this.dateFormatMap;
      var options = {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              round: interval,
              minUnit: interval,
              unit: this.calculateUnits(interval, xValues)
            },
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        tooltips: {
          callbacks: {
            title: function title(items, obj) {
              var activeItem = items[0];
              var currentObj = obj.datasets[activeItem.datasetIndex].data[activeItem.index];

              var startDate = moment(currentObj.originalX ? currentObj.originalX : currentObj.x);

              return startDate.format(dateFormatMap[interval]);
            }
          }
        }
      };

      return options;
    }),

    chartData: Ember.computed("data", function () {
      var mainData = this.get("data");

      var datasets = this.get('dataMapping').map(function (stack) {
        return {
          label: stack.name,
          data: mainData[stack.id],
          backgroundColor: stack.color || _component.interpretationMap[stack.class]
        };
      });

      return {
        datasets: datasets,
        labels: []
      };
    })
  });
});