define('frontend/pods/components/scale-creation/question-option-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    nextIdNumber: Ember.computed('collection.@each.id', function () {
      return this._nextIdNumber();
    }),

    _nextIdNumber: function _nextIdNumber() {
      var collection = this.get("collection");

      if (collection && collection.length > 0) {
        var existingIds = collection.mapBy("id").compact();
        var existing = existingIds.length > 0 ? Math.max.apply(Math, _toConsumableArray(existingIds)) : 0;
        return existing + 1;
      } else {
        return 1;
      }
    },


    // While we are still upgrading, force everything to have an id when it comes in.
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._addMissingIds();
    },
    _addMissingIds: function _addMissingIds() {
      var _this = this;

      var collection = this.get("collection");

      if (collection) {
        collection.forEach(function (option) {
          if (!option.id) {
            option.id = _this._nextIdNumber();
          }
        });
      }
    },


    collection: Ember.computed("model", "property", function () {
      return this.get('model.' + this.get('property'));
    }),
    normalizedScoreOptions: [1, 2, 3, 4, 5, 6, 7],
    scoreOptionMap: {
      1: 'value1',
      2: 'value2',
      3: 'value3',
      4: 'value4',
      5: 'value5',
      6: 'value6',
      7: 'value7'
    },
    itemCodingOptions: ['mild', 'moderate', 'severe'],
    actions: {
      addRow: function addRow() {
        var collection = this.get("collection");
        if (!collection) {
          collection = Ember.set(this.get("model"), this.get("property"), Ember.A());
          this.notifyPropertyChange('collection');
        }

        collection.addObject(Ember.Object.create({
          id: this.get('nextIdNumber'),
          value: null,
          en: null,
          es: null,
          normalized_score: null,
          report_text: null
        }));

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      reverseScores: function reverseScores() {
        var newScores = this.get("collection").mapBy('normalized_score').reverse();
        this.get("collection").forEach(function (row, index) {
          Ember.set(row, 'normalized_score', newScores[index]);
        });
        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      deleteRow: function deleteRow(row) {
        this.get("collection").removeObject(row);
        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      updateScore: function updateScore(row, score) {
        Ember.set(row, 'normalized_score', score);
        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      updateItemCoding: function updateItemCoding(row, coding) {
        Ember.set(row, 'item_coding', coding);
        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    }
  });
});