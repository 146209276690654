define('frontend/mixins/assessment/question-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cachedText: null,
    cachedFullText: null,
    intl: Ember.inject.service(),
    isQuestionDetailShown: false,
    showFullQuestionText: false,

    questionDefaultText: Ember.computed('showFullQuestionText', 'questionText', 'questionFullText', function () {
      if (this.get("showFullQuestionText")) {
        return this.get("questionFullText");
      } else {
        return this.get("questionText");
      }
    }),

    questionText: Ember.computed('answer', 'cachedText', 'loaded', function () {
      var _this = this;

      var text = void 0;
      if (this.get('cachedText')) {
        text = this.get('cachedText');
      } else if (this.get('intl').exists('question.' + this.get('mnemonic') + '.report')) {
        text = this.get('intl').t('question.' + this.get('mnemonic') + '.report');
      } else {
        text = this.get('question.reportText');
        if (!text && this.get('loaded')) {
          // First try to peek it from the store
          var _getProperties = this.getProperties('mnemonic', 'questionDomain'),
              mnemonic = _getProperties.mnemonic,
              questionDomain = _getProperties.questionDomain;

          var result = this.store.peekAll('assessment/question').find(function (q) {
            // We intentionally use == so we can compare undefined == null as true.
            return q.get('shortname') === mnemonic && q.get('domain') == questionDomain;
          });

          if (result) {
            text = this.set('cachedText', result.get('reportText'));
          } else {
            //go get it from the store/server.
            //Eventually, we may want to consolidate all the unanswered question text queries into a single pull, but this will do for now since
            //it's only applicable to custom questions that don't have translations and are unanswered
            this.store.query('assessment/question', { filter: { shortname: this.get('mnemonic') } }).then(function (r) {
              //we have to do this indirect method of setting the value since otherwise the template won't update
              if (!r.objectAt(0)) {
                console.log("WARN: possible missing question mnemonic: " + _this.get('mnemonic'));
              } else {
                _this.set('cachedText', r.objectAt(0).get('reportText'));
              }
            });
          }
        }
      }
      if (text && text.substring && text.length > this.get("MAX_QUESTION_LENGTH")) {
        text = text.substring(0, this.get("MAX_QUESTION_LENGTH") - 3) + "...";
      }
      return text;
    }),

    questionFullText: Ember.computed('answer', 'cachedFullText', 'loaded', function () {
      var _this2 = this;

      var text = void 0;

      if (this.get('cachedFullText')) {
        return this.get('cachedFullText');
      }

      // Allow full translation overload.
      var overrideKey = 'question.' + this.get('mnemonic') + '.fullText';
      if (this.get('intl').exists(overrideKey)) {
        text = this.get('intl').t(overrideKey);
      } else {
        text = this.get('question.title');
      }

      if (!text && this.get('loaded')) {
        //go get it from the store/server.
        //Eventually, we may want to consolidate all the unanswered question text queries into a single pull, but this will do for now since
        //it's only applicable to custom questions that don't have translations and are unanswered
        this.store.query('assessment/question', { filter: { shortname: this.get('mnemonic') } }).then(function (r) {
          //we have to do this indirect method of setting the value since otherwise the template won't update
          if (!r.objectAt(0)) {
            console.log("WARN: possible missing question mnemonic: " + _this2.get('mnemonic'));
          } else {
            _this2.set('cachedFullText', r.objectAt(0).get('title'));
          }
        });
      }
      return text;
    })
  });
});