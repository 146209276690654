define('frontend/pods/components/provider/help/scale-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: 'help__scale-info',
    scaleDocs: {
      norse: { localDoc: 'cams' },
      ptpb: { localDoc: 'ptpb' },
      bam: { remoteDoc: 'https://www.mentalhealth.va.gov/providers/sud/docs/BAM_Scoring_Clinical_Guidelines_01-04-2011.pdf' },
      phq9: { remoteDoc: 'https://www.uspreventiveservicestaskforce.org/Home/GetFileByID/218' },
      gad7: { remoteDoc: 'https://www.integration.samhsa.gov/clinical-practice/GAD708.19.08Cartwright.pdf' },
      pcl: { remoteDoc: 'https://www.ptsd.va.gov/professional/assessment/adult-sr/ptsd-checklist.asp' },
      nsi: { remoteDoc: 'https://health.mil/Reference-Center/Forms/2015/04/30/Neurobehavioral-Symptom-Inventory-Form' },
      phq2: { remoteDoc: 'https://www.uspreventiveservicestaskforce.org/Home/GetFileByID/218' },
      brs: { remoteDoc: 'https://ogg.osu.edu/media/documents/MB%20Stream/Brief%20Resilience%20Scale.pdf' },
      phq9Adolescent: { remoteDoc: 'https://www.psychiatry.org/File%20Library/Psychiatrists/Practice/DSM/APA_DSM5_Severity-Measure-For-Depression-Child-Age-11-to-17.pdf' }
    },
    hasHelpDoc: Ember.computed('scale', function () {
      return !!this.get('scaleDocs.' + this.get('scale.mnemonic'));
    }),
    helpDocLink: Ember.computed('scale', function () {
      var key = this.get('scaleDocs.' + this.get('scale.mnemonic')).localDoc;
      if (key) {
        return '/images/manuals/' + key + "MeasureGuide.pdf";
      }
      return this.get('scaleDocs.' + this.get('scale.mnemonic')).remoteDoc;
    }),

    isLocalHelpDoc: Ember.computed('scale', function () {
      return !!this.get('scaleDocs.' + this.get('scale.mnemonic')).localDoc;
    }),

    hasAdminText: Ember.computed('scale', function () {
      return this.get('intl').t('provider.scales.' + this.get('scale.mnemonic') + '.adminInterval') && this.get('intl').t('provider.scales.' + this.get('scale.mnemonic') + '.adminInterval').indexOf('Missing T') < 0;
    }),

    takingMinutes: Ember.computed('scale', function () {
      var numMins = Math.round(this.getWithDefault('scale.estimatedTime', 300) / 60, 1);
      if (numMins === 0) {
        return '<1';
      }
      return numMins;
    })
  });
});