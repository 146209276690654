define('frontend/serializers/care/alter-measurement-plan-request', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      alteredPlans: { serialize: false }
    },
    /*
     * The JSON API spec does not as of v1.0 support multiple simulataneous operations, which includes compound create operations
     * like is necessary for session creation. In order to support this for now, there is a custom specification where a 'nested data'
     * parameter gets encoded on the frontend side, and then reinflated on the backend. All interactions otherwise are done via the correct
     * infrastructure. The intent is that once JSONAPI supports multi-put, we can transparently turn off the hack. Note that in order to
     * work properly, the adapter needs to be changed to include all these sidecar'd resources, otherwise they will not be correctly updated
     * after the save operation.
     *
     * The one wrinkle here is deleted records: we don't delete them directly, but rather
     * the back end will remove them once they are no longer used. Because we don't actually
     * issue a delete, they will stay in state isDeleted=true locally, so we have to filter them out.
     */
    serialize: function serialize(snapshot) {
      var result = this._super.apply(this, arguments);
      var model = snapshot.record;

      var measurementPlan = model.get('measurementPlan');

      var nestedData = {};
      var saveNested = false;

      if (measurementPlan && measurementPlan.get('isNew') || model.get('planModified')) {
        saveNested = true;
        var measurementPlanAttributes = typeof measurementPlan.serialize === 'function' ? measurementPlan.serialize() : measurementPlan.content.serialize();
        measurementPlanAttributes.data.id = measurementPlan.get('id');

        nestedData['new-measurement-plan'] = measurementPlanAttributes.data;
      }

      result.data.attributes['nested-data'] = nestedData;
      result.data.attributes['save-nested'] = saveNested;

      return result;
    }
  });
});