define('frontend/pods/provider/patients/show/care-episodes/show/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: {},
    model: function model(params) {
      return this.store.findRecord('careEpisode', params.care_episode_id, { include: 'care-episode-providers,organization' });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('name'), linkable: true, isRaw: true, crumbClass: 'root-crumb' });
    }
  });
});