define('frontend/pods/components/internal/scales/awaiting-publication/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    actions: {
      publish: function publish() {
        var _this = this;

        var scale = this.get("scale");
        scale.updateRawContent();
        scale.set('statusEvent', 'mark_for_publication');

        var success = function success() {
          _this.get('messages').success("Scale published.");
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      }
    }
  });
});