define('frontend/pods/provider/assessment/invitation/edit/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: {},
    intl: Ember.inject.service(),
    queryParams: { returnUrl: {}, referrer: {} },
    model: function model(params) {
      var _this = this;

      var invitationId = this.modelFor("provider.assessment.invitation").get('id');
      var invitation = this.store.findRecord('assessment/invitation', invitationId, { include: 'patient,participant,report.patient-session,report-scales.scale' });
      var inviteBundle = this.store.find("survey/invite-bundle", invitationId);
      var dirtyChecker = Ember.Object.create({ preventTransition: false });

      invitation.then(function (inv) {
        if (!inv.get('referrer') && params.referrer) {
          inv.set('referrer', params.referrer);
          inv.save();
        }
      });

      return inviteBundle.then(function (bundle) {
        var response = bundle.get("response");

        if (response.get('id')) {
          return Ember.RSVP.hash({
            dirtyChecker: dirtyChecker,
            invitation: invitation,
            response: response
          });
        } else {
          return Ember.RSVP.hash({
            dirtyChecker: dirtyChecker,
            invitation: invitation,
            response: _this._createResponse(invitation)
          });
        }
      });
    },
    afterModel: function afterModel(model) {
      var intl = this.get('intl');
      var title = intl.t('provider.assessments.invitations.editResponse', { fullName: model.invitation.get('participant.name') });

      this.set('breadCrumb', { title: title, linkable: false, isRaw: true });
    },
    _createResponse: function _createResponse(invitation) {
      return this.store.createRecord('survey/takeSurveyRequest', {
        assessmentInvitationId: invitation.get("id")
      }).save().then(function (request) {
        return request.get("invite.response");
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // reset the return url on exit.
        controller.set('returnUrl', null);
      }
    },


    actions: {
      /**
       * @override: ember lifecycle
       */
      willTransition: function willTransition(transition) {
        if (this.controller.get("model.dirtyChecker.preventTransition") && !this.controller.get("model.dirtyChecker.confirmExit")) {
          transition.abort();
          this.controller.set("model.dirtyChecker.attemptedTransition", transition);

          // Keep the URL as it was.
          if (window.history) {
            window.history.forward();
          }

          return false;
        }
      }
    }
  });
});