define('frontend/pods/provider/assessment/invitation/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      var invitation = this.modelFor("provider.assessment.invitation");
      return this.transitionTo('provider.patients.show.sessions.show', invitation.get("patient.id"), invitation.get("report.patientSession.id"));
    }
  });
});