define('frontend/pods/components/provider/profile/notification-settings/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // We only want certain groups of people to have access to some of the subscriptions, but
  // we don't have time to actually make a system which does that. So for now, just hide them in the UI.
  var restrictedSubscriptions = ['weekly_adherence_email'];
  var fullAccessRoles = ['superuser', 'Sub Unit Supervisor', 'executive'];

  exports.default = Ember.Component.extend(_errorPrinter.default, {
    messages: Ember.inject.service(),
    subscribedSubjectIds: Ember.computed.mapBy('subscribedSubjects', 'id'),
    actions: {
      submit: function submit() {
        return this._updateSubscriptions();
      },
      itemChanged: function itemChanged(item) {
        this._markItemChanged(item);
      }
    },

    sessionUser: Ember.inject.service(),

    // Only show all subs to some access roles, as defined by fullAccessRoles above.
    // By hiding this we do end up in a circumstance where if they already have it selected
    // then they won't be able to change it, but this is acceptable at time of writing
    // as we have disabled all users of the wrong role having those subs.
    availableSubjects: Ember.computed('sessionUser', 'subscriptions', function () {
      var sessionUser = this.get("sessionUser");

      var subjects = this.get("subjects");

      if (fullAccessRoles.any(function (role) {
        return sessionUser.hasRole(role);
      })) {
        return subjects;
      }

      return subjects.reject(function (sub) {
        return restrictedSubscriptions.indexOf(sub.get("name")) >= 0;
      });
    }),

    _updateSubscriptions: function _updateSubscriptions() {
      var _this = this;

      var subjectIds = this.get("subscribedSubjects").mapBy('id');
      var user = this.get("user");

      var record = this.get("store").createRecord('updateUserSubscriptionsRequest', {
        subjectIds: subjectIds,
        user: user,
        notificationPreference: this.get('model.notificationPreference')
      });

      var onSuccess = function onSuccess() {
        _this.get("messages").success("Subscriptions updated successfully");
      };

      var onFailure = function onFailure(reason) {
        _this.get('messages').danger(_this.printError(reason));
      };

      return record.save().then(onSuccess, onFailure);
    },
    _markItemChanged: function _markItemChanged(item) {
      var selected = this.get("subscribedSubjects");

      var existing = selected.findBy('id', item.get("id"));

      if (existing) {
        this.get("subscribedSubjects").removeObject(existing);
      } else {
        this.get("subscribedSubjects").addObject(item);
      }
    },


    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      // We create a copy and set it rather than using a computed property so that we can modify it without modifying
      // the underlying collection until submit is pressed.
      this.set("subscribedSubjects", this.getWithDefault("userSubscriptions", []).filterBy('active').mapBy('subject'));

      if (!this.get('model')) {
        var model = Ember.Object.create({
          notificationPreference: this.get('user.notificationPreference')
        });
        this.set('model', model);
      }
    }
  });
});