define('frontend/pods/components/provider/assessment/report-v2/recent-changes/component', ['exports', 'frontend/utils/assessment/peabody'], function (exports, _peabody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['recent-changes'],

    currentPopulation: Ember.computed.oneWay('reportSettings.currentPopulation'),

    targeted: Ember.computed("reportComparison", "targetedScales", function () {
      var scaleIds = this.getWithDefault("targetedScales", []).map(function (st) {
        return st.get("id");
      });
      return this.getWithDefault("reportComparison.normedComparisons", []).filter(function (comparison) {
        return scaleIds.includes(comparison.get("scale.id"));
      });
    }),

    worsened: Ember.computed("reportComparison", function () {
      var shownPtpbMnemonics = _peabody.default.GRAPHED_SYMPTOM_MNEMONICS.concat(_peabody.default.GRAPHED_SKILL_MNEMONICS);
      return this.get("reportComparison.normedComparisons").filter(function (nc) {
        return nc.get('latestValue').isSignificantChangeFrom(nc.get('comparisonValue')) && nc.get('isWorsening') && (nc.get('scale.measureType') !== 'ptpb' || shownPtpbMnemonics.indexOf(nc.get('scale.baseMnemonic')) >= 0);
      }).sort(function (a, b) {
        return Math.abs(b.get('delta')) - Math.abs(a.get('delta'));
      });
    }),

    improved: Ember.computed("reportComparison", function () {
      var shownPtpbMnemonics = _peabody.default.GRAPHED_SYMPTOM_MNEMONICS.concat(_peabody.default.GRAPHED_SKILL_MNEMONICS);
      return this.get("reportComparison.normedComparisons").filter(function (nc) {
        return nc.get('latestValue').isSignificantChangeFrom(nc.get('comparisonValue')) && nc.get('isImproving') && (nc.get('scale.measureType') !== 'ptpb' || shownPtpbMnemonics.indexOf(nc.get('scale.baseMnemonic')) >= 0);
      }).sort(function (a, b) {
        return Math.abs(b.get('delta')) - Math.abs(a.get('delta'));
      });
    })
  });
});