define('frontend/pods/components/common/server-side-table/expandable-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isExpanded: false,
    visibleColumns: Ember.computed.filterBy("processedColumns", "isVisible", true),
    expandedColSpan: Ember.computed.alias("visibleColumns.length"),
    actions: {
      expand: function expand() {
        //don't expand/contract on button clicks
        if (typeof event !== 'undefined' && event && event.target) {
          if ($(event.target).closest('a').length || $(event.target).closest('button').length) {
            return;
          }
        }

        this.toggleProperty('isExpanded');
      },
      sendAction: function sendAction() {
        this.get('actionHandler').apply(undefined, arguments);
      }
    }
  });
});