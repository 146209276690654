define('frontend/helpers/assessment/scale-interpretation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assessmentScaleInterpretation = assessmentScaleInterpretation;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function assessmentScaleInterpretation(scale, interpretation, intl) {
    // Because we started doing this in the intl by mistake early on, we have a complicated set up for this:
    //  First look for scale.displayData.interpretation to see if the DB has it
    //  Then see if the intl has any lookups for it
    var intlKey = 'provider.scales.' + scale.get('mnemonic') + '.interpretation.' + interpretation;
    if (scale.get('displayData.interpretation.' + interpretation)) {
      return scale.get('displayData.interpretation.' + interpretation);
    } else if (intl.exists(intlKey)) {
      return intl.t(intlKey);
    } else {
      return interpretation;
    }
  }

  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          scale = _ref2[0],
          interpretation = _ref2[1];

      return assessmentScaleInterpretation(scale, interpretation, this.get('intl'));
    }
  });
});