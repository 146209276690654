define('frontend/models/reporting/drilldown-report-view-data', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    preload: Ember.inject.service(),

    currentScaleScorer: Ember.computed('report.scaleScorer', function () {
      return this.getWithDefault('report.scaleScorer');
    }),

    viewColumns: Ember.computed('reportHeadersChanged', 'reportDefinition', 'currentScaleScorer', function () {
      var _this = this;

      var viewColumnProperties = this.getWithDefault('reportDefinition.columnProperties', {});
      var backendColumns = this.getWithDefault('report.headers', []).map(function (h) {
        var merged = Ember.assign(h, viewColumnProperties[h.translationKey]);
        if (merged.type === 'scale-value') {
          Ember.set(merged, 'scaleScorer', _this.get('currentScaleScorer'));
        }
        return merged;
      }).rejectBy('shown', false);
      return backendColumns.concat(this.getWithDefault('reportDefinition.addedViewColumns', []));
    }),

    headlineColumns: Ember.computed.filterBy('viewColumns', 'headline', true),

    hasFeatureColumns: Ember.computed('viewColumns.[]', function () {
      return this.get('viewColumns').isAny('featured', true);
    }),

    shownColumns: Ember.computed('viewColumns.[]', function () {
      var cols = this.get('viewColumns').rejectBy('graphOnly');
      if (this.get('hasFeatureColumns')) {
        // return only featured columns
        return this.get('viewColumns').filterBy('featured', true);
      }
      // otherwise show everything
      return cols;
    }),

    hiddenColumns: Ember.computed('viewColumns.[]', function () {
      if (this.get('hasFeatureColumns')) {
        //return non-featured columns, note that undefined is non-featured
        return this.get('viewColumns').rejectBy('featured', true);
      }
      // otherwise nothing
      return [];
    }),

    detailColumns: Ember.computed.alias('hiddenColumns'),

    totalColumnCount: Ember.computed('shownColumns.[]', function () {
      // There is an additional column used for the entity tree, and for links
      return this.get("shownColumns.length") + 2;
    }),

    currentMaxLevel: Ember.computed('currentLevels', function () {
      var currentLevels = this.get("currentLevels");
      if (!currentLevels) {
        return 1;
      } else {
        return Math.max(1, _lodash.default.findLastIndex(this.get("currentLevels"), function (i) {
          return i > 0;
        }) + 1);
      }
    }),

    comparisonStartDate: Ember.computed.alias('report.comparisonStartDate'),
    comparisonEndDate: Ember.computed.alias('report.comparisonEndDate'),

    onExpand: function onExpand(level) {
      var currentLevels = this.get("currentLevels");
      if (!currentLevels) {
        currentLevels = this.set("currentLevels", []);
      }
      if (!currentLevels[level]) {
        currentLevels[level] = 1;
      } else {
        currentLevels[level]++;
      }

      this.notifyPropertyChange('currentMaxLevel');
    },
    onHide: function onHide(level) {
      var currentLevels = this.get("currentLevels");
      if (currentLevels && currentLevels[level]) {
        currentLevels[level]--;
      }

      this.notifyPropertyChange('currentMaxLevel');
    },
    reset: function reset() {
      this.set("currentLevels", []);
    }
  });
});