define('frontend/pods/components/provider/assessment/scale-browser/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mode: 'session', // Modes: session = choose for session, plan = 
    actions: {
      focusScale: function focusScale(scale) {
        this.sendAction('scaleDetailSelected', scale);
      },
      unfocusScale: function unfocusScale() {
        this.sendAction("unfocusScale");
      }
    },

    enabledScales: Ember.computed.filterBy('scales', 'instituteAllowsAdministration', true),

    filterContextSession: Ember.computed('scales.[]', 'report.patient', 'allParticipants.[]', function () {
      return {
        scales: this.get("enabledScales"),
        patient: this.get('report.patient'),
        age: this.get('report.patient.age'),
        gender: this.get('report.patient.gender'),
        allParticipants: this.get('allParticipants')
      };
    }),

    filterContextPlan: Ember.computed('patient', 'scales.[]', 'demographics.minAge', 'demographics.maxAge', 'demographics.gender', function () {
      if (this.get("patient")) {
        return {
          scales: this.get("enabledScales"),
          patient: this.get("patient"),
          age: this.get("patient.age"),
          gender: this.get('patient.gender')
        };
      } else {
        return {
          scales: this.get("enabledScales"),
          minAge: this.get('demographics.minAge'),
          maxAge: this.get('demographics.maxAge'),
          age: this.get('demographics.age'),
          gender: this.get('demographics.gender')
        };
      }
    }),

    filterContext: Ember.computed('mode', 'filterContextSession', 'filterContextPlan', function () {
      var mode = this.get('mode');

      switch (mode) {
        case 'session':
          return this.get('filterContextSession');
        case 'plan':
          return this.get('filterContextPlan');
        default:
          return null;
      }
    }),

    rowContextSession: Ember.computed('report', 'selectedReportScales.[]', 'allParticipants.[]', function () {
      if (!this.get("report")) {
        return null;
      }

      return {
        selectedScales: this.get("selectedReportScales").mapBy('scale'),
        selectedReportScales: this.get("selectedReportScales"),
        selectedScaleIds: this.get("selectedReportScales").mapBy('scale.id'),
        completedScaleIds: this.get("selectedReportScales").filterBy('isEditable', false).mapBy('scale.id'),
        allParticipants: this.get("allParticipants")
      };
    }),

    showSelect: Ember.computed.notEmpty('report'),
    showChoose: Ember.computed.equal('mode', 'plan')
  });
});