define('frontend/models/assessment/scale-scorer', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    scale: _emberData.default.belongsTo('assessment/scale', { async: true }),

    name: _emberData.default.attr("string"),
    fullNameWithScale: _emberData.default.attr("string"),
    thresholdData: _emberData.default.attr(),
    displayData: _emberData.default.attr(),
    domain: _emberData.default.attr("string"),
    minValue: _emberData.default.attr("number"),
    maxValue: _emberData.default.attr("number"),
    minInterpretation: _emberData.default.attr("string"),
    minInterpretationShort: _emberData.default.attr("string"),
    maxInterpretation: _emberData.default.attr("string"),
    maxInterpretationShort: _emberData.default.attr("string")
  });
});