define('frontend/pods/components/landing-page/component', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    showDemoTour: Ember.computed(function () {
      return _environment.default.enableDemoWalkthroughs;
    }),
    steps: [{
      intro: '\n        <div class="onboarding__banner">\n          <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n          <h1>Welcome to Mirah!</h2>\n          <p><strong>Hi!</strong>  Thanks for your interest in Mirah, our software, and advancing measurement-based care. This walkthrough is meant to give you a brief overview of some of the functions available in our demo.  It\u2019s by no means a complete list of demo features, much less all the features that are available in our product.</p>\n        </div>'
    }, {
      intro: '\n        <div class="onboarding__banner">\n          <img src="/images/blue-star.png" class="onboarding__header-logo" alt="Mirah Logo"/>\n          <h3>Demo differences</h3>\n          <p><strong>Integrations</strong>:  The demo environment operates in \u201Cstandalone\u201D mode -- clients and assessments are created manually in the tool.  This is in contrast to production deployments where clients and appointments are typically pulled from an electronic health record system.</p>\n        </div>'
    }, {
      element: '.provider-login',
      intro: 'Once you have your login details to hand, let\'s go to the provider portal to start'
    }]
  });
});