define('frontend/pods/components/survey/taking-local/page-components/decision-support/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',

    messagesRequest: Ember.computed("response.id", function () {
      var _this = this;

      var request = this.store.createRecord('survey/calculateMessagesRequest', {
        surveyResponseId: this.get('response.id')
      }).save();

      request.then(function (response) {
        // If we don't have any messages we want to instantly trigger next module.
        if (Ember.isEmpty(response.get("careEpisodeMessages"))) {
          _this.get('next')();
        }
      });

      return request;
    }),

    isLoading: Ember.computed.alias("messagesRequest.isLoading"),

    questions: Ember.computed("messagesRequest.careEpisodeMessages.[]", function () {
      var _this2 = this;

      return this.getWithDefault("messagesRequest.careEpisodeMessages", []).toArray().flatMap(function (message) {
        return _this2._hydrateQuestions(message);
      });
    }),

    _hydrateQuestions: function _hydrateQuestions(message) {
      return [message.get("content")].flat().map(function (question) {
        return Ember.merge(question, { questionDomain: 'decisionSupport#' + message.get('id') });
      });
    }
  });
});