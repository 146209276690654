define('frontend/pods/components/provider/assessment/report-v2/longitudinal-report/bread-crumb/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    //for now, hack the session number with the report index
    activeReportNumber: Ember.computed("model.reports", "model.activeReport", function () {
      var reports = this.getWithDefault("model.bundle.reports", []);
      var activeReport = this.get("model.activeReport");

      return reports.get('length') - reports.indexOf(activeReport);
    }),
    showTakingDetail: Ember.computed('model.activeReport.patientInvitation', function () {
      return this.get('model.activeReport.focalInvitation.takingTimeSeconds') || this.get('model.activeReport.focalInvitation.takingLanguage');
    })
  });
});