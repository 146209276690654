define('frontend/pods/components/scale-creation/dynamic-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'horizontal-select',
    selected: Ember.computed('options.[]', function () {
      var id = Ember.get(this.get('model'), this.get('property'));
      var options = this.get("options");

      if (!id) {
        return null;
      }

      return options.find(function (option) {
        return option.id === id;
      });
    }),
    actions: {
      updateSelected: function updateSelected(option) {
        var value = option ? option.id : null;
        Ember.set(this.get('model'), this.get('property'), value);
        this.notifyPropertyChange("selected");
        if (this.get('onUpdate')) {
          this.get('onUpdate')();
        }
      }
    }
  });
});