define('frontend/pods/components/common/configuration/edit-form/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('configObject')) {
        this.set('configObject', {});
      }
    },

    configObject: Ember.computed('model', 'property', {
      get: function get() {
        return Ember.get(this.get('model'), this.get('property'));
      },
      set: function set(key, value) {
        Ember.set(this.get('model'), this.get('property'), {});
        return value;
      }
    }),
    groups: Ember.computed('configuration', function () {
      var pairs = Object.entries(this.get('configuration'));
      return _lodash.default.groupBy(pairs, function (pair) {
        return pair[1].group;
      });
    }),
    didRender: function didRender() {
      $('[data-toggle="popover"]').popover();
    }
  });
});