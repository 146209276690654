define('frontend/pods/components/provider/assessment/report-v2/kristi-house-broker-metrics/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/custom-measures/measure-item/component', 'ember-data', 'lodash'], function (exports, _component, _emberData, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var chartColors = ["#2893ff", "#ffa556", "#57d057", "#e36667", "#b494d0", "#8c564b", "#b68378", "#a5a5a5", "#dfe054", "#4edceb"];

  var componentData = [{ questionShortname: 'psychoeducation', displayName: 'P: Psychoeducation' }, { questionShortname: 'parentingSkills', displayName: 'P: Parenting Skills' }, { questionShortname: 'relaxation', displayName: 'R: Relaxation' }, { questionShortname: 'affectiveModulation', displayName: 'A: Affective Modulation' }, { questionShortname: 'cognitiveCoping', displayName: 'C: Cognitive Coping and Processing' }, { questionShortname: 'developingTraumaNarrative', displayName: 'T: Developing the Trauma Narrative (TN)' }, { questionShortname: 'processingTraumaNarrative', displayName: 'T: Cognitive Processing of the Trauma Narrative (TN)' }, { questionShortname: 'sharingTraumaNarrative', displayName: 'T: Sharing the Trauma Narrative (TN) with Caregiver' }, { questionShortname: 'invivoExposure', displayName: 'I: In-Vivo Exposure' }, { questionShortname: 'conjointSessions', displayName: 'C: Conjoint Child-Parent Sessions (other than TN)' }, { questionShortname: 'enhancingSafety', displayName: 'E: Enhancing Safety and Developmen' }];

  var barrierLabels = ['Difficulty Engaging Child', 'Difficulty Engaging Caregiver', 'Catch up due to poor attendance', 'New events', 'System related issues', 'Client ran away', ' Client returned from run away', 'other'];

  exports.default = _component.default.extend({
    allAnswers: Ember.computed("computedValues.[]", function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('assessment/answer', {
          include: 'question',
          filter: { computedValueId: this.get("computedValues").mapBy('id') }
        })
      });
    }),

    graphLoading: Ember.computed.not('allAnswers.isFulfilled'),

    groupedAnswers: Ember.computed("allAnswers.[]", function () {
      var allAnswers = this.get("allAnswers");
      if (!allAnswers.content) {
        return {};
      }

      return _lodash.default.groupBy(allAnswers.content.toArray(), function (answer) {
        return answer.get('question.shortname');
      });
    }),

    attendanceData: Ember.computed('groupedAnswers', function () {
      var groupedAnswers = this.get('groupedAnswers');
      var attendedSessions = 0;
      var unknownMissedSessions = 0;

      if (groupedAnswers['sessionThisWeek']) {
        var answers = this.get("groupedAnswers")['sessionThisWeek'].mapBy("value");
        var countedAnswers = _lodash.default.countBy(answers);
        attendedSessions = countedAnswers['yes'];
        unknownMissedSessions = countedAnswers['no'];
      }

      var missedReasons = {
        notScheduled: 0,
        noShow: 0,
        clientCancelled: 0,
        therapistCancelled: 0,
        other: 0
      };

      if (groupedAnswers['noSessionReason']) {
        var _answers = groupedAnswers['noSessionReason'].mapBy("value");
        missedReasons = _lodash.default.countBy(_answers);
        unknownMissedSessions -= _answers.length;
      }

      return {
        datasets: [{
          data: [attendedSessions, unknownMissedSessions, missedReasons['notScheduled'], missedReasons['noShow'], missedReasons['clientCancelled'], missedReasons['therapistCancelled'], missedReasons['other']],
          backgroundColor: chartColors
        }],
        labels: ['Attended', 'Missed - Unknown', 'No Session Scheduled', 'Client No Show', 'Client/Caregiver Cancelled', 'Therapist Cancelled', 'Other']
      };
    }),

    participationData: Ember.computed('groupedAnswers', function () {
      var groupedAnswers = this.get('groupedAnswers');

      var counts = {
        yes: 0,
        no: 0
      };

      if (groupedAnswers['caregiverParticipated']) {
        var answers = this.get("groupedAnswers")['caregiverParticipated'].mapBy("value");
        counts = _lodash.default.countBy(answers);
      }

      return {
        datasets: [{
          data: [counts['yes'], counts['no']],
          backgroundColor: chartColors
        }],
        labels: ['> 15 mins', 'None']
      };
    }),

    usedInSessionData: Ember.computed('groupedAnswers', function () {
      var groupedAnswers = this.get('groupedAnswers');

      var getCounts = function getCounts(answers, questionShortname) {
        if (answers[questionShortname]) {
          return _lodash.default.countBy(groupedAnswers[questionShortname].mapBy("value"));
        } else {
          return {
            discussedBriefly: 0,
            majorFocus: 0
          };
        }
      };

      var discussedBrieflyData = [];
      var majorFocusData = [];

      componentData.forEach(function (question) {
        var counts = getCounts(groupedAnswers, question.questionShortname);

        discussedBrieflyData.push(counts.discussedBriefly || 0);
        majorFocusData.push(counts.majorFocus || 0);
      });

      return {
        labels: componentData.mapBy('displayName'),
        datasets: [{
          data: discussedBrieflyData,
          label: 'Discussed Briefly',
          backgroundColor: '#2893ff'
        }, {
          data: majorFocusData,
          label: 'Major Focus',
          backgroundColor: '#ffa556'
        }]
      };
    }),

    usedInSessionOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }],
        yAxes: [{
          stacked: true,
          type: 'category',
          labels: componentData.mapBy('displayName')
        }]
      }
    },

    barriersData: Ember.computed('groupedAnswers', function () {
      var groupedAnswers = this.get('groupedAnswers');

      var barriers = {
        difficultyEngagingChildInTreatmentProcess: 0,
        difficultyEngagingCaregiverInTreatmentProcess: 0,
        NeedToCatchUpDueToPoorPastAttendance: 0,
        NewEventsToBeDiscussed: 0,
        NeedtoDealWithIssuesRelatedToOtherSystems: 0,
        ClientRanAway: 0,
        ClientRanAwayAndHadJustReturned: 0,
        other: 0
      };

      if (groupedAnswers['adherenceBarriers']) {
        var answers = this.get("groupedAnswers")['adherenceBarriers'].mapBy("value");
        barriers = _lodash.default.countBy(answers);
      }

      return {
        datasets: [{
          data: [barriers.difficultyEngagingChildInTreatmentProcess, barriers.difficultyEngagingCaregiverInTreatmentProcess, barriers.NeedToCatchUpDueToPoorPastAttendance, barriers.NewEventsToBeDiscussed, barriers.NeedtoDealWithIssuesRelatedToOtherSystems, barriers.ClientRanAway, barriers.ClientRanAwayAndHadJustReturned, barriers.other],
          backgroundColor: '#2893ff'
        }],
        labels: barrierLabels
      };
    }),

    barriersOptions: {
      legend: false,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }],
        yAxes: [{
          type: 'category',
          labels: barrierLabels
        }]
      }
    }
  });
});