define('frontend/pods/components/provider/billing/billing-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'charge-explorer page__wide-content',

    billableStatuses: ['billable'],
    billedStatuses: ['billed']
  });
});