define("frontend/mixins/survey/finish-redirect", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    timeout: 10000,

    // Note that this is mostly cribbed from the assessment->return routes. We want our local survey taking to be a little more
    // streamlined so we skip the extra step here.
    model: function model() {
      var returnPath = sessionStorage.getItem("returnUrl");
      var refreshPage = true;
      sessionStorage.removeItem("returnUrl");
      var referrer = sessionStorage.getItem("referrer");
      sessionStorage.removeItem("referrer");
      var noRedirect = referrer === 'email' || referrer === 'text' || !this.get("session.isAuthenticated");

      var doLogout = false;

      var activeRoleType = this.get("session.data.authenticated.active_role_type");

      if (activeRoleType === "provider") {
        refreshPage = false;

        if (!returnPath) {
          // TODO: once roll out of the complete is complete
          // REMOVE this feature gate
          // I'm not sure i can just stick a path in here and everything will work on the redirect logic below
          returnPath = this.get("features.enableDashboardSchedule") ? "/app/providers/dashboard/schedule" : "provider.assessment.dashboard";
        }

        if (noRedirect) {
          doLogout = true;
        }
      } else {
        if (!returnPath && !noRedirect) {
          returnPath = "patient.welcome";
        }

        doLogout = true;
      }

      if (doLogout) {
        this.get("sessionUser").silentLogout();
      }

      var redirectPromise = null;

      if (!noRedirect) {
        redirectPromise = Ember.run.later(this, this.doRedirect, returnPath, refreshPage, this.get("timeout"));
      }

      return { showRedirectWarning: !noRedirect, showLogoutWarning: doLogout, redirectPromise: redirectPromise };
    },


    actions: {
      doRedirectNow: function doRedirectNow(redirectPromise) {
        redirectPromise.apply();
        Ember.run.cancel(redirectPromise);
      }
    },

    doRedirect: function doRedirect(route, refreshPage) {
      if (refreshPage) {
        if (route.indexOf("/") === -1) {
          route = this.get("router").generate(route);
        }

        window.location.replace(route);
      } else {
        this.get("router").transitionTo(route);
      }
    }
  });
});