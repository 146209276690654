define('frontend/pods/components/reporting/report-table-server-sort/component', ['exports', 'frontend/pods/components/reporting/report-table/component', 'frontend/pods/components/reporting/report-table/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,

    showLimitControls: true,
    defaultLimit: Ember.computed.alias('reportDefinition.defaultLimit'),

    sortOn: Ember.computed('reportParams.sort', 'viewColumns.[]', function () {
      var sort = this.get('reportParams.sort');
      return this.getWithDefault('viewColumns', []).find(function (column) {
        return column.sortBy === sort;
      });
    }),
    sortDirection: Ember.computed.alias('reportParams.sortDirection'),

    sortedData: Ember.computed.alias('data'),

    onSort: function onSort(column) {
      var reportParams = this.get('reportParams');

      var sortDirection = 'asc';

      if (this.get('sortOn.id') === column.id) {
        if (this.get('sortDirection') === 'asc') {
          sortDirection = 'desc';
        }
      }

      reportParams.setProperties({
        sort: column.sortBy,
        sortDirection: sortDirection
      });
      this.sendAction("reportParamsRefresh");
    }
  });
});