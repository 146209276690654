define('frontend/validators/international-phone-number', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /* eslint-disable no-useless-escape */
  var usFormatter = /^([\+]?1\s*[-\/\.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-\/\.]?\s*(\d{3})\s*[-\/\.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[\.]?|extension)\s*([#*\d]+))*$/;

  var internationalFormatter = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  /* eslint-enable no-useless-escape */

  var InternationalPhoneNumber = _base.default.extend({
    validate: function validate(value, options) /*model, attribute*/{
      if (!value) {
        return options.allowBlank;
      }

      if (Ember.isEmpty(value.match(usFormatter)) && Ember.isEmpty(value.match(internationalFormatter))) {
        return this.createErrorMessage("phone", value, options);
      }

      return true;
    }
  });

  InternationalPhoneNumber.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = InternationalPhoneNumber;
});