define('frontend/pods/components/reporting/table-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['columnStyle'],
    columnStyle: Ember.computed.alias('column.className')
  });
});