define('frontend/pods/components/provider/patient-sessions/list-component-timeline/component', ['exports', 'frontend/pods/components/provider/assessment/scale-info-drawer/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.ScaleDrawerMixin, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    preload: Ember.inject.service(),
    availableMeasures: Ember.computed('preload.independentScales.[]', function () {
      return this.get('preload.independentScales');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "status",
        component: "components/provider/patient-sessions/session-list-status",
        title: intl.t('model.patientSession.effectiveStart'),
        className: 'session-status'
      }, {
        propertyName: "targetDate",
        component: "components/provider/patient-sessions/session-list-item",
        title: intl.t('model.patientSession.effectiveStart'),
        sortPrecedence: 1,
        sortDirection: "asc"
      }, {
        className: 'session-actions',
        template: "components/provider/patient-sessions/session-list-actions",
        title: intl.t('model.patientSession.effectiveStart')
      }];
      this.set("tableColumns", tableColumns);
      this.set("pageSize", 10);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var patientId = this.get('patient.id');
      var isTest = this.get('patient.isTest');
      if (!this.get("pastData")) {
        var pastData = Ember.Object.create({
          store: this.get("store"),
          modelName: 'patientSession',
          query: {
            include: "scheduling-appointment,assessment-report.report-scales",
            adapterOptions: { fieldset: 'all' },
            filter: { recent_history_for: patientId, show_test: isTest },
            page_size: 25
          }
        });

        this.set("pastData", pastData);
      }

      if (!this.get("futureData")) {
        var futureData = Ember.Object.create({
          store: this.get("store"),
          modelName: 'patientSession',
          query: {
            include: "scheduling-appointment,assessment-report.report-scales,provider",
            adapterOptions: { fieldset: 'all' },
            filter: { upcoming_for: patientId, show_test: isTest },
            page_size: 25
          }
        });

        this.set("futureData", futureData);
      }
    },

    actions: {
      onFocusScale: function onFocusScale(scale) {
        this.set("focusedScale.scale", scale);
      },
      showPredictions: function showPredictions() {
        var careEpisode = this.get("futureData.currentContent.firstObject.careEpisode");
        var thisRequest = this.get('store').createRecord('care/futureSessionMeasurementEstimate', { careEpisode: careEpisode });

        thisRequest.save().then(function (results) {
          results.get("hydratedResults").forEach(function (result) {
            if (result.patientSession) {
              Ember.set(result.patientSession, 'predictedScales', result.scales);
            }
          });
        });
      }
    }

  });
});