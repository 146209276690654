define('frontend/pods/components/scale-creation/autogen-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    autogen: true,
    changedObserver: Ember.observer('parentModel.dataHasChanged', function () {
      this._setAutomaticValue();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set("autogen", !this.get("model").get(this.get('property')));
    },
    _setAutomaticValue: function _setAutomaticValue() {
      if (!this.get("autogen")) {
        return;
      }

      var autoFunc = this.get("autogenFunction");

      var _getProperties = this.getProperties("registry", "model", "registryType"),
          registry = _getProperties.registry,
          model = _getProperties.model,
          registryType = _getProperties.registryType;

      var baseValue = autoFunc(this.get("parentModel"));
      var uniqueIndex = 0;
      var newValue = baseValue;

      var isValid = registry.register(model, baseValue, registryType);

      while (!isValid) {
        newValue = baseValue + (uniqueIndex++).toString();
        isValid = registry.register(model, newValue, registryType);
      }

      Ember.set(this.get('model'), this.get("property"), newValue);
    },

    actions: {
      clearAutogen: function clearAutogen() {
        this.set("autogen", false);
      },
      setAutogen: function setAutogen() {
        this.set("autogen", true);
        this._setAutomaticValue();
      },
      focusOutAndValidate: function focusOutAndValidate() {
        var newValue = Ember.get(this.get("model"), this.get("property"));
        if (this.get('registry').reregister(this.get("parentModel"), newValue, this.get("registryType"))) {
          if (this.get('focusOut')) {
            return this.get("focusOut")();
          }
        } else {
          alert("This variable name is already taken");
          return false;
        }
      }
    }
  });
});