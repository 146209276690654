define('frontend/pods/provider/assessment/invitation/begin/route', ['exports', 'frontend/pods/base-route', 'frontend/mixins/provider-assessment-redirect'], function (exports, _baseRoute, _providerAssessmentRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerAssessmentRedirect.default, {
    sessionUser: Ember.inject.service(),
    redirect: function redirect() /*model, transition*/{
      var invitation = this.modelFor("provider.assessment.invitation");
      if (invitation.get("status") === "complete") {
        this.transitionTo('provider.assessment.invitation.complete');
      }

      return this.actions.takeAssessment.call(this, invitation);
    }
  });
});