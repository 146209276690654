define('frontend/serializers/related-person', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      allowsEmail: { serialize: false },
      allowsText: { serialize: false }
    }
  });
});