define('frontend/pods/components/provider/assessment/report-v2/text-synopsis/scale-overview/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/full-answer-detail/scale-overview/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    abnormalScaleListString: Ember.computed('abnormalScales', function () {
      return this.getScaleListString(this.get('abnormalScales'));
    }),
    normalScaleListString: Ember.computed('normalScales', function () {
      return this.getScaleListString(this.get('normalScales'));
    }),
    unclassifiedScaleListString: Ember.computed('unclassifiedScales', function () {
      return this.getScaleListString(this.get('unclassifiedScales'));
    }),

    // normally I'd like code like this in a template, but it's ugly to get the strings right
    // in templates due to how Ember templates handles whitespace
    getScaleListString: function getScaleListString(computedValues) {
      var nameTexts = computedValues.map(function (cv) {
        var text = cv.get('scale.translatedShortname');
        // CHC's eclinical works notes cannot accept endashes or emdashes, replace with hyphen
        if (text && text.replace) {
          text = text.replace(/–|—/, '-');
        }
        if (!cv.get('isPatientReported')) {
          text += ' <span class="detail">(' + cv.get('user.name') + ')</span>';
        }
        if (!cv.get('isUnclassified')) {
          text += ' - <span class="strong ' + cv.get('thresholdClass') + '">' + cv.get('adaptedMnemonic') + '</span>';
        } else {
          if (cv.get('value') || cv.get('value') === 0) {
            text += ' - <span class="strong">' + cv.get('value') + '</span>';
          }
        }
        return text;
      });
      return nameTexts.join('; ');
    }
  });
});