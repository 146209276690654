define('frontend/models/flag', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    manual: _emberData.default.attr("boolean"),
    editable: _emberData.default.attr("boolean"),
    description: _emberData.default.attr("string"),
    icon: _emberData.default.attr("string"),
    sdoh: _emberData.default.attr("string")
  });
});