define('frontend/serializers/assessment/invitation', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      createdAt: { serialize: false }, // never send server-side timestamps
      updatedAt: { serialize: false }, // never send server-side timestamps
      dateEmailed: { serialize: false }, // never send server-side timestamps
      dateTexted: { serialize: false }, // never send server-side timestamps
      assessmentUrl: { serialize: false },
      emailUrl: { serialize: false },
      status: { serialize: false },
      participant: { serialize: false },
      assessment: { serialize: false },
      reportScales: { serialize: false },
      patient: { serialize: false },
      surveyInvite: { serialize: false },
      patientFirstName: { serialize: false },
      patientLastName: { serialize: false }
    }
  });
});