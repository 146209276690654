define('frontend/pods/components/provider-admin/payors/payor-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "name",
        title: intl.t('model.payor.name')
      }, {
        propertyName: "primaryId",
        title: intl.t('model.payor.primaryId')
      }, {
        template: "components/provider-admin/payors/payor-table/contract-list",
        title: intl.t('model.payor.contracts'),
        disableSorting: true
      }, {
        template: "components/provider-admin/payors/payor-table/action-list",
        title: intl.t('common.actions'),
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // Note that the patient search term is passed straight through to the component.
      // We pass in the details for the request rather than the request itself to make sure
      // the filtering is done correctly first time round.
      if (!this.get("data")) {
        var data = Ember.Object.create({
          store: this.get("store"),
          modelName: 'institutePayor',
          query: { include: 'billing-payor-contracts,payor', sortBy: 'payor.name' }
        });
        this.set("data", data);
      }
    }
  });
});