define('frontend/pods/components/survey/taking-local/common/questions/dynamic-variable-checkbox/component', ['exports', 'frontend/mixins/survey-metric', 'frontend/mixins/survey/question-component-mixin'], function (exports, _surveyMetric, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CheckItem = Ember.Object.extend({
    isChecked: Ember.computed('responseItem.active', function () {
      return this.get("responseItem.active");
    }),
    responseItem: Ember.computed('question.responseSet.responseItems.[]', function () {
      return this.getWithDefault("question.responseSet.responseItems", []).find(function (item) {
        return !item.get("overridden") && !item.get('isDeleted');
      });
    })
  });

  /*
   * This checkbox question works slightly differently. Given a base stem question mnemonic, it will make a series of
   * questions for each check made.
   *  Start out with all X answers blank. When restoring questions, restore to previous state.
   *  When an option is selected, choose the next available blank question slot (or retrieve a previous, deleted question for that option) and set the value to that option.
   *  When an option is unchecked, if it's been persisted, mark as outdated.
   */
  exports.default = Ember.Component.extend(_surveyMetric.default, _questionComponentMixin.default, {
    tagName: '',
    createResponsesAutomatically: false,
    otherResponseItem: undefined,

    derivedQuestions: Ember.computed('question.properties.limit', 'question.shortname', function () {
      var limit = this.get("question.properties.limit") || this.get("question.sub_questions.length");
      var base = this.get('question.shortname');

      var questions = [];

      for (var ii = 1; ii <= limit; ii++) {
        var q = { shortname: '' + base + ii };

        q.responseSet = this.get('response').getResponseSetForQuestion(q, this.get('module'));
        questions.pushObject(q);
      }

      return questions;
    }),

    answerRanks: Ember.computed('remainingAnswers', function () {
      var map = {};
      var optionResponses = this.get("optionResponses");
      this.get("derivedQuestions").forEach(function (question, index) {
        var option = optionResponses.findBy('question', question);
        if (option) {
          map[option.opt.id] = index + 1;
        }
      });
      return map;
    }),

    optionResponses: Ember.computed('response', 'derivedQuestions.[]', 'question.options.[]', function () {
      var questions = this.get('derivedQuestions');

      return this.get("question.options").map(function (option) {
        var question = questions.find(function (q) {
          return q.responseSet && q.responseSet.responseItems.any(function (ri) {
            return ri.get('optionId') === option.id;
          });
        });

        return CheckItem.create({
          opt: option,
          question: question
        });
      });
    }),

    remainingAnswers: Ember.computed('question.properties.limit', function () {
      var limit = this.get('question.properties.limit');

      if (!limit) {
        return null;
      }

      var taken = this.get("optionResponses").filterBy('isChecked', true).length;

      return limit - taken;
    }),

    answersDisabled: Ember.computed('remainingAnswers', function () {
      if (!this.get('question.properties.limit')) {
        return false;
      }
      return this.get("remainingAnswers") <= 0;
    }),

    _getNextAvailableQuestion: function _getNextAvailableQuestion() {
      var allQuestions = this.get('derivedQuestions');
      var takenQuestions = this.get('optionResponses').mapBy('question').compact();

      var found = allQuestions.find(function (question) {
        return takenQuestions.indexOf(question) < 0;
      });

      // Go through again and see if there are any now-deleted questions we can use.
      if (!found) {
        var takenButNotDeletedQuestions = this.get('optionResponses').filter(function (option) {
          var item = option.get("responseItem");
          return item && !item.get("overridden") && !item.get("isDeleted");
        }).mapBy('question').compact();

        found = allQuestions.find(function (question) {
          return takenButNotDeletedQuestions.indexOf(question) < 0;
        });
      }

      return found;
    },
    _toggleOptionNormal: function _toggleOptionNormal(optionResponse) {
      // Find the appropriate question to assign it to.
      var question = optionResponse.get('question');

      if (!optionResponse.get('isChecked')) {
        if (this.get("answersDisabled")) {
          return;
        }
        if (!question) {
          question = this._getNextAvailableQuestion();
          optionResponse.set('question', question);
        }

        var responseSet = question.responseSet;

        var responseItem = optionResponse.get("responseItem");

        // If you just unclick and click again, only make one item.
        if (responseItem && responseItem.get("isDeleted") && responseItem.get("hasDirtyAttributes")) {
          Ember.set(responseSet, 'simpleValue', optionResponse.get('opt.value'));
          responseItem.rollbackAttributes();
        } else {
          responseItem = this.store.createRecord('survey/response-item', {
            response: this.get('response'),
            questionId: this.get('question').id,
            optionId: optionResponse.get('opt.id'),
            questionMnemonic: question.shortname,
            questionDomain: this.get('module.question_domain'),
            value: optionResponse.get('opt.value')
          });

          if (optionResponse.get('opt.properties.other')) {
            // Find or make an other response item.
            this.set('otherResponseItem', responseItem);
            responseItem.set('value', '');
          }

          responseSet.responseItems.push(responseItem);
          Ember.set(responseSet, 'simpleValue', optionResponse.get('opt.value'));
          optionResponse.notifyPropertyChange('isChecked');
          optionResponse.notifyPropertyChange('responseItem');
          this.notifyPropertyChange('remainingAnswers');
        }

        if (this.get("trackTime")) {
          responseItem.startTrackingTime();
        }
      } else {
        if (question) {
          Ember.set(question.responseSet, 'simpleValue', null);
        }
        var _responseItem = optionResponse.get('responseItem');

        optionResponse.set('question', null);

        if (!_responseItem.get("isDeleted")) {
          _responseItem.deleteRecordWithDebounce();
        }
        optionResponse.notifyPropertyChange('isChecked');
        this.notifyPropertyChange('remainingAnswers');
      }
    },
    _toggleOptionBulk: function _toggleOptionBulk(optionResponse) {
      // When unchecking,
      // If persisted: do not delete, instead mark as overridden
      // if not persisted: normal.
      var question = optionResponse.get('question');
      if (!optionResponse.get('isChecked')) {
        if (this.get("answersDisabled")) {
          return;
        }
        if (!question) {
          question = this._getNextAvailableQuestion(optionResponse);
          optionResponse.set('question', question);
        }

        var responseSet = question.responseSet;

        var responseItem = optionResponse.get("responseItem");

        // If you just unclick and click again, only make one item.
        if (responseItem && responseItem.get("isDeleted") && responseItem.get("hasDirtyAttributes")) {
          responseItem.rollbackAttributes();
          Ember.set(responseSet, 'simpleValue', optionResponse.get('opt.value'));
        } else {
          responseItem = this.store.createRecord('survey/response-item', {
            response: this.get('response'),
            questionId: this.get('question').id,
            optionId: optionResponse.get('opt.id'),
            questionMnemonic: question.shortname,
            questionDomain: this.get('module.question_domain'),
            value: optionResponse.get('opt.value')
          });

          responseSet.responseItems.push(responseItem);
          if (optionResponse.get('opt.properties.other')) {
            // Find or make an other response item.
            this.set('otherResponseItem', responseItem);
            responseItem.set('value', '');
            Ember.set(responseSet, 'simpleValue', '');
            Ember.set(responseSet, 'isOther', true);
          } else {
            Ember.set(responseSet, 'simpleValue', optionResponse.get('opt.value'));
          }

          optionResponse.notifyPropertyChange('isChecked');
          optionResponse.notifyPropertyChange('responseItem');
          this.notifyPropertyChange('remainingAnswers');
        }

        if (this.get("trackTime")) {
          responseItem.startTrackingTime();
        }
      } else {
        var _responseItem2 = optionResponse.get('responseItem');

        if (_responseItem2.get("isNew")) {
          _responseItem2.deleteRecordWithDebounce();
        } else {
          _responseItem2.set("overridden", true);
        }

        if (question) {
          Ember.set(question.responseSet, 'simpleValue', null);
        }

        optionResponse.set('question', null);

        optionResponse.notifyPropertyChange('responseItem');
        this.notifyPropertyChange('remainingAnswers');
      }
    },


    actions: {
      toggleOption: function toggleOption(optionResponse) {
        if (this.get("mode") === 'bulk') {
          this._toggleOptionBulk(optionResponse);
        } else {
          this._toggleOptionNormal(optionResponse);
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      updateSimpleValueOther: function updateSimpleValueOther(option) {
        var responseSet = option.question.responseSet;
        Ember.set(responseSet, 'simpleValue', option.get('responseItem.value'));
      },
      next: function next() {
        var _this = this;

        this.get("derivedQuestions").forEach(function (question) {
          if (!question.responseSet && !question.responseSet.responseItems) {
            return;
          }

          $.each(question.responseSet.responseItems, function (index, item) {
            if (_this.get("trackTime")) {
              item.stopTrackingTime();
            }

            if (_this.get("saveOnSubmit")) {
              // There is a bug in ember data <= 2.14.1 which means that any persisted deleted records stop the save of other
              // records. Therefore for now, don't save any records we already know are deleted.
              // TODO: remove after we upgrade to ember 2.14.1
              if (item.get("isDeleted") && !item.get("hasDirtyAttributes")) {
                return;
              }

              if (!_this.get("previewMode")) {
                item.saveWithDebounce();
              }
            }
          });
        });
        this.sendAction('next');
      }
    }
  });
});