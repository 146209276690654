define('frontend/models/care/recurring-session-request', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    endDateString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      }), (0, _emberCpValidations.validator)('presence-or', {
        presence: true,
        dependentKeys: ['numberOfSessions']
      })]
    },
    numberOfSessions: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0,
        allowBlank: true
      }), (0, _emberCpValidations.validator)('presence-or', {
        presence: true,
        dependentKeys: ['endDateString']
      })]
    },
    intervalDays: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    },
    careEpisode: (0, _emberCpValidations.validator)('presence', true),
    provider: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    intervalDays: _emberData.default.attr('number', { defaultValue: 7 }),
    numberOfSessions: _emberData.default.attr('number'),
    firstSessionDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    endDate: _emberData.default.attr('date'),
    patient: _emberData.default.belongsTo("patient"),
    provider: _emberData.default.belongsTo("provider"),
    careEpisode: _emberData.default.belongsTo("careEpisode"),

    endDateString: Ember.computed('endDate', {
      get: function get() {
        if (this.get('endDate')) {
          return moment(this.get('endDate')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("endDate", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    // NOTE: there is a nestedData property which is serialized to allow us to save the entire session on one call

    saveNested: _emberData.default.attr("boolean")
  });
});