define("frontend/pods/components/common/offline-modal/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOffline: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._setOnline = function () {
        return _this.set("isOffline", false);
      };
      this._setOffline = function () {
        return _this.set("isOffline", true);
      };
    },
    didInsertElement: function didInsertElement() {
      Offline.on("up", this._setOnline);
      Offline.on("down", this._setOffline);
    },
    willDestroyElement: function willDestroyElement() {
      Offline.off("up", this._setOnline);
      Offline.off("down", this._setOffline);
    }
  });
});