define('frontend/pods/provider/user/update-password/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, _providerRouteMixin.default, {
    breadCrumb: null,
    sessionUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model() {
      return this.get("sessionUser.currentUser");
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.updatePassword" });
      }
    }
  });
});