define('frontend/pods/components/provider/related-people/list-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');
      var isCompressed = this.get('compressed');
      var tableColumns = [{
        propertyName: "id",
        isHidden: true,
        title: intl.t('common.id'),
        disableSorting: true
      }, {
        // Although this is expressed as a property in the model directly, the sorting doesn't
        // work as it's a rails-level delegate, not a DB property. so sort instead on the user
        template: "components/provider/related-people/list-component/name-and-relationship",
        title: intl.t('model.relatedPerson.name'),
        disableSorting: true
      }, {
        propertyName: "user.username",
        title: intl.t('common.username'),
        disableSorting: true,
        isHidden: isCompressed
      }, {
        propertyName: "active",
        title: intl.t('common.active'),
        template: "components/common/server-side-table/tick-mark",
        disableSorting: true,
        isHidden: isCompressed
      }, {
        title: intl.t('common.actions'),
        template: "components/provider/related-people/list-component/action-buttons",
        disableSorting: true,
        isHidden: isCompressed
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 20);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient && typeof patient.query === 'function') {
          var data = Ember.Object.create({
            store: this.get("patient"),
            modelName: 'relatedPeople',
            query: { include: "user" }
          });
          this.set("data", data);
        }
      }
    },

    actions: {}

  });
});