define('frontend/mixins/reports/route-mixin', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    reports: Ember.inject.service(),
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    breadCrumb: {},

    beforeModel: function beforeModel() {
      // Make sure we have the user first, otherwise the provider gets refreshed and we load reports
      // twice.
      return Ember.RSVP.hash({
        currentUser: this.get("sessionUser.currentUser"),
        preload: this.get("preload").ensure()
      });
    },


    //return a hash of the report and clinicians
    model: function model(params) {
      var reportCode = params.report_code;
      var report = this.get('reports.reportMap')[reportCode];

      var instituteConditions = this.get("features.enableConditions") ? this.get('preload.instituteConditions') : [];

      return Ember.RSVP.hash({
        report: report,
        clinicians: this.get('preload.clinicians'),
        organizations: this.get('preload.organizations'),
        flags: this.get('preload.flags'),
        careUnitTypes: this.get('preload.careUnitTypes'),
        scaleScorer: params.reportScaleScorerId ? this.store.findRecord('assessment/scaleScorer', params.reportScaleScorerId) : undefined,
        instituteConditions: instituteConditions,
        showMockComponent: this.get('features.showMockAnalytics') && report && report.mockComponent
      });
    },
    afterModel: function afterModel(model) {
      var intl = this.get('intl');
      var code = model.report.get('shortcode');
      this.set('breadCrumb', { title: intl.t('reporting.reps.' + code + '.title') });
    },
    setupController: function setupController(controller) {
      // For first time load, we don't want to double-trigger the data fetch
      controller.set('noRefresh', true);
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.resetQueryParams();
      }
      return this._super.apply(this, arguments);
    },
    serializeQueryParam: function serializeQueryParam(value, key) {
      switch (key) {
        case 'start':
        case 'cstart':
        case 'end':
        case 'cend':
          if (typeof value === 'string') {
            return value;
          } else if (value) {
            return value.format('YYYY-MM-DD');
          }

          return;
        case 'cpreset':
          if (value === 'null') {
            return null;
          }

          return value;
        case 'preset':
          // We only want the allTime preset to persist in the URL as the other preset dates should
          // not be dynamic when sharing.
          if (value === 'allTime' || value === 'custom') {
            return value;
          }

          return;
        default:
          if (value === null || typeof value === 'undefined') {
            return null;
          }
          return this._super.apply(this, arguments);
      }
    },
    deserializeQueryParam: function deserializeQueryParam(value, key) {
      switch (key) {
        case 'start':
        case 'end':
        case 'cstart':
        case 'cend':
          return (0, _moment.default)(value, 'YYYY-MM-DD');
        default:
          return this._super.apply(this, arguments);
      }
    }
  });
});