define('frontend/pods/components/internal/institutes/edit-form/component', ['exports', 'ember-validations', 'frontend/models/institute'], function (exports, _emberValidations, _institute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var timezones = [{ id: "America/Los_Angeles", name: 'Los Angeles' }, { id: "America/Chicago", name: 'Chicago' }, { id: "America/Denver", name: 'Denver' }, { id: "America/New_York", name: 'New York' }, { id: "America/Phoenix", name: 'Phoenix' }];

  var patientAuthenticationOptions = [{ id: "full", name: 'Last Name, SSN, DoB' }, { id: "mrn_only", name: 'Last Name' }, { id: "mrn_ssn", name: 'Last Name, SSN' }, { id: "dob_only", name: 'Last Name, DoB' }, { id: "email", name: 'Last Name + email' }];

  var providerAuthenticationMechanisms = [{ id: "password", name: 'Password' }, { id: "saml", name: 'Generic Saml' }, { id: "athena", name: 'Athena' }, { id: "epic", name: 'Epic' }];

  exports.default = Ember.Component.extend(_emberValidations.default, {
    classNames: ['edit-form'],

    featureConfiguration: _institute.featureConfiguration,

    configurationData: _institute.configurationData,

    timezoneOptions: timezones,

    authenticationOptions: patientAuthenticationOptions,

    providerAuthenticationMechanisms: providerAuthenticationMechanisms,

    actions: {
      submit: function submit() {
        var action = this.get('submitAction');
        if (typeof action === 'function') {
          return action();
        } else {
          console.warn("No action function was passed to edit-form");
        }
      }
    }
  });
});