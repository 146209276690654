define('frontend/models/patient-current-session', ['exports', 'frontend/models/application', 'ember-data', 'frontend/models/severity-mixin'], function (exports, _application, _emberData, _severityMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_severityMixin.default, {
    UNVIEWED_STATES: ["feedback_available", "feedback_complete"],
    patientId: _emberData.default.attr('string'),
    isTest: _emberData.default.attr("boolean"),
    lastName: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    sessionId: _emberData.default.attr("string"),
    sessionStatus: _emberData.default.attr("string"),
    sessionTargetDate: _emberData.default.attr("date"),
    currentStats: _emberData.default.attr(),
    reportAvailable: _emberData.default.attr("boolean"),
    isReportComplete: _emberData.default.attr("boolean"),
    organizationIds: _emberData.default.attr(),
    careEpisodeIds: _emberData.default.attr(),
    flagIds: _emberData.default.attr(),

    patient: _emberData.default.belongsTo('patient'),

    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName');
      var lastName = this.get('lastName');

      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (firstName) {
        return '' + firstName;
      } else {
        return "Anonymous Patient";
      }
    }),

    hasReport: Ember.computed.notEmpty('currentStats.stats_session'),

    sessionIsFuture: Ember.computed('sessionTargetDate', function () {
      var targetDate = this.get("sessionTargetDate");
      return targetDate && moment(targetDate).isAfter(moment());
    }),

    isWaitingOnPatientCompletion: Ember.computed.equal('sessionStatus', 'awaiting_assessment'),
    isInErrorState: Ember.computed('sessionStatus', function () {
      return this.get('status') === 'locked_out';
    }),
    allowPatientActions: Ember.computed("sessionStatus", function () {
      var status = this.get("sessionStatus");
      return status && status !== 'complete' && status !== 'canceled';
    }),
    statusClass: Ember.computed('sessionStatus', function () {
      return 'status-' + this.get('sessionStatus');
    }),
    isUnviewed: Ember.computed('status', function () {
      return this.get('UNVIEWED_STATES').indexOf(this.get('status')) >= 0;
    })
  });
});