define('frontend/pods/provider/patients/reports/session/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('patient-session', params.patient_session_id);
    },
    afterModel: function afterModel(model) {
      this.transitionTo('provider.patients.show.longitudinal', model.get("patient.id"), { queryParams: { activeSessionId: model.get('id') } });
    }
  });
});