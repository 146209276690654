define('frontend/models/assessment/related-person-assessment', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    patientFirstName: _emberData.default.attr("string"),
    patientLastName: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    dateEmailed: _emberData.default.attr("date"),
    dateCompleted: _emberData.default.attr("date"),
    emailUrl: _emberData.default.attr("string"),
    assessmentUrl: _emberData.default.attr("string"),
    statusEvent: _emberData.default.attr("string"),

    patientName: Ember.computed('patientFirstName', 'patientLastName', function () {
      var firstName = this.get('patientFirstName');
      var lastName = this.get('patientLastName');

      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (firstName) {
        return firstName;
      } else {
        return "Anonymous Patient";
      }
    }),

    availability: Ember.computed('status', function () {
      var status = this.get("status");

      switch (status) {
        case 'future':
          return 'tooEarly';
        case 'expired':
          return 'tooLate';
        default:
          return 'open';
      }
    })
  });
});