define('frontend/pods/provider/patients/show/sessions/show/edit/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),
    // The current provider isn't guaranteed to be loaded but we need it to work out the organization structure.
    beforeModel: function beforeModel() {
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      var _this = this;

      var session = this.modelFor("provider.patients.show.sessions.show");
      var assessments = this.store.findAll("assessment/assessment");
      var patient = this.modelFor("provider.patients.show");
      var scales = this.get("preload.scales").then(function () {
        return _this.get("preload.independentScales");
      });
      var defaultScales = patient.get("defaultScales");
      var activeCareEpisodes = patient.query("careEpisodes", { filter: { status: "active" } });
      var providerOrganizations = this.get("sessionUser").get("activeProvider.organizationSubtrees");
      var allProviders = this.get("preload.clinicians");

      return Ember.RSVP.hash({
        patient: patient,
        session: session,
        assessments: assessments,
        scales: scales,
        defaultScales: defaultScales,
        activeCareEpisodes: activeCareEpisodes,
        providerOrganizations: providerOrganizations,
        allProviders: allProviders
      });
    },


    // Make sure the notification preferences are updated
    afterModel: function afterModel(model) {
      var appointmentDate = model.session.get('schedulingAppointment.startDate');
      model.session.get('assessmentReport.invitations').forEach(function (invitation) {
        // Use whether we have any notifications sent as whether we should enable them.
        var notificationsSent = invitation.get('sendEmailAt') || invitation.get('sendTextAt');
        invitation.updateNotificationsToPreferences(notificationsSent, appointmentDate);
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.session.edit" });
      }
    }
  });
});