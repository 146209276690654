define('frontend/models/assessment/patient-scale-target', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    patient: _emberData.default.belongsTo("patient"),
    scale: _emberData.default.belongsTo("assessment/scale")
  });
});