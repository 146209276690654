define('frontend/pods/components/patient/assessment/invitation-login/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var authenticationMethod = this.get("institute.current.patientAuthenticationMethod");

      var showLastSsn = authenticationMethod == "full" || authenticationMethod == "mrn_ssn";
      var showDob = authenticationMethod === 'full' || authenticationMethod === 'dob_only';
      var showEmail = authenticationMethod === 'email';

      this.setProperties({ showLastSsn: showLastSsn, showDob: showDob, showEmail: showEmail });

      var validationOptions = {};

      if (showLastSsn) {
        validationOptions.lastSsn = {
          validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            is: 4
          }), (0, _emberCpValidations.validator)('number', {
            allowString: true,
            integer: true
          })]
        };
      }

      if (showDob) {
        validationOptions.dobString = {
          validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
            before: 'now',
            precision: 'day',
            format: 'MM/DD/YYYY',
            errorFormat: 'MM/DD/YYYY'
          })]
        };
      }

      if (showEmail) {
        validationOptions.email = {
          validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })]
        };
      }

      var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

      var patientDetails = Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        invitationId: this.get("invitationId"),
        mrn: null,
        dob: null,
        lastSsn: null,
        email: null,
        dobString: Ember.computed('dob', {
          get: function get() {
            if (this.get('dob')) {
              return moment(this.get('dob')).format("MM/DD/YYYY");
            } else {
              return null;
            }
          },
          set: function set(key, value) {
            if (value) {
              this.set("dob", moment(value, "MM/DD/YYYY").toDate());
              return value;
            }
          }
        }),
        dobIsoString: Ember.computed('dob', function () {
          var dob = this.get('dob');

          if (dob) {
            return moment(dob).format('YYYY-MM-DD');
          } else {
            return null;
          }
        })
      }).create(Ember.getOwner(this).ownerInjection());

      this.set("patientDetails", patientDetails);
    },

    isInvalid: Ember.computed.alias("patientDetails.isInvalid"),
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');
        this.set('session.silentInvalidate', true);
        this.set('session.noTransitionOnAuthenticate', true);

        var _get$getProperties = this.get("patientDetails").getProperties("lastSsn", "dobIsoString", "invitationId", "email"),
            lastSsn = _get$getProperties.lastSsn,
            dobIsoString = _get$getProperties.dobIsoString,
            invitationId = _get$getProperties.invitationId,
            email = _get$getProperties.email;

        var trackLoginEvent = function trackLoginEvent(event) {
          return _this.get('metrics').trackEvent({
            event: 'patient.invitation.' + event,
            invitationId: invitationId,
            patientLastSsn: lastSsn,
            patientDob: dobIsoString,
            email: email
          });
        };

        trackLoginEvent('signInAttempt');

        var onSuccess = function onSuccess() {
          trackLoginEvent('signInSucceeded');

          if (Ember.isEmpty(_this.get("session.data.authenticated.confirm_details_required"))) {
            var attemptedTransition = _this.get('session.attemptedTransition');

            if (attemptedTransition) {
              attemptedTransition.retry();
              _this.set('session.attemptedTransition', null);
            } else {
              _this.get("router").transitionTo("patient.assessment.invitation.begin", invitationId);
            }
          } else {
            _this.get("router").transitionTo("patient.assessment.confirm-details", { queryParams: { invitationId: invitationId } });
          }
        };

        var onFailure = function onFailure() {
          _this.get('messages').danger(intl.t('patient.invitation.failed'));
          trackLoginEvent('signInFailed');
        };

        this.get('messages').clear();

        return this.get('session').authenticate('authenticator:devise-patient', {
          institute_id: this.get("institute.current.id"),
          invitation_id: invitationId,
          last_ssn: lastSsn,
          dob: dobIsoString,
          email: email
        }).then(onSuccess, onFailure);
      }
    }
  });
});