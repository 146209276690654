define('frontend/pods/components/provider/contact-support/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      hideModal: function hideModal() {
        this.sendAction('hideContactSupport');
      }
    }
  });
});