define('frontend/mixins/survey/text-question-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    nextQuestionDelay: 0,
    actions: {
      focus: function focus() {
        if (this.get('mode') === "bulk") {
          this._createResponseSet(true, false);
        }
      },
      textChanged: function textChanged() {
        // If we're in bulk mode, we want to override rather than update the existing value, but only once, and only
        // if it's persisted.
        if (this.get("mode") === 'bulk' && !this.get("responseItem.isNew")) {
          var newValue = this.get("currentValue");
          if (this.get('responseItem')) {
            this.get("responseItem").rollbackAttributes();
          }
          var responseItem = this._prepareForNewValue();
          responseItem.set("value", newValue);
        }

        if (this.get("mode") === 'bulk') {
          this.set('responseSet.simpleValue', this.get('responseItem.value'));
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    }
  });
});