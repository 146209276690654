define('frontend/pods/components/provider/assessment/report-v2/emap-wordbank/table-row/component', ['exports', 'frontend/mixins/assessment/question-text'], function (exports, _questionText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionText.default, {
    tagName: 'tr',
    question: Ember.computed.alias('row.question')
  });
});