define("frontend/pods/components/provider/client-password-reset/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {},
    _randomPassword: function _randomPassword(length) {
      var chars = "abcdefghijklmnpqrstuvwxyz123456789";
      var pass = "";
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var newPassword = this._randomPassword(8);
        this.set("newPassword", newPassword);

        var request = this.get("store").createRecord('userPassword', { user: this.get("user"), password: newPassword, passwordConfirmation: newPassword, passwordExpiredAt: new Date() });
        var onSuccess = function onSuccess() {
          _this.set("reset", true);
        };

        var onError = function onError(error) {
          _this.get("messages").danger(error);
        };

        return request.save().then(onSuccess, onError);
      },
      resetCompleted: function resetCompleted() {
        if (this.get("onCompleteRoute")) {
          this.get("router").transitionTo(this.get("onSaveRoute"));
        } else {
          window.history.go(-1);
        }
      }
    }
  });
});