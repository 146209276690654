define('frontend/pods/components/provider/plan/remove-scale-form/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    intl: Ember.inject.service(),
    messages: Ember.inject.service(),
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    request: Ember.computed(function () {
      return this.get("store").createRecord('care/alter-measurement-plan-scale-request', {
        entityTreeNode: this.get('entityTreeNode'),
        allowChange: false,
        effectiveDate: new Date()
      });
    }),

    actions: {
      setScales: function setScales(scalesToRemove) {
        var depromised = scalesToRemove.map(function (obj) {
          return obj.content ? obj.content : obj;
        });
        this.set("request.scalesToRemove", depromised);
      },
      cancelRemoveScale: function cancelRemoveScale() {
        this.get("onCancel")();
      },
      doRemoveScale: function doRemoveScale() {
        var _this = this;

        var onSuccess = function onSuccess(result) {
          _this.get("messages").success(_this.get('intl').t('provider.patientMeasurementPlans.scaleRemoved'));
          if (_this.get('onSave')) {
            _this.get("onSave")(result);
          }
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason, 'alterMeasurementPlanScaleRequest'));
        };

        return this.get('request').save().then(onSuccess, onFailure);
      }
    }
  });
});