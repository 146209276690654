define('frontend/pods/components/common/alert-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'alert-messages',
    service: Ember.inject.service("messages"),
    messages: Ember.computed.reads('service.content')
  });
});