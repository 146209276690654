define('frontend/models/consent', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    mode: _emberData.default.attr("string"),
    assentText: _emberData.default.attr(),
    consentText: _emberData.default.attr(),
    userConsents: _emberData.default.hasMany('userConsents'),
    consentAgreeText: _emberData.default.attr(),
    consentDeclineText: _emberData.default.attr(),
    consentDeferText: _emberData.default.attr(),
    assentAgreeText: _emberData.default.attr(),
    assentDeclineText: _emberData.default.attr(),
    assentDeferText: _emberData.default.attr(),
    consentInfoText: _emberData.default.attr(),
    assentInfoText: _emberData.default.attr(),
    consentInfoButtonText: _emberData.default.attr(),
    assentInfoButtonText: _emberData.default.attr(),
    maxDeferrals: _emberData.default.attr('number'),
    consentPreambleText: _emberData.default.attr(),
    consentPreambleYesText: _emberData.default.attr(),
    consentPreambleNoText: _emberData.default.attr(),
    assentPreambleText: _emberData.default.attr(),
    assentPreambleYesText: _emberData.default.attr(),
    assentPreambleNoText: _emberData.default.attr()
  });
});