define("frontend/mixins/provider-assessment-redirect", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sessionUser: Ember.inject.service(),

    _calculateReferrer: function _calculateReferrer(invitation, referrer) {
      if (invitation.get("referrer")) {
        return invitation.get("referrer");
      } else {
        return referrer;
      }
    },


    actions: {
      // take an assessment as the current provider.  if the invitation was not intended for this provider,
      // redirect to the wrong-provider workflow.
      takeAssessment: function takeAssessment(invitation) {
        var nextRoute = 'provider.assessment.invitation.edit';
        if (invitation.belongsTo('user').id() !== this.get("sessionUser.currentUser.id")) {
          nextRoute = 'provider.assessment.invitation.wrong-provider';
          //don't update invitation until user confirms on next screen
        } else {
          invitation.setProperties({
            takingUser: this.get('sessionUser.currentUser'),
            statusEvent: 'assessment_opened',
            referrer: this._calculateReferrer(invitation, 'provider_session_bulk')
          });
          invitation.save();
        }
        return this.get("router").transitionTo(nextRoute, invitation.id);
      },

      // take an assessment not meant for the current provider.  Will not validate/confirm with the user beforehand
      takeWrongProviderAssessment: function takeWrongProviderAssessment(invitation) {
        invitation.setProperties({
          takingUser: this.get('invitation.user'),
          statusEvent: 'assessment_opened',
          referrer: this._calculateReferrer(invitation, 'provider_session_bulk')
        });
        invitation.save();
        return this.get("router").transitionTo('provider.assessment.invitation.edit', invitation.id);
      },


      // take an assessment not meant for the current provider.  Will not validate/confirm with the user beforehand
      takeProviderAssessmentAndReturn: function takeProviderAssessmentAndReturn(invitation, returnUrl) {
        invitation.setProperties({
          takingUser: this.get('invitation.user'),
          statusEvent: 'assessment_opened',
          referrer: this._calculateReferrer(invitation, 'provider_session_bulk')
        });
        invitation.save();
        return this.get("router").transitionTo('provider.assessment.invitation.edit', invitation.id, { queryParams: { returnUrl: returnUrl } });
      },
      takeClientAssessmentAsProvider: function takeClientAssessmentAsProvider(invitation) {
        var _this = this;

        invitation.setProperties({
          takingUser: this.get('invitation.user'),
          statusEvent: 'assessment_opened',
          referrer: this._calculateReferrer(invitation, 'provider_session_bulk')
        });
        invitation.save().then(function () {
          _this.get("router").transitionTo('provider.assessment.invitation.edit', invitation.id);
        });
      },
      takeRelatedPersonAssessment: function takeRelatedPersonAssessment(invitation) {
        var currentPath = window.location.pathname;
        sessionStorage.setItem("returnUrl", currentPath);
        return this.get("router").transitionTo("provider.exit-to-patient", invitation.get("user.id"), { queryParams: { referrer: 'provider_session', destination: 'related-person.assessment.invitation.begin', params: invitation.get("id") } });
      },
      takePatientAssessment: function takePatientAssessment(invitation) {
        var currentPath = window.location.pathname;
        sessionStorage.setItem("returnUrl", currentPath);
        return this.get("router").transitionTo("provider.exit-to-patient", invitation.get("user.id"), { queryParams: { referrer: 'provider_session', destination: 'patient.assessment.invitation.begin', params: invitation.get("id") } });
      },
      goBackToAssessment: function goBackToAssessment(invitationId) {
        return this.get("router").transitionTo("provider.assessment.invitation.begin", invitationId);
      },
      returnFromAssessment: function returnFromAssessment() {
        var returnPath = sessionStorage.getItem("returnUrl");
        sessionStorage.removeItem("returnUrl");

        if (returnPath) {
          this.get("router").transitionTo(returnPath);
        } else {
          console.warn("No return path found from assessment.");
        }
      }
    }
  });
});