define('frontend/pods/components/internal/scales/scale-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        var scale = this.get("scale");
        scale.updateRawContent();
        scale.set('publish', false);

        var success = function success() {
          _this.get('messages').success("Saved");
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      },
      reject: function reject() {
        var _this2 = this;

        var value = confirm("Reject this scale? This will send it back for another version with changes to be created.");

        if (!value) {
          return Ember.RSVP.resolve();
        }

        var scale = this.get("scale");
        scale.set('publish', false);
        scale.updateRawContent();
        scale.set('statusEvent', 'review_reject');

        var success = function success() {
          _this2.get('messages').success("Rejected");
        };

        var failure = function failure(reason) {
          _this2.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      },
      approve: function approve() {
        var _this3 = this;

        var value = confirm("Approve this scale? It will then move on ready to be published");

        if (!value) {
          return Ember.RSVP.resolve();
        }

        var scale = this.get("scale");
        scale.set('publish', true);
        scale.set('statusEvent', 'review_approve');

        var success = function success() {
          _this3.get('messages').success("Approved");
        };

        var failure = function failure(reason) {
          _this3.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      }
    }
  });
});