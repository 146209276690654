define('frontend/mixins/survey/response-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    allowedTransitions: ['survey.finished', 'survey.exit', 'survey.timeout', 'survey.error'],

    model: function model(params) {
      var response = this.store.findRecord('survey/response', params.response_id, { include: 'invite,response-items' });

      //we need to make sure both the invite and the response have resolved, but just return the response as the model so it can be routable from other places
      return Ember.RSVP.hash({
        response: response,
        invite: response.get('invite')
      }).then(function (hash) {
        return hash.response;
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        //catch transitions back to the beginning and replace them with alerts to the component that back was pressed
        if (this.allowedTransitions.indexOf(transition.targetName) === -1) {
          transition.abort();
          //push history so that the next back button press will also be handled correctly
          if (window.history) {
            window.history.pushState({}, '', this.get('router.url') + "#stay");
          }
          this.currentModel.notifyPropertyChange('isBrowserBackPressed');
        } else {
          return true;
        }
      }
    }
  });
});