define('frontend/pods/provider/profile/edit/notifications/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/pods/base-route'], function (exports, _authenticatedRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_authenticatedRouteMixin.default, {
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      var user = this.get("sessionUser.currentUser");
      return Ember.RSVP.hash({
        user: user,
        subjects: this.store.query('subscriptionSubject', {}),
        userSubscriptions: user.get('userSubscriptions')
      });
    }
  });
});