define('frontend/models/assessment/institute-scale', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    scale: _emberData.default.belongsTo('assessment/scale', { async: true, inverse: 'activeInstituteScale' }),
    internalScale: _emberData.default.belongsTo('assessment/internal-scale', { async: true }),
    institute: _emberData.default.belongsTo('institute', { async: true }),

    disableAdministration: _emberData.default.attr('boolean', { defaultValue: false })
  });
});