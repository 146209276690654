define('frontend/pods/patient/assessment/logout/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    queryParams: {
      reason: {},
      closeOnLogout: {}
    },
    model: function model(params) {
      var returnPath = sessionStorage.getItem("returnUrl");
      sessionStorage.clear();
      localStorage.clear();

      if (this.get("session.isAuthenticated")) {
        var invalidationUrl = returnPath;

        if (!invalidationUrl) {
          if (params.closeOnLogout === 'true') {
            invalidationUrl = this.get("router").generate('patient.assessment.logout', { queryParams: { reason: params.reason } });
          } else {
            invalidationUrl = this.get("router").generate('patient.welcome', { queryParams: { reason: params.reason } });
          }
        }

        this.set("session.invalidationUrl", invalidationUrl);
        this.get("sessionUser").logout();
      } else {

        if (returnPath) {
          window.location.replace(returnPath);
        }
      }

      return {
        reason: params.reason
      };
    }
  });
});