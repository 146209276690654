define('frontend/pods/components/provider/assessment/scale-browser/scale-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // Our language support for patient currently does not correctly register
  // locale, so assume US.
  var languageToLocaleMap = {
    en: 'en_US',
    es: 'es_US'
  };

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    showFilter: false,
    filterAge: Ember.computed.or('filterContext.age', 'filterContext.minAge', 'filterContext.maxAge'),
    ages: Ember.computed('filterContext.minAge', 'filterContext.maxAge', 'filterContext.age', function () {
      var _Ember$getProperties = Ember.getProperties(this.get('filterContext'), 'age', 'minAge', 'maxAge'),
          age = _Ember$getProperties.age,
          minAge = _Ember$getProperties.minAge,
          maxAge = _Ember$getProperties.maxAge;

      if (!age || age === 'Unknown') {
        if (minAge || maxAge) {
          return [minAge || 0, maxAge || 100];
        }
        return [0, 100];
      } else {
        return [age, age];
      }
    }),

    ageText: Ember.computed('filterAge', 'ages', function () {
      if (!this.get("ages")) {
        return null;
      }

      var _get = this.get("ages"),
          _get2 = _slicedToArray(_get, 2),
          minAge = _get2[0],
          maxAge = _get2[1];

      if (!this.get('filterAge')) {
        return null;
      }

      if (minAge === maxAge) {
        return minAge;
      } else {
        return minAge + '-' + maxAge;
      }
    }),

    filterTime: false,
    times: [0, 20],

    timesText: Ember.computed('times', function () {
      var _get3 = this.get("times"),
          _get4 = _slicedToArray(_get3, 2),
          minTime = _get4[0],
          maxTime = _get4[1];

      if (maxTime === 20) {
        return minTime + '-' + maxTime + '+ min';
      } else if (maxTime) {
        return minTime + '-' + maxTime + ' min';
      } else {
        return null;
      }
    }),

    languageOptions: ['en', 'es'],
    languages: Ember.computed('filterContext.patient.language', function () {
      var lang = this.get('filterContext.patient.language');

      if (lang) {
        return [lang];
      } else {
        return [];
      }
    }),

    localesToFilter: Ember.computed('languages.[]', function () {
      return this.get("languages").map(function (l) {
        return languageToLocaleMap[l];
      });
    }),

    tagOptions: Ember.computed('filterContext.scales.[]', function () {
      var scales = this.get("filterContext.scales").toArray();

      if (scales) {
        return scales.flatMap(function (s) {
          return s.get("tags");
        }).uniq();
      } else {
        return [];
      }
    }),
    tags: [],

    categoryOptions: Ember.computed('filterContext.scales.[]', function () {
      var scales = this.get("filterContext.scales").toArray();

      if (scales) {
        return scales.mapBy("category").uniq().compact();
      } else {
        return [];
      }
    }),
    categories: [],

    genderOptions: ['M', 'F'],
    genders: Ember.computed('filterContext.gender', function () {
      var gender = this.get('filterContext.gender');

      if (gender) {
        return [gender];
      } else {
        return [];
      }
    }),

    matchingParticipants: Ember.computed.notEmpty('filterContext.patient'),
    filterParticipants: Ember.computed.notEmpty('filterContext.patient'),
    participantOptions: ['patient', 'provider', 'guardian', 'teacher'],
    participants: Ember.computed('filterContext.patient.clientParticipants.[]', function () {
      if (!this.get('filterContext.patient')) {
        return this.get("participantOptions");
      }
      return this.getWithDefault('filterContext.patient.clientParticipants', []).mapBy('participantType').addObject('provider');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.later(function () {
        _this._filterScales();
        _this._notifyScaleFilter();
      });
    },
    _filterScales: function _filterScales() {
      var allScales = this.get("filterContext.scales");

      this.set('filteredScales', allScales.filter(this._ageFilter()).filter(this._tagsFilter()).filter(this._timeFilter()).filter(this._participantFilter()).filter(this._categoriesFilter()).filter(this._genderFilter()).filter(this._languageFilter()));
    },
    _ageFilter: function _ageFilter() {
      if (!this.get("filterAge")) {
        return function () {
          return true;
        };
      }

      var _get5 = this.get('ages'),
          _get6 = _slicedToArray(_get5, 2),
          filterMinAge = _get6[0],
          filterMaxAge = _get6[1];

      return function (scale) {
        var _scale$getProperties = scale.getProperties('minAge', 'maxAge'),
            minAge = _scale$getProperties.minAge,
            maxAge = _scale$getProperties.maxAge;

        if (minAge && maxAge) {
          return filterMaxAge >= minAge && filterMinAge <= maxAge;
        } else if (minAge) {
          return filterMaxAge >= minAge;
        } else if (maxAge) {
          return filterMinAge <= maxAge;
        } else {
          return true;
        }
      };
    },
    _timeFilter: function _timeFilter() {
      var _get7 = this.get('times'),
          _get8 = _slicedToArray(_get7, 2),
          filterMinTime = _get8[0],
          filterMaxTime = _get8[1];

      var filterMinTimeSeconds = filterMinTime * 60;
      var filterMaxTimeSeconds = filterMaxTime * 60;

      if (!this.get("filterTime")) {
        return function () {
          return true;
        };
      }

      return function (scale) {
        var estimatedTime = scale.get('estimatedTime');

        if (estimatedTime) {
          // Use 20 minutes to mean '20 plus minutes'
          if (filterMaxTime === 20) {
            return filterMinTimeSeconds <= estimatedTime;
          } else {
            return filterMinTimeSeconds <= estimatedTime && filterMaxTimeSeconds >= estimatedTime;
          }
        } else {
          return true;
        }
      };
    },
    _participantFilter: function _participantFilter() {
      if (!this.get("filterParticipants")) {
        return function () {
          return true;
        };
      }
      var participants = this.get("participants");

      return function (scale) {
        return scale.getWithDefault('defaultParticipants', []).any(function (dp) {
          return participants.indexOf(dp) >= 0;
        });
      };
    },
    _languageFilter: function _languageFilter() {
      var locales = this.get("localesToFilter");

      if (Ember.isEmpty(locales)) {
        return function () {
          return true;
        };
      } else {
        return function (scale) {
          var locale = scale.get("locale");
          var defaultParticipants = scale.get('defaultParticipants');

          // Do not filter out provider scales as it's assumed they can understand English.
          if (defaultParticipants && defaultParticipants.includes('provider')) {
            return true;
          }

          if (locale) {
            return !!locale.find(function (l) {
              return locales.includes(l);
            });
          } else {
            return false;
          }
        };
      }
    },
    _tagsFilter: function _tagsFilter() {
      var filterTags = this.get("tags");

      if (Ember.isEmpty(filterTags)) {
        return function () {
          return true;
        };
      } else {
        return function (scale) {
          var tags = scale.get("tags");
          if (tags) {
            return !!tags.find(function (l) {
              return filterTags.includes(l);
            });
          } else {
            return false;
          }
        };
      }
    },
    _categoriesFilter: function _categoriesFilter() {
      var filterCategories = this.get("categories");

      if (Ember.isEmpty(filterCategories)) {
        return function () {
          return true;
        };
      } else {
        return function (scale) {
          var category = scale.get("translatedCategory");
          return filterCategories.includes(category);
        };
      }
    },
    _genderFilter: function _genderFilter() {
      var filterGenders = this.get('genders');

      if (Ember.isEmpty(filterGenders)) {
        return function () {
          return true;
        };
      } else {
        return function (scale) {
          var gender = scale.get("gender");

          if (gender) {
            return filterGenders.includes(gender);
          } else {
            return true;
          }
        };
      }
    },
    _notifyScaleFilter: function _notifyScaleFilter() {
      if (this.onFilterChange) {
        this.onFilterChange(this.get("filteredScales"));
      }
    },
    _hideFilter: function _hideFilter() {
      $(document).off('click.scale-filter');
      if (!this.get('isDestroyed')) {
        this.$('.filter-group .btn-group').removeClass('open');
        this.set("showFilter", false);
      }
    },
    _showFilter: function _showFilter() {
      var _this2 = this;

      this.$('.filter-group .btn-group').addClass('open');
      var handleClick = function handleClick(event) {
        // Don't close on clicks that are in the filter box, or in a power select.
        if (!$(event.target).hasClass('filter-toggle') && !$(event.target).closest('.filter-scales').length && !$(event.target).closest('#ember-basic-dropdown-wormhole').length) {
          _this2._hideFilter();
        }
      };

      var registerClick = function registerClick() {
        return $(document).on('click.scale-filter', handleClick);
      };

      // setTimeout needed or else the click handler will catch the click that called this action
      setTimeout(registerClick);
      this.set("showFilter", true);
    },

    actions: {
      changeMatching: function changeMatching() {
        this.toggleProperty('onlyMatching');
        this._filterScales();
        this._notifyScaleFilter();
      },
      languagesUpdated: function languagesUpdated(el, languages) {
        this.set("languages", languages);
        this._filterScales();
        this._notifyScaleFilter();
      },
      removeLanguage: function removeLanguage(l) {
        this.get("languages").removeObject(l);
        this._filterScales();
        this._notifyScaleFilter();
      },
      tagsUpdated: function tagsUpdated(el, tags) {
        this.set("tags", tags);
        this._filterScales();
        this._notifyScaleFilter();
      },
      removeTag: function removeTag(t) {
        this.get("tags").removeObject(t);
        this._filterScales();
        this._notifyScaleFilter();
      },
      categoriesUpdated: function categoriesUpdated(el, categories) {
        this.set("categories", categories);
        this._filterScales();
        this._notifyScaleFilter();
      },
      removeCategory: function removeCategory(category) {
        this.get("categories").removeObject(category);
        this._filterScales();
        this._notifyScaleFilter();
      },
      gendersUpdated: function gendersUpdated(el, genders) {
        this.set("genders", genders);
        this._filterScales();
        this._notifyScaleFilter();
      },
      removeGender: function removeGender(gender) {
        this.get("genders").removeObject(gender);
        this._filterScales();
        this._notifyScaleFilter();
      },
      toggleFilter: function toggleFilter() {
        var currentlyShowing = this.get("showFilter");

        if (currentlyShowing) {
          this._hideFilter();
        } else {
          this._showFilter();
        }
      },
      ageChanged: function ageChanged() {
        var _this3 = this;

        Ember.run.debounce(this, function () {
          _this3._filterScales();
          _this3._notifyScaleFilter();
        }, 100);
      },
      toggleAgeFilter: function toggleAgeFilter() {
        this.toggleProperty('filterAge');
        this._filterScales();
        this._notifyScaleFilter();
      },
      toggleMatchingParticipants: function toggleMatchingParticipants() {
        this.toggleProperty('matchingParticipants');
        this._filterScales();
        this._notifyScaleFilter();
      },
      toggleParticipantFilter: function toggleParticipantFilter() {
        this.toggleProperty('filterParticipants');
        this.set('matchingParticipants', false);
        this._filterScales();
        this._notifyScaleFilter();
      },
      participantsUpdated: function participantsUpdated(el, participants) {
        this.set("participants", participants);
        this._filterScales();
        this._notifyScaleFilter();
      },
      removeParticipant: function removeParticipant(participant) {
        this.set('matchingParticipants', false);
        this.get("participants").removeObject(participant);
        this._filterScales();
        this._notifyScaleFilter();
      },
      timeChanged: function timeChanged() {
        var _this4 = this;

        Ember.run.debounce(this, function () {
          _this4._filterScales();
          _this4._notifyScaleFilter();
        }, 100);
      },
      toggleTimeFilter: function toggleTimeFilter() {
        this.toggleProperty('filterTime');
        this._filterScales();
        this._notifyScaleFilter();
      }
    }
  });
});