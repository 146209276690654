define('frontend/pods/components/provider/patients/patient-info-timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    tagName: '',

    showMrn: Ember.computed.not('institute.configuration.hideMrn')
  });
});