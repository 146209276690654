define('frontend/pods/components/provider/plan/schedule-calculator-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    buttonClasses: "btn btn-raised btn-primary calculate-schedule",
    request: Ember.computed('entityTreeNode', 'demographics.age', 'demographics.gender', 'dates', function () {
      return this.get('store').createRecord('care/measureScheduleCalculatorRequest', {
        entityTreeNode: this.get('entityTreeNode'),
        age: this.get('demographics.age'),
        gender: this.get('demographics.gender'),
        dates: this.get('dates')
      });
    }),
    actions: {
      calculate: function calculate() {
        var _this = this;

        var onSuccess = function onSuccess(response) {
          response.get('results').forEach(function (session) {
            session.scales = session.scale_ids.map(function (id) {
              return _this.get('store').peekRecord('assessment/scale', id);
            });
          });

          return response.get('results');
        };

        var thisRequest = this.get('store').createRecord('care/measureScheduleCalculatorRequest', this.get('request').getProperties('entityTreeNode', 'age', 'gender', 'dates'));

        var result = thisRequest.save().then(onSuccess);

        this.get('onCalculate')(result);

        return result;
      }
    }
  });
});