define('frontend/models/assessment/answer', ['exports', 'frontend/models/application', 'ember-data', 'frontend/utils/assessment/analytics'], function (exports, _application, _emberData, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    value: _emberData.default.attr(''),
    normalizedScore: _emberData.default.attr('number'),
    question: _emberData.default.belongsTo('assessment/question', { async: true }),
    option: _emberData.default.belongsTo('assessment/question-option', { async: true }),
    shown: _emberData.default.attr('boolean'),
    shortname: _emberData.default.attr(), //shortcut to question/shortname
    isEditing: _emberData.default.attr('boolean', { transient: true, default: false }),
    user: _emberData.default.belongsTo('user'),
    participant: _emberData.default.belongsTo('participant', { polymorphic: true }),
    shownButNotAnswered: Ember.computed('value', function () {
      if (this.get('value') !== null) {
        return false;
      }

      return this.get('question.subtype') !== 'checkbox' && !this.get('question.optional');
    }),
    hideIfUnanswered: Ember.computed.equal('question.optional', true),
    unanswered: Ember.computed('value', function () {
      return this.get('value') === null;
    }),
    answeredClass: Ember.computed('value', function () {
      return _analytics.default.computeQuestionScore(this).answered ? '' : 'noAnswer';
    }),
    score: Ember.computed('value', function () {
      return _analytics.default.computeQuestionScore(this);
    }),
    scoreClass: Ember.computed('score', function () {
      return _analytics.default.getAnswerScoreClass(this);
    }),
    computedValue: _emberData.default.hasMany('assessment/computedValue', { async: true }),
    report: _emberData.default.belongsTo('assessment/report')
  });
});