define('frontend/models/user-help-state', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    adviceLevel: _emberData.default.attr("string"),
    user: _emberData.default.belongsTo('user', { async: true }),
    state: _emberData.default.attr("object"),
    sessionState: _emberData.default.attr("object", { defaultValue: function defaultValue() {
        return {};
      }, transient: true })
  });
});