define("frontend/locales/en/metrics", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    clinical: {
      viewedAssessments: {
        title: "Reports reviewed",
        description: "Percentage of reports viewed by the primary provider"
      },
      activeUsers: {
        title: "Active Providers",
        description: "Percentage of providers who have logged into the system"
      }
    },
    user: {
      logins: "User Logins",
      viewedAssessmentPercentage: "% of assessments viewed by clinician",
      viewedAssessments: "Assessments viewed by clinician",
      withLogin: "Users having logged in",
      withoutLogin: "Users having not logged in"
    },
    patients: {
      atLeastOneAssessment: {
        title: "Clients measured",
        description: "Clients with at least one completed assessment"
      },
      total: "Total patients in system",
      oneAssessment: "Patients with at least one assessment",
      totalCompletedAssessments: "Total completed assessments",
      totalEmailedInvitations: "Total emailed assessment invitations"
    },
    intervals: {
      hour: "hourly",
      day: "daily",
      week: "weekly",
      month: "monthly",
      year: "yearly"
    },
    last: {
      hour: "in the previous hour",
      day: "yesterday",
      week: "last week",
      month: "last month",
      year: "last year"
    },
    current: {
      hour: "in the current hour",
      day: "today",
      week: "this week",
      month: "this month",
      year: "this year"
    },
    periods: {
      hour: "in the hour",
      day: "in the day",
      week: "in the week",
      month: "in the month",
      year: "in the year"
    },
    time: "{interval} ending {endDate}",
    total: "total",
    byAllProviders: "by all providers",
    byName: "by {name}",
    percentage: "%",
    count: "Count",
    active: "Active"
  };
});