define('frontend/pods/components/reporting/columns/date-range/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['detail', 'text-nowrap'],
    startDate: Ember.computed("value", function () {
      var value = this.getWithDefault("value", []);

      return value[0];
    }),
    endDate: Ember.computed("value", function () {
      var value = this.getWithDefault("value", []);

      return value[1];
    }),
    hasStartDate: Ember.computed.notEmpty("startDate"),
    hasEndDate: Ember.computed.notEmpty("endDate")
  });
});