define('frontend/helpers/asset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(path /*, hash*/) {
      if (path.length && path.length > 0) {
        return this.assets.resolve(path[0]);
      } else {
        return this.assets.resolve(path);
      }
    }
  });
});