define('frontend/pods/components/provider/patient-sessions/list-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "targetDate",
        template: "components/common/server-side-table/absolute-date",
        title: intl.t('model.patientSession.effectiveStart'),
        sortPrecedence: 1,
        sortDirection: "asc"
      }, {
        propertyName: "status",
        title: intl.t('provider.assessments.invitationDetails.status'),
        template: "components/provider/patient-sessions/list-component/session-status",
        disableSorting: true
      }, {
        title: intl.t('common.actions'),
        template: "components/provider/patient-sessions/list-component/action-buttons",
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 10);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient && typeof patient.query === 'function') {
          var data = Ember.Object.create({
            store: this.get("patient"),
            modelName: 'patientSessions',
            query: { include: "assessment-report,scheduling-appointment", filter: { show_test: 'all' } }
          });
          this.set("data", data);
        }
      }
    },

    actions: {}

  });
});