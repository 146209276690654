define('frontend/pods/components/provider-admin/payors/create-payor/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    messages: Ember.inject.service(),
    metrics: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var _this = this;

        var onSuccess = function onSuccess(result) {
          _this.get('metrics').trackEvent({ event: 'provider.payors.payorAdded' });
          _this.get('router').transitionTo('provider.admin.payors.show', result.get('id'));
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason));
        };

        return this.get('model').save().then(onSuccess, onFailure);
      },
      setPayor: function setPayor(payor) {
        Ember.set(this.get('model'), 'payor', payor);
        Ember.set(this.get('model'), 'name', payor.get('name'));
      }
    }
  });
});