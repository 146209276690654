define('frontend/models/user-login-code', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    codeMap: _emberData.default.attr(),
    patientSession: _emberData.default.belongsTo('patientSession'),
    patientSessionId: _emberData.default.attr('string')
  });
});