define('frontend/pods/components/provider-admin/configuration-active-values/expanded-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    classNames: 'configuration-details',
    showChange: false,
    defaultValue: Ember.computed('record.configurationProperty.defaultValue', function () {
      var value = this.get("record.configurationProperty.defaultValue");
      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
        return JSON.stringify(value);
      }

      return value;
    }),

    _findOrCreateConfig: function _findOrCreateConfig() {
      var currentValue = this.get('record.configurationValue');
      var currentNode = this.get('rowContext.entityTreeNode');

      if (currentValue && currentValue.get('entityTreeNode.path') === currentNode.get('path')) {
        return this.get('record.configurationValue');
      } else {
        return this.get('store').createRecord('configurationValue', {
          configurationProperty: this.get('record.configurationProperty'),
          value: this.get('record.value'),
          entityTreeNode: currentNode
        });
      }
    },

    actions: {
      onEntityTreeNodeSelected: function onEntityTreeNodeSelected(node) {
        this.get('actionHandler')('onEntityTreeNodeSelected', node);
      },
      beginChangeConfig: function beginChangeConfig() {
        this.setProperties({
          showChange: true,
          newConfig: this._findOrCreateConfig()
        });
      },
      cancelChangeConfig: function cancelChangeConfig() {
        this.get('newConfig').rollbackAttributes();
        this.setProperties({
          showChange: false,
          newConfig: null
        });
      },
      onSaveConfig: function onSaveConfig() {
        this.get('actionHandler')('onConfigurationChange');
      }
    }
  });
});