define('frontend/pods/components/reporting/filter-selector/component', ['exports', 'frontend/mixins/fixed-modal-mixin'], function (exports, _fixedModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fixedModalMixin.default, {
    classNames: ['filter-selector'],
    prefix: 'unknown',
    selectorClassName: Ember.computed('prefix', function () {
      return 'report-' + this.get('prefix') + '-selector';
    }),

    actions: {
      setCurrentValue: function setCurrentValue(value) {
        var _this = this;

        this.sendAction("valueSelected", value);
        Ember.run.once(function () {
          return _this.send('toggleModal');
        });
      }
    }
  });
});