define('frontend/pods/provider/billing/charges/index/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),

    rootPath: Ember.computed('currentInstitute', 'institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations"),
        institute: this.get("institute.current")
      });
    },
    model: function model() {
      var reimbursementSummary = this.get('store').createRecord('billing/reimbursementSummaryRequest', {}).save();

      return Ember.RSVP.hash({
        providerEntities: this.get("preload.providerEntities"),
        organizationEntities: this.get("preload.organizationEntities"),
        reimbursementSummary: reimbursementSummary
      });
    },
    setupController: function setupController(controller) {
      // We can't inject the default path in because default params happen before the init hooks, so we have to 
      // do it here instead.
      controller.set('rootPath', this.get("rootPath"));
      if (!controller.get('reportPath')) {
        controller.setProperties({ reportPath: this.get('rootPath') });
      }

      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ path: undefined });
      }
    },
    serializeQueryParam: function serializeQueryParam(value, key) {
      switch (key) {
        case 'start':
        case 'end':
          if (typeof value === 'string') {
            return value;
          } else if (value) {
            return value.format('YYYY-MM-DD');
          }

          return;
        case 'preset':
          // We only want the allTime preset to persist in the URL as the other preset dates should
          // not be dynamic when sharing.
          if (value === 'allTime' || value === 'custom') {
            return value;
          }

          return;
        default:
          if (value === null || typeof value === 'undefined') {
            return null;
          }
          return this._super.apply(this, arguments);
      }
    },
    deserializeQueryParam: function deserializeQueryParam(value, key) {
      switch (key) {
        case 'start':
        case 'end':
          return moment(value, 'YYYY-MM-DD');
        default:
          return this._super.apply(this, arguments);
      }
    }
  });
});