define('frontend/models/assessment/color-settings', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var mnemonicIndexColorMap = {
    alliance: 4,
    attachment: 5,
    avoidance: 14,
    connectedness: 1,
    demoralization: 9,
    eating: 12,
    emotionalDistance: 11,
    expressedTherapyNeeds: 19,
    hurtfulRumination: 16,
    hypervigilance: 10,
    perfectionism: 13,
    negativeAffect: 15,
    psychosis: 3,
    relationalDistress: 8,
    resilience: 18,
    socialRole: 0,
    somaticAnxiety: 17,
    substanceUse: 2,
    suicideRisk: 6,
    sfss: 12,
    sfss_C: 13,
    sfssI: 16,
    sfssE: 8,
    sfssI_C: 17,
    sfssE_C: 9,
    taqs: 4,
    ptpbChs: 1,
    bmslss: 18,
    sss: 21,
    sss_C: 22,
    myts: 23,
    myts_C: 24,
    ycis: 25,
    toes: 26,
    toes_C: 27,
    cgsq: 28,
    swls: 29
  };

  var defaultColorScale = d3.scale.category20().domain(d3.range(0, 19));

  // Expects to be supplied computedValues
  exports.default = Ember.Object.extend({
    init: function init() {
      this._super();
      this.set("userScaleColorMap", {});
    },


    userColorMap: {},
    // colors picked to be as far outside the red-orange-yellow-green of severity colors
    userColorScale: ['#1f77b4', '#00bbbb', '#338888', '#6688bb', '#113377', '#007777'],
    // gets the color for rendering a user in this graph
    getColorForUser: function getColorForUser(user) {
      var userColorMap = this.get('userColorMap');
      var userId = user.get('id');
      if (!userColorMap[userId]) {
        var numAssignedColors = Object.keys(userColorMap).length;
        var colorScale = this.get('userColorScale');
        userColorMap[userId] = colorScale[numAssignedColors % colorScale.length];
      }
      return userColorMap[userId];
    },


    userScaleColorMap: {},
    defaultColorScale: d3.scale.category20().domain(d3.range(0, 19)),

    // Designed for graphs where there are potentially multiple scales AND
    // multiple users for each scale
    getColorForScale: function getColorForScale(scale, index) {
      return defaultColorScale(index);
    },


    colorMode: Ember.computed('computedValues.[]', function () {
      var cvs = this.get("computedValues");

      // One scale only
      var scaleCount = _lodash.default.uniqBy(cvs, function (cv) {
        return cv.get('scale.id');
      }).length;

      // TODO: refactor so that multiple different base mnemonics are supported correctly
      // const byScale = _.groupBy(cvs, cv => cv.get('scale.baseMnemonic'));
      // const multiplePerScale = _.some(Object.values(byScale), group => _.uniqBy(group, g => g.get('user.id')).length > 1);

      if (scaleCount > 1) {
        var scaleAndUserCount = _lodash.default.uniqBy(cvs, function (cv) {
          return cv.belongsTo('scale').id() + '.' + cv.belongsTo("user").id();
        }).length;
        var multiplePerScale = scaleAndUserCount > scaleCount;

        return multiplePerScale ? 'perScaleAndUser' : 'perScale';
      } else {
        var userCount = _lodash.default.uniqBy(cvs, function (cv) {
          return cv.get('user.id');
        });

        return userCount.length > 1 ? 'perUser' : 'perScale';
      }
    }),

    orderedScaleMnemonics: Ember.computed('computedValues.[]', function () {
      return this.get("computedValues").mapBy('scale.mnemonic').uniq();
    }),

    colorFor: function colorFor(scale, user) {
      var mode = this.get("colorMode");

      // If you're not passed in any user info, just use the scale.
      if (!user) {
        return this.colorForScale(scale);
      }

      switch (mode) {
        case 'perUser':
          return this.get("reportSettings").getColorForUser(user);
        case 'perScaleAndUser':
          return this.colorForUserAndScale(scale, user);
        default:
        case 'perScale':
          return this.colorForScale(scale);
      }
    },
    colorForScale: function colorForScale(scale) {
      var mnemonic = scale.get('mnemonic');
      var index = mnemonicIndexColorMap[mnemonic];
      if (!index) {
        index = this.get("orderedScaleMnemonics").indexOf(mnemonic);
      }

      return defaultColorScale(index);
    },


    // Designed for graphs where there are potentially multiple scales AND
    // multiple users for each scale
    colorForUserAndScale: function colorForUserAndScale(scale, user) {
      var map = this.get('userScaleColorMap');

      var userId = user.get('id');
      var scaleId = scale.get('id');

      var key = scaleId + '-' + userId;
      if (!map[key]) {
        var numAssignedColors = Object.keys(map).length;
        map[key] = defaultColorScale(numAssignedColors);
      }
      return map[key];
    }
  });
});