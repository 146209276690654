define('frontend/pods/patient/assessment/invitation/return/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    session: Ember.inject.service(),
    model: function model(params, transition) {
      var status = transition.params['patient.assessment.invitation.return.status'].status;
      var invitation = this.modelFor("patient.assessment.invitation");
      return {
        status: status,
        invitation: invitation
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var invitation = this.modelFor("patient.assessment.invitation");

      var referrer = sessionStorage.getItem("referrer");
      sessionStorage.removeItem("referrer");

      var closeOnLogout = referrer === 'email' || referrer === 'text';

      this.trackMetricNow({ event: "patient.assessment.invitation." + model.status, invitationId: invitation.get("id") });

      if (invitation && model.status === 'complete') {
        invitation.statusEvent = 'assessment_complete';
        invitation.save().then(function () {
          _this.get("router").transitionTo('patient.assessment.logout', { queryParams: { closeOnLogout: closeOnLogout, reason: model.status } });
        });
      } else {
        this.get("router").transitionTo('patient.assessment.logout', { queryParams: { closeOnLogout: closeOnLogout, reason: model.status } });
      }
    }
  });
});