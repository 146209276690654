define('frontend/pods/components/provider/plan/entity-active-plans/component', ['exports', 'frontend/mixins/provider/plan/active-plans'], function (exports, _activePlans) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_activePlans.default, {
    classNames: ['entity-active-plans'],
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    exactMatchOnly: true,
    pathFilterType: Ember.computed('exactMatchOnly', function () {
      return this.get("exactMatchOnly") ? 'matchesPath' : 'availableForPath';
    }),
    columns: Ember.computed(function () {
      var intl = this.get('intl');

      var columns = [{
        template: 'components/provider/plan/entity-active-plans/name-column',
        title: intl.t('model.measurementPlan.title')
      }, {
        template: 'components/provider/plan/entity-active-plans/entity-path',
        title: intl.t('model.entity.path')
      }, {
        propertyName: 'planType',
        title: intl.t('model.entityMeasurementPlan.planType'),
        disableSorting: true
      }, {
        component: 'components/provider/plan/entity-active-plans/filter-summary',
        title: intl.t('model.entityMeasurementPlan.filters')
      }, {
        title: '',
        template: 'components/provider/plan/entity-active-plans/action-buttons'
      }];

      return columns;
    }),

    actions: {
      onPlanChange: function onPlanChange() {
        this.notifyPropertyChange('entityMeasurementPlans');
        this.get('messages').success(this.get('intl').t('provider.measurementPlans.planUpdated'));

        if (this.get("onPlanChange")) {
          this.get('onPlanChange')();
        }
      }
    }
  });
});