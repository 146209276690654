define('frontend/pods/components/reporting/percentage-change/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    isPercentage: false,
    comparisonClass: Ember.computed('lowerIsBetter', 'comparisonPercentChange', function () {
      var lowerIsBetter = this.get('lowerIsBetter');
      var value = this.get("comparisonPercentChange");

      if (value === 0) {
        return 'no-change';
      } else if (value < 0) {
        return lowerIsBetter ? 'change-good' : 'change-bad';
      } else {
        return lowerIsBetter ? 'change-bad' : 'change-good';
      }
    }),

    comparisonIsNa: Ember.computed.equal('comparisonPercentChange', 'N/A'),

    comparisonPercentChange: Ember.computed('isPercentage', 'value', 'comparisonValue', function () {
      var _getProperties = this.getProperties('value', 'comparisonValue', 'isPercentage'),
          value = _getProperties.value,
          comparisonValue = _getProperties.comparisonValue,
          isPercentage = _getProperties.isPercentage;

      if (typeof value === 'number' && typeof comparisonValue === 'number') {
        if (value === 0) {
          if (!comparisonValue) {
            // If there's no data for either, return nothing.
            return null;
          }

          // Give -100% change if no data.
          return -1;
        }

        // For now, report percentage change in percentage in absolute terms.
        if (isPercentage) {
          return value - comparisonValue;
        } else {
          if (comparisonValue === 0) {
            return 'N/A';
          } else {
            // If you don't do the abs, negative changes are reversed.
            return (value - comparisonValue) / Math.abs(comparisonValue);
          }
        }
      } else {
        return null;
      }
    })
  });
});