define('frontend/services/reports/my-adherence', ['exports', 'frontend/models/reporting/report'], function (exports, _report) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _report.default.create({
    shortcode: 'adherence',
    backendShortcode: 'adherence_drilldown_v2',
    role: 'provider',
    defaultLimit: 15,
    showDateRangeControl: true,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    // By default zoom to the current provider. If not available, e.g. internal user, show the whole institute.
    defaultPathFn: function defaultPathFn() {
      var providerId = this.get("sessionUser.currentUser.provider.id");

      if (providerId) {
        return 'provider/' + providerId;
      } else {
        return this.get('institute.current.rootPath');
      }
    },
    icon: 'rulerMeasure.svg',
    mockData: null,
    showFlagFilter: true,
    showConditionFilter: true,
    columnProperties: {
      repeatPatients: {
        shown: false
      },
      measuredPatients: {
        shown: false
      },
      canceledPercent: {
        shown: false,
        lowerIsBetter: true
      },
      totalCanceled: {
        lowerIsBetter: true
      },
      providerRole: {
        featured: true
      },
      totalComplete: {
        featured: true,
        headerIcon: 'fa fa-check',
        className: 'primary',
        headline: true
      },
      totalFulfilled: {
        featured: true,
        headerIcon: 'fa fa-calendar'
      },
      completePercent: {
        featured: true,
        displayType: 'percent-bar',
        headline: true
      },
      reportViews: {
        featured: true
      },
      notViewedByPrimary: {
        displayType: 'alert-nonzero',
        lowerIsBetter: true
      },
      viewedOnTimePercent: {
        shown: false
      }
    },
    mockSummaryData: {
      statString: '79%',
      statLabel: 'sessions measured',
      trend: '2%',
      trendIcon: 'fa fa-arrow-up'
    }
  });
});