define('frontend/pods/provider/plan/entities/create/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),
    queryParams: {
      path: {},
      planType: {}
    },

    rootPath: Ember.computed('institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations"),
        // I'm not sure why this is necessary to preload but there's a weird timing bug where the list doesn't load
        // if it's only in model()
        treatmentTrackEntities: this.get("preload.activeTreatmentTrackEntities"),
        treatmentServiceEntities: this.get("preload.treatmentServiceEntities"),
        panelEntities: this.get("preload.panelEntities")
      });
    },
    model: function model(params) {
      var _this = this;

      var path = params.path || this.get('rootPath');
      var planType = params.planType || 'core';
      var treeNode = this.get("store").findRecord('entityTreeNode', path, { include: 'collections' });
      var scales = this.get("preload.scales").then(function () {
        return _this.get("preload.independentScales");
      });
      var measurementPlans = this.get('store').query('care/measurementPlan', { filter: { preset: 'reusable' }, include: 'measurement-plan-scales,author', page: { limit: 100 } });
      return treeNode.then(function (treeNodeResult) {
        var entityMeasurementPlan = _this.store.createRecord('care/entityMeasurementPlan', { planType: planType, entityTreeNode: treeNodeResult });
        return Ember.RSVP.hash({
          entityTreeNode: treeNodeResult,
          providerEntities: _this.get("preload.providerEntities"),
          organizationEntities: _this.get("preload.organizationEntities"),
          treatmentTrackEntities: _this.get("preload.activeTreatmentTrackEntities"),
          treatmentServiceEntities: _this.get("preload.treatmentServiceEntities"),
          panelEntities: _this.get("preload.panelEntities"),
          entityMeasurementPlan: entityMeasurementPlan,
          scales: scales,
          measurementPlans: measurementPlans
        });
      });
    },


    breadCrumb: null
  });
});