define('frontend/serializers/assessment/related-person-assessment', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      dateCompleted: { serialize: false }, // never send server-side timestamps
      dateEmailed: { serialize: false }, // never send server-side timestamps
      assessmentUrl: { serialize: false },
      emailUrl: { serialize: false },
      status: { serialize: false },
      patientFirstName: { serialize: false },
      patientLastName: { serialize: false }
    }
  });
});