define("frontend/pods/components/provider-admin/users/edit-user-roles-row/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    hidden: Ember.computed("role", function () {
      // We don't want to show our developer role. We could probably expand this to have hidden properties,
      // but this is good for now and we can always revisit how this works.
      if (this.get("role.name") == "Developer") {
        return true;
      }
      return false;
    }),

    isChecked: Ember.computed("user.roles.[]", "role", function () {
      return this.getWithDefault("user.roles", []).includes(this.get("role"));
    }),

    actions: {
      selectRole: function selectRole() {
        this.set("user.roles", [this.get("role")]);
      }
    }
  });
});