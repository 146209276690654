define('frontend/services/configuration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    configurationFor: function configurationFor(node) {
      var onSuccess = function onSuccess(result) {
        var values = {};

        result.forEach(function (value) {
          values[value.get('code')] = value.get('value');
        });

        return Ember.Object.create(values);
      };

      if (node) {
        var promise = this.get("store").query('activeConfigurationValue', { filter: { activeFor: node.get('path') }, page: { limit: 100 } }).then(onSuccess);

        return _emberData.default.PromiseObject.create({ promise: promise });
      } else {
        return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function (resolve) {
            resolve(Ember.Object.create({}));
          }) });
      }
    }
  });
});