define('frontend/utils/survey/redcap-importer', ['exports', 'frontend/models/assessment/scale-templates'], function (exports, _scaleTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.defaultConfiguration = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /* eslint-disable no-useless-escape */
  var theirPlaceholderRegex = new RegExp(/(?:^|\W)\[([\w\.]+?)\](?:\W|$)/g);

  // Match [question] = '3' with groups for the question and the value
  var basicBranchRegex = new RegExp(/(?:^|\W)\[([\w\.]+?)\]\s*=\s*'?(\w)'?/);
  var checkboxSelectRegex = new RegExp(/(?:^|\W)\[([\w\.]+?)\((\d)\)\]\s*=\s*'?(1)'?/);
  var basicGreaterThan = new RegExp(/(?:^|\W)\[([\w\.]+?)\]\s*>\s*'?(\w)'?/);
  /* eslint-enable no-useless-escape */

  // Redcap importer functionality

  var transformBranchingLogicBasic = function transformBranchingLogicBasic(logic) {
    var match = basicBranchRegex.exec(logic);

    if (match) {
      var _match = _slicedToArray(match, 3),
          questionName = _match[1],
          value = _match[2];

      return {
        "leftSide": {
          "question": questionName
        },
        "rightSide": {
          "value": value
        },
        "operator": "==="
      };
    } else {
      return null;
    }
  };

  var transformGreaterThanLogic = function transformGreaterThanLogic(logic) {
    var match = basicGreaterThan.exec(logic);

    if (match) {
      var _match2 = _slicedToArray(match, 3),
          questionName = _match2[1],
          value = _match2[2];

      return {
        "leftSide": {
          "question": questionName
        },
        "rightSide": {
          "value": value
        },
        "operator": ">"
      };
    } else {
      return null;
    }
  };

  var transformCheckboxLogicBasic = function transformCheckboxLogicBasic(logic) {
    var match = checkboxSelectRegex.exec(logic);

    if (match) {
      var _match3 = _slicedToArray(match, 3),
          questionName = _match3[1],
          value = _match3[2];

      return {
        "leftSide": {
          "question": questionName
        },
        "rightSide": {
          "value": value
        },
        "operator": "contains"
      };
    } else {
      return null;
    }
  };

  var transformBranchingLogic = function transformBranchingLogic(logic) {
    var rules = [transformBranchingLogicBasic, transformCheckboxLogicBasic, transformGreaterThanLogic];

    var showLogic = rules.map(function (rule) {
      return rule(logic);
    });

    return showLogic.compact()[0];
  };

  var defaultConfiguration = {
    questionConfig: {
      radio: {
        template: 'radioQuestion-v1',
        options: function options() {
          return { showValue: true };
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: true
      },
      // Not a real redcap option but useful for us.
      radioLong: {
        template: 'radioQuestion-v1',
        options: function options() {
          return { showValue: true };
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: false
      },
      radioGrid: {
        template: 'gridRadioQuestion-v1',
        options: function options() {
          return { showValue: true };
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: true
      },
      radioGridSmall: {
        template: 'gridRadioQuestion-v1',
        options: function options() {
          return { showValue: true, textSize: 'small' };
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: true
      },
      checkbox: {
        template: 'checkboxQuestion-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      yesno: {
        template: 'yesNoQuestionNumeric-v1',
        options: function options() {
          return {};
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: false
      },
      notes: {
        template: 'essayInput-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      descriptive: {
        template: 'instructions-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      text: {
        template: 'textInput-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      number: { // Note this doesn't real exist in redcap but we use it to help preprocess scales.
        template: 'numberInput-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      essay: { // Note this doesn't real exist in redcap but we use it to help preprocess scales.
        template: 'essayInput-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      slider: { // Note this doesn't real exist in redcap but we use it to help preprocess scales.
        template: 'slider-v1',
        options: function options() {
          return { headers: [{
              "title": "0%",
              "alignment": "left"
            }, {
              "title": "100%",
              "alignment": "right"
            }] };
        },
        includeScoreInReportText: false
      },
      date: { // Also doesn't exist
        template: 'dateInput-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      likert: { // Also doesn't exist
        template: 'likertWithOptions-v1',
        options: function options() {
          return {};
        },
        guessNormalizedScores: true,
        reverseNormalizedMap: false,
        includeScoreInReportText: true,
        optionParser: function optionParser(question, options) {
          var leftHeader = { en: options[0].en };
          var rightHeader = { en: options[options.length - 1].en };

          options.forEach(function (option) {
            option.en = option.value;
          });

          Ember.set(question, 'templateParameters.leftText', leftHeader);
          Ember.set(question, 'templateParameters.rightText', rightHeader);

          return options;
        }
      },
      dropdown: {
        template: 'selectQuestion-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      monthyear: {
        template: 'monthYearQuestion-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      monthyearduration: {
        template: 'monthYearDurationQuestion-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      },
      yearrange: {
        template: 'yearRange-v1',
        options: function options() {
          return {};
        },
        includeScoreInReportText: false
      }
    }
  };

  var parseForDependentVariables = function parseForDependentVariables(text) {
    var index = 1;
    var matches = [];
    var match = theirPlaceholderRegex.exec(text);
    while (match) {
      matches.push(match[index]);
      match = theirPlaceholderRegex.exec(text);
    }

    matches.forEach(function (match) {
      text = text.replace("[" + match + "]", "{{" + match + "}}");
    });

    return {
      text: text,
      dependentVariables: matches
    };
  };

  var normalizedScoreMap = {
    2: [1, 7],
    3: [1, 4, 7],
    4: [1, 2, 6, 7],
    5: [1, 2, 4, 6, 7],
    6: [1, 2, 4, 4, 6, 7],
    7: [1, 2, 4, 4, 4, 6, 7],
    10: [1, 1, 2, 2, 4, 4, 6, 6, 7, 7],
    11: [1, 1, 2, 2, 4, 4, 4, 6, 6, 7, 7]
  };

  var guessNormalizedScores = function guessNormalizedScores(options, reversed, questionConfiguration) {
    var numItems = options.length;

    var scoreMap = normalizedScoreMap[numItems];

    if (!scoreMap) {
      return options;
    }

    var applyReversed = !questionConfiguration.reverseNormalizedMap && reversed || questionConfiguration.reverseNormalizedMap && !reversed;

    options.forEach(function (item, index) {
      if (applyReversed) {
        item.normalized_score = scoreMap[scoreMap.length - 1 - index];
      } else {
        item.normalized_score = scoreMap[index];
      }
    });

    return options;
  };

  exports.defaultConfiguration = defaultConfiguration;


  var RedcapImporter = Ember.Object.extend({
    scaleTemplates: Ember.inject.service(),
    fileValid: function fileValid(csv) {
      return !!csv.data[0]['Variable / Field Name'];
    },
    updatePage: function updatePage(row, survey, currentPage, registry) {
      var newPage = void 0;

      if (row['Section Header']) {
        // Use a dash as a reset for a new page
        if (row['Section Header'] === '-') {
          var template = this.get("scaleTemplates").lookup('page', 'emptyPage-v1');
          newPage = _scaleTemplates.Page.createNewFromTemplate(template, null, Ember.getOwner(this));
        } else {
          var _template = this.get("scaleTemplates").lookup('page', 'pageWithHeader-v1');
          newPage = _scaleTemplates.Page.createNewFromTemplate(_template, registry, Ember.getOwner(this));

          var _parseForDependentVar = parseForDependentVariables(row['Section Header']),
              text = _parseForDependentVar.text,
              dependentVariables = _parseForDependentVar.dependentVariables;

          Ember.set(newPage, 'templateParameters.header', { en: text });
          if (!Ember.isEmpty(dependentVariables)) {
            Ember.set(newPage, 'properties.dependentVariables', dependentVariables);
          }
        }
      } else if (!currentPage) {
        var _template2 = this.get("scaleTemplates").lookup('page', 'emptyPage-v1');
        newPage = _scaleTemplates.Page.createNewFromTemplate(_template2, null, Ember.getOwner(this));
      }

      if (newPage) {
        Ember.set(newPage, 'templateParameters.title', 'Survey Page');

        newPage.updateContent();

        survey.get("pages").addObject(newPage);

        return newPage;
      }

      return currentPage;
    },
    createQuestion: function createQuestion(row, currentPage, registry, configuration, includeNormalizedScores) {
      var questionConfiguration = configuration.questionConfig[row['Field Type'].trim()];

      if (!questionConfiguration) {
        throw new Error('Unrecognized question type: ' + row['Field Type']);
      }

      var template = this.get("scaleTemplates").lookup('question', questionConfiguration.template);
      var newQuestion = _scaleTemplates.Question.createNewFromTemplate(template, null, Ember.getOwner(this), questionConfiguration.options());

      var _parseForDependentVar2 = parseForDependentVariables(row['Field Label']),
          dependentVariables = _parseForDependentVar2.dependentVariables,
          text = _parseForDependentVar2.text;

      if (!Ember.isEmpty(dependentVariables)) {
        Ember.set(newQuestion, 'properties.dependentVariables', dependentVariables);
      }

      Ember.set(newQuestion, 'templateParameters.title', { en: text });
      Ember.set(newQuestion, 'properties.shortname', row['Variable / Field Name']);

      if (row['Report Text']) {
        Ember.set(newQuestion, 'properties.report_text', row['Report Text']);
      }

      if (row['Required Field?'] === 'y') {
        Ember.set(newQuestion, 'templateParameters.required', true);
      }

      if (row['Branching Logic (Show field only if...)']) {
        var logic = transformBranchingLogic(row['Branching Logic (Show field only if...)']);

        if (logic) {
          Ember.set(newQuestion, 'properties.show_rule', logic);
        }
      } else if (row['Mirah Show Rule']) {
        Ember.set(newQuestion, 'properties.show_rule', JSON.parse(row['Mirah Show Rule']));
      }

      if (row['Choices, Calculations, OR Slider Labels']) {
        var options = row['Choices, Calculations, OR Slider Labels'].split("|");

        var transformedOptions = options.map(function (option, index) {
          var valueSplit = option.indexOf(',');
          var value = option.substr(0, valueSplit).trim();
          var text = option.substr(valueSplit + 1).trim();

          if (Ember.isEmpty(value)) {
            value = text;
          }

          var reportText = void 0;

          if (questionConfiguration.includeScoreInReportText) {
            reportText = text.indexOf(value) >= 0 ? text : '(' + value + ') ' + text;
          } else {
            reportText = text;
          }

          return {
            id: index,
            value: value,
            en: text,
            report_text: reportText
          };
        });

        if (includeNormalizedScores && questionConfiguration.guessNormalizedScores) {
          var reversed = row['Reversed'] === 'y';
          guessNormalizedScores(transformedOptions, reversed, questionConfiguration);
        }

        registry.register(newQuestion, row['Variable / Field Name'], 'question');

        if (questionConfiguration.optionParser) {
          transformedOptions = questionConfiguration.optionParser(newQuestion, transformedOptions, row);
        }

        Ember.set(newQuestion, 'templateParameters.options', transformedOptions);
      }

      newQuestion.updateContent();
      currentPage.get("questions").addObject(newQuestion);
    },
    import: function _import(csv, rawScaleDatum, configuration, includeNormalizedScores) {
      var _this = this;

      if (!this.fileValid(csv)) {
        throw new Error("Invalid CSV file for REDCap import");
      }

      var registry = rawScaleDatum.get("registry");

      var rawSurvey = _scaleTemplates.RawSurvey.createNew(Ember.getOwner(this));

      var currentSurvey = null;

      var surveys = [];

      var currentPage = null;

      var rowsToSkip = csv.errors.mapBy('row');

      var mnemonic = null;
      var currentFormName = null;

      csv.data.forEach(function (row, index) {
        if (rowsToSkip.includes(index)) {
          return; // Do nothing with rows with errors.
        }

        if (!mnemonic && row['Form Name']) {
          mnemonic = row['Form Name'];
        }

        if (!currentFormName || row['Form Name'] && row['Form Name'] !== currentFormName) {
          currentFormName = row['Form Name'];
          currentSurvey = _scaleTemplates.Survey.createNew(Ember.getOwner(_this));
          surveys.addObject(currentSurvey);
          currentSurvey.set('properties.mnemonic', currentFormName);
          currentSurvey.set('properties.name', currentFormName);
          currentPage = null;
        }

        if (row['Field Type'] === 'calc') {
          // Add a scorer
        } else {
          currentPage = _this.updatePage(row, currentSurvey, currentPage, registry, configuration);
          _this.createQuestion(row, currentPage, registry, configuration, includeNormalizedScores);
        }
      });

      rawSurvey.set("surveys", surveys);

      rawScaleDatum.set('mnemonic', mnemonic);

      rawScaleDatum.set("parsedScaleData", rawSurvey);

      // TODO: Scales for calculated fields

      return rawScaleDatum;
    }
  });

  exports.default = RedcapImporter;
});