define('frontend/adapters/entity-tree-collection', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      if (url.indexOf('/child-tree-nodes') > 0) {
        //eagerly fetch the name and report status
        url = url + '?include=collections';
      }
      return this._super(store, snapshot, url, relationship);
    }
  });
});