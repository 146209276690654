define('frontend/pods/components/provider/assessment/report-v2/scale-interpretation-tree/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['interpretation-tree'],
    expanded: true,
    actions: {
      computedValueSelected: function computedValueSelected(item) {
        if (this.get("onComputedValueSelect")) {
          this.get("onComputedValueSelect")(item);
        }
      }
    }
  });
});