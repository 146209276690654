define('frontend/locales/en/translations', ['exports', 'frontend/locales/en/provider', 'frontend/locales/en/patient', 'frontend/locales/en/common', 'frontend/locales/en/model', 'frontend/locales/en/kiosk', 'frontend/locales/en/executive', 'frontend/locales/en/metrics', 'frontend/locales/en/internal', 'frontend/locales/en/reporting', 'frontend/locales/en/related-person', 'frontend/locales/en/provider-admin', 'frontend/locales/en/survey'], function (exports, _provider, _patient, _common, _model, _kiosk, _executive, _metrics, _internal, _reporting, _relatedPerson, _providerAdmin, _survey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var norseOptionDescriptions = {
    '1': 'not at all',
    '2': 'very little',
    '3': 'a little',
    '4': 'somewhat',
    '5': 'more than some',
    '6': 'a lot',
    '7': 'very much',
    'na': 'n/a'
  };

  var bamDaysOptionDescriptions = {
    '0': 'none',
    '1': '1-3 days',
    '2': '4-8 days',
    '3': '9-15 days',
    '4': '16-30 days'
  };

  var bamQualifiedAmountDescriptions = {
    '0': 'Not at all',
    '1': 'Slightly',
    '2': 'Moderately',
    '3': 'Considerably',
    '4': 'Extremely'
  };

  var imrResponseDescriptions = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5'
  };

  var cansasResponseDescriptions = {
    '0': 'No problem',
    '1': 'Met need',
    '2': 'Unmet need',
    '9': 'Not known'
  };

  var csiResponseDescriptions = {
    '0': 'not at all',
    '1': 'once',
    '2': 'several times/mo.',
    '3': 'several times/wk.',
    '4': 'every day',
    'rf': 'refused to answer',
    'na': 'n/a',
    'dk': "don't know"
  };

  var brsResponseDescriptions = {
    '1': 'Strongly Disagree',
    '2': 'Disagree',
    '3': 'Neutral',
    '4': 'Agree',
    '5': 'Strongly Agree'
  };

  var nsiResponseDescriptions = {
    '0': 'None',
    '1': 'Mild',
    '2': 'Moderate',
    '3': 'Severe',
    '4': 'Very Severe'
  };

  exports.default = {
    provider: _provider.default,
    common: _common.default,
    model: _model.default,
    kiosk: _kiosk.default,
    executive: _executive.default,
    metrics: _metrics.default,
    patient: _patient.default,
    internal: _internal.default,
    relatedPerson: _relatedPerson.default,
    providerAdmin: _providerAdmin.default,
    reporting: _reporting.default,
    survey: _survey.default,

    table: {
      pageSizeIndicator: 'records per page'
    },
    // standard question answers
    answer: {
      yes: 'Yes',
      no: 'No',
      rarelyNever: 'Rarely/Never',
      sometimes: 'Sometimes',
      often: 'Often',
      always: 'Always',
      'null': 'No answer',
      notShown: 'Not shown',
      declined: 'Did not answer'
    },

    'answer.': 'Not answered',

    score: {
      percentileLabel: {
        '-4': '0.01%',
        '-3': '0.1%',
        '-2': '2%',
        '-1': '15%',
        '0': '50%',
        '1': '85%',
        '2': '98%',
        '3': '99.9%',
        '4': '99.99%',
        '5': '99.999%',
        skill: {
          '-5': 'very low',
          '-4': '',
          '-3': '',
          '-2': 'low',
          '-1': '',
          '0': 'typical',
          '1': '',
          '2': 'strong',
          '3': '',
          '4': '',
          '5': ''
        },
        symptom: {
          '-4': '',
          '-3': '',
          '-2': '',
          '-1': '',
          '0': 'typical',
          '1': '',
          '2': 'elevated',
          '3': '',
          '4': 'severe',
          '5': '',
          '6': ''
        },
        ptpb: {
          '25': 'low',
          '50': 'moderate',
          '75': 'high'
        }
      },
      shortPercentileLabel: {
        '-4': '0.01%',
        '-3': '0.1%',
        '-2': '2%',
        '-1': '15%',
        '0': '50%',
        '1': '85%',
        '2': '98%',
        '3': '99.9%',
        '4': '99.99%',
        '5': '99.999%',
        skill: {
          '-5': 'vlow',
          '-4': '',
          '-3': '',
          '-2': 'low',
          '-1': '',
          '0': 'typ',
          '1': '',
          '2': 'strong',
          '3': '',
          '4': '',
          '5': ''
        },
        symptom: {
          '-4': '',
          '-3': '',
          '-2': '',
          '-1': '',
          '0': 'typ',
          '1': '',
          '2': 'elvtd',
          '3': '',
          '4': 'sev',
          '5': '',
          '6': ''
        },
        ptpb: {
          '25': 'low',
          '50': 'mod',
          '75': 'high'
        }
      }
    },

    // nested objects work just like dotted keys
    question: {
      //events
      recentChanges: {
        report: 'Recent events',
        none: 'none reported',
        icon: {
          relationshipStatus: 'fa-heart',
          housingStatus: 'fa-home',
          employmentStatus: 'fa-suitcase',
          medicationsTaking: 'fa-medkit',
          erVisit: 'fa-ambulance'
        },
        iconCode: {
          relationshipStatus: '\uF004',
          housingStatus: '\uF015',
          employmentStatus: '\uF0B1',
          medicationsTaking: '\uF0FA',
          erVisit: '\uF0F9'
        },
        option: {
          relationshipStatus: 'relationship status change',
          housingStatus: 'housing status change',
          employmentStatus: 'employment status change',
          medicationsTaking: 'medication change',
          erVisit: 'emergency room visit'
        },
        'option.': 'none specified'
      },

      //NORSE avoidance
      avoidPlacesOrExperiences: {
        report: 'Avoids places and experiences',
        option: norseOptionDescriptions
      },
      emotionsHelpKnowRight: {
        report: 'Emotions help guide them to what\'s right',
        option: norseOptionDescriptions
      },
      tryToAvoidSomeEmotions: {
        report: 'Avoids some emotions',
        option: norseOptionDescriptions
      },
      discomfortPrefersAlone: {
        report: 'Discomfort makes them prefer to be alone',
        option: norseOptionDescriptions
      },
      workToAvoidPainfulThoughts: {
        report: 'Works to avoid painful thoughts',
        option: norseOptionDescriptions
      },
      doEverythingToAvoidSomething: {
        report: 'Does everything to avoid certain fear',
        option: norseOptionDescriptions
      },

      //NORSE attachment
      easyToCareAboutOthers: {
        report: 'Easily cares about others',
        option: norseOptionDescriptions
      },
      canFormStrongAttachments: {
        report: 'Can form strong attachments',
        option: norseOptionDescriptions
      },
      easyToTrustPeopleIMeet: {
        report: 'Easily trusts people met',
        option: norseOptionDescriptions
      },
      comfortableSharingEmotions: {
        report: 'Comfortable sharing emotions',
        option: norseOptionDescriptions
      },

      //NORSE connectedness
      satisfiedWithSexLife: {
        report: 'Satisfied with sex life',
        option: norseOptionDescriptions
      },
      noControlOverLife: {
        report: 'Feels no control over life',
        option: norseOptionDescriptions
      },
      goodFriendsKnowMe: {
        report: 'Has good friends who know them',
        option: norseOptionDescriptions
      },
      feelAloneWithOthers: {
        report: 'Feels alone around others',
        option: norseOptionDescriptions
      },
      havePeopleForSupport: {
        report: 'Has people for support',
        option: norseOptionDescriptions
      },

      //NORSE demoralization
      everythingTooMuchToHandle: {
        report: 'Everything too much to handle',
        option: norseOptionDescriptions
      },
      noOneUndestandsGivenUp: {
        report: 'Given up because no one understands',
        option: norseOptionDescriptions
      },
      feelingDepressed: {
        report: 'Feels depressed',
        option: norseOptionDescriptions
      },
      givenUpHopeForFuture: {
        report: 'No hope for future',
        option: norseOptionDescriptions
      },
      trappedAndUncertain: {
        report: 'Feels trapped without knowing how to get out',
        option: norseOptionDescriptions
      },

      //NORSE eating
      effortReadingAndPlanningFood: {
        report: 'Too much effort planning food',
        option: norseOptionDescriptions
      },
      fearLosingFoodControl: {
        report: 'Afraid can\'t control eating',
        option: norseOptionDescriptions
      },
      stomachDigestionDifficulties: {
        report: 'Digestion and/or stomach difficulties',
        option: norseOptionDescriptions
      },
      eatingRestrictsSocial: {
        report: 'Eating habits restrict social life',
        option: norseOptionDescriptions
      },
      importantToControlEating: {
        report: 'Very important to control food intake',
        option: norseOptionDescriptions
      },
      dontHaveBodyIShould: {
        report: 'Feel they don\'t have the body they should',
        option: norseOptionDescriptions
      },

      //NORSE distancing
      zoneOutIntenseMoments: {
        report: 'Zones out during emotional moments',
        option: norseOptionDescriptions
      },
      outsideBodyDuringStressEvents: {
        report: 'Watches from out-of-body during stress',
        option: norseOptionDescriptions
      },

      //NORSE hurtful rumination
      bodyTenseAndHurts: {
        report: 'Body hurts from tenseness',
        option: norseOptionDescriptions
      },
      constantlySelfCriticize: {
        report: 'Constantly self-criticizes',
        option: norseOptionDescriptions
      },
      cantStopWorrying: {
        report: 'Can\'t stop worrying',
        option: norseOptionDescriptions
      },
      iAmWorthless: {
        report: 'Feels worthless',
        option: norseOptionDescriptions
      },
      needToRuminateLess: {
        report: 'Needs to ruminate less',
        option: norseOptionDescriptions
      },

      //NORSE  hypervigilance
      effortEvaluatingSafety: {
        report: 'Spends lots of time evaluating safety',
        option: norseOptionDescriptions
      },
      alwaysPreparedForBad: {
        report: 'Always prepared for bad things',
        option: norseOptionDescriptions
      },
      feelSafeInHome: {
        report: 'Feels safe in home',
        option: norseOptionDescriptions
      },

      //NORSE  perfectionism
      angerFrightensMe: {
        report: 'Frightened by own anger',
        option: norseOptionDescriptions
      },
      fearHealthBodyWrong: {
        report: 'Fears something is wrong with body or health',
        option: norseOptionDescriptions
      },
      hurtSelfPurposely: {
        report: 'Hurts themselves on purpose',
        shortName: 'Self-Harm',
        score: {
          unknown: 'unknown',
          none: 'typical',
          low: 'elevated',
          moderate: 'elevated',
          severe: 'severe',
          '': ''
        },
        option: norseOptionDescriptions
      },
      enoughSleepRested: {
        report: 'Feels rested when waking up',
        option: norseOptionDescriptions
      },
      mostlyRestlessUneasy: {
        report: 'Feels often restless and uneasy',
        option: norseOptionDescriptions
      },
      mostTimesFeel: {
        report: 'Feels excited most of the time',
        option: norseOptionDescriptions
      },

      //NORSE  pressure from negative affect
      excessWorryAboutSloppy: {
        report: 'Worries about being sloppy',
        option: norseOptionDescriptions
      },
      worryAboutRightInteferes: {
        report: 'Worry about perfection interferes with doing things',
        option: norseOptionDescriptions
      },
      feelStartCryingNeverStop: {
        report: 'Worry if crying started couldn\'t stop',
        option: norseOptionDescriptions
      },
      easyHonestyWithFriends: {
        report: 'Easy to be self with friends',
        option: norseOptionDescriptions
      },
      repeatBehaviorsForAnxiety: {
        report: 'Repeated behaviors to control anxiety',
        option: norseOptionDescriptions
      },
      alwaysNeedControl: {
        report: 'Needs to always feel in control',
        option: norseOptionDescriptions
      },
      hardToAllowOthersControl: {
        report: 'Hard to let others have control',
        option: norseOptionDescriptions
      },

      //NORSE  psychosis
      feelSecretOrgWatching: {
        report: 'Feels secret org. is watching them',
        option: norseOptionDescriptions
      },
      stayAloneForLife: {
        report: 'Rather be alone for life',
        option: norseOptionDescriptions
      },
      worriedPeopleHearMyThoughts: {
        report: 'Worry people can hear their thoughts',
        option: norseOptionDescriptions
      },

      //NORSE  relational distress
      careTooMuchOthersOpinion: {
        report: 'Care too much about other\'s opinions',
        option: norseOptionDescriptions
      },
      easilyAnnoyedByOthers: {
        report: 'Easily annoyed by others',
        option: norseOptionDescriptions
      },
      conflictInRelationships: {
        report: 'Has a lot of conflicts in relationships',
        option: norseOptionDescriptions
      },
      fewerProblemsWithoutOthers: {
        report: 'Would have less problems without others',
        option: norseOptionDescriptions
      },
      relationshipsCauseStress: {
        report: 'Relationships cause them stress',
        option: norseOptionDescriptions
      },

      //NORSE  resilience
      enjoyJobSchool: {
        report: 'Enjoys their job/school',
        option: norseOptionDescriptions
      },
      feelThingsWillImprove: {
        report: 'Feels things will improve',
        option: norseOptionDescriptions
      },
      feelGoodAboutAmountOfWork: {
        report: 'Feels good about amount of work they do',
        option: norseOptionDescriptions
      },
      canCommunicateWhatIsImportant: {
        report: 'Can communicate what is important',
        option: norseOptionDescriptions
      },
      easyToDoThingsForFun: {
        report: 'Easy to do things just for fun',
        option: norseOptionDescriptions
      },
      raisedHeartRateFeelsGood: {
        report: 'Feels good to raise heart rate',
        option: norseOptionDescriptions
      },
      likeWhoIam: {
        report: 'Like who they are',
        option: norseOptionDescriptions
      },
      discomfortSeekingHelp: {
        report: 'Uncomfortable asking for support when needed',
        option: norseOptionDescriptions
      },
      healthThroughActivity: {
        report: 'Looks after health by being active',
        option: norseOptionDescriptions
      },
      goodAtRelaxing: {
        report: 'Good at relaxing when needed',
        option: norseOptionDescriptions
      },

      //NORSE  social role
      communityValuesMe: {
        report: 'Feel community values them',
        option: norseOptionDescriptions
      },
      matterToCommunity: {
        report: 'Feels they matter to community',
        option: norseOptionDescriptions
      },

      //NORSE  somatic anxiety
      breathRacingOrNumbness: {
        report: 'Feels short of breath, heart racing, or tingling',
        option: norseOptionDescriptions
      },
      afraidDontKnowWhy: {
        report: 'Feels afraid without knowing why',
        option: norseOptionDescriptions
      },

      //NORSE  substance
      drugUseInterferes: {
        report: 'Drugs/alcohol interferes with functioning',
        option: norseOptionDescriptions
      },
      othersConcernedDrugUse: {
        report: 'Others are concerned with their drug/alcohol use',
        option: norseOptionDescriptions
      },
      concernedDrugDependent: {
        report: 'Concerned they are dependent on drugs/alcohol',
        option: norseOptionDescriptions
      },
      needToReduceDrugUse: {
        report: 'Feel need to reduce drug/alcohol use',
        option: norseOptionDescriptions
      },

      //NORSE  suicide
      betterOffDead: {
        report: 'Thinks it would be better if they were dead',
        option: norseOptionDescriptions
      },
      thoughtsOfSuicide: {
        report: 'Have had thoughts of suicide',
        option: norseOptionDescriptions
      },
      fearLoseControlSuicide: {
        report: 'Afraid will lose control and kill self',
        option: norseOptionDescriptions
      },
      plansForHowSuicide: {
        report: 'Have plans for how to kill self',
        option: norseOptionDescriptions
      },

      currentProblemStrategy: {
        report: 'Current strategy for problems',
        option: norseOptionDescriptions
      },
      otherTopics: {
        report: 'Also wants to discuss'
      },

      //NORSE  therapy
      understandTreatmentHelp: {
        report: 'Understands how treatment will help them',
        option: norseOptionDescriptions
      },
      therapistUnderstandsMe: {
        report: 'Feel their therapist understands them',
        option: norseOptionDescriptions
      },
      therapistAcceptsAsPerson: {
        report: 'Feel their therapist accepts them as a person',
        option: norseOptionDescriptions
      },
      understandPathToGetBetter: {
        report: 'Understands what they need to work on',
        option: norseOptionDescriptions
      },

      therapistTechniqueExerciseQuantity: {
        report: 'Wants fewer/more techniques and exercises',
        reportPositive: 'Wants MORE techniques and exercises',
        reportNegative: 'Wants LESS techniques and exercises',
        option: {
          '-5': 'much less techniques/exercises',
          '-4': 'much less techniques & exercises',
          '-3': 'less techniques & exercises',
          '-2': 'less techniques & exercises',
          '-1': 'a little less techniques & exercises',
          '0': 'same',
          '1': 'a little more techniques & exercises',
          '2': 'more techniques & exercises',
          '3': 'more techniques & exercises',
          '4': 'much more techniques & exercises',
          '5': 'much more techniques & exercises'
        }
      },
      therapistHumorFormalQuantity: {
        report: 'Wants more humor/formality',
        reportPositive: 'Wants more formality',
        reportNegative: 'Wants more humor',
        option: {
          '-5': 'much more humor',
          '-4': 'much more humor',
          '-3': 'more humor',
          '-2': 'more humor',
          '-1': 'a little more humor',
          '0': 'same',
          '1': 'a little more formality',
          '2': 'more formality',
          '3': 'more formality',
          '4': 'much more formality',
          '5': 'much more formality'
        }
      },
      therapistFeelingCognitionFocus: {
        report: 'Wants more focus on feelings/thoughts',
        reportPositive: 'Wants more focus on thoughts',
        reportNegative: 'Wants more focus on feelings',
        option: {
          '-5': 'much more feelings',
          '-4': 'much more feelings',
          '-3': 'more feelings',
          '-2': 'more feelings',
          '-1': 'a little more feelings',
          '0': 'same',
          '1': 'a little more thoughts',
          '2': 'more thoughts',
          '3': 'more thoughts',
          '4': 'much more thoughts',
          '5': 'much more thoughts'
        }
      },
      focusOnTherapistRelationship: {
        report: 'Wants more focus on the therapist relationship',
        reportPositive: 'Wants MORE focus on therapist relationship',
        reportNegative: 'Wants LESS focus on therapist relationship',
        option: {
          '-5': 'much less focus on relationship',
          '-4': 'much less focus on relationship',
          '-3': 'less focus on relationship',
          '-2': 'less focus on relationship',
          '-1': 'a little less focus on relationship',
          '0': 'same',
          '1': 'a little more focus on relationship',
          '2': 'more focus on relationship',
          '3': 'more focus on relationship',
          '4': 'much more focus on relationship',
          '5': 'much more focus on relationship'
        }
      },

      wantToDiscussMedicationConcern: {
        report: 'Has concerns about medications to discuss',
        badge: 'Wants to discuss',
        option: norseOptionDescriptions
      },

      feelMyMedicationHelps: {
        report: 'Believes their psych. medication helps',
        badge: 'Not sure they help',
        option: norseOptionDescriptions
      },

      'counselingGoals': {
        report: 'Goals for counseling'
      },

      //BAM Options
      bamAnyAlcohol: {
        report: 'Days drank ANY alcohol',
        option: bamDaysOptionDescriptions
      },

      bamBingeDrinking: {
        report: 'Days in had at least 5 (male) or 4 (female) drinks',
        option: bamDaysOptionDescriptions
      },

      bamIllegalDrugs: {
        report: 'Days using illegal drugs or abusing prescriptions',
        option: bamDaysOptionDescriptions
      },

      bamPhysicalHealth: {
        report: 'Health in the past thirty days',
        option: {
          '0': 'Excellent',
          '1': 'Very good',
          '2': 'Good',
          '3': 'Fair',
          '4': 'Poor'
        }
      },
      bamTroubleSleeping: {
        report: 'Nights having trouble sleeping',
        option: bamDaysOptionDescriptions
      },
      bamDepressedAnxious: {
        report: 'Days felt depressed, anxious, angry or very upset',
        option: bamDaysOptionDescriptions
      },
      bamCravings: {
        report: 'Days bothered by cravings or urges to drink alcohol or use drugs',
        option: bamDaysOptionDescriptions
      },
      bamRiskySituation: {
        report: 'Days in situations or with people that might increase risk of using alcohol or drugs',
        option: bamDaysOptionDescriptions
      },
      bamArgumentsWithOthers: {
        report: 'Been bothered by arguments or problems getting along with any family members or friends',
        option: bamQualifiedAmountDescriptions
      },
      bamAbstinenceConfidence: {
        report: 'Confidence in ability to be completely abstinent from drugs and alcohol',
        option: bamQualifiedAmountDescriptions
      },
      bamSelfHelpMeetings: {
        report: 'Attended self-help meetings like AA or NA',
        option: bamDaysOptionDescriptions
      },
      bamReligionHelp: {
        report: 'How much religion or spirituality helps support recovery',
        option: bamQualifiedAmountDescriptions
      },
      bamWorkFrequency: {
        report: 'Spent much of the time at work, school, or doing volunteer work',
        option: bamDaysOptionDescriptions
      },
      bamSufficientIncome: {
        report: 'Has enough income (from legal sources) to pay for necessities',
        option: {
          '0': 'no',
          '4': 'yes'
        }
      },

      bamSupportFrequency: {
        report: 'Days in contact with family or friends supportive of recovery',
        option: bamDaysOptionDescriptions
      },

      ptpbMedAsDirected: {
        badge: 'not taking'
      },
      ptpbMedPositiveChanges: {
        badge: 'no impact'
      },
      ptpbMedAsDirected_C: {
        badge: 'not taking (cgvr)'
      },
      ptpbMedPositiveChanges_C: {
        badge: 'no impact (cgvr)'
      },
      ptpbMedConcerns: {
        badge: 'concerns'
      },
      ptpbMedConcerns_C: {
        badge: 'concerns (cgvr)'
      },

      //IMR self questions
      imrGoalProgress: {
        report: 'Progress on personal goals',
        option: imrResponseDescriptions
      },
      imrKnowledge: {
        report: 'Knowledge of condition',
        option: imrResponseDescriptions
      },
      imrOthersInvolved: {
        report: 'Family and friend treatment involvement',
        option: imrResponseDescriptions
      },
      imrOutsideContact: {
        report: 'Contact outside of family',
        option: imrResponseDescriptions
      },
      imrTimeStructured: {
        report: 'Time in structured roles',
        option: imrResponseDescriptions
      },
      imrSymptomDistress: {
        report: 'Symptom distress',
        option: imrResponseDescriptions
      },
      imrFunctionImpairment: {
        report: 'Symptoms impair functioning',
        option: imrResponseDescriptions
      },
      imrRelapsePlan: {
        report: 'Relapse prevention planning',
        option: imrResponseDescriptions
      },
      imrRecentRelapse: {
        report: 'Most recent relapse',
        option: imrResponseDescriptions
      },
      imrPsychHospitalized: {
        report: 'Most recent psych. hospitalization',
        option: imrResponseDescriptions
      },
      imrCoping: {
        report: 'Coping effectiveness',
        option: imrResponseDescriptions
      },
      imrSelfHelp: {
        report: 'Involvement in self-help activities',
        option: imrResponseDescriptions
      },
      imrNoPrescriptions: {
        report: 'No prescribed psych. meds'
      },
      imrMedicationUse: {
        report: 'Using medication effectively',
        option: imrResponseDescriptions
      },
      imrAlcoholFunction: {
        report: 'Alcohol impairs functioning',
        option: imrResponseDescriptions
      },
      imrDrugFunction: {
        report: 'Drug use impairs functioning',
        option: imrResponseDescriptions
      },
      //IMR clinician questions
      imrGoalProgress_C: {
        report: 'Progress on personal goals',
        option: imrResponseDescriptions
      },
      imrKnowledge_C: {
        report: 'Knowledge of condition',
        option: imrResponseDescriptions
      },
      imrOthersInvolved_C: {
        report: 'Family and friend treatment involvement',
        option: imrResponseDescriptions
      },
      imrOutsideContact_C: {
        report: 'Contact outside of family',
        option: imrResponseDescriptions
      },
      imrTimeStructured_C: {
        report: 'Time in structured roles',
        option: imrResponseDescriptions
      },
      imrSymptomDistress_C: {
        report: 'Symptom distress',
        option: imrResponseDescriptions
      },
      imrFunctionImpairment_C: {
        report: 'Symptoms impair functioning',
        option: imrResponseDescriptions
      },
      imrRelapsePlan_C: {
        report: 'Relapse prevention planning',
        option: imrResponseDescriptions
      },
      imrRecentRelapse_C: {
        report: 'Most recent relapse',
        option: imrResponseDescriptions
      },
      imrPsychHospitalized_C: {
        report: 'Most recent psych. hospitalization',
        option: imrResponseDescriptions
      },
      imrCoping_C: {
        report: 'Coping effectiveness',
        option: imrResponseDescriptions
      },
      imrSelfHelp_C: {
        report: 'Involvement in self-help activities',
        option: imrResponseDescriptions
      },
      imrNoPrescriptions_C: {
        report: 'No prescribed psych. meds'
      },
      imrMedicationUse_C: {
        report: 'Using medication effectively',
        option: imrResponseDescriptions
      },
      imrAlcoholFunction_C: {
        report: 'Alcohol impairs functioning',
        option: imrResponseDescriptions
      },
      imrDrugFunction_C: {
        report: 'Drug use impairs functioning',
        option: imrResponseDescriptions
      },
      //CANSAS questions

      cansasAccommodation: {
        report: 'Kind of place to live',
        option: cansasResponseDescriptions
      },
      cansasFood: {
        report: 'Gets enough Food',
        option: cansasResponseDescriptions
      },
      cansasLookingAfterHome: {
        report: 'Able to look after home',
        option: cansasResponseDescriptions
      },
      cansasSelfCare: {
        report: 'Keeping clean and tidy',
        option: cansasResponseDescriptions
      },
      cansasDaytimeActivities: {
        report: 'Daytime activities',
        option: cansasResponseDescriptions
      },
      cansasPhysicalHealth: {
        report: 'Physical health',
        option: cansasResponseDescriptions
      },
      cansasPsychoticSymptoms: {
        report: 'Hear voices or have problems with thoughts',
        option: cansasResponseDescriptions
      },
      cansasConditionInformation: {
        report: 'Clear information on medication',
        option: cansasResponseDescriptions
      },
      cansasPsychologicalDistress: {
        report: 'Felt sad or low',
        option: cansasResponseDescriptions
      },
      cansasSelfSafety: {
        report: 'Thoughts of self-harm',
        option: cansasResponseDescriptions
      },
      cansasOtherSafety: {
        report: 'Thinks are a danger to others',
        option: cansasResponseDescriptions
      },
      cansasAlcohol: {
        report: 'Drinking causes problems',
        option: cansasResponseDescriptions
      },
      cansasDrugs: {
        report: 'Drugs cause problems',
        option: cansasResponseDescriptions
      },
      cansasCompany: {
        report: 'Happy with social life',
        option: cansasResponseDescriptions
      },
      cansasIntimateRelationships: {
        report: 'Has a partner',
        option: cansasResponseDescriptions
      },
      cansasSexualExpression: {
        report: 'Sex life',
        option: cansasResponseDescriptions
      },
      cansasChildCare: {
        report: 'Children under 18',
        option: cansasResponseDescriptions
      },
      cansasBasicEducation: {
        report: 'Difficulty with English',
        option: cansasResponseDescriptions
      },
      cansasTelephone: {
        report: 'Use a telephone',
        option: cansasResponseDescriptions
      },
      cansasTransport: {
        report: 'Can use bus or train',
        option: cansasResponseDescriptions
      },
      cansasMoney: {
        report: 'Budgeting money',
        option: cansasResponseDescriptions
      },
      cansasBenefits: {
        report: 'Getting money entitled to',
        option: cansasResponseDescriptions
      },
      csiFeltNervousAfraid: {
        report: 'felt nervous, frustrated or afraid',
        option: csiResponseDescriptions
      },
      csiFeltDepressed: {
        report: 'felt depressed',
        option: csiResponseDescriptions
      },
      csiFeltLonely: {
        report: 'felt lonely',
        option: csiResponseDescriptions
      },
      csiOthersThoughtParanoid: {
        report: 'others told they acted suspicious',
        option: csiResponseDescriptions
      },
      csiHeardVoices: {
        report: "heard or saw things others didn't",
        option: csiResponseDescriptions
      },
      csiTroubleDeciding: {
        report: 'had trouble making up mind',
        option: csiResponseDescriptions
      },
      csiTroubleConcentrating: {
        report: 'had trouble concentrating',
        option: csiResponseDescriptions
      },
      csiFeltBehaviorStrange: {
        report: 'felt behavior was strange',
        option: csiResponseDescriptions
      },
      csiFeltOutOfPlace: {
        report: 'felt out of place',
        option: csiResponseDescriptions
      },
      csiForgetImportantThings: {
        report: 'forgot important things',
        option: csiResponseDescriptions
      },
      csiThoughtsRacing: {
        report: 'problems with thoughts racing',
        option: csiResponseDescriptions
      },
      csiFeltParanoid: {
        report: 'felt paranoid or suspicious',
        option: csiResponseDescriptions
      },
      csiFeltSelfHarm: {
        report: 'felt like hurting or killing self',
        option: csiResponseDescriptions
      },
      csiFeltHarmOthers: {
        report: 'felt like hurting someone else',
        option: csiResponseDescriptions
      },

      //MIRECC GAF Questions
      mireccGafOccupationalFunctioning: {
        report: 'occupational functioning'
      },
      mireccGafSocialFunctioning: {
        report: 'social functioning'
      },
      mireccGafSymptomaticFunctioning: {
        report: 'symptomatic functioning'
      },

      brsBounceBack: {
        report: 'bounce back quickly',
        option: brsResponseDescriptions
      },
      brsHardStressEvents: {
        report: 'hard time with stressful events',
        option: brsResponseDescriptions
      },
      brsShortStressRecovery: {
        report: 'short recovery time from stress',
        option: brsResponseDescriptions
      },
      brsHardSnapBack: {
        report: 'hard to snap back after something bad',
        option: brsResponseDescriptions
      },
      brsComeThroughEasy: {
        report: 'come through difficult times easily',
        option: brsResponseDescriptions
      },
      brsLongSetBacks: {
        report: 'long time to get over set-backs',
        option: brsResponseDescriptions
      },

      //BSCS questions
      bscsPrimaryIntensity: {
        report: 'primary craving intensity',
        option: {
          '0': 'None at all',
          '1': 'Slight',
          '2': 'Moderate',
          '3': 'Considerable',
          '4': 'Extreme'
        }
      },
      bscsPrimaryFrequency: {
        report: 'primary craving frequency',
        option: {
          '0': 'Never',
          '1': 'Almost never',
          '2': 'Several times',
          '3': 'Regularly',
          '4': 'Almost constantly'
        }
      },
      bscsPrimaryLength: {
        report: 'primary craving length',
        option: {
          '0': 'None at all',
          '1': 'Very short',
          '2': 'Short',
          '3': 'Somewhat long',
          '4': 'Very long'
        }
      },
      bscsPrimaryNumber: {
        report: 'number of times of primary craving',
        option: {
          '0': '0',
          '1': '1-2',
          '2': '3-5',
          '3': '6-8',
          '4': '9+'
        }
      },
      bscsSecondarySubstance: {
        report: 'Secondary substance craved'
      },
      bscsSecondaryIntensity: {
        report: 'secondary craving intensity',
        option: {
          '0': 'None at all',
          '1': 'Slight',
          '2': 'Moderate',
          '3': 'Considerable',
          '4': 'Extreme'
        }
      },
      bscsSecondaryFrequency: {
        report: 'secondary craving frequency',
        option: {
          '0': 'Never',
          '1': 'Almost never',
          '2': 'Several times',
          '3': 'Regularly',
          '4': 'Almost constantly'
        }
      },
      bscsSecondaryLength: {
        report: 'secondary craving length',
        option: {
          '0': 'None at all',
          '1': 'Very short',
          '2': 'Short',
          '3': 'Somewhat long',
          '4': 'Very long'
        }
      },
      bscsSecondaryNumber: {
        report: 'number of time of secondary craving',
        option: {
          '0': '0',
          '1': '1-2',
          '2': '3-5',
          '3': '6-8',
          '4': '9+'
        }
      },

      nsiDizzy: {
        option: nsiResponseDescriptions
      },
      nsiBalance: {
        option: nsiResponseDescriptions
      },
      nsiClumsy: {
        option: nsiResponseDescriptions
      },
      nsiHeadaches: {
        option: nsiResponseDescriptions
      },
      nsiNausea: {
        option: nsiResponseDescriptions
      },
      nsiVision: {
        option: nsiResponseDescriptions
      },
      nsiLightSensitive: {
        option: nsiResponseDescriptions
      },
      nsiHearing: {
        option: nsiResponseDescriptions
      },
      nsiNoiseSensitive: {
        option: nsiResponseDescriptions
      },
      nsiNumbness: {
        option: nsiResponseDescriptions
      },
      nisTasteChange: {
        option: nsiResponseDescriptions
      },
      nsiAppetite: {
        option: nsiResponseDescriptions
      },
      nsiConcentration: {
        option: nsiResponseDescriptions
      },
      nsiForgetful: {
        option: nsiResponseDescriptions
      },
      nsiDecisions: {
        option: nsiResponseDescriptions
      },
      nsiSlowThinking: {
        option: nsiResponseDescriptions
      },
      nsiFatigue: {
        option: nsiResponseDescriptions
      },
      nsiSleep: {
        option: nsiResponseDescriptions
      },
      nsiAnxious: {
        option: nsiResponseDescriptions
      },
      nsiDepressed: {
        option: nsiResponseDescriptions
      },
      nsiIrritable: {
        option: nsiResponseDescriptions
      },
      nsiFrustration: {
        option: nsiResponseDescriptions
      }
    }
  };
});