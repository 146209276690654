define('frontend/utils/assessment/symptoms', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Current hardcoded symptoms are based on clinical outpatient norms.
  var symptoms = {
    // Mirah Measure
    attachment: {
      id: 21,
      isSkill: true,
      isBadged: false,
      name: 'attachment',
      displayOrder: 1,
      screenQs: ['easyToCareAboutOthers'],
      expressionQs: ['canFormStrongAttachments', 'easyToTrustPeopleIMeet', 'comfortableSharingEmotions'],
      textQs: [],
      mean: 8 - 4.1089,
      stddev: 1.24878,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('attachment', symptoms.attachment.screenQs.concat(symptoms.attachment.expressionQs), response);
      }
    },

    avoidance: {
      id: 20,
      isSkill: false,
      isBadged: false,
      name: 'avoidance',
      displayOrder: 2,
      screenQs: ['avoidPlacesOrExperiences'],
      expressionQs: ['emotionsHelpKnowRight', 'doEverythingToAvoidSomething', 'tryToAvoidSomeEmotions', 'discomfortPrefersAlone', 'workToAvoidPainfulThoughts'],
      textQs: [],
      mean: 3.6225,
      stddev: 1.31257,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('avoidance', symptoms.avoidance.screenQs.concat(symptoms.avoidance.expressionQs), response);
      }
    },

    connectedness: {
      id: 22,
      isSkill: true,
      isBadged: false,
      name: 'connectedness',
      screenQs: ['noControlOverLife'],
      expressionQs: ['easyToTrustPeopleIMeet', 'satisfiedWithSexLife', 'goodFriendsKnowMe', 'feelAloneWithOthers', 'havePeopleForSupport'],
      textQs: [],
      displayOrder: 2,
      mean: 8 - 3.9177,
      stddev: 1.11450,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('connectedness', symptoms.connectedness.screenQs.concat(symptoms.connectedness.expressionQs), response);
      }
    },

    demoralization: {
      id: 23,
      isSkill: false,
      isBadged: false,
      name: 'demoralization',
      displayOrder: 8,
      screenQs: ['everythingTooMuchToHandle'],
      expressionQs: ['noOneUndestandsGivenUp', 'feelingDepressed', 'givenUpHopeForFuture', 'trappedAndUncertain', 'havePeopleForSupport'],
      textQs: [],
      mean: 3.3343,
      stddev: 1.53208,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('demoralization', symptoms.demoralization.screenQs.concat(symptoms.demoralization.expressionQs), response);
      }
    },

    eating: {
      id: 24,
      isSkill: false,
      isBadged: false,
      name: 'eating',
      displayOrder: 5,
      screenQs: ['importantToControlEating'],
      expressionQs: ['effortReadingAndPlanningFood', 'fearLosingFoodControl', 'stomachDigestionDifficulties', 'eatingRestrictsSocial', 'dontHaveBodyIShould'],
      textQs: [],
      mean: 2.8589,
      stddev: 1.49412,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('eating', symptoms.eating.screenQs.concat(symptoms.eating.expressionQs), response);
      }
    },

    emotionalDistance: {
      id: 25,
      isSkill: false,
      isBadged: false,
      name: 'emotionalDistance',
      displayOrder: 1,
      screenQs: ['zoneOutIntenseMoments'],
      expressionQs: ['outsideBodyDuringStressEvents'],
      textQs: [],
      mean: 3.1432,
      stddev: 1.86089,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('emotionalDistance', symptoms.emotionalDistance.screenQs.concat(symptoms.emotionalDistance.expressionQs), response);
      }
    },

    hurtfulRumination: {
      id: 26,
      isSkill: false,
      isBadged: false,
      name: 'hurtfulRumination',
      displayOrder: 7,
      screenQs: ['cantStopWorrying'],
      expressionQs: ['bodyTenseAndHurts', 'constantlySelfCriticize', 'iAmWorthless', 'needToRuminateLess'],
      textQs: [],
      mean: 4.0525,
      stddev: 1.54968,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('hurtfulRumination', symptoms.hurtfulRumination.screenQs.concat(symptoms.hurtfulRumination.expressionQs), response);
      }
    },

    hypervigilance: {
      id: 27,
      isSkill: false,
      isBadeg: false,
      name: 'hypervigilance',
      displayOrder: 10,
      screenQs: ['effortEvaluatingSafety'],
      expressionQs: ['alwaysPreparedForBad', 'feelSafeInHome', 'easyToTrustPeopleIMeet'],
      textQs: [],
      mean: 3.7122,
      stddev: 1.27695,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('hypervigilance', symptoms.hypervigilance.screenQs.concat(symptoms.hypervigilance.expressionQs), response);
      }
    },

    perfectionism: {
      id: 28,
      isSkill: false,
      isBadged: false,
      name: 'perfectionism',
      displayOrder: 4,
      screenQs: ['excessWorryAboutSloppy'],
      expressionQs: ['worryAboutRightInteferes', 'feelStartCryingNeverStop', 'repeatBehaviorsForAnxiety', 'alwaysNeedControl', 'hardToAllowOthersControl', 'constantlySelfCriticize'],
      textQs: [],
      mean: 3.5396,
      stddev: 1.37191,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('perfectionism', symptoms.perfectionism.screenQs.concat(symptoms.perfectionism.expressionQs), response);
      }
    },

    negativeAffect: {
      id: 28,
      isSkill: false,
      isBadged: false,
      name: 'negativeAffect',
      displayOrder: 9,
      screenQs: ['angerFrightensMe'],
      expressionQs: ['fearHealthBodyWrong', 'hurtSelfPurposely', 'enoughSleepRested', 'mostlyRestlessUneasy', 'mostTimesFeel', 'feelStartCryingNeverStop', 'feelingDepressed', 'trappedAndUncertain'],
      textQs: [],
      mean: 3.4477,
      stddev: 1.12335,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('negativeAffect', symptoms.negativeAffect.screenQs.concat(symptoms.negativeAffect.expressionQs), response);
      }
    },

    psychosis: {
      id: 28,
      isSkill: false,
      isBadged: true,
      name: 'psychosis',
      displayOrder: 3,
      screenQs: ['feelSecretOrgWatching'],
      expressionQs: ['stayAloneForLife', 'worriedPeopleHearMyThoughts'],
      textQs: [],
      mean: 1.6044,
      stddev: 0.82296,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('psychosis', symptoms.psychosis.screenQs.concat(symptoms.psychosis.expressionQs), response);
      }
    },

    relationalDistress: {
      id: 28,
      isSkill: false,
      isBadged: false,
      name: 'relationalDistress',
      displayOrder: 3,
      screenQs: ['careTooMuchOthersOpinion'],
      expressionQs: ['easilyAnnoyedByOthers', 'conflictInRelationships', 'fewerProblemsWithoutOthers', 'relationshipsCauseStress', 'feelAloneWithOthers', 'noOneUndestandsGivenUp'],
      textQs: [],
      mean: 3.3612,
      stddev: 1.13434,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('relationalDistress', symptoms.relationalDistress.screenQs.concat(symptoms.relationalDistress.expressionQs), response);
      }
    },

    resilience: {
      id: 28,
      isSkill: true,
      isBadged: true,
      name: 'resilience',
      displayOrder: 2,
      screenQs: ['enjoyJobSchool'],
      expressionQs: ['feelThingsWillImprove', 'goodAtRelaxing', 'feelGoodAboutAmountOfWork', 'canCommunicateWhatIsImportant', 'easyToDoThingsForFun', 'raisedHeartRateFeelsGood', 'likeWhoIam', 'discomfortSeekingHelp', 'healthThroughActivity', 'enoughSleepRested', 'comfortableSharingEmotions'],
      textQs: [],
      mean: 8 - 3.7883,
      stddev: 1.22175,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('resilience', symptoms.resilience.screenQs.concat(symptoms.resilience.expressionQs), response);
      }
    },

    socialRole: {
      id: 28,
      isBadged: false,
      isSkill: true,
      name: 'socialRole',
      displayOrder: 4,
      screenQs: ['communityValuesMe'],
      expressionQs: ['matterToCommunity', 'goodAtRelaxing', 'feelGoodAboutAmountOfWork'],
      textQs: [],
      mean: 8 - 3.8540,
      stddev: 1.43467,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('socialRole', symptoms.socialRole.screenQs.concat(symptoms.socialRole.expressionQs), response);
      }
    },

    somaticAnxiety: {
      id: 28,
      isBadged: false,
      isSkill: false,
      name: 'somaticAnxiety',
      displayOrder: 6,
      screenQs: ['breathRacingOrNumbness'],
      expressionQs: ['afraidDontKnowWhy', 'stomachDigestionDifficulties', 'bodyTenseAndHurts', 'fearHealthBodyWrong', 'mostlyRestlessUneasy'],
      textQs: [],
      mean: 3.6439,
      stddev: 1.41336,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('somaticAnxiety', symptoms.somaticAnxiety.screenQs.concat(symptoms.somaticAnxiety.expressionQs), response);
      }
    },

    substanceUse: {
      id: 28,
      isSkill: false,
      isBadged: true,
      name: 'substanceUse',
      displayOrder: 12,
      screenQs: ['drugUseInterferes'],
      expressionQs: ['othersConcernedDrugUse', 'concernedDrugDependent', 'needToReduceDrugUse'],
      textQs: [],
      mean: 1.4936,
      stddev: 1.15375,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('substanceUse', symptoms.substanceUse.screenQs.concat(symptoms.substanceUse.expressionQs), response);
      }
    },

    suicideRisk: {
      id: 28,
      isSkill: false,
      isBadged: true,
      name: 'suicideRisk',
      displayOrder: 1,
      screenQs: ['betterOffDead'],
      expressionQs: ['thoughtsOfSuicide', 'fearLoseControlSuicide', 'plansForHowSuicide'],
      textQs: [],
      mean: 2.0837,
      stddev: 1.63598,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('suicideRisk', symptoms.suicideRisk.screenQs.concat(symptoms.suicideRisk.expressionQs), response);
      }
    },

    selfHarm: {
      id: 34,
      isSkill: false,
      isBadged: true,
      name: 'selfHarm',
      displayOrder: 4,
      screenQs: ['hurtSelfPurposely'],
      expressionQs: [],
      textQs: [],
      mean: 1,
      stddev: 1.5,
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('selfHarm', symptoms.selfHarm.screenQs.concat(symptoms.selfHarm.expressionQs), response);
      }
    },

    //MIRAH CLASSIC
    anxiety: {
      id: 0,
      name: 'anxiety',
      screenQs: ['screenNervous', 'overwhelmedByAll'],
      expressionQs: ['a73', 'a74'],
      textQs: ['a75', 'a76'],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('anxiety', symptoms.anxiety.screenQs.concat(symptoms.anxiety.expressionQs), response);
      }
    },

    adhd: {
      id: 1,
      name: 'adhd',
      screenQs: ['screenAttention', 'screenSitStill'],
      expressionQs: ['a56', 'a57', 'a58', 'a59', 'a60', 'a62', 'a63', 'a64', 'a65', 'a66', 'a67', 'a68'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('adhd', symptoms.adhd.screenQs.concat(symptoms.adhd.expressionQs), response);
      }
    },

    risk: {
      id: 2,
      name: 'risk',
      screenQs: ['screenSelfHarm', 'screenOtherHarm'],
      expressionQs: ['currentSafetyConcern', 'suicidePlan', 'suicideMeans', 'pastSuicideThoughts', 'reasonForLiving', 'pastSuicideAttempt', 'riskIntentionallyHurtCut', 'dangerHurtOthers', 'planHarmOthers', 'madeThreats', 'pastAggressionTowardsOthers'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('risk', symptoms.risk.screenQs.concat(symptoms.risk.expressionQs), response);
      }
    },

    ptsd: {
      id: 3,
      name: 'ptsd',
      screenQs: ['screenPastTrauma'],
      expressionQs: ['a120', 'a121', 'a122', 'a123', 'a124', 'a126', 'a127', 'a128'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('ptsd', symptoms.ptsd.screenQs.concat(symptoms.ptsd.expressionQs), response);
      }
    },

    depression: {
      id: 4,
      name: 'depression',
      screenQs: ['screenSad', 'screenNoEnjoyment', 'screenIrritable'],
      expressionQs: ['a95', 'a96', 'a97', 'a98', 'a99', 'a100', 'a101', 'a102', 'a103', 'a106'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('depression', symptoms.depression.screenQs.concat(symptoms.depression.expressionQs), response);
      }
    },

    social: {
      id: 5,
      name: 'social',
      screenQs: ['screenSocialHard', 'screenEmbarrassed', 'conversationTendency'],
      expressionQs: ['a77', 'a78', 'a79', 'a80', 'a81'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('social', symptoms.social.screenQs.concat(symptoms.social.expressionQs), response);
      }
    },

    panic: {
      id: 6,
      name: 'panic',
      screenQs: ['screenPanic'],
      expressionQs: ['a86'],
      textQs: ['a85', 'a87'],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('panic', symptoms.panic.screenQs.concat(symptoms.panic.expressionQs), response, 6);
      }
    },

    phobia: {
      id: 6,
      name: 'phobia',
      screenQs: ['screenPhobia'],
      expressionQs: ['a90'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('phobia', symptoms.phobia.screenQs.concat(symptoms.phobia.expressionQs), response, 6);
      }
    },

    agoraphobia: {
      id: 6,
      name: 'agoraphobia',
      screenQs: ['screenAgoraphobia'],
      expressionQs: ['a89'],
      textQs: [],
      computeScore: function computeScore(response) {
        return _analytics.default.computeSymptomScore('agoraphobia', symptoms.agoraphobia.screenQs.concat(symptoms.agoraphobia.expressionQs), response, 6);
      }
    }
  };

  exports.default = symptoms;
});