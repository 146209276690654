define('frontend/pods/provider/admin/payors/show/contracts/create/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var institutePayor = this.modelFor('provider.admin.payors.show');

      return {
        payorContract: this.store.createRecord('billing/payorContract', { institutePayor: institutePayor })
      };
    },

    breadCrumb: {
      title: 'Add new contract'
    }
  });
});