define('frontend/pods/components/provider/patients/create-patient/component', ['exports', 'frontend/mixins/workflow-enabled', 'frontend/mixins/error-printer'], function (exports, _workflowEnabled, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_workflowEnabled.default, _errorPrinter.default, {
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    classNames: ['create-patient'],

    pageTitle: Ember.computed("workflow.headerTitle", function () {
      var workflowTitle = this.get("workflow.headerTitle");
      if (workflowTitle) {
        return workflowTitle;
      } else {
        return "provider.patients.createPatient.title";
      }
    }),
    pageDescription: Ember.computed("workflow.headerDescription", function () {
      var workflowTitle = this.get("workflow.headerDescription");
      if (workflowTitle) {
        return workflowTitle;
      } else {
        return "provider.patients.createPatient.description";
      }
    }),

    actions: {
      savePatient: function savePatient() {
        var _this = this;

        var success = function success(patient) {
          _this.get('messages').success("Patient created");
          _this.get("metrics").trackEvent({ event: "provider.patient.created" });
          if (_this.get("hasWorkflow")) {
            _this.completeWorkflowWithRedirect(patient);
          } else {
            if (_this.get("onSave")) {
              _this.get("onSave")();
            } else if (_this.get("onSaveRoute")) {
              _this.get("router").transitionTo(_this.get("onSaveRoute"), patient);
            }
          }
        };

        var failure = function failure(reason) {
          _this.get('messages').danger(_this.printError(reason, 'patient'));
        };

        return this.get("patient").save().then(success).catch(failure);
      }
    }
  });
});