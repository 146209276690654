define('frontend/helpers/past-score', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function () /*params*/{
    //var score = params[0];
    return Math.rand() > 0.5;
  });
});