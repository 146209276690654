define('frontend/pods/components/common/client-side-table/component', ['exports', 'ember-models-table/components/models-table', 'frontend/pods/components/common/server-side-table/template'], function (exports, _modelsTable, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelsTable.default.extend({
    layout: _template.default,
    columnsDropdownTemplate: "components/common/server-side-table/columns-dropdown",
    globalFilterTemplate: "components/common/server-side-table/global-filter",
    tableFooterTemplate: "components/common/server-side-table/table-footer",
    rowTemplate: "components/common/server-side-table/row",
    useFilteringByColumns: false,
    customClasses: {
      table: 'table table-condensed table-hover list',
      pageSizeSelectWrapper: 'pull-right form-group pageSizeWrapper',
      paginationWrapperPrevious: "pull-left table-nav col-xs-2 col-sm-2 col-md-2",
      paginationWrapperNext: "pull-right table-nav col-xs-2 col-sm-2 col-md-2",
      footerSummary: "col-xs-8 col-md-8 col-sm-8 table-summary"
    },
    listView: true,
    showHeader: Ember.computed.not('listView'),
    customIcons: {
      "sort-asc": "fa fa-sort-asc",
      "sort-desc": "fa fa-sort-desc",
      "unsorted": "fa fa-sort",
      "column-visible": "fa fa-check-square-o",
      "column-hidden": "fa fa-square-o",
      "nav-first": "fa fa-step-backward",
      "nav-prev": "fa fa-chevron-left",
      "nav-next": "fa fa-chevron-right",
      "nav-last": "fa fa-step-forward",
      "caret": "caret"
    },
    actions: {
      columnClick: function columnClick(row, column) {
        // don't show the drawer for button clicks inside a row
        // This is currently broken in firefox as firefox does not set a global `event` variable and ember doesn't pass
        // it through
        if (typeof event !== 'undefined' && ($(event.target).closest('a').length || $(event.target).closest('button').length)) {
          return;
        }
        // Set to null to undo default action.
        if (typeof column.onClick !== 'undefined') {
          // But only call if is a function
          if (typeof column.onClick === 'function') {
            column.onClick(row);
          }
        } else if (this.get("onRowClick")) {
          this.get("onRowClick")(row);
        }
      }
    },
    screen: Ember.inject.service(),
    columns: Ember.computed('screen.isCompressed', 'fullColumns', 'compressedColumns', function () {
      return this.get("screen.isCompressed") && this.get("compressedColumns") ? this.get("compressedColumns") : this.get("fullColumns");
    }),

    columnsChangedObserver: Ember.observer('columns.[]', function () {
      this._setupColumns();
    })
  });
});