define('frontend/pods/components/provider/assessment/scale-browser/list-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['scale-browser-list'],
    intl: Ember.inject.service(),

    fullColumns: Ember.computed('showSelect', function () {
      var intl = this.get('intl');
      var columns = [{
        component: 'components/provider/assessment/scale-browser/list-component/extended-details-column',
        title: intl.t('model.scale.name'),
        width: "300px",
        filteredBy: "translatedShortname",
        sortedBy: "translatedShortnameText"
      },
      // Used for filtering only
      {
        isHidden: true,
        propertyName: "translatedName"
      }, {
        isHidden: true,
        propertyName: "translatedNanoname"
      }, {
        isHidden: true,
        propertyName: "tags"
      }, {
        template: 'components/provider/assessment/scale-browser/list-component/languages-column',
        title: ''
      }, {
        propertyName: "translatedCategory",
        title: intl.t('model.scale.category')
      }, {
        template: 'components/provider/assessment/scale-browser/list-component/age-column',
        title: intl.t('model.scale.age'),
        sortedBy: 'minAge'
      }, {
        title: intl.t('model.scale.estimatedTimeNano'),
        template: 'components/provider/assessment/scale-browser/list-component/estimated-time-column',
        sortedBy: "estimatedTime"
      }, {
        template: 'components/provider/assessment/scale-browser/list-component/actions-column'
      }];

      if (this.get("showSelect")) {
        columns.unshift({
          component: 'components/provider/assessment/scale-browser/list-component/selected-scale-column',
          disableRowClick: true, // Don't activate drawer inside this column
          className: 'text-center'
        });
      } else if (this.get("showChoose")) {
        columns.unshift({
          component: 'components/provider/assessment/scale-browser/list-component/choose-scale-column',
          disableRowClick: true // Don't activate drawer inside this column
        });
      }

      return columns;
    }),

    compressedColumns: Ember.computed('showSelect', function () {
      var intl = this.get('intl');
      var columns = [{
        component: 'components/provider/assessment/scale-browser/list-component/extended-details-column',
        title: intl.t('model.scale.name'),
        width: "300px",
        filteredBy: "translatedShortname",
        sortedBy: "translatedShortnameText"
      },
      // Used for filtering only
      {
        isHidden: true,
        propertyName: "translatedName"
      }, {
        isHidden: true,
        propertyName: "translatedNanoname"
      }, {
        isHidden: true,
        propertyName: "tags"
      }, {
        template: 'components/provider/assessment/scale-browser/list-component/languages-column'
      }, {
        propertyName: "translatedCategory",
        title: intl.t('model.scale.category')
      }, {
        template: 'components/provider/assessment/scale-browser/list-component/age-column',
        title: intl.t('model.scale.age'),
        sortedBy: 'minAge'
      }];

      if (this.get("showSelect")) {
        columns.unshift({
          component: 'components/provider/assessment/scale-browser/list-component/selected-scale-column',
          disableRowClick: true // Don't activate drawer inside this column
        });
      } else if (this.get("showChoose")) {
        columns.unshift({
          component: 'components/provider/assessment/scale-browser/list-component/choose-scale-column',
          disableRowClick: true // Don't activate drawer inside this column
        });
      }

      return columns;
    }),

    showSelect: Ember.computed.notEmpty('report'),
    actions: {
      onScaleChange: function onScaleChange() {
        this.get("onScaleChange").apply(this, arguments);
      },
      onFocusScale: function onFocusScale() {
        this.get("onFocusScale").apply(this, arguments);
      },
      updateFilter: function updateFilter(filteredScales) {
        this.set("filteredScales", filteredScales);
      },
      removeReportScale: function removeReportScale(reportScale) {
        var report = this.get("report");

        if (report.content) {
          report = report.content;
        }

        report.removeReportScale(reportScale);
      }
    }
  });
});