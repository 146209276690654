define('frontend/models/assessment/population', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    mnemonic: _emberData.default.attr("string"),
    norms: _emberData.default.hasMany("assessment/norm")
  });
});