define('frontend/pods/components/provider/reports/outcomes-explorer-mock/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    outcomeTypes: [{ value: 'overallFeeling', name: 'Severity' }, { value: 'phq9', name: 'PHQ-9' }],
    currentOutcome: { value: 'overallFeeling', name: 'Severity' },
    actions: {
      updateOutcomeType: function updateOutcomeType(outcomeType) {
        this.set('currentOutcome', _lodash.default.find(this.get('outcomeTypes'), function (outcome) {
          return outcome.value === outcomeType;
        }));
      }
    },

    patientStatusIcon: {
      active: 'fa-calendar-o',
      unknown: 'fa-question-circle-o',
      discharged: 'fa-calendar-check-o'
    },

    meanPatient: {
      overallAdherence: { value: '73%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '5', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    medianPatient: {
      overallAdherence: { value: '80%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '8', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    patients: [{
      name: "Frank Blue",
      provider: 'Dr. Zoidberg',
      apptHistory: ['C', 'C', 'P', 'C', 'C', 'Y'],
      firstAppt: { date: 'May 20' },
      lastAppt: { date: '' },
      patientStatus: 'active',
      completionMethods: ['kiosk'],
      overallAdherence: { value: '25', className: "bad" },
      outcomes: {
        phq9: { initial: '25', final: '27', className: "bad", arrowClass: 'moderate', sparkline: 'sparkline2.png' },
        overallFeeling: { initial: '7', final: '9', className: "bad", arrowClass: 'bad', sparkline: 'sparkline2.png' }
      }
    }, {
      name: "Larry Purple",
      provider: 'Dr. Jones',
      apptHistory: ['C', 'Y', 'Y'],
      firstAppt: { date: 'June 15' },
      lastAppt: { date: '' },
      patientStatus: 'unknown',
      completionMethods: ['email'],
      overallAdherence: { value: '66', className: "moderate" },
      outcomes: {
        phq9: { initial: '14', final: '10', className: "moderate", arrowClass: 'good', sparkline: 'sparkline1.png' },
        overallFeeling: { initial: '10', final: '7', className: "moderate", arrowClass: 'good', sparkline: 'sparkline3.png' }
      }
    }, {
      name: "Elena Lavender",
      provider: 'Dr. Zoidberg',
      apptHistory: ['Y'],
      firstAppt: { date: 'July 7' },
      lastAppt: { date: '' },
      patientStatus: 'active',
      completionMethods: ['email'],
      overallAdherence: { value: '100', className: "good" },
      outcomes: {
        phq9: { initial: '19', className: "good", arrowClass: 'good', sparkline: undefined },
        overallFeeling: { initial: '6', className: "good", arrowClass: 'good', sparkline: undefined }
      }
    }, {
      name: "Megan Sandy",
      provider: 'Dr. Jones',
      apptHistory: ['Y', 'Y', 'Y', 'Y', 'Y', 'Y'],
      firstAppt: { date: 'May 15' },
      lastAppt: { date: 'June 26' },
      patientStatus: 'discharged',
      completionMethods: ['email', 'kiosk'],
      overallAdherence: { value: '100', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '5', className: "good", arrowClass: 'good', sparkline: 'sparkline3.png' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good', sparkline: 'sparkline2.png' }
      }
    }, {
      name: "Steve Ochre",
      provider: 'Dr. Jones',
      apptHistory: ['Y', 'Y', 'P', 'Y', 'C', 'Y'],

      firstAppt: { date: 'May 15' },
      lastAppt: { date: 'June 26' },
      patientStatus: 'discharged',
      completionMethods: ['email'],
      overallAdherence: { value: '80', className: "good" },
      outcomes: {
        phq9: { initial: '23', final: '4', className: "good", arrowClass: 'good', sparkline: 'sparkline3.png' },
        overallFeeling: { initial: '8', final: '3', className: "good", arrowClass: 'good', sparkline: 'sparkline3.png' }
      }
    }]
  });
});