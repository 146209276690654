define('frontend/models/care/alter-measurement-plan-scale-request', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    effectiveDate: (0, _emberCpValidations.validator)('presence', true),
    entityTreeNode: (0, _emberCpValidations.validator)('presence', true),
    scalesToRemove: (0, _emberCpValidations.validator)('length', { min: 1 })
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    scalesToRemove: _emberData.default.hasMany('assessment/scale', { inverse: null }),
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode', { inverse: null }),
    effectiveDate: _emberData.default.attr('date'),
    allowChange: _emberData.default.attr('boolean')
  });
});