define('frontend/pods/components/provider/plan/select-or-create-plan-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    sessionUser: Ember.inject.service(),
    planSelection: 'existing',
    actions: {
      selectMeasurementPlan: function selectMeasurementPlan() {
        this.set('entityMeasurementPlan.measurementPlan', null);
      },
      newMeasurementPlan: function newMeasurementPlan() {
        var newPlan = this.store.createRecord('care/measurementPlan', {
          autoCreateScale: true,
          author: this.get('sessionUser.currentUser'),
          defaultPlanType: this.get('entityMeasurementPlan.planType')
        });

        var activeScale = this.store.createRecord('care/measurementPlanScale', { measurementPlan: newPlan, schedulePreset: 'everySession' });
        newPlan.set('initialOpenScale', activeScale);

        this.set('entityMeasurementPlan.measurementPlan', newPlan);
        this.set('entityMeasurementPlan.planModified', false);
      },
      newMeasurementPlanFromTemplate: function newMeasurementPlanFromTemplate() {
        this.setProperties({ 'entityMeasurementPlan.measurementPlan': null });
      },
      editPlan: function editPlan() {
        this.set('entityMeasurementPlan.planModified', true);
      },
      resetTemplate: function resetTemplate() {
        this.set('templateMeasurementPlan', null);
        // Note that it will automatically roll back the other plan.
      },
      templateSelected: function templateSelected(plan) {
        if (plan && plan.content) {
          plan = plan.content;
        }

        this.setProperties({
          'entityMeasurementPlan.measurementPlan': plan.duplicate(this.get('sessionUser.currentUser'))
        });
      }
    }
  });
});