define('frontend/pods/components/common/controls/date-range/component', ['exports', 'frontend/pods/components/common/controls/date-range/preset-operations'], function (exports, _presetOperations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showStartDate: false,

    startDate: null,
    endDate: null,
    timePreset: 'thisMonth',

    timePresets: ['custom', 'yesterday', 'today', 'lastWeek', 'thisWeek', 'lastMonth', 'thisMonth', 'lastYear', 'thisYear', 'allTime'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!(this.get('startDate') && this.get('endDate'))) {
        this.updateDatesFromTimePreset(this.get('timePreset'));
      }
    },
    updateDatesFromTimePreset: function updateDatesFromTimePreset(preset) {
      if (preset !== 'custom') {
        var dates = (0, _presetOperations.default)(preset);
        this.setProperties(dates);
      }
    },


    paramsChanged: Ember.on('init', Ember.observer('startDate', 'endDate', 'timePreset', function () {
      // Required to prevent multiple triggers
      Ember.run.once(this, 'sendParams');
    })),

    sendParams: function sendParams() {
      this.sendAction('onChange', {
        preset: this.get('timePreset'),
        startDate: this.get('startDate'),
        endDate: this.get('endDate')
      });
    },


    actions: {
      setTimePreset: function setTimePreset(event) {
        this.set('timePreset', event.target.value);
        this.updateDatesFromTimePreset(event.target.value);
      },
      setStartDate: function setStartDate(value) {
        var date = moment(value).startOf("day").toDate();
        this.setProperties({
          timePreset: 'custom',
          startDate: date
        });
      },
      setEndDate: function setEndDate(value) {
        var date = moment(value).endOf("day").toDate();
        this.setProperties({
          timePreset: 'custom',
          endDate: date
        });
      }
    }
  });
});