define('frontend/pods/components/internal/institutes/create-institute/component', ['exports', 'frontend/mixins/workflow-enabled'], function (exports, _workflowEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_workflowEnabled.default, {
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    classNames: ['create-institute'],

    actions: {
      save: function save() {
        var _this = this;

        var success = function success(institute) {
          _this.get('messages').success("Institute created");
          _this.get("metrics").trackEvent({ event: "internal.institute.created" });

          if (_this.get("onSave")) {
            _this.get("onSave")();
          } else if (_this.get("onSaveRoute")) {
            _this.get("router").transitionTo(_this.get("onSaveRoute"));
          } else {
            _this.get("router").transitionTo('internal.institutes.show', institute.get('id'));
          }
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error creating institute: " + reason);
        };

        return this.get("institute").save().then(success).catch(failure);
      }
    }
  });
});