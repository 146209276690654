define('frontend/pods/components/provider/care-episodes/care-team-selector/component', ['exports', 'ember-cp-validations', 'frontend/models/provider', 'frontend/mixins/rollback-mixin'], function (exports, _emberCpValidations, _provider, _rollbackMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NewRowValidations = (0, _emberCpValidations.buildValidations)({
    provider: (0, _emberCpValidations.validator)('presence', true),
    relationship: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = Ember.Component.extend(_rollbackMixin.default, {
    showAllProviders: true,
    relationshipOptions: _provider.Relationships,
    autoAddSelf: Ember.computed.oneWay("careEpisode.isNew"),
    rollbackProperties: ['careEpisode.careEpisodeProviders'],

    unusedProviders: Ember.computed("showAllProviders", "activeCareEpisodeProviders", "organization.providers", function () {
      var usedProviderIds = this.get('activeCareEpisodeProviders').mapBy("provider.id");

      var providersToUse;

      if (this.get("showAllProviders")) {
        providersToUse = this.getWithDefault("allProviders", []);
      } else {
        providersToUse = this.getWithDefault("organization.providers", []).sortBy('user.lastName');
      }

      return providersToUse.reject(function (provider) {
        return usedProviderIds.includes(provider.get("id"));
      });
    }),

    activeCareEpisodeProviders: Ember.computed("careEpisode.careEpisodeProviders.@each.isDeleted", function () {
      return this.getWithDefault("careEpisode.careEpisodeProviders", []).rejectBy("isDeleted");
    }),

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get("autoAddSelf")) {
        this._addSelf();
      }

      this.set("newRow", Ember.Object.extend(NewRowValidations, {
        provider: null,
        relationship: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },


    showAdd: Ember.computed.notEmpty("unusedProviders"),
    showAddRelationship: Ember.computed.notEmpty("newRow.provider"),
    actions: {
      addProvider: function addProvider() {
        if (this.get("newRow.validations.isInvalid")) {
          return;
        }

        this._addProvider();
      },
      removeProvider: function removeProvider(careEpisodeProvider) {
        this._removeProvider(careEpisodeProvider);
      },
      setDefaultRelationship: function setDefaultRelationship(provider) {
        if (provider) {
          this.set("newRow.relationship", provider.get("defaultRelationship"));
        }
      }
    },

    _removeProvider: function _removeProvider(careEpisodeProvider) {
      if (careEpisodeProvider.get("isNew")) {
        careEpisodeProvider.rollbackAttributes(); // Remove it from the store
      } else {
        careEpisodeProvider.deleteRecord(); // Mark as deleted but do not yet persist to server.
      }
    },
    _addSelf: function _addSelf() {
      var provider = this.get('activeProvider');
      if (provider && provider.get("defaultRelationship")) {
        this._doAddProvider(provider, provider.get("defaultRelationship"));
      }
    },
    _addProvider: function _addProvider() {
      this._doAddProvider(this.get("newRow.provider"), this.get("newRow.relationship"));
      this.set("newRow.provider", null);
      this.set("newRow.relationship", null);
    },
    _doAddProvider: function _doAddProvider(provider, relationship) {
      var providerRow = this.getWithDefault("careEpisode.careEpisodeProviders", []).findBy("provider.id", provider.get("id"));

      if (providerRow) {
        if (providerRow.get("isDeleted")) {
          providerRow.rollbackAttributes();
        }

        providerRow.set("relationship", relationship);
      } else {
        providerRow = this.get("store").createRecord("careEpisodeProvider", {
          relationship: relationship,
          provider: provider,
          careEpisode: this.get("careEpisode")
        });
      }

      return providerRow;
    }
  });
});