define('frontend/pods/components/survey/taking-local/common/questions/month-year-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, {
    months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    years: Ember.computed('question.properties.yearLookback', function () {
      var now = new Date().getFullYear();

      var lookback = this.getWithDefault('question.properties.yearLookback', 30);

      var years = [now];
      var currentYear = now;

      for (var ii = 0; ii <= lookback; ii++) {
        currentYear -= 1;
        years.addObject(currentYear);
      }

      return years;
    }),

    dateDisabled: Ember.computed.and('isNa', 'showNa'),

    _setValidAnswer: function _setValidAnswer(month, year) {
      if (month && year) {
        var responseItem = this._prepareForNewValue();
        var newValue = month + '/' + year;
        responseItem.set('value', newValue);

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    },

    showNa: Ember.computed.readOnly('question.properties.showNa'),

    selectedMonth: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        if (string && string !== 'na') {
          return string.split('/')[0];
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        this._setValidAnswer(value, this.get('selectedYear'));

        return value;
      }
    }),
    selectedYear: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        if (string && string !== 'na') {
          return parseInt(string.split('/')[1]);
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        this._setValidAnswer(this.get('selectedMonth'), value);

        return value;
      }
    }),
    isNa: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        return string && string === 'na';
      },
      set: function set(key, value) {
        var responseItem = this._prepareForNewValue();
        responseItem.set('value', 'na');

        if (this.get("onChange")) {
          this.get("onChange")();
        }

        return value;
      }
    }),

    actions: {
      onSelectYear: function onSelectYear(year) {
        this.set('selectedYear', year);
      },
      onSelectMonth: function onSelectMonth(year) {
        this.set('selectedMonth', year);
      },
      toggleNa: function toggleNa() {
        this.toggleProperty("isNa");
      }
    }
  });
});