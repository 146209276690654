define('frontend/routes/provider', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    // pageHeader: Ember.inject.service(),

    // This will work to stop headers being shown when they shouldn't be
    // but it causes flashing as the header briefly disappears. So for
    // now you explicitly have to call {{common/page-header visible=false}}
    // to hide it
    // actions: {
    //   willTransition(transition) {
    //     this.get("pageHeader").reset();
    //   }
    // }
  });
});