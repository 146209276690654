define('frontend/pods/components/common/scale-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showFilter: Ember.computed.notEmpty('patient.id'),
    showDemographics: true,
    filterScales: Ember.computed.notEmpty('patient.id'),
    actions: {
      updateSelected: function updateSelected(selected) {
        this.get("onScaleChange")(selected);
      }
    },

    sortedScales: Ember.computed('scales.[]', 'hiddenScales.[]', function () {
      var hiddenScales = this.get('hiddenScales');
      var scales = this.get('scales');

      if (hiddenScales) {
        var hiddenScaleIds = hiddenScales.mapBy('id');
        scales = scales.reject(function (scale) {
          return hiddenScaleIds.indexOf(scale.get('id')) >= 0;
        });
      }

      if (this.get("filterScales")) {
        var patient = this.get('patient');

        scales = scales.filter(function (scale) {
          return scale.hasCorrectDemographics(patient);
        });
      }

      return scales.sortBy('translatedShortnameText');
    }),

    filterActions: Ember.computed('filterScales', function () {
      var _this = this;

      return {
        showFilter: this.get("showFilter"),
        filterScales: this.get("filterScales"),
        onSetFilter: function onSetFilter() {
          _this.set('filterScales', true);
          _this.notifyPropertyChange('sortedScales');
        },
        onClearFilter: function onClearFilter() {
          _this.set('filterScales', false);
          _this.notifyPropertyChange('sortedScales');
        }
      };
    })
  });
});