define('frontend/pods/components/provider/assessment/report-v2/combined-graph/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled', 'lodash', 'frontend/models/assessment/color-settings'], function (exports, _component, _drawerEnabled, _lodash, _colorSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_drawerEnabled.default, {
    graphDomains: ['raw', 'normed'],

    primaryScale: Ember.computed("scales", function () {
      return this.getWithDefault("scales", []).find(function (s) {
        return s.get("displayData.groupDisplayData");
      });
    }),

    colorSettings: Ember.computed('scales.[]', 'reports', function () {
      var scaleIds = this.get('scales').mapBy('id');
      var cvs = this.get("reports").flatMap(function (r) {
        return r.get('computedValues').filter(function (cv) {
          return scaleIds.includes(cv.get('scale.id'));
        });
      });

      return _colorSettings.default.create({ computedValues: cvs, reportSettings: this.get('reportSettings') });
    }),

    enableFilter: true,

    // Note that scales is passed in through parameter.

    currentValuesByScale: Ember.computed("currentValues.[]", function () {
      return _lodash.default.groupBy(this.get("currentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),
    mostRecentValuesByScale: Ember.computed("mostRecentValues.[]", function () {
      return _lodash.default.groupBy(this.get("mostRecentValues"), function (cv) {
        return cv.get("scale.mnemonic");
      });
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});