define('frontend/pods/provider/assessment/dashboard/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/provider-filter-route', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _authenticatedRouteMixin, _providerFilterRoute, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _providerFilterRoute.default, _metricsMixin.default, {
    breadCrumb: null,
    queryParams: {
      activeTab: {}
    },
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),
    userTaskService: Ember.inject.service('provider/taskService'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      return Ember.RSVP.hash({
        flags: this.get("preload.flags"),
        provider: this.get("sessionUser.activeProvider")
      });
    },


    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.assessment.dashboardVisit" });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // reset the return url on exit.
        controller.set('activeTab', null);
      }
    }
  });
});