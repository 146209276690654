define('frontend/pods/components/reporting/columns/attendance-rate/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'attendance-rate',
    valueNotApplicable: Ember.computed('value', function () {
      var value = this.get('value');
      return value === undefined || value === null;
    }),
    indicatorClass: Ember.computed('value', function () {
      var value = this.get('value');

      if (!value) {
        return null;
      } else if (value >= 0.8) {
        return 'good';
      } else if (value >= 0.6) {
        return 'moderate';
      } else {
        return 'bad';
      }
    })
  });
});