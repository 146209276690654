define('frontend/controllers/provider/exit-to-kiosk', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    metrics: Ember.inject.service(),
    rootURL: _environment.default.rootURL,
    actions: {
      confirmExit: function confirmExit() {
        // This abomination exists because there doesn't seem to be a way
        // of getting a single, full url for a route, so we have to combine
        // in this fudgy way.
        var url = this.rootURL.replace(/\/$/, '') + this.get('target').generate('patient.welcome') + ('?shortcode=' + this.get("institute.current.shortcode"));
        this.set('session.invalidationUrl', url);
        this.set("session.logoutReason", "userLogout");
        this.get("metrics").trackEvent({ event: "user.logout", reason: "goToKiosk" });
        this.get('sessionUser').logout();
      },
      goBack: function goBack() {
        window.history.go(-1);
      }
    }
  });
});