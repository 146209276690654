define('frontend/models/assessment/raw-scale-datum', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'frontend/models/assessment/scale-templates'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper, _scaleTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.migrateToMultiScale = exports.Registry = exports.Validations = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var validationOptions = {
    mnemonic: (0, _emberCpValidations.validator)('presence', true),
    version: (0, _emberCpValidations.validator)('presence', true),
    scaleData: (0, _emberCpValidations.validator)('presence', true),
    parsedScaleData: (0, _emberCpValidations.validator)('belongs-to', true),
    scaleDataParseError: (0, _emberCpValidations.validator)('inclusion', { in: [false] })
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  var Registry = {
    create: function create() {
      return {
        internalRegistry: {
          question: {},
          scale: {},
          survey: {}
        },
        listeners: {},
        listenForChange: function listenForChange(id, func, type) {
          if (!this.listeners[type]) {
            this.listeners[type] = {};
          }

          this.listeners[type][id] = func;
        },
        stopListeningForChange: function stopListeningForChange(id, type) {
          if (this.listeners[type] && this.listeners[type][id]) {
            delete this.listeners[type][id];
          }
        },
        register: function register(object, name, type) {
          if (!this.internalRegistry[type]) {
            this.internalRegistry[type] = {};
          }

          if (this.internalRegistry[type][name]) {
            return false;
          } else {
            this.internalRegistry[type][name] = object;

            if (this.listeners[type]) {
              Object.values(this.listeners[type]).forEach(function (listener) {
                listener();
              });
            }
            return true;
          }
        },
        unregister: function unregister(object, name, type) {
          if (this.internalRegistry[type] && this.internalRegistry[type][name]) {
            delete this.internalRegistry[type][name];
          }
        },
        reregister: function reregister(object, newName, type) {
          if (this.internalRegistry[type][newName] && this.internalRegistry[type][newName] !== object) {
            // Already taken.
            return false;
          }

          var oldKey = null;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = Object.entries(this.internalRegistry)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var _ref = _step.value;

              var _ref2 = _slicedToArray(_ref, 2);

              var key = _ref2[0];
              var storedObject = _ref2[1];

              if (object === storedObject) {
                oldKey = key;
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          this.internalRegistry[type][newName] = object;

          if (oldKey && oldKey !== newName) {
            delete this.internalRegistry[type][oldKey];
          }

          if (this.listeners[type]) {
            Object.values(this.listeners[type]).forEach(function (listener) {
              listener();
            });
          }

          return true;
        },
        optionsForType: function optionsForType(type) {
          return this.internalRegistry[type];
        }
      };
    }
  };

  function migrateToMultiScale(rawContent, mnemonic) {
    rawContent.survey.mnemonic = mnemonic;
    rawContent.surveys = [rawContent.survey];
    delete rawContent.survey;
    return rawContent;
  }

  exports.Validations = Validations;
  exports.Registry = Registry;
  exports.migrateToMultiScale = migrateToMultiScale;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    mnemonic: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    statusEvent: _emberData.default.attr('string'),
    createNewVersion: _emberData.default.attr('boolean'),
    publish: _emberData.default.attr('boolean'),
    successorId: _emberData.default.attr(),
    globallyAvailable: _emberData.default.attr('boolean'),
    scaleData: _emberData.default.attr('object'),
    publishedAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    institute: _emberData.default.belongsTo('institute'),
    scaleTemplates: Ember.inject.service(),
    publishLog: _emberData.default.attr(),
    publishSuccess: _emberData.default.attr(),

    scaleDataParseError: false,

    registry: Ember.computed(function () {
      return Registry.create();
    }),

    updateRawContent: function updateRawContent() {
      this.set('scaleData', this.get('parsedScaleData').toJson());
    },


    reviewNotes: Ember.computed('parsedScaleData.properties.review_notes', {
      get: function get() {
        return this.get('parsedScaleData.properties.review_notes');
      },
      set: function set(key, value) {
        var parsedScaleData = this.get("parsedScaleData");

        if (!parsedScaleData.get("properties")) {
          parsedScaleData.set("properties", {});
        }

        parsedScaleData.set("properties.review_notes", value);
        return value;
      }
    }),

    versionNotes: Ember.computed('parsedScaleData.properties.version_notes', {
      get: function get() {
        return this.get('parsedScaleData.properties.version_notes');
      },
      set: function set(key, value) {
        var parsedScaleData = this.get("parsedScaleData");

        if (!parsedScaleData.get("properties")) {
          parsedScaleData.set("properties", {});
        }

        parsedScaleData.set("properties.version_notes", value);
        return value;
      }
    }),

    notes: Ember.computed('parsedScaleData.properties.notes', {
      get: function get() {
        return this.get('parsedScaleData.properties.notes');
      },
      set: function set(key, value) {
        var parsedScaleData = this.get("parsedScaleData");

        if (!parsedScaleData.get("properties")) {
          parsedScaleData.set("properties", {});
        }

        parsedScaleData.set("properties.notes", value);
        return value;
      }
    }),

    dependencies: Ember.computed('parsedScaleData.properties.dependencies', {
      get: function get() {
        var value = this.get('parsedScaleData.properties.dependencies');

        if (value) {
          return value.join('|');
        }
        return value;
      },
      set: function set(key, value) {
        var parsedScaleData = this.get("parsedScaleData");

        if (!parsedScaleData.get("properties")) {
          parsedScaleData.set("properties", {});
        }

        if (value) {
          value = value.split("|");
        }

        parsedScaleData.set("properties.dependencies", value);
        return value;
      }
    }),

    parsedScaleData: Ember.computed('scaleData', {
      get: function get() {
        return _scaleTemplates.RawSurvey.fromJson(this.get('scaleData'), this.get('registry'), Ember.getOwner(this));
      },
      set: function set(key, value) {
        this.set("scaleData", value.toJson());
        return value;
      }
    }),

    scaleDataText: Ember.computed('scaleData', {
      get: function get() {
        return JSON.stringify(this.get('scaleData'), null, 2);
      },
      set: function set(key, value) {
        try {
          var json = JSON.parse(value);
          this.setProperties({ scaleDataParseError: false, scaleData: json });
        } catch (err) {
          this.set('scaleDataParseError', true);
        }

        return value;
      }
    })
  });
});