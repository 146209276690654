define('frontend/pods/components/reporting/headline-stat/component', ['exports', 'frontend/pods/components/reporting/table-column/component', 'lodash'], function (exports, _component, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var percentageTransform = function percentageTransform(value) {
    if (!value && value !== 0) {
      return null;
    }

    return value * 100;
  };

  var identityTransform = function identityTransform(value) {
    return value;
  };

  var currencyTransform = function currencyTransform(value) {
    return value / 100;
  };

  exports.default = _component.default.extend({
    classNames: ['gauge'],

    row: Ember.computed.alias('reportEntityTreeNode.summaryData'),
    comparisonRow: Ember.computed.alias('reportEntityTreeNode.comparisonData'),

    // This operation is slightly complicated because the charting library needs to be tricked into putting the comparison 
    // data into the same bucket as the main, i.e. lie about the date. But you can have different lengths depending on the break.
    transformedGraphData: Ember.computed('column.id', 'column.propertyName', 'reportEntityTreeNode.summaryGraphData', 'reportEntityTreeNode.comparisonGraphData', function () {
      var columnType = this.get('column.type');
      var propertyName = this.get('column.propertyName');
      var multiProperty = this.get('column.multiProperty');
      var dataMapping = this.get('column.dataMapping');
      var graphData = this.get('reportEntityTreeNode.summaryGraphData');
      var valueTransform = void 0;

      switch (this.get("column.type")) {
        case 'percent':
          valueTransform = percentageTransform;
          break;
        case 'monetary':
          valueTransform = currencyTransform;
          break;
        default:
          valueTransform = identityTransform;
      }

      var comparisonGraphData = this.get('reportEntityTreeNode.comparisonGraphData');

      if (!graphData) {
        return null;
      }

      if (multiProperty) {
        return this._stackedGraphData(graphData, dataMapping, valueTransform, columnType);
      }

      if (comparisonGraphData) {
        return {
          data: this._transformGraphData(graphData, propertyName, valueTransform),
          comparison: this._transformComparisonGraphData(graphData, comparisonGraphData, propertyName, valueTransform)
        };
      } else {
        return {
          data: this._transformGraphData(graphData, propertyName, valueTransform)
        };
      }
    }),

    _transformGraphData: function _transformGraphData(graphData, propertyName, valueTransform) {
      return graphData.flatMap(function (row) {
        if (row[propertyName] === null) {
          return [];
        } else {
          return [{ x: new Date(row.interval), y: valueTransform(row[propertyName]) }];
        }
      });
    },
    _transformComparisonGraphData: function _transformComparisonGraphData(graphData, comparisonData, propertyName, valueTransform) {
      return _lodash.default.zip(graphData, comparisonData).flatMap(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            mainRow = _ref2[0],
            comparisonRow = _ref2[1];

        // Dump the last row of the comparison if there's no main row.
        if (!mainRow || !comparisonRow) {
          return [];
        }
        return [{
          // Use the same date as the main row to make it appear on the graph in the right place
          x: new Date(mainRow.interval),
          y: valueTransform(comparisonRow[propertyName]),
          originalX: new Date(comparisonRow.interval)
        }];
      });
    },
    _stackedGraphData: function _stackedGraphData(graphData, dataMapping, valueTransform, type) {
      switch (type) {
        case 'classified-bar':
          return this._classifiedBarStackedGraphData(graphData, dataMapping, valueTransform);
        case 'scale-threshold-breakdown':
        default:
          return this._breakdownStackedGraphData(graphData, dataMapping, valueTransform);
      }
    },
    _classifiedBarStackedGraphData: function _classifiedBarStackedGraphData(graphData, dataMapping, valueTransform) {
      var mappingWithColors = [{ id: 'good', color: '#379392', name: dataMapping.goodTitle, source: dataMapping.good }, { id: 'medium', color: '#babfd4', name: dataMapping.mediumTitle, source: dataMapping.medium }, { id: 'bad', color: '#e00', name: dataMapping.badTitle, source: dataMapping.bad }];

      return {
        dataMapping: mappingWithColors,
        data: mappingWithColors.reduce(function (mapping, part) {
          mapping[part.id] = graphData.flatMap(function (row) {
            if (row[part.source] === null) {
              return [];
            } else {
              return [{ x: new Date(row.interval), stack: part.id, y: valueTransform(row[part.source]) }];
            }
          });

          return mapping;
        }, {})
      };
    },
    _breakdownStackedGraphData: function _breakdownStackedGraphData(graphData, dataMapping, valueTransform) {
      return {
        dataMapping: dataMapping,
        data: dataMapping.reduce(function (mapping, part) {
          mapping[part.id] = graphData.flatMap(function (row) {
            if (row[part.id] === null) {
              return [];
            } else {
              return [{ x: new Date(row.interval), stack: part.id, y: valueTransform(row[part.id]) }];
            }
          });

          return mapping;
        }, {})
      };
    },


    column: Ember.computed.oneWay('defaultColumn'),

    graphConfig: Ember.computed('column.multiProperty', function () {
      if (this.get('column.multiProperty')) {
        return {
          type: 'stacked-vertical-bar',
          map: function map(value) {
            return value;
          }
        };
      } else {
        return {
          type: 'line',
          map: function map(value) {
            return value;
          }
        };
      }
    }),

    graphType: Ember.computed('column.multiProperty', function () {
      if (this.get('column.multiProperty')) {
        return 'stacked';
      } else {
        return 'timeSeries';
      }
    }),

    hasGraph: Ember.computed.notEmpty('transformedGraphData.data'),

    didReceiveAttrs: function didReceiveAttrs() {
      // After you change the column, it is no longer automatically synched from the
      // available columns.
      // If you change the report using the sidebar, it will not automatically reset
      // and the column may no longer be available. So check if we have to
      // reset the column to its default.
      var column = this.get('column');
      var availableColumns = this.get('availableColumns');

      if (column && availableColumns && !availableColumns.any(function (col) {
        return col.id === column.id;
      })) {
        this.set("column", this.get("defaultColumn"));
      }
    },


    actions: {
      selectColumn: function selectColumn(column) {
        this.set('column', column);
      }
    }
  });
});