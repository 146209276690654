define('frontend/pods/components/provider/assessment/report-v2/text-synopsis/scale-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    customScaleTypes: ['custom', 'sdoh'],
    _loadScaleData: function _loadScaleData(scale, values) {
      var cvs = values.filter(function (cv) {
        return cv.get('scale.id') === scale.get('id');
      });

      var showEndorsedAnswers = cvs.any(function (cv) {
        return ['moderate', 'high', 'severe', 'mild'].indexOf(cv.get('thresholdClass')) >= 0;
      });
      return {
        mnemonic: scale.get('mnemonic'),
        scale: scale,
        showEndorsedAnswers: showEndorsedAnswers,
        cvs: cvs
      };
    },

    scaleGroups: Ember.computed('report.scales.[]', 'scaleValues', function () {
      var _this = this;

      var topLevel = this.get("report.scales").reject(function (scale) {
        return _this.customScaleTypes.includes(scale.get('measureType'));
      }).sortBy('mnemonic');
      var scaleValues = this.get("scaleValues");

      return topLevel.flatMap(function (scale) {
        var subscaleGroups = scale.get('subtreeWithoutSelf').flatMap(function (subscale) {
          if (subscale.get('hideInSynopsis')) {
            return [];
          } else {
            return [_this._loadScaleData(subscale, scaleValues)];
          }
        });

        var data = Ember.merge(_this._loadScaleData(scale, scaleValues), { subscaleGroups: subscaleGroups });

        return data.subscaleGroups.length > 0 || data.cvs.length > 0 ? [data] : [];
      });
    }),
    customScalesCvs: Ember.computed("scaleValues.[]", function () {
      var _this2 = this;

      return this.get("scaleValues").filter(function (cv) {
        return _this2.customScaleTypes.includes(cv.get('scale.measureType'));
      });
    }),

    showSubscaleDescription: Ember.computed("group.scale.hasSubscales", "group.scale.displayData.synopsis.description", function () {
      return this.get("group.scale.hasSubscales") && !this.get("group.scale.displayData.synopsis.description");
    })
  });
});