define('frontend/pods/components/survey/taking-local/common/questions/year-range-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, {
    years: Ember.computed('question.properties.yearLookback', function () {
      var now = new Date().getFullYear();

      var lookback = this.getWithDefault('question.properties.yearLookback', 30);

      var endYears = [now];
      var currentYear = now;

      for (var ii = 0; ii <= lookback; ii++) {
        currentYear -= 1;
        endYears.addObject(currentYear.toString());
      }

      return endYears;
    }),

    dateDisabled: Ember.computed.and('isNa', 'showNa'),

    _setValidAnswer: function _setValidAnswer(startYear, endYear) {
      if (startYear && endYear) {
        var responseItem = this._prepareForNewValue();
        var newValue = startYear + '-' + endYear;
        responseItem.set('value', newValue);

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    },

    showNa: Ember.computed.readOnly('question.properties.showNa'),

    selectedStartYear: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        if (string && string !== 'na') {
          return string.split('-')[0];
        }
      },
      set: function set(key, value) {
        this._setValidAnswer(value, this.get('selectedEndYear'));

        return value;
      }
    }),
    selectedEndYear: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        if (string && string !== 'na') {
          return string.split('-')[1];
        }
      },
      set: function set(key, value) {
        this._setValidAnswer(this.get('selectedStartYear'), value);

        return value;
      }
    }),
    isNa: Ember.computed('responseItem.value', {
      get: function get() {
        var string = this.get("responseItem.value");

        return string && string === 'na';
      },
      set: function set(key, value) {
        var responseItem = this._prepareForNewValue();
        responseItem.set('value', 'na');

        if (this.get("onChange")) {
          this.get("onChange")();
        }

        return value;
      }
    }),

    actions: {
      onSelectEndyear: function onSelectEndyear(endYear) {
        this.set('selectedEndYear', endYear);
      },
      onSelectStartYear: function onSelectStartYear(endYear) {
        this.set('selectedStartYear', endYear);
      },
      toggleNa: function toggleNa() {
        this.toggleProperty("isNa");
      }
    }
  });
});