define('frontend/initializers/warnings-to-ignore', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IGNORED_WARNINGS = ['ds.store.push-link-for-sync-relationship'];

  exports.default = {
    name: 'warnings-to-ignore',
    initialize: function initialize() {
      Ember.Debug.registerWarnHandler(function (message, options, next) {
        if (!ignoreWarning(options)) {
          next.apply(undefined, arguments);
        }
      });
    }
  };


  function ignoreWarning(options) {
    return options && options.id && IGNORED_WARNINGS.includes(options.id);
  }
});