define('frontend/pods/components/provider/assessment/report-v2/longitudinal-main/component', ['exports', 'lodash', 'frontend/mixins/drawer-enabled'], function (exports, _lodash, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headlineSectionConfiguration = [{
    measureType: 'norse',
    component: 'provider/assessment/report-v2/symptoms-explorer'
  }, {
    mnemonic: 'ptpb', // You need to have administered scale to trigger the component.
    measureType: 'ptpb',
    component: 'provider/assessment/report-v2/peabody-main',
    includeParentScale: true // In this case, we use this because all the subscales are independently administrable.
  }, {
    baseMnemonic: 'dsm5Level1Adult',
    mode: 'dsm5Level1Adult',
    measureType: 'dsm5',
    component: 'provider/assessment/report-v2/dsm-main'
  }, {
    baseMnemonic: 'dsm5Level1Adolescent',
    mode: 'dsm5Level1Adolescent',
    measureType: 'dsm5',
    component: 'provider/assessment/report-v2/dsm-main'
  }, {
    baseMnemonic: 'dsm5Level1Child',
    mode: 'dsm5Level1Child',
    measureType: 'dsm5',
    component: 'provider/assessment/report-v2/dsm-main'
  }, {
    measureType: 'sdq',
    component: 'provider/assessment/report-v2/sdq-explorer'
  }, {
    measureType: 'emapMcq',
    includeParentScale: true,
    component: 'provider/assessment/report-v2/emap-mcq'
  }];

  /*
   * Expected params: reportSettings, patient, populations
   */
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['longitudinal-main'],
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    comparisonReport: Ember.computed.alias('reportSettings.comparisonReport'),

    reportComparison: Ember.computed.alias('reportSettings.reportComparison'),

    currentReportSet: Ember.computed("reports", "activeReport", function () {
      var reports = this.get("reports");
      var activeReport = this.get("activeReport");

      if (!reports || reports.length < 2) {
        return null;
      }

      var sortedReports = reports.sortBy("patientSession.targetDate").toArray();

      var selectedIndex = sortedReports.indexOf(activeReport);

      return sortedReports.slice(0, selectedIndex + 1);
    }),

    administeredScalesInReportSet: Ember.computed('currentReportSet', function () {
      var currentReportSet = this.get("currentReportSet");

      if (!currentReportSet) {
        return [];
      }

      return currentReportSet.flatMap(function (report) {
        return report.get("scales").toArray();
      });
    }),

    scalesInReportSet: Ember.computed('currentReportSet', function () {
      var scales = [];
      if (!this.get('currentReportSet')) {
        return scales;
      }
      this.get('currentReportSet').forEach(function (r) {
        r.get('computedValues').filter(function (cv) {
          return cv.value != null;
        }).forEach(function (cv) {
          var match = _lodash.default.find(scales, function (s) {
            return s.get('mnemonic') === cv.get('scale.mnemonic');
          });

          if (!match) {
            scales.push(cv.get('scale'));
          }
        });
      });
      return this._filterDisplayScales(scales);
    }),

    // This is currently a quick fix to allow Mclean to hide alliance scales during research.
    // this logic is duplicated in full-answer-detail
    _filterDisplayScales: function _filterDisplayScales(scales) {
      var hideAllianceScales = this.get('institute.configuration.mcleanLegacyMode');
      var role = this.get("sessionUser.activeProvider.defaultRelationship");

      if (hideAllianceScales && ['psychiatrist', 'psychologist', 'therapist', 'clinician'].indexOf(role) >= 0) {
        var hiddenScales = ['waisrt', 'waisr', 'waisrGoal', 'waisrTask', 'waisrBond', 'waisr_C', 'waisrGoal_C', 'waisrTask_C', 'waisrBond_C', 'react', 'emapSessionEvaluation', 'emapClientSatisfaction'];
        var filtered = scales.reject(function (scale) {
          return hiddenScales.indexOf(scale.get('baseMnemonic')) >= 0;
        });
        return filtered;
      } else {
        return scales;
      }
    },


    isFirstReport: Ember.computed("activeReport", "reports", function () {
      if (this.get("reports.length") === 1) {
        return true;
      }
      var sortedReports = this.getWithDefault("reports", []).sortBy("patientSession.targetDate").toArray();

      return sortedReports.indexOf(this.get("reportSettings.selectedReport")) === 0;
    }),

    supportedHeadlineSections: Ember.computed("scales.[]", function () {
      var _this = this;

      return headlineSectionConfiguration.map(function (config) {
        var scales = _this.get("scales").filter(function (s) {
          return s.get("measureType") === config.measureType && (config.includeParentScale || !s.get("independent"));
        });
        return Ember.merge(Ember.Object.create({ scales: scales }), config);
      });
    }),

    headlineSections: Ember.computed("supportedHeadlineSections", "scalesInReportSet.[]", function () {
      var _this2 = this;

      return this.get('supportedHeadlineSections').filter(function (config) {
        if (config.mnemonic) {
          return _this2.get('administeredScalesInReportSet').any(function (s) {
            return s.get('mnemonic') === config.mnemonic;
          });
        } else if (config.baseMnemonic) {
          return _this2.get('administeredScalesInReportSet').any(function (s) {
            return s.get('baseMnemonic') === config.baseMnemonic;
          });
        } else if (config.measureType) {
          return _this2.get('administeredScalesInReportSet').any(function (s) {
            return s.get('measureType') === config.measureType;
          });
        }
      });
    }),

    // We are slowly moving to a battery approach, but in the mean time, PTPB scales can be administered separately
    // Or as part of the battery.
    remainingScales: Ember.computed('headlineSections.[]', function () {
      var allScales = this.get("scalesInReportSet");

      var usePtpbAsStandard = !this.get('headlineSections').findBy('mnemonic', 'ptpb');

      var typesToFilter = usePtpbAsStandard ? ['standard', 'ptpb'] : ['standard'];

      return allScales.filter(function (scale) {
        return typesToFilter.includes(scale.get('measureType'));
      });
    }),

    showCustomMeasures: Ember.computed('scalesInReportSet', function () {
      return this.get('scalesInReportSet').any(function (s) {
        return s.get('measureType') === 'custom';
      });
    }),

    showSdohMeasures: Ember.computed('scalesInReportSet', function () {
      return this.get('scalesInReportSet').any(function (s) {
        return s.get('measureType') === 'sdoh';
      });
    }),

    includesHolisticResponses: Ember.computed('currentReportSet', function () {
      var reportSet = this.getWithDefault('currentReportSet', []);

      if (!reportSet) {
        return false;
      }

      return reportSet.any(function (s) {
        return s.get('includesHolisticResponses');
      });
    }),

    // This is enabled for everyone but two legacy customers
    showGoals: Ember.computed('features.enablePatientGoals', 'institute.shortcode', function () {
      return !(this.get("institute.configuration.mcleanLegacyMode") || this.get("institute.shortcode") === 'genoa-d2c');
    }),

    compressStandardMeasures: Ember.computed('showNorseExplorer', 'showPeabodyExplorer', function () {
      return this.get('showNorseExplorer') || this.get('showPeabodyExplorer');
    }),

    patientScaleTargets: Ember.computed.alias('patient.assessmentPatientScaleTargets'),

    targetedScales: Ember.computed.map("patient.assessmentPatientScaleTargets", function (pst) {
      return pst.get("scale");
    }),

    actions: {
      showScaleDetails: function showScaleDetails(scale) {
        this.send('drawer');
        this.set("focusedScale", scale);
      },
      unfocusScale: function unfocusScale() {
        this.set("focusedScale", null);
      }
    }
  });
});