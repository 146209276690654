define('frontend/pods/components/survey/taking-local/common/questions/grid-question/component', ['exports', 'frontend/mixins/survey-metric', 'frontend/mixins/survey/question-component-mixin', 'frontend/models/survey/response-item'], function (exports, _surveyMetric, _questionComponentMixin, _responseItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RowItem = Ember.Object.extend({
    question: null,
    questionMnemonic: null,
    uniqueQuestionIdentifier: null,

    // For now, do not recalculate this when responseItems get added because it causes all the fields to be rerendered
    // from scratch
    fields: Ember.computed("question.options.[]", 'responseSet', function () {
      var _this = this;

      var mode = this.get("mode");
      return this.getWithDefault("question.options", []).map(function (option) {
        return FieldItem.create({ mode: mode, questionComponent: _this, option: option, responseSet: _this.get("responseSet") });
      });
    }),

    responseSet: Ember.computed('response', 'question', 'module', function () {
      return this.get('response').getResponseSetForQuestion(this.get('question'), this.get('module'));
    }),

    responseItems: Ember.computed("responseSet.responseItems.[]", function () {
      return this.get("responseSet.responseItems").filterBy("uniqueQuestionIdentifier", this.get("uniqueQuestionIdentifier"));
    })
  });

  var FieldItem = Ember.Object.extend({
    activeResponseItem: Ember.computed("responseItems.@each.active", function () {
      return this.get("responseItems").findBy("active", true);
    }),

    responseItems: Ember.computed("responseSet.responseItems.[]", function () {
      return this.get("responseSet.responseItems").filterBy("optionId", this.get("option.id"));
    }),

    // This is set as a one-way so it correctly picks up any existing value, but does not require a value to write to.
    // The value is then written back ot the response item by an onchange event.
    currentValue: Ember.computed.oneWay("activeResponseItem.value"),

    uniqueId: Ember.computed("questionComponent.uniqueQuestionIdentifier", "option.id", function () {
      return this.get('questionComponent.uniqueQuestionIdentifier') + '-' + this.get("option.id");
    }),

    updateValue: function updateValue(responseItem) {
      responseItem.set("value", this.get("currentValue"));
    }
  });

  exports.default = Ember.Component.extend(_surveyMetric.default, _questionComponentMixin.default, {
    nextQuestionDelay: 0,
    //a two-dimensional array of the responseItems -- this is essentially the 'view-model'
    textResponses: [],

    _forEachField: function _forEachField(func) {
      this.get("rows").forEach(function (row) {
        row.get("fields").forEach(func);
      });
    },


    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      // Note we don't support changing subtypes on the fly.
      var mode = this.get("mode");
      var response = this.get("response");
      var module = this.get('module');

      if (!this.get("rows")) {
        var rows = this.get('question.sub_questions').map(function (question) {
          return RowItem.create({ mode: mode, response: response, question: question, module: module,
            questionMnemonic: question.shortname, uniqueQuestionIdentifier: (0, _responseItem.UniqueQuestionIdentifier)(module.question_domain, question.shortname), questionId: question.id });
        });
        this.set("rows", rows);
      }

      if (this.get("currentlyActive")) {
        this._beginShowQuestion();
      } else {
        this._createResponseSet(this.get("mode") === 'normal', false);
      }
    },


    //for grid questions, we automatically create response items for all grid items, and set the initial value to null.
    _createResponseSet: function _createResponseSet(createItemIfMissing, overrideItemIfOld) {
      var _this2 = this;

      this._forEachField(function (field) {
        _this2._createOrGetResponseItem(field, createItemIfMissing, overrideItemIfOld);
      });
    },
    _beginShowQuestion: function _beginShowQuestion() {
      var _this3 = this;

      if (this.get("trackTime")) {
        this._forEachField(function (field) {
          var responseItem = _this3._prepareForNewValue(field);
          // Start tracking the new question
          if (responseItem) {
            responseItem.startTrackingTime();
          }
        });
      }
    },
    _endShowQuestion: function _endShowQuestion() {
      var _this4 = this;

      if (this.get("trackTime")) {
        this._forEachField(function (field) {
          var responseItem = _this4._prepareForNewValue(field);
          // Start tracking the new question
          if (responseItem) {
            responseItem.stopTrackingTime();
          }
        });
      }
    },
    _prepareForNewValue: function _prepareForNewValue(field) {
      // If we're in bulk mode, override the old answer
      return this._createOrGetResponseItem(field, true, this.get("mode") === 'bulk');
    },
    _createOrGetResponseItem: function _createOrGetResponseItem(field, createItemIfMissing, overrideItemIfOld) {
      var lastItem = field.get("activeResponseItem");
      var lastItemIsOld = lastItem && !lastItem.get("isNew");
      var responseItem = null;
      if (createItemIfMissing && !lastItem || lastItemIsOld && overrideItemIfOld) {
        //the parent question is essentially just a grouping concept -- response items are attached to the subquestions
        responseItem = this.store.createRecord('survey/response-item', {
          response: this.get('response'),
          questionId: field.get("questionComponent.questionId"),
          optionId: field.get("option.id"),
          questionMnemonic: field.get('questionComponent.questionMnemonic'),
          questionDomain: field.get('questionComponent.module.question_domain'),
          value: null
        });
        if (lastItem) {
          lastItem.set("overridingItem", responseItem);
        }
        field.get("questionComponent.responseSet.responseItems").addObject(responseItem);
        return responseItem;
      } else {
        return lastItem;
      }
    },


    actions: {
      next: function next() {
        var _this5 = this;

        this._forEachField(function (field) {
          var item = field.get("activeResponseItem");

          if (item) {
            if (_this5.get("trackTime")) {
              item.stopTrackingTime();
            }

            if (_this5.get("saveOnSubmit")) {
              // There is a bug in ember data <= 2.14.1 which means that any persisted deleted records stop the save of other
              // records. Therefore for now, don't save any records we already know are deleted.
              // TODO: remove after we upgrade to ember 2.14.1
              if (item.get("isDeleted") && !item.get("hasDirtyAttributes")) {
                return;
              }

              item.saveWithDebounce();
            }
          }
        });
        this.sendAction('next');
      },
      textChanged: function textChanged(field) {
        var responseItem = this._prepareForNewValue(field);

        field.updateValue(responseItem);

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    }

  });
});