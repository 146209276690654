define('frontend/mixins/validation-helper', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // when there is a validation error copy its message into the format we currently understand
      this.get('validations.validatableAttributes').forEach(function (va) {
        _this.addObserver(va, 'validations.attrs.' + va + '.messages', function () {
          _this._copyErrors(_this, va);
        });
      });
    },
    validate: function validate() {
      var _get,
          _this2 = this;

      return (_get = this.get('validations')).validate.apply(_get, arguments).then(function (validations) {
        var modelErrors = _this2.get('errors');
        if (!Ember.isNone(modelErrors) && Ember.canInvoke(modelErrors, 'add')) {
          _this2.get('validations.validatableAttributes').forEach(function (va) {
            _this2._copyErrors(_this2, va);
          });
        }
        return validations;
      });
    },

    // gets called when a message is added (that means there is a validation-error)
    _copyErrors: function _copyErrors(model, attribute) {
      if (model.currentState) {
        if (model.currentState.stateName === 'root.loaded.saved' || model.currentState.stateName === 'root.empty' || model.currentState.stateName === 'root.deleted.saved') {
          return;
        }
      }

      var modelErrors = model.get('errors');

      if (!modelErrors) {
        modelErrors = _emberData.default.Errors.create({});
        model.set("errors", modelErrors);
      }

      var wasEmpty = modelErrors.get('isEmpty');

      if (modelErrors.get(attribute)) {
        modelErrors._remove(attribute);
      }

      var messages = this.get('validations.attrs.' + attribute + '.messages');

      // manually add them to the errorObject
      if (messages && messages.length > 0) {
        messages.forEach(function (m) {
          modelErrors._add(attribute, m);
        });
      }

      var isEmpty = modelErrors.get('isEmpty');

      // trigger the method whether a model is now valid or not
      if (wasEmpty && !isEmpty) {
        modelErrors.trigger('becameInvalid');
      } else if (!wasEmpty && isEmpty) {
        modelErrors.trigger('becameValid');
      }
    }
  });
});