define('frontend/pods/components/patient/details-login-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var authenticationMethod = this.get("institute.current.patientAuthenticationMethod");

      var showLastSsn = authenticationMethod == "full" || authenticationMethod == "mrn_ssn";
      var showDob = authenticationMethod === 'full' || authenticationMethod === 'dob_only';
      var showEmail = authenticationMethod === 'email';

      this.setProperties({ showLastSsn: showLastSsn, showDob: showDob, showEmail: showEmail });

      var validationOptions = {
        lastName: (0, _emberCpValidations.validator)('presence', true)
      };

      this.set("showLastSsn", showLastSsn);
      this.set("showDob", showDob);

      if (showLastSsn) {
        validationOptions.lastSsn = {
          validators: [(0, _emberCpValidations.validator)('length', {
            is: 4,
            allowBlank: true
          }), (0, _emberCpValidations.validator)('number', {
            allowString: true,
            allowBlank: true,
            integer: true
          })]
        };
      }

      if (showDob) {
        validationOptions.dobString = {
          validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
            before: 'now',
            precision: 'day',
            format: 'MM/DD/YYYY',
            errorFormat: 'MM/DD/YYYY'
          })]
        };
      }

      if (showEmail) {
        validationOptions.email = {
          validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })]
        };
      }

      var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

      var patientDetails = Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        invitationId: this.get("invitationId"),
        mrn: null,
        dob: null,
        lastSsn: null,
        lastName: null,
        email: null,
        dobString: Ember.computed('dob', {
          get: function get() {
            if (this.get('dob')) {
              return moment(this.get('dob')).format("MM/DD/YYYY");
            } else {
              return null;
            }
          },
          set: function set(key, value) {
            if (value) {
              this.set("dob", moment(value, "MM/DD/YYYY").toDate());
              return value;
            }
          }
        }),
        dobIsoString: Ember.computed('dob', function () {
          var dob = this.get('dob');

          if (dob) {
            return moment(dob).format('YYYY-MM-DD');
          } else {
            return null;
          }
        })
      }).create(Ember.getOwner(this).ownerInjection());

      this.set("patientDetails", patientDetails);
    },

    isInvalid: Ember.computed.alias("patientDetails.validations.isInvalid"),
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');

        var _get$getProperties = this.get("patientDetails").getProperties("lastName", "lastSsn", "dobIsoString", "email"),
            lastName = _get$getProperties.lastName,
            lastSsn = _get$getProperties.lastSsn,
            dobIsoString = _get$getProperties.dobIsoString,
            email = _get$getProperties.email;

        var trackLoginEvent = function trackLoginEvent(event) {
          return _this.get('metrics').trackEvent({
            event: 'patient.kiosk.' + event,
            patientLastName: lastName,
            patientLastSsn: lastSsn,
            patientEmail: email,
            patientDob: dobIsoString
          });
        };

        trackLoginEvent('signInAttempt');

        var onSuccess = function onSuccess() {
          trackLoginEvent('signInSucceeded');
        };

        var onFailure = function onFailure(reason) {
          if (reason && reason.error === 'Ambiguous login') {
            _this.get('messages').danger(intl.t('patient.kiosk.ambiguousLogin'));
            trackLoginEvent('ambiguousLogin');
          } else {
            _this.get('messages').danger(intl.t('patient.kiosk.failed'));
            trackLoginEvent('signInFailed');
          }
        };

        this.get('messages').clear();

        return this.get('session').authenticate('authenticator:devise-patient', {
          institute_id: this.get("institute.current.id"),
          last_name: lastName,
          last_ssn: lastSsn,
          email: email,
          dob: dobIsoString
        }).then(onSuccess, onFailure);
      }
    }
  });
});