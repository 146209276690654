define('frontend/pods/components/reporting/columns/condition-badges/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    preload: Ember.inject.service(),
    activeConditions: Ember.computed('value.[]', 'preload.conditions.[]', function () {
      var conditionIds = this.get("value");

      if (!conditionIds || conditionIds.get("length") < 1) {
        return [];
      }

      return this.getWithDefault("preload.conditions", []).filter(function (condition) {
        return conditionIds.indexOf(condition.get('id')) >= 0;
      });
    })
  });
});