define('frontend/pods/components/provider/plan/edit-entity-measurement-plan/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    actions: {
      submit: function submit(model) {
        var _this = this;

        var intl = this.get('intl');

        var onSuccess = function onSuccess() {
          _this.get("metrics").trackEvent({ event: "provider.plan.entityMeasurementPlanUpdated" });
          _this.get('messages').success(intl.t('provider.measurementPlans.planUpdated'));
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason, 'entityMeasurementPlan'));
        };

        return model.save().then(onSuccess, onFailure);
      }
    }
  });
});