define('frontend/services/help-scout-beacon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    initStatus: false,
    institute: Ember.inject.service(),

    beaconKey: Ember.computed("institute.current.helpscoutBeaconKey", function () {
      return this.get("institute.current.helpscoutBeaconKey");
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.configBeacon();
    },
    configBeacon: function configBeacon() {
      if (this.get('beaconKey')) {
        !function (e, t, n) {
          function a() {
            var e = t.getElementsByTagName("script")[0],
                n = t.createElement("script");n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e);
          }if (e.Beacon = n = function n(t, _n, a) {
            e.Beacon.readyQueue.push({ method: t, options: _n, data: a });
          }, n.readyQueue = [], "complete" === t.readyState) return a();e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
        }(window, document, window.Beacon || function () {});
        window.Beacon('config', { 'hideAvatars': true, color: '#BE8445' });
      }
    },
    setUser: function setUser(id, name, email, signature) {

      if (this.get('beaconKey')) {
        if (!this.get('initStatus')) {
          window.Beacon('init', this.get('beaconKey'));
          this.set('initStatus', true);
        }
        window.Beacon('identify', {
          name: name,
          email: email,
          signature: signature,
          company: this.get("institute.current.name"),
          'institute-id': this.get("institute.current.id"),
          'user-id': id
        });
      }
    },
    trackPageView: function trackPageView() {
      if (this.get('initStatus')) {
        window.Beacon('event', {
          type: 'page-viewed',
          url: document.location.href,
          title: document.title
        });
      }
    },
    shutDown: function shutDown() {
      if (this.get('initStatus')) {
        window.Beacon('destroy');
        this.set('initStatus', false);
      }
    }
  });
});