define('frontend/pods/components/common/focus-trap/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // This whole mess is a riff on https://davidtang.io/trapping-focus-in-a-modal-in-ember/
  // Basically, nest something that needs to be trapped inside {{#common/focus-trap}} tags
  // When the element renders, it will trap focus inside itself: this is meant to only be used with modals.
  // It handles backwards and forwards via tab and autofocuses the first element.

  function findFocusableElements(element) {
    return element.querySelectorAll('\n      a[href],\n      input:not([disabled]),\n      select:not([disabled]),\n      textarea:not([disabled]),\n      button:not([disabled]),\n      [tabindex="0"],\n      .ember-power-select-trigger\n    ');
  }

  exports.default = Ember.Component.extend({
    classNames: ['focus-trap'],

    boundEventListener: false,

    didTrap: false,

    didRender: function didRender() {
      var _findFocusableElement = findFocusableElements(this.element),
          _findFocusableElement2 = _slicedToArray(_findFocusableElement, 1),
          focusableElement = _findFocusableElement2[0];

      if (focusableElement && !this.get('didTrap')) {
        focusableElement.focus();
        this.set("didTrap", true);
      }
      if (!this.boundEventListener) {
        this.element.addEventListener('keydown', this.handleKeyDown);
        this.boundEventListener = true;
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.boundEventListener) {
        this.element.removeEventListener('keydown', this.handleKeyDown);
        this.boundEventListener = false;
      }
    },

    handleKeyDown: function handleKeyDown(event) {
      var TAB_KEY = 9;
      var focusableElements = findFocusableElements(this);

      var _focusableElements = _slicedToArray(focusableElements, 1),
          firstFocusableElement = _focusableElements[0];

      var lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (event.keyCode !== TAB_KEY) {
        return;
      }

      function handleBackwardTab() {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      }

      function handleForwardTab() {
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }

      if (event.shiftKey) {
        handleBackwardTab();
      } else {
        handleForwardTab();
      }
    }
  });
});