define('frontend/helpers/translate', ['exports', 'ember-cli-d3/helpers/translate'], function (exports, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _translate.default;
    }
  });
  Object.defineProperty(exports, 'translate', {
    enumerable: true,
    get: function () {
      return _translate.translate;
    }
  });
});