define('frontend/services/reports/conditions', ['exports', 'frontend/models/reporting/report'], function (exports, _report) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _report.default.create({
    shortcode: 'conditions',
    role: 'executive',
    icon: 'stethoscope.svg',
    hideFooter: false,
    featureGate: 'enableConditions',
    showDateRangeControl: true,
    showProviderFilter: true,
    showOrganizationFilter: true,
    showBreakdownControl: false,
    columnProperties: {
      addressedPercent: {
        displayType: 'percent-bar'
      }
    },
    defaultSort: 'institute_condition',
    defaultPreset: 'lastMonth',
    defaultSortDirection: 'asc'
  });
});