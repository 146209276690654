define('frontend/pods/provider/profile/edit/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/pods/base-route'], function (exports, _authenticatedRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_authenticatedRouteMixin.default, {
    sessionUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.get("sessionUser.currentUser")
      });
    }
  });
});