define('frontend/pods/provider/patients/create/route', ['exports', 'frontend/mixins/with-custom-actions', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _withCustomActions, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, _withCustomActions.default, {
    metrics: Ember.inject.service(),
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get("sessionUser.activeProvider");
    },
    model: function model() {
      var patient = this.store.createRecord('patient', { source: 'manual' });
      var activeProvider = this.get("sessionUser.activeProvider");

      if (activeProvider && activeProvider.get("practicing")) {
        patient.set("provider", activeProvider);
      }

      return {
        patient: patient,
        providers: this.get("preload.clinicians")
      };
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.create" });
      }
    }
  });
});