define('frontend/helpers/score-class', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (params[0].answered === false) {
      return 'unanswered';
    }
    return new Ember.Handlebars.SafeString(_analytics.default.getAnswerScoreClass(params[0][params[1]]));
  });
});