define('frontend/pods/components/common/input-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      if (!this.formatted) {
        var property = this.get("property");
        this.formatted = document.getElementById(property).value;
        new Pikaday({
          field: $('#' + property)[0],
          trigger: $('#' + property + 'Picker')[0],
          format: 'MM/DD/YYYY',
          yearRange: [1900, 2021]
        });
      }
    }
  });
});