define('frontend/pods/components/reporting/compliance-report/component', ['exports', 'frontend/mixins/drawer-enabled', 'moment'], function (exports, _drawerEnabled, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['compliance-report'],
    metricEventName: 'reporting.complianceDetails',

    measuresCompletedData: [{
      "month": "Jan 2019",
      "date": (0, _moment.default)('2019-01-01').toDate(),
      "sessions": 95,
      "measured": 40,
      "value": 42
    }, {
      "month": "Feb 2019",
      "date": (0, _moment.default)('2019-02-01').toDate(),
      "sessions": 101,
      "measured": 43,
      "value": 43
    }, {
      "month": "Mar 2019",
      "date": (0, _moment.default)('2019-03-01').toDate(),
      "sessions": 98,
      "measured": 48,
      "value": 48
    }, {
      "month": "Apr 2019",
      "date": (0, _moment.default)('2019-04-01').toDate(),
      "sessions": 102,
      "measured": 58,
      "value": 57
    }, {
      "month": "May 2019",
      "date": (0, _moment.default)('2019-05-01').toDate(),
      "sessions": 113,
      "measured": 66,
      "value": 57
    }, {
      "month": "Jun 2019",
      "date": (0, _moment.default)('2019-06-01').toDate(),
      "sessions": 108,
      "measured": 57,
      "value": 53
    }, {
      "month": "Jul 2019",
      "date": (0, _moment.default)('2019-07-01').toDate(),
      "sessions": 117,
      "measured": 71,
      "value": 61
    }, {
      "month": "Aug 2019",
      "date": (0, _moment.default)('2019-08-01').toDate(),
      "sessions": 103,
      "measured": 57,
      "value": 55
    }, {
      "month": "Sep 2019",
      "date": (0, _moment.default)('2019-09-01').toDate(),
      "sessions": 98,
      "measured": 61,
      "value": 62
    }, {
      "month": "Oct 2019",
      "date": (0, _moment.default)('2019-10-01').toDate(),
      "sessions": 118,
      "measured": 70,
      "value": 59
    }, {
      "month": "Nov 2019",
      "date": (0, _moment.default)('2019-11-01').toDate(),
      "sessions": 105,
      "measured": 65,
      "value": 62
    }, {
      "month": "Dec 2019",
      "date": (0, _moment.default)('2019-12-01').toDate(),
      "sessions": 120,
      "measured": 76,
      "value": 63
    }],

    measuresCompletedGraphConfig: {
      type: 'line'
    },
    yDomain: [0, 100],
    measuresCompletedGraphOptions: {
      legend: false,
      scales: {
        xAxes: [{
          type: 'time'
        }],
        yAxes: [{
          ticks: {
            min: 40,
            max: 70
          }
        }]
      }
    },
    measuresCompletedGraphData: Ember.computed("measuresCompletedData", function () {
      return {
        datasets: [{
          label: 'Measures Completed',
          borderColor: 'rgb(25,75,120)',
          fill: null,
          data: this.get('measuresCompletedData').map(function (row) {
            return {
              x: row.date,
              y: row.value
            };
          })
        }]
      };
    }),
    patientsMeasuredGraphData: {
      datasets: [{
        label: 'Patients Measured',
        borderColor: 'rgb(25,75,120)',
        fill: null,
        data: [{
          "month": "Jan 2019",
          "x": (0, _moment.default)('2019-01-01').toDate(),
          "y": 20
        }, {
          "month": "Feb 2019",
          "x": (0, _moment.default)('2019-02-01').toDate(),
          "y": 42
        }, {
          "month": "Mar 2019",
          "x": (0, _moment.default)('2019-03-01').toDate(),
          "y": 45
        }, {
          "month": "Apr 2019",
          "x": (0, _moment.default)('2019-04-01').toDate(),
          "y": 53
        }, {
          "month": "May 2019",
          "x": (0, _moment.default)('2019-05-01').toDate(),
          "y": 54
        }, {
          "month": "Jun 2019",
          "x": (0, _moment.default)('2019-06-01').toDate(),
          "y": 59
        }, {
          "month": "Jul 2019",
          "x": (0, _moment.default)('2019-07-01').toDate(),
          "y": 61
        }, {
          "month": "Aug 2019",
          "x": (0, _moment.default)('2019-08-01').toDate(),
          "y": 69
        }, {
          "month": "Sep 2019",
          "x": (0, _moment.default)('2019-09-01').toDate(),
          "y": 70
        }, {
          "month": "Oct 2019",
          "x": (0, _moment.default)('2019-10-01').toDate(),
          "y": 72
        }, {
          "month": "Nov 2019",
          "x": (0, _moment.default)('2019-11-01').toDate(),
          "y": 78
        }, {
          "month": "Dec 2019",
          "x": (0, _moment.default)('2019-12-01').toDate(),
          "y": 85
        }]
      }]
    },
    patientsMeasuredGraphOptions: {
      legend: false,
      scales: {
        xAxes: [{
          type: 'time'
        }]
      }
    },
    reportsViewedData: [{
      "month": "Jan 2019",
      "time": (0, _moment.default)('2019-01-01').toDate(),
      "measured": 50,
      "viewed": 35,
      "viewedPercent": 70,
      "viewedInSession": 18,
      "viewedInSessionPercent": 50
    }, {
      "month": "Feb 2019",
      "time": (0, _moment.default)('2019-02-01').toDate(),
      "measured": 53,
      "viewed": 37,
      "viewedPercent": 69,
      "viewedInSession": 19,
      "viewedInSessionPercent": 53
    }, {
      "month": "Mar 2019",
      "time": (0, _moment.default)('2019-03-01').toDate(),
      "measured": 54,
      "viewed": 39,
      "viewedPercent": 73,
      "viewedInSession": 23,
      "viewedInSessionPercent": 60
    }, {
      "month": "Apr 2019",
      "time": (0, _moment.default)('2019-04-01').toDate(),
      "measured": 58,
      "viewed": 49,
      "viewedPercent": 85,
      "viewedInSession": 27,
      "viewedInSessionPercent": 55
    }, {
      "month": "May 2019",
      "time": (0, _moment.default)('2019-05-01').toDate(),
      "measured": 66,
      "viewed": 58,
      "viewedPercent": 89,
      "viewedInSession": 37,
      "viewedInSessionPercent": 63
    }, {
      "month": "Jun 2019",
      "time": (0, _moment.default)('2019-06-01').toDate(),
      "measured": 57,
      "viewed": 50,
      "viewedPercent": 88,
      "viewedInSession": 35,
      "viewedInSessionPercent": 70
    }, {
      "month": "Jul 2019",
      "time": (0, _moment.default)('2019-07-01').toDate(),
      "measured": 71,
      "viewed": 66,
      "viewedPercent": 30,
      "viewedInSession": 47,
      "viewedInSessionPercent": 71
    }, {
      "month": "Aug 2019",
      "time": (0, _moment.default)('2019-08-01').toDate(),
      "measured": 57,
      "viewed": 51,
      "viewedPercent": 90,
      "viewedInSession": 39,
      "viewedInSessionPercent": 77
    }, {
      "month": "Sep 2019",
      "time": (0, _moment.default)('2019-09-01').toDate(),
      "measured": 61,
      "viewed": 54,
      "viewedPercent": 89,
      "viewedInSession": 41,
      "viewedInSessionPercent": 76
    }, {
      "month": "Oct 2019",
      "time": (0, _moment.default)('2019-10-01').toDate(),
      "measured": 70,
      "viewed": 64,
      "viewedPercent": 92,
      "viewedInSession": 51,
      "viewedInSessionPercent": 80
    }, {
      "month": "Nov 2019",
      "time": (0, _moment.default)('2019-11-01').toDate(),
      "measured": 65,
      "viewed": 62,
      "viewedPercent": 95,
      "viewedInSession": 49,
      "viewedInSessionPercent": 79
    }, {
      "month": "Dec 2019",
      "time": (0, _moment.default)('2019-12-01').toDate(),
      "measured": 76,
      "viewed": 70,
      "viewedPercent": 92,
      "viewedInSession": 56,
      "viewedInSessionPercent": 81
    }],
    viewedInSessionGraphData: Ember.computed('reportsViewedData', function () {
      return {
        datasets: [{
          label: 'Reports Viewed',
          borderColor: 'rgb(25,75,120)',
          fill: null,
          data: this.get('reportsViewedData').map(function (row) {
            return {
              x: row.time,
              y: row.viewedInSessionPercent
            };
          })
        }]
      };
    }),
    viewedInSessionGraphOptions: {
      legend: false,
      scales: {
        xAxes: [{
          type: 'time'
        }],
        yAxes: [{
          ticks: {
            min: 0,
            max: 100
          }
        }]
      }
    },
    actions: {
      ruleDetails: function ruleDetails(section) {
        this.set("ruleSection", section);
        this.send('drawer');
      }
    }
  });
});