define('frontend/models/assessment/automatic-report-question-calculator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function calculateLeftValue(document) {
    if (Ember.isEmpty(document.options)) {
      return null;
    }

    return Ember.get(document, 'options.firstObject.value');
  }

  function calculateRightValue(document) {
    if (Ember.isEmpty(document.options)) {
      return null;
    }

    return Ember.get(document, 'options.lastObject.value');
  }

  //Per template settings
  var templateMap = {
    'likert-v1': {
      leftValue: function leftValue(document) {
        return document._templateOptions.parameters.minValue;
      },
      rightValue: function rightValue(document) {
        return document._templateOptions.parameters.maxValue;
      },
      leftLabel: function leftLabel(document) {
        return document._templateOptions.parameters.leftText.en;
      },
      rightLabel: function rightLabel(document) {
        return document._templateOptions.parameters.rightText.en;
      },
      valueModes: ['likert', 'text']
    },
    'likertWithOptions-v1': {
      leftValue: function leftValue(document) {
        return parseInt(document.options[0].value);
      },
      rightValue: function rightValue(document) {
        return parseInt(document.options[document.options.length - 1].value);
      },
      leftLabel: function leftLabel(document) {
        return document._templateOptions.parameters.leftText.en;
      },
      rightLabel: function rightLabel(document) {
        return document._templateOptions.parameters.rightText.en;
      },
      valueModes: ['likert', 'text']
    }
  };

  // Per question type settings as fallback.
  var questionTypeMap = {
    RADIO: {
      leftValue: calculateLeftValue,
      rightValue: calculateRightValue
    },
    SLIDER: null,
    FULLSLIDER: {
      leftValue: function leftValue(document) {
        return document.properties.min_value;
      },
      rightValue: function rightValue(document) {
        return document.properties.max_value;
      },
      leftLabel: function leftLabel(document) {
        return document.properties.advancedLikert.findBy('alignment', 'left').text.en;
      },
      rightLabel: function rightLabel(document) {
        return document.properties.advancedLikert.findBy('alignment', 'right').text.en;
      },
      valueModes: ['fullslider', 'text']
    },
    CHECKBOX: null,
    DYNAMICVARIABLECHECKBOX: null,
    INSTRUCTIONS: null,
    ESSAY: null,
    TEXTBOX: null,
    MEDIA: null,
    TABLE: null,
    SELECT: null
  };

  function calculator(document) {
    var worker = templateMap[document._templateOptions.templateId] || questionTypeMap[document.type];

    if (!worker) {
      return;
    }

    var details = document.display_data.details;

    ['leftValue', 'rightValue', 'leftLabel', 'rightLabel'].forEach(function (method) {

      if (worker[method]) {
        details[method] = worker[method](document);
      }
    });

    if (worker.valueModes) {
      details.valueModes = worker.valueModes;
    } else {
      delete details.valueModes;
    }

    return document;
  }

  exports.default = calculator;
});