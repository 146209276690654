define('frontend/models/survey/take-survey-request', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    assessmentInvitationId: _emberData.default.attr("string"),
    referralUrl: _emberData.default.attr("string"),
    requestedUrlCodes: _emberData.default.attr('object'),
    currentLanguage: _emberData.default.attr("string"),
    invite: _emberData.default.belongsTo("survey/invite"),
    response: _emberData.default.belongsTo("survey/response")
  });
});