define('frontend/locales/es/translations', ['exports', 'frontend/locales/es/patient', 'frontend/locales/es/survey', 'frontend/locales/es/common', 'frontend/locales/es/model', 'frontend/locales/es/related-person'], function (exports, _patient, _survey, _common, _model, _relatedPerson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    common: _common.default,
    model: _model.default,
    patient: _patient.default,
    survey: _survey.default,
    relatedPerson: _relatedPerson.default
  };
});