define('frontend/models/care/session-active-measurement-plan', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    dates: _emberData.default.attr('object', {
      defaultValue: function defaultValue() {
        return [new Date()];
      }
    }),
    patientSession: _emberData.default.belongsTo("patientSession"),
    entityMeasurementPlan: _emberData.default.belongsTo("care/entityMeasurementPlan"),
    planType: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number'),
    specificity: _emberData.default.attr('number'),
    typePriority: _emberData.default.attr('number'),
    sessionNumber: _emberData.default.attr('number')
  });
});