define('frontend/pods/components/provider/related-people/edit-related-person/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    classNames: ['edit-related-person'],
    actions: {
      saveRelatedPerson: function saveRelatedPerson() {
        var _this = this;

        var onSuccess = function onSuccess(relatedPerson) {
          _this.get("messages").success("Related Person saved");
          _this.get("metrics").trackEvent({ event: "provider.relatedPerson.updated" });
          _this.get("router").transitionTo('provider.patients.show', relatedPerson.get("patient.id"));
        };

        var onFailure = function onFailure(reason) {
          var msgString = reason.errors.reduce(function (str, e, index) {
            return str + (index ? '; ' : '') + e.detail;
          }, '');
          _this.get("metrics").trackEvent({ event: "provider.relatedPerson.updateFailed" });

          _this.get('messages').danger("Error: " + msgString);
        };

        return this.get("relatedPerson").save().then(onSuccess, onFailure);
      }
    }
  });
});