define('frontend/models/care/treatment-track', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    entityType: 'treatment-track'
  });
});