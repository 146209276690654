define('frontend/pods/components/reporting/columns/outcome-delta-session/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Necessary as we need to determine between 0 and null which the ember if helper does not do.
    isNull: Ember.computed('value', function () {
      var value = this.get("value");

      return !value && value !== 0;
    })
  });
});