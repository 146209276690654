define('frontend/pods/components/scale-creation/entity-select/component', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _updateOptions: function _updateOptions() {
      this.notifyPropertyChange('entityOptions');
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set("listenerUuid", (0, _uuidHelpers.default)());
      this.get("registry").listenForChange(this.get("listenerUuid"), function () {
        _this._updateOptions();
      }, this.get("registryType"));
    },

    classNames: 'horizontal-select',
    // Needs to be triggered for external changes in models.
    selected: Ember.computed('entityOptions.[]', function () {
      var selectedKey = Ember.get(this.get('model'), this.get('property'));
      var options = this.get("entityOptions");

      if (!selectedKey) {
        return null;
      }

      return options.find(function (option) {
        return option.key === selectedKey;
      });
    }),
    entityOptions: Ember.computed(function () {
      var registry = this.get("registry");

      var options = registry.optionsForType(this.get('registryType'));

      if (options) {
        return Object.entries(options).map(function (entry) {
          return {
            key: entry[0],
            value: entry[1]
          };
        });
      } else {
        return [];
      }
    }),

    actions: {
      updateSelected: function updateSelected(option) {
        Ember.set(this.get('model'), this.get('property'), Ember.get(option, 'key'));
        this.notifyPropertyChange("selected");
        if (this.get('onUpdate')) {
          this.get('onUpdate')();
        }
      }
    },

    willDestroyElement: function willDestroyElement() {
      this.get("registry").stopListeningForChange(this.get("listenerUuid"), this.get("registryType"));
    }
  });
});