define('frontend/helpers/assessment/scale-administered-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var intl = this.get('intl');
      var scale = params[0];
      var defaultParticipants = scale.get("defaultParticipants");

      if (!defaultParticipants) {
        return "";
      }

      var participants = scale.get("defaultParticipants").map(function (p) {
        return intl.t('model.participant.participantType.' + p);
      }).join(", ");

      return intl.t('provider.scales.administeredTo', { participants: participants });
    }
  });
});