define('frontend/pods/provider/patients/show/care-episodes/create/route', ['exports', 'frontend/pods/base-route', 'frontend/models/care-episode'], function (exports, _baseRoute, _careEpisode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),

    // The current provider isn't guaranteed to be loaded but we need it to work out the organization structure.
    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        user: this.get("sessionUser.currentUser"),
        providers: this.get("preload.providers")
      });
    },
    model: function model() {
      var patient = this.modelFor("provider.patients.show.care-episodes");

      var organizations = this.get("sessionUser.activeOrganizations");
      var allProviders = this.get("preload.clinicians");
      var activeCareEpisodes = patient.get("activeCareEpisodes");
      var activeProvider = this.get("sessionUser.activeProvider");

      var careEpisode = (0, _careEpisode.createNewCareEpisode)(this.store, patient, organizations, activeProvider);

      return Ember.RSVP.hash({
        patient: patient,
        careEpisode: careEpisode,
        organizations: organizations,
        allProviders: allProviders,
        activeCareEpisodes: activeCareEpisodes,
        activeProvider: activeProvider
      });
    }
  });
});