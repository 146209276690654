define('frontend/services/provider/filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    features: Ember.inject.service(),
    // Call notifyPropertyChange on this property to ensure that any other filters get the result of your change.
    filterDidChange: false,

    namespaces: ['', 'clients_'],

    // Front desk staff see all providers by default.
    patientProviderMode: Ember.computed("sessionUser.activeProvider.defaultRelationship", function () {
      if (this.get("sessionUser.activeProvider.defaultRelationship") === 'administrator') {
        return "all";
      }

      return "mine";
    }),
    patientProviderId: void 0,
    patientOrganizationMode: Ember.computed("sessionUser.activeProvider.defaultRelationship", function () {
      if (this.get("sessionUser.activeProvider.defaultRelationship") === 'administrator') {
        return "mine";
      }

      return "all";
    }),
    patientOrganizationId: void 0,
    patientFlagIds: [],
    showTestData: false,

    // Namespaced to prevent confusion when changing filters
    clients_patientProviderMode: "all",
    clients_patientProviderId: void 0,
    clients_patientOrganizationMode: "all",
    clients_patientOrganizationId: void 0,
    clients_patientFlagIds: [],
    clients_showTestData: false,

    withPatientFilters: function withPatientFilters(otherFilters, namespace) {
      var _this = this;

      var get = function get(key) {
        if (namespace) {
          return _this.get(namespace + '_' + key);
        } else {
          return _this.get(key);
        }
      };

      var patientFilter = {
        'only_current_provider': get('patientProviderMode') === 'mine',
        'only_current_provider_organizations': get('patientOrganizationMode') === 'mine',
        'show_test': get('showTestData') === 'true' || get('showTestData') === true
      };

      if (get('patientProviderId')) {
        patientFilter['selected_provider'] = get('patientProviderId');
      }

      if (get('patientOrganizationId')) {
        patientFilter['selected_organization'] = get('patientOrganizationId');
      }

      if (get('patientFlagIds')) {
        patientFilter['active_flags'] = get('patientFlagIds');
      }

      return Ember.merge(patientFilter, otherFilters);
    },


    // This does not work with namespaced values!
    urlParams: function urlParams() {
      return {
        patientProviderMode: this.get("patientProviderMode"),
        patientProviderId: this.get("patientProviderId"),
        patientOrganizationMode: this.get("patientOrganizationMode"),
        patientOrganizationId: this.get("patientOrganizationId"),
        showTestData: String(this.get("showTestData"))
      };
    },


    changeObserver: Ember.observer('patientProviderMode', 'patientProviderId', 'patientOrganizationMode', 'patientOrganizationId', 'patientFlagIds', 'showTestData', 'clients_patientProviderMode', 'clients_patientProviderId', 'clients_patientOrganizationMode', 'clients_patientOrganizationId', 'clients_patientFlagIds', 'clients_showTestData', function () {
      this.notifyPropertyChange('filterDidChange');
    })
  });
});