define('frontend/models/care/measure-schedule-calculator-request', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    dates: (0, _emberCpValidations.validator)('length', {
      min: 1
    }),
    entityTreeNode: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    dates: _emberData.default.attr('object', {
      defaultValue: function defaultValue() {
        return [new Date()];
      }
    }),
    entityTreeNode: _emberData.default.belongsTo("entityTreeNode"),
    age: _emberData.default.attr('number'),
    gender: _emberData.default.attr('string'),
    results: _emberData.default.attr()
  });
});