define('frontend/pods/components/provider/assessment/common/symptom-rating/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    showScore: Ember.computed('value.domain', function () {
      return this.get("value.domain") !== 'interpreted';
    }),
    classNames: ['symptom-rating'],
    classNameBindings: ['notCurrent:old-value'],

    notCurrent: false,

    thresholdClass: Ember.computed('value.thresholdClass', function () {
      // Don't use getwithDefault as the property is defined always, and we need to return 'unknown' on null
      return this.get('value.thresholdClass') || 'unknown';
    }),

    displayValue: Ember.computed('value', function () {
      return this.get('value.properties.unadjusted_value') ? this.get('value.properties.unadjusted_value') : this.get('value.truncatedValue');
    }),
    valueOnly: Ember.computed('value.domain', 'value.scale.thresholdData', function () {
      return !this.get('value.scale.thresholdData.length') && this.get('value.domain') !== 'interpreted';
    }),
    // we don't want to display the raw values for scales that don't have a natural interpretation (therapy prefs, med alerts, etc..
    // this should eventually get moved to a db property of the scale 'humanComprehensibleScore'
    hideThreshold: Ember.computed('value.thresholdClass', 'value.scale.measureType', function () {
      return !this.get("value.thresholdClass") && ['ptpb', 'norse'].includes(this.get("value.scale.measureType"));
    })
  });
});