define('frontend/pods/components/provider-admin/users/edit-user-orgs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    plugins: 'checkbox,search',
    themes: {
      name: 'proton',
      icons: false,
      url: '/jstree-themes/proton-mirah/style.min.css'
    },
    checkbox: { "three_state": false },

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // Note that we reset jsTreeData here rather than in a computed property, because otherwise any selection/deselection of nodes
      // will cause the tree to refresh instantly, causing a blinking effect.
      var selected = this.getWithDefault("provider.organizations", []);
      var all = this.getWithDefault("organizations", []);

      var defaultOpen = all.get("length") < 10;

      var jsTreeData = all.map(function (org) {
        return Ember.Object.create({
          id: org.get("id"),
          organization: org,
          text: org.get("name"),
          state: {
            selected: selected.includes(org),
            open: defaultOpen
          },
          parent: org.getWithDefault("parent.id", "#") // # = root node
        });
      });

      this.set("jsTreeData", jsTreeData);
    },


    actions: {
      handleJstreeEventDidChange: function handleJstreeEventDidChange(event) {
        var provider = this.get("provider");

        switch (event.action) {
          case 'select_node':
            provider.get("organizations").addObject(this.get("organizations").findBy('id', event.node.original.id));
            break;
          case 'deselect_node':
            provider.get("organizations").removeObject(this.get("organizations").findBy('id', event.node.original.id));
            break;
        }
      }
    }
  });
});