define('frontend/pods/components/reporting/headline-adoption/component', ['exports', 'google-charts'], function (exports, _googleCharts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function addRows(output, start, end, value) {
    if (value > 0) {
      output.addRows([[start, end, value]]);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['headline-adoption'],
    chartsLoaded: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _googleCharts.GoogleCharts.load('current', { 'packages': ['sankey'], callback: function callback() {
          _this.set('chartsLoaded', true);
        } });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.drawChart();
    },

    data: Ember.computed('reportEntityTreeNode', 'chartsLoaded', function () {
      if (!this.get('chartsLoaded')) {
        return { loaded: false };
      }

      var row = this.get('reportEntityTreeNode.data.summaryData');
      var output = new _googleCharts.GoogleCharts.api.visualization.DataTable();
      output.addColumn('string', 'From');
      output.addColumn('string', 'To');
      output.addColumn('number', 'Care Episode Adoptions');
      ['both', 'email', 'phone', 'none'].forEach(function (method) {
        addRows(output, method, 'started', row[method + 'StartedMeasures']);
        addRows(output, method, 'not started', row[method + 'TotalMeasures'] - row[method + 'StartedMeasures']);
      });
      // We're going to let the tail after started just converge because it's less interesting.
      var totalStartedMeasures = row['bothStartedMeasures'] + row['emailStartedMeasures'] + row['phoneStartedMeasures'] + row['noneStartedMeasures'];
      var totalCompletedMeasures = row['bothCompletedMeasures'] + row['emailCompletedMeasures'] + row['phoneCompletedMeasures'] + row['noneCompletedMeasures'];
      addRows(output, 'started', 'completed', totalCompletedMeasures);
      addRows(output, 'started', 'not completed', totalStartedMeasures - totalCompletedMeasures);
      return {
        loaded: true,
        rows: output
      };
    }),
    drawChart: function drawChart() {
      var data = this.get('data');
      if (!data.loaded) {
        // We have no data for whatever reason, this is a no-op.
        return;
      }

      // Sets chart options.
      var colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f', '#cab2d6', '#ffff99', '#1f78b4', '#33a02c'];

      var options = {
        sankey: {
          node: {
            colorMode: 'unique',
            label: {
              fontName: 'Arial',
              fontSize: 16
            },
            nodePadding: 30
          },
          link: {
            colorMode: 'gradient',
            colors: colors
          }
        }
      };

      // Instantiates and draws our chart, passing in some options.
      var chart = new _googleCharts.GoogleCharts.api.visualization.Sankey(document.getElementById('headline-adoption'));
      chart.draw(data.rows, options);
    }
  });
});