define('frontend/mixins/patient-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    exceptions: ['patient.login', 'patient.user.password-reset', 'patient.user.password-reset', 'patient.user.confirm', 'patient.user.confirm-error'],
    beforeModel: function beforeModel(transition) {
      $('.ember-load-indicator').remove(); // double-confirm that loading indicator doesn't persist
      sessionStorage.setItem("authenticationType", "patient");

      if (!this.get('session.isAuthenticated')) {
        if (this.get("exceptions").includes(transition.targetName)) {
          return this._super.apply(this, arguments);
        }

        transition.abort();
        this.transitionTo('patient.login');
      } else {
        if (this.get("session").get("data.authenticated.user_type") !== "patient") {
          this.set("session.invalidationUrl", this.get("router").generate('patient.welcome'));
          this.get("metrics").trackEvent({ event: "user.logout", reason: "wrongRole" });
          this.get("sessionUser").logout();
        } else {
          return this._super.apply(this, arguments);
        }
      }
    }
  });
});