define('frontend/pods/components/common/drawer-container/component', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['drawer'],
    classNameBindings: ['isWide'],
    overlayClass: '.site-overlay.overlay-level-1',
    isWide: false,
    eventName: (0, _uuidHelpers.default)(),

    actions: {
      closeDrawer: function closeDrawer(viewArg) {
        var view = viewArg || this;
        view.set('isDrawerShown', false);
        $(view.element).removeClass('active');
        if (view.get('isDrawerShown')) {
          $(view.get('overlayClass')).addClass('fade');
        } else {
          $(view.get('overlayClass')).removeClass('fade');
        }
        if (view.get('onClose')) {
          view.get('onClose')();
        }
      }
    },
    didRender: function didRender() {
      var view = this;
      if (this.get("isDrawerShown")) {
        $(this.get('overlayClass')).off('click.' + this.get("eventName"));
        $(this.get('overlayClass')).on('click.' + this.get("eventName"), function () {
          view.actions.closeDrawer(view);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      $(this.get('overlayClass')).off('click.' + this.get("eventName"));
    }
  });
});