define('frontend/models/care-episode-condition', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    careEpisode: _emberData.default.belongsTo('careEpisode'),
    patientCondition: _emberData.default.belongsTo("patientCondition")
  });
});