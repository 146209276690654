define('frontend/pods/components/survey/response/edit-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    //WARNING -- changes to this function should be mirrored in the taking-local component function of the same name
    inviteProperties: Ember.computed('response.invite.paramData.inviteProperties', 'module.mnemonic', function () {
      var allProperties = this.get('response.invite.paramData');
      if (!allProperties) {
        allProperties = {};
      }
      Ember.set(allProperties, 'inviteProperties', this.get('response.invite.paramData.inviteProperties'));
      if (allProperties.inviteProperties) {
        allProperties.module = allProperties.inviteProperties[this.get('module.mnemonic')];
      }

      return allProperties;
    }),

    dynamicProperties: Ember.computed('inviteProperties', 'response.currentPage.properties.dependent_variables.[]', function () {
      var response = this.get('response');
      var domain = this.get("module.question_domain");
      var dynamicProperties = this.get("page.properties.dependent_variables");

      if (!dynamicProperties || dynamicProperties.length === 0) {
        return Ember.Object.create({
          calculated: this.get('inviteProperties')
        });
      } else {
        var keyMap = dynamicProperties.reduce(function (obj, el) {
          obj[el] = 'responseItemMap.' + domain + '#' + el + '.simpleValue';
          return obj;
        }, {});

        return Ember.Object.extend({
          calculated: Ember.computed.apply(Ember, _toConsumableArray(Object.values(keyMap)).concat([function () {
            var _this = this;

            var valueMap = dynamicProperties.reduce(function (obj, el) {
              obj[el] = _this.get('responseItemMap.' + domain + '#' + el + '.simpleValue');
              return obj;
            }, {});

            var merged = Ember.merge(valueMap, this.get('inviteProperties'));
            return merged;
          }]))
        }).create({ inviteProperties: this.get('inviteProperties'), responseItemMap: response.get('responseItemMap') });
      }
    })
  });
});