define('frontend/pods/provider/patients/show/longitudinal/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    queryParams: {
      activeReportId: {},
      activeSessionId: {},
      careEpisodeId: {}
    },
    model: function model() {
      return this.modelFor("provider.patients.show");
    },
    afterModel: function afterModel(model, transition) {
      if (this.get('features.enableMeasurementDimensions')) {
        // Currently we always transition to the new report. In future we may dispatch to old or new depending on the
        // circumstances, e.g. on a per-care-episode basis.
        // Ensure we do not persist the previously viewed active report id by merging in null.
        this.replaceWith('provider.patients.show.report-v3', { queryParams: Ember.merge({ careEpisodeId: null, activeReportId: null }, transition.queryParams) });
      } else {
        // Ensure we do not persist the previously viewed active report id by merging in null.
        this.replaceWith('provider.patients.show.report-v2', { queryParams: Ember.merge({ activeReportId: null }, transition.queryParams) });
      }
    }
  });
});