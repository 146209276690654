define('frontend/services/institute', ['exports', 'ember-data', 'frontend/locales/customers/map'], function (exports, _emberData, _map) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    features: Ember.inject.service(),

    configuration: Ember.computed.alias("current.configuration"),
    ssoSettings: Ember.computed.alias("current.ssoSettings"),
    helpscoutBeaconKey: Ember.computed.alias("current.helpscoutBeaconKey"),

    scaleConsumer: Ember.computed.equal("current.scaleCreatorMode", "consumer"),

    allowScaleEdit: Ember.computed.not("scaleConsumer"),

    current: Ember.computed("shortcode", function () {
      var _this = this;

      var queryPromise = this.get("store").query("currentInstitute", { filter: { shortcode: this.get("shortcode") } }).then(function (resultList) {
        var current = resultList.get("firstObject");

        if (current) {
          _this.set('metrics.context.instituteId', current.get('id'));
          _this._brandForInstitute(current);
          _this._loadFeaturesForInstitute(current);
          _this._loadFeaturesForInstitute(current);
          return current;
        } else {
          _this.get("router").transitionTo("invalid");
        }
      });

      return _emberData.default.PromiseObject.create({ promise: new Ember.RSVP.Promise(function (resolve) {
          resolve(queryPromise);
        }) });
    }),

    _brandForInstitute: function _brandForInstitute(institute) {
      var _this2 = this;

      if (!institute) {
        return;
      }

      var shortcode = institute.get("shortcode");

      // Stylesheet changes will be picked up by adding the class to the body.
      $('body').addClass(shortcode);

      // Text translations are manually reloaded.
      var translations = _map.default[shortcode];

      if (translations) {
        Object.keys(translations).forEach(function (t) {
          _this2.get('intl').addTranslations(t, translations[t]);
        });
      }
    },
    _loadFeaturesForInstitute: function _loadFeaturesForInstitute(institute) {
      var _this3 = this;

      if (!institute) {
        return;
      }

      var f = institute.get("featureSet");
      if (f) {
        Object.keys(f).forEach(function (feature) {
          if (f[feature]) {
            _this3.get('features').enable(feature);
          } else {
            _this3.get('features').disable(feature);
          }
        });
      }
    }
  });
});