define("frontend/adapters/survey/response-item", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // We want to get back any overwritten items atomically.
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=overridden-items";
      return value;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=overridden-items";
      return value;
    }
  });
});