define('frontend/pods/components/provider-admin/configuration-superseding-values/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    showDetails: true,

    supersedingConfigValues: Ember.computed('forceRefresh', 'path', 'entityTreeNode', 'entityTreeNode.path', 'filterTerm', function () {
      this.get("forceRefresh"); // Trigger the actual property binding.

      var filters = {
        supersedesPath: this.get('entityTreeNode.path')
      };

      return Ember.Object.create({
        store: this.get('store'),
        modelName: 'configurationValue',
        query: {
          filter: filters,
          include: 'configuration-property,user,entity-tree-node'
        }
      });
    }),

    columns: Ember.computed(function () {
      var intl = this.get('intl');

      var columns = [{
        propertyName: 'configurationProperty.name',
        title: intl.t('model.configurationProperty.name')
      }, {
        template: 'components/provider-admin/configuration-superseding-values/entity-path',
        title: intl.t('model.entityPath.path')
      }, {
        propertyName: 'value',
        title: intl.t('model.configurationValue.value')
      }, {
        template: 'components/provider-admin/configuration-active-values/action-buttons',
        title: ''
      }];

      return columns;
    })
  });
});