define('frontend/pods/components/provider/plan/entity-active-plans/expanded-details/component', ['exports', 'frontend/models/entity-tree-node', 'frontend/mixins/error-printer'], function (exports, _entityTreeNode, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    metrics: Ember.inject.service(),
    classNames: ['plan-expanded-details'],
    activeSegments: Ember.computed('rowContext.entityTreeNode', 'record.entityTreeNode', function () {

      var currentNode = this.get('rowContext.entityTreeNode');
      var planNode = this.get('record.entityTreeNode');

      if (this.get("rowContext.editCurrentNodeOnly")) {
        return [currentNode.get('segmentsWithSubSegments.lastObject')];
      }

      if (currentNode && (0, _entityTreeNode.isChildOf)(planNode, currentNode)) {
        return (0, _entityTreeNode.differenceSubSegments)(planNode, currentNode);
      } else if (currentNode) {
        return currentNode.get('segmentsWithSubSegments');
      } else {
        return [];
      }
    }),
    alterMode: Ember.computed('record.active', function () {
      if (this.get('record.active')) {
        return 'disable';
      } else {
        return 'enable';
      }
    }),

    // editing is enabled if:
    //   you are in administer mode
    //   OR the plan is defined at this level.

    showEditOptions: Ember.computed('record.active', 'canEdit', 'rowContext.administratorMode', function () {
      return this.get('record.active') && this.get('rowContext.administratorMode') && !this.get('canEdit');
    }),

    canEditOrDisable: Ember.computed('rowContext.administratorMode', 'canEdit', 'canDisable', function () {
      return this.get("rowContext.administratorMode") && (this.get("canEdit") || this.get('canDisable'));
    }),

    canEdit: Ember.computed('record.active', "rowContext.entityTreeNode", "record.entityTreeNode", function () {
      if (this.get('rowContext.disableEdit') || !this.get('record.active')) {
        return false;
      }

      var currentNode = this.get('rowContext.entityTreeNode');
      var planNode = this.get('record.entityTreeNode');

      return currentNode.get("path") === planNode.get("path");
    }),

    canDisable: Ember.computed('rowContext.administratorMode', "rowContext.entityTreeNode", "record.entityTreeNode", function () {
      if (this.get('rowContext.administratorMode')) {
        return true;
      }

      var currentNode = this.get('rowContext.entityTreeNode');
      var planNode = this.get('record.entityTreeNode');

      return currentNode.get("path") === planNode.get("path");
    }),

    actions: {
      alterPlan: function alterPlan(segment) {
        this.setProperties({ showingAlterModal: true, segmentToAlter: segment });
      },
      cancelAlterPlan: function cancelAlterPlan() {
        this.setProperties({ showingAlterModal: false, segmentToAlter: null });
      },
      confirmAlterPlan: function confirmAlterPlan() {
        var _this = this;

        this.set('alterErrorMessage', null);
        var model = this.get('store').createRecord('care/alterMeasurementPlanRequest', {
          mode: 'disable',
          entityMeasurementPlan: this.get('record.isGrouped') ? this.get('record.activeEntityPlan') : this.get('record'),
          effectiveDate: moment().startOf("day").toDate()
        });
        if (this.get('segmentToAlter.current.path') === this.get('record.entityTreeNode.path')) {
          // We can just set the existing path to false
          model.set("entityTreeNode", this.get("record.entityTreeNode"));
        } else {
          var entity = (0, _entityTreeNode.createFromSubsegment)(this.get('segmentToAlter'), this.get('store'));
          model.set('entityTreeNode', entity);
        }

        var onSuccess = function onSuccess(result) {
          _this.setProperties({ showingAlterModal: false, segmentToAlter: null });
          _this.get('metrics').trackEvent({
            event: 'provider.plan.entityMeasurementPlanActiveToggle',
            entityMeasurementPlanId: model.get('id')
          });
          _this.get('actionHandler')('onPlanChange', result);
        };

        var onError = function onError(reason) {
          _this.set('alterErrorMessage', _this.printError(reason));
        };

        return model.save().then(onSuccess).catch(onError);
      }
    }
  });
});