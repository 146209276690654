define('frontend/models/entity', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fromModel = exports.fromSegment = exports.iconMap = undefined;


  var iconMap = {
    institute: 'fa fa-hospital-o',
    organization: 'fa fa-hospital-o',
    provider: 'fa fa-user-md',
    patient: 'fa fa-user',
    'care-episode': 'fa fa-medkit',
    'patient-session': 'fa fa-wpforms',
    'condition': 'fa fa-heartbeat',
    'experiment': 'fa fa-flask',
    'experiment-variant': 'fa fa-flask',
    'care-unit-type': 'fa fa-users',
    'user': 'fa fa-user-o',
    'treatment-track': 'fa fa-road',
    'institute-payor': 'fa fa-usd',
    'institute-condition': 'fa fa-heartbeat',
    'treatment-service': 'fa fa-institution',
    'entity-decision-algorithm': 'fa fa-code',
    'workflow': 'fa fa-code-fork',
    'workflow-action': 'fa fa-tasks',
    'panel': 'fa fa-list'
  };

  var fromSegment = function fromSegment(segment, store) {
    return store.push({
      data: {
        id: segment.id,
        type: 'entity',
        attributes: {
          name: segment.name,
          entityType: segment.type
        }
      }
    });
  };

  var fromModel = function fromModel(model, store) {
    return store.push({
      data: {
        id: model.id,
        type: 'entity',
        attributes: {
          name: model.get('name'),
          entityType: model.get('entityType')
        }
      }
    });
  };

  exports.iconMap = iconMap;
  exports.fromSegment = fromSegment;
  exports.fromModel = fromModel;
  exports.default = _application.default.extend({
    entityType: _emberData.default.attr('string'),

    name: _emberData.default.attr('string'),

    links: Ember.computed('entityType', 'entity.id', function () {
      var entityType = this.get("entityType");

      switch (entityType) {
        case 'patient':
          return this.patientLinks();
        case 'care-episode':
          return this.careEpisodeLinks();
        case 'patient-session':
          return this.patientSessionLinks();
        case 'experiment':
          return this.experimentLinks();
        default:
          return [];
      }
    }),

    patientLinks: function patientLinks() {
      return [{
        icon: 'fa fa-bar-chart',
        path: 'provider.patients.show.longitudinal',
        entityId: this.belongsTo("entity").id(),
        title: 'Go to the latest report for this client'
      }];
    },
    careEpisodeLinks: function careEpisodeLinks() {
      return [{
        icon: 'fa fa-bar-chart',
        path: 'provider.patients.reports.care-episode',
        entityId: this.belongsTo("entity").id(),
        title: 'Go to the latest report for this care episode'
      }];
    },
    patientSessionLinks: function patientSessionLinks() {
      return [{
        icon: 'fa fa-bar-chart',
        path: 'provider.patients.reports.session',
        entityId: this.belongsTo("entity").id(),
        title: 'Go to this report'
      }];
    },
    experimentLinks: function experimentLinks() {
      return [{
        icon: 'fa fa-cog',
        path: 'provider.experiments.show',
        entityId: this.belongsTo("entity").id(),
        title: 'View settings for this experiment'
      }];
    },


    icon: Ember.computed('entityType', function () {
      return iconMap[this.get('entityType')];
    })
  });
});