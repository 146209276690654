define('frontend/pods/components/provider/assessment/report-v2/sidebar-mobile-section/component', ['exports', 'frontend/mixins/drawer-enabled'], function (exports, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    actions: {
      mobileTray: function mobileTray() {
        $('.mobile-tray').toggleClass('active');
      },
      closeTray: function closeTray() {
        $('.mobile-tray').removeClass('active');
      },
      setReportMetricContext: function setReportMetricContext(value) {
        if (value) {
          this.set("metrics.context.patientSessionId", value.get("patientSession.id"));
          this.set("metrics.context.reportId", value.get("id"));
        } else {
          var context = this.get("metrics.context");
          delete context.patientSessionId;
          delete context.reportId;
        }
      }
    }
  });
});