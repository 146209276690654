define("frontend/adapters/drilldown-metric", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=report-entity-tree-nodes.entity-tree-node,report-entity-tree-nodes.entity-tree-collections,scale-scorer";
      return value;
    }
  });
});