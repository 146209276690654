define('frontend/serializers/import/data-file', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // Status event is the only thing we're allowed to serialize
    attrs: {
      status: { serialize: false },
      fileName: { serialize: false },
      institute: { serialize: false },
      recordsCreated: { serialize: false },
      recordsUpdated: { serialize: false },
      recordsSkipped: { serialize: false },
      recordsErrored: { serialize: false },
      createdAt: { serialize: false }, // never send server-side timestamps
      updatedAt: { serialize: false } // never send server-side timestamps
    }
  });
});