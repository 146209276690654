define('frontend/models/care-episode-provider', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    provider: (0, _emberCpValidations.validator)('presence', true),
    careEpisode: (0, _emberCpValidations.validator)('presence', true),
    relationship: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    provider: _emberData.default.belongsTo("provider"),
    careEpisode: _emberData.default.belongsTo("careEpisode"),
    relationship: _emberData.default.attr("string")
  });
});