define("frontend/pods/components/provider/patient-search-bar/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    term: "",
    tagName: "form",
    classNames: ['navbar-form'],
    ariaRole: 'search',
    actions: {
      doSearch: function doSearch() {
        var term = this.get("term");

        if (Ember.isEmpty(term)) {
          return;
        }

        var searchObject = this.get("store").createRecord('patientSearch', { term: term });

        this.get("router").transitionTo("provider.patients", { queryParams: { searchId: searchObject.get("id") } });
        this.set("term", null); // Reset the search term after use so it doesn't stay in the bar.
        return true;
      }
    }
  });
});