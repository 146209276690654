define('frontend/helpers/debug/log', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.debug_log = debug_log;


    // Debug log helper one can slap in a handlebars template to console log an arbitrary value.
    // Call like {{ debug_log something-you-wish-to-know-the-true-nature-of }}
    function debug_log(value) {
        console.log(value);
    }

    exports.default = Ember.Helper.helper(debug_log);
});