define('frontend/pods/components/provider/assessment/report-v2/peabody-main/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/symptoms-explorer/component', 'frontend/utils/assessment/peabody'], function (exports, _component, _peabody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    sfssMnemonics: _peabody.default.GRAPHED_SYMPTOM_MNEMONICS,

    sfssSkillMnemonics: _peabody.default.GRAPHED_SKILL_MNEMONICS,

    showSfssGraph: Ember.computed('scales', function () {
      var _this = this;

      return this.getWithDefault('scalesToShow', []).any(function (s) {
        return _this.get('sfssMnemonics').indexOf(s.get('mnemonic')) >= 0;
      });
    }),
    sfssScales: Ember.computed('scales', function () {
      var _this2 = this;

      return this.getWithDefault('scalesToShow', []).filter(function (s) {
        return _this2.get('sfssMnemonics').indexOf(s.get("mnemonic")) >= 0;
      }).sortBy('mnemonic');
    }),

    trendScales: Ember.computed('scales', function () {
      var mnemonicArray = this.get('sfssMnemonics').concat(this.get('sfssSkillMnemonics'));
      return this.getWithDefault('scalesToShow', []).filter(function (s) {
        return mnemonicArray.indexOf(s.get("baseMnemonic")) >= 0;
      }).sortBy('baseMnemonic');
    }),

    showRecentChanges: Ember.computed('isFirstReport', function () {
      return !this.get('isFirstReport') && this.get('activeReport.includesComputedPeabody');
    }),

    validPopulation: Ember.computed.equal('reportSettings.currentPopulation.mnemonic', 'intreatment')
  });
});