define('frontend/pods/components/reporting/table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isExpanded: false,
    type: "generic",

    columns: Ember.A(),
    row: undefined,

    typeClassName: Ember.computed("type", function () {
      return "report-row-" + this.get("type");
    }),
    actions: {
      expand: function expand() {
        if (this.get('detailColumns.length')) {
          this.toggleProperty('isExpanded');
        }
      }
    }
  });
});