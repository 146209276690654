define('frontend/models/survey/response', ['exports', 'ember-data', 'frontend/utils/assessment/rule-evaluator', 'frontend/utils/survey/survey-hooks', 'frontend/models/application', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _emberData, _ruleEvaluator, _surveyHooks, _application, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Module = exports.Page = undefined;


  var Module = Ember.Object.extend({
    pagesToDisplay: null,

    init: function init() {
      // avoid using the same object for multiple objects.
      this.set("pagesToDisplay", {});
    },
    ensurePageIds: function ensurePageIds() {
      this.getWithDefault('content.pages', []).forEach(function (page) {
        if (!page.id) {
          page.id = (0, _uuidHelpers.default)();
        }
      });
    },
    updatePagesToDisplay: function updatePagesToDisplay() {
      var _this = this;

      $.each(this.getWithDefault('content.pages', []), function (index, p) {
        _this.get('pagesToDisplay')[p.id] = _this.evaluatePageShown(p);
      });
      this.notifyPropertyChange('pagesToDisplay');
    },
    evaluatePageShown: function evaluatePageShown(page) {
      if (!page.properties) {
        return true;
      }

      var context = {
        response: this.get("response"),
        module: this
      };

      return !page.properties.showRule || _ruleEvaluator.default.evaluateRule(page.properties.showRule, context);
    },


    pages: Ember.computed('content.pages.[]', function () {
      var _this2 = this;

      return this.get("content.pages").map(function (page) {
        return Page.create(Ember.merge({ module: _this2, response: _this2.get("response") }, page));
      });
    }),

    currentShownPages: Ember.computed('pages.[]', 'pagesToDisplay', function () {
      var _this3 = this;

      return this.getWithDefault('pages', []).filter(function (p) {
        return _this3.isPageShown(p);
      });
    }),

    isPageShown: function isPageShown(page) {
      if (page.id) {
        return this.get('pagesToDisplay')[page.id] !== false;
      } else {
        return this.evaluatePageShown(page);
      }
    },
    updateAllDisplayLogic: function updateAllDisplayLogic() {
      this.updatePagesToDisplay();
      this.get('pages').forEach(function (page) {
        page.updateQuestionsToDisplay();
      });
    }
  });

  var Page = Ember.Object.extend({
    questionsToDisplay: {},
    currentShownQuestions: Ember.computed('questionsToDisplay', function () {
      var _this4 = this;

      var context = {
        response: this.get("response"),
        module: this.get("module")
      };

      return this.get('questions').filter(function (q) {
        var properties = q.properties || {};
        if (properties.showRule) {
          return _ruleEvaluator.default.evaluateRule(properties.showRule, context);
        }

        return _this4.get('questionsToDisplay')[q.shortname] || !properties.hidden;
      });
    }),

    updateQuestionsToDisplay: function updateQuestionsToDisplay() {
      this.notifyPropertyChange('currentShownQuestions');
    }
  });

  exports.Page = Page;
  exports.Module = Module;
  exports.default = _application.default.extend({
    invite: _emberData.default.belongsTo('survey/invite'),
    startTime: _emberData.default.attr('date'),
    endTime: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    statusEvent: _emberData.default.attr('string'),
    currentLanguage: _emberData.default.attr('string', { defaultValue: 'en' }),
    //unless you know what you are doing, don't manipulate this array directly.  Instead use the updateResponseItem method
    responseItems: _emberData.default.hasMany('survey/responseItem'),

    //map of mnemonic => true/false of whether the question should be shown.  If undefined, show it
    questionsToDisplay: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    //map of id => true/false of whether the question should be shown.  If undefined, show it
    pagesToDisplay: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    takingMetadata: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    //The current* properties below represent the current place of the user within the survey.  They should NOT be set directly from outside unless the outside processes really knows what it is doing.
    //These properties would be prefixed with _ if not for them needing to be stored in the DB
    //index of the currently shown question within the list of currentShownQuestions.  Be VERY careful about showing/hiding questions behind the current view index
    currentQuestionNum: _emberData.default.attr('number', { defaultValue: 0 }),
    //index of the currently shown page within the list of currentShownPages.  Be VERY careful about showing/hiding questions behind the current view index
    currentPageNum: _emberData.default.attr('number', { defaultValue: 0 }),
    currentModuleNum: _emberData.default.attr('number', { defaultValue: 0 }),

    //map of question mnemonics to response items
    responseItemMap: Ember.computed(function () {
      return {};
    }),

    //this is a 'fake' property built to allow the route or other components to notifiy others of when the back button is pressed
    isBrowserBackPressed: false,

    ////////////// OVERALL CONTROL
    //initializes the response so it is ready to be taken (updates show/hide for the current module)
    //note that begin() currently does not behave correctly if the invite on the response is not yet resolved
    begin: function begin() {
      this.updateResponseItemMap();
      this.runAllInitHooks();
      this.runResumedAfterHooks();
      this.get("currentModule").updatePagesToDisplay();
      if (this.get("pageIsBlank")) {
        this.goToNextPage();
      }
    },
    beginInterviewPreview: function beginInterviewPreview() {
      this.runAllInitHooks();
      this.updateResponseItemMap();
      this.updateAllDisplayLogic();
    },


    //mark as complete, and clean up any unsaved answers
    finish: function finish() {
      this.set('statusEvent', 'completed');
      this.flush();
    },


    //exit and clean up any unsaved answers
    exit: function exit() {
      this.flush();
    },


    //saves any unsaved answers, and marks any progress
    flush: function flush() {
      this.save();
      //TODO iterate over answeers and make sure all are saved
    },
    updateAllDisplayLogic: function updateAllDisplayLogic() {
      this.get('allModules').forEach(function (m) {
        return m.updateAllDisplayLogic();
      });
    },
    evaluateModuleShown: function evaluateModuleShown(module) {
      var administrationData = this.get('invite.paramData.' + module.mnemonic);

      if (this._skipContactDetailsModule(module)) {
        return false;
      }

      if (!administrationData || !administrationData.conditional_logic) {
        return true;
      }

      var context = {
        response: this,
        module: module
      };

      return _ruleEvaluator.default.evaluateRule(administrationData.conditional_logic, context);
    },


    // We need to hide the contact details module if the user has already hit the notification preferences.
    // In future the contact details should probably be handled out of band rather than as a module.
    _skipContactDetailsModule: function _skipContactDetailsModule(module) {
      if (module.mnemonic !== 'contactDetails') {
        return false;
      }

      return this.get('skipContactDetails');
    },


    //get the critical tracking information from the response
    //this is perhaps chattier than it needs to be, syncing the pages and questions on every call, but
    //the alternative is obscure refresh bugs where the logic wasn't correctly updated
    //(or attempting to rerun all logic on each refresh, which is dangerous due to various hooks)
    //and its good info for debugging
    getCurrentTracking: function getCurrentTracking() {
      return {
        currentPageNum: this.get('currentPageNum'),
        currentModuleNum: this.get('currentModuleNum'),
        currentQuestionNum: this.get('currentQuestionNum'),
        questionsToDisplay: this.get('questionsToDisplay'),
        pagesToDisplay: this.get('pagesToDisplay')
      };
    },


    //syncs the response item map to the response items
    //this should only need to be called on resume/refresh from the browser, since the answering components are delegated the task of keeping the responseItemMap up to date
    updateResponseItemMap: function updateResponseItemMap() {
      var newResponseItemMap = {};
      this.get('responseItems').forEach(function (item) {
        var responseSet = newResponseItemMap[item.get('uniqueQuestionIdentifier')];
        if (!responseSet) {
          responseSet = {
            responseItems: [item]
          };
          newResponseItemMap[item.get('uniqueQuestionIdentifier')] = responseSet;
        } else {
          responseSet.responseItems.push(item);
        }

        if (item.get('active')) {
          responseSet.simpleValue = item.get('value');
        }
      });
      this.set('responseItemMap', newResponseItemMap);
    },


    // Run any hooks which are needed to initialize the modules
    runAllInitHooks: function runAllInitHooks() {
      var _this5 = this;

      this.get("invite.preloadedModules").forEach(function (module) {
        if (module.content.properties && module.content.properties.initHook) {
          _surveyHooks.default.runHook(module.content.properties.initHook, module, _this5, _this5.get('invite.paramData'));
        }

        module.content.pages.forEach(function (page) {
          if (page.properties && page.properties.initHook) {
            _surveyHooks.default.runHook(page.properties.initHook, page, _this5, _this5.get('invite.paramData'));
          }
        });
      });
    },


    // In bulk edit mode, you want to run all the after module hooks too, because everything loads at once.
    runAllAfterHooks: function runAllAfterHooks() {
      var _this6 = this;

      this.get("invite.preloadedModules").forEach(function (module) {
        if (module.content && module.content.afterModuleHook) {
          _surveyHooks.default.runHook(module.content.afterModuleHook, _this6, _this6.get('invite.paramData'));
        }
      });
    },


    // If you resume a response, you need to rerun all the after module hooks for completed assessments.
    runResumedAfterHooks: function runResumedAfterHooks() {
      var _this7 = this;

      var currentModuleNum = this.get("currentModuleNum");

      this.get("invite.preloadedModules").forEach(function (module, index) {
        if (currentModuleNum > index && module.content && module.content.afterModuleHook) {
          _surveyHooks.default.runHook(module.content.afterModuleHook, _this7, _this7.get('invite.paramData'));
        }
      });
    },


    ///////////////PAGING FUNCTIONS/////////////////////////

    //not yet implemented
    nextPage: Ember.computed('currentModule', 'currentPageNum', function () {
      return undefined;
    }),

    //not yet implemented
    prevPage: Ember.computed('currentModule', 'currentPageNum', function () {
      return undefined;
    }),

    currentPage: Ember.computed('currentShownPages', 'currentPageNum', function () {
      return this.get('currentShownPages')[this.get('currentPageNum')];
    }),

    isLastPageOfModule: Ember.computed('currentModule', 'currentPageNum', 'currentShownPages.[]', function () {
      return this.get('currentShownPages.length') <= this.get('currentPageNum') + 1;
    }),

    isFirstPageOfModule: Ember.computed('currentPageNum', 'currentShownPages.[]', function () {
      return this.get('currentPageNum') === 0;
    }),

    hasNextPageOrModule: Ember.computed('isLastPageOfModule', 'isLastModule', function () {
      return !this.get('isLastPageOfModule') || !this.get('isLastModule');
    }),

    currentShownPages: Ember.computed.alias('currentModule.currentShownPages'),

    pageIsBlank: Ember.computed('currentPage.properties.component', 'currentShownQuestions.length', function () {
      return !this.get("currentPage.properties.component") && !this.get('currentShownQuestions.length');
    }),

    completedPages: Ember.computed('currentPageNum', 'currentModuleNum', 'activeModules', function () {
      var modules = this.get('invite.preloadedModules');
      var pagesPast = 0;
      for (var i = 0; i < this.get('currentModuleNum'); i++) {
        pagesPast += modules[i].content.pages.length;
      }
      return pagesPast + this.get('currentPageNum');
    }),

    //advance the page and updates currentPage.  returns false if this is not possible (e.g. if this is the last page of the last module)
    //this also re-evaluates page display logic
    //saveProgress defaults to true, and indicates whether the response progress should be saved to the server
    goToNextPage: function goToNextPage() {
      this.get('currentModule').updatePagesToDisplay();
      if (this.get('isLastPageOfModule')) {
        if (this.get('isLastModule')) {
          return false;
        }
        if (this.get('currentModule.content.afterModuleHook')) {
          _surveyHooks.default.runHook(this.get('currentModule.content.afterModuleHook'), this, this.get('invite.paramData'));
        }

        var candidateNum = this.get('currentModuleNum') + 1;
        var candidateModule = this.get('allModules')[candidateNum];

        while (candidateModule && !this.evaluateModuleShown(candidateModule)) {
          candidateNum += 1;
          candidateModule = this.get('allModules')[candidateNum];
        }

        this.setProperties({ currentModuleNum: candidateNum,
          currentPageNum: 0,
          currentQuestionNum: 0 });

        this.get('currentModule').updatePagesToDisplay();
      } else {
        this.setProperties({ currentPageNum: this.get('currentPageNum') + 1,
          currentQuestionNum: 0 });
      }

      // Skip blank pages which aren't custom components
      if (this.get("pageIsBlank")) {
        this.goToNextPage();
      }
    },


    //return to previous page and updates currentPage.  returns false if this is not possible (e.g. if this is the first page of the first module)
    goToPrevPage: function goToPrevPage() {
      this.get('currentModule').updatePagesToDisplay();
      if (this.get('isFirstPageOfModule')) {
        if (this.get('isFirstModule')) {
          return false;
        }

        var candidateNum = this.get('currentModuleNum') - 1;
        var candidateModule = this.get('allModules')[candidateNum];

        while (candidateModule && !this.evaluateModuleShown(candidateModule)) {
          candidateNum -= 1;
          candidateModule = this.get('allModules')[candidateNum];
        }
        this.set('currentModuleNum', candidateNum);
        this.set('currentPageNum', this.get('currentShownPages.length') - 1);
      } else {
        this.set('currentPageNum', this.get('currentPageNum') - 1);
      }

      if (this.get("currentPage.properties.component")) {
        // custom components always have 0 for question number
        this.set('currentQuestionNum', 0);
      } else {
        this.set('currentQuestionNum', this.get('currentShownQuestions.length') - 1);
      }

      // Skip blank pages which aren't custom components
      if (this.get("pageIsBlank")) {
        this.goToPrevPage();
      }
    },


    //////////////////  QUESTION FUNCTIONS /////////////////////////////////////////
    //advance the question and update currentQuestion.  returns false if not possible (already on the last question of the page)
    goToNextQuestion: function goToNextQuestion() {
      if (this.get('isLastQuestionOfPage')) {
        return false;
      }

      this.set('currentQuestionNum', this.get('currentQuestionNum') + 1);
      return true;
    },
    goToPrevQuestion: function goToPrevQuestion() {
      if (this.get('isFirstQuestionOfPage')) {
        return false;
      }
      this.set('currentQuestionNum', this.get('currentQuestionNum') - 1);
    },


    prevQuestionNum: Ember.computed('currentPage', 'currentQuestionNum', function () {
      return this.get('currentQuestionNum') - 1;
    }),

    currentQuestion: Ember.computed('currentModule', 'currentQuestionNum', 'currentPageNum', function () {
      return this.get('currentShownQuestions')[this.get('currentQuestionNum')];
    }),

    isLastQuestionOfPage: Ember.computed('currentPage', 'currentQuestionNum', 'currentShownQuestions.[]', function () {
      return this.get('currentShownQuestions.length') <= this.get('currentQuestionNum') + 1;
    }),

    isFirstQuestionOfPage: Ember.computed('currentQuestionNum', function () {
      return this.get('currentQuestionNum') === 0;
    }),

    currentShownQuestions: Ember.computed.alias('currentPage.currentShownQuestions'),

    //////////////////  MODULE FUNCTIONS ///////////////////////////////////
    allModules: Ember.computed('invite', function () {
      var _this8 = this;

      return this.get('invite.preloadedModules').map(function (m) {
        var mod = Module.create(Ember.merge({ response: _this8 }, m));
        mod.ensurePageIds();
        return mod;
      });
    }),

    currentModule: Ember.computed('allModules.[]', 'currentModuleNum', function () {
      return this.get('allModules.[]')[this.get('currentModuleNum')];
    }),

    isLastModule: Ember.computed('currentModuleNum', function () {
      return this.get('currentModuleNum') === this.get('invite.moduleIds.length') - 1;
    }),

    isFirstModule: Ember.computed('currentModuleNum', function () {
      return this.get('currentModuleNum') === 0;
    }),

    hasPrevItem: Ember.computed('currentModuleNum', 'currentPageNum', 'currentQuestionNum', function () {
      return this.get('currentModuleNum') !== 0 || this.get('currentPageNum') !== 0 || this.get('currentQuestionNum') !== 0;
    }),

    hasNextItem: Ember.computed('currentModule', 'hasNextPageOrModule', 'currentPage', function () {
      return (this.get('hasNextPageOrModule') || !this.get('isLastQuestionOfPage')) && !this.get('currentPage.properties.terminalPage');
    }),

    currentAnchor: Ember.computed('currentModuleNum', 'currentPageNum', 'currentQuestionNum', function () {
      var _getProperties = this.getProperties('currentModuleNum', 'currentPageNum', 'currentQuestionNum'),
          currentModuleNum = _getProperties.currentModuleNum,
          currentPageNum = _getProperties.currentPageNum,
          currentQuestionNum = _getProperties.currentQuestionNum;

      return 'm' + currentModuleNum + 'p' + currentPageNum + 'q' + currentQuestionNum;
    }),

    /////////////////  RESPONSE FUNCTIONS ///////////////////////////////////

    /**
     returns a responseSet object for the question (if one doesn't exist, it will be created)
     responseSets are of the form
      {
        simpleValue: naive string representation of value, useful for rules comparisons
        responseItems: array of zero or more responseItems
      }
    */
    getResponseSetForQuestion: function getResponseSetForQuestion(question, module) {
      var fqKey = module.question_domain ? module.question_domain + '#' + question.shortname : question.shortname;

      var responseSet = this.get('responseItemMap')[fqKey];

      // For legacy reasons, check against the unqualified name if not present.
      if (!responseSet) {
        responseSet = this.get('responseItemMap')[question.shortname];
      }

      if (!responseSet) {
        responseSet = {
          simpleValue: null,
          responseItems: []
        };
        this.get('responseItemMap')[fqKey] = responseSet;
      }
      return responseSet;
    }
  });
});