define('frontend/pods/provider/patients/show/measure-now/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    metrics: Ember.inject.service(),
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      var patientFuture = this.store.findRecord('patient', this.paramsFor('provider.patients.show').patient_id, { include: "default-scales,client-participants" });
      var activeProvider = this.get("sessionUser.activeProvider");
      var providerOrganizations = this.get("sessionUser").get("activeProvider.organizationSubtrees");
      var allProviders = this.get("preload.clinicians");
      var scales = this.store.query("assessment/scale", { filter: { independent: true }, page: { limit: 100 } });

      return patientFuture.then(function (patient) {
        // We want to 'detch' these from the relationship, so they both get toArray'd. Otherwise, whenever you create
        // a new record, it'll instantly get added to this list.
        var activePatientSessions = patient.query("patientSessions", { filter: { preset: "with_potentially_active" }, include: "assessment-report.report-scales.scale-participants" }).then(function (result) {
          return result ? result.toArray() : [];
        });
        var activeCareEpisodes = patient.query("careEpisodes", { include: 'participants', filter: { status: "active" } }).then(function (result) {
          return result ? result.toArray() : [];
        });

        return Ember.RSVP.hash({
          patient: patient,
          activeProvider: activeProvider,
          activePatientSessions: activePatientSessions,
          activeCareEpisodes: activeCareEpisodes,
          providerOrganizations: providerOrganizations,
          allProviders: allProviders,
          scales: scales
        });
      });
    }
  });
});