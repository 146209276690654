define('frontend/mixins/unauthenticated-route-mixin', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('session').get('isAuthenticated')) {
        // If you're not a provider
        if (this.get("session").get("data.authenticated.role") !== "provider") {
          this.set('session.silentInvalidate', true);
          this.get("metrics").trackEvent({ event: "user.logout", reason: "wrongRole" });
          this.get('sessionUser').logout();
          return this._super.apply(this, arguments);
        } else {
          // otherwise go to the real home page.
          transition.abort();
          this.transitionTo(_environment.default['ember-simple-auth'].routeIfAlreadyAuthenticated);
        }
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});