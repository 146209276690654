define('frontend/pods/components/provider/reports/provider-load-mock/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    glanceTimeOptions: ['Oct.', 'Sep.', 'Aug.', 'Jul.', 'Jun.'],

    actions: {
      updateOutcomeType: function updateOutcomeType(outcomeType) {
        this.set('currentOutcome', _lodash.default.find(this.get('outcomeTypes'), function (outcome) {
          return outcome.value === outcomeType;
        }));
      }
    },

    reimbursementFraction: Ember.computed(function () {
      return { value: 0.86, key: 'reimbursement.fraction', className: 'good' };
    }),

    patientStatusIcon: {
      active: 'fa-calendar-o',
      unknown: 'fa-question-circle-o',
      discharged: 'fa-calendar-check-o'
    },

    meanPatient: {
      overallAdherence: { value: '73%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '5', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    medianPatient: {
      overallAdherence: { value: '80%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '8', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    providers: [{
      name: "Dr. Meredith Grey",
      clinicalHours: 22,
      hardHours: 26,
      hardHoursClass: 'good',
      nonClinicalPercentage: 23,
      nonClinicalBurdenClass: 'good',
      intakeSeverity: 7,
      dischargeSeverity: 4,
      noShowRate: 20
    }, {
      name: "Dr. John Zoidberg",
      clinicalHours: 34,
      nonClinicalPercentage: 45,
      hardHoursClass: 'moderate',
      nonClinicalBurdenClass: 'moderate',
      hardHours: 72,
      intakeSeverity: 6,
      dischargeSeverity: 3,
      noShowRate: 20
    }, {
      name: "Dr. Who",
      clinicalHours: 32,
      hardHours: 56,
      hardHoursClass: 'moderate',
      nonClinicalPercentage: 35,
      nonClinicalBurdenClass: 'moderate',
      intakeSeverity: 7,
      dischargeSeverity: 3,
      noShowRate: 20
    }, {
      name: "Dr. Henry Jekyll ",
      clinicalHours: 33,
      hardHours: 84,
      hardHoursClass: 'severe',
      nonClinicalPercentage: 65,
      nonClinicalBurdenClass: 'severe',
      intakeSeverity: 9,
      dischargeSeverity: 8,
      noShowRate: 20
    }, {
      name: "Dr. Deanna Troi",
      clinicalHours: 21,
      hardHours: 21,
      hardHoursClass: 'good',
      nonClinicalPercentage: 45,
      nonClinicalBurdenClass: 'moderate',
      intakeSeverity: 7,
      dischargeSeverity: 4,
      noShowRate: 20
    }, {
      name: "Dr. Dana Scully",
      clinicalHours: 31,
      hardHours: 58,
      hardHoursClass: 'moderate',
      nonClinicalPercentage: 39,
      nonClinicalBurdenClass: 'moderate',
      intakeSeverity: 9,
      dischargeSeverity: 7,
      noShowRate: 20
    }]
  });
});