define('frontend/pods/components/provider/patient-sessions/session-schedule/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    hourOptions: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    minuteOptions: [0, 15, 30, 45],
    recurrenceOptions: ['none', 'daily', 'weekdays', 'weekly', 'biweekly', 'monthly', 'quarterly', 'biannually'],

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('session.targetDate') && this.get('session')) {
        this.updateSessionTargetDate();
      }
    },


    practicingProviders: Ember.computed.filterBy('allProviders', 'practicing', true),

    providerIsSelf: Ember.computed("session.provider", "sessionUser.activeProvider", function () {
      return this.get("session.provider.id") && this.get("session.provider.id") === this.get("sessionUser.activeProvider.id");
    }),

    //these properties below cause problems if they are bound to session.schedulingAppointment.startDate due to update race conditions
    appointmentStartDateMinutes: Ember.computed(function () {
      return Math.floor(this.getWithDefault('session.schedulingAppointment.startDate', new Date()).getMinutes() / 15) * 15;
    }),

    appointmentStartDateHours: Ember.computed(function () {
      var hourOffset = this.getWithDefault('session.schedulingAppointment.startDate', new Date()).getHours() % 12;
      return hourOffset === 0 ? 12 : hourOffset;
    }),

    appointmentStartDateIsPm: Ember.computed('session.schedulingAppointment.startDate', function () {
      return this.getWithDefault('session.schedulingAppointment.startDate', new Date()).getHours() >= 12;
    }),

    targetDateObserver: Ember.observer('session.schedulingAppointment.startDate', function () {
      this.updateSessionTargetDate();
    }),

    updateSessionTargetDate: function updateSessionTargetDate() {
      if (this.get('session.schedulingAppointment.startDate')) {
        this.set('session.targetDate', this.get('session.schedulingAppointment.startDate'));
      } else {
        this.set('session.targetDate', new Date());
      }
    },
    _createRecurrence: function _createRecurrence() {
      var recurrence = this.get("session.schedulingRecurrence");
      // You may or maynot have a promise
      if (recurrence.then) {
        recurrence = recurrence.content;
      }

      if (recurrence) {
        if (recurrence.get("isDeleted")) {
          recurrence.rollbackAttributes();
        }
      } else {
        recurrence = this.get("store").createRecord("scheduling/recurrence", {});
        this.set("session.schedulingRecurrence", recurrence);
      }

      return recurrence;
    },
    _deleteRecurrence: function _deleteRecurrence() {
      var recurrence = this.get("session.schedulingRecurrence");

      // You may or maynot have a promise
      if (recurrence.then) {
        recurrence = recurrence.content;
      }

      if (recurrence) {
        recurrence.deleteRecord();
      }
    },


    actions: {
      changeRecurrenceType: function changeRecurrenceType(value) {
        if (value === 'none') {
          this._deleteRecurrence();
        } else {
          this._createRecurrence();
        }
      },
      changeRecurrence: function changeRecurrence(value) {
        var endDate = value.endDate,
            repeatMnemonic = value.repeatMnemonic;

        if (repeatMnemonic === 'none') {
          this._deleteRecurrence();
        } else {
          var recurrence = this._createRecurrence();

          recurrence.setProperties({ endDate: endDate, repeatMnemonic: repeatMnemonic });
        }
      },


      // The provider is automatically set on the session, but not on the appointment.
      updateAppointmentProvider: function updateAppointmentProvider(provider) {
        this.set("session.provider", provider);
        if (this.get("session.schedulingAppointment.id")) {
          this.set("session.schedulingAppointment.provider", provider);
        }
      },
      changeAppointmentType: function changeAppointmentType(value) {
        if (value === 'walkin') {
          var appointment = this.get("session.schedulingAppointment");

          // You may or maynot have a promise
          if (appointment.then) {
            appointment = appointment.content;
          }

          if (appointment) {
            appointment.deleteRecord();
          }
          this.set("session.statusEvent", "measure_now");

          this._deleteRecurrence();
        } else {
          var _appointment = this.get("session.schedulingAppointment");
          // You may or maynot have a promise
          if (_appointment.then) {
            _appointment = _appointment.content;
          }

          if (_appointment) {
            if (_appointment.get("isDeleted")) {
              _appointment.rollbackAttributes();
            }
          } else {
            this.get("store").createRecord("scheduling/appointment", { startDate: new Date(), patientSession: this.get("session"), patient: this.get("patient"), provider: this.get("session.provider") });
          }
          this.set("session.statusEvent", null);
          this._createRecurrence();
        }
      }
    }
  });
});