define('frontend/mixins/survey/scrollable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scrollableSelector: '.question-content',
    endOfScroll: true,
    _setupScroll: function _setupScroll() {
      this.showHideScrollIndicator = this.showHideScrollIndicator.bind(this);
      this.$(this.get('scrollableSelector')).scroll(this.showHideScrollIndicator);
      $(window).resize(this.showHideScrollIndicator);
      this.showHideScrollIndicator();
    },
    didInsertElement: function didInsertElement() {
      this._setupScroll();
      this._super();
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('resize', this.showHideScrollIndicator);
      this._super();
    },
    showHideScrollIndicator: function showHideScrollIndicator() {
      if (!this.$(this.get('scrollableSelector'))) {
        // funky timing can cause this to be called after the component's element is destroyed 
        // see https://sentry.mirah.com/mirah/production/issues/719/
        return;
      }
      var el = this.$(this.get('scrollableSelector'))[0];
      this.set('endOfScroll', el.scrollTop + el.offsetHeight >= el.scrollHeight);
    }
  });
});