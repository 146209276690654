define('frontend/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('crossFade-demo'), this.toValue(true), this.use('wait', 1000, { then: 'crossFade' }));
  };
});