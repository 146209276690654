define('frontend/pods/patient/assessment/latest/route', ['exports', 'frontend/mixins/patient-route-mixin', 'frontend/pods/base-route'], function (exports, _patientRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_patientRouteMixin.default, {
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    model: function model() {
      var _this = this;

      this._super.apply(this, arguments);
      var measurementRequest = this.store.createRecord("assessment/measurementRequest");
      return measurementRequest.save().then(function (response) {
        if (response.get("invitation.id")) {
          _this.get("router").transitionTo("patient.assessment.invitation.begin", response.get("invitation.id"));
        } else {
          _this.transitionTo('patient.assessment.up-to-date');
        }
      });
    }
  });
});