define('frontend/models/import/data-file', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    fileName: _emberData.default.attr("string"),
    institute: _emberData.default.belongsTo("institute"),
    recordsCreated: _emberData.default.attr("number"),
    recordsUpdated: _emberData.default.attr("number"),
    recordsSkipped: _emberData.default.attr("number"),
    recordsErrored: _emberData.default.attr("number"),
    status: _emberData.default.attr("string"),
    statusEvent: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr("date"),
    updatedAt: _emberData.default.attr("date")
  });
});