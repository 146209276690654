define("frontend/pods/components/common/graphs/goal-lines/component", ["exports", "frontend/pods/components/common/graphs/scale-lines/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    transformData: function transformData(data) {
      var transformed = data.map(function (goalData) {
        return {
          datum: goalData.goal,
          index: goalData.colorIndex,
          lineStyle: 0,
          color: goalData.color,
          items: goalData.answerData.map(function (answer) {
            return {
              datum: answer,
              color: goalData.color,
              x: answer.get("report.patientSession.targetDate"),
              y: answer.get("value"),
              valid: !!answer.get("value") || answer.get("value") === 0
            };
          })
        };
      });

      return transformed;
    }
  });
});