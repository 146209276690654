define('frontend/pods/components/provider/assessment/common/likert-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['response-answer'],
    tagName: 'span',

    leftLabel: Ember.computed('question.displayData.details.leftLabel', 'question.displayData.details.leftValue', function () {
      if (this.get('question.displayData.details.leftLabel')) {
        return this.get('question.displayData.details.leftLabel');
      }

      return this.get('question.displayData.details.leftValue');
    }),

    rightLabel: Ember.computed('question.displayData.details.rightLabel', 'question.displayData.details.rightValue', function () {
      if (this.get('question.displayData.details.rightLabel')) {
        return this.get('question.displayData.details.rightLabel');
      }

      return this.get('question.displayData.details.rightValue');
    }),

    transformedValue: Ember.computed('answer.value', 'reversed', 'minValue', 'maxValue', function () {
      if (this.get('reversed')) {
        var minValue = this.get('minValue');
        var maxValue = this.get('maxValue');

        return maxValue + minValue - parseInt(this.get("answer.value"));
      } else {
        return this.get("answer.value");
      }
    }),

    reversed: Ember.computed('question.displayData.details.leftValue', 'question.displayData.details.rightValue', function () {
      var left = this.get('question.displayData.details.leftValue');
      var right = this.get('question.displayData.details.rightValue');

      return left > right;
    }),

    minValue: Ember.computed('reversed', 'question.displayData.details.leftValue', 'question.displayData.details.rightValue', function () {
      if (this.get('reversed')) {
        return this.get('question.displayData.details.rightValue');
      } else {
        return this.get('question.displayData.details.leftValue');
      }
    }),
    maxValue: Ember.computed('reversed', 'question.displayData.details.leftValue', 'question.displayData.details.rightValue', function () {
      if (this.get('reversed')) {
        return this.get('question.displayData.details.leftValue');
      } else {
        return this.get('question.displayData.details.rightValue');
      }
    })
  });
});