define('frontend/pods/components/survey/taking-local/common/survey-question/component', ['exports', 'frontend/mixins/survey/scrollable'], function (exports, _scrollable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.QuestionTypeMap = undefined;


  var QuestionTypeMap = {
    RADIO: 'survey/taking-local/common/questions/radio-question',
    SLIDER: 'survey/taking-local/common/questions/slider-question',
    FULLSLIDER: 'survey/taking-local/common/questions/full-slider-question',
    CHECKBOX: 'survey/taking-local/common/questions/checkbox-question',
    DYNAMICVARIABLECHECKBOX: 'survey/taking-local/common/questions/dynamic-variable-checkbox',
    INSTRUCTIONS: 'survey/taking-local/common/questions/content-question',
    ESSAY: 'survey/taking-local/common/questions/essay-question',
    TEXTBOX: 'survey/taking-local/common/questions/textbox-question',
    MEDIA: 'survey/taking-local/common/questions/media-question',
    TABLE: 'survey/taking-local/common/questions/grid-question',
    SELECT: 'survey/taking-local/common/questions/select-question',
    MONTHYEAR: 'survey/taking-local/common/questions/month-year-question',
    YEARRANGE: 'survey/taking-local/common/questions/year-range-question'
  };

  exports.QuestionTypeMap = QuestionTypeMap;
  exports.default = Ember.Component.extend(_scrollable.default, {
    tagName: 'div',
    classNames: 'question',
    classNameBindings: ['getClassNames'],
    scrollableSelector: '.question-content',

    // Set to bulk for bulk editing
    mode: 'normal',

    getClassNames: Ember.computed('question', function () {
      return this.get('question.properties.displayClass');
    }),

    currentlyActive: Ember.computed("questionNum", "response.currentQuestionNum", function () {
      return this.get("questionNum") === this.get("response.currentQuestionNum");
    }),

    questionComponentName: Ember.computed('question', function () {
      var questionType = this.get('question.type');

      return QuestionTypeMap[questionType];
    }),

    actions: {
      next: function next() {
        Ember.run.later(this, this.sendAction, 'next', 300);
      }
    }
  });
});