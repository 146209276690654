define('frontend/pods/provider/patients/show/plan/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),

    breadCrumb: {
      title: 'Measurement Protocol'
    },

    queryParams: {
      providerId: {},
      careEpisodeId: {},
      organizationId: {}
    },

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations")
      });
    },
    model: function model() {
      var patient = this.modelFor('provider.patients.show');
      var isTest = this.get('patient.isTest');

      var upcomingSessions = this.store.query('patientSession', {
        include: "scheduling-appointment,assessment-report,provider,patient-session-measurement-plans.entity-measurement-plan.measurement-plan",
        adapterOptions: { fieldset: 'all' },
        filter: { upcoming_and_today_for: patient.get('id'), show_test: isTest },
        page: { limit: 5 }
      });

      var participants = patient.query("clientParticipants", { include: "user" });
      var measurementPlans = this.get('store').query('care/measurementPlan', { filter: { preset: 'reusable' }, include: 'measurement-plan-scales,author', page: { limit: 100 } });

      // Ensure care episodes are up to date.
      patient.hasMany("activeCareEpisodes").reload();

      return Ember.RSVP.hash({
        patient: patient,
        participants: participants,
        providerEntities: this.get("preload.providerEntities"),
        organizationEntities: this.get("preload.organizationEntities"),
        scales: this.get("preload.independentScales"),
        upcomingSessions: upcomingSessions,
        activeCareEpisodes: patient.get('activeCareEpisodes'),
        organizations: this.get('preload.organizations'),
        providerOrgs: patient.get('defaultProvider.organizations'),
        providers: this.get('preload.providers'),
        measurementPlans: measurementPlans
      });
    },


    actions: {
      onPlanChange: function onPlanChange() {
        return this.refresh();
      }
    }
  });
});