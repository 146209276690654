define('frontend/pods/components/reporting/tabular-report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    preload: Ember.inject.service(),
    tableData: null, //the table data to render

    didReceiveAttrs: function didReceiveAttrs() {
      var report = this.reportSchema();

      if (report) {
        var tableData = this.store.createRecord('tabular-metric', report);
        this.set('tableData', tableData.save());
      }
    },
    reportSchema: function reportSchema() {
      var report = {
        shortcode: this.get('tableMetric'),
        breakdown: this.get('breakdown')
      };

      if (this.get('preset') === 'custom') {
        report.startDate = this.get('startTime');
        report.endDate = this.get('endTime');

        if (!report.startDate || !report.endDate) {
          // Don't fetch the report if the required start and end date haven't been set!
          return;
        }
      } else {
        report.preset = this.get('preset');
      }

      return report;
    }
  });
});