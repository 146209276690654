define('frontend/pods/components/internal/event-tracer-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        "propertyName": "id",
        "title": "Id",
        isHidden: true,
        disableSorting: true
      }, {
        "propertyName": "eventDate",
        "template": "components/common/server-side-table/date-no-time-zone",
        "title": intl.t('model.event.eventDate'),
        sortDirection: 'desc',
        sortPrecedence: 0
      }, {
        "propertyName": "user.name",
        "title": "User",
        disableSorting: true
      }, {
        "propertyName": "patient.name",
        "title": intl.t('model.event.patient'),
        disableSorting: true
      }, {
        "propertyName": "route",
        "title": "Route",
        disableSorting: true
      }, {
        "propertyName": "eventName",
        "title": intl.t('model.event.eventName')
      }, {
        "propertyName": "sessionId",
        "title": "Session Id",
        disableSorting: true,
        isHidden: true
      }, {
        "template": "components/internal/event-tracer-table/data-column",
        "title": "Event data",
        isHidden: true,
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        this.set("data", this.get("store").query("analytics-event", { sort: "-eventDate", page: { number: 1, limit: 25 }, include: "patient" }));
      }
    }
  });
});