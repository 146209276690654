define('frontend/mixins/error-printer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    printError: function printError(reason, model) {
      var intl = this.get('intl');
      if (reason.errors) {
        var result = "The following errors were encountered:";

        if (Ember.isArray(reason.errors)) {
          reason.errors.forEach(function (error) {
            var text = error.detail;

            if (text.length > 200) {
              text = text.substr(0, 200);
            }

            if (model) {
              var intlString = 'model.' + model + '.errors.' + error.detail;

              if (intl.exists(intlString)) {
                text = intl.t(intlString);
              }
            }

            result += ' ' + text;
          });
        } else {
          result += reason.errors;
        }
        return result;
      } else {
        return reason;
      }
    }
  });
});