define('frontend/pods/components/provider/assessment/report-v2/badges/alliance-risk/component', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    messageKey: 'therapy',
    showPercentileRisk: Ember.computed.alias('isRiskPresent'),
    isBadgeShown: Ember.computed.alias('activeReport.includesComputedAlliance'),

    riskValue: Ember.computed("activeReport.computedValues.[]", "activeReport", "reportSettings.currentPopulation", function () {
      var popId = this.get('reportSettings.currentPopulation.id');
      var activeReport = this.get('activeReport');
      if (!activeReport) {
        return 0;
      }
      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');

      if (!computedValuesByScale['alliance']) {
        return;
      }

      return computedValuesByScale['alliance'].find(function (cv) {
        return cv.get('domain') === 'normed' && cv.get('population.id') === popId;
      });
    }),

    isRiskPresent: Ember.computed("riskValue", function () {
      return this.get('riskValue.value') <= -1;
    }),

    riskPercentile: Ember.computed("riskValue", function () {
      return 100 - Math.round(_analytics.default.GetZPercent(this.get('riskValue.value')) * 100);
    }),

    riskPercentileString: Ember.computed("riskPercentile", function () {
      return _analytics.default.ordinalSuffix(this.get('riskPercentile'));
    })
  });
});