define('frontend/pods/provider/admin/payors/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('sessionUser').hasPermission(['editExperiments'])) {
        return Ember.RSVP.reject('Permission denied');
      }
    }
  });
});