define('frontend/pods/components/scale-creation/sub-list/component', ['exports', 'npm:papaparse'], function (exports, _npmPapaparse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    collection: Ember.computed("model", "property", function () {
      return this.get('model.' + this.get('property'));
    }),

    pastePlaceholder: Ember.computed("columns.[]", function () {
      return this.get("columns").mapBy('property').join(",");
    }),

    actions: {
      addRow: function addRow() {
        var newObj = Ember.Object.create();
        this.get("columns").forEach(function (column) {
          var value = null;

          switch (_typeof(column.defaultValue)) {
            case "undefined":
              break;
            case "function":
              value = column.defaultValue();
              break;
            default:
              value = column.defaultValue;
          }

          newObj.set(column.property, value);
        });

        this.get("collection").addObject(newObj);
      },
      deleteRow: function deleteRow(row) {
        this.get("collection").removeObject(row);
      },
      beginPaste: function beginPaste() {
        this.set("showPaste", true);
      },
      processPaste: function processPaste() {
        var rawContent = this.get("pasteContent");

        var csv = _npmPapaparse.default.parse(rawContent, { header: true });

        this.get("collection").setObjects(csv.data);

        this.setProperties({ pasteContent: null, showPaste: false });
      },
      cancelPaste: function cancelPaste() {
        this.setProperties({ pasteContent: null, showPaste: false });
      }
    }
  });
});