define('frontend/pods/components/survey/taking-local/section-loading/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isHidden: false,
    actions: {
      transitionFromLeft: function transitionFromLeft() {},
      transitionFromRight: function transitionFromRight() {}
    }
  });
});