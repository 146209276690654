define('frontend/pods/components/provider/assessment/invitation/return-screen/component', ['exports', 'frontend/mixins/provider-assessment-redirect'], function (exports, _providerAssessmentRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_providerAssessmentRedirect.default, {
    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var invitation = this.get("invitation");

      if (invitation && this.get("status") === 'complete') {
        invitation.statusEvent = 'assessment_complete';
        invitation.save().then(function () {
          _this.get("actions.returnFromAssessment").call(_this);
        });
      }
    }
  });
});