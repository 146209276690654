define('frontend/models/organization', ['exports', 'frontend/models/application', 'ember-data', 'frontend/models/entity'], function (exports, _application, _emberData, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    shortname: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    providers: _emberData.default.hasMany("provider", { inverse: 'organizations' }),
    institute: _emberData.default.belongsTo("institute"),
    children: _emberData.default.hasMany("organization", { inverse: 'parent' }),
    parent: _emberData.default.belongsTo("organization", { inverse: 'children' }),
    subtree: _emberData.default.hasMany("organization", { inverse: null }),
    careEpisodes: _emberData.default.hasMany("careEpisode"),
    entityType: 'organization',
    hasAppointmentIntegration: _emberData.default.attr('boolean'),
    identifiers: _emberData.default.attr(),

    rootPath: Ember.computed('id', function () {
      return 'organization/' + this.get('id');
    }),

    toEntity: function toEntity() {
      return (0, _entity.fromModel)(this, this.get('store'));
    }
  });
});