define('frontend/pods/provider/patients/show/coverages/create/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var institutePayors = this.store.query('institutePayor', { page: { limit: 300 }, include: 'payor' });
      var patient = this.modelFor('provider.patients.show');
      return Ember.RSVP.hash({
        coverage: this.store.createRecord('billing/coverage', { patient: patient, isPrimary: true }),
        institutePayors: institutePayors
      });
    }
  });
});