define('frontend/routes/application', ['exports', 'frontend/mixins/application-route-session', 'frontend/pods/base-route', 'frontend/locales/map'], function (exports, _applicationRouteSession, _baseRoute, _map) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _baseRoute.default.extend(_applicationRouteSession.default, {
    queryParams: {
      shortcode: {},
      locale: {}
    },

    session: Ember.inject.service('session'),
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    metrics: Ember.inject.service(),
    intl: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this._subscribeToSessionEvents();
      this._setUserContext();
    },
    _setUserContext: function _setUserContext() {
      this.get('sessionUser.currentUser');
    },
    sessionInvalidated: function sessionInvalidated() {
      this._deleteAllWorkflows();
      this._super.apply(this, arguments);
    },
    _deleteAllWorkflows: function _deleteAllWorkflows() {
      this.get('store').findAll('workflow').then(function (record) {
        record.content.forEach(function (rec) {
          Ember.run.once(this, function () {
            if (rec) {
              rec.deleteRecord();
              rec.save();
            }
          });
        }, this);
      });
    },


    // This allows us to whitelabel everything. When we check the current institute,
    // We load various translations etc to customize.
    beforeModel: function beforeModel(params) {
      var _this = this;

      Object.keys(_map.default).forEach(function (t) {
        _this.get('intl').addTranslations(t, _map.default[t]);
      });

      if (params.queryParams.shortcode) {
        this.set("institute.shortcode", params.queryParams.shortcode);
      }

      if (params.queryParams.locale) {
        this.get('intl').setLocale([params.queryParams.locale, "en"]);
      } else {
        var currentLocale = this.get('intl.locale');
        if (Array.isArray(currentLocale) && currentLocale[currentLocale.length - 1] != "en") {
          this.get('intl').setLocale([].concat(_toConsumableArray(currentLocale), ["en"]));
        } else if (!Array.isArray(currentLocale) && currentLocale !== undefined) {
          this.get('intl').setLocale([currentLocale, "en"]);
        }
      }

      var setupInstitute = function setupInstitute() {
        return _this.get('institute.current').then(function () {
          _this._ensureMetricsContext();
          _this._setInitialLocale();
          _this._sendBrowserInfo();
        });
      };

      // The validateSession promise _always_ resolves
      return this.get('sessionUser').validateSession().then(setupInstitute);
    },
    _ensureMetricsContext: function _ensureMetricsContext() {
      this.set('metrics.context.userId', this.get("session.data.authenticated.id"));

      var activeRoleType = this.get("session.data.authenticated.active_role_type");
      var activeRoleId = this.get("session.data.authenticated.active_role_id");

      switch (activeRoleType) {
        case "provider":
          this.set("metrics.context.providerId", activeRoleId);
          break;
        case "patient":
          this.set("metrics.context.patientId", activeRoleId);
          break;
        case "related_person":
          this.set("metrics.context.relatedPersonId", activeRoleId);
          break;
      }
    },
    _sendBrowserInfo: function _sendBrowserInfo() {
      var params = {
        event: 'browserInfo',
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        userAgent: navigator.userAgent
      };

      if (screen) {
        params.screenWidth = screen.width;
        params.screenHeight = screen.height;
      }

      this.get('metrics').trackEvent(params);
    },
    _setInitialLocale: function _setInitialLocale() {
      var language = this.get("session.data.authenticated.language");

      // Set the correct default language for the user.
      if (language) {
        this.get('intl').setLocale([language || 'en', "en"]);
      }
    }
  });
});