define('frontend/pods/components/common/configuration/edit-form/configuration-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['configuration-row'],

    value: Ember.computed('configObject', 'item', {
      get: function get() {
        var config = this.get('configObject');

        var existing = config ? Ember.get(config, this.get('item')) : undefined;

        if (typeof existing === 'undefined') {
          return this.get('itemConfig.default');
        } else {
          return existing;
        }
      },
      set: function set(_key, value) {
        return Ember.set(this.get('configObject'), this.get('item'), value);
      }
    }),
    specified: Ember.computed('value', function () {
      var config = this.get('configObject');

      if (config) {
        return typeof Ember.get(this.get('configObject'), this.get('item')) !== 'undefined';
      } else {
        return false;
      }
    }),

    actions: {
      toggleProperty: function toggleProperty() {
        this.toggleProperty("value");
      },
      removeDefault: function removeDefault() {
        this.set('value', this.get('itemConfig.default'));
        this.notifyPropertyChange('value');
      },
      resetToDefault: function resetToDefault() {
        var config = this.get('configObject');
        delete config[this.get('item')];
        this.notifyPropertyChange('value');
      }
    }
  });
});