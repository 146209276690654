define('frontend/mixins/computed-value-type', ['exports', 'frontend/utils/assessment/analytics', 'frontend/utils/assessment/peabody', 'frontend/utils/assessment/cams', 'frontend/utils/assessment/dsm'], function (exports, _analytics, _peabody, _cams, _dsm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    notRespondedTo: Ember.computed('unansweredQuestions', 'totalQuestions', function () {
      return this.get('unansweredQuestions') === this.get('totalQuestions');
    }),

    //has questions that were shown but not answeered
    hasUnansweredQuestions: Ember.computed('declinedAnswers', function () {
      return this.get('declinedQuestions') > 0;
    }),

    // compensates for NORSE values' implied mnemonics
    adaptedMnemonic: Ember.computed("thresholdMnemonic", 'value', function () {
      if (!this.get('thresholdMnemonic') && this.get('scale.measureType') === 'norse') {
        return _analytics.default.getSeverityMnemonic(this.get('value'), this.get("scale"));
      }
      return this.get('thresholdMnemonic');
    }),

    isAbnormal: Ember.computed('isNormal', 'isUnclassified', 'thresholdClass', function () {
      var abnormalClasses = ['moderate', 'high', 'severe', 'mild'];
      return abnormalClasses.indexOf(this.get('thresholdClass')) >= 0;
    }),

    isNormal: Ember.computed('isAbnormal', function () {
      var normalClasses = ['low', 'none', 'typical'];
      return normalClasses.indexOf(this.get('thresholdClass')) >= 0;
    }),

    isUnclassified: Ember.computed('thresholdClass', function () {
      var unknownClasses = ['unknown'];
      var thresholdClass = this.get('thresholdClass');
      return !thresholdClass || unknownClasses.indexOf(thresholdClass) >= 0;
    }),

    answerMap: Ember.computed('updatedAt', 'answers.[]', 'scale', function () {
      var answers = this.get("answers");
      var answerMap = {};
      this.get('scale.allQuestionMnemonics').forEach(function (m) {
        answerMap[m] = answers.filter(function (a) {
          return a.get('question.shortname') === m;
        });
      });
      return answerMap;
    }),

    isNorse: Ember.computed("scale", function () {
      return this.get('scale.measureType') === 'norse';
    }),

    isNormedNorse: Ember.computed("scale", "isNorse", function () {
      return this.get('isNorse') && this.get('domain') === "normed";
    }),

    declinedAnswers: Ember.computed('answers.[]', function () {
      var answers = this.get('answers');
      return answers.filter(function (s) {
        return s && s.get('shownButNotAnswered');
      });
    }),

    severeAnswers: Ember.computed('answers.[]', 'scale', function () {
      var scale = this.get("scale");
      var answers = this.get('answers');

      // PROD-1289 Since we have updated the CAMS/NORSE we have started showing these results where previously
      // they were not. While we decide what to do, remove the NORSE answers
      if (scale.get("measureType") === "norse") {
        return [];
      }

      return answers.filter(function (s) {
        var severity = _analytics.default.getAnswerScoreClassNew(s, scale);
        return severity === 'severe' || severity === 'modsevere';
      });
    }),

    moderateAnswers: Ember.computed('answers.[]', 'scale', function () {
      var scale = this.get("scale");
      var answers = this.get('answers');

      // PROD-1289 Since we have updated the CAMS/NORSE we have started showing these results where previously
      // they were not. While we decide what to do, remove the NORSE answers
      if (scale.get("measureType") === "norse") {
        return [];
      }

      return answers.filter(function (s) {
        return _analytics.default.getAnswerScoreClassNew(s, scale) === 'moderate';
      });
    }),

    typicalAnswers: Ember.computed('answers.[]', function () {
      var scale = this.get("scale");
      var answers = this.get('answers');

      // PROD-1289 Since we have updated the CAMS/NORSE we have started showing these results where previously
      // they were not. While we decide what to do, remove the NORSE answers
      if (scale.get("measureType") === "norse") {
        return [];
      }

      return answers.filter(function (s) {
        var scoreClass = _analytics.default.getAnswerScoreClassNew(s, scale);
        return scoreClass === 'none' || scoreClass === 'low';
      });
    }),

    percentile: Ember.computed("value", function () {
      return _analytics.default.formatPercentile(_analytics.default.GetZPercent(this.get("value")));
    }),

    truncatedValue: Ember.computed("value", function () {
      var val = this.get('value');

      if (val === null || typeof val === 'undefined') {
        return;
      }

      if (val === Math.round(val)) {
        return val;
      }
      return val.toFixed(1);
    }),

    percentileWithSuffix: Ember.computed('percentile', function () {
      return _analytics.default.ordinalSuffix(Math.round(this.get('percentile') * 100) / 100);
    }),

    isPatientReported: Ember.computed(function () {
      //get('participant.participantType') doesn't work in some contexts, so we go lower-level
      return this.get('patient.user.id') === this.get('user.id');
    }),

    // returns false unless both values are valid and significantly different
    // note that this assumes both this value and the value you're passing in are
    // NORMED values of the same SCALE.
    isSignificantChangeFrom: function isSignificantChangeFrom(normedComputedValue) {
      if (!this.get('resultValid') || !normedComputedValue || !normedComputedValue.get('resultValid')) {
        return false;
      }
      if (this.get('measureType') === 'ptpb') {
        return _peabody.default.isSignificantChange(this, normedComputedValue);
      } else if (this.get('measureType') === 'dsm5') {
        return _dsm.default.isSignificantChange(this, normedComputedValue);
      } else {
        return _cams.default.isSignificantChange(this, normedComputedValue);
      }
    }
  });
});