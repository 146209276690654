define('frontend/pods/components/provider/reports/reimbursement-mock/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    glanceTimeOptions: ['Oct. 2017', 'Sept. 2017', 'Aug. 2017', 'Jul. 2017', 'Jun. 2017'],
    outcomeTypes: [{ value: 'overallFeeling', name: 'Severity' }, { value: 'phq9', name: 'PHQ-9' }],
    currentOutcome: { value: 'overallFeeling', name: 'Severity' },
    actions: {
      updateOutcomeType: function updateOutcomeType(outcomeType) {
        this.set('currentOutcome', _lodash.default.find(this.get('outcomeTypes'), function (outcome) {
          return outcome.value === outcomeType;
        }));
      }
    },

    reimbursementFraction: Ember.computed(function () {
      return { value: 0.86, key: 'reimbursement.fraction', className: 'good' };
    }),

    patientStatusIcon: {
      active: 'fa-calendar-o',
      unknown: 'fa-question-circle-o',
      discharged: 'fa-calendar-check-o'
    },

    meanPatient: {
      overallAdherence: { value: '73%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '5', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    medianPatient: {
      overallAdherence: { value: '80%', className: "good" },
      outcomes: {
        phq9: { initial: '19', final: '8', className: "good", arrowClass: 'good' },
        overallFeeling: { initial: '7', final: '5', className: "moderate", arrowClass: 'good' }
      }
    },

    payers: [{
      name: "Blue Cross / Blue Shield",
      measures: 645,
      patients: 145,
      claimsSent: 157,
      dollarsSent: 11034,
      claimsPaid: 97,
      claimsPaidClass: 'good',
      received: 10910
    }, {
      name: "Aetna",
      measures: 231,
      patients: 20,
      claimsSent: 52,
      dollarsSent: 670,
      claimsPaid: 90,
      claimsPaidClass: 'good',
      received: 640
    }, {
      name: "Humana",
      measures: 645,
      patients: 40,
      claimsSent: 0,
      dollarsSent: 0,
      claimsPaid: 0,
      claimsPaidClass: 'bad',
      received: 0
    }, {
      name: "Medicare",
      measures: 245,
      patients: 37,
      claimsSent: 46,
      dollarsSent: 670,
      claimsPaid: 91,
      claimsPaidClass: 'good',
      received: 700
    }, {
      name: "Medicaid",
      measures: 40,
      patients: 7,
      claimsSent: 7,
      dollarsSent: 200,
      claimsPaid: 80,
      claimsPaidClass: 'good',
      received: 200
    }]
  });
});