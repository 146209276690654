define('frontend/pods/components/provider/assessment/measure-now/component', ['exports', 'frontend/mixins/back-action-mixin', 'frontend/models/patient-session', 'frontend/mixins/rollback-mixin', 'frontend/mixins/error-printer'], function (exports, _backActionMixin, _patientSession, _rollbackMixin, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_backActionMixin.default, _rollbackMixin.default, _errorPrinter.default, {
    rollbackProperties: ['defaultCareEpisode', 'defaultSession', 'session'],

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('defaultSession')) {
        this.set('session', this.get('defaultSession'));
      } else if (this.get('defaultCareEpisode')) {
        this._createNewSession(this.get('defaultCareEpisode'));
      }
    },

    submitDisabled: Ember.computed("session", "session.validations.isInvalid", function () {
      return !this.get("session") || this.get("session.validations.isInvalid");
    }),

    defaultCareEpisode: Ember.computed('activeCareEpisodes.[]', function () {
      var episodes = this.get('activeCareEpisodes');
      if (!episodes || !episodes.get('length')) {
        //create a new one
        var defaultNewOrg = this.get("providerOrganizations.firstObject");
        var careEpisode = this.get("store").createRecord("careEpisode", { organization: defaultNewOrg, patient: this.get("patient") });
        return careEpisode;
      }
      //default to what was started most recently
      return episodes.sortBy('periodStart').objectAt(episodes.get('length') - 1);
    }),

    defaultSession: Ember.computed('defaultCareEpisode', 'activePatientSessions.[]', function () {
      //first default to the most recent active session
      var sessions = this.get('activePatientSessions');
      if (!sessions || !sessions.get('length')) {
        return null;
      } else {
        //default to the most recent
        return sessions.filter(function (s) {
          return s.get("isMutable") && moment(s.get("targetDate")).isSame(new Date(), "day");
        }).sortBy('targetDate').get("lastObject");
      }
    }),

    _createNewSession: function _createNewSession(careEpisode) {
      var store = this.get("store");
      var patient = this.get("patient");
      var newSession = _patientSession.Utils.create(store, patient, careEpisode, this.get('activeProvider'), true);

      this.set('defaultSession', newSession);
      this.set("session", newSession);
      return newSession;
    },


    actions: {
      submit: function submit() {
        var _this = this;

        // When the patient-session serializer is invoked, all the sub-resources are serialized at the same time and created in one go.
        // So we simply save this and let the serializer do the all sidecar stuff.

        // Setting this means that we stop all the downstreams hooks when we get the session saved back and it's now 'during' not 'before'
        this.set("isSaving", true);

        var onSuccess = function onSuccess(session) {
          if (_this.get("onSave")) {
            _this.get("onSave")(session);
          } else if (_this.get("onSaveRoute")) {
            _this.get("router").transitionTo(_this.get("onSaveRoute"), session);
          } else {
            _this.get("router").transitionTo('provider.patients.show.sessions.show', session.get("patient.id"), session);
          }
        };

        var onFailure = function onFailure(error) {
          _this.get('messages').danger(_this.printError(error));
          _this.set("isSaving", false);
        };

        var targetDate = moment(this.get("session.targetDate"));

        if (targetDate.isAfter(moment().startOf("day")) && targetDate.isBefore(moment().endOf("day"))) {
          this.set("session.statusEvent", "measure_now");
        }

        return this.get("session").save().then(onSuccess, onFailure);
      },
      sessionSelected: function sessionSelected(session) {
        this.set("session", session);
      },
      onCreateInvitation: function onCreateInvitation(invitation) {
        invitation.updateNotificationsToPreferences(true, this.get("session.schedulingAppointment.startDate"));
      }
    }
  });
});