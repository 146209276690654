define('frontend/pods/components/provider/assessment/report-v2/patient-goals/component', ['exports', 'frontend/models/assessment/goal'], function (exports, _goal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // colors picked to be as far outside the red-orange-yellow-green of severity colors
  var colorCycle = ['#1f77b4', '#00bbbb', '#338888', '#6688bb', '#113377', '#007777', '#0f3752', '#004444', '#415777', '#2e8f8f'];

  function transformGoalsData(goal, index, reportDate, hideDisabled) {
    return {
      goal: goal,
      answerData: goal.get('goalAnswers').filter(function (answer) {
        return answer.get("report.patientSession.targetDate") <= reportDate;
      }),
      isActive: goal.get('status') === 'active' ? 0 : 1,
      colorIndex: index,
      color: colorCycle[index % colorCycle.length],
      hidden: hideDisabled && goal.get('status') !== 'active'
    };
  }

  exports.default = Ember.Component.extend({
    activeCareEpisode: Ember.computed("patient", "activeReport.patientSession.careEpisode", function () {
      var active = this.get("activeReport.patientSession.careEpisode");

      if (active) {
        return active;
      }

      return this.get("patient.activeCareEpisodes.firstObject");
    }),

    goalsByTypeAndUser: Ember.computed('activeGoals.[]', 'activeReport', function () {
      var reportDate = this.get("activeReport.patientSession.targetDate");

      var hasAnyActive = this.get('activeGoals').any(function (goal) {
        return goal.get("status") === 'active';
      });

      return this.get('activeGoals').sortBy("createdAt").reduce(function (result, goal, index) {
        var goalType = goal.get('goalType');
        var userId = goal.belongsTo('user').id();

        if (!result[goalType]) {
          result[goalType] = { goalType: goalType, users: [] };
        }

        if (!result[goalType]['users'][userId]) {
          result[goalType]['users'][userId] = { user: goal.get('user'), hasData: false, goals: [], goalsWithAnswers: [] };
        }

        var transformed = transformGoalsData(goal, index, reportDate, hasAnyActive);

        result[goalType]['users'][userId]['goals'].addObject(transformed);

        if (transformed.answerData.length > 0) {
          result[goalType]['users'][userId]['hasData'] = true;
          result[goalType]['users'][userId]['goalsWithAnswers'].addObject(transformed);
        }

        return result;
      }, {});
    }),

    hasGoals: Ember.computed.gt("activeGoals.length", 0),

    didReceiveAttrs: function didReceiveAttrs() {
      // Not a computed property as we want to add new goals immediately.
      this.set("activeGoals", this.getWithDefault("goals", []).rejectBy('status', 'entered_in_error').toArray());
    },


    actions: {
      onNewGoalSave: function onNewGoalSave(goal) {
        this.toggleProperty("showCreateGoal");
        this.get("activeGoals").addObject(goal);
      },
      onGoalCancel: function onGoalCancel(goal) {
        this.get("activeGoals").removeObject(goal);
      },
      onNewGoalCancel: function onNewGoalCancel() {
        this.toggleProperty("showCreateGoal");
      },
      beginCreateGoal: function beginCreateGoal() {
        this.setProperties({
          showCreateGoal: true,
          blankGoal: _goal.Utils.create(this.get("store"), this.get("patient"), this.get("activeCareEpisode"))
        });
      }
    }
  });
});