define('frontend/pods/components/common/controls/primary-doctor-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'provider-select',
    excludeNonpracticing: true,

    shownProviders: Ember.computed('providers.[]', 'excludeNonpracticing', function () {
      if (!this.get("providers")) {
        var filterObj = this.get("excludeNonpracticing") ? { practicing: true, "users.disabled": false } : { "users.disabled": false };
        return this.get("store").query('provider', { filter: filterObj, page: { limit: 300 }, include: 'user', sort: "user.lastName" });
      } else {
        return this.get("excludeNonpracticing") ? this.get('providers').filterBy('practicing', true) : this.get('providers');
      }
    }),

    cid: "provider",
    property: "provider",
    labelKey: 'kiosk.patient.createPatient.primaryClinician',

    actions: {
      providerSelected: function providerSelected(provider) {
        Ember.set(this.get('model'), this.get('property'), provider);
      }
    }
  });
});