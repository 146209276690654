define('frontend/pods/components/provider/assessment/report-v2/symptom-box/component', ['exports', 'frontend/mixins/drawer-enabled'], function (exports, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['symptom-box'],
    showStdDev: false,

    actions: {
      showValues: function showValues(comparison) {
        this.set('currentComputedValue', comparison.get('latestValue'));
        this.send('drawer');
      }
    }
  });
});