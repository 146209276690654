define('frontend/pods/components/common/graphs/frequency-graph/component', ['exports', 'd3', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3'], function (exports, _d, _d3Support, _marginConvention, _d2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    opts: {
      maxValue: 10,
      minValue: 1,
      leftAxisMargin: 20,
      leftLabelMargin: 110,
      textMargin: 40,
      isHorizontal: false
    },
    xLabel: '',
    yLabel: '',
    xLabelRotate: false,
    classNames: ['bar-chart'],
    defaultMargin: 5,
    intl: Ember.inject.service(),
    requiredProperties: ['model'],
    //contentWidth: 400,

    graphData: Ember.computed('model', function () {
      return this.transformData(this.get('model'));
    }),

    xScale: Ember.computed('contentWidth', 'graphData', function () {
      var width = this.get('contentWidth');
      var x = _d.default.scale.ordinal().rangeBands([this.get('opts.leftAxisMargin') + this.get('opts.leftLabelMargin'), width]);
      x.domain(this.get('graphData').mapBy('label'));
      return x;
    }).readOnly(),

    // The yscale has 2 parts: the 'good' side is compressed to take up 1/3, and 2/3rds go to the 'bad' side
    yScale: Ember.computed('contentHeight', function () {
      var height = this.get('contentHeight');

      var y = _d.default.scale.linear().range([0, height - this.get('opts.textMargin') * 2]).clamp(true);
      y.domain([this.get('maxFreqValue'), this.get('minFreqValue')]);

      return y;
    }).readOnly(),

    maxFreqValue: Ember.computed('graphData', function () {
      return Math.max.apply(null, this.get('graphData').mapBy('value'));
    }),

    minFreqValue: Ember.computed('graphData', function () {
      return Math.min(0, Math.min.apply(null, this.get('graphData').mapBy('value')));
    }),

    axes: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var axis = selection.append("g").classed("axis", true);

        axis.append("g").attr("class", "baraxis x");

        axis.append("g").attr("class", "xLabel");

        axis.append("g").attr("class", "baraxis y");

        axis.append("g").attr("class", "yLabel");
      },
      update: function update(selection) {
        var intl = this.get('intl');
        var opts = this.opts;
        var component = this;
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var height = this.get('contentHeight');
        var data = this.get('graphData');
        this.axisSelection = selection;

        var xAxis = _d.default.svg.axis().scale(xScale).ticks(data.length).orient('bottom');

        var labelText = selection.selectAll('g.baraxis.x').attr("transform", "translate(0," + yScale(0) + ")").call(xAxis).selectAll("text").style("text-anchor", this.get('xTickRotate') ? 'end' : 'middle').attr("class", "barlabel").text("").attr('transform', this.get('xTickRotate') ? 'rotate(-30)' : '').attr("dx", "-5px");

        labelText.append('tspan').text(function (d) {
          // TODO: we only support labelling on this component for old-school hard coded scales
          return component.get('xTickTranslate') ? intl.t('provider.scales.' + d + '.shortname') : d;
        });

        selection.selectAll('g.xLabel').attr("transform", "translate(" + (xScale(data[0].label) + this.get('graphWidth') / 2 - 50) + "," + (height - opts.textMargin) + ")").append('text').append('tspan').style("text-anchor", 'middle').text(this.get('xLabel'));

        //x axis
        var yAxis = _d.default.svg.axis().scale(yScale).ticks(2) //opts.maxValue - opts.minValue)
        .orient("left");

        var yLabelText = selection.selectAll('g.baraxis.y').attr("transform", "translate(" + xScale(data[0].label) + ",0)").call(yAxis).selectAll("text").attr("class", "barlabel").text("");

        yLabelText.append('tspan').text(function (d) {
          return d;
        });

        selection.selectAll('g.yLabel').attr("transform", "translate(20," + this.get('graphHeight') / 2 + ")").append('text').append('tspan').text(this.get('yLabel'));
      }
    }),

    graphWidth: Ember.computed('xScale', 'graphData', function () {
      var xScale = this.get("xScale");
      var data = this.get('graphData');
      return xScale(data[data.length - 1].label) - xScale(data[0].label);
    }),

    graphHeight: Ember.computed('yScale', 'graphData', function () {
      var yScale = this.get("yScale");
      return yScale(0) - yScale(this.get('maxFreqValue'));
    }),

    bars: (0, _d2.join)([0], 'g.dotGraph', {
      enter: function enter(selection) {
        selection.append("g").classed("dotGraph", true);
      },
      update: function update(selection) {
        //draw the bars and attach event handlers
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var data = this.get('graphData');

        var centerDistance = this.get('graphWidth') / data.length / 2;

        var symptomLines = selection.selectAll(".freqLine").data(data);

        symptomLines.enter().append("rect").attr('class', function (d) {
          return "freqBar " + d.displayClass;
        });

        symptomLines.transition().duration(250).attr("x", function (d) {
          return xScale(d.label);
        }).attr("width", centerDistance * 2 - 4).attr("y", function (d) {
          return d.value > 0 ? yScale(d.value) : yScale(0);
        }).attr("height", function (d) {
          return d.value > 0 ? yScale(0) - yScale(d.value) : yScale(d.value) - yScale(0);
        });
        symptomLines.exit().remove();
      }
    }),

    //if raw data points are gven, map to frequencies
    transformData: function transformData(data) {
      return data;
    },

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');

      this.get("svg.defs").selection.each(function () {
        //nothing for now
      });

      selection.each(function () {
        var selection = _d.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;

        //context.gradients(selection);
        context.axes(selection);
        context.bars(selection);
      });
    }
  });
});