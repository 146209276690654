define('frontend/serializers/patient', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      lastAssessmentReport: { serialize: false },
      role: { serialize: false },
      institute: { serialize: false },
      defaultScales: { serialize: false },
      currentStats: { serialize: false },
      clientParticipants: { serialize: false },
      relationship: { serialize: false },
      patientStat: { serialize: false },
      patientFlags: { serialize: false },
      allowsEmail: { serialize: false },
      allowsText: { serialize: false },
      activeCareEpisodes: { serialize: false },
      demographics: { serialize: false }
    }
  });
});