define("frontend/pods/components/related-person/assessment/active-assessments/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isUpToDate: Ember.computed.equal("invitations.length", 0),
    showList: Ember.computed.gt("invitations.length", 1),
    activeInvitation: Ember.computed.alias("invitations.firstObject")
  });
});