define('frontend/pods/components/reporting/columns/classified-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'percent-bar',
    attributeBindings: ['percentBarStyle:style', 'barTitle:title'],

    goodCount: Ember.computed("column.dataMapping.good", "row", function () {
      return this.get("row")[this.get("column.dataMapping.good")];
    }),

    mediumCount: Ember.computed("column.dataMapping.medium", "row", function () {
      var mapping = this.get("column.dataMapping.medium");

      if (mapping) {
        return this.get("row")[mapping];
      } else {
        return 0;
      }
    }),

    badCount: Ember.computed("column.dataMapping.bad", "row", function () {
      return this.get("row")[this.get("column.dataMapping.bad")];
    }),

    hasData: Ember.computed("goodCount", "mediumCount", "badCount", function () {
      var _getProperties = this.getProperties('goodCount', 'mediumCount', 'badCount'),
          goodCount = _getProperties.goodCount,
          mediumCount = _getProperties.mediumCount,
          badCount = _getProperties.badCount;

      return goodCount + mediumCount + badCount > 0;
    }),

    percentBarStyle: Ember.computed("goodCount", "mediumCount", "badCount", function () {
      var _getProperties2 = this.getProperties('goodCount', 'mediumCount', 'badCount'),
          goodCount = _getProperties2.goodCount,
          mediumCount = _getProperties2.mediumCount,
          badCount = _getProperties2.badCount;

      var total = goodCount + mediumCount + badCount;

      if (total === 0) {
        return Ember.String.htmlSafe('');
      }

      var firstBreak = Math.round(goodCount / total * 100);
      var secondBreak = Math.round((goodCount + mediumCount) / total * 100);

      return Ember.String.htmlSafe('background:linear-gradient(to right, #379392, #379392 ' + firstBreak + '%, #babfd4 ' + firstBreak + '%, #babfd4 ' + secondBreak + '%, #fff ' + secondBreak + '%)');
    }),

    barTitle: Ember.computed('column', 'goodCount', 'mediumCount', 'badCount', function () {
      var _get = this.get('column.dataMapping'),
          goodTitle = _get.goodTitle,
          mediumTitle = _get.mediumTitle,
          badTitle = _get.badTitle;

      var _getProperties3 = this.getProperties('goodCount', 'mediumCount', 'badCount'),
          goodCount = _getProperties3.goodCount,
          mediumCount = _getProperties3.mediumCount,
          badCount = _getProperties3.badCount;

      return [goodTitle + ': ' + goodCount, mediumTitle + ': ' + mediumCount, badTitle + ': ' + badCount].join("\n");
    })
  });
});