define('frontend/pods/components/survey/taking-local/contact-details-modal/component', ['exports', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'ember-rapid-forms/mixins/input-errors'], function (exports, _emberCpValidations, _validationHelper, _inputErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationOptions = {
    email: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'phone' })
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  exports.default = Ember.Component.extend({
    tagName: '',
    sessionUser: Ember.inject.service(),
    isLoading: Ember.computed.not('sessionUser.currentUser.isFulfilled'),
    // Instead of making changers on the user model directly, we make changes to a smaller object to allow us to:
    //  a) easily ignore edits that might otherwise stay dirty later
    //  b) allow us to do the checkboxes for email/phone correctly.
    model: Ember.computed('sessionUser.currentUser.id', function () {
      var user = this.get('sessionUser.currentUser');

      var notificationPreference = user.get('notificationPreferenceWithDefault');

      var emailChecked = ['both', 'email'].includes(notificationPreference);
      var textChecked = ['both', 'text'].includes(notificationPreference);

      return Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        email: user.get('email'),
        phoneNumber: user.get('phoneNumber'),
        allowEmail: emailChecked,
        allowText: textChecked
      }).create(Ember.getOwner(this).ownerInjection());
    }),

    _getPreferenceFromCheckboxes: function _getPreferenceFromCheckboxes() {
      var _get$getProperties = this.get("model").getProperties('allowEmail', 'allowText'),
          allowEmail = _get$getProperties.allowEmail,
          allowText = _get$getProperties.allowText;

      if (allowEmail && allowText) {
        return 'both';
      } else if (allowEmail) {
        return 'email';
      } else if (allowText) {
        return 'text';
      } else {
        return 'none';
      }
    },
    _updatePreferences: function _updatePreferences() {
      var _this = this;

      var user = this.get('sessionUser.currentUser');

      // Awkward hack for sometimes getting a promise object and sometimes getting an object.
      if (user.content) {
        user = user.content;
      }

      user.setProperties({
        email: this.get('model.email'),
        phoneNumber: this.get('model.phoneNumber'),
        notificationPreference: this._getPreferenceFromCheckboxes()
      });

      var onSuccess = function onSuccess() {
        _this.get('onClose')();
      };

      var onFailure = function onFailure() {
        // For now, just close the modal if something breaks. We'll get a notification on sentry.
        _this.get('onClose')();
      };

      return user.save().then(onSuccess, onFailure);
    },


    actions: {
      saveAndClose: function saveAndClose() {
        this._updatePreferences();
      },
      cancelAndClose: function cancelAndClose() {
        this.get('onClose')();
      },
      emailStartEditing: function emailStartEditing() {
        this.set("model.allowEmail", true);
      },
      textStartEditing: function textStartEditing() {
        this.set("model.allowText", true);
      }
    }
  });
});