define('frontend/pods/provider/plan/entities/show/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),

    model: function model(params) {
      return this.get('store').findRecord('care/entityMeasurementPlan', params.entity_plan_id, { include: 'entity-tree-node.collections,measurement-plan.measurement-plan-scales' });
    },


    breadCrumb: null
  });
});