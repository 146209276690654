define('frontend/controllers/application', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    messages: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    noHipaa: _environment.default.noHipaa,

    actions: {
      invalidateSession: function invalidateSession() {
        this.get("metrics").trackEvent({ event: "user.logout", reason: "userLogout" });
        this.get('sessionUser').logout();
      }
    }

  });
});