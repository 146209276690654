define("frontend/pods/components/provider/care-episodes/care-episode-details/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionQuery: Ember.computed("careEpisode", function () {
      var careEpisode = this.get("careEpisode");
      return Ember.Object.create({
        store: careEpisode,
        modelName: "patientSessions",
        query: { include: "assessment-report,scheduling-appointment" }
      });
    }),
    actions: {
      onDischarge: function onDischarge() /*careEpisode*/{
        // When we discharge, it'll update the care plans and sessions, and we need to refetch them from the server.
        this.propertyDidChange("carePlanQuery");
        this.propertyDidChange("sessionQuery");
      }
    },
    settingsUrlService: Ember.inject.service("settings-url"),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var url = window.location.origin + "/app/settings" + window.location.pathname;
      this.get("settingsUrlService").setUrl(url);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get("settingsUrlService").clearUrl();
    }
  });
});