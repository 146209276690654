define('frontend/pods/components/survey/taking-local/common/questions/full-slider-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, {
    createResponsesAutomatically: true, // Because the slider has a default, we always automatically create an item
    inputAsNumber: Ember.computed('responseItem.value', {
      get: function get() {
        var asString = this.get("responseItem.value");
        return asString ? parseInt(asString) : null;
      },
      set: function set(_key, value) {
        var responseItem = this.get("responseItem");

        if (!responseItem) {
          responseItem = this._prepareForNewValue();
        }

        return responseItem.set('value', value);
      }
    }),

    actions: {
      valueChanged: function valueChanged() {
        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    }
  });
});