define('frontend/models/assessment/alter-report-scales-request', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    alterPlan: _emberData.default.attr('boolean'),
    patientSession: _emberData.default.belongsTo('patientSession', { inverse: null }),
    scalesToAdd: _emberData.default.hasMany('assessment/scale', { inverse: null }),
    scalesToRemove: _emberData.default.hasMany('assessment/scale', { inverse: null }),
    alteredSessions: _emberData.default.hasMany('patientSession', { inverse: null })
  });
});