define('frontend/initializers/ios-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  // iOS currently has two problems for material bootstrap checkbox:
  //   1) It registers the first click as an attempt to hover. So double clicks are needed.
  //   2) It does not register clicks on other elements inside the label as label clicks.
  //      For us, this means the span that's added does not click.
  //
  // This hack fixes both problems by detecting the presence of iOS and automatically sending
  // the event down to the correct input.
  // If it is enabled in any other context, the input will receive a double press and will
  // not change state.
  function initialize() /* application */{
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS) {
      document.arrive('.checkbox > label > span.checkbox-material', function (selector) {
        $(selector).on('click', function () {
          $(selector).siblings().filter('input').click();
        });
      });
    }
  }

  exports.default = {
    name: 'ios-checkbox',
    initialize: initialize
  };
});