define('frontend/models/scheduling/appointment', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    patient: _emberData.default.belongsTo("patient", { async: true }),
    doctor: _emberData.default.belongsTo("user", { async: true }),
    patientSession: _emberData.default.belongsTo("patientSession", { async: true, inverse: "schedulingAppointment" }),

    status: _emberData.default.attr("string"),

    description: _emberData.default.attr("string"),
    comment: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("date", {
      defaultValue: function defaultValue() {
        var date = new Date();
        date.setMinutes(Math.floor(date.getMinutes() / 15) * 15);
        return date;
      }
    }),
    endDate: _emberData.default.attr("date"),
    minutesDuration: _emberData.default.attr("number", { defaultValue: 60 }),

    source: _emberData.default.attr('string'),

    // All these fields are currently unused
    reason: _emberData.default.attr("string", { readOnly: true }),
    appointment_type: _emberData.default.attr("string", { readOnly: true }),
    priority: _emberData.default.attr("string", { readOnly: true })
  });
});