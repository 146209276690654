define('frontend/pods/components/scale-creation/entity-subset/component', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _updateOptions: function _updateOptions() {
      this.notifyPropertyChange('entityOptions');
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set("listenerUuid", (0, _uuidHelpers.default)());
      this.get("registry").listenForChange(this.get("listenerUuid"), function () {
        _this._updateOptions();
      }, this.get("registryType"));
    },

    classNames: 'horizontal-select',
    // Needs to be triggered for external changes in models.
    selected: Ember.computed('entityOptions.[]', function () {
      var selectedKeys = Ember.get(this.get('model'), this.get('property'));
      var options = this.get("entityOptions");

      if (!selectedKeys) {
        return [];
      }

      return options.filter(function (option) {
        return selectedKeys.indexOf(option.key) >= 0;
      });
    }),
    entityOptions: Ember.computed(function () {
      var registry = this.get("registry");

      return Object.entries(registry.optionsForType(this.get('registryType'))).map(function (entry) {
        return {
          key: entry[0],
          value: entry[1]
        };
      });
    }),
    actions: {
      updateSelected: function updateSelected(options) {
        Ember.set(this.get('model'), this.get('property'), options.mapBy('key'));
        this.notifyPropertyChange("selected");
        if (this.get('onUpdate')) {
          this.get('onUpdate')();
        }
      }
    },

    willDestroyElement: function willDestroyElement() {
      this.get("registry").stopListeningForChange(this.get("listenerUuid"), this.get("registryType"));
    }
  });
});