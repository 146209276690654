define('frontend/models/reporting/drilldown-report-settings', ['exports', 'frontend/utils/date-range-presets'], function (exports, _dateRangePresets) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    defaultLimit: Ember.computed.alias('reportParams.limit'),
    limit: Ember.computed.oneWay('reportParams.limit'),
    path: Ember.computed.oneWay('reportParams.path'),
    reportRequestData: Ember.computed('limit', 'path', 'breakdown', 'reportDefinition.shortcode', 'reportParams.preset', 'reportParams.startTime', 'reportParams.endTime', 'reportParams.breakdown', 'reportParams.scaleScorer', 'reportParams.flagIds.[]', 'reportParams.instituteConditionIds.[]', 'reportParams.sort', 'reportParams.sortDirection', 'reportParams.comparisonStartTime', 'reportParams.comparisonEndTime', 'reportParams.comparisonPreset', function () {
      var shortcode = this.get('reportDefinition.backendShortcode') || this.get('reportDefinition.shortcode');
      var requestData = {
        shortcode: shortcode,
        providerId: this.get('reportParams.provider.id'),
        organizationId: this.get('reportParams.organization.id'),
        sort: this.get('reportParams.sort'),
        sortDirection: this.get('reportParams.sortDirection'),
        limit: this.get('limit'),
        path: this.get('path'),
        comparisonStartDate: this.get('reportParams.comparisonStartTime'),
        comparisonEndDate: this.get('reportParams.comparisonEndTime'),
        comparisonPreset: this.get('reportParams.comparisonPreset')
      };

      if (this.get("reportParams.flagIds.length") > 0) {
        requestData['flagIds'] = this.get('reportParams.flagIds');
      }

      if (this.get("reportParams.instituteConditionIds.length") > 0) {
        requestData['instituteConditionIds'] = this.get('reportParams.instituteConditionIds');
      }

      if (this.get('breakdown')) {
        requestData.breakdown = this.get('breakdown');
      } else if (this.get('reportParams.breakdown') !== 'none') {
        requestData.breakdown = this.get('reportParams.breakdown');
      }

      if (this.get('reportParams.scaleScorer.id')) {
        requestData.scaleScorerId = this.get('reportParams.scaleScorer.id');
      }

      var preset = this.get('reportParams.preset');
      if (this.get('reportDefinition.hasDateRange') && preset !== 'allTime') {
        requestData.startDate = this.get('reportParams.startTime');
        requestData.endDate = this.get('reportParams.endTime');

        if (!(requestData.startDate && requestData.endDate)) {
          if (!preset) {
            preset = this.get('reportDefinition.defaultPreset');
          }
          if (preset === 'custom') {
            // Don't fetch the report if the required start and end date haven't been set!
            return;
          } else {
            // Something weird with routing prevents this from happening the usual way.
            // TODO refactor routing/filter handling to prevent this issue
            var dates = (0, _dateRangePresets.default)(preset);
            requestData.startDate = dates.startTime;
            requestData.endDate = dates.endTime;
          }
        }
      } else {
        requestData.preset = 'allTime';
      }

      return requestData;
    }),

    forCollection: function forCollection(collection) {
      return this.constructor.create({
        reportParams: this.get("reportParams"),
        reportDefinition: this.get("reportDefinition"),
        path: collection.get('path')
      });
    }
  });
});