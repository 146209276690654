define('frontend/pods/provider/exit-to-patient/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: null,
    model: function model(params) {
      return {
        userId: params.user_id
      };
    }
  });
});