define('frontend/mixins/survey/question-component-mixin', ['exports', 'frontend/mixins/survey-metric'], function (exports, _surveyMetric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_surveyMetric.default, {
    tagName: '',

    // Modes can be 'normal', used in survey taking, or 'bulk' which is used for bulk editing responses.
    mode: 'normal',

    nextQuestionDelay: 300, //ms after answering until the transition to the next question should start

    createResponsesAutomatically: true,

    showNavigation: Ember.computed.equal('mode', 'normal'),
    navigateOnSubmit: Ember.computed.equal('mode', 'normal'),
    saveOnSubmit: Ember.computed.equal('mode', 'normal'),
    trackTime: Ember.computed.equal('mode', 'normal'),

    responseItem: Ember.computed('responseSet.responseItems.@each.active', function () {
      return this.getWithDefault('responseSet.responseItems', []).findBy('active', true);
    }),

    questionActiveChanged: Ember.observer("currentlyActive", function () {
      if (this.get("currentlyActive")) {
        // This is a balance - we can't do it at the end of the run loop as it's possible to answer the question before
        // that hits, but we leave ourselves open to weird ordering things by calling it immediately.
        // Required to prevent multiple triggers
        this._beginShowQuestion();
      } else {
        this._endShowQuestion();
      }
    }),

    // Note this currently only works for single-valued questions. Multi-valued questions should implement their own.
    // Two way binding components such as text should also not use it.
    currentValue: Ember.computed("responseItem.value", function () {
      return this.get("responseItem.value");
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get("currentlyActive")) {
        this._beginShowQuestion();
      } else {
        this._createResponseSet(this.get("mode") === 'normal', false);
      }
    },


    actions: {
      submitAnswer: function submitAnswer() {
        this.set('responseSet.simpleValue', this.get('responseItem.value'));

        if (this.get("trackTime")) {
          this.get('responseItem').stopTrackingTime();
          this.set('responseItem.responseTracking', this.get('response').getCurrentTracking());
          this.trackSurveyMetric({ event: "survey.questionAnswered", questionMnemonic: this.get('question').shortname });
        }
        if (this.get("saveOnSubmit")) {
          this._saveAnswer();
        }
        if (this.get("navigateOnSubmit")) {
          Ember.run.later(this, this.sendAction, 'next', this.get('nextQuestionDelay'));
        }

        if (this.get("onChange")) {
          this.get("onChange")(this.get('responseItem'));
        }
      },
      skip: function skip() {
        if (this.get("trackTime")) {
          this.get('responseItem').stopTrackingTime();
          this.trackSurveyMetric({ event: "survey.questionSkipped", questionId: this.get('question').id });
        }

        if (this.get("navigateOnSubmit")) {
          this.sendAction('next');
        }

        if (this.get("saveOnSubmit")) {
          this._saveAnswer();
        }
      }
    },

    _prepareForNewValue: function _prepareForNewValue() {
      // If we're in bulk mode, override the old answer
      return this._createResponseSet(true, this.get("mode") === 'bulk');
    },
    _createResponseSet: function _createResponseSet(createItemIfMissing, overrideItemIfOld) {
      return this._createResponseSetForQuestion(this.get('question'), this.get('module'), createItemIfMissing, overrideItemIfOld);
    },


    // Logic here is slightly complicated:
    //  - if we're in bulk mode, then we don't create a new response item until an item is clicked, to preserve questions
    //    that are not answered. But then, when we click, we need to create a new overridden answer. Multiple clicks
    //    do not create any further new items.
    //  - in normal mode, we just ensure that the response item is present.
    _createResponseSetForQuestion: function _createResponseSetForQuestion(question, module, createItemIfMissing, overrideItemIfOld) {
      //as soon as a question is shown, we create a response item
      var responseSet = this.get('response').getResponseSetForQuestion(question, module);

      var lastItem = responseSet.responseItems.findBy('active');
      var lastItemIsOld = lastItem && !lastItem.get("isNew");
      var responseItem = null;

      if (this.get("createResponsesAutomatically") && createItemIfMissing && !lastItem || lastItemIsOld && overrideItemIfOld) {
        responseItem = this.store.createRecord('survey/response-item', {
          response: this.get('response'),
          questionId: question.id,
          questionMnemonic: question.shortname,
          questionDomain: question.questionDomain || module.question_domain,
          value: null
        });

        if (lastItem) {
          lastItem.set("overridingItem", responseItem);
        }

        responseSet.responseItems.addObject(responseItem);
      } else {
        responseItem = lastItem;
      }

      this.set('responseSet', responseSet);

      return responseItem;
    },
    _beginShowQuestion: function _beginShowQuestion() {
      if (this.get("trackTime")) {
        var responseItem = this._prepareForNewValue();
        // Start tracking the new question
        if (responseItem) {
          responseItem.startTrackingTime();
        }
      }
    },
    _endShowQuestion: function _endShowQuestion() {
      if (this.get("trackTime")) {
        var responseItem = this._prepareForNewValue();
        // Start tracking the new question
        if (responseItem) {
          responseItem.stopTrackingTime();
        }
      }
    },
    _saveAnswer: function _saveAnswer() {
      if (!this.get("previewMode")) {
        this.get('responseItem').saveWithDebounce();
      }
    }
  });
});