define('frontend/pods/provider/patients/show/sessions/show/route', ['exports', 'frontend/mixins/metrics-mixin', 'moment', 'frontend/pods/base-route'], function (exports, _metricsMixin, _moment, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    breadCrumb: {},
    model: function model(params) {
      // We probably already got a copy of the session, but it won't have got all the includes. If you don't force reload the model,
      // it'll start spinning off a load of requests for sub resources. This has the downside of not cached versions of the page.
      var include = "scheduling-appointment,scheduling-recurrence,assessment-report.report-scales.scale-participants,assessment-report.invitations.participant,assessment-report.invitations.user,care-episode,assessment-report.invitations.invitation-notifications";
      return this.store.findRecord('patientSession', params.patient_session_id, { include: include, reload: true });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: (0, _moment.default)(model.get('targetDate')).format("MMMM Do"), linkable: true, isRaw: true, crumbClass: 'root-crumb' });
    }
  });
});