define('frontend/pods/components/common/alert-list/message-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['alert-message-container'],
    classNameBindings: ['insertState'],
    insertState: 'pre-insert',
    service: Ember.inject.service("messages"),

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (!this.isDestroyed) {
        this.set('insertState', 'inserted');
      }

      if (this.get('service.timeout')) {
        Ember.run.later(function () {
          if (!_this.isDestroyed) {
            _this.set('insertState', 'destroyed');
            if (!Ember.testing) {
              _this.get('service').removeObject(_this.get('message'));
            }
          }
        }, this.get('service.timeout'));
      }
    },
    click: function click() {
      this.set('insertState', 'destroyed');
    },
    didRender: function didRender() {
      var isScrolled = $(window).scrollTop();
      if (isScrolled) {
        $('html, body').animate({ scrollTop: 0 });
      }
    }
  });
});