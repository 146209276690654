define("frontend/helpers/common/page-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    pageHeader: Ember.inject.service(),

    compute: function compute(params, _ref) {
      var title = _ref.title,
          description = _ref.description,
          visible = _ref.visible,
          className = _ref.className;

      if (visible !== false) {
        visible = true;
      }

      this.set("pageHeader.title", title);
      this.set("pageHeader.description", description);
      this.set("pageHeader.visible", visible);
      this.set("pageHeader.className", className);

      return "";
    }
  });
});