define("frontend/pods/components/common/bread-crumb/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: "",

    hasBlock: Ember.computed.bool("template").readOnly(),

    //if raw, return title as is, otherwise try to translate, otherwise return raw
    title: Ember.computed("route.title", "route.isRaw", function () {
      if (this.get("route.isRaw")) {
        return this.get("route.title");
      }
      var intlPath = this.get("route.path").split(".").map(function (s) {
        return s.camelize();
      }).join(".") + ".crumb";

      var title = this.get("intl").exists(intlPath) ? this.get("intl").t(intlPath) : this.get("route.title");

      return title;
    })
  });
});