define('frontend/services/provider/task-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sessionUser: Ember.inject.service(),
    store: Ember.inject.service(),
    _pendingInvitations: undefined,

    //whenever an action is taken that may impact a user's number of pending tasks, this method should be call to make sure the user's list is up-to-date 
    refreshTaskCount: function refreshTaskCount() {
      this.pendingInvitations(true);
    },


    //return sessions waiting on forms from the current user
    pendingInvitations: function pendingInvitations(forceReload) {
      if (!this.get('pendingInvitations') || forceReload) {
        var user = this.get('sessionUser.currentUser');
        if (user) {
          var pendingInvitations = this.get('store').query('assessment/invitation', { include: 'report.patient-session', filter: { pending_for_current_user: true } });
          this.set('_pendingInvitations', pendingInvitations);
        }
      }
      return this.get('_pendingInvitations');
    }
  });
});