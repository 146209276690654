define('frontend/pods/components/provider/assessment/scale-info-drawer/component', ['exports', 'frontend/mixins/drawer-enabled'], function (exports, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ScaleDrawerMixin = undefined;


  var FocusedScale = Ember.Object.extend({
    scale: null,
    showActions: false
  });

  var ScaleDrawerMixin = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('focusedScale')) {
        this.setProperties({ focusedScale: FocusedScale.create(), makeScaleDrawer: true });
      }
    }
  });

  exports.ScaleDrawerMixin = ScaleDrawerMixin;
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    metricEventName: 'provider.assessment.scaleInfoOpened',
    overlayClass: '.site-overlay.overlay-level-2',
    scaleSelectedObserver: Ember.observer('focusedScale.scale', function () {
      if (this.get('focusedScale.scale')) {
        this.send('drawer', null, this.get('overlayClass'));
      }
    }),

    _resetScaleInfo: function _resetScaleInfo() {
      this.get('focusedScale').setProperties({
        scale: null,
        selected: null,
        onScaleChange: null
      });
    },


    actions: {
      unfocusScale: function unfocusScale() {
        this.send('drawer', null, this.get('overlayClass'));
        this._resetScaleInfo();
      },
      onDrawerClose: function onDrawerClose() {
        this._resetScaleInfo();
      }
    }
  });
});