define('frontend/pods/patient/assessment/invitation/exit/route', ['exports', 'frontend/mixins/patient-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _patientRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_patientRouteMixin.default, _metricsMixin.default, {
    sessionUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model() {
      return this.modelFor("patient.assessment.invitation");
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Trigger the update to in progress
      model.set("statusEvent", 'assessment_opened');

      var assessmentUrl = model.get("assessmentUrl");

      var doRedirect = function doRedirect() {
        _this.get("metrics").trackEvent({ event: "patient.assessment.invitation.goToAssessment" });

        if (!assessmentUrl) {
          // In certain unit tests, this is only available here, rather than earlier. Not sure why, but this fixes it for now
          assessmentUrl = model.get("assessmentUrl");
        }

        var host = document.location.origin;
        var reflink = '' + host + _this.get("router").generate("patient.assessment.invitation.return", model);

        // Explicitly generate urls for our local survey capability which lets us transition quicker.
        if (assessmentUrl && assessmentUrl.indexOf('/') >= 0) {
          var remoteUrl = assessmentUrl + '&reflink=' + reflink;
          window.location.replace(remoteUrl);
        } else {
          //this is a local url -- it just has params
          var localUrl = _this.get("router").generate("survey.taking.patient.begin") + '?' + assessmentUrl + '&reflink=' + reflink;
          _this.get("router").transitionTo(localUrl);
        }
      };

      var handleError = function handleError(msg) {
        _this.get("metrics").trackEvent({ event: "patient.assessment.invitation.assessmentError", error: msg });
        Raven.captureException(msg);
        _this.get("router").transitionTo("patient.assessment.invitation.error");
      };

      switch (model.get('availability')) {
        case 'tooEarly':
          return this.get("router").transitionTo("patient.assessment.invitation.early");
        case 'tooLate':
          return this.get("router").transitionTo("patient.assessment.invitation.expired");
      }

      model.set('referrer', sessionStorage.getItem('referrer'));
      model.save({ adapterOptions: { fieldset: 'all' } }).then(doRedirect, handleError);
    }
  });
});