define('frontend/helpers/choice-answer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 4),
          value = _ref2[0],
          option = _ref2[1],
          question = _ref2[2],
          scale = _ref2[3];

      var raw = this.rawValue([value, option, question, scale]);

      var units = question.get("displayData.units");

      if (units) {
        return raw + ' ' + units;
      } else {
        return raw;
      }
    },
    rawValue: function rawValue(_ref3) {
      var _ref4 = _slicedToArray(_ref3, 4),
          value = _ref4[0],
          option = _ref4[1],
          question = _ref4[2],
          scale = _ref4[3];

      // If there's a hard coded transation, use it.
      var intl = this.get('intl');

      if (option && option.get("reportText")) {
        return option.get("reportText");
      }

      if (question.get("displayData.answer_value_map")) {
        var result = question.get("displayData.answer_value_map")[value];

        if (result) {
          return result;
        } else {
          console.warn('Missing answer map value for question ' + question.get('shortname'));
        }
      } else if (question.get("displayData.answer_value_definition")) {
        var map = scale.get('displayData.answer_value_maps.' + question.get("displayData.answer_value_definition"));
        if (map && map[value]) {
          return map[value];
        } else {
          console.warn('Missing answer map value for question ' + question.get('shortname'));
        }
      }

      //try to find scale-specific translation
      var translationKey = 'question.' + question.get("shortname") + '.option.' + value;
      //default to generic answer translation
      if (intl.exists(translationKey)) {
        return intl.t(translationKey);
      } else if (intl.exists('answer.' + value)) {
        return intl.t('answer.' + value);
      } else if (option && option.get("title")) {
        if (option.get('value') !== value) {
          // It's a write in
          return value;
        }
        return option.get("title");
      } else {
        return value;
      }
    }
  });
});