define('frontend/pods/provider/admin/payors/show/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model(params) {
      return this.store.findRecord('institutePayor', params.institute_payor_id, { include: 'billing-payor-contracts' });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('name'), linkable: true, isRaw: true, icon: 'fa-usd' });
    }
  });
});