define('frontend/pods/components/reporting/control-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showReportSelector: true,
    reports: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    reportsToShow: Ember.computed('report.allReports.[]', function () {
      return this.get('reports.allReports');
    }),

    actions: {
      selectReport: function selectReport(value) {
        this.sendAction('reportParamsChangeReport');
        this.get("router").transitionTo('provider.reports.show', value);
        // blur the select so that up/down arrow keypresses don't switch the report again
        $('.analytics__menu-select').blur();
      },
      setBreakdown: function setBreakdown(value) {
        this.set('reportParams.breakdown', value);
        this.sendAction('reportParamsRefresh');
      },
      setScale: function setScale(value) {
        this.set('reportParams.scale', value);
        this.sendAction('reportParamsRefresh');
      },
      setScaleScorer: function setScaleScorer(value) {
        this.set('reportParams.scaleScorer', value);
        this.sendAction('reportParamsRefresh');
      },
      setDateRange: function setDateRange(dateRange) {
        this.get('reportParams').setProperties(dateRange);
        this.sendAction('reportParamsRefresh');
      },
      setComparisonDates: function setComparisonDates(properties) {
        var transformed = { comparisonStartTime: properties.startTime, comparisonEndTime: properties.endTime, comparisonPreset: properties.preset };
        this.get('reportParams').setProperties(transformed);
        this.sendAction('reportParamsRefresh');
      },
      setProvider: function setProvider(value) {
        this.set('reportParams.provider', value);
        this.sendAction('reportParamsRefresh');
      },
      setOrganization: function setOrganization(value) {
        this.set('reportParams.organization', value);
        this.sendAction('reportParamsRefresh');
      },
      setFlags: function setFlags() {
        this.sendAction('reportParamsRefresh');
      },
      setConditions: function setConditions() {
        this.sendAction('reportParamsRefresh');
      },
      openPrint: function openPrint() {
        if (this.get("onPrint")) {
          this.get("onPrint")();
          Ember.run.later(function () {
            window.print();
          });
        } else {
          window.print();
        }
      }
    }
  });
});