define('frontend/pods/components/scale-creation/json-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._jsonToTextField();
    },
    _jsonToTextField: function _jsonToTextField() {
      var raw = Ember.get(this.get('model'), this.get('property'));

      if (raw) {
        this.set("jsonText", JSON.stringify(raw));
      } else {
        this.set("jsonText", null);
      }
    },

    actions: {
      updateJson: function updateJson() {
        try {
          var json = JSON.parse(this.get("jsonText"));
          Ember.set(this.get('model'), this.get('property'), json);
        } catch (err) {
          // TODO: validation
          Ember.set(this.get('model'), this.get('property'), null);
        }

        if (this.get("onUpdate")) {
          this.get("onUpdate")();
        }
      }
    }
  });
});