define('frontend/pods/components/provider/care-episodes/discharge-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    tagName: '',
    showButton: Ember.computed.equal('careEpisode.status', 'active'),
    actions: {
      toggleDischargeDialog: function toggleDischargeDialog() {
        this.toggleProperty('isShowingDischargeModal');
      },
      discharge: function discharge(careEpisode) {
        var _this = this;

        careEpisode.set("statusEvent", 'discharge');

        var onSuccess = function onSuccess(result) {
          _this.send('toggleDischargeDialog');
          _this.get("messages").success("Episode of care discharged");

          _this.sendAction("careEpisodeDischarged", result);
        };

        var onFailure = function onFailure() {
          _this.get("messages").danger("There was an error discharging the episode of care");
        };

        return careEpisode.save().then(onSuccess, onFailure);
      }
    }
  });
});