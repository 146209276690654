define('frontend/models/care/measurement-plan-scale', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeExact = exports.SchedulePresets = exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    scale: (0, _emberCpValidations.validator)('presence', true),
    measurementPlan: (0, _emberCpValidations.validator)('presence', true),
    schedulePreset: (0, _emberCpValidations.validator)('presence', true),
    // In theory it'd be nice to only have 2 validators here, but the dependent keys
    // functionality isn't working very well so rather than dig into that for hours, I'm 
    // comfortable just having 4 validators.
    // This in general does not provide a good user experience but given how infrequently it's used
    // it's good enough for now.
    discontinueSession: (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get("onsetSession")) {
        var onsetSession = parseInt(model.get("onsetSession"));
        var discontinueSession = parseInt(value);

        return discontinueSession > onsetSession ? true : "Discontinue session must be after onset session";
      } else {
        return true;
      }
    }, {}),
    onsetSession: (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get("discontinueSession")) {
        var discontinueSession = parseInt(model.get("discontinueSession"));
        var onsetSession = parseInt(value);

        return discontinueSession > onsetSession ? true : "Onset session must be before discontinue session";
      } else {
        return true;
      }
    }, {}),
    discontinueTime: (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get("onsetTime")) {
        return value > model.get("onsetTime") ? true : "Discontinue time must be greater than onset time";
      } else {
        return true;
      }
    }, {}),
    onsetTime: (0, _emberCpValidations.validator)(function (value, options, model) {
      if (value && model.get("discontinueSession")) {
        return model.get("discontinueTime") > value ? true : "Onset session must be before discontinue session";
      } else {
        return true;
      }
    }, {})
  });

  var customPreset = {
    id: 'custom',
    name: "Custom"
  };

  var SchedulePresets = Ember.A([{
    id: "onceOnly",
    name: "Once Only",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 0,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  },
  // {
  //   id: "intakeOnly",
  //   name: "Intake Only", // TODO: renable with the session type enabled.
  //   onsetSession: 1,
  //   onsetTime: null,
  //   repeatSessions: 0,
  //   maxRepeatInterval: null,
  //   discontinueSession: null,
  //   discontinueTime: null
  // },
  {
    id: 'everySession',
    name: "Every Session",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, {
    id: 'weekly',
    name: "Weekly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: 'P1W',
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, {
    id: 'everyOtherSession',
    name: "Every Other Session",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 2,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, {
    id: 'fortnightly',
    name: "Every 2 weeks",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: 'P2W',
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, {
    id: 'monthly',
    name: "Monthly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: 'P1M',
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, {
    id: 'quarterly',
    name: "Quarterly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: 'P3M',
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: 'care_episode_period_start'
  }, customPreset]);

  var humanizeExact = function humanizeExact(duration) {
    if (!duration.humanize) {
      Raven.captureException("Expecting a duration to be passed to humanizeExact but instead received " + duration);
      return duration;
    }

    if (duration.asMilliseconds() === 0) {
      return "immediately";
    }

    var dWas = moment.relativeTimeThreshold('d');
    var mWas = moment.relativeTimeThreshold('M');

    // Use internal values so we know if we were set at the day or month level.
    var days = duration._days;
    var months = duration._months;

    if (days) {
      if (days % 7 === 0) {
        if (days === 7) {
          return '1 week';
        } else {
          return days / 7 + ' weeks';
        }
      }
      moment.relativeTimeThreshold('d', 999999);
    }

    if (months) {
      moment.relativeTimeThreshold('M', 999999);
    }

    var result = duration.humanize();

    moment.relativeTimeThreshold('d', dWas);
    moment.relativeTimeThreshold('M', mWas);

    return result;
  };

  var isSameInterval = function isSameInterval(a, b) {
    if (a && b) {
      return moment.duration(a).asMilliseconds() === moment.duration(b).asMilliseconds();
    } else {
      return !a && !b;
    }
  };

  var stringIntCompare = function stringIntCompare(a, b) {
    if (!Ember.isEmpty(a) && !Ember.isEmpty(b)) {
      return a.toString() === b.toString();
    } else {
      return Ember.isEmpty(a) && Ember.isEmpty(b);
    }
  };

  exports.Validations = Validations;
  exports.SchedulePresets = SchedulePresets;
  exports.humanizeExact = humanizeExact;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    intl: Ember.inject.service(),
    scale: _emberData.default.belongsTo('assessment/scale'),
    measurementPlan: _emberData.default.belongsTo('care/measurementPlan'),

    onsetSession: _emberData.default.attr("number"),
    onsetTime: _emberData.default.attr("interval"),
    repeatSessions: _emberData.default.attr("number"),
    maxRepeatInterval: _emberData.default.attr("interval"),
    discontinueSession: _emberData.default.attr("number"),
    discontinueTime: _emberData.default.attr("interval"),
    alternationGroup: _emberData.default.attr('string'),
    alternationPriority: _emberData.default.attr('number'),
    blinding: _emberData.default.attr('string', { defaultValue: 'unblinded' }),
    displayOrder: _emberData.default.attr('number'),
    measureOnsetFrom: _emberData.default.attr('string'),
    conditionalLogic: _emberData.default.attr(),

    conditionalLogicText: Ember.computed('conditionalLogic', {
      get: function get() {
        if (!this.get('conditionalLogic')) {
          return null;
        }
        return JSON.stringify(this.get('conditionalLogic'), null, 2);
      },
      set: function set(key, value) {
        if (!value) {
          this.setProperties({ conditionalLogic: null });
          return null;
        }

        try {
          var json = JSON.parse(value);
          this.setProperties({ conditionalLogic: json });
        } catch (err) {
          console.error('Invalid JSON for conditional logic');
        }

        return value;
      }
    }),

    blinded: Ember.computed('blinding', function () {
      return this.get('blinding') == 'export_only';
    }),

    format: _emberData.default.attr('string'),
    phase: _emberData.default.attr('string'),

    fullDescription: Ember.computed('scale.translatedShortname', 'scheduleSummary', function () {
      return this.get('scale.translatedShortname') + ' - ' + this.get('scheduleSummary');
    }),

    administrationSummary: Ember.computed('scheduleSummary', 'formatWithScaleDefault', 'phaseWithScaleDefault', 'blinded', 'conditionalLogic', function () {
      var parts = [this.get('scheduleSummary') + ' - ' + this.get('phaseWithScaleDefault') + ' - ' + this.get('formatWithScaleDefault')];

      if (this.get('blinded')) {
        parts.push('(Blinded)');
      }

      if (this.get('conditionalLogic')) {
        parts.push('(Conditional)');
      }

      return parts.join(" ");
    }),

    formatWithScaleDefault: Ember.computed('scale', 'format', function () {
      var format = this.get("format") || this.get('scale.defaultFormat');
      var intl = this.get('intl');
      if (format) {
        return intl.t('model.invitation.formats.' + format);
      } else {
        return null;
      }
    }),

    phaseWithScaleDefault: Ember.computed('scale', 'phase', function () {
      var phase = this.get("phase") || this.get('scale.defaultPhase');
      var intl = this.get('intl');
      if (phase) {
        return intl.t('model.invitation.phases.' + phase);
      } else {
        return null;
      }
    }),

    measureOnsetFromText: Ember.computed("measureOnsetFrom", function () {
      var measureOnsetFrom = this.get("measureOnsetFrom");

      switch (measureOnsetFrom) {
        case 'next_calendar_quarter':
          return this.get('intl').t('provider.carePlans.scheduleSummary.measureOnsetFromNextCalendarQuarter');
        case 'care_episode_period_start':
        default:
          return this.get('intl').t('provider.carePlans.scheduleSummary.measureOnsetFromCareEpisodePeriodStart');
      }
    }),

    scheduleSummary: Ember.computed('schedulePreset', 'onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime', 'measureOnsetFromText', function () {
      // Output needs to be, e.g.:
      // "starting at the later of session 2 or 2 weeks after first session, repeating the longer of every 1 session or every 2 weeks"
      // "starting at session 1, every 2 sessions"
      // "starting 2 weeks after the first session, once only"

      var _getProperties = this.getProperties('schedulePreset', 'onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime', 'measureOnsetFromText'),
          schedulePreset = _getProperties.schedulePreset,
          onsetSession = _getProperties.onsetSession,
          onsetTime = _getProperties.onsetTime,
          repeatSessions = _getProperties.repeatSessions,
          maxRepeatInterval = _getProperties.maxRepeatInterval,
          discontinueSession = _getProperties.discontinueSession,
          discontinueTime = _getProperties.discontinueTime,
          measureOnsetFromText = _getProperties.measureOnsetFromText;

      if (!onsetSession && !onsetTime && repeatSessions === 0 && !maxRepeatInterval && !discontinueTime && !discontinueSession) {
        return this.get('intl').t('provider.carePlans.scheduleSummary.onceOnly');
      }

      if (schedulePreset && schedulePreset.id !== 'custom') {
        return schedulePreset.name;
      }

      var initial = null;

      if (onsetSession && onsetTime) {
        initial = this.get('intl').t('provider.carePlans.scheduleSummary.initialBoth', { onsetSession: onsetSession, onsetTime: humanizeExact(onsetTime), measureOnset: measureOnsetFromText });
      } else if (onsetSession) {
        initial = this.get('intl').t('provider.carePlans.scheduleSummary.initialSession', { onsetSession: onsetSession });
      } else if (onsetTime) {
        initial = this.get('intl').t('provider.carePlans.scheduleSummary.initialTime', { onsetTime: humanizeExact(onsetTime), measureOnset: measureOnsetFromText });
      }

      var repeat = null;

      if (repeatSessions === 0) {
        repeat = this.get('intl').t('provider.carePlans.scheduleSummary.onceOnly');
      } else if (repeatSessions && maxRepeatInterval) {
        repeat = this.get('intl').t('provider.carePlans.scheduleSummary.repeatBoth', { repeatSessions: repeatSessions, maxRepeatInterval: humanizeExact(maxRepeatInterval) });
      } else if (repeatSessions) {
        repeat = this.get('intl').t('provider.carePlans.scheduleSummary.repeatSession', { repeatSessions: repeatSessions });
      } else if (maxRepeatInterval) {
        repeat = this.get('intl').t('provider.carePlans.scheduleSummary.repeatTime', { maxRepeatInterval: humanizeExact(maxRepeatInterval) });
      } else {
        repeat = this.get('intl').t('provider.carePlans.scheduleSummary.everySession');
      }

      var ending = null;

      if (discontinueTime && discontinueSession) {
        ending = this.get('intl').t('provider.carePlans.scheduleSummary.endingBoth', { discontinueSession: discontinueSession, discontinueTime: humanizeExact(discontinueTime) });
      } else if (discontinueSession) {
        ending = this.get('intl').t('provider.carePlans.scheduleSummary.endingSession', { discontinueSession: discontinueSession });
      } else if (discontinueTime) {
        ending = this.get('intl').t('provider.carePlans.scheduleSummary.endingTime', { discontinueTime: humanizeExact(discontinueTime) });
      }

      return [initial, repeat, ending].compact().join(', ');
    }),

    schedulePreset: Ember.computed('onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime', 'measureOnsetFrom', {
      get: function get() {
        var _getProperties2 = this.getProperties('onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime', 'measureOnsetFrom'),
            onsetSession = _getProperties2.onsetSession,
            onsetTime = _getProperties2.onsetTime,
            repeatSessions = _getProperties2.repeatSessions,
            maxRepeatInterval = _getProperties2.maxRepeatInterval,
            discontinueSession = _getProperties2.discontinueSession,
            discontinueTime = _getProperties2.discontinueTime,
            measureOnsetFrom = _getProperties2.measureOnsetFrom;

        if (typeof onsetSession === 'undefined' && typeof onsetTime === 'undefined' && typeof repeatSessions === 'undefined' && typeof maxRepeatInterval === 'undefined' && typeof discontinueSession === 'undefined' && typeof discontinueTime === 'undefined') {
          return null;
        }

        var preset = SchedulePresets.find(function (preset) {
          return stringIntCompare(onsetSession, preset.onsetSession) && isSameInterval(onsetTime, preset.onsetTime) && stringIntCompare(repeatSessions, preset.repeatSessions) && isSameInterval(maxRepeatInterval, preset.maxRepeatInterval) && stringIntCompare(discontinueSession, preset.discontinueSession) && isSameInterval(discontinueTime, preset.discontinueTime) && measureOnsetFrom === preset.measureOnsetFrom;
        });

        if (preset) {
          return preset;
        } else {
          return customPreset;
        }
      },
      set: function set(key, value) {
        var row = value;

        if (typeof row === 'string') {
          row = SchedulePresets.findBy('id', value);
        }

        // If you get a custom object, don't reset all the fields, just leave them be.
        if (row && row.id && row.id === 'custom') {
          return row;
        }

        if (row) {
          this.set("onsetSession", row.onsetSession);
          this.set("onsetTime", row.onsetTime);
          this.set("repeatSessions", row.repeatSessions);
          this.set("maxRepeatInterval", row.maxRepeatInterval);
          this.set("discontinueSession", row.discontinueSession);
          this.set("discontinueTime", row.discontinueTime);
          this.set("measureOnsetFrom", row.measureOnsetFrom);

          return row;
        } else {
          this.set("onsetSession", null);
          this.set("onsetTime", null);
          this.set("repeatSessions", null);
          this.set("maxRepeatInterval", null);
          this.set("discontinueSession", null);
          this.set("discontinueTime", null);
          this.set("measureOnsetFrom", null);
          return null;
        }
      }
    })
  });
});