define('frontend/pods/components/provider/assessment/goals/edit-form/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/error-printer', 'frontend/models/assessment/goal'], function (exports, _rollbackMixin, _errorPrinter, _goal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, _rollbackMixin.default, {
    rollbackProperties: ['model'],
    goalTypeOptions: _goal.goalTypes,
    userOptions: Ember.computed.mapBy('model.patient.clientParticipants', 'user'),
    actions: {
      goalTypeSelected: function goalTypeSelected(goalType) {
        Ember.set(this.get('model'), 'goalType', goalType);
      },
      userSelected: function userSelected(user) {
        Ember.set(this.get('model'), 'user', user);
      },
      submit: function submit() {
        var _this = this;

        var onSuccess = function onSuccess(result) {
          _this.get("metrics").trackEvent({ event: "provider.patientGoal.saved" });
          if (_this.get("onSave")) {
            _this.get("onSave")(result);
          }
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason));
        };

        return this.get("model").save().then(onSuccess, onFailure);
      },
      cancel: function cancel() {
        if (this.get("onCancel")) {
          this.get("onCancel")();
        }
      }
    }
  });
});