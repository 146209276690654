define('frontend/pods/components/provider/patients/billing-coverage-form/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/models/billing/coverage'], function (exports, _rollbackMixin, _coverage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, {
    intl: Ember.inject.service(),
    rollbackProperties: ["model"],
    relationshipOptions: Ember.computed(function () {
      return (0, _coverage.RelationshipOptionBuilder)(this.get('intl'));
    }),
    actions: {
      submit: function submit() {
        var action = this.get('onSave');
        if (typeof action === 'function') {
          return action();
        } else {
          console.warn("No action function was passed");
        }
      },
      selectInstitutePayor: function selectInstitutePayor(payor) {
        this.get('model').set('institutePayor', payor);
      }
    }
  });
});