define('frontend/helpers/has-role', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    sessionUser: Ember.inject.service(),
    compute: function compute(params) {
      return this.get('sessionUser').hasRole(params[0]);
    }
  });
});