define('frontend/models/survey/response-item', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UniqueQuestionIdentifier = undefined;


  function UniqueQuestionIdentifier(questionDomain, questionMnemonic) {
    if (questionDomain) {
      return questionDomain + '#' + questionMnemonic;
    } else {
      return questionMnemonic;
    }
  }

  exports.UniqueQuestionIdentifier = UniqueQuestionIdentifier;
  exports.default = _application.default.extend({
    router: Ember.inject.service(),
    response: _emberData.default.belongsTo('survey/response'),
    value: _emberData.default.attr('string'),
    optionId: _emberData.default.attr('number'),
    questionId: _emberData.default.attr('string'),
    questionMnemonic: _emberData.default.attr('string'),
    questionDomain: _emberData.default.attr('string'),
    overridingItem: _emberData.default.belongsTo('survey/responseItem', { inverse: 'overriddenItems' }),
    overriddenItems: _emberData.default.hasMany('survey/responseItem', { inverse: 'overridingItem' }),
    overridden: _emberData.default.attr('boolean'),
    timeTracking: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    responseTracking: _emberData.default.attr({ defaultValue: null }),
    active: Ember.computed("isDeleted", "overridingItem.id", "overridden", function () {
      return !this.get("isDeleted") && !this.get("overridden") && !this.get("overridingItem.id");
    }),

    // Note that questionMnemonic is not unique, we need to do unique on the module as well.
    uniqueQuestionIdentifier: Ember.computed('questionDomain', 'questionMnemonic', function () {
      return UniqueQuestionIdentifier(this.get('questionDomain'), this.get('questionMnemonic'));
    }),

    startTrackingTime: function startTrackingTime() {
      if (!this.get("currentlyTrackingTime")) {
        this.get("timeTracking").push({ open: +new Date() });
        this.set("currentlyTrackingTime", true);
      }
    },
    stopTrackingTime: function stopTrackingTime() {
      this.set("currentlyTrackingTime", false);
      var timeTracking = this.get("timeTracking");

      if (timeTracking.length > 0) {
        var lastEntry = timeTracking[timeTracking.length - 1];
        lastEntry["close"] = +new Date();
        lastEntry["span"] = lastEntry["close"] - lastEntry["open"];
      }
    },

    // If the user is particularly quick and the server particularly slow, it's possible to send 2 requests before the
    // first has completed, which results in a duplicate key exception as it'll try to create the same record twice.
    // In order to prevent this, hook around the save call.
    saveWithDebounce: function saveWithDebounce() {
      if (this.get('isSaving') || this.get('isReloading')) {
        console.log('queueing save of item with mnemonic' + this.get('uniqueQuestionIdentifier'));
        return this.one('didUpdate', this, this.saveWithLog);
      } else {
        return this.saveWithLog();
      }
    },
    saveWithLog: function saveWithLog() {
      var _this = this;

      var value = this.get('value');
      var onSaveSuccess = function onSaveSuccess(item) {
        console.log('saved answer of (' + value + ') for question (' + item.get('uniqueQuestionIdentifier') + ')');
      };
      var onSaveFail = function onSaveFail(msg) {
        // For now, if the save fails, abort immediately and log such to sentry.
        Raven.captureException(msg);

        // This doesn't really belong here, but as you only get here in extenuating circumstances it's not quite
        // worth a large rearchitecture to make this one case nicer.
        _this.get('router').transitionTo("survey.error");
      };

      return this.save().then(onSaveSuccess, onSaveFail);
    },
    deleteRecordWithDebounce: function deleteRecordWithDebounce() {
      if (this.get('isSaving') || this.get('isReloading')) {
        return this.one('didUpdate', this, this.deleteRecord);
      } else {
        return this.deleteRecord();
      }
    }
  });
});