define('frontend/pods/components/scale-creation/scale-thresholds/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scoreClasses: ['unknown', 'none', 'low', 'mild', 'moderate', 'severe'],
    // scoreMnemonics: ['unknown', 'none', 'mild', 'moderate', 'severe'], For now, free text for interpretation.
    collection: Ember.computed("model", "property", function () {
      return this.get('model.' + this.get('property'));
    }),

    actions: {
      addRow: function addRow() {
        var collection = this.get("collection");
        if (!collection) {
          collection = this.get("model").set(this.get('property'), Ember.A());
          this.notifyPropertyChange('collection');
        }

        collection.addObject(Ember.Object.create({
          min_value: null,
          max_value: null,
          class: this.get('scoreClasses.firstObject'),
          mnemonic: null,
          graph_label: null
        }));
      },
      deleteRow: function deleteRow(row) {
        this.get("collection").removeObject(row);
      }
    }
  });
});