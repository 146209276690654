define("frontend/pods/components/user/confirm-identity/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    actions: {
      confirm: function confirm() {
        return this.get("onComplete")();
      },
      cancel: function cancel() {
        return this.get("sessionUser").logout();
      }
    }
  });
});