define('frontend/pods/components/provider-admin/users/create-user/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/error-printer'], function (exports, _rollbackMixin, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, _errorPrinter.default, {
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    rollbackProperties: ['provider.user', 'provider'],

    actions: {
      saveProvider: function saveProvider() {
        var _this = this;

        var success = function success(provider) {
          _this.get('messages').success("Provider created");
          _this.get("metrics").trackEvent({ event: "provider.admin.users.created" });

          _this.get("router").transitionTo("provider.admin.users.show", provider);
        };

        var failure = function failure(reason) {
          _this.get('messages').danger(_this.printError(reason, "provider"));
        };

        return this.get("provider").save().then(success).catch(failure);
      }
    }
  });
});