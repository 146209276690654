define("frontend/adapters/care/recurring-session-request", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // We currently use a nested data structure to update all this information at once, but we then need to ensure that we include all the
    // side resources back on any request, or we're forced to do a manual pull later.
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=care-episode.patient-sessions";
      return value;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=care-episode.patient-sessions";
      return value;
    }
  });
});