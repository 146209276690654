define('frontend/pods/components/internal/scales/import-scale/component', ['exports', 'frontend/pods/components/internal/scales/edit-form/component', 'npm:papaparse', 'frontend/utils/survey/redcap-importer', 'frontend/models/assessment/scale-templates', 'frontend/models/assessment/raw-scale-datum'], function (exports, _component, _npmPapaparse, _redcapImporter, _scaleTemplates, _rawScaleDatum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function makeAdministrativeScale(mnemonic, name) {
    return {
      "_templateOptions": {
        "parameters": {},
        "templateId": "scale-v1"
      },
      "mnemonic": mnemonic,
      "name": name,
      "shortname": name,
      "independent": true,
      "measure_type": "custom",
      "allowed_participants": ["patient"],
      "default_participants": ["patient"],
      "role": "administrative",
      "subscale_selection": "all",
      "beneficial": false,
      "unadministrable": false,
      "scales": [],
      "subscales": [],
      "scorers": [{
        "params": {},
        "score_class_name": "Scoring::QuestionCountScorer",
        "_templateOptions": {
          "parameters": {},
          "templateId": "questionCount-v1"
        },
        "domain": "interpreted",
        "score_type": "unscored"
      }],
      "norms": []
    };
  }

  var ImportOptions = Ember.Object.extend({
    rawData: null,
    configuration: _redcapImporter.defaultConfiguration,
    fileLoaded: Ember.computed.notEmpty("rawScaleDatum"),
    import: function _import(datum, mode) {
      var rawData = this.get("rawData");
      var guessNormalizedScores = mode === 'full';
      _redcapImporter.default.create(Ember.getOwner(this).ownerInjection()).import(rawData, datum, this.get("configuration"), guessNormalizedScores);
      this.set("rawScaleDatum", datum);

      if (mode === 'administrative') {
        var registry = _rawScaleDatum.Registry.create();
        datum.set('parsedScaleData.scales', [_scaleTemplates.Scale.fromJson(makeAdministrativeScale(datum.get('mnemonic'), datum.get('mnemonic')), registry, Ember.getOwner(this))]);
      }
    }
  });

  exports.default = Ember.Component.extend({
    importer: Ember.computed(function () {
      return ImportOptions.create(Ember.getOwner(this).ownerInjection());
    }),
    modes: ['full', 'administrative'],
    mode: 'full',
    importDisabled: Ember.computed.empty('importer.rawData'),
    actions: {
      import: function _import() {
        var datum = this.get("store").createRecord('assessment/rawScaleDatum', { version: 1, status: 'draft' });
        this.get("importer").import(datum, this.get("mode"));
      },
      upload: function upload(event) {
        var _this = this;

        var file = event.target.files[0];
        _npmPapaparse.default.parse(file, {
          header: true,
          complete: function complete(results) {
            _this.set('importer.rawData', results);
          }
        });
      },
      createScale: function createScale() {
        var createController = Ember.getOwner(this).lookup('controller:internal/scales/create');
        createController.set("importedData", this.get('importer.rawScaleDatum'));
        this.get('router').transitionTo('internal.scales.create');
      },
      preview: function preview() {
        var mnemonic = this.get("importer.rawScaleDatum.mnemonic");
        var previewSurvey = this.get("importer.rawScaleDatum.parsedScaleData.surveys.firstObject").toJson();
        // Set the question domain directly as this is inferred from the raw scale datum
        previewSurvey.question_domain = mnemonic;
        var invite = this.get("store").createRecord('survey/invite', {
          sections: [{
            modules: [previewSurvey.mnemonic, 'previewFinish']
          }],
          preloadedModules: [previewSurvey, _component.previewFinish],
          moduleIds: ['ignored', 'previewFinish']
        });

        var previewModel = this.get("store").createRecord('survey/response', {
          invite: invite
        });
        this.setProperties({ isPreviewing: true, previewModel: previewModel });
      },
      closePreview: function closePreview() {
        this.set("isPreviewing", false);
        this.get("previewModel").deleteRecord();
        this.set("previewModel", null);
      }
    }
  });
});