define('frontend/pods/components/provider/assessment/report-v2/graph-with-symptom-details/component', ['exports', 'lodash', 'frontend/mixins/drawer-enabled'], function (exports, _lodash, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['symptoms-overview'],
    patientScaleTargets: Ember.computed.alias('patient.assessmentPatientScaleTargets'),
    displayMode: 'standard',
    screen: Ember.inject.service(),
    computerAdaptive: false,

    allReportData: Ember.computed("reports", "reportSettings.currentPopulation", "patientScaleTargets.[]", "reportSettings.compareReportTo", function () {
      var activeReport = this.get("activeReport");
      var reports = this.getWithDefault("reports", []);
      var component = this;

      var filtered = reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get('domain') === 'raw';
        });
      });

      var groups = _lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.mnemonic");
      });

      var groupWithScale = {};

      this.getWithDefault("scales", []).forEach(function (scale) {
        var scaleData = {
          items: groups[scale.get("mnemonic")] || [],
          scale: scale,
          isTargeted: component.get('patientScaleTargets').any(function (pst) {
            return pst.get('scale.id') === scale.get('id');
          })
        };

        var userIds = scaleData.items.mapBy('user.id').uniq();
        var byUser = _lodash.default.groupBy(scaleData.items, function (item) {
          return item.get('user.id');
        });
        scaleData.users = userIds.map(function (userId) {
          var items = byUser[userId];
          return {
            user: items[0].get("user"),
            items: items,
            scale: scale,
            isTargeted: scaleData.isTargeted,
            isCurrent: items.any(function (item) {
              return item.get("report.id") === activeReport.get("id");
            }),
            lastAdministrationDate: _lodash.default.max(items.mapBy('report.patientSession.targetDate'))
          };
        });

        groupWithScale[scale.get("mnemonic")] = scaleData;
      });

      return groupWithScale;
    }),

    scalesByBaseMnemonic: Ember.computed('scales', function () {
      return _lodash.default.groupBy(this.get("scales"), function (scale) {
        return scale.get('baseMnemonic');
      });
    }),

    sectionData: Ember.computed("scaleSections", "allReportData", function () {
      var allScaleData = this.getWithDefault("allReportData", {});
      var sections = this.get("scaleSections");

      var sectionHeaders = Object.keys(sections);
      var scalesByBaseMnemonic = this.get("scalesByBaseMnemonic");
      var data = {};

      sectionHeaders.forEach(function (header) {
        var config = sections[header];
        if (config.baseScales) {
          // Group all the scales by the base scale
          data[header] = config.baseScales.map(function (base) {
            return {
              baseMnemonic: base,
              scales: (scalesByBaseMnemonic[base] || []).map(function (scale) {
                return allScaleData[scale.get('mnemonic')];
              })
            };
          }).compact();
        } else {
          data[header] = config.scales.flatMap(function (scale) {
            if (typeof scale === 'string') {
              return [allScaleData[scale]];
            } else {
              return scale.scales.map(function (subscale) {
                return allScaleData[subscale];
              }).compact();
            }
          }).compact();
        }
      });

      return data;
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value, onDeselected) {
        this.sendAction('onValueSelect', value);

        if (this.get("onDeselected")) {
          this.get("onDeselected")();
        }
        this.set("onDeselected", onDeselected);

        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});