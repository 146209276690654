define('frontend/pods/components/reporting/drilldown-headline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'drilldown-headline',
    entityTreeNode: Ember.computed.alias('reportEntityTreeNode.entityTreeNode'),
    classNameBindings: ['isLoading:hide'],

    isLoading: Ember.computed.or('isFirstLoad', 'isRefreshing')
  });
});