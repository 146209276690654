define('frontend/pods/components/provider/assessment/scale-browser/list-component/choose-scale-column/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      onScaleChoice: function onScaleChoice() {
        this.get("actionHandler").apply(undefined, ['onScaleChange'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});