define("frontend/adapters/assessment/alter-report-scales-request", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=altered-sessions.assessment-report.invitations,altered-sessions.assessment-report.report-scales.scale-participants";
      return value;
    }
  });
});