define('frontend/pods/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service(),
    redirect: function redirect() {
      if (this.get('features.useProviderLoginAsHome')) {
        if (this.get('session.isAuthenticated')) {
          this.transitionTo('provider.assessment.dashboard');
        } else {
          this.transitionTo('provider.login');
        }
      } else {
        this.transitionTo('landing');
      }
    }
  });
});