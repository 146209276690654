define("frontend/mixins/executive-route-mixin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    authenticationRoute: "executive.login",
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      sessionStorage.setItem("authenticationType", "executive");
      if (!this.get('session.isAuthenticated')) {
        transition.abort();
        this.set('session.attemptedTransition', transition);
        this.transitionTo(this.get('authenticationRoute'));
      } else if (this.get('session.data.authenticated.user_type') === 'internal') {
        return this._super.apply(this, arguments);
      } else if (this.get('session.data.authenticated.user_type') !== 'provider') {
        // Silently log out, then hard redirect to the url given.
        transition.abort();
        this.get("sessionUser").logout();
      } else if (this.getWithDefault('session.data.authenticated.roles', []).indexOf("executive") < 0) {
        // TODO: make this better configurable.
        // TODO: replace this with feature gated schedule? i don't think the executive role is used
        this.transitionTo('provider.assessment.dashboard');
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});