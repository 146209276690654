define("frontend/mixins/scheduler", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // Need:
    //condition on which to run the execute function - anything truthy will execute
    scheduleGuard: null,

    // frequency in ms to call the function
    scheduleFrequency: 3 * 60000,

    // The function to execute
    scheduleExecute: null,

    /**
     * @override: ember lifecycle
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.setupSchedule();
    },
    setupSchedule: function setupSchedule() {
      if (this.get("timerId")) {
        clearTimeout(this.get("timerId"));
      }

      var timerId = setInterval(this.executeSchedule.bind(this), this.get("scheduleFrequency"));

      this.set("timerId", timerId);
    },
    executeSchedule: function executeSchedule() {
      if (this.get("scheduleGuard")) {
        this.get("scheduleExecute").apply(this);
      }
    },


    /**
     * @override: ember lifecycle
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      clearTimeout(this.get("timerId"));
    }
  });
});