define('frontend/models/export/data-dump-download', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    dataDump: _emberData.default.belongsTo("export/dataDump"),
    downloadUrl: _emberData.default.attr("string")
  });
});