define('frontend/pods/survey/finished/route', ['exports', 'frontend/mixins/survey/finish-redirect', 'frontend/pods/base-route'], function (exports, _finishRedirect, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_finishRedirect.default, {
    actions: {
      willTransition: function willTransition(transition) {
        // Do not allow going back to the survey as it is already completed!
        if (transition.targetName.indexOf("survey") === 0) {
          transition.abort();
          // Keep the URL as it was.
          if (window.history) {
            window.history.forward();
          }

          return false;
        }
      }
    }
  });
});