define('frontend/pods/components/reporting/breakdown-selector/component', ['exports', 'frontend/mixins/fixed-modal-mixin'], function (exports, _fixedModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fixedModalMixin.default, {
    classNames: ['filter-selector'],
    currentBreakdown: Ember.computed('breakdown', function () {
      return this.get('breakdown') || 'none';
    }),

    allBreakdowns: Ember.computed('breakdowns', function () {
      return ['none'].concat(this.get('breakdowns'));
    }),

    actions: {
      setBreakdown: function setBreakdown(value) {
        var _this = this;

        this.sendAction("setBreakdown", value);
        Ember.run.once(function () {
          return _this.send('toggleModal');
        });
      }
    }
  });
});