define('frontend/models/consent-agreement', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    consent: _emberData.default.belongsTo('consent'),
    userConsents: _emberData.default.hasMany('userConsents'),
    outstandingUsers: _emberData.default.hasMany('user', { inverse: null }),
    status: _emberData.default.attr("string")
  });
});