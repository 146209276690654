define('frontend/pods/components/common/simple-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    showBlank: false,
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("options")) {
        this.set("options", []);
      }

      if (!this.get("itemKey")) {
        this.set("itemKey", "name");
      }

      if (this.get("isModel") === undefined) {
        this.set("isModel", true);
      }
    },

    currentValue: Ember.computed('model', 'property', function () {
      return this.get('model').get(this.get('property'));
    }),
    blankSelected: Ember.computed('currentValue', function () {
      return !this.get("currentValue");
    }),
    enableMetrics: true,
    metricEventName: 'unknown.selectAction',
    actions: {
      itemSelected: function itemSelected() {
        var changeAction = this.get('action');
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;

        var indexAdjust = this.get("showBlank") ? 1 : 0; // If there's a blank value, offset by one to account for it.
        var selectedValue = this.get("options").toArray()[selectedIndex - indexAdjust];
        var model = this.get("model");

        if (this.get("showBlank") && selectedIndex === 0) {
          Ember.set(model, this.get("property"), null);
        } else {
          Ember.set(model, this.get("property"), selectedValue);
        }

        var metricEventName = this.get("metricEventName");
        var value = selectedValue && selectedValue.get && selectedValue.get('id') ? selectedValue.get('id') : selectedValue;
        if (metricEventName && this.get("enableMetrics")) {
          this.get("metrics").trackEvent({ event: metricEventName, value: value });
        }

        this.notifyPropertyChange('currentValue');

        this.sendAction(changeAction || 'onChange', selectedValue);
      }
    }

  });
});