define('frontend/models/analytics-event', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    eventName: _emberData.default.attr("string"),
    eventDate: _emberData.default.attr("date"),
    eventUuid: _emberData.default.attr("string"),
    sessionId: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    route: _emberData.default.attr("string"),
    eventData: _emberData.default.attr(),

    patient: _emberData.default.belongsTo('patient'),
    user: _emberData.default.belongsTo('user'),
    institute: _emberData.default.belongsTo('institute'),

    isTest: _emberData.default.attr("boolean")
  });
});