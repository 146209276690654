define('frontend/pods/components/provider/patients/institute-condition-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),

    tableColumns: Ember.computed(function () {
      var intl = this.get('intl');

      return [{
        propertyName: 'instituteCondition.name',
        title: 'Diagnosis',
        disableSorting: true
      }, {
        propertyName: 'instituteCondition.code',
        title: 'Code',
        disableSorting: true
      }, {
        template: "components/provider/patients/institute-condition-list/active-dates",
        title: 'Active Dates'
      }, {
        title: intl.t('common.status'),
        propertyName: 'status'
      }];
    }),

    pageSize: 25,

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient && typeof patient.query === 'function') {
          var data = Ember.Object.create({
            store: this.get("patient"),
            modelName: 'patientConditions',
            query: { include: "institute-condition" }
          });
          this.set("data", data);
        }
      }
    }
  });
});