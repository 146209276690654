define("frontend/pods/components/provider-admin/configuration-tree/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: "entity-tree page__wide-content",
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    entityTreeNode: Ember.computed("path", function () {
      var path = this.get("path") || this.get("rootPath");
      return this.get("store").findRecord("entityTreeNode", path);
    }),

    allowEditActions: Ember.computed(function () {
      return this.get("sessionUser").hasPermission("editConfigurationValues");
    }),

    rowContext: Ember.computed("entityTreeNode", function () {
      return {
        entityTreeNode: this.get("entityTreeNode")
      };
    }),

    path: Ember.computed.oneWay("rootPath"),

    rootPath: Ember.computed("institute.current.id", function () {
      return "institute/" + this.get("institute.current.id");
    }),

    settingsUrlService: Ember.inject.service("settings-url"),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var url = window.location.origin + "/app/settings" + window.location.pathname;
      this.get("settingsUrlService").setUrl(url);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get("settingsUrlService").clearUrl();
    },
    _setEntityTreeNode: function _setEntityTreeNode(path) {
      var _this = this;

      this.get("store").findRecord("entityTreeNode", path).then(function (result) {
        _this.set("entityTreeNode", result);
      });
    },

    actions: {
      resetRoot: function resetRoot() {
        this.set("path", this.get("rootPath"));
        this._setEntityTreeNode(this.get("rootPath"));
      },
      pathSelected: function pathSelected(path) {
        if (!path) {
          path = this.get("rootPath");
        }

        this.set("path", path);
        this._setEntityTreeNode(path);
      },
      entityTreeNodeSelected: function entityTreeNodeSelected(entity) {
        entity = entity.content ? entity.content : entity;
        this.setProperties({ path: entity.get("path"), entityTreeNode: entity });
      }
    }
  });
});