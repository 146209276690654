define('frontend/pods/related-person/assessment/list/route', ['exports', 'frontend/mixins/related-person-route-mixin', 'frontend/pods/base-route'], function (exports, _relatedPersonRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_relatedPersonRouteMixin.default, {
    model: function model() {
      var measurementRequest = this.store.createRecord("assessment/measurementRequest");
      return measurementRequest.save().then(function (response) {
        if (response.get("invitation.id")) {
          return [response.get("invitation")];
        } else {
          return [];
        }
      });
    }
  });
});