define('frontend/models/demo-patient-request', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var validationOptions = {
    name: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('format', { allowBlank: true, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('international-phone-number', { allowBlank: true }),
    scaleSet: (0, _emberCpValidations.validator)('presence', true)
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);
  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    relatedPersonName: _emberData.default.attr('string'),
    relatedPersonEmail: _emberData.default.attr('string'),
    relatedPersonPhoneNumber: _emberData.default.attr('string'),
    teacherName: _emberData.default.attr('string'),
    teacherEmail: _emberData.default.attr('string'),
    teacherPhoneNumber: _emberData.default.attr('string'),
    organizationName: _emberData.default.attr('string'),
    welcomeMessage: _emberData.default.attr('string'),
    child: _emberData.default.attr('boolean'),
    scaleSet: _emberData.default.attr('string', { defaultValue: 'cams' }),

    // These fields are serialized on the way back and should not be set.
    patientSession: _emberData.default.belongsTo("patientSession"),
    patient: _emberData.default.belongsTo("patient")
  });
});