define('frontend/models/assessment/scale', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var languageToLocaleMap = {
    en: 'en_US',
    es: 'es_US'
  };

  function replaceNameDashesForChc(string) {
    // We can sometimes be handed a 'SafeString' from the i18n code.
    if (string.string) {
      return string.string.replace(/–|—/, '-');
    } else if (string.replace) {
      return string.replace(/–|—/, '-');
    } else {
      return '';
    }
  }

  // When in the context of a subscale display, you don't need to e.g. show 'Vanderbilt ADHD - Parent - Conduct Disorder',
  // as the 'Vanderbilt ADHD - Parent' is implicit.
  // This should probably become a real field but for now just hardcode for the scales we care about.
  var subscaleNameMap = {
    vanderbilt3_parent_baseline_anx_dep: 'Anxiety and Depression',
    vanderbilt3_parent_baseline_conduct: 'Conduct Disorder',
    vanderbilt3_parent_baseline_diagnosis: 'Diagnosis',
    vanderbilt3_parent_baseline_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_parent_baseline_inattentive: 'Inattentive',
    vanderbilt3_parent_baseline_opp_def: 'Oppositional Defiant Disorder',
    vanderbilt3_parent_fu_diagnosis: 'Diagnosis',
    vanderbilt3_parent_fu_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_parent_fu_inattentive: 'Inattentive',
    vanderbilt3_parent_fu_opp_def: 'Oppositional Defiant Disorder',
    vanderbilt3_parent_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_parent_inattentive: 'Inattentive',
    vanderbilt3_parent_opp_def: 'Oppositional Defiant Disorder',
    vanderbilt3_teacher_baseline_diagnosis: 'Diagnosis',
    vanderbilt3_teacher_baseline_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_teacher_baseline_inattentive: 'Inattentive',
    vanderbilt3_teacher_baseline_learning: 'Learning Disability',
    vanderbilt3_teacher_baseline_anx_dep: 'Anxiety and Depression',
    vanderbilt3_teacher_baseline_opp_def_conduct: 'Oppositional Defiant and Conduct Disorder',
    vanderbilt3_teacher_diagnosis: 'Diagnosis',
    vanderbilt3_teacher_fu_diagnosis: 'Diagnosis',
    vanderbilt3_teacher_fu_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_teacher_fu_inattentive: 'Inattentive',
    vanderbilt3_teacher_fu_opp_def_conduct: 'Oppositional Defiant and Conduct Disorder',
    vanderbilt3_teacher_hyperactive: 'Hyperactive-Impulsive',
    vanderbilt3_teacher_inattentive: 'Inattentive',
    vanderbilt3_teacher_opp_def_conduct: 'Oppositional Defiant and Conduct Disorder'
  };

  exports.default = _application.default.extend({
    intl: Ember.inject.service(),
    norms: _emberData.default.hasMany('assessment/norm', { async: true }),

    // Mnemonic is equal to 'fq_mnemonic' in the back end. There is enough
    // code relying on the uniqueness of mnemonic that this change is safer
    // than updating code throughout. Base mnemonic is equal to 'mnemonic' in
    // the back end and represents the base family of scale.
    mnemonic: _emberData.default.attr("string"),
    baseMnemonic: _emberData.default.attr("string"),

    disabled: _emberData.default.attr("boolean"),

    isBeneficial: _emberData.default.attr("boolean"),
    isClinicianFocused: _emberData.default.attr("boolean"),
    independent: _emberData.default.attr("boolean"),
    measureType: _emberData.default.attr("string"),
    thresholdData: _emberData.default.attr(""),
    deprecatedQuestions: _emberData.default.attr(),
    allQuestionMnemonics: _emberData.default.attr(),
    estimatedTime: _emberData.default.attr('number'),
    color: _emberData.default.attr("string"), //tracks what color is assigned a given scale for dispaly
    tags: _emberData.default.attr(""),
    defaultParticipants: _emberData.default.attr(),
    defaultPhase: _emberData.default.attr('string'),
    defaultFormat: _emberData.default.attr('string'),
    adminInterval: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    name: _emberData.default.attr("string"),
    shortname: _emberData.default.attr('string'),
    nanoname: _emberData.default.attr('string'),
    displayData: _emberData.default.attr(),
    gender: _emberData.default.attr('string'),
    minAge: _emberData.default.attr('number'),
    maxAge: _emberData.default.attr('number'),
    locale: _emberData.default.attr(),
    version: _emberData.default.attr('string'),
    subscaleSelection: _emberData.default.attr('string', { defaultValue: 'all' }),
    guideUrl: _emberData.default.attr('string'),
    scoringText: _emberData.default.attr('string'),
    surveyMnemonics: _emberData.default.attr(),
    billableUnits: _emberData.default.attr('number'),
    role: _emberData.default.attr('string'),

    hasNonPatientRespondents: Ember.computed('defaultParticipants', function () {
      return this.get('defaultParticipants') && (this.get('defaultParticipants').length > 1 || this.get('defaultParticipants').objectAt(0) !== 'patient');
    }),
    hasSubscales: Ember.computed.gt('children.length', 0),
    children: _emberData.default.hasMany("assessment/scale", { inverse: 'parent' }),
    parent: _emberData.default.belongsTo("assessment/scale", { inverse: 'children' }),
    subtree: _emberData.default.hasMany("assessment/scale", { inverse: null }),
    subtreeWithoutSelf: Ember.computed('subtree.[]', function () {
      return this.get("subtree").rejectBy('id', this.get('id'));
    }),

    instituteScales: _emberData.default.hasMany("assessment/institute-scale"),

    activeInstituteScale: _emberData.default.belongsTo("assessment/institute-scale"),

    instituteAllowsAdministration: Ember.computed("activeInstituteScale.disableAdministration", function () {
      var activeInstituteScale = this.get("activeInstituteScale");

      if (this.get("disabled") || !activeInstituteScale) {
        return false;
      }

      return !activeInstituteScale.get("disableAdministration");
    }),

    filteredThresholds: function filteredThresholds(domains, population) {
      var thresholdData = this.get('thresholdData');

      if (!thresholdData) {
        return [];
      }

      if (domains || population) {
        return thresholdData.filter(function (t) {
          if (!t.domain && !t.population) {
            return true;
          }

          var domainMatches = !t.domain || t.domain && domains.indexOf(t.domain) >= 0;
          var populationMatches = !t.population || t.population === population;
          return domainMatches && populationMatches;
        });
      } else {
        return thresholdData;
      }
    },
    maxValue: function maxValue(domains, population) {
      var displayData = this.get('displayData');
      if (displayData) {
        if (typeof displayData.maxValue === 'number') {
          return displayData.maxValue;
        }

        if (domains && domains[0]) {
          if (displayData[domains[0]] && typeof displayData[domains[0]]['maxValue'] === 'number') {
            return displayData[domains[0]]['maxValue'];
          }
        }
      }

      return Math.max.apply(null, this.get('filteredThresholds').call(this, domains, population).mapBy('max_value'));
    },
    minValue: function minValue(domains, population) {
      var displayData = this.get('displayData');
      if (displayData) {
        if (typeof displayData.minValue === 'number') {
          return displayData.minValue;
        }

        if (domains && domains[0]) {
          if (displayData[domains[0]] && typeof displayData[domains[0]]['minValue'] === 'number') {
            return displayData[domains[0]]['minValue'];
          }
        }
      }

      return Math.min.apply(null, this.get('filteredThresholds').call(this, domains, population).mapBy('min_value'));
    },

    isCore: Ember.computed('measureType', function () {
      var measureType = this.get('measureType');
      return measureType !== 'standard';
    }),
    isAdult: Ember.computed('tags', function () {
      var _getProperties = this.getProperties('maxAge', 'minAge'),
          maxAge = _getProperties.maxAge,
          minAge = _getProperties.minAge;

      return !maxAge && !minAge || maxAge && maxAge >= 18;
    }),
    isChild: Ember.computed('tags', function () {
      var _getProperties2 = this.getProperties('maxAge', 'minAge'),
          maxAge = _getProperties2.maxAge,
          minAge = _getProperties2.minAge;

      return !maxAge && !minAge || maxAge && maxAge <= 18;
    }),
    allQuestionsWithDeprecated: Ember.computed('allQuestionMnemonics', 'deprecatedQuestions', function () {
      return this.get('allQuestionMnemonics').concat(this.get('deprecatedQuestions'));
    }),
    // until we transition all the old scales to the DB mechanism, this is left hardcoded
    hasOldScoreMaps: Ember.computed('measureType', 'mnemonic', function () {
      return ['nsi', 'bscs'].indexOf(this.get('mnemonic')) >= 0;
    }),

    hasValidParticipants: function hasValidParticipants(participants) {
      var defaultParticipants = this.get("defaultParticipants");

      // provider scales are always valid.
      if (defaultParticipants.includes('provider')) {
        return true;
      }

      if (!participants) {
        return false;
      }

      var participantTypes = participants.mapBy('participantType').uniq();
      return !!participantTypes.find(function (t) {
        return defaultParticipants.indexOf(t) >= 0;
      });
    },


    // Temporary hack: if PTPB, don't administer if age is < 11.
    // TODO: 
    activeParticipantsFor: function activeParticipantsFor(patient) {
      if (this.get('mnemonic') === 'ptpb') {
        var age = Ember.get(patient, 'age');
        if (age && age < 11) {
          return ['guardian']; // Do not administer the PTPB self report for 
        }
        return this.get("defaultParticipants");
      } else {
        return this.get("defaultParticipants");
      }
    },


    translatedName: Ember.computed('name', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.name';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString, { htmlSafe: true });
      } else {
        return this.get("name");
      }
    }),

    translatedDescription: Ember.computed('description', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.description';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString, { htmlSafe: true });
      } else {
        return this.get("description");
      }
    }),

    translatedCategory: Ember.computed('category', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.category';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString, { htmlSafe: true });
      } else {
        return this.get("category");
      }
    }),

    translatedShortname: Ember.computed('shortname', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.shortname';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString, { htmlSafe: true });
      } else {
        return this.get("shortname");
      }
    }),

    // Note that we convert the intl string to text so it can be sorted. Use translatedShortname for displayable data.
    //  Also converts html character codes back to text
    translatedShortnameText: Ember.computed('shortname', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.shortname';

      if (this.get('intl').exists(intlString)) {
        var text = this.get('intl').t(intlString, { htmlSafe: true }).string;
        return $('<textarea />').html(text).text();
      } else {
        return this.get("shortname");
      }
    }),

    translatedCHCShortname: Ember.computed("translatedShortname", function () {
      return replaceNameDashesForChc(this.get('translatedShortname'));
    }),

    translatedNanoname: Ember.computed('nanoname', 'mnemonic', function () {
      var intlString = 'provider.scales.' + this.get('mnemonic') + '.nanoname';

      if (this.get('intl').exists(intlString)) {
        return this.get('intl').t(intlString, { htmlSafe: true });
      } else {
        return this.get("nanoname");
      }
    }),
    nanonameWithFallback: Ember.computed('translatedShortname', 'translatedNanoname', 'translatedName', function () {
      if (this.get("translatedNanoname")) {
        return this.get("translatedNanoname");
      }

      if (this.get("translatedShortname")) {
        return this.get("translatedShortname");
      }

      return this.get("translatedName");
    }),

    // For the text synopsis, we don't always want to use the name used in the rest of the application as it might not
    // make sense, e.g. you don't necessarily need to know the exact variant of the scale. If there's an override, use it.
    translatedSynopsisName: Ember.computed('translatedName', 'displayData.synopsis.name', function () {
      var synopsisOverride = this.get("displayData.synopsis.name");

      if (synopsisOverride) {
        return synopsisOverride;
      } else {
        return this.get("translatedName");
      }
    }),

    hideInSynopsis: Ember.computed.oneWay('displayData.synopsis.hide'),

    translatedSubscaleName: Ember.computed("translatedShortname", function () {
      var override = subscaleNameMap[this.get("mnemonic")];
      if (override) {
        return override;
      }

      return this.get("translatedShortname");
    }),

    demographicsSummary: Ember.computed('minAge', 'maxAge', 'gender', function () {
      var intl = this.get('intl');

      var _getProperties3 = this.getProperties('minAge', 'maxAge', 'gender'),
          minAge = _getProperties3.minAge,
          maxAge = _getProperties3.maxAge,
          gender = _getProperties3.gender;

      var genderString = void 0,
          ageString = void 0;

      if (gender) {
        genderString = intl.t('model.gender.' + gender);
      }

      if (minAge && maxAge) {
        ageString = minAge + '-' + maxAge;
      } else if (minAge) {
        ageString = minAge + '+';
      } else if (maxAge) {
        ageString = '<' + maxAge;
      }

      return [genderString, ageString].compact().join(', ');
    }),

    hasCorrectDemographics: function hasCorrectDemographics(patient) {
      return this._ageIsValid(patient) && this._languageIsValid(patient) && this._genderIsValid(patient);
    },
    _ageIsValid: function _ageIsValid(patient) {
      var age = patient.get('age');

      var _getProperties4 = this.getProperties("minAge", "maxAge"),
          minAge = _getProperties4.minAge,
          maxAge = _getProperties4.maxAge;

      if (age === 'Unknown' || !minAge && !maxAge) {
        return true;
      }

      if (minAge && maxAge) {
        return age > minAge && age < maxAge;
      } else {
        return minAge && age > minAge || maxAge && age < maxAge;
      }
    },
    _languageIsValid: function _languageIsValid(patient) {
      var patientLanguage = patient.get('language');

      if (!patientLanguage) {
        return true;
      }

      var patientLocale = languageToLocaleMap[patientLanguage];

      var locale = this.get("locale");

      // If no languages declared, assume valid.
      if (!locale) {
        return true;
      }

      return locale.indexOf(patientLocale) >= 0;
    },
    _genderIsValid: function _genderIsValid(patient) {
      var patientGender = patient.get('gender');

      if (!patientGender) {
        return true;
      }

      var gender = this.get("gender");

      if (!gender) {
        return true;
      }

      return gender === patientGender;
    }
  });
});