define('frontend/pods/provider/assessment/invitation/return/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    session: Ember.inject.service(),
    model: function model(params, transition) {
      var invitation = this.modelFor("provider.assessment.invitation");
      var status = transition.params['provider.assessment.invitation.return.status'].status;
      return {
        invitation: invitation,
        status: status
      };
    },
    afterModel: function afterModel(model) {
      var invitation = this.modelFor("provider.assessment.invitation");

      this.trackMetricNow({ event: "provider.assessment.invitation." + model.status, invitationId: invitation.get("id") });
    }
  });
});