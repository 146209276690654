define('frontend/pods/components/provider/assessment/report-v2/collapsible-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      expand: function expand() {
        this.set('isCompressed', false);
      },
      compress: function compress() {
        this.set('isCompressed', true);
      }
    }
  });
});