define('frontend/pods/components/provider/experiment/experiment-details/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      disableExperiment: function disableExperiment() {
        var _this = this;

        var experiment = this.get("experiment");

        experiment.set("enabled", false);

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason, "experiment"));
        };
        return experiment.save().then(null, onFailure);
      },
      reenableExperiment: function reenableExperiment() {
        var _this2 = this;

        var experiment = this.get("experiment");

        experiment.set("enabled", true);

        var onFailure = function onFailure(reason) {
          _this2.get('messages').danger(_this2.printError(reason, "experiment"));
        };
        return experiment.save().then(null, onFailure);
      }
    }
  });
});