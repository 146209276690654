define('frontend/pods/components/provider/assessment/report-v2/symptom-details/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    messages: Ember.inject.service(),
    classNames: ['row', 'overview', 'symptom-details'],
    scaleIsTargeted: Ember.computed("activeComputedValue", "patientScaleTargets.[]", function () {
      var patientScaleTargets = this.get("patientScaleTargets");
      var scaleId = this.get("activeComputedValue.scale.id");
      return patientScaleTargets ? patientScaleTargets.map(function (pst) {
        return pst.get("scale.id");
      }).includes(scaleId) : false;
    }),
    explanationIntlKey: 'provider.assessments.report.symptomDetails.explanation',
    savingTarget: false,
    actions: {
      addTarget: function addTarget() {
        var newTarget = this.get("store").createRecord("assessment/patientScaleTarget");
        newTarget.set("scale", this.get("activeComputedValue.scale"));
        newTarget.set("patient", this.get("activeComputedValue.patient"));

        return this._saveTarget(function () {
          return newTarget.save();
        });
      },
      removeTarget: function removeTarget() {
        var scaleId = this.get("activeComputedValue.scale.id");
        var existingTarget = this.get("patientScaleTargets").find(function (pst) {
          return pst.get("scale.id") === scaleId;
        });

        if (existingTarget) {
          return this._saveTarget(function () {
            return existingTarget.destroyRecord();
          });
        }
      },
      closeDetails: function closeDetails() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      },
      showScaleDetails: function showScaleDetails() {
        if (this.get('onScaleInfo')) {
          this.get('onScaleInfo')(this.get('activeComputedValue.scale'));
        }
      }
    },
    _saveTarget: function _saveTarget(doSave) {
      var _this = this;

      var onSuccess = function onSuccess() {
        _this.set("savingTarget", false);
      };

      var onFailure = function onFailure(reason) {
        _this.set("savingTarget", false);
        _this.get('messages').danger(_this.printError(reason));
      };

      this.set("savingTarget", true);

      return doSave().then(onSuccess, onFailure);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get("activeComputedValue")) {
        this.get("activeComputedValue").query('answers', { include: 'question,option' });
      }
    },
    didRender: function didRender() {
      var view = this;
      $('[data-toggle="popover"]').popover();
      $(window).resize(function () {
        if (view.$()) {
          var spacer = view.$().find('.spacer');
          var height = view.$().find('.overview__section').height();
          spacer.height(height);
        }
      });
    }
  });
});