define('frontend/pods/components/survey/taking-local/common/questions/select-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, {
    showBlank: Ember.computed.notEmpty("question.properties.blankText.en"),
    actions: {
      onSelect: function onSelect(option) {
        var responseItem = this._prepareForNewValue();
        responseItem.set('value', option);
        this.send('submitAnswer');
      }
    }
  });
});