define("frontend/helpers/format-with-unknown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatWithUnknown = formatWithUnknown;
  function formatWithUnknown(params /*, hash*/) {
    var string = params;
    if (Ember.isArray(params)) {
      string = params[0];
    }

    if (string) {
      return params;
    } else {
      return "Unknown";
    }
  }

  exports.default = Ember.Helper.helper(formatWithUnknown);
});