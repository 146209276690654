define('frontend/pods/components/common/date-of-birth-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    labelKey: 'common.dateOfBirth',
    months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    years: Ember.computed(function () {
      var year = moment().year();

      var years = [year.toString()];

      for (var ii = 0; ii < 100; ii++) {
        year -= 1;
        years.addObject(year.toString());
      }

      return years;
    }),

    // Note that we store this all internally as MM/DD/YYYY to make it swappable with the old interface.
    dateComponents: Ember.computed('property', 'model', function () {
      var model = this.get('model');

      if (!model) {
        return {
          month: null,
          day: null,
          year: null
        };
      }

      var dobString = Ember.getWithDefault(model, this.get('property'), '');

      if (dobString) {
        var bits = dobString.split('/');

        return {
          month: bits[0],
          day: bits[1],
          year: bits[2]
        };
      } else {
        return {
          month: null,
          day: null,
          year: null
        };
      }
    }),

    month: Ember.computed('dateComponents', function () {
      return this.get("dateComponents").month;
    }),

    day: Ember.computed('dateComponents', function () {
      return this.get("dateComponents").day;
    }),

    year: Ember.computed('dateComponents', function () {
      return this.get("dateComponents").year;
    }),

    setDatePart: function setDatePart(part, value) {
      var components = this.get('dateComponents');

      components[part] = value;

      var day = components.day && components.day.length == 1 ? '0' + components.day : components.day;

      this.get('model').set(this.get('property'), components.month + '/' + day + '/' + components.year);
      this.notifyPropertyChange('dateComponents');
    },


    actions: {
      monthSelected: function monthSelected(value) {
        this.setDatePart('month', value);
      },
      daySelected: function daySelected(value) {
        this.setDatePart('day', value);
      },
      yearSelected: function yearSelected(value) {
        this.setDatePart('year', value);
      }
    }
  });
});