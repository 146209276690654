define("frontend/pods/provider/inactive/route", ["exports", "frontend/pods/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: null,
    beforeModel: function beforeModel() {
      sessionStorage.setItem("tabInactive", true);
    },
    willTransition: function willTransition() {
      sessionStorage.setItem("tabInactive", false);
    }
  });
});