define('frontend/pods/components/reporting/columns/attendance-history/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // If we're looking backwards in time, appointments in the 'booked' or 'proposed' state aren't really booked or proposed,
  // they have already happened or not and we haven't been told by the EMR. So when looking into the past, render all
  // future states as unknowns.
  var futureStatusMap = {
    booked: 'fa fa-calendar',
    fulfilled: 'fa fa-check good',
    arrived: 'fa fa-check good',
    noshow: 'fa fa-times bad',
    proposed: 'fa fa-calendar',
    canceled: 'fa fa-minus',
    pending: 'fa fa-calendar'
  };

  var futureUnknownStatusMap = {
    booked: 'fa fa-question',
    fulfilled: 'fa fa-check good',
    arrived: 'fa fa-check good',
    noshow: 'fa fa-times bad',
    proposed: 'fa fa-question',
    canceled: 'fa fa-minus',
    pending: 'fa fa-question'
  };

  exports.default = Ember.Component.extend({
    // Truncate session history 6 backwards, and 3 forwards, from the first 'new' entry.
    renderFutureAsUnknown: Ember.computed.alias('column.params.renderFutureAsUnknown'),
    appointmentHistory: Ember.computed("value.[]", 'renderFutureAsUnknown', function () {
      var mapToUse = this.get("renderFutureAsUnknown") ? futureUnknownStatusMap : futureStatusMap;

      var all = this.getWithDefault("value", []);

      if (!all) {
        return [];
      }

      var truncated = all.slice(Math.max(all.length - 6, 0));
      return truncated.map(function (status) {
        return {
          className: mapToUse[status],
          title: status[0].toUpperCase() + status.slice(1)
        };
      });
    })
  });
});