define('frontend/models/billing/reimbursement-summary-request', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    path: _emberData.default.attr('string'),
    billableChargeCount: _emberData.default.attr('number', { readOnly: true }),
    billableAmountCents: _emberData.default.attr('number', { readOnly: true }),
    billableUnitCount: _emberData.default.attr('number', { readOnly: true }),
    billedChargeCount: _emberData.default.attr('number', { readOnly: true }),
    billedAmountCents: _emberData.default.attr('number', { readOnly: true }),
    billedUnitCount: _emberData.default.attr('number', { readOnly: true })
  });
});