define('frontend/pods/components/patient/intro-screen/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    enableLanguageSelect: true,
    primaryLocale: Ember.computed("intl.primaryLocale", function () {
      return this.get("intl.primaryLocale");
    }),
    showRelatedPeople: true,
    actions: {
      setLanguage: function setLanguage(langString) {
        this.get('intl').setLocale([langString || 'en', 'en']);
        this.get("router").transitionTo({ queryParams: { locale: langString } });
      }
    }
  });
});