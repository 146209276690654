define('frontend/pods/components/provider/assessment/report-v2/badges/body-risk/component', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    messageKey: 'body',
    isBadgeShown: Ember.computed.or('activeReport.includesComputedNorse', 'activeReport.includesSfss'), // always show risk, even if unevaluated
    showPercentileRisk: Ember.computed.alias('isCamsRiskShown'),

    riskValue: Ember.computed("activeReport.computedValues.[]", "activeReport", "reportSettings.currentPopulation", function () {
      var popId = this.get('reportSettings.currentPopulation.id');
      var activeReport = this.get('activeReport');
      if (!activeReport) {
        return 0;
      }
      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');
      if (activeReport.get('includesComputedNorse') && computedValuesByScale['suicideRisk']) {

        return computedValuesByScale['suicideRisk'].find(function (cv) {
          return cv.get('domain') === 'normed' && cv.get('population.id') === popId;
        });
      } else if (activeReport.get('includesPeabody')) {
        var combinedValue = computedValuesByScale['sfssSuicideCombined'] ? computedValuesByScale['sfssSuicideCombined'][0] : null;

        if (combinedValue) {
          return combinedValue;
        }

        // So we don't have to rescore all the old reports that do not have a caregiver suicide question.
        return computedValuesByScale['sfssSuicide'] ? computedValuesByScale['sfssSuicide'][0] : null;
      }
    }),

    allRiskValues: Ember.computed("activeReport.computedValues.[]", "activeReport", "reportSettings.currentPopulation", function () {
      var popId = this.get('reportSettings.currentPopulation.id');
      var activeReport = this.get('activeReport');
      if (!activeReport) {
        return 0;
      }
      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');
      if (activeReport.get('includesComputedNorse') && computedValuesByScale['suicideRisk']) {

        return computedValuesByScale['suicideRisk'].filter(function (cv) {
          return cv.get('domain') === 'normed' && cv.get('population.id') === popId;
        });
      } else if (activeReport.get('includesPeabody')) {
        var caregiverValues = computedValuesByScale['sfssSuicide_C'] || [];
        var childValues = computedValuesByScale['sfssSuicide'] || [];

        return caregiverValues.addObjects(childValues);
      }
    }),

    isCamsRiskShown: Ember.computed.equal('riskValue.scale.mnemonic', 'suicideRisk'),

    isRiskPresent: Ember.computed("riskValue", "isCamsRiskShown", function () {
      var riskValue = this.get('riskValue.value');
      // CAMS is based on whether risk is above the mean,
      // PTPB is based on whether the item is endorsed at all (any option other than 1 chosen)
      if (this.get('riskValue.scale.baseMnemonic') === 'sfssSuicide') {
        return riskValue > 1;
      }
      return riskValue > 0;
    }),

    riskPercentile: Ember.computed("riskValue", function () {
      return Math.round(_analytics.default.GetZPercent(this.get('riskValue.value')) * 100);
    }),

    riskPercentileString: Ember.computed("riskPercentile", function () {
      return _analytics.default.ordinalSuffix(this.get('riskPercentile'));
    })
  });
});