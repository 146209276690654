define("frontend/polyfill", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // This file contains polyfills, functionality which we want
  // to have work in any browser but might not be widely supported or
  // maybe doesn't work in one browser. This file is automatically
  // included when the app boots making the functionality available
  // in every browser.

  // This lets us use forEach with nodelists. This is not supported in IE.
  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
  var polyfillNodeListForEach = function polyfillNodeListForEach() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
  };

  var polyfill = function polyfill() {
    polyfillNodeListForEach();
  };

  exports.default = polyfill;
});