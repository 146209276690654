define('frontend/pods/provider/admin/users/show/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model(params) {
      return this.store.findRecord('provider', params.provider_id, { include: 'user,user.roles,organizations' });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', { title: model.get('name'), linkable: true, isRaw: true, icon: 'fa-user-md' });
    }
  });
});