define('frontend/pods/components/common/boolean-toggle/component', ['exports', 'frontend/components/x-toggle'], function (exports, _xToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _xToggle.default.extend({
    _offValue: false,
    _onValue: true,
    theme: 'material'
  });
});