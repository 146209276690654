define('frontend/models/assessment/measurement-request', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    user: _emberData.default.belongsTo("user"),
    invitation: _emberData.default.belongsTo("assessment/invitation")
  });
});