define('frontend/pods/components/provider/assessment/scale-browser/list-component/extended-details-column/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    validParticipants: Ember.computed('record', 'rowContext.allParticipants', function () {
      // If we aren't in participant mode, we are valid.
      if (!this.get("rowContext.allParticipants")) {
        return true;
      }

      return this.get("record").hasValidParticipants(this.get("rowContext.allParticipants"));
    })
  });
});