define("frontend/locales/customers/varesearch", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: {
      "common.lastSsn": "Pin number",
      "model.patient.lastName": "Last Name (Study ID)",
      "model.patient.firstName": "First Name ('Research')",
      "common.mrn": "Study ID",
      "model.patient.gender": "Sex (leave blank)",
      "model.patient.dob": "Date of Birth (01/01/1990)"
    }
  };
});