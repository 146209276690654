define('frontend/services/reports', ['exports', 'frontend/models/reporting/report', 'frontend/services/reports/conditions', 'frontend/services/reports/my-adherence', 'frontend/services/reports/adherence-drilldown-v2'], function (exports, _report, _conditions, _myAdherence, _adherenceDrilldownV) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var REPORTS = [_report.default.create({
    shortcode: 'reimbursement_summary',
    component: 'provider.reports.reimbursement-mock',
    isMock: true,
    role: 'executive',
    icon: 'reimburse.svg',
    mockSummaryData: {
      statString: '$11,657',
      statLabel: 'reimbursements received',
      trend: '$685',
      trendIcon: 'fa fa-arrow-up'
    }
  }), _myAdherence.default, _conditions.default, _report.default.create({
    shortcode: 'provider_load',
    component: 'provider.reports.provider-load-mock',
    isMock: true,
    role: 'provider',
    icon: 'paperStack.svg',
    mockSummaryData: {
      statString: '1',
      statLabel: 'provider at risk',
      trend: '',
      trendIcon: 'fa fa-arrows-h'
    }
  }), _report.default.create({
    shortcode: 'taking_time_summary',
    role: 'executive',
    icon: 'elapsedTime.svg',
    showDateRangeControl: true,
    mockSummaryData: {
      statString: '7 mins',
      statLabel: 'median taking time',
      trend: '',
      trendIcon: 'fa fa-arrows-h'
    }
  }), _report.default.create({
    shortcode: 'user_recent_activity',
    role: 'executive',
    icon: 'computerActivity.svg',
    showDateRangeControl: true,
    defaultPreset: 'thisWeek',
    showOrganizationFilter: true,
    columnProperties: {
      provider: {
        className: 'text-left'
      }
    },
    mockSummaryData: {
      statString: '2',
      statLabel: 'inactive users',
      trend: '1',
      trendIcon: 'fa fa-arrow-down'
    }
  }), _report.default.create({
    shortcode: 'session_status_summary',
    role: 'executive',
    internalOnly: true,
    showDateRangeControl: true,
    showProviderFilter: true,
    icon: 'rulerMeasure.svg',
    mockData: null
  }), _report.default.create({
    shortcode: 'compliance_report',
    role: 'executive',
    isMock: true,
    showPrint: true,
    showDateRangeControl: false,
    showProviderFilter: false,
    icon: 'rulerMeasure.svg',
    mockData: null,
    customComponent: 'reporting/compliance-report'
  }), _adherenceDrilldownV.default, _report.default.create({
    shortcode: 'no_show_history_drilldown',
    role: 'executive',
    icon: 'noShow.svg',
    showDateRangeControl: true,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      noShows: {
        lowerIsBetter: true,
        className: 'primary'
      },
      canceled: {
        lowerIsBetter: true
      },
      noShowRate: {
        displayType: 'percent-bar',
        lowerIsBetter: true,
        displayParams: {
          colorMap: [[0.4, '#babfd4'], [1, '#E85D75']]
        },
        headline: true
      }
    },
    defaultSort: 'noShows',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'session_outcome_drilldown',
    role: 'provider',
    icon: 'heartbeat.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: true,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      meanScore: {
        lowerIsBetter: true,
        className: 'primary'
      },
      meanDelta: {
        lowerIsBetter: true,
        headline: true,
        className: 'primary'
      },
      meanDeltaPerWeek: {
        lowerIsBetter: true,
        headline: true,
        format: {
          maximumFractionDigits: 1
        }
      },
      meanDaysBetweenMeasurement: {
        incomparable: true
      }
    },
    defaultSort: 'meanDelta',
    defaultSortDirection: 'asc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    mockSummaryData: {
      stat: 0.77,
      statString: '77%',
      statLabel: 'patients improving',
      trend: '2%',
      trendIcon: 'fa fa-arrow-up'
    }
  }), _report.default.create({
    shortcode: 'care_episode_outcome_drilldown',
    role: 'provider',
    icon: 'heartbeat.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: true,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      meanIntakeScore: {
        lowerIsBetter: true,
        className: 'primary',
        featured: true,
        headline: true
      },
      meanCurrentScore: {
        featured: true,
        lowerIsBetter: true,
        className: 'primary'
      },
      meanNumberOfSessions: {
        featured: true,
        lowerIsBetter: true
      },
      meanLengthOfService: {
        featured: true,
        incomparable: true
      },
      total: {
        featured: true
      },
      meanDeltaSinceIntake: {
        featured: true,
        lowerIsBetter: true
      },
      meaningfulChange: {},
      thresholdChanged: {}
    },
    defaultSort: 'meanIntakeScore',
    defaultSortDirection: 'asc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'coverage_drilldown',
    role: 'executive',
    icon: 'reimburse.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      insuredPatients: {
        lowerIsBetter: false,
        className: 'primary',
        headline: true
      },
      uninsuredWithPlanPatients: {
        lowerIsBetter: true
      },
      percentInsured: {
        headline: true,
        displayType: 'percent-bar'
      }
    },
    featureGate: 'enablePayors',
    defaultSort: 'insuredPatients',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    internalOnly: true
  }), _report.default.create({
    shortcode: 'enrollment_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'thisYear',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalPatients: {},
      enrolledPatients: {
        className: 'primary',
        headline: true
      },
      enrolledPercentage: {
        displayType: 'percent-bar',
        headline: true
      },
      unscheduledPatients: {
        shown: false
      },
      unenrolledPatients: {
        shown: false
      },
      futureScheduledPatients: {
        shown: false
      }
    },
    defaultSort: 'totalPatients',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'patient_usage_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'thisYear',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      measuredPatients: {
        className: 'primary',
        headline: true
      },
      attemptedPatients: {
        className: 'primary',
        headline: true
      }
    },
    defaultSort: 'measuredPatients',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'billable_charge_drilldown',
    role: 'executive',
    icon: 'reimburse.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      receivedAmountCumulative: {
        headline: true
      },
      expectedAmountCumulative: {
        headline: true
      },
      receivedAmount: {
        featured: true,
        className: 'primary'
      },
      billedAmount: {
        featured: true
      },
      billableSessions: {
        featured: true
      },
      billableUnits: {
        featured: true
      },
      billableAmount: {
        featured: true
      },
      expectedAmount: {
        featured: true,
        className: 'primary'
      }
    },
    featureGate: 'enableBilling',
    permissionGate: 'viewPatientBilling',
    defaultSort: 'billableUnits',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'charge_efficiency_drilldown',
    role: 'executive',
    icon: 'reimburse.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalPriceSum: {
        headline: true
      },
      totalPriceCapturedPercent: {
        headline: true,
        displayType: 'percent-bar'
      },
      adherenceEfficiencyUnitsPercent: {
        displayType: 'percent-bar'
      },
      adherenceEfficiencyAmountPercent: {
        headline: true,
        displayType: 'percent-bar'
      },
      contractEfficiencyUnitsPercent: {
        displayType: 'percent-bar'
      },
      contractEfficiencyAmountPercent: {
        headline: true,
        displayType: 'percent-bar'
      }

    },
    featureGate: 'enableBilling',
    permissionGate: 'viewPatientBilling',
    defaultSort: 'contractEfficiencyAmountPercent',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'taking_time_drilldown',
    role: 'executive',
    icon: 'elapsedTime.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      meanTime: {
        headline: true,
        className: 'primary'
      },
      fiveToFifteen: {
        displayType: 'percent-bar',
        headline: true
      },
      fifteenToTwenty: {
        displayType: 'percent-bar',
        displayParams: {
          colorMap: [[0.4, '#babfd4'], [1, '#E85D75']]
        }
      },
      moreThanTwenty: {
        displayType: 'percent-bar',
        displayParams: {
          colorMap: [[0.4, '#babfd4'], [1, '#E85D75']]
        }
      }
    },
    defaultSort: 'meanTime',
    defaultSortDirection: 'asc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'experiment_enrollment_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'allTime',
    featureGate: 'enableExperiments',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      enrolled: {
        headline: true,
        className: 'primary'
      },
      enrollmentRate: {
        displayType: 'percent-bar',
        headline: true
      }
    },
    defaultSort: 'enrolled',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'client_assessment_completion_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      completion: {
        headline: true,
        className: 'primary',
        featured: true
      },
      percentageStarted: {
        headline: false,
        displayType: 'percent-bar'
      },
      percentageComplete: {
        headline: false,
        displayType: 'percent-bar',
        featured: true
      },
      totalAssessments: {
        featured: true
      },
      completedAssessments: {
        featured: true
      },
      expiredAssessments: {
        featured: false
      },
      partialAssessments: {
        featured: false
      },
      percentageItemsResponded: {
        featured: true,
        displayType: 'percent-bar',
        headline: true
      }
    },
    defaultSort: 'percentageStarted',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'provider_assessment_completion_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      completion: {
        headline: true,
        className: 'primary',
        featured: true
      },
      percentageStarted: {
        headline: false,
        displayType: 'percent-bar'
      },
      percentageComplete: {
        headline: false,
        displayType: 'percent-bar',
        featured: true
      },
      totalAssessments: {
        featured: true
      },
      completedAssessments: {
        featured: true
      },
      expiredAssessments: {
        featured: false
      },
      partialAssessments: {
        featured: false
      },
      percentageItemsResponded: {
        featured: true,
        displayType: 'percent-bar',
        headline: true
      }
    },
    defaultSort: 'percentageStarted',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'genoa_metrics_drilldown',
    institutes: ['test', 'genoa', 'genoa-d2c'],
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      patientsAttempted: {
        featured: false,
        headline: true
      },
      patientsMeasured: {
        featured: true,
        headline: false
      },
      percentageMeasured: {
        headline: true,
        featured: true,
        displayType: 'percent-bar',
        className: 'primary'
      },
      patientIntakesAttempted: {
        headline: true,
        featured: true
      },
      patientIntakesMeasured: {
        headline: false,
        featured: false
      },
      percentageIntakesMeasured: {
        headline: true,
        featured: true,
        displayType: 'percent-bar',
        className: 'primary'
      },
      totalAssessments: {
        featured: true
      },
      completedAssessments: {
        featured: false
      },
      percentageAssessmentsCompleted: {
        featured: true,
        headline: true,
        displayType: 'percent-bar',
        className: 'primary'
      }
    },
    internalOnly: true,
    defaultSort: 'percentageMeasured',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'patient_adoption_flow_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    headline: 'reporting/headline-adoption',
    columnProperties: {
      bothStartedMeasures: {
        featured: true,
        headline: false
      },
      bothCompletedMeasures: {
        featured: true,
        headline: false
      },
      bothTotalMeasures: {
        featured: true,
        headline: false
      },
      emailStartedMeasures: {
        featured: true,
        headline: true
      },
      emailCompletedMeasures: {
        featured: true,
        headline: false
      },
      emailTotalMeasures: {
        featured: true,
        headline: true
      },
      phoneStartedMeasures: {
        featured: true,
        headline: true
      },
      phoneCompletedMeasures: {
        featured: true,
        headline: false
      },
      phoneTotalMeasures: {
        featured: true,
        headline: true
      },
      noneStartedMeasures: {
        featured: true,
        headline: true
      },
      noneCompletedMeasures: {
        featured: true,
        headline: false
      },
      noneTotalMeasures: {
        featured: true,
        headline: true
      }
    },
    internalOnly: true,
    defaultSort: 'bothCompletedMeasures',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true
  }), _report.default.create({
    shortcode: 'provider_usage_drilldown',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      activeProviders: {
        featured: true,
        headline: true
      },
      totalProviders: {
        featured: true,
        headline: false
      },
      activePercent: {
        featured: true,
        headline: true,
        displayType: 'percent-bar'
      }
    },
    internalOnly: true,
    defaultSort: 'activePercent',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: false,
    showConditionFilter: false
  }), _report.default.create({
    shortcode: 'treatment_track_enrollment_drilldown',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalEnrollments: {
        featured: true,
        headline: true
      },
      totalActive: {
        featured: true,
        headline: true
      },
      percentActive: {
        featured: true,
        headline: true,
        displayType: 'percent-bar'
      },
      meanTreatmentLength: {
        featured: true,
        headline: false,
        format: {
          maximumFractionDigits: 1
        }
      }
    },
    defaultSort: 'totalEnrollments',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: false,
    showConditionFilter: false
  }), _report.default.create({
    shortcode: 'treatment_track_active_drilldown',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalActive: {
        featured: true,
        headline: true
      },
      meanTreatmentLength: {
        featured: true,
        headline: true,
        format: {
          maximumFractionDigits: 1
        }
      }
    },
    featureGate: 'enableBilling',
    defaultSort: 'totalActive',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: false,
    showConditionFilter: false
  }), _report.default.create({
    shortcode: 'optum_adoption_all_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      adopted: {
        headline: true,
        featured: true
      },
      total: {
        headline: false,
        featured: true
      },
      percentageAdopted: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageAdopted',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_adoption_gina_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      adopted: {
        headline: true,
        featured: true
      },
      total: {
        headline: false,
        featured: true
      },
      percentageAdopted: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageAdopted',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_ongoing_all_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      attemptedMeasurements: {
        headline: true,
        featured: true
      },
      completedMeasurements: {
        headline: false,
        featured: true
      },
      percentageMeasured: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageMeasured',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_ongoing_gina_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      attemptedMeasurements: {
        headline: true,
        featured: true
      },
      completedMeasurements: {
        headline: false,
        featured: true
      },
      percentageMeasured: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageMeasured',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_feedback_viewing_all_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      total: {
        headline: true,
        featured: true
      },
      viewed: {
        headline: false,
        featured: true
      },
      percentageViewed: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageViewed',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_feedback_viewing_gina_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      total: {
        headline: true,
        featured: true
      },
      viewed: {
        headline: false,
        featured: true
      },
      percentageViewed: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageViewed',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_feedback_use_all_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      sessionsWithResponse: {
        headline: true,
        featured: true
      },
      sessionsAddressed: {
        headline: false,
        featured: true
      },
      percentageAddressed: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageAddressed',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'optum_feedback_use_gina_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      sessionsWithResponse: {
        headline: true,
        featured: true
      },
      sessionsAddressed: {
        headline: false,
        featured: true
      },
      percentageAddressed: {
        headline: true,
        className: 'primary',
        displayType: 'percent-bar',
        featured: true
      }
    },
    defaultSort: 'percentageAddressed',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: true,
    featureGate: 'enableOptumMetrics',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'quality_issue_workflow_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      issuesClosed: {
        headline: true,
        featured: true
      },
      issuesUnresolved: {
        headline: true,
        featured: true
      },
      meanIssueResolutionDays: {
        headline: false,
        featured: true
      },
      resolution: {
        headline: false,
        featured: true
      }
    },
    defaultSort: 'issuesResolved',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: false,
    featureGate: 'enableQualityIssues',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'patient_quality_issue_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalPatients: {
        headline: false,
        featured: true
      },
      healthyPatients: {
        headline: false,
        featured: true
      },
      warningPatients: {
        headline: false,
        featured: true,
        lowerIsBetter: true
      },
      errorPatients: {
        headline: false,
        featured: true,
        lowerIsBetter: true
      },
      percentHealthy: {
        headline: true,
        featured: false
      },
      health: {
        headline: true,
        featured: true
      }
    },
    defaultSort: 'percentHealthy',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: false,
    featureGate: 'enableQualityIssues',
    alwaysAvailableInternal: true
  }), _report.default.create({
    shortcode: 'decision_support_workflow_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalWorkflows: {
        headline: false,
        featured: true
      },
      activeWorkflows: {
        headline: true,
        featured: true
      },
      completeWorkflows: {
        headline: true,
        featured: true,
        lowerIsBetter: true
      },
      snoozedWorkflows: {
        headline: false,
        featured: false,
        lowerIsBetter: true
      },
      canceledWorkflows: {
        headline: false,
        featured: false
      },
      status: {
        headline: false,
        featured: true
      },
      meanResolutionDays: {
        headline: false,
        featured: true
      }
    },
    defaultSort: 'completeWorkflows',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: false,
    featureGate: 'enableDecisionSupport',
    alwaysAvailableInternal: false
  }), _report.default.create({
    shortcode: 'decision_support_actions_drilldown',
    role: 'executive',
    icon: 'rulerMeasure.svg',
    showDateRangeControl: true,
    showScaleSelector: false,
    showScaleScorerSelector: false,
    showComparisonDateRangeControl: true,
    defaultPreset: 'last90Days',
    defaultComparisonPreset: 'none',
    drilldown: true,
    columnProperties: {
      totalWorkflows: {
        headline: false,
        featured: true
      },
      workflowsWithAction: {
        headline: true,
        featured: true
      },
      percentageWorkflowsWithAction: {
        headline: true,
        featured: true,
        className: 'primary',
        displayType: 'percent-bar'
      }
    },
    defaultSort: 'percentageWorkflowsWithAction',
    defaultSortDirection: 'desc',
    defaultLimit: 10,
    showFlagFilter: true,
    showConditionFilter: false,
    featureGate: 'enableDecisionSupport',
    alwaysAvailableInternal: false
  })];

  // map of viewable reports by shortcode
  function computedReportList(allReports) {
    return Ember.computed('sessionUser.currentUser.isInternal', function () {
      var _this = this;

      var userIsInternal = this.get('sessionUser.currentUser.isInternal');
      var sessionUser = this.get("sessionUser");
      var showMocks = this.getWithDefault('features.showMockAnalytics', false);
      var showExecs = userIsInternal || this.get('sessionUser').hasPermission('viewExecutiveReports');

      return allReports.filter(function (report) {
        return report.get('internalOnly') !== true || userIsInternal;
      }).filter(function (report) {
        return !report.get('isMock') || showMocks;
      }).filter(function (report) {
        return userIsInternal && report.get('alwaysAvailableInternal') || !report.get('featureGate') || _this.get("features").get(report.get('featureGate'));
      }).filter(function (report) {
        return !report.get('instituteList') || _this.get('institute.current.shortcode') === report.get('instituteList');
      }).filter(function (report) {
        return showExecs || report.get('role') !== 'executive';
      }).filter(function (report) {
        return !report.get('permissionGate') || sessionUser.hasPermission(report.get('permissionGate'));
      });
    });
  }

  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    features: Ember.inject.service(),
    activeReport: undefined,

    allReports: computedReportList(REPORTS),
    reportMap: Ember.computed('allReports.[]', function () {
      var reportMap = {};
      this.get('allReports').forEach(function (r) {
        reportMap[r.get('shortcode')] = r;
      });
      return reportMap;
    }),
    userHasViewableReports: Ember.computed('allReports.[]', function () {
      return this.get('allReports.length') > 0;
    }),

    // returns an array of report objects for the given codes.
    // if a code is invalid or the user does not have access, the report will not 
    // be included in the array
    getReportsForCodes: function getReportsForCodes(codes) {
      if (!codes) {
        return [];
      }
      var reportMap = this.get('reportMap');
      return codes.map(function (code) {
        return reportMap[code];
      }).filter(function (r) {
        return r;
      });
    }
  });
});