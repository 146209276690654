define('frontend/utils/assessment/rule-evaluator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //for now, we roll our own rules since the rules frameworks (eg. nools) I could find are both heavy and don't quite do what we need
  var evaluatorLib = {
    evaluateRule: function evaluateRule(ruleObject, context) {
      // Special rule for n-ary boolean ops to stop the ridulous binary op chains.
      if (ruleObject.rules) {
        if (ruleObject.operator === '&&') {
          return ruleObject.rules.every(function (rule) {
            return evaluatorLib.evaluateRule(rule, context);
          });
        } else if (ruleObject.operator === '||') {
          return ruleObject.rules.any(function (rule) {
            return evaluatorLib.evaluateRule(rule, context);
          });
        } else if (ruleObject.operator === 'sum') {
          return ruleObject.rules.reduce(function (sum, rule) {
            var value = evaluatorLib.evaluateRule(rule, context);

            if (typeof value !== 'undefined') {
              return sum + Number(value);
            } else {
              return null;
            }
          }, 0);
        }
      }

      // It's a simple property calculation
      if (ruleObject.value || ruleObject.property || ruleObject.question) {
        return evaluatorLib.evaluateProperty(ruleObject, context);
      }

      var leftResult = void 0,
          rightResult = void 0,
          result = false;
      if (ruleObject.leftSide.rule) {
        //nested rule
        leftResult = evaluatorLib.evaluateRule(ruleObject.leftSide.rule, context);
      } else {
        leftResult = evaluatorLib.evaluateProperty(ruleObject.leftSide, context);
      }
      if (ruleObject.rightSide) {
        if (ruleObject.rightSide.rule) {
          //nested rule
          rightResult = {};
          rightResult = evaluatorLib.evaluateRule(ruleObject.rightSide.rule, context);
        } else {
          rightResult = evaluatorLib.evaluateProperty(ruleObject.rightSide, context);
        }
      }

      switch (ruleObject.operator) {
        case '===':
          result = leftResult === rightResult;
          break;
        case '!==':
          result = leftResult !== rightResult;
          break;
        case '||':
          result = leftResult || rightResult;
          break;
        case '&&':
          result = leftResult && rightResult;
          break;
        case 'contains':
          if (Ember.isArray(leftResult)) {
            result = leftResult.includes(rightResult);
          } else {
            result = false;
          }
          break;
        case 'in':
          if (Ember.isArray(rightResult)) {
            result = rightResult.includes(leftResult);
          } else {
            result = false;
          }
          break;
        case '>':
          result = Number(leftResult) > Number(rightResult);
          break;
        case '<':
          result = Number(leftResult) < Number(rightResult);
          break;
        case 'sum':
          result = Number(leftResult) + Number(rightResult);
          break;
        case 'present':
          result = leftResult !== null && leftResult !== undefined;
          break;
        case 'empty':
          result = leftResult === null || leftResult === undefined;
          break;
        default:
          result = false;
      }
      return result;
    },
    evaluateProperty: function evaluateProperty(propertySpecification, context) {
      if (propertySpecification.value) {
        //literal value
        return propertySpecification.value;
      } else if (propertySpecification.property) {
        //when survey gets pulled into a separate frontend, this should get replaced with something else so we don't need to bring in lodash
        var value = context.response.get(propertySpecification.property);
        if (value) {
          return value;
        } else if (typeof propertySpecification.defaultValue !== 'undefined') {
          return propertySpecification.defaultValue;
        }
      } else if (propertySpecification.question) {
        var _value = void 0;
        if (propertySpecification.question.includes('#')) {
          _value = context.response.get('responseItemMap.' + propertySpecification.question + '.simpleValue');
        } else {
          _value = context.response.get('responseItemMap.' + context.module.get("question_domain") + '#' + propertySpecification.question + '.simpleValue');

          // Legacy hack. We can't currently distinguish between 'is a legacy scale lookup' and 'is in this module', so if we don't find something
          // look withtout the domain as well.
          if (!_value) {
            _value = context.response.get('responseItemMap.' + propertySpecification.question + '.simpleValue');
          }
        }
        if (_value) {
          return _value;
        } else if (typeof propertySpecification.defaultValue !== 'undefined') {
          return propertySpecification.defaultValue;
        }
      } else {
        return null;
      }
    }
  };

  exports.default = evaluatorLib;
});