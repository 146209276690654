define('frontend/pods/components/provider/assessment/report-v2/left-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['side-bar'],
    tagName: 'section',

    //for now, hack the session number with the report index
    reportNumber: Ember.computed("reports", "activeReport", function () {
      var reports = this.getWithDefault("reports", []);
      var activeReport = this.get("activeReport");

      return reports.get('length') - reports.indexOf(activeReport);
    })
  });
});