define('frontend/helpers/date-picker-day-classes', ['exports', 'ember-date-components/helpers/date-picker-day-classes'], function (exports, _datePickerDayClasses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _datePickerDayClasses.default;
    }
  });
  Object.defineProperty(exports, 'datePickerDayClasses', {
    enumerable: true,
    get: function () {
      return _datePickerDayClasses.datePickerDayClasses;
    }
  });
});