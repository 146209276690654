define('frontend/pods/internal/scales/create/route', ['exports', 'frontend/models/assessment/scale-templates'], function (exports, _scaleTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var surveyData = this.controllerFor('internal.scales.create').get("importedData");

      if (surveyData) {
        return surveyData;
      }

      var blankScale = _scaleTemplates.RawSurvey.createNew(Ember.getOwner(this));

      return this.get('store').createRecord('assessment/rawScaleDatum', { parsedScaleData: blankScale, version: 1, status: 'draft' });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ importedData: undefined });
      }
    }
  });
});