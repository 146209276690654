define('frontend/pods/components/survey/taking-local/common/questions/slider-question/component', ['exports', 'frontend/mixins/survey/question-component-mixin'], function (exports, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, {
    //because everything is stored as a string server-side, we need to do numeric conversion here
    numericValue: Ember.computed('responseItem.value', function () {
      return parseInt(this.get('responseItem.value'));
    }),

    actions: {
      clickLess: function clickLess() {
        // Note we do this before preparing for a new value as it's possible the previous response will be overwritten.
        var value = this.get('responseItem.value');
        var responseItem = this._prepareForNewValue();

        if (!value || value > -1 || value <= -5) {
          responseItem.set('value', -1);
        } else if (value > -5) {
          responseItem.set('value', value - 2);
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },


      //if they are clicking for the first time, submit it, if they are changing, don't immediately submit
      clickSame: function clickSame() {
        // Note we do this before preparing for a new value as it's possible the previous response will be overwritten.
        var prevValue = this.get('responseItem.value');

        var responseItem = this._prepareForNewValue();

        responseItem.set('value', 0);
        if (!prevValue || prevValue === '0') {
          this.send('submitAnswer');
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      },
      clickMore: function clickMore() {
        // Note we do this before preparing for a new value as it's possible the previous response will be overwritten.
        var value = this.get('responseItem.value');

        var responseItem = this._prepareForNewValue();

        if (!value || value < 1 || value >= 5) {
          responseItem.set('value', 1);
        } else if (value < 5) {
          responseItem.set('value', value + 2);
        }

        if (this.get("onChange")) {
          this.get("onChange")();
        }
      }
    }
  });
});