define('frontend/pods/components/provider/assessment/dashboard/dashboard-v3/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dashboard', 'page'],
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),

    tabCounts: {
      allPatients: undefined,
      todaysPatients: undefined
    },

    actions: {
      updateCount: function updateCount(countProperty, numRecords) {
        this.set('tabCounts.' + countProperty, numRecords);
      }
    }
  });
});