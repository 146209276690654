define("frontend/mixins/provider-route-mixin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    authenticationRoute: "provider.login",
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    exceptions: ["provider.login", "provider.user.password-reset", "provider.user.password-reset", "provider.user.confirm", "provider.sso-auth"]
  });
});