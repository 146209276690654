define("frontend/serializers/care/recurring-session-request", ["exports", "frontend/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /*
     * The JSON API spec does not as of v1.0 support multiple simulataneous operations, which includes compound create operations
     * like is necessary for session creation. In order to support this for now, there is a custom specification where a 'nested data'
     * parameter gets encoded on the frontend side, and then reinflated on the backend. All interactions otherwise are done via the correct
     * infrastructure. The intent is that once JSONAPI supports multi-put, we can transparently turn off the hack. Note that in order to
     * work properly, the adapter needs to be changed to include all these sidecar'd resources, otherwise they will not be correctly updated
     * after the save operation.
     *
     * The one wrinkle here is deleted records: we don't delete them directly, but rather
     * the back end will remove them once they are no longer used. Because we don't actually
     * issue a delete, they will stay in state isDeleted=true locally, so we have to filter them out.
     */
    serialize: function serialize(snapshot) {
      var result = this._super.apply(this, arguments);
      var model = snapshot.record;

      var careEpisode = model.get("careEpisode");
      var careEpisodeId = careEpisode.get("id");

      var nestedData = {};

      if (careEpisode && careEpisode.get("isNew")) {
        var careEpisodeSerialized = typeof careEpisode.serialize === 'function' ? careEpisode.serialize() : careEpisode.content.serialize();

        nestedData.newCareEpisode = {
          id: careEpisodeId,
          patientId: careEpisode.get("patient.id"),
          periodStart: careEpisode.get("periodStart"),
          organizationId: careEpisode.get("organization.id"),
          saveNested: true,
          nestedData: careEpisodeSerialized.data.attributes['nested-data']
        };
      }

      result.data.attributes['nested-data'] = nestedData;
      result.data.attributes['save-nested'] = true;

      return result;
    }
  });
});