define('frontend/locales/en/internal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    institutes: {
      list: {
        title: 'Institutes',
        description: 'Institutes in this environment'
      },
      createInstitute: {
        title: 'Create institute',
        description: 'Remember to configure Route 53 ***FIRST***'
      }
    },
    scales: {
      list: {
        title: 'Scales',
        description: 'Modify and change scales'
      }
    }
  };
});