define('frontend/pods/components/survey/taking-local/page-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    displayStatus: 'current', //statuses are current|prev|next
    questionsSelector: '.current-section .questions',

    didUpdatePage: Ember.observer('page', function () {
      // if we're loading the previous page, start with the page's final question
      this.scrollToCurrentQuestion();
      // If we've hit the finish question, mark such so we can close out 'finished' assessments
      // where they don't hit the final button.
      if (this.get("response.currentQuestion.properties.finish") && this.get('onFinishScreen')) {
        this.get('onFinishScreen')();
      }
    }),
    // whether to show the reminder text about what patient this form is about
    showAboutHeader: Ember.computed('response.currentPage', 'response.invite.paramData', function () {
      return this.get('response.invite.paramData.patientName') && this.get('response.currentPage.properties.showPatientHeader') !== false;
    }),

    customComponentName: Ember.computed('page', function () {
      if (!this.get('page.properties.component')) {
        return null;
      }
      return 'survey/taking-local/page-components/' + this.get('page.properties.component');
    }),

    dynamicProperties: Ember.computed('inviteProperties', 'response.currentPage.properties.dependent_variables.[]', function () {
      var response = this.get('response');
      var domain = this.get("response.currentModule.question_domain");
      var dynamicProperties = this.get("response.currentPage.properties.dependent_variables");

      if (!dynamicProperties || dynamicProperties.length === 0) {
        return Ember.Object.create({
          calculated: this.get('inviteProperties')
        });
      } else {
        var keyMap = dynamicProperties.reduce(function (obj, el) {
          obj[el] = 'responseItemMap.' + domain + '#' + el + '.simpleValue';
          return obj;
        }, {});

        return Ember.Object.extend({
          calculated: Ember.computed.apply(Ember, _toConsumableArray(Object.values(keyMap)).concat([function () {
            var _this = this;

            var valueMap = dynamicProperties.reduce(function (obj, el) {
              obj[el] = _this.get('responseItemMap.' + domain + '#' + el + '.simpleValue');
              return obj;
            }, {});

            var merged = Ember.merge(valueMap, this.get('inviteProperties'));
            return merged;
          }]))
        }).create({ inviteProperties: this.get('inviteProperties'), responseItemMap: response.get('responseItemMap') });
      }
    }),

    scrollToCurrentQuestion: function scrollToCurrentQuestion() {
      var $questions = $(this.get('questionsSelector'));
      $questions.velocity({
        translateX: -100 * this.get('response.currentQuestionNum') + '%'
      }, {
        duration: 0,
        queue: false
      });
    },
    didInsertElement: function didInsertElement() {
      this.sendAction('register', this);
      this.scrollToCurrentQuestion();
    },
    transitionNextQuestion: function transitionNextQuestion() {
      var _this2 = this;

      var $questions = $(this.get('questionsSelector'));
      $questions.velocity({
        translateX: '-=100%'
      }, {
        begin: function begin() {
          _this2.set("isTransitioning", true);
        },
        complete: function complete() {
          _this2.get('response').goToNextQuestion();
          _this2.set("isTransitioning", false);
        },
        duration: 550,
        queue: false
      });
    },
    transitionPrevQuestion: function transitionPrevQuestion() {
      var _this3 = this;

      var $questions = $(this.get('questionsSelector'));
      $questions.velocity({
        translateX: '+=100%'
      }, {
        begin: function begin() {
          _this3.set("isTransitioning", true);
        },
        complete: function complete() {
          _this3.get('response').goToPrevQuestion();
          _this3.set("isTransitioning", false);
        },
        duration: 550,
        queue: false,
        ease: 'ease-out'
      });
    },


    actions: {
      nextQuestion: function nextQuestion(rawAnswer) {
        if (!this.get("isTransitioning")) {
          this.sendAction('updatedAnswer', rawAnswer);
          //notify the response to trigger a recompute of displayed questions
          this.get('response.currentPage').notifyPropertyChange('questionsToDisplay');
          if (this.get('response.isLastQuestionOfPage')) {
            this.sendAction('nextPage');
          } else {
            this.transitionNextQuestion();
          }
          this.sendAction('resetBacksteps');
        }
      },
      nextPage: function nextPage() {
        if (!this.get("isTransitioning")) {
          this.sendAction('nextPage');
        }
      },
      nextQuestionWithoutReset: function nextQuestionWithoutReset(rawAnswer) {
        if (!this.get("isTransitioning")) {
          this.sendAction('didMoveForward');
          this.sendAction('updatedAnswer', rawAnswer);
          if (this.get("customComponentName") || this.get('response.isLastQuestionOfPage')) {
            this.sendAction('nextPage');
          } else {
            this.transitionNextQuestion();
          }
        }
      },
      prevQuestion: function prevQuestion() {
        if (!this.get("isTransitioning")) {
          this.sendAction('didMoveBack');

          if (this.get("customComponentName") || this.get('response.isFirstQuestionOfPage')) {
            this.sendAction('prevPage');
          } else {
            this.transitionPrevQuestion();
          }
        }
      }
    }
  });
});