define('frontend/pods/components/provider/login-code-display/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    codeDurationString: Ember.computed('institute', function () {
      var durationMinutes = this.get('institute.configuration.loginCodeValidityMinutes');
      return _moment.default.duration(durationMinutes, 'minutes').humanize();
    })
  });
});