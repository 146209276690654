define('frontend/pods/components/provider/assessment/report-v2/text-synopsis/dsm5-summary/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/text-synopsis/dsm5-summary/dsm-scale', 'lodash'], function (exports, _dsmScale, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    dsmGroups: [{
      mnemonic: 'anxiety',
      scaleMnemonics: ['somaticAnxiety', 'hurtfulRumination', 'gad7'],

      allQuestionMnemonics: ['afraidDontKnowWhy', 'stomachDigestionDifficulties', 'bodyTenseAndHurts', 'fearHealthBodyWrong', 'needToRuminateLess', 'constantlySelfCriticize', 'mostlyRestlessUneasy']
    }, {
      mnemonic: 'adhd',
      scaleMnemonics: [],

      allQuestionMnemonics: ['enoughSleepRested', 'goodAtRelaxing']
    }, {
      mnemonic: 'risk',
      scaleMnemonics: ['suicideRisk'],
      codes: { dsm5: [{ code: 'V15.59', description: 'Personal history of self-harm' }], icd10: [{ code: 'Z91.5', description: 'Personal history of self-harm' }] },
      allQuestionMnemonics: ['thoughtsOfSuicide', 'fearLoseControlSuicide', 'plansForHowSuicide', 'betterOffDead']
    }, {
      mnemonic: 'ptsd',
      scaleMnemonics: ['pcl', 'hypervigilance'],
      codes: { dsm5: [{ code: '309.81', description: 'Posttraumatic stress disorder' }, { code: 'F43.9', description: 'Unspecified trauma- and stressor-related disorder' }], icd10: [{ code: 'F43.10', description: 'Posttraumatic stress disorder' }, { code: 'F43.9', description: 'Unspecified trauma- and stressor-related disorder' }] },
      allQuestionMnemonics: ['effortEvaluatingSafety', 'alwaysPreparedForBad', 'feelSafeInHome', 'easyToTrustPeopleIMeet']
    }, {
      mnemonic: 'depression',
      scaleMnemonics: ['demoralization', 'phq9'],

      allQuestionMnemonics: ['noOneUndestandsGivenUp', 'feelingDepressed', 'givenUpHopeForFuture', 'trappedAndUncertain', 'havePeopleForSupport']
    }, {
      mnemonic: 'social',
      scaleMnemonics: ['connectedness'],

      allQuestionMnemonics: ['comfortableSharingEmotions', 'easyToTrustPeopleIMeet', 'canFormStrongAttachments', 'discomfortPrefersAlone', 'feelAloneWithOthers']
    }, {
      mnemonic: 'panic',
      scaleMnemonics: [],

      allQuestionMnemonics: []
    }, {
      mnemonic: 'phobia',
      scaleMnemonics: ['avoidance'],

      allQuestionMnemonics: ['doEverythingToAvoidSomething', 'tryToAvoidSomeEmotions']
    }, {
      mnemonic: 'agoraphobia',
      scaleMnemonics: [],
      codes: { dsm5: [{ code: '300.22', description: 'Agoraphobia' }], icd10: [{ code: 'F40.00', description: 'Agoraphobia' }] },
      allQuestionMnemonics: []
    }, {
      mnemonic: 'ocd',
      scaleMnemonics: [],

      allQuestionMnemonics: ['workToAvoidPainfulThoughts']
    }, {
      mnemonic: 'psychosis',
      scaleMnemonics: ['psychosis'],

      allQuestionMnemonics: ['feelSecretOrgWatching', 'stayAloneForLife', 'worriedPeopleHearMyThoughts']
    }, {
      mnemonic: 'eating',
      scaleMnemonics: ['eating'],

      allQuestionMnemonics: ['importantToControlEating', 'effortReadingAndPlanningFood', 'fearLosingFoodControl', 'stomachDigestionDifficulties', 'eatingRestrictsSocial', 'dontHaveBodyIShould']
    }, {
      mnemonic: 'substanceUse',
      scaleMnemonics: ['bamUse', 'bamProtectiveFactors', 'bamRiskFactors'],

      allQuestionMnemonics: []
    }, {
      mnemonic: 'mania',
      scaleMnemonics: [],

      allQuestionMnemonics: []
    }, {
      mnemonic: 'sleep',
      scaleMnemonics: [],

      allQuestionMnemonics: ['enoughSleepRested']
    }, {
      mnemonic: 'borderline',
      scaleMnemonics: [],

      allQuestionMnemonics: ['easilyAnnoyedByOthers', 'feelAloneWithOthers']
    }],

    symptoms: Ember.computed('answerMap', 'scaleValues', function () {
      var answerMap = this.getWithDefault('answerMap', {});
      var scaleValues = this.getWithDefault('scaleValues', []);
      return _lodash.default.map(this.get('dsmGroups'), function (g) {
        return _dsmScale.default.create({
          mnemonic: g.mnemonic,
          isBeneficial: g.isBeneficial,
          measureType: g.mnemonic,
          codes: g.codes,
          //attach the actual scale for each mnemonic
          scales: _lodash.default.map(g.scaleMnemonics, function (mnemonic) {
            return scaleValues.find(function (s) {
              return s.get('scale.mnemonic') === mnemonic;
            });
          }).filter(function (s) {
            return s;
          }),
          answers: _lodash.default.map(g.allQuestionMnemonics, function (mnemonic) {
            return answerMap[mnemonic];
          })
        });
      });
    }),

    abnormalSymptoms: Ember.computed('symptoms', function () {
      var scales = this.get('symptoms');
      return scales.filter(function (s) {
        return s.get('isAbnormal');
      });
    }),

    normalSymptoms: Ember.computed('symptoms', function () {
      var scales = this.get('symptoms');
      return scales.filter(function (s) {
        return !s.get('isAbnormal');
      });
    })
  });
});