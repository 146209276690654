define("frontend/adapters/survey/calculate-messages-request", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // Ensure we get full details of the care episode messages when we post
    urlForCreateRecord: function urlForCreateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=care-episode-messages";
      return value;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var value = this._super.apply(this, arguments);
      value = value + "?include=care-episode-messages";
      return value;
    }
  });
});