define("frontend/instance-initializers/sentry-raven", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    var raven = app.lookup("service:raven");
    var buildInfo = app.lookup("buildInfo:main");

    raven.callRaven("setRelease", buildInfo.build);
  }

  exports.default = {
    name: 'sentry-raven',
    initialize: initialize
  };
});