define('frontend/pods/components/common/controls/spinner-button/component', ['exports', 'frontend/pods/components/common/controls/spinner-button/async-button'], function (exports, _asyncButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _asyncButton.default.extend({
    intl: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var pendingText = this.get('pending');
      if (!pendingText) {
        this.set('pending', this.get('intl').t('common.asyncButton.saving'));
      }
    },

    isPending: Ember.computed('textState', function () {
      return this.get('textState') === 'pending';
    })
  });
});