define('frontend/helpers/negative', ['exports', 'ember-cli-d3/helpers/negative'], function (exports, _negative) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _negative.default;
    }
  });
  Object.defineProperty(exports, 'negative', {
    enumerable: true,
    get: function () {
      return _negative.negative;
    }
  });
});