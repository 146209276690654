define('frontend/pods/components/provider/assessment/participant-scale-chooser-v2/scale-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['active-scale'],
    report: Ember.computed.oneWay('reportScale.report'),
    scale: Ember.computed.oneWay('reportScale.scale'),
    badgeText: Ember.computed("reportScale.maxInvitationStatus", function () {
      var maxInvitationStatus = this.get("reportScale.maxInvitationStatus");

      // We don't show badges for the early stages.
      switch (maxInvitationStatus) {
        case null:
        case undefined:
        case "future":
        case "new":
        case "scheduled":
          return null;
        default:
          return maxInvitationStatus;
      }
    }),
    hasParticipants: Ember.computed.gt("eligibleParticipants.length", 0),
    eligibleParticipants: Ember.computed('scale.allowedParticipants', 'allParticipants', 'providers.[]', function () {
      var allowedParticipants = this.get("scale.defaultParticipants");

      var participants = this.get("allParticipants").filter(function (p) {
        return allowedParticipants.includes(p.get('participantType'));
      });

      if (allowedParticipants.includes('provider')) {
        participants.addObjects(this.get('providers'));
      }

      // We need to turn promises into models to make sure uniq works
      var resolved = participants.map(function (p) {
        return p.content ? p.content : p;
      });

      return resolved.uniq();
    }),
    showParticipantChooser: Ember.computed.gt('eligibleParticipants.length', 1),
    selectedParticipants: Ember.computed('reportScale.scaleParticipants.@each.isDeleted', function () {
      return this.get("reportScale.scaleParticipants").filterBy('isDeleted', false).mapBy('participant');
    }),
    actions: {
      participantUpdated: function participantUpdated(_unknown, currentParticipants) {
        var reportScale = this.get('reportScale');
        var report = this.get('report');

        if (report.content) {
          report = report.content;
        }

        var previous = this.get("selectedParticipants");
        var removed = previous.filter(function (p) {
          return !currentParticipants.includes(p);
        });
        var added = currentParticipants.filter(function (p) {
          return !previous.includes(p);
        });

        removed.forEach(function (p) {
          report.removeParticipantFromScale(reportScale, p);
        });

        report.addParticipantsToScale(reportScale, added);
      },
      showScaleDetails: function showScaleDetails() {
        this.sendAction('onScaleDetailSelect', this.get('scale'));
      },
      removeScale: function removeScale() {
        var report = this.get('report');

        if (report.content) {
          report = report.content;
        }

        report.removeReportScale(this.get("reportScale"));
      },
      toggleInterviewFormat: function toggleInterviewFormat() {
        var reportScale = this.get('reportScale');

        reportScale.set('format', reportScale.get('format') === 'interview' ? 'self_report' : 'interview');
        var report = this.get('report');

        if (report.content) {
          report = report.content;
        }

        report.changeScaleFormat(this.get("reportScale"));
      },
      setPhase: function setPhase(phase) {
        var reportScale = this.get("reportScale");
        reportScale.set('phase', phase);

        var report = this.get('report');

        if (report.content) {
          report = report.content;
        }

        report.changeScaleFormat(this.get("reportScale"));
      }
    }
  });
});