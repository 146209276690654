define('frontend/pods/components/internal/import/data-file-list/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    messages: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var tableColumns = [{
        propertyName: "updatedAt",
        sortPrecedence: 1,
        sortDirection: "desc",
        template: "components/common/server-side-table/absolute-date",
        title: "Updated At"
      }, {
        propertyName: "institute.shortcode",
        title: "Institute"
      }, {
        propertyName: "fileName",
        title: "File Name"
      }, {
        propertyName: "status",
        title: "Status",
        disableSorting: true
      }, {
        propertyName: "recordsCreated",
        title: "Created",
        disableSorting: true
      }, {
        propertyName: "recordsUpdated",
        title: "Updated",
        disableSorting: true
      }, {
        propertyName: "recordsSkipped",
        title: "Skipped",
        disableSorting: true
      }, {
        propertyName: "recordsErrored",
        title: "Errored",
        disableSorting: true
      }, {
        propertyName: "actions",
        title: "Actions",
        template: "components/internal/import/data-file-list/action-buttons",
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 25);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var data = this.get("store").query('import/dataFile', { include: "institute", sort: "-updatedAt" });
        this.set("data", data);
      }
    },

    actions: {
      download: function download(record) {
        var _this = this;

        var request = this.get("store").createRecord("import/dataFileDownload", { dataFile: record });

        request.save().then(function (success) {
          _this.get("messages").info(_this.get('intl').t("providerAdmin.bulkExport.beginDownload"));
          window.location = success.get("downloadUrl");
        }, function (failure) {
          _this.get('messages').danger(_this.printError(failure));
        });
      }
    }

  });
});