define('frontend/pods/provider/assessment/invitation/exit/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    model: function model() {
      return this.modelFor("provider.assessment.invitation");
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Trigger the update to in progress
      model.set("statusEvent", 'assessment_opened');
      model.set("takingUser", this.get("sessionUser.currentUser"));

      var assessmentUrl = model.get("assessmentUrl"); // Grab this here because it won't be present when we come back.

      var doRedirect = function doRedirect() {
        _this.get("metrics").trackEvent({ event: "provider.assessment.invitation.goToAssessment" });
        var host = document.location.origin;
        var reflink = '' + host + _this.get("router").generate("provider.assessment.invitation.return", model);

        // Explicitly generate urls for our local survey capability which lets us transition quicker.
        if (assessmentUrl && assessmentUrl.indexOf('/') >= 0) {
          var remoteUrl = assessmentUrl + '&reflink=' + reflink;
          window.location.replace(remoteUrl);
        } else {
          // TODO, possibly make the route generic rather than patient.
          var localUrl = _this.get("router").generate("survey.taking.provider.begin") + '?' + assessmentUrl + '&reflink=' + reflink;
          _this.get("router").transitionTo(localUrl);
        }
      };

      var handleError = function handleError() {
        _this.get("metrics").trackEvent({ event: "provider.assessment.invitation.assessmentError" });
      };

      return model.save().then(doRedirect, handleError);
    }
  });
});