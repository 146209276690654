define('frontend/pods/components/provider/reports/clinician-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    todaysDate: new Date(),
    totalPatients: 32,
    patientsAssessedOnce: 17,
    severityLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    severityFrequencies: [1, 0, 1, 2, 1, 3, 2, 1, 1, 5],
    severityGraphData: Ember.computed('severityLabels', 'severityFrequencies', function () {
      var freqs = this.get('severityFrequencies');
      return this.get('severityLabels').map(function (l, i) {
        return { label: l, value: freqs[i] };
      });
    }),
    symptomFrequencyData: [{ label: 'psychosis', value: 4 }, { label: 'expressedTherapyNeeds', value: 4 }, { label: 'relationalDistress', value: 3 }, { label: 'perfectionism', value: 3 }, { label: 'avoidance', value: 3 }, { label: 'suicideRisk', value: 2 }, { label: 'somaticAnxiety', value: 2 }, { label: 'negativeAffect', value: 2 }, { label: 'substanceUse', value: 1 }, { label: 'alliance', value: 1 }, { label: 'attachment', value: 1 }, { label: 'hypervigilance', value: 1 }, { label: 'emotionalDistance', value: 1 }],
    completionTimeData: [{ label: "1-3", value: 0 }, { label: "4-7", value: 1 }, { label: "8-11", value: 1 }, { label: "12-15", value: 4 }, { label: "16-20", value: 5 }, { label: "21-25", value: 2 }, { label: "26-30", value: 2 }, { label: "30+", value: 0 }],
    endorsedCountData: [{ label: '0', value: 0 }, { label: '1', value: 0 }, { label: '2-3', value: 1 }, { label: '4-6', value: 3 }, { label: '7-9', value: 3 }, { label: '10+', value: 7 }],
    symptomTrendData: [{ label: 'alliance', value: -1.1625, class: 'none' }, { label: 'resilience', value: 0.47540099243055756, class: 'none' }, { label: 'attachment', value: -0.3620776775118816, class: 'none' }, { label: 'demoralization', value: 0.22914582804808695, class: 'severe' }, { label: 'avoidance', value: 0.2906975476601608, class: 'severe' }, { label: 'hypervigilance', value: 0.7341940829531917, class: 'severe' }, { label: 'perfectionism', value: 0.7293197176940132, class: 'severe' }, { label: 'emotionalDistance', value: -0.22043995258966334, class: 'severe' }, { label: 'negativeAffect', value: 0.02540307604564529, class: 'severe' }, { label: 'relationalDistress', value: 0.20724011071862114, class: 'severe' }],
    repeatPatients: 8,
    totalAssessments: 41,
    actions: {
      print: function print() {
        window.print();
      }
    }
  });
});