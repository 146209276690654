define('frontend/pods/provider/user/confirm/route', ['exports', 'frontend/mixins/unauthenticated-route-mixin', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _unauthenticatedRouteMixin, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_unauthenticatedRouteMixin.default, _metricsMixin.default, {
    messages: Ember.inject.service(),
    breadCrumb: null,
    intl: Ember.inject.service(),
    queryParams: {
      confirmationToken: {}
    },
    metrics: Ember.inject.service(),
    model: function model(params) {
      return Ember.$.ajax({
        type: 'GET',
        url: "/users/confirm",
        dataType: "json",
        accepts: {
          text: "application/json"
        },
        data: { confirmation_token: params.confirmationToken }
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "userConfirmationPage" });
      },
      error: function error() /*error, transition*/{
        this.get("messages").warning(this.get('intl').t('provider.confirmUserExpired.description'));
        this.transitionTo("provider.login");
      }
    }
  });
});