define("frontend/pods/components/provider/care-episodes/care-episode-summary/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    actions: {
      onDischarge: function onDischarge(careEpisode) {
        this.sendAction("onDischarge", careEpisode);
      }
    }
  });
});