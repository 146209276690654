define('frontend/pods/provider/admin/users/create/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    preload: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get("preload").ensure();
    },
    model: function model() {
      return Ember.RSVP.hash({
        provider: this.store.createRecord('provider', { user: this.store.createRecord('user'), practicing: true }),
        roles: this.get("preload.providerRoles"),
        organizations: this.get("preload.organizations")
      });
    }
  });
});