define('frontend/pods/components/provider/assessment/scale-browser/scale-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    showDetailedScoring: false,
    actions: {
      unfocus: function unfocus() {
        this.get("onUnfocus")();
      },
      toggleScaleSelect: function toggleScaleSelect() {
        this.get("onScaleChange")(this.get("scale"), false);
      },
      toggleScaleSelectAndClose: function toggleScaleSelectAndClose() {
        this.get("onScaleChange")(this.get("scale"), true);
        this.get("onUnfocus")();
      }
    }
  });
});