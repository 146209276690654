define("frontend/helpers/assessment/user-color", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      return Ember.String.htmlSafe("color: " + params[0].getColorForUser(params[1]));
    }
  });
});