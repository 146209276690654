define('frontend/pods/provider/patients/show/related-people/edit/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    preload: Ember.inject.service(),
    model: function model(params) {
      var patient = this.modelFor("provider.patients.show");
      var relatedPerson = this.store.findRecord('relatedPerson', params.related_person_id);

      return Ember.RSVP.hash({
        patient: patient,
        relatedPerson: relatedPerson
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.relatedPerson.edit" });
      }
    }
  });
});