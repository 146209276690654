define('frontend/initializers/remove-autocomplete-from-forms', ['exports', 'ember-rapid-forms/components/em-form'], function (exports, _emForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    _emForm.default.reopen({
      attributeBindings: ['autocomplete'],

      autocomplete: "off"
    });
  }

  exports.default = {
    name: 'remove-autocomplete-from-forms',
    initialize: initialize
  };
});