define('frontend/models/patient-flag', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    active: _emberData.default.attr("boolean"),
    notes: _emberData.default.attr("string"),
    eventDate: _emberData.default.attr("date"),
    flag: _emberData.default.belongsTo("flag"),
    patient: _emberData.default.belongsTo("patient"),
    user: _emberData.default.belongsTo("user")
  });
});