define('frontend/pods/related-person/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    beforeModel: function beforeModel() {
      return this.replaceWith('related-person.login');
    }
  });
});