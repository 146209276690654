define('frontend/pods/provider/admin/configuration/index/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),
    queryParams: {
      path: {},
      searchTerm: {}
    },

    rootPath: Ember.computed('institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    sessionUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      if (!this.get('sessionUser').hasPermission(['bulkUpload'])) {
        return Ember.RSVP.reject('Permission denied');
      }

      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations")
      }).then(function () {
        return _this.get("preload.organizationEntities");
      });
    },
    model: function model(params) {
      var path = params.path || this.get('rootPath');
      return Ember.RSVP.hash({
        entityTreeNode: this.get("store").findRecord('entityTreeNode', path),
        providerEntities: this.get("preload.providerEntities"),
        organizationEntities: this.get("preload.organizationEntities"),
        treatmentServiceEntities: this.get("preload.treatmentServiceEntities"),
        panelEntities: this.get("preload.panelEntities")
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({ path: undefined });
      }
    }
  });
});