define('frontend/models/report-entity-tree-node', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    entityTreeNode: _emberData.default.belongsTo('entityTreeNode'),
    entityTreeCollections: _emberData.default.hasMany('entityTreeCollection'),
    summaryData: _emberData.default.attr(),
    comparisonData: _emberData.default.attr(),
    summaryGraphData: _emberData.default.attr(),
    comparisonGraphData: _emberData.default.attr(),
    graphInterval: _emberData.default.attr('string')
  });
});