define('frontend/pods/components/provider/billing/charge-explorer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'charge-explorer page__wide-content',
    institute: Ember.inject.service(),
    showAdvancedFilter: false,
    statusOptions: ['billable', 'not_billable', 'billed', 'finalized', 'planned'],

    actions: {
      resetRoot: function resetRoot() {
        this.set('reportParams.path', undefined);
      },
      pathSelected: function pathSelected(path) {
        var _this = this;

        if (!path) {
          path = this.get("rootPath");
        }

        this.get("store").findRecord('entityTreeNode', path).then(function (node) {
          _this.set("entityTreeNode", node);
        });
      },
      entityTreeNodeSelected: function entityTreeNodeSelected(entity) {
        entity = entity.content ? entity.content : entity;
        this.set("entityTreeNode", entity);
      },
      updateSelectedStatuses: function updateSelectedStatuses(statuses) {
        this.set('reportParams.statuses', statuses);
      },
      setDateRange: function setDateRange(dateRange) {
        this.get("reportParams").setProperties({
          preset: dateRange.preset,
          start: dateRange.startTime,
          end: dateRange.endTime
        });
      },
      goToBillable: function goToBillable() {
        this.get('reportParams').setProperties({ path: undefined, statuses: ['billable'] });
      },
      goToBilled: function goToBilled() {
        this.get('reportParams').setProperties({ path: undefined, statuses: ['billed'] });
      }
    },

    data: Ember.computed('entityTreeNode.path', 'reportParams.statuses', 'reportParams.start', 'reportParams.end', 'reportParams.preset', function () {

      var dateFilter = this.get("reportParams").getProperties('preset', 'start', 'end');

      if (dateFilter.start) {
        dateFilter.start = dateFilter.start.format('YYYY-MM-DD');
      }

      if (dateFilter.end) {
        dateFilter.end = dateFilter.end.format('YYYY-MM-DD');
      }

      return Ember.Object.create({
        store: this.get("store"),
        modelName: 'billing/charge',
        query: { include: "payor-contract.institute-payor,charge-item-code,patient-session,patient,organization,provider,payor-contract-code",
          filter: { for_path: this.get('entityTreeNode.path'), statuses: this.get('reportParams.statuses'), service_date: dateFilter }
        }
      });
    })
  });
});