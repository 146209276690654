define('frontend/pods/components/provider/plan/demographics-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['demographics-filter'],
    // ageOptions: ['all'].addObjects([...Array(100).keys()].map(s => s.toString())), // Make them strings to be searchable
    // genderOptions: ['all', 'M', 'F'],
    ageOptions: [].concat(_toConsumableArray(Array(100).keys())).map(function (s) {
      return s.toString();
    }), // Make them strings to be searchable
    genderOptions: ['M', 'F'],
    actions: {
      updateAge: function updateAge(newAge) {
        this.set('demographics.age', newAge ? parseInt(newAge) : null);
      },
      updateGender: function updateGender(newGender) {
        this.set('demographics.gender', newGender);
      }
    }
  });
});