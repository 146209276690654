define('frontend/pods/components/provider/assessment/report-v2/full-answer-detail/patient-overview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    renderedOpenEndedQuestions: ['counselingGoals', 'currentProblemStrategy', 'otherTopics'],

    // If we don't know gender, substitute 'client' instead, but don't do it in general as client isn't a gender.
    genderString: Ember.computed("report.patient.gender", function () {
      if (this.get("report.patient.genderIdentity")) {
        return this.get("report.patient.genderIdentityString");
      } else if (this.get("report.patient.gender")) {
        return this.get("report.patient.genderString");
      } else {
        return this.get('intl').t("common.patient");
      }
    }),

    openEndedQuestions: Ember.computed('report', function () {
      return this.getWithDefault('report.holisticResponses', {});
    })
  });
});