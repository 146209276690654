define('frontend/models/entity-tree-collection', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var iconMap = {
    institutes: 'fa fa-hospital-o',
    organizations: 'fa fa-hospital-o',
    providers: 'fa fa-user-md',
    patients: 'fa fa-user'
  };

  exports.default = _application.default.extend({
    intl: Ember.inject.service(),
    path: _emberData.default.attr('string'),
    collection: _emberData.default.attr('string'),
    entityTreeNode: _emberData.default.belongsTo('entity-tree-node'),
    childTreeNodes: _emberData.default.hasMany('entity-tree-node'),

    childEntities: _emberData.default.hasMany('entities', { inverse: null }),

    name: Ember.computed('collection', function () {
      return this.get('intl').t('model.entity.collections.' + this.get('collection'));
    }),
    icon: Ember.computed('collection', function () {
      return iconMap[this.get('collection')];
    })
  });
});