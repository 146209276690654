define('frontend/pods/provider/admin/payors/index/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      return {
        institutePayors: this.store.query('institutePayor', { page: { limit: 300 }, include: 'billing-payor-contracts', sortBy: 'name' })
      };
    }
  });
});