define('frontend/models/entity-tree-node', ['exports', 'frontend/models/entity', 'ember-data'], function (exports, _entity, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isChildOf = exports.differenceSubSegments = exports.createFromEntities = exports.createFromSubsegment = undefined;


  var createFromSubsegment = function createFromSubsegment(segment, store) {
    var record = store.peekRecord('entityTreeNode', segment.current.path);
    if (record) {
      return record;
    }

    return store.createRecord("entityTreeNode", {
      id: segment.current.path,
      path: segment.current.path,
      segments: segment.segments,
      entity: (0, _entity.fromSegment)(segment.current, store)
    });
  };

  // Pass in an array of entities.
  var createFromEntities = function createFromEntities(entities, store) {
    var segments = [];

    var filtered = entities.compact().filterBy('id').rejectBy('isNew', true); // only create from persisted entities

    var path = filtered.reduce(function (path, entity) {
      var lead = path ? path + '/' : '';
      return lead + entity.get('entityType') + '/' + entity.get('id');
    }, null);

    if (Ember.isEmpty(filtered)) {
      return null;
    }

    var entity = filtered[filtered.length - 1];

    if (entity.content) {
      entity = entity.content;
    }

    var lastSegment = null;

    var demographicsPresent = false;

    filtered.forEach(function (entity) {
      var lead = lastSegment ? lastSegment.path + '/' : '';
      var path = lead + entity.get('entityType') + '/' + entity.get('id');

      lastSegment = {
        name: entity.get('name'),
        type: entity.get('entityType'),
        id: entity.get('id'),
        path: path
      };

      if (entity.get('entityType') === 'patient') {
        demographicsPresent = true;
      }

      segments.addObject(lastSegment);
    });

    var record = store.peekRecord('entityTreeNode', path);

    if (record) {
      return record;
    } else {
      return store.createRecord("entityTreeNode", {
        id: path,
        path: path,
        entity: entity.toEntity(),
        segments: segments,
        demographicsPresent: demographicsPresent,
        filterData: {}
      });
    }
  };

  var isChildOf = function isChildOf(parent, candidate) {
    return candidate.get('path').indexOf(parent.get('path')) >= 0;
  };

  // Common segements:
  // If paths same = return just the path
  // If planNode lower than current node, shouldn't be here
  // If planNode higher than current node, return segments between the two, inclusive.
  // Fall back on current node.
  var differenceSubSegments = function differenceSubSegments(parent, child) {
    if (isChildOf(parent, child)) {
      var childSegments = child.get('segmentsWithSubSegments');
      var parentSegmentPaths = parent.get('parentSegments').mapBy('path');

      // Reject anything which is lower than the parent level.
      return childSegments.reject(function (segment) {
        return parentSegmentPaths.indexOf(segment.current.path) >= 0;
      });
    } else {
      // If they are not children, return just the single segment of the more specific
      // If they are not children, return just the single segment of the child
      var segments = child.get('segmentsWithSubSegments');
      return segments[segments.length - 1];
    }
  };

  exports.createFromSubsegment = createFromSubsegment;
  exports.createFromEntities = createFromEntities;
  exports.differenceSubSegments = differenceSubSegments;
  exports.isChildOf = isChildOf;
  exports.default = _entity.default.extend({
    intl: Ember.inject.service(),
    path: _emberData.default.attr('string'),
    filter: _emberData.default.attr('string'),
    parentPath: _emberData.default.attr('string'),
    entity: _emberData.default.belongsTo('entity'),
    parent: _emberData.default.belongsTo('entity-tree-node', { inverse: null }),
    collections: _emberData.default.hasMany('entity-tree-collection', { inverse: 'entityTreeNode' }),
    segments: _emberData.default.attr(),
    demographics: _emberData.default.attr(),
    demographicsPresent: _emberData.default.attr('boolean'),
    filterData: _emberData.default.attr(),

    parentSegments: Ember.computed('segments.[]', function () {
      var segments = this.getWithDefault('segments', []);
      return segments.slice(0, segments.length - 1);
    }),

    segmentsWithSubSegments: Ember.computed('segments.[]', function () {
      var segments = this.get("segments");

      return segments.map(function (segment, index) {
        return {
          current: segment,
          segments: segments.slice(0, index + 1)
        };
      });
    }),

    reverseSubsegments: Ember.computed('segments.[]', function () {
      var segments = this.get("segments");

      return segments.reverse().map(function (segment, index) {
        return {
          current: segment,
          segments: segments.slice(index, segments.length)
        };
      });
    }),

    patient: Ember.computed('path', function () {
      return this.entityForType('patient');
    }),

    filterEntities: Ember.computed('filterData', function () {
      var _this = this;

      return Object.values(this.getWithDefault('filterData', {})).flatMap(function (value) {
        if (value.id) {
          return [(0, _entity.fromSegment)(value, _this.get('store'))];
        } else {
          return [];
        }
      });
    }),

    filterSummary: Ember.computed('filterData', function () {
      var intl = this.get('intl');
      var filterData = this.getWithDefault('filterData', {});

      var minAge = Ember.get(filterData, 'min-age.value');
      var maxAge = Ember.get(filterData, 'max-age.value');
      var gender = Ember.get(filterData, 'gender.value');

      var ageSummary = null;

      if (minAge && maxAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.minMax', { minAge: minAge, maxAge: maxAge });
      } else if (maxAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.max', { maxAge: maxAge });
      } else if (minAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.min', { minAge: minAge });
      }

      var genderSummary = gender ? intl.t('model.gender.' + gender) : null;

      var entities = this.get("filterEntities").mapBy('name');

      return [ageSummary, genderSummary].addObjects(entities).compact().join(", ");
    }),

    entityForType: function entityForType(entityType) {
      var segment = this.get('segments').find(function (seg) {
        return entityType === seg.type;
      });

      if (!segment && this.get('filterData')) {
        segment = this.get('filterData')[entityType];
      }

      if (segment) {
        var existing = this.get("store").peekRecord('entity', segment.id);

        if (existing) {
          return existing;
        } else {
          return (0, _entity.fromSegment)(segment, this.get('store'));
        }
      } else {
        return null;
      }
    },
    filterValue: function filterValue(filter) {
      var result = this.get('filterData')[filter];

      if (result) {
        return result.value;
      }
    }
  });
});