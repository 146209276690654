define('frontend/utils/date-range-presets', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = calculateTimePreset;


  var operations = {
    today: {
      scope: 'day',
      offset: 0
    },
    yesterday: {
      scope: 'day',
      offset: 1
    },
    thisWeek: {
      scope: 'isoWeek',
      durationScope: 'week',
      offset: 0,
      endAtNow: true
    },
    lastWeek: {
      scope: 'isoWeek',
      durationScope: 'week',
      offset: 1
    },
    last30Days: {
      durationScope: 'day',
      offset: 30
    },
    thisMonth: {
      scope: 'month',
      offset: 0,
      endAtNow: true
    },
    lastMonth: {
      scope: 'month',
      offset: 1
    },
    thisQuarter: {
      scope: 'quarter',
      offset: 0,
      endAtNow: true
    },
    lastQuarter: {
      scope: 'quarter',
      offset: 1
    },
    last90Days: {
      durationScope: 'day',
      offset: 90
    },
    thisYear: {
      scope: 'year',
      offset: 0,
      endAtNow: true
    },
    lastYear: {
      scope: 'year',
      offset: 1
    }
  };

  function calculateTimePreset(preset) {
    // With no operations defined we set null dates
    if (!Object.prototype.hasOwnProperty.call(operations, preset)) {
      return {
        startTime: null,
        endTime: null
      };
    }

    var _operations$preset = operations[preset],
        scope = _operations$preset.scope,
        durationScope = _operations$preset.durationScope,
        offset = _operations$preset.offset,
        endAtNow = _operations$preset.endAtNow;


    var duration = _moment.default.duration(offset, durationScope || scope);
    var pivotDate = (0, _moment.default)().subtract(duration);

    if (scope) {
      return {
        startTime: pivotDate.clone().startOf(scope),
        endTime: endAtNow ? (0, _moment.default)().endOf('day') : pivotDate.clone().endOf(scope)
      };
    } else {
      return {
        startTime: pivotDate,
        endTime: (0, _moment.default)()
      };
    }
  }
});