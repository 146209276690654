define('frontend/pods/components/provider/experiments/experiment-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        "propertyName": "name",
        "title": intl.t('model.experiment.name')
      }, {
        "component": "components/provider/entities/entity-path-with-filter-column",
        "title": intl.t('model.experiment.path'),
        "disableSorting": true
      }, {
        "propertyName": "author.name",
        "title": intl.t('model.experiment.author')
      }, {
        "propertyName": "enabled",
        "title": intl.t('model.experiment.enabled')
      }, {
        "title": intl.t('common.actions'),
        "template": "components/provider/experiments/experiment-list/action-buttons"
      }];

      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var data = Ember.Object.create({
          store: this.get("store"),
          modelName: 'experiment',
          query: { include: "consent,experiment-variants,entity-tree-node,author", sort: "name" }
        });
        this.set("data", data);
      }
    }
  });
});