define('frontend/models/billing/charge-item', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    charge: _emberData.default.belongsTo('billing/charge', { async: false }),
    assessmentReportScaleParticipant: _emberData.default.belongsTo('assessment/reportScaleParticipant', { async: false }),
    patientSession: _emberData.default.belongsTo('patientSession', { async: false }),
    chargeItemCode: _emberData.default.belongsTo('billing/chargeItemCode', { async: false }),

    status: _emberData.default.attr('string'),

    algorithmState: _emberData.default.attr('string'),
    eligibilityStatus: _emberData.default.attr('string'),
    dataChanged: _emberData.default.attr('boolean'),

    quantity: _emberData.default.attr('number')
  });
});