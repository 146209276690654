define("frontend/mixins/provider-filter-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    filter: Ember.inject.service("provider/filter"),

    queryParams: {
      patientProviderMode: {
        refreshModel: true,
        replace: true
      },
      patientProviderId: {
        refreshModel: true,
        replace: true
      },
      patientOrganizationId: {
        refreshModel: true,
        replace: true
      },
      patientOrganizationMode: {
        refreshModel: true,
        replace: true
      },
      showTestData: {
        refreshModel: true,
        replace: true
      }
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      return this._setupFilters(transition.state.queryParams);
    },
    _setupFilters: function _setupFilters(queryParams) {
      if (queryParams.patientProviderMode) {
        this.set("filter.patientProviderMode", queryParams.patientProviderMode);
      }
      if (queryParams.patientProviderId) {
        this.set("filter.patientProviderId", queryParams.patientProviderId);
      }
      if (queryParams.patientOrganizationMode) {
        this.set("filter.patientOrganizationMode", queryParams.patientOrganizationMode);
      }
      if (queryParams.patientOrganizationId) {
        this.set("filter.patientOrganizationId", queryParams.patientOrganizationId);
      }
      if (queryParams.showTestData) {
        this.set("filter.showTestData", queryParams.showTestData === 'true');
      }
    },


    changeRouteObserver: Ember.observer("filter.patientProviderMode", "filter.patientProviderId", "filter.patientOrganizationMode", "filter.patientOrganizationId", "filter.showTestData", function () {
      this.get("router").transitionTo({
        queryParams: this.get("filter").urlParams()
      });
    })
  });
});