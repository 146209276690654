define('frontend/models/patient-session-type', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    mnemonic: _emberData.default.attr("string"),
    name: _emberData.default.attr("string")
  });
});