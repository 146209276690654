define('frontend/locales/map', ['exports', 'frontend/locales/en/translations', 'frontend/locales/es/translations'], function (exports, _translations, _translations2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: _translations.default,
    es: _translations2.default
  };
});