define('frontend/utils/assessment/scoremaps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  //Maps questions to severity of particular answers on a scale of 1-7.  7 is most sever (red), 1 is ok (green)
  // this should eventually be replaced with proper threshold classes in the DB

  var defaultInverseMap = {
    '1': 7,
    '2': 6,
    '3': 5,
    '4': 4,
    '5': 3,
    '6': 2,
    '7': 1
  };

  var therapyNeedsMap = {
    '-5': 7,
    '-4': 6,
    '-3': 5,
    '-2': 4,
    '-1': 2,
    '0': 1,
    '1': 2,
    '2': 5,
    '3': 5,
    '4': 6,
    '5': 7
  };

  var phq9Map = {
    '0': 1,
    '1': 5,
    '2': 6,
    '3': 7
  };

  var gad7Map = {
    '0': 1,
    '1': 2,
    '2': 4,
    '3': 6
  };

  var pcl5Map = {
    '0': 1,
    '1': 2,
    '2': 4,
    '3': 6,
    '4': 7
  };

  var bamMap = {
    '0': 1,
    '1': 2,
    '2': 4,
    '3': 5,
    '4': 7
  };

  var bamInverseMap = {
    '0': 7,
    '1': 5,
    '2': 4,
    '3': 2,
    '4': 1
  };

  var sfssMap = {
    '1': 1,
    '2': 2,
    '3': 4,
    '4': 5,
    '5': 7
  };

  var nsiMap = {
    '0': 1,
    '1': 4,
    '2': 4,
    '3': 5,
    '4': 7
  };

  var sfssInverseMap = {
    '1': 7,
    '2': 5,
    '3': 4,
    '4': 2,
    '5': 1
  };
  var ptpbChsMap = {
    '1': 7,
    '2': 7,
    '3': 5,
    '4': 4,
    '5': 2,
    '6': 1
  };
  var sssMap = {
    '1': 7,
    '2': 5,
    '3': 3,
    '4': 1
  };

  var fivePointStrength = {
    '1': 7,
    '2': 6,
    '3': 4,
    '4': 2,
    '5': 1
  };

  var toesMap = {
    '1': 7,
    '2': 4,
    '3': 1
  };

  var scoreMaps = {
    default: {
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 4,
      '5': 5,
      '6': 6,
      '7': 7,
      'rarelyNever': 0, //rarely
      'sometimes': 3, //sometimes
      'often': 5, //often
      'always': 7, //always
      'yes': 5,
      'no': 0
    },

    havePeopleForSupport: defaultInverseMap,
    goodFriendsKnowMe: defaultInverseMap,
    satisfiedWithSexLife: defaultInverseMap,
    easyHonestyWithFriends: defaultInverseMap,
    emotionsHelpKnowRight: defaultInverseMap,
    comfortableSharingEmotions: defaultInverseMap,
    easyToTrustPeopleIMeet: defaultInverseMap,
    canFormStrongAttachments: defaultInverseMap,
    easyToCareAboutOthers: defaultInverseMap,

    feelSafeInHome: defaultInverseMap,
    enoughSleepRested: defaultInverseMap,
    mostTimesFeel: defaultInverseMap,

    enjoyJobSchool: defaultInverseMap,
    feelThingsWillImprove: defaultInverseMap,
    goodAtRelaxing: defaultInverseMap,
    canCommunicateWhatIsImportant: defaultInverseMap,
    easyToDoThingsForFun: defaultInverseMap,
    raisedHeartRateFeelsGood: defaultInverseMap,
    likeWhoIam: defaultInverseMap,
    healthThroughActivity: defaultInverseMap,
    matterToCommunity: defaultInverseMap,
    communityValuesMe: defaultInverseMap,
    feelGoodAboutAmountOfWork: defaultInverseMap,
    understandTreatmentHelp: defaultInverseMap,
    therapistUnderstandsMe: defaultInverseMap,
    therapistAcceptsAsPerson: defaultInverseMap,
    understandPathToGetBetter: defaultInverseMap,
    feelMyMedicationHelps: defaultInverseMap,

    therapistTechniqueExerciseQuantity: therapyNeedsMap,
    therapistHumorFormalQuantity: therapyNeedsMap,
    therapistFeelingCognitionFocus: therapyNeedsMap,
    focusOnTherapistRelationship: therapyNeedsMap,

    gad7nervousAnxious: gad7Map,
    gad7cantStopWorry: gad7Map,
    gad7worryTooMuch: gad7Map,
    gad7troubleRelaxing: gad7Map,
    gad7restless: gad7Map,
    gad7annoyedIrritable: gad7Map,
    gad7AfraidAwful: gad7Map,
    gad7problemImpact: gad7Map,

    phq9littleInterestPleasure: phq9Map,
    phq9DownDepressedHopeless: phq9Map,
    phq9TroubleSleeping: phq9Map,
    phq9feelingTired: phq9Map,
    phq9appetiteOvereating: phq9Map,
    phq9feelingBadAboutSelf: phq9Map,
    phq9troubleConcentrating: phq9Map,
    phq9movingQuicklySlowly: phq9Map,
    phq9betterOffDead: phq9Map,
    phq9problemImpact: phq9Map,

    pclRepeatedMemories: pcl5Map,
    pclRepeatedDreams: pcl5Map,
    pclSuddenlyHappeningAgain: pcl5Map,
    pclUpsetOnReminder: pcl5Map,
    pclPhysicalReactionOnReminder: pcl5Map,
    pclAvoidingMemories: pcl5Map,
    pclAvoidingExternalReminders: pcl5Map,
    pclTroubleRemembering: pcl5Map,
    pclStrongNegativeBeliefs: pcl5Map,
    pclBlamingSomeoneElse: pcl5Map,
    pclStrongNegativeFeeling: pcl5Map,
    pclLostInterest: pcl5Map,
    pclFeelingDistant: pcl5Map,
    pclTroubleExperiencingPositive: pcl5Map,
    pclIrritableBehavior: pcl5Map,
    pclTakingRisks: pcl5Map,
    pclSuperAlert: pcl5Map,
    pclFeelingJumpy: pcl5Map,
    pclDifficultyConcentrating: pcl5Map,
    pclTroubleSleeping: pcl5Map,

    bamPhysicalHealth: bamMap,
    bamTroubleSleeping: bamMap,
    bamDepressedAnxious: bamMap,
    bamCravings: bamMap,
    bamRiskySituation: bamMap,
    bamArgumentsWithOthers: bamMap,

    bamAbstinenceConfidence: bamInverseMap,
    bamSelfHelpMeetings: bamInverseMap,
    bamReligionHelp: bamInverseMap,
    bamWorkFrequency: bamInverseMap,
    bamSufficientIncome: bamInverseMap,
    bamSupportFrequency: bamInverseMap,

    bamAnyAlcohol: bamMap,
    bamBingeDrinking: bamMap,
    bamIllegalDrugs: bamMap,

    sfssFeelUnhappySad: sfssMap,
    sfssGetInTrouble: sfssMap,
    sfssNoEnergy: sfssMap,
    sfssDisobeyAduls: sfssMap,
    sfssThreatenOthers: sfssMap,
    sfssFearOfMocked: sfssMap,
    sfssHardTimeWaiting: sfssMap,
    sfssNervousShy: sfssMap,
    sfssHardSitStill: sfssMap,
    sfssCryEasily: sfssMap,
    sfssAnnoyOthers: sfssMap,
    sfssArgueAdults: sfssMap,
    sfssDrinkAlcohol: sfssMap,
    sfssThrowThingsMad: sfssMap,
    sfssInterruptOthers: sfssMap,
    sfssLieToGetThings: sfssMap,
    sfssUncontrolTemper: sfssMap,
    sfssWorryAboutALot: sfssMap,
    sfssCantGetAlong: sfssMap,
    sfssFeelWorthless: sfssMap,
    sfssHardToHaveFun: sfssMap,
    sfssHangOutWithTrouble: sfssMap,
    sfssAttentionHard: sfssMap,
    sfssCantSleepWorry: sfssMap,
    sfssFeelTense: sfssMap,
    sfssUseDrugs: sfssMap,
    sfssSelfHarm: {
      '1': 1,
      '2': 4,
      '3': 4,
      '4': 7,
      '5': 7
    },

    sfssFeelUnhappySad_C: sfssMap,
    sfssGetInTrouble_C: sfssMap,
    sfssNoEnergy_C: sfssMap,
    sfssDisobeyAduls_C: sfssMap,
    sfssThreatenOthers_C: sfssMap,
    sfssFearOfMocked_C: sfssMap,
    sfssHardTimeWaiting_C: sfssMap,
    sfssNervousShy_C: sfssMap,
    sfssHardSitStill_C: sfssMap,
    sfssCryEasily_C: sfssMap,
    sfssAnnoyOthers_C: sfssMap,
    sfssArgueAdults_C: sfssMap,
    sfssDrinkAlcohol_C: sfssMap,
    sfssThrowThingsMad_C: sfssMap,
    sfssInterruptOthers_C: sfssMap,
    sfssLieToGetThings_C: sfssMap,
    sfssUncontrolTemper_C: sfssMap,
    sfssWorryAboutALot_C: sfssMap,
    sfssCantGetAlong_C: sfssMap,
    sfssFeelWorthless_C: sfssMap,
    sfssHardToHaveFun_C: sfssMap,
    sfssHangOutWithTrouble_C: sfssMap,
    sfssAttentionHard_C: sfssMap,
    sfssCantSleepWorry_C: sfssMap,
    sfssFeelTense_C: sfssMap,
    sfssUseDrugs_C: sfssMap,

    taqsWantedDirection: sfssInverseMap,
    taqsUnderstoodCounselor: sfssInverseMap,
    taqsWorkedTogether: sfssInverseMap,
    taqsCounselorCommited: sfssInverseMap,
    taqsCounselorUnderstoodMe: sfssInverseMap,

    ptpbChsDoingWell: ptpbChsMap,
    ptpbChsWellOthers: ptpbChsMap,
    ptpbChsProblemSolve: ptpbChsMap,
    ptpbChsSolveOthersQuit: ptpbChsMap,

    sssServicesNeeded: sssMap,
    sssServicesRightApproach: sssMap,
    sssRecommendToFriend: sssMap,
    sssReturnHelp: sssMap,

    sssServicesNeeded_C: sssMap,
    sssServicesRightApproach_C: sssMap,
    sssRecommendToFriend_C: sssMap,
    sssReturnHelp_C: sssMap,

    ycisUnderstandFeelingsBetter: fivePointStrength,
    ycisBetterDealWithProblems: fivePointStrength,
    ycisUnderstandStrengthsBetter: fivePointStrength,
    ycisTriedCounselorSuggestions: fivePointStrength,
    ycisUsedThingsLearned: fivePointStrength,
    ycisImprovedBehaviorInHome: fivePointStrength,

    toesImproveRelationshipWithCaregiver: toesMap,
    toesDealWithThoughts: toesMap,
    toesDealWithFeelings: toesMap,
    toesSolveOwnProblems: toesMap,
    toesImproveBehaviorHome: toesMap,
    toesImproveQualityOfLife: toesMap,
    toesUseStrengths: toesMap,
    toesImproveBehaviorSchool: toesMap,

    toesImproveRelationshipWithYouth_C: toesMap,
    toesDealWithThoughts_C: toesMap,
    toesDealWithFeelings_C: toesMap,
    toesSolveOwnProblems_C: toesMap,
    toesImproveBehaviorHome_C: toesMap,
    toesImproveQualityOfLife_C: toesMap,
    toesUseStrengths_C: toesMap,
    toesImproveBehaviorSchool_C: toesMap,

    cgsqInterruptionOfPersonalTime: sfssMap,
    cgsqMissingWork: sfssMap,
    cgsqFinancialStrain: sfssMap,
    cgsqDisruptedRelationships: sfssMap,
    cgsqFeltSad: sfssMap,
    cgsqWorriedAboutFuture: sfssMap,
    cgsqTiredOrStrained: sfssMap,

    swlsLifeIsCloseToIdeal: defaultInverseMap,
    swlsConditionsOfLifeExcellent: defaultInverseMap,
    swlsSatisfiedWithLife: defaultInverseMap,
    swlsGotImportantThingsInLife: defaultInverseMap,
    swlsLiveLifeOverChanges: defaultInverseMap,

    ptpbMedAsDirected: sfssInverseMap,
    ptpbMedPositiveChanges: sfssInverseMap,
    ptpbMedAsDirected_C: sfssInverseMap,
    ptpbMedPositiveChanges_C: sfssInverseMap,
    ptpbMedConcerns: sfssMap,
    ptpbMedConcerns_C: sfssMap,

    bmslssFamilyLife: sfssInverseMap,
    bmslssFriendships: sfssInverseMap,
    bmslssSchool: sfssInverseMap,
    bmslssYourself: sfssInverseMap,
    bmslssYourHome: sfssInverseMap,
    bmslssOverall: sfssInverseMap,

    bscsPrimaryIntensity: bamMap,
    bscsPrimaryFrequency: bamMap,
    bscsPrimaryLength: bamMap,
    bscsPrimaryNumber: bamMap,
    bscsSecondaryIntensity: bamMap,
    bscsSecondaryFrequency: bamMap,
    bscsSecondaryLength: bamMap,
    bscsSecondaryNumber: bamMap,

    nsiDizzy: nsiMap,
    nsiBalance: nsiMap,
    nsiClumsy: nsiMap,
    nsiHeadaches: nsiMap,
    nsiNausea: nsiMap,
    nsiVision: nsiMap,
    nsiLightSensitive: nsiMap,
    nsiHearing: nsiMap,
    nsiNoiseSensitive: nsiMap,
    nsiNumbness: nsiMap,
    nisTasteChange: nsiMap,
    nsiAppetite: nsiMap,
    nsiConcentration: nsiMap,
    nsiForgetful: nsiMap,
    nsiDecisions: nsiMap,
    nsiSlowThinking: nsiMap,
    nsiFatigue: nsiMap,
    nsiSleep: nsiMap,
    nsiAnxious: nsiMap,
    nsiDepressed: nsiMap,
    nsiIrritable: nsiMap,
    nsiFrustration: nsiMap
  };

  exports.default = scoreMaps;
});