define('frontend/pods/components/provider/plan/entity-plan-tree/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'entity-tree page__wide-content',
    institute: Ember.inject.service(),
    sessionUser: Ember.inject.service(),

    entityTreeNode: Ember.computed('path', function () {
      var path = this.get('path') || this.get('rootPath');
      return this.get("store").findRecord('entityTreeNode', path);
    }),

    hasDemographics: Ember.computed.alias('entityTreeNode.demographicsPresent'),

    allowEditActions: Ember.computed(function () {
      if (this.get('institute.configuration.paceLegacyMode')) {
        return this.get("sessionUser.currentUser.isInternal");
      }

      return this.get("sessionUser").hasPermission('editEntityMeasurementPlan');
    }),

    rowContext: Ember.computed('entityTreeNode', 'allowEditActions', function () {
      return {
        entityTreeNode: this.get('entityTreeNode'),
        administratorMode: this.get('allowEditActions')
      };
    }),

    path: Ember.computed.oneWay('rootPath'),

    rootPath: Ember.computed('institute.current.id', function () {
      return 'institute/' + this.get('institute.current.id');
    }),

    patientWarning: Ember.computed('entityTreeNode', 'supersedingPlans.[]', function () {
      var supersedingPlans = this.get("supersedingPlans");

      if (!supersedingPlans) {
        return null;
      }

      if (this.get("entityTreeNode.entity.entityType") === 'patient' && supersedingPlans.get('length') > 0) {
        var hasCareEpisodePlan = supersedingPlans.any(function (plan) {
          var entityTreeNode = plan.get('entityTreeNode');

          if (entityTreeNode.content) {
            entityTreeNode = entityTreeNode.content;
          }

          return entityTreeNode.entityForType('care-episode');
        });

        if (hasCareEpisodePlan) {
          return 'careEpisode';
        } else if (this.get("entityTreeNode").entityForType('organization')) {
          return 'noCareEpisode';
        } else {
          return 'noOrganization';
        }
      }
    }),

    supersedingPlanNodes: Ember.computed('supersedingPlans.[]', function () {
      return this.get('supersedingPlans').mapBy('entityTreeNode').uniqBy('path');
    }),

    _setEntityTreeNode: function _setEntityTreeNode(path) {
      var _this = this;

      this.get("store").findRecord('entityTreeNode', path).then(function (result) {
        _this.set('entityTreeNode', result);
      });
    },

    actions: {
      resetRoot: function resetRoot() {
        this.set('path', this.get('rootPath'));
        this._setEntityTreeNode(this.get('rootPath'));
      },
      pathSelected: function pathSelected(path) {
        if (!path) {
          path = this.get('rootPath');
        }

        this.set('path', path);
        this._setEntityTreeNode(path);
      },
      entityTreeNodeSelected: function entityTreeNodeSelected(entity) {
        entity = entity.content ? entity.content : entity;
        this.setProperties({ path: entity.get('path'), entityTreeNode: entity });
      },
      onQueryResults: function onQueryResults(name, results) {
        this.set('supersedingPlans', results);
      }
    }
  });
});