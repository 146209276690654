define('frontend/pods/components/provider/assessment/report-v2/ucla-ptsd-trauma-history/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled'], function (exports, _component, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var scaleMnemonic = 'UCLAPTSDReactionIndexchildadolescenttraumahistory';

  var sections = [{
    trigger: '1_seriousaccidentalinjury',
    title: 'Serious Accidental Injury',
    age: ['1c_seriousaccidentalinjury_age'],
    other: ['1a_seriousaccidentalinjury_details', '1b_seriousaccidentalinjury_role']
  }, {
    trigger: '2_illnessmedicaltrauma',
    title: 'Illness/Medical Trauma',
    age: ['2c_illnessmedical_age'],
    other: ['2a_illnessmedical_traumadetails', '2_illnessmedical_typeofillness', '2b_illnessmedical_role']
  }, {
    trigger: '3_communityviolence',
    title: 'Community Violence',
    age: ['3c_communityviolence_age'],
    other: ['3a_communityviolence_traumadetails', '3b_communityviolence_role']
  }, {
    trigger: '4_domesticviolence',
    title: 'Domestic Violence',
    age: ['4c_domesticviolence_age'],
    other: ['4a_domesticviolence_traumadetails', '4b_domesticviolence_role']
  }, {
    trigger: '5_schoolviolence',
    title: 'School Violence/Emergency',
    age: ['5c_schoolviolence_age'],
    other: ['5a_schoolviolence_traumadetails', '5b_schoolviolence_role']
  }, {
    trigger: '6_physicalassault',
    title: 'Physical Assault',
    age: ['6c_physicalassault_age'],
    other: ['6a_physicalassault_traumadetails', '6b_physicalassault_role']
  }, {
    trigger: '7_disaster',
    title: 'Disaster',
    age: ['7c_disaster_age'],
    other: ['7a_disaster_traumadetails', '7b_disaster_role']
  }, {
    trigger: '8_sexualabuse',
    title: 'Sexual Abuse',
    age: ['8c_sexualabuse_age'],
    other: ['8a_sexualabuse_traumadetails', '8_sexualabuse_perpetrator', '8b_sexualabuse_role']
  }, {
    trigger: '9_physicalabuse',
    title: 'Physical Abuse',
    age: ['9c_physicalabuse_age'],
    other: ['9a_physicalabuse_traumadetails', '9_physicalabuse_perpetrator', '9b_physicalabuse_role']
  }, {
    trigger: '10_neglect',
    title: 'Neglect',
    age: ['10c_neglect_age'],
    other: ['10a_neglect_traumadetails', '10_neglect_perpetrator', '10b_neglect_role']
  }, {
    trigger: '11_psychologicalmaltreatment',
    title: 'Psychological Maltreatment',
    age: ['11c_psychologicalmaltreatment_age'],
    other: ['11a_psychologicalmaltreatment_traumadetails', '11_psychologicalmaltreatment_perpetrator', '11b_psychologicalmaltreatment_role']
  }, {
    trigger: '12_interferencewithcaregiving',
    title: 'Interference with Caregiving',
    age: ['12c_interferencewithcaregiving_age'],
    other: ['12a_interferencewithcaregiving_traumadetails_impairmentdueto', '12a_interferencewithcaregiving_traumadetails_affectedcaregiver', '12b_interferencewithcaregiving_role']
  }, {
    trigger: '13_sexualassault',
    title: 'Sexual Assault',
    age: ['13c_sexualassault_age'],
    other: ['13a_sexualassault_perpetrator', '13a_sexualassault_traumadetails', '13b_sexualassault_role']
  }, {
    trigger: '14_kidnapping',
    title: 'Kidnapping/Abduction',
    age: ['14c_kidnapping_age'],
    other: ['14a_kidnapping_perpetrator', '14a_kidnapping_lengthoftime', '14b_kidnapping_role']
  }, {
    trigger: '15_terrorism',
    title: 'Terrorism',
    age: ['15c_terrorism_age'],
    other: ['15a_terrorism_trauma details', '15b_terrorism_role']
  }, {
    trigger: '16_bereavement',
    title: 'Bereavement',
    age: ['16c_bereavement_age', '16c_bereavement_age_pc', '16c_bereavement_age_parent', '16c_bereavement_age_sibling', '16c_bereavement_age_grandparent', '16c_bereavement_age_relative', '16c_bereavement_age_friend', '16c_bereavement_age_other'],
    other: ['16a_bereavement_trauma details_persondeceased', '16a_bereavement_trauma details_persondeceased_cod_pc', '16a_bereavement_trauma details_persondeceased_cod_parent', '16a_bereavement_trauma details_persondeceased_cod_sibling', '16a_bereavement_trauma details_persondeceased_cod_grandparent', '16a_bereavement_trauma details_persondeceased_cod_relative', '16a_bereavement_trauma details_persondeceased_cod_friend', '16a_bereavement_trauma details_persondeceased_cod_otherperson', '16a_bereavement_trauma details_persondeceased_cod_other', '16b_bereavement_role']
  }, {
    trigger: '17_separation',
    title: 'Separation',
    age: ['17c_separation_age'],
    other: ['17a_separation_trauma details', '17b_separation_role']
  }, {
    trigger: '18_war',
    title: 'War/Political Violence',
    age: ['18c_war_age'],
    other: ['18a_war_trauma details', '18b_war_role']
  }, {
    trigger: '19_forceddisplacement',
    title: 'Forced Displacement',
    age: ['19c_forceddisplacement_age'],
    other: ['19a_forceddisplacement_trauma details_cod', '19a_forceddisplacement_trauma details_sod', '19b_forceddisplacement_role']
  }, {
    trigger: '20_trafficking',
    title: 'Trafficking/Sexual Exploitation',
    age: ['20c_trafficking_age'],
    other: ['20a_trafficking_trauma details', '20b_trafficking_role']
  }, {
    trigger: '21_bullying',
    title: 'Bullying',
    age: ['21c_bullying_age'],
    other: ['21a_bullying_trauma details', '21b_bullying_role']
  }, {
    trigger: '22_attemptedsuicide',
    title: 'Attempted Suicide',
    age: ['22c_attemptedsuicide_age'],
    other: ['22a_attempted_trauma_details', '22b_attemptedsuicide_role']
  }, {
    trigger: '23_witnessedsuicide',
    title: 'Witnessed Suicide',
    age: ['23c_witnessedsuicide_age'],
    other: ['23a_witnessedsuicide_person', '23a_witnessedsuicide_method_mother', '23a_witnessedsuicide_method_father', '23a_witnessedsuicide_method_brother', '23a_witnessedsuicide_method_sister', '23a_witnessedsuicide_method_otherrelative', '23a_witnessedsuicide_method_closefriend', '23a_witnessedsuicide_method_acquaintance', '23a_witnessedsuicide_method_stranger', '23a_witnessedsuicide_method_other', '22b_witnessedsuicide_role']
  }, {
    trigger: '24_racialtrauma',
    title: 'Racial Trauma',
    age: ['24c_racialtrauma_age'],
    other: ['24a_racialtrauma_traumadetails', '24b_racialtrauma_role']
  }, {
    trigger: '25_othertrauma',
    title: 'Other Trauma',
    age: ['25c_othertrauma_age'],
    other: ['25a_othertrauma_traumadetails', '25b_othertrauma_role']
  }];

  exports.default = _component.default.extend(_drawerEnabled.default, {

    availableReports: Ember.computed('reportSettings.currentReportSet.[]', function () {
      return this.get("reportSettings.currentReportSet").filter(function (report) {
        return report.get("computedValues").any(function (cv) {
          return cv.get("scale.mnemonic") === scaleMnemonic;
        });
      });
    }),

    selectedComputedValue: Ember.computed('selectedReport.computedValues.[]', function () {
      return this.get("selectedReport").get("computedValues").find(function (cv) {
        return cv.get("scale.mnemonic") === scaleMnemonic;
      });
    }),

    selectedReportAnswerMap: Ember.computed("selectedComputedValue.answers.[]", function () {
      var map = {};
      this.get("selectedComputedValue.answers").forEach(function (answer) {
        var shortname = answer.get("question.shortname");
        if (!map[shortname]) {
          map[shortname] = [];
        }

        map[shortname].addObject(answer);
      });

      return map;
    }),

    sectionsWithData: Ember.computed('selectedReportAnswerMap', function () {
      var answerMap = this.get("selectedReportAnswerMap");
      return sections.map(function (section) {

        var endorsed = answerMap[section.trigger] && answerMap[section.trigger][0].get("value") === '1';

        var ages = Array(19);

        var answers = [section.trigger].addObjects(section.age).addObjects(section.other).map(function (question) {
          return {
            answers: answerMap[question],
            mnemonic: question
          };
        });

        section.age.forEach(function (age) {
          (answerMap[age] || []).forEach(function (answer) {
            var value = answer.get("value");

            if (value === '0-1') {
              ages[0] = true;
              ages[1] = true;
            } else {
              ages[Number(value)] = true;
            }
          });
        });

        return {
          trigger: section.trigger,
          title: section.title,
          endorsed: endorsed,
          ages: ages,
          answers: answers
        };
      });
    }),

    selectedSection: null,

    selectedReport: Ember.computed.oneWay('availableReports.lastObject'),

    title: 'UCLA PTSD Trauma History',

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      },
      setSelectedReport: function setSelectedReport(value) {
        this.set("selectedReport", value);
      },
      selectSection: function selectSection(section) {
        this.set("selectedSection", section);
      }
    }
  });
});