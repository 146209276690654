define('frontend/pods/provider/external/patient/report/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    queryParams: {
      externalPatientId: {},
      externalProviderId: {}
    },

    model: function model(params) {
      var _this = this;

      var patients = this.store.query('patient', { include: "last-assessment-report", filter: { "with_any_identifier": params.externalPatientId, "show_test": "all" } });

      return patients.then(function (results) {
        if (results.get("length") === 1) {
          var patient = results.get("firstObject");

          // If we don't have a report to go to, go to patient page
          if (patient.get("lastAssessmentReport.id")) {
            _this.transitionTo('provider.patients.show.longitudinal', patient.get("id"));
          } else {
            _this.transitionTo('provider.patients.show', patient.get("id"));
          }
        } else {
          var searchObject = _this.store.createRecord('patientSearch', { term: params.externalPatientId });

          _this.get("router").transitionTo("provider.patients", { queryParams: { searchId: searchObject.get("id") } });
        }
      });
    }
  });
});