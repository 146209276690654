define('frontend/serializers/billing/charge', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      // These are all autogenereated by the system and should not be changed.
      patientSession: { serialize: false },
      payorContractCode: { serialize: false },
      payorContract: { serialize: false },
      coverage: { serialize: false }
    }
  });
});