define('frontend/pods/provider/admin/users/show/index/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    breadCrumb: {},
    metrics: Ember.inject.service(),
    model: function model() {
      return this.modelFor("provider.admin.users.show");
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.admin.users.show" });
      }
    }
  });
});