define('frontend/pods/components/survey/taking-local/common/questions/content-question/component', ['exports', 'frontend/mixins/survey-metric', 'frontend/mixins/survey/question-component-mixin'], function (exports, _surveyMetric, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_surveyMetric.default, _questionComponentMixin.default, {
    tagName: '',

    /**
     * 
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      // Do nothing, override superclass behavior.
    },


    actions: {
      next: function next() {
        if (this.get("trackTime")) {
          this.trackSurveyMetric({ event: "survey.instructionsNext", questionId: this.get('question').id });
        }

        this.sendAction('next');
      }
    }
  });
});