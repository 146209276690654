define('frontend/pods/components/provider/reports/report-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    reports: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    outcomesReportCodes: ['outcomes_explorer', 'care_episode_outcome_drilldown', 'session_outcome_drilldown', 'conditions', 'no_show_history_drilldown'],
    adoptionReportCodes: ['adherence', 'adherence_drilldown_v2', 'user_recent_activity', 'provider_assessments', 'patient_assessments', 'enrollment_drilldown', 'client_assessment_completion_drilldown', 'provider_assessment_completion_drilldown', 'treatment_track_enrollment_drilldown', 'treatment_track_active_drilldown'],
    operationsReportCodes: ['taking_time_drilldown', 'reimbursement_summary', 'provider_load', 'compliance_report', 'coverage_drilldown', 'billable_charge_drilldown', 'charge_efficiency_drilldown', 'experiment_enrollment_drilldown', 'genoa_metrics_drilldown', 'patient_usage_drilldown', 'patient_adoption_flow_drilldown', 'provider_usage_drilldown', 'optum_adoption_all_drilldown', 'optum_adoption_gina_drilldown', 'optum_ongoing_all_drilldown', 'optum_ongoing_gina_drilldown', 'optum_feedback_viewing_all_drilldown', 'optum_feedback_viewing_gina_drilldown', 'optum_feedback_use_all_drilldown', 'optum_feedback_use_gina_drilldown', "quality_issue_workflow_drilldown", "patient_quality_issue_drilldown", "decision_support_workflow_drilldown", "decision_support_actions_drilldown"],

    showMockData: Ember.computed(function () {
      return this.get('features.showMockAnalytics');
    }),
    showSummaryStats: Ember.computed('showMockAnalytics', function () {
      return this.get('sessionUser').hasRole('executive') && this.get('showMockData');
    }),

    outcomesReports: Ember.computed('reports.allReports.[]', function () {
      return this.get('reports').getReportsForCodes(this.get('outcomesReportCodes'));
    }),
    adoptionReports: Ember.computed('reports.allReports.[]', function () {
      return this.get('reports').getReportsForCodes(this.get('adoptionReportCodes'));
    }),
    operationsReports: Ember.computed('reports.allReports.[]', function () {
      return this.get('reports').getReportsForCodes(this.get('operationsReportCodes'));
    }),
    outcomesExrplorerReport: Ember.computed.alias('reports.reportMap.outcomes_explorer'),
    orgAdherenceReport: Ember.computed.alias('reports.reportMap.adherence_drilldown_v2'),
    actions: {
      selectReport: function selectReport(value) {
        this.get("router").transitionTo('provider.reports.show', value);
      }
    }
  });
});