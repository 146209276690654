define('frontend/pods/components/scale-creation/templated-form/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'templated-form-component',
    classNameBindings: ['showHeader:expandable-form'],
    showHeader: true,
    showChangeTemplate: false,
    templateOptions: Ember.computed.alias('model.templateOptions'),
    scaleTemplates: Ember.inject.service(),
    showProperties: Ember.computed('model.propertyDefinitions', function () {
      return !_lodash.default.isEmpty(this.get("model.propertyDefinitions"));
    }),
    template: Ember.computed('scaleTemplates', 'templateOptions.id', function () {
      return this.get("scaleTemplates").lookup(this.get("model.templateClassName"), this.get('templateOptions.templateId'));
    }),
    title: Ember.computed('template.name', 'model.templateClassName', function () {
      return this.get("template.name") || this.get("model.templateClassName");
    }),
    basicProperties: Ember.computed('model.propertyDefinitions', function () {
      return _lodash.default.pickBy(this.get("model.propertyDefinitions"), function (item) {
        return !item.advanced;
      });
    }),
    advancedProperties: Ember.computed('model.propertyDefinitions', function () {
      return _lodash.default.pickBy(this.get("model.propertyDefinitions"), function (item) {
        return item.advanced;
      });
    }),
    hasAdvancedProperties: Ember.computed('advancedProperties.[]', function () {
      return !_lodash.default.isEmpty(this.get("advancedProperties"));
    }),
    showAdvancedProperties: Ember.computed('model.propertyDefinitions', function () {
      var model = this.get("model");
      return _lodash.default.some(this.get("advancedProperties"), function (definition, key) {
        var currentValue = Ember.get(model.properties, key);
        if (definition.valueIsDefault) {
          return !definition.valueIsDefault(currentValue);
        } else if (definition.defaultValue) {
          return currentValue === (typeof definition.defaultValue === 'function' ? definition.defaultValue() : definition.defaultValue);
        } else {
          return !Ember.isEmpty(currentValue);
        }
      });
    }),
    actions: {
      propertyUpdated: function propertyUpdated() {
        this.get("model").updatePropertiesAndNotify();
      },
      parameterUpdated: function parameterUpdated() {
        this.get("model").updateContentAndNotify();
      },
      toggleExpanded: function toggleExpanded() {
        if (typeof event !== 'undefined' && ($(event.target).closest('a').length || $(event.target).closest('button').length)) {
          return;
        }
        this.toggleProperty('isExpanded');
      },
      toggleShowChangeTemplate: function toggleShowChangeTemplate() {
        if (!this.get("isExpanded")) {
          this.set('isExpanded', true);
        }
        this.toggleProperty('showChangeTemplate');
      },
      changeTemplate: function changeTemplate() {
        var newTemplate = this.get("newTemplate");
        var model = this.get("model");
        var onChangeTemplate = this.get('onChangeTemplate');

        onChangeTemplate(model, newTemplate);

        this.toggleProperty('showChangeTemplate');
      }
    }
  });
});