define('frontend/serializers/entity-tree-node', ['exports', 'frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload) {
      // The response including hashes comes back munged from the server, so fix it.
      if (payload && payload.data && payload.data.id) {
        payload.data.id = payload.data.id.replace(/%2F/g, '/');
      }
      return this._super.apply(this, arguments);
    }
  });
});