define('frontend/pods/components/provider/care-episodes/care-episode-selector/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/models/care-episode'], function (exports, _rollbackMixin, _careEpisode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_rollbackMixin.default, {
    useDefault: true, //if true, and defaults exist, will hide full selection/creation utilities
    tagName: '',
    rollbackProperties: ['selectedCareEpisode', 'newCareEpisode'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set("showCreate", this.get('model.' + this.get("property") + '.isNew'));
    },


    availableOrganizations: Ember.computed("allowedOrganizations", "existingCareEpisodes", function () {
      var takenOrgIds = this.getWithDefault("existingCareEpisodes", []).mapBy("organization.id");
      return this.getWithDefault("allowedOrganizations", []).reject(function (org) {
        return takenOrgIds.includes(org.get("id"));
      });
    }),

    // You are allowed to create an episode of care if there is a organization in your
    // subtree which does not have one.
    canCreate: Ember.computed.notEmpty("availableOrganizations"),

    showCreate: false,

    selectedCareEpisode: Ember.computed("model", "property", {
      get: function get() {
        var model = this.get("model");
        return model.get(this.get("property"));
      },
      set: function set(key, value) {
        var model = this.get("model");
        return model.set(this.get("property"), value);
      }
    }),

    newCareEpisode: Ember.computed('careEpisodes.[]', function () {
      var alreadyCreated = this.get('careEpisodes').findBy('isNew', true);

      if (alreadyCreated) {
        return alreadyCreated;
      } else {
        return (0, _careEpisode.createNewCareEpisode)(this.get('store'), this.get('patient'), this.get('availableOrganizations'), this.get('activeProvider'));
      }
    }),

    // When we create the new row, the care episode collection automatically includes the new row, so
    // remove it here.
    existingCareEpisodes: Ember.computed.filter("careEpisodes", function (ce) {
      return !ce.get("isNew");
    }),

    // Because groupValue does an === comparison, we can't compare selected episode directly because
    // sometimes you'll have a promise and other times the object directly. So instead use the id
    // to compare against.
    selectedCareEpisodeId: Ember.computed("selectedCareEpisode.id", "showCreate", {
      get: function get() {
        if (this.get("showCreate")) {
          return 'new';
        }

        var model = this.get("model");
        return model.get(this.get("property") + '.id');
      },
      set: function set(key, value) {
        if (value === 'new') {
          this.set("selectedCareEpisode", this.get("newCareEpisode"));
          this.set("showCreate", true);
          return 'new';
        } else {
          var model = this.get("model");
          var careEpisodes = this.get("careEpisodes");

          var selected = careEpisodes.findBy('id', value);
          this.set("showCreate", false);
          return model.set(this.get("property"), selected);
        }
      }
    }),

    actions: {
      declineDefault: function declineDefault() {
        this.set('useDefault', false);
      }
    }
  });
});