define('frontend/pods/components/provider/assessment/report-v2/ucla-ptsd/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled', 'lodash'], function (exports, _component, _drawerEnabled, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var severityMnemonic = 'UCLAPTSDReactionIndexchildadolescentsymptomscale';
  var diagnosisMnemonic = 'UCLAPTSDRI_Diagnosis';
  var impairmentMnemonic = 'UCLAPTSDRI_Impairment';
  var distressMnemonic = 'UCLAPTSDRI_Distress';

  var sections = [{
    totalMnemonic: 'UCLAPTSDRI_SC_B_childadolescent',
    subscaleMnemonics: ['UCLAPTSDRI_SC_B1', 'UCLAPTSDRI_SC_B2', 'UCLAPTSDRI_SC_B3', 'UCLAPTSDRI_SC_B4', 'UCLAPTSDRI_SC_B5']
  }, {
    totalMnemonic: 'UCLAPTSDRI_SC_C_childadolescent',
    subscaleMnemonics: ['UCLAPTSDRI_SC_C1', 'UCLAPTSDRI_SC_C2']
  }, {
    totalMnemonic: 'UCLAPTSDRI_SC_D_childadolescent',
    subscaleMnemonics: ['UCLAPTSDRI_SC_D1', 'UCLAPTSDRI_SC_D2', 'UCLAPTSDRI_SC_D3', 'UCLAPTSDRI_SC_D4', 'UCLAPTSDRI_SC_D5', 'UCLAPTSDRI_SC_D6', 'UCLAPTSDRI_SC_D7']
  }, {
    totalMnemonic: 'UCLAPTSDRI_SC_E_childadolescent',
    subscaleMnemonics: ['UCLAPTSDRI_SC_E1', 'UCLAPTSDRI_SC_E2', 'UCLAPTSDRI_SC_E3', 'UCLAPTSDRI_SC_E4', 'UCLAPTSDRI_SC_E5', 'UCLAPTSDRI_SC_E6']
  }, {
    totalMnemonic: 'UCLAPTSDRI_Distress',
    subscaleMnemonics: ['UCLAPTSDRI_Distress']
  }, {
    totalMnemonic: 'UCLAPTSDRI_Impairment',
    subscaleMnemonics: ['UCLAPTSDRI_I_home', 'UCLAPTSDRI_I_school', 'UCLAPTSDRI_I_peer', 'UCLAPTSDRI_I_devprog']
  }];

  exports.default = _component.default.extend(_drawerEnabled.default, {

    availableReports: Ember.computed('reportSettings.currentReportSet.[]', function () {
      return this.get("reportSettings.currentReportSet").filter(function (report) {
        return report.get("computedValues").any(function (cv) {
          return cv.get("scale.mnemonic") === severityMnemonic;
        });
      });
    }),

    currentDiagnosticValue: Ember.computed("selectedReport", function () {
      return this.get('selectedReport.computedValues').find(function (cv) {
        return cv.get("scale.mnemonic") === diagnosisMnemonic;
      });
    }),

    severityScales: Ember.computed("scales.[]", function () {
      return this.get("scales").filterBy('mnemonic', severityMnemonic);
    }),

    distressScales: Ember.computed("scales.[]", function () {
      return this.get("scales").filterBy('mnemonic', distressMnemonic);
    }),

    impairmentScales: Ember.computed("scales.[]", function () {
      return this.get("scales").filterBy('mnemonic', impairmentMnemonic);
    }),

    filteredSeverityComputedValuesByReport: Ember.computed("severityScales", "severityScales.[]", "reports", function () {
      var activeScaleIds = this.get("severityScales").map(function (s) {
        return s.get("id");
      });

      var reports = this.get("reports");

      if (Ember.isEmpty(reports)) {
        return [];
      }

      return reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get('domain') === 'raw' && activeScaleIds.includes(cv.get("scale.id"));
        });
      });
    }),

    filteredDistressComputedValuesByReport: Ember.computed("distressScales", "distressScales.[]", "reports", function () {
      var activeScaleIds = this.get("distressScales").map(function (s) {
        return s.get("id");
      });

      var reports = this.get("reports");

      if (Ember.isEmpty(reports)) {
        return [];
      }

      return reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get('domain') === 'raw' && activeScaleIds.includes(cv.get("scale.id"));
        });
      });
    }),

    filteredImpairmentComputedValuesByReport: Ember.computed("impairmentScales", "impairmentScales.[]", "reports", function () {
      var activeScaleIds = this.get("impairmentScales").map(function (s) {
        return s.get("id");
      });

      var reports = this.get("reports");

      if (Ember.isEmpty(reports)) {
        return [];
      }

      return reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get('domain') === 'raw' && activeScaleIds.includes(cv.get("scale.id"));
        });
      });
    }),

    severityGraphOptions: Ember.computed('severityScales.[]', function () {
      return {
        isPercentileGraph: false,
        showYAxis: true,
        maxValue: this.get('severityScales.firstObject.maxValue'),
        minValue: this.get('severityScales.firstObject.minValue')
      };
    }),

    impairmentGraphOptions: Ember.computed('impairmentScales.[]', function () {
      return {
        isPercentileGraph: false,
        showYAxis: true,
        maxValue: this.get('impairmentScales.firstObject.maxValue'),
        minValue: this.get('impairmentScales.firstObject.minValue')
      };
    }),

    distressGraphOptions: Ember.computed('distressScales.[]', function () {
      return {
        isPercentileGraph: false,
        showYAxis: true,
        maxValue: this.get('distressScales.firstObject.maxValue'),
        minValue: this.get('distressScales.firstObject.minValue')
      };
    }),

    severityDomains: ['raw'],

    severityReportData: Ember.computed("filteredSeverityComputedValuesByReport.[]", function () {
      var activeScaleIds = this.get("severityScales").map(function (s) {
        return s.get("id");
      });
      var firstScaleId = activeScaleIds.objectAt(0);
      var filtered = this.get("filteredSeverityComputedValuesByReport");

      var graphData = _lodash.default.values(_lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.id") + f.get("user.id");
      }));
      return _lodash.default.sortBy(graphData, function (g) {
        return g[0].get('scale.id') !== firstScaleId;
      });
    }),

    currentSeverityValue: Ember.computed("selectedReport", function () {
      return this.get('selectedReport.computedValues').find(function (cv) {
        return cv.get("scale.mnemonic") === severityMnemonic;
      });
    }),

    distressReportData: Ember.computed("filteredDistressComputedValuesByReport.[]", function () {
      var activeScaleIds = this.get("distressScales").map(function (s) {
        return s.get("id");
      });
      var firstScaleId = activeScaleIds.objectAt(0);
      var filtered = this.get("filteredDistressComputedValuesByReport");

      var graphData = _lodash.default.values(_lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.id") + f.get("user.id");
      }));
      return _lodash.default.sortBy(graphData, function (g) {
        return g[0].get('scale.id') !== firstScaleId;
      });
    }),

    impairmentReportData: Ember.computed("filteredImpairmentComputedValuesByReport.[]", function () {
      var activeScaleIds = this.get("impairmentScales").map(function (s) {
        return s.get("id");
      });
      var firstScaleId = activeScaleIds.objectAt(0);
      var filtered = this.get("filteredImpairmentComputedValuesByReport");

      var graphData = _lodash.default.values(_lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.id") + f.get("user.id");
      }));
      return _lodash.default.sortBy(graphData, function (g) {
        return g[0].get('scale.id') !== firstScaleId;
      });
    }),

    selectedReport: Ember.computed.oneWay('availableReports.lastObject'),

    title: 'UCLA PTSD Symptom, Distress and Impairment Scale',

    sectionsWithData: Ember.computed("selectedReport", function () {
      var selectedReport = this.get("selectedReport");

      return sections.map(function (section) {
        var totalValue = selectedReport.get('computedValues').findBy('scale.mnemonic', section.totalMnemonic);

        var parts = section.subscaleMnemonics.map(function (mnemonic) {
          return selectedReport.get('computedValues').findBy('scale.mnemonic', mnemonic);
        });

        return {
          title: totalValue.get("scale.translatedShortname"),
          total: totalValue,
          parts: parts
        };
      });
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      },
      setSelectedReport: function setSelectedReport(value) {
        this.set("selectedReport", value);
      }
    }
  });
});