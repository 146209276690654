define('frontend/pods/provider/plan/entities/show/edit/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    institute: Ember.inject.service(),
    preload: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        providers: this.get("preload.providers"),
        organizations: this.get("preload.organizations"),
        // I'm not sure why this is necessary to preload but there's a weird timing bug where the list doesn't load
        // if it's only in model()
        treatmentTrackEntities: this.get("preload.activeTreatmentTrackEntities")
      });
    },
    model: function model() {
      var _this = this;

      var entityMeasurementPlan = this.modelFor('provider.plan.entities.show');
      var scales = this.get("preload.scales").then(function () {
        return _this.get("preload.independentScales");
      });
      var measurementPlans = this.get('store').query('care/measurementPlan', { filter: { preset: 'reusable' }, include: 'measurement-plan-scales,author', page: { limit: 100 } });

      return Ember.RSVP.hash({
        entityMeasurementPlan: entityMeasurementPlan,
        entityTreeNode: entityMeasurementPlan.get('entityTreeNode'),
        providerEntities: this.get("preload.providerEntities"),
        organizationEntities: this.get("preload.organizationEntities"),
        treatmentTrackEntities: this.get("preload.activeTreatmentTrackEntities"),
        scales: scales,
        measurementPlans: measurementPlans
      });
    },


    breadCrumb: null
  });
});