define("frontend/pods/components/provider/patients/patient-details-timeline/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    isShowingDeleteModal: false,
    messages: Ember.inject.service(),
    institute: Ember.inject.service(),

    showEligibility: Ember.computed("sessionUser.currentUser", "features.enableBilling", function () {
      var user = this.get("sessionUser");
      return this.get("features.enablePayors") && user.hasPermission("editPatientInsurance");
    }),

    showExperiments: Ember.computed("sessionUser.currentUser", "features.enableExperiments", function () {
      var user = this.get("sessionUser");
      return this.get("features.enableExperiments") && user.hasPermission("viewExperiments");
    }),

    // If there is more than one provider on the care team but appointments will be ignored, show this warning. This exists for PACE right now.
    showIntegrationPrimaryProviderWarning: Ember.computed("institute.configuration.appointmentIntegrationExists", "patient.hasEmrLink", "institute.configuration.integrationOnlyMeasurePrimaryProvider", "patient.currentActiveCareEpisode.careEpisodeProviders.length", function () {
      if (!this.get("institute.configuration.appointmentIntegrationExists") || !this.get("patient.hasEmrLink") || !this.get("institute.configuration.integrationOnlyMeasurePrimaryProvider")) {
        return false;
      }

      return this.get("patient.currentActiveCareEpisode.careEpisodeProviders.length") > 1;
    }),

    actions: {
      deleteRecord: function deleteRecord(patient) {
        var _this = this;

        var onSuccess = function onSuccess() {
          _this.get("messages").success("Patient successfully deleted");
          _this.get("router").transitionTo("provider.patients");
        };

        var onError = function onError() {
          _this.send("toggleDeleteDialog");
          _this.get("messages").danger("Error deleting patient");
        };
        patient.destroyRecord().then(onSuccess, onError);
      },
      toggleDeleteDialog: function toggleDeleteDialog() {
        this.toggleProperty("isShowingDeleteModal");
      },
      sendDemoFollowUp: function sendDemoFollowUp() {
        var _this2 = this;

        var record = this.store.createRecord("demoPatientRequest");
        record.set("patient", this.get("patient"));

        var onSuccess = function onSuccess() {
          _this2.get("messages").success("Follow up sent");
        };

        var onError = function onError() {
          _this2.get("messages").danger("Follow up error");
        };

        return record.save().then(onSuccess, onError);
      },
      disableNotifications: function disableNotifications() {
        var patient = this.get("patient");

        patient.set("enableNotifications", false);
        return patient.save();
      },
      enableNotifications: function enableNotifications() {
        var patient = this.get("patient");

        patient.set("enableNotifications", true);
        return patient.save();
      }
    }
  });
});