define("frontend/pods/components/provider/assessment/report-v2/holistic-responses/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["holistic-responses"],
    counselingGoals: Ember.computed.alias("computedValue.properties.counselingGoals"),
    currentProblemStrategy: Ember.computed.alias("computedValue.properties.currentProblemStrategy"),
    otherTopics: Ember.computed.alias("computedValue.properties.otherTopics"),

    dataIsStale: Ember.computed.not("activeReport.includesHolisticResponses"),

    closestReportWithData: Ember.computed("activeReport", "reportSettings.currentReportSet.[]", function () {
      if (this.get('activeReport.includesHolisticResponses')) {
        return this.get("activeReport");
      }

      return this.getWithDefault('reportSettings.currentReportSet', []).slice().reverse().find(function (report) {
        return report.get("includesHolisticResponses");
      });
    }),

    computedValue: Ember.computed.alias("closestReportWithData.holisticResponses")
  });
});