define('frontend/models/experiment-decision', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    enabled: _emberData.default.attr("boolean"),
    experiment: _emberData.default.belongsTo('experiment'),
    experimentVariant: _emberData.default.belongsTo('experimentVariant'),
    userConsent: _emberData.default.belongsTo('userConsent'),
    target: _emberData.default.belongsTo('participant', { polymorphic: true })
  });
});