define('frontend/pods/components/internal/scales/edit-form/component', ['exports', 'frontend/models/assessment/raw-scale-datum'], function (exports, _rawScaleDatum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.previewFinish = undefined;


  var previewFinish = {
    name: "Preview Finish",
    mnemonic: "previewFinish",
    content: {
      pages: [{
        title: {
          en: "Finished"
        },
        properties: [],
        questions: [{
          base_type: "Decorative",
          type: "INSTRUCTIONS",
          title: {
            en: "The preview has finished"
          },
          properties: {
            finish: true
          }
        }]
      }]
    }
  };

  exports.previewFinish = previewFinish;
  exports.default = Ember.Component.extend({
    classNames: ['edit-form'],

    showFieldDescriptions: false,

    didReceiveAttrs: function didReceiveAttrs() {
      // We are transitioning from having a single survey per scale to allowing multiple. Check if the scale
      // has been migrated yet, and if not, make the change.
      // The back end will continue to support the old format.
      var scale = this.get('scale');
      var rawData = this.get('scale.scaleData');

      if (rawData && rawData['survey']) {
        scale.set('scaleData', (0, _rawScaleDatum.migrateToMultiScale)(rawData, scale.get('mnemonic')));
      }
    },
    _generatePreviewModel: function _generatePreviewModel() {
      var _this = this;

      var previewSurveys = this.get("scale.parsedScaleData.surveys").map(function (survey) {
        var content = survey.toJson();
        // Set the question domain directly as this is inferred from the raw scale datum
        content.question_domain = _this.get("scale.mnemonic");

        return content;
      });

      previewSurveys.pushObject(previewFinish);

      var invite = this.get("store").createRecord('survey/invite', {
        sections: [{
          modules: previewSurveys.mapBy('mnemonic')
        }],
        preloadedModules: previewSurveys,
        moduleIds: previewSurveys.mapBy('mnemonic')
      });

      return this.get("store").createRecord('survey/response', {
        invite: invite
      });
    },


    actions: {
      submit: function submit() {
        var action = this.get('submitAction');
        // TODO: refactor this to update
        this.get("scale").updateRawContent();
        if (typeof action === 'function') {
          return action(this.get("scale"));
        } else {
          console.warn("No action function was passed to edit-form");
        }
      },
      preview: function preview() {
        var previewModel = this._generatePreviewModel();

        this.setProperties({ isPreviewing: true, previewModel: previewModel });
      },
      previewInterview: function previewInterview() {
        var previewModel = this._generatePreviewModel();

        previewModel.beginInterviewPreview();

        this.setProperties({ isPreviewingInterview: true, previewModel: previewModel });
      },
      onInterviewDataChange: function onInterviewDataChange() {
        this.get('previewModel').updateAllDisplayLogic();
      },
      closePreview: function closePreview() {
        this.set("isPreviewing", false);
        this.set("isPreviewingInterview", false);
        this.get("previewModel").deleteRecord();
        this.set("previewModel", null);
      },
      reloadTemplates: function reloadTemplates() {
        this.get("scale.parsedScaleData.surveys").forEach(function (survey) {
          return survey.regenerateTemplates();
        });
      },
      changeLanguage: function changeLanguage(locale) {
        this.set('previewModel.currentLanguage', locale);
      }
    }
  });
});