define("frontend/pods/components/common/bread-crumb-spacer/component", ["exports", "frontend/pods/components/common/collapsible-breadcrumbs/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    tagName: "div",
    hasCrumbs: Ember.computed.gt("routeHierarchy.length", 0),
    breadCrumbClass: "breadcrumb-spacer",
    settingsUrlService: Ember.inject.service("settings-url"),
    // This needs to update when the service updates.
    settingsUrl: Ember.computed("settingsUrlService.url", function () {
      return this.get("settingsUrlService").getUrl();
    }),
    sessionUser: Ember.inject.service(),
    showSettingsButton: Ember.computed("sessionUser.currentUser.isInternal", "settingsUrl", function () {
      return this.get("settingsUrl") && this.get("sessionUser.currentUser.isInternal");
    })
  });
});