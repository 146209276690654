define('frontend/mixins/survey/begin-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    model: function model(params, transition) {
      //for now, assume we're always starting fresh
      var takingRequest = this.store.createRecord('survey/takeSurveyRequest', {
        assessmentInvitationId: params.invitationId,
        referralUrl: params.refLink,
        requestedUrlCodes: this.requestedUrlCodes(transition.queryParams),
        currentLanguage: this.requestedLanguage(transition.queryParams)
      }).save();
      return takingRequest.then(function (request) {
        return Ember.RSVP.hash({
          invite: request.get("invite")
        });
      });
    },


    // By default, do not pass across requested url codes.
    requestedUrlCodes: function requestedUrlCodes() /*allQueryParams*/{
      return [];
    },
    requestedLanguage: function requestedLanguage(allQueryParams) {
      var lang = allQueryParams.lang;
      if (!lang) {
        lang = this.get('intl.primaryLocale') || 'en';
      }
      return lang;
    },
    afterModel: function afterModel(model) {
      var response = model.invite.get('response');
      var nextRoute = this.get('routeName').replace('begin', 'response');
      // Ensure that there's always at least one page to go back to so that back events don't pop out of the ember app.
      window.history.pushState({}, '', this.get('router.url') + "#begin");
      this.transitionTo(nextRoute, response);
    }
  });
});