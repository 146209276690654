define('frontend/models/survey/invite', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    //most of the fields are raw since we want to manually handle most of the fetching...
    referralUrl: _emberData.default.attr('string'),
    moduleIds: _emberData.default.attr(),
    paramData: _emberData.default.attr(),
    preloadedModules: _emberData.default.attr(),
    assessmentInvitationId: _emberData.default.attr(),
    requestedUrlCodes: _emberData.default.attr('object'),
    response: _emberData.default.belongsTo('survey/response'),
    moduleMap: {},

    sections: _emberData.default.attr(),

    totalPages: Ember.computed('preloadedModules.[]', function () {
      return this.get('preloadedModules').reduce(function (sum, module) {
        return sum + module.content.pages.length;
      }, 0);
    })
  });
});