define("frontend/pods/internal/event-tracer/route", ["exports", "frontend/pods/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: {
      title: "Event Tracer"
    }
  });
});