define('frontend/pods/components/internal/scales/begin-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    actions: {
      beginReview: function beginReview() {
        var _this = this;

        var scale = this.get("scale");
        scale.set('publish', true);
        scale.set('statusEvent', 'begin_review');

        var success = function success() {
          _this.get('messages').success("Beginning review");
        };

        var failure = function failure(reason) {
          _this.get('messages').danger("Error updating scale: " + reason);
        };

        return scale.save().then(success).catch(failure);
      }
    }
  });
});