define('frontend/pods/provider/plan/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      this.transitionTo('provider.plan.entities.index');
    }
  });
});