define('frontend/serializers/application', ['exports', 'ember-data', 'lodash', 'frontend/config/environment'], function (exports, _emberData, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // This is designed to get around 2 bugs/features:
  //  a) the back end does not fully qualify namespaces for resources
  //  b) mirage does not support them either.
  // So we intercept calls here and use this map to look back
  // up the namespaced type that ember data expects.
  var ADD_NAMESPACES = {
    "invitation": "assessment/invitation",
    "invitations": "assessment/invitations",
    "report": "assessment/report",
    "reports": "assessment/reports",
    "question": "assessment/question",
    "questions": "assessment/questions",
    "question-option": "assessment/question-option",
    "question-options": "assessment/question-options",
    "answer": "assessment/answer",
    "answers": "assessment/answers",
    "assessment": "assessment/assessment",
    "assessments": "assessment/assessments",
    "scale": "assessment/scale",
    "scales": "assessment/scales",
    "norm": "assessment/norm",
    "norms": "assessment/norms",
    "population": "assessment/population",
    "populations": "assessment/populations",
    "computed-value": "assessment/computed-value",
    "computed-values": "assessment/computed-values",
    "patient-scale-target": "assessment/patient-scale-target",
    "patient-scale-targets": "assessment/patient-scale-targets",
    "report-scale": "assessment/report-scale",
    "report-scales": "assessment/report-scales",
    "report-scale-participant": "assessment/report-scale-participant",
    "report-scale-participants": "assessment/report-scale-participants",
    "related-person-assessment": "assessment/related-person-assessment",
    "related-person-assessments": "assessment/related-person-assessments",
    "goal": "assessment/goal",
    "goals": "assessment/goals",
    "goal-answer": "assessment/goal-answer",
    "goal-answers": "assessment/goal-answers",
    "measurement-requests": "assessment/measurement-requests",
    "measurement-request": "assessment/measurement-request",
    "report-bundles": "assessment/report-bundles",
    "report-bundle": "assessment/report-bundle",
    "raw-scale-datum": "assessment/raw-scale-datum",
    "raw-scale-data": "assessment/raw-scale-data",
    "alter-report-scales-request": "assessment/alter-report-scales-request",
    "alter-report-scales-requests": "assessment/alter-report-scales-requests",
    "institute-scale": "assessment/institute-scale",
    "institute-scales": "assessment/institute-scales",
    "internal-scale": "assessment/internal-scale",
    "internal-scales": "assessment/internal-scales",
    "invitation-notification": "assessment/invitation-notification",
    "invitation-notifications": "assessment/invitation-notifications",
    "scale-scorer": "assessment/scale-scorer",
    "scale-scorers": "assessment/scale-scorers",
    "appointment": "scheduling/appointment",
    "appointments": "scheduling/appointments",
    "recurrence": "scheduling/recurrence",
    "recurrences": "scheduling/recurrences",
    "care-plans": "care/care-plans",
    "care-plan": "care/care-plan",
    "patient-care-plan": "care/patient-care-plan",
    "patient-care-plans": "care/patient-care-plans",
    "care-plan-participant": "care/care-plan-participant",
    "care-plan-participants": "care/care-plan-participants",
    "recurring-session-request": "care/recurring-session-request",
    "recurring-session-requests": "care/recurring-session-requests",
    "measurement-plan": "care/measurement-plan",
    "measurement-plans": "care/measurement-plans",
    "measurement-plan-scale": "care/measurement-plan-scale",
    "measurement-plan-scales": "care/measurement-plan-scales",
    "entity-measurement-plan": "care/entity-measurement-plan",
    "entity-measurement-plans": "care/entity-measurement-plans",
    "measure-schedule-calculator-request": "care/measure-schedule-calculator-request",
    "measure-schedule-calculator-requests": "care/measure-schedule-calculator-requests",
    "future-session-measurement-estimate": "care/future-session-measurement-estimate",
    "future-session-measurement-estimates": "care/future-session-measurement-estimates",
    "session-active-measurement-plan": "care/session-active-measurement-plan",
    "session-active-measurement-plans": "care/session-active-measurement-plans",
    "patient-session-measurement-plan": "care/patient-session-measurement-plan",
    "patient-session-measurement-plans": "care/patient-session-measurement-plans",
    "alter-measurement-plan-request": "care/alter-measurement-plan-request",
    "alter-measurement-plan-requests": "care/alter-measurement-plan-requests",
    "alter-measurement-plan-scale-request": "care/alter-measurement-plan-scale-request",
    "alter-measurement-plan-scale-requests": "care/alter-measurement-plan-scale-requests",
    "treatment-track": "care/treatment-track",
    "treatment-tracks": "care/treatment-tracks",
    "data-file": "import/data-file",
    "data-files": "import/data-files",
    "data-file-download": "import/data-file-download",
    "data-file-downloads": "import/data-file-downloads",
    "data-dump": "export/data-dump",
    "data-dumps": "export/data-dumps",
    "data-dump-download": "export/data-dump-download",
    "data-dump-downloads": "export/data-dump-downloads",
    "invite": "survey/invite",
    "invites": "survey/invites",
    "response": "survey/response",
    "responses": "survey/responses",
    "response-item": "survey/response-item",
    "response-items": "survey/response-items",
    "module": "survey/module",
    "modules": "survey/modules",
    "take-survey-requests": "survey/take-survey-requests",
    "take-survey-request": "survey/take-survey-request",
    "bulk-survey-edits": "survey/bulk-survey-edits",
    "bulk-survey-edit": "survey/bulk-survey-edit",
    "calculate-messages-requests": "survey/calculate-messages-requests",
    "calculate-messages-request": "survey/calculate-messages-request",
    "invite-bundle": "survey/invite-bundle",
    "invite-bundles": "survey/invite-bundles",
    "payor-contract": "billing/payor-contract",
    "payor-contracts": "billing/payor-contracts",
    "payor-contract-code": "billing/payor-contract-code",
    "payor-contract-codes": "billing/payor-contract-codes",
    "coverage": "billing/coverage",
    "coverages": "billing/coverages",
    "charge": "billing/charge",
    "charges": "billing/charges",
    "charge-item": "billing/charge-item",
    "charge-items": "billing/charge-items",
    "charge-item-code": "billing/charge-item-code",
    "charge-item-codes": "billing/charge-item-codes",
    "reimbursement-summary-request": "billing/reimbursement-summary-request",
    "reimbursement-summary-requests": "billing/reimbursement-summary-requests"
  };

  var REMOVE_NAMESPACES = {
    "assessment/invitations": "invitations",
    "assessment/invitation": "invitation",
    "assessment/reports": "reports",
    "assessment/report": "report",
    "assessment/questions": "questions",
    "assessment/question": "question",
    "assessment/question-option": "question-option",
    "assessment/question-options": "question-options",
    "assessment/answers": "answers",
    "assessment/answer": "answer",
    "assessment/assessments": "assessments",
    "assessment/assessment": "assessment",
    "assessment/scale": "scale",
    "assessment/scales": "scales",
    "assessment/norm": "norm",
    "assessment/norms": "norms",
    "assessment/population": "population",
    "assessment/populations": "populations",
    "assessment/computed-value": "computed-value",
    "assessment/computed-values": "computed-values",
    "assessment/patient-scale-target": "patient-scale-target",
    "assessment/patient-scale-targets": "patient-scale-targets",
    "assessment/report-scale": "report-scale",
    "assessment/report-scales": "report-scales",
    "assessment/report-scale-participant": "report-scale-participant",
    "assessment/report-scale-participants": "report-scale-participants",
    "assessment/related-person-assessment": "related-person-assessment",
    "assessment/related-person-assessments": "related-person-assessments",
    "assessment/measurement-requests": "measurement-requests",
    "assessment/measurement-request": "measurement-request",
    "assessment/goals": "goals",
    "assessment/goal": "goal",
    "assessment/goal-answers": "goal-answers",
    "assessment/goal-answer": "goal-answer",
    "assessment/report-bundles": "report-bundles",
    "assessment/report-bundle": "report-bundle",
    "assessment/raw-scale-datum": "raw-scale-datum",
    "assessment/raw-scale-data": "raw-scale-data",
    "assessment/alter-report-scales-request": "alter-report-scales-request",
    "assessment/alter-report-scales-requests": "alter-report-scales-requests",
    "assessment/institute-scale": "institute-scale",
    "assessment/institute-scales": "institute-scales",
    "assessment/internal-scale": "internal-scale",
    "assessment/internal-scales": "internal-scales",
    "assessment/invitation-notification": "invitation-notification",
    "assessment/invitation-notifications": "invitation-notifications",
    "assessment/scale-scorer": "scale-scorer",
    "assessment/scale-scorers": "scale-scorers",
    "scheduling/appointments": "appointments",
    "scheduling/appointment": "appointment",
    "scheduling/recurrences": "recurrences",
    "scheduling/recurrence": "recurrence",
    "care/care-plans": "care-plans",
    "care/care-plan": "care-plan",
    "care/patient-care-plans": "patient-care-plans",
    "care/patient-care-plan": "patient-care-plan",
    "care/care-plan-participants": "care-plan-participants",
    "care/care-plan-participant": "care-plan-participant",
    "care/recurring-session-request": "recurring-session-request",
    "care/recurring-session-requests": "recurring-session-requests",
    "care/measurement-plan": "measurement-plan",
    "care/measurement-plans": "measurement-plans",
    "care/measurement-plan-scale": "measurement-plan-scale",
    "care/measurement-plan-scales": "measurement-plan-scales",
    "care/entity-measurement-plan": "entity-measurement-plan",
    "care/entity-measurement-plans": "entity-measurement-plans",
    "care/measure-schedule-calculator-request": "measure-schedule-calculator-request",
    "care/measure-schedule-calculator-requests": "measure-schedule-calculator-requests",
    "care/future-session-measurement-estimate": "future-session-measurement-estimate",
    "care/future-session-measurement-estimates": "future-session-measurement-estimates",
    "care/session-active-measurement-plan": "session-active-measurement-plan",
    "care/session-active-measurement-plans": "session-active-measurement-plans",
    "care/patient-session-measurement-plan": "patient-session-measurement-plan",
    "care/patient-session-measurement-plans": "patient-session-measurement-plans",
    "care/alter-measurement-plan-request": "alter-measurement-plan-request",
    "care/alter-measurement-plan-requests": "alter-measurement-plan-requests",
    "care/alter-measurement-plan-scale-request": "alter-measurement-plan-scale-request",
    "care/alter-measurement-plan-scale-requests": "alter-measurement-plan-scale-requests",
    "care/treatment-track": "treatment-track",
    "care/treatment-tracks": "treatment-tracks",
    "import/data-files": "data-files",
    "import/data-file": "data-file",
    "import/data-file-download": "data-file-download",
    "import/data-file-downloads": "data-file-downloads",
    "export/data-dump": "data-dump",
    "export/data-dumps": "data-dumps",
    "export/data-dump-download": "data-dump-download",
    "export/data-dump-downloads": "data-dump-downloads",
    "survey/invite": "invite",
    "survey/invites": "invites",
    "survey/response": "response",
    "survey/responses": "responses",
    "survey/response-item": "response-item",
    "survey/response-items": "response-items",
    "survey/module": "module",
    "survey/modules": "modules",
    "survey/take-survey-requests": "take-survey-requests",
    "survey/take-survey-request": "take-survey-request",
    "survey/bulk-survey-edits": "bulk-survey-edits",
    "survey/bulk-survey-edit": "bulk-survey-edit",
    "survey/calculate-messages-request": "calculate-messages-request",
    "survey/calculate-messages-requests": "calculate-messages-requests",
    "survey/invite-bundle": "invite-bundle",
    "survey/invite-bundles": "invite-bundles",
    "billing/payor-contract": "payor-contract",
    "billing/payor-contracts": "payor-contracts",
    "billing/payor-contract-code": "payor-contract-code",
    "billing/payor-contract-codes": "payor-contract-codes",
    "billing/coverage": "coverage",
    "billing/coverages": "coverages",
    "billing/charges": "charges",
    "billing/charge-items": "charge-items",
    "billing/charge-item-codes": "charge-item-codes",
    "billing/reimbursement-summary-request": "reimbursement-summary-request",
    "billing/reimbursement-summary-requests": "reimbursement-summary-requests"
  };

  exports.default = _emberData.default.JSONAPISerializer.extend({
    attrs: {
      // Some of our postprocessing code requires that this exist.
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var result = this._serializeWithNamespace(json);

      if (_environment.default.logEmberData) {
        console.debug("Serialized data for server", result);
      }

      return result;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute - http://stackoverflow.com/questions/32829966
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);

      var payloadKey = this._getMappedKey(key, snapshot.type);

      if (payloadKey === key) {
        payloadKey = this.keyForAttribute(key, 'serialize');
      }

      if (json.attributes && json.attributes[payloadKey] === '' && !attribute.options.keepEmptyString) {
        json.attributes[payloadKey] = null;
      }

      if (this.attrs[key] && this.attrs[key].translateUnderscore && json.attributes[payloadKey]) {
        json.attributes[payloadKey] = json.attributes[payloadKey].underscore();
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var translatedPayload = this._normalizePayloadWithNamespace(payload);

      if (_environment.default.logEmberData) {
        console.debug("Normalized payload for " + primaryModelClass, translatedPayload);
      }

      return this._super(store, primaryModelClass, translatedPayload, id, requestType);
    },
    pushPayload: function pushPayload(store, payload) {
      var normalizedPayload = this._normalizeDocumentHelper(this._normalizePayloadWithNamespace(payload));
      store.push(normalizedPayload);
    },


    // Overwrite attributes which have convertToUnderscore back to camelCase.
    // Replaces https://github.com/emberjs/data/blob/v2.9.0/addon/serializers/json-api.js#L259
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      var _this = this;

      var attributes = {};

      if (resourceHash.attributes) {
        modelClass.eachAttribute(function (key) {
          var attributeKey = _this.keyForAttribute(key, 'deserialize');
          if (resourceHash.attributes[attributeKey] !== undefined) {
            attributes[key] = resourceHash.attributes[attributeKey];
          }

          if (_this.attrs[key] && _this.attrs[key].translateUnderscore && attributes[key]) {
            attributes[key] = attributes[key].camelize();
          }
        });
      }

      return attributes;
    },
    _serializeWithNamespace: function _serializeWithNamespace(json) {
      if (json.data) {
        if (Ember.isArray(json.data)) {
          json.data.map(this._removeNamespace.bind(this));
        } else {
          this._removeNamespace(json.data);
        }
      }

      return json;
    },
    _normalizePayloadWithNamespace: function _normalizePayloadWithNamespace(payload) {
      if (payload) {
        if (payload.data) {
          if (Ember.isArray(payload.data)) {
            payload.data.map(this._addNamespace.bind(this));
          } else {
            this._addNamespace(payload.data);
          }
        }

        if (payload.included && Ember.isArray(payload.included)) {
          payload.included.map(this._addNamespace.bind(this));
        }
      }

      return payload;
    },
    _addNamespace: function _addNamespace(object) {
      if (ADD_NAMESPACES[object.type]) {
        object.type = ADD_NAMESPACES[object.type];
      }
      if (object.relationships) {
        _lodash.default.values(object.relationships).map(function (rel) {
          if (Ember.isArray(rel.data)) {
            rel.data.map(this._addNamespace);
          } else if (rel.data) {
            this._addNamespace(rel.data);
          }
        }.bind(this));
      }
    },
    _removeNamespace: function _removeNamespace(object) {
      if (REMOVE_NAMESPACES[object.type]) {
        object.type = REMOVE_NAMESPACES[object.type];
      }

      if (object.relationships) {
        _lodash.default.values(object.relationships).map(function (rel) {
          if (Ember.isArray(rel.data)) {
            rel.data.map(this._removeNamespace.bind(this));
          } else if (rel.data) {
            this._removeNamespace(rel.data);
          }
        }.bind(this));
      }
    }
  });
});