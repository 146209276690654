define('frontend/pods/components/provider/entities/entity-node-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'h3',
    classNames: ['text-center'],
    hideInstituteParent: false,

    parentSegments: Ember.computed('entityTreeNode.parentSegments', function () {
      var segments = this.get('entityTreeNode.parentSegments');
      if (this.get('hideInstituteParent') && segments && segments[0] && segments[0].type == 'institute') {
        return segments.slice(1, segments.length);
      } else {
        return segments;
      }
    }),

    actions: {}

  });
});