define('frontend/models/participant', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isPatient: Ember.computed('participantType', function () {
      return this.get('participantType') === 'patient';
    }),
    relationship: _emberData.default.attr("string"),
    participantType: _emberData.default.attr("string")
  });
});