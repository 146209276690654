define('frontend/pods/components/provider/assessment/dashboard/all-patients/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    filter: Ember.inject.service("provider/filter"),

    // Used to give access to additional data inside table row
    rowContext: Ember.computed('flags', function () {
      return {
        flags: this.get("flags")
      };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        template: 'components/common/test-patient-indicator',
        title: '',
        mayBeHidden: false,
        disableSorting: true
      }, {
        template: "components/provider/assessment/dashboard/columns-v3/patient-name-with-link",
        title: intl.t('provider.assessments.columns.patient'),
        sortedBy: 'lastName',
        sortDirection: "ASC",
        sortPrecedence: 1
      }, {
        template: "components/provider/assessment/dashboard/columns-v3/last-session-status",
        title: intl.t('common.status'),
        disableSorting: true
      }, {
        title: '',
        template: "components/provider/assessment/dashboard/columns-v3/severity-trend",
        className: 'patient-stats',
        disableSorting: true
      }];
      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        this.reloadTable();
      }
    },
    reloadTable: function reloadTable() {
      // Note that the patient search term is passed straight through to the component.
      // We pass in the details for the request rather than the request itself to make sure
      // the filtering is done correctly first time round.
      var data = Ember.Object.create({
        store: this.get("store"),
        modelName: 'patient-current-session',
        query: { filter: this.get("filter").withPatientFilters({}) }
      });
      this.set("data", data);
    },


    _filterObserver: Ember.observer('filter.filterDidChange', function () {
      Ember.run.debounce(this, this.reloadTable, 500);
    })

  });
});