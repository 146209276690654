define('frontend/adapters/assessment/computed-value', ['exports', 'frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      if (url.indexOf('/answers') > 0) {
        //eagerly fetch answers and questions
        url = url + '?include=option';
      }
      return this._super(store, snapshot, url, relationship);
    }
  });
});