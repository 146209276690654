define('frontend/pods/patient/assessment/confirm-details/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    sessionUser: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      invitationId: {}
    },
    beforeModel: function beforeModel() {
      // Ensure that the current user is available before trying to load the patient model from it.
      return this.get("sessionUser.currentUser");
    },
    model: function model(params) {
      var detailsToConfirm = this.get("session.data.authenticated.confirm_details_required");
      var transformedDetails = {};

      if (Ember.isEmpty(detailsToConfirm)) {
        this.get("router").transitionTo("patient.assessment.invitation.begin", params.invitationId);
      } else {
        Object.keys(detailsToConfirm).forEach(function (key) {
          var newKey = Ember.String.camelize(key);
          transformedDetails[newKey] = detailsToConfirm[key];
        });
      }

      return Ember.RSVP.hash({
        patient: this.get("sessionUser.activePatient"),
        invitationId: params.invitationId,
        detailsToConfirm: transformedDetails
      });
    }
  });
});