define('frontend/pods/components/provider/care-episodes/list-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "name",
        title: intl.t('model.careEpisode.name'),
        disableSorting: true
      }, {
        propertyName: "periodStart",
        template: "components/common/server-side-table/absolute-date",
        title: intl.t('model.careEpisode.periodStart'),
        sortPrecedence: 1,
        sortDirection: "asc"
      }, {
        propertyName: "status",
        title: intl.t('common.status'),
        template: "components/provider/care-episodes/list-component/care-episode-status",
        disableSorting: true
      }, {
        title: intl.t('common.actions'),
        template: "components/provider/care-episodes/list-component/action-buttons",
        disableSorting: true,
        allowExternalHrefs: true
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 25);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient && typeof patient.query === 'function') {
          var data = Ember.Object.create({
            store: this.get("patient"),
            modelName: 'careEpisodes',
            query: { include: "treatment-service,organization" }
          });
          this.set("data", data);
        }
      }
    },

    actions: {}

  });
});