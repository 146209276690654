define('frontend/helpers/survey/question-header-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Where do the translations live?
  var TRANSLATION_KEY_PREFIX = 'survey.questionHeaders.';

  // Given a base module name, lookup the translation of that module.
  function lookupHeaderTranslation(intl, module) {
    if (intl.exists(TRANSLATION_KEY_PREFIX + module)) {
      return intl.t(TRANSLATION_KEY_PREFIX + module);
    }
    return intl.t(TRANSLATION_KEY_PREFIX + 'default');
  }

  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var intl = this.get('intl');

      // We only care about the base module name and not the institute variations.
      var module = (params[0] || '').replace(/-.*/, '');
      return lookupHeaderTranslation(intl, module);
    }
  });
});