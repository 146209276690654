define('frontend/pods/components/provider/patient-flag-details/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    preload: Ember.inject.service(),

    enabledFlags: Ember.computed.filterBy('patientFlags', 'active', true),

    clearedFlags: Ember.computed.filterBy('patientFlags', 'active', false),

    unsetFlags: Ember.computed('enabledFlags.[]', 'flags.[]', function () {
      var enabledIds = this.get('enabledFlags').mapBy('flag.id');
      var clearedFlags = this.get('clearedFlags');

      return this.get('flags').filter(function (flag) {
        return enabledIds.indexOf(flag.get('id')) < 0;
      }).map(function (flag) {
        return {
          flag: flag,
          clearedFlag: clearedFlags.findBy('flag.id', flag.get("id"))
        };
      });
    }),

    noUnsetFlags: Ember.computed('unsetFlags.length', 'clearedFlags.length', function () {
      return this.get('unsetFlags.length') === 0 && this.get("clearedFlags.length") === 0;
    }),

    flagUnderEdit: null,

    flagAction: null,

    _createFlagAction: function _createFlagAction(flag) {
      this.store.createRecord('providerFlagAction', flag);
    },
    _cancelFlagAction: function _cancelFlagAction() {
      var action = this.get('flagAction');
      action.rollbackAttributes();
      this.set('flagAction', null);
    },
    _clearFlagAction: function _clearFlagAction(patientFlag) {
      var action = this.store.createRecord('providerFlagAction', { patientFlag: patientFlag, action: 'clear' });
      this.set("flagAction", action);
    },
    _setFlagAction: function _setFlagAction(pair) {
      var action = this.store.createRecord('providerFlagAction', { patient: this.get('patient'), flag: pair.flag, action: 'create' });
      this.set("flagAction", action);
    },
    _saveAction: function _saveAction() {
      var _this = this;

      var onSuccess = function onSuccess() {
        _this.set("flagUnderEdit", null);
        _this.set("flagAction", null);
        _this.notifyPropertyChange("enabledFlags");
      };

      var onFailure = function onFailure(reason) {
        _this.get('messages').danger(_this.printError(reason));
      };

      return this.get("flagAction").save().then(onSuccess, onFailure);
    },


    actions: {
      toggleClear: function toggleClear(value) {
        var flagUnderEdit = this.get("flagUnderEdit");
        if (flagUnderEdit === value) {
          this.set("flagUnderEdit", null);
          this._cancelFlagAction();
        } else if (flagUnderEdit) {
          this.set("flagUnderEdit", value);
          this._cancelFlagAction();
          this._clearFlagAction(value);
        } else {
          this.set("flagUnderEdit", value);
          this._clearFlagAction(value);
          Ember.run.later(this, function () {
            $('#notes').focus();
          });
        }
      },
      confirmClear: function confirmClear() {
        return this._saveAction();
      },
      toggleSet: function toggleSet(pair) {
        var flagUnderEdit = this.get("flagUnderEdit");
        if (flagUnderEdit === pair.flag) {
          this.set("flagUnderEdit", null);
          this._cancelFlagAction();
        } else if (flagUnderEdit) {
          this.set("flagUnderEdit", pair.flag);
          this._cancelFlagAction();
          this._setFlagAction(pair);
        } else {
          this.set("flagUnderEdit", pair.flag);
          this._setFlagAction(pair);
        }
      },
      confirmSet: function confirmSet() {
        return this._saveAction();
      }
    }
  });
});