define('frontend/pods/components/provider/assessment/report-v2/vanderbilt-user/component', ['exports', 'frontend/pods/components/provider/assessment/report-v2/standard-measures/measure-list-item/component', 'frontend/mixins/drawer-enabled', 'frontend/models/assessment/color-settings', 'lodash'], function (exports, _component, _drawerEnabled, _colorSettings, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var parentComorbidities = {
    anx_dep: ['vanderbilt3_parent_baseline_anx_dep'],
    conduct: ['vanderbilt3_parent_baseline_conduct'],
    opp_def: ['vanderbilt3_parent_opp_def', 'vanderbilt3_parent_baseline_opp_def', 'vanderbilt3_parent_fu_opp_def']
  };

  var parentCharts = {
    inattentive: ['vanderbilt3_parent_inattentive', 'vanderbilt3_parent_baseline_inattentive', 'vanderbilt3_parent_fu_inattentive'],
    hyperactive: ['vanderbilt3_parent_hyperactive', 'vanderbilt3_parent_baseline_hyperactive', 'vanderbilt3_parent_fu_hyperactive']
  };

  var teacherComorbidities = {
    anx_dep: ['vanderbilt3_teacher_baseline_anx_dep'],
    opp_def_conduct: ['vanderbilt3_teacher_opp_def_conduct', 'vanderbilt3_teacher_baseline_opp_def_conduct', 'vanderbilt3_teacher_fu_opp_def_conduct'],
    learning_disabilities: ['vanderbilt3_teacher_baseline_learning']
  };

  var teacherCharts = {
    inattentive: ['vanderbilt3_teacher_inattentive', 'vanderbilt3_teacher_baseline_inattentive', 'vanderbilt3_teacher_fu_inattentive'],
    hyperactive: ['vanderbilt3_teacher_hyperactive', 'vanderbilt3_teacher_baseline_hyperactive', 'vanderbilt3_teacher_fu_hyperactive']
  };

  function firstScaleMatching(scales, orderedMnemonics) {
    var found = orderedMnemonics.map(function (mnemonic) {
      return scales.find(function (s) {
        return s.get("mnemonic") === mnemonic;
      });
    });

    return found.compact()[0];
  }

  exports.default = _component.default.extend(_drawerEnabled.default, {
    primaryScales: Ember.computed.alias("diagnosticScales"),

    isTeacher: Ember.computed('scales.[]', function () {
      return this.get("scales").any(function (scale) {
        return scale.get("mnemonic").indexOf("teacher") > -1;
      });
    }),

    // This is a nasty hack but right now we don't actually get a computed value for the top scale, only the diagnosis.
    // So just write exactly what we want to be the title rather than using the scale system.
    title: Ember.computed("isTeacher", function () {
      if (this.get("isTeacher")) {
        return "Vanderbilt ADHD - Teacher";
      }

      return "Vanderbilt ADHD - Parent";
    }),

    // Find the first of the diagnostic scales which has data. These are ordered such that they'll take
    // the aggregate if available, as that's resilient to both baseline and follow up usage.
    diagnosticScales: Ember.computed("scales", function () {
      var scaleOptions = this.get("displayData.primaryDiagnosisScales");

      return [firstScaleMatching(this.getWithDefault("scales"), scaleOptions)];
    }),

    graphedScales: Ember.computed("scales.[]", function () {
      var chartsToUse = this.get("isTeacher") ? teacherCharts : parentCharts;
      var scales = this.get("scales");

      return Object.values(chartsToUse).map(function (set) {
        return firstScaleMatching(scales, set);
      });
    }),

    graphOptions: {
      maxValue: 3,
      minValue: 0,
      showYAxis: true,
      isPercentileGraph: false
    },

    graphedData: Ember.computed("scales.[]", 'reports', function () {
      var _this = this;

      return this.get("graphedScales").map(function (scale) {
        return _this.get("reports").flatMap(function (report) {
          return report.get("computedValues").filter(function (cv) {
            return cv.get("scale.id") === scale.get("id");
          });
        });
      });
    }),

    colorSettings: Ember.computed('graphedScales.[]', 'reports', function () {
      var scaleIds = this.get('graphedScales').mapBy('id');
      var cvs = this.getWithDefault("reports", []).flatMap(function (r) {
        return r.get('computedValues').filter(function (cv) {
          return scaleIds.includes(cv.get('scale.id'));
        });
      });

      return _colorSettings.default.create({ computedValues: cvs, reportSettings: this.get('reportSettings') });
    }),

    mostRecentDiagnosis: Ember.computed('mostRecentByScale', 'primaryScale', function () {
      return this.get("mostRecentByScale")[this.get("primaryScale.mnemonic")];
    }),

    comorbidSubscales: Ember.computed("scales", function () {
      var _this2 = this;

      var comorbidities = this.get("isTeacher") ? teacherComorbidities : parentComorbidities;
      var found = Object.values(comorbidities).map(function (mnemonics) {
        var scales = mnemonics.map(function (mnemonic) {
          return _this2.getWithDefault("scales", []).find(function (s) {
            return s.get("mnemonic") === mnemonic;
          });
        }).compact();

        return scales.find(function (scale) {
          return _this2.get("mostRecentByScale")[scale.get("mnemonic")];
        });
      }).compact();

      return found;
    }),

    primaryScale: Ember.computed.alias("primaryScales.firstObject"),

    subscales: Ember.computed("primaryScale", "scales", function () {
      return this.getWithDefault("scales", []).filter(function (s) {
        return !s.get("displayData.groupDisplayData");
      });
    }),

    mostRecentPrimaryValues: Ember.computed("mostRecentValuesAllDomains.[]", function () {
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');

      return this.get("mostRecentValues").filter(function (cv) {
        return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
      });
    }),

    currentPrimaryValues: Ember.computed("currentValuesAllDomains.[]", function () {
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');

      return this.get("currentValuesAllDomains").filter(function (cv) {
        return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
      });
    }),

    historyByScale: Ember.computed('reports.[]', 'scale', function () {
      var primaryScaleMnemonics = this.get("primaryScales").mapBy('mnemonic');
      var scaleMnemonics = this.get("comorbidSubscales").mapBy("mnemonic");
      return this.get("reports").flatMap(function (report) {

        var filtered = report.get("computedValues").filter(function (cv) {
          return cv.get("domain") === 'interpreted' && scaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
        });

        if (filtered.length > 0) {
          return [{
            primary: report.get("computedValues").filter(function (cv) {
              return primaryScaleMnemonics.indexOf(cv.get('scale.mnemonic')) >= 0;
            }),
            all: _lodash.default.groupBy(filtered, function (cv) {
              return cv.get("scale.mnemonic");
            })
          }];
        } else {
          return [];
        }
      });
    }),

    // Npte that all the scales for vanderbit have only one scorer
    mostRecentByScale: Ember.computed('reports.[]', 'scale', function () {
      var result = {};

      this.get("reports").forEach(function (report) {
        report.get("computedValues").forEach(function (cv) {
          if (result[cv.get("scale.mnemonic")]) {
            // nothing to do
          } else {
            result[cv.get("scale.mnemonic")] = cv;
          }
        });
      });

      return result;
    }),

    mostRecentByScaleArray: Ember.computed('reports.[]', 'scale', function () {
      var result = {};

      this.get("reports").forEach(function (report) {
        report.get("computedValues").forEach(function (cv) {
          if (result[cv.get("scale.mnemonic")]) {
            // nothing to do
          } else {
            result[cv.get("scale.mnemonic")] = [cv];
          }
        });
      });

      return result;
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }
  });
});