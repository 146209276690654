define('frontend/pods/components/provider/generate-login-code-link/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    tagName: 'li',
    messages: Ember.inject.service(),

    actions: {
      generateLoginCode: function generateLoginCode() {
        return this._generateLoginCode();
      }
    },

    _generateLoginCode: function _generateLoginCode() {
      var _this = this;

      var request = this.get("store").createRecord("userLoginCode", { patientSession: this.get("session") });

      var onSuccess = function onSuccess(result) {
        _this.get("onGenerateLoginCode")(result);
      };

      var onFailure = function onFailure(error) {
        _this.get('messages').danger(_this.printError(error));
      };

      return request.save().then(onSuccess, onFailure);
    }
  });
});