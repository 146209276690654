define('frontend/pods/components/reporting/date-selector/component', ['exports', 'frontend/utils/date-range-presets', 'frontend/mixins/fixed-modal-mixin'], function (exports, _dateRangePresets, _fixedModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fixedModalMixin.default, {
    classNames: ['date-selector'],

    presets: ['yesterday', 'today', 'lastWeek', 'thisWeek', 'last30Days', 'lastMonth', 'thisMonth', 'last90Days', 'lastQuarter', 'thisQuarter', 'lastYear', 'thisYear', 'allTime'],

    values: Ember.computed('startTime', 'endTime', function () {
      return [this.get('startTime'), this.get('endTime')].filter(function (date) {
        return !!date;
      }).map(function (date) {
        return moment(date);
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('preset') !== 'allTime' && (!this.get('startTime') || !this.get('endTime'))) {
        this._applyPreset(this.get('preset'));
      }
    },
    _applyPreset: function _applyPreset(preset) {
      if (preset === 'custom') {
        this.set('preset', preset);
      } else if (preset) {
        var dates = (0, _dateRangePresets.default)(preset);

        this.setProperties(Ember.merge({
          preset: preset
        }, dates));
      }
    },
    _sendDateRange: function _sendDateRange() {
      var props = this.getProperties('startTime', 'endTime');

      if (this.get('preset') === 'allTime') {
        this.sendAction('setDateRange', {
          preset: 'allTime',
          startTime: undefined,
          endTime: undefined
        });
      } else if (props.startTime && props.endTime) {
        this.sendAction('setDateRange', Ember.merge({ preset: 'custom' }, props));
      }
    },


    actions: {
      sendDateRange: function sendDateRange() {
        this.send('toggleModal');
        this._sendDateRange();
      },
      selectDate: function selectDate(dates) {
        this.setProperties({
          preset: 'custom',
          startTime: dates[0],
          endTime: dates[1]
        });
      },
      setPreset: function setPreset(preset) {
        var _this = this;

        this._applyPreset(preset);

        Ember.run.once(function () {
          _this.send('toggleModal');
          _this._sendDateRange();
        });
      }
    }
  });
});