define("frontend/utils/assessment/dsm", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var dsm = {
    isSignificantChange: function isSignificantChange(computedValue, compareValue) {
      return computedValue.get("value") != compareValue.get("value");
    }
  };

  exports.default = dsm;
});