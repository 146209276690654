define('frontend/pods/provider/patients/show/coverages/show/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var institutePayors = this.store.query('institutePayor', { page: { limit: 300 }, include: 'payor' });
      var coverage = this.modelFor('provider.patients.show.coverages.show');
      return Ember.RSVP.hash({
        coverage: coverage,
        institutePayors: institutePayors
      });
    },
    afterModel: function afterModel(model) {
      // Note we link this here rather than in the show page because only the edit page is linkable
      this.set('breadCrumb', { title: model.coverage.get('displayName'), linkable: true, isRaw: true });
    }
  });
});