define('frontend/pods/components/provider/assessment/patient-provider-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['patient-provider-filter'],
    filter: Ember.inject.service('provider/filter'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    preload: Ember.inject.service(),

    filterNamespace: null,
    providerSelectActive: false,

    sessionUser: Ember.inject.service(),

    //show the filter if the user is in multiple orgs, or if orgs aren't locked and there are more than one of them
    showOrganizations: Ember.computed.gt("organizations.length", 1),

    organizations: Ember.computed('sessionUser.activeOrganizations.[]', function () {
      return this.get('preload.organizations');
    }),

    flags: [],

    filterKey: function filterKey(key) {
      if (this.get('filterNamespace')) {
        return this.get('filterNamespace') + '_' + key;
      } else {
        return key;
      }
    },
    getFilterValue: function getFilterValue(key) {
      return this.get('filter.' + this.filterKey(key));
    },


    showTestData: Ember.computed('filter.showTestData', 'filter.clients_showTestData', function () {
      return this.getFilterValue('showTestData');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('providers')) {
        this.set('providers', this.get('store').query('provider', {
          filter: {
            'users.disabled': false,
            practicing: true
          },
          sort: "user.lastName",
          page: { limit: 300 }
        }));
      }
    },


    actions: {
      changeTestData: function changeTestData(el) {
        this.get('filter').set(this.filterKey('showTestData'), el.checked);
      }
    }
  });
});