define('frontend/pods/components/user/code-login-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    code: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        is: 6
      })]
    }
  });

  exports.default = Ember.Component.extend({
    authenticator: 'devise-patient',
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model', Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        code: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },

    isInvalid: Ember.computed.alias("model.validations.isInvalid"),
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');
        var authenticator = this.get("authenticator");
        var code = this.get("model").get('code');
        this.get("metrics").trackEvent({ event: "patient.kiosk.signInAttempt", method: 'code' });

        var onSuccess = function onSuccess() {
          _this.get("metrics").trackEvent({ event: "patient.kiosk.signInSucceeded", method: 'code' });
        };

        var onFailure = function onFailure() {
          _this.get('messages').danger(intl.t('patient.kiosk.failed'));
          _this.get("metrics").trackEvent({ event: "patient.kiosk.signInFailed", method: 'code' });
        };

        var fqAuthenticator = 'authenticator:' + authenticator;
        var params = { institute_id: this.get("institute.current.id"), login_code: code };

        return this.get('session').authenticate(fqAuthenticator, params).then(onSuccess, onFailure);
      }
    }
  });
});