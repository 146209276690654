define('frontend/models/assessment/invitation-notification', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    dateEmailed: _emberData.default.attr('date'),
    dateTexted: _emberData.default.attr('date'),
    sendAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    notificationTrigger: _emberData.default.attr('string'),

    invitation: _emberData.default.belongsTo('assessment/invitation'),

    activeDate: Ember.computed('sendAt', 'status', 'dateEmailed', 'dateTexted', function () {
      if (this.get("status") === 'sent') {
        var _getProperties = this.getProperties("dateEmailed", "dateTexted"),
            dateEmailed = _getProperties.dateEmailed,
            dateTexted = _getProperties.dateTexted;

        return dateTexted || dateEmailed;
      } else {
        return this.get("sendAt");
      }
    })

  });
});