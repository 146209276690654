define('frontend/models/scheduling/recurrence', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    patientSessions: _emberData.default.hasMany('patient-session'),
    repeatMnemonic: _emberData.default.attr('string'),
    endDate: _emberData.default.attr('date'),
    deleteAfter: _emberData.default.attr('string') // This is the id of the session to delete, until I can find a better way of displaying this.
  });
});