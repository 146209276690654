define('frontend/pods/components/provider/user-consents/list-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    institute: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: "createdAt",
        template: "components/common/server-side-table/absolute-date",
        title: intl.t('model.patientConsent.respondedAt'),
        sortPrecedence: 1,
        sortDirection: "desc"
      }, {
        template: "components/provider/user-consents/list-component/name-column",
        title: intl.t('model.patientConsent.consentType'),
        disableSorting: true
      }, {
        title: intl.t('model.patientConsent.respondent'),
        propertyName: 'user.name',
        disableSorting: true
      }, {
        propertyName: "status",
        title: intl.t('model.patientConsent.acceptance'),
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 10);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient.content) {
          patient = patient.content;
        }

        if (patient) {
          var data = this.get('store').query('userConsent', { include: 'consent,user', filter: { for_patient: patient.get('id') } });
          this.set("data", data);
        }
      }
    },


    awaitingConsent: Ember.computed('patient', function () {
      var patient = this.get("patient");

      if (patient.content) {
        patient = patient.content;
      }
      return this.get('store').query('consentAgreement', { include: 'outstanding-users', filter: { needing_consent: true, for_patient: patient.get('id') } });
    })
  });
});