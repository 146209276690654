define('frontend/pods/components/provider/assessment/report-v2/other-settings/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showExtraSettings: Ember.computed('reportSettings.otherSettings', function () {
      var settings = this.get('reportSettings.otherSettings');
      return settings && Object.keys(settings).length > 0;
    }),
    actions: {
      toggleSetting: function toggleSetting(setting) {
        this.toggleProperty('reportSettings.otherSettings.' + setting);
      }
    }
  });
});