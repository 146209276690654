define('frontend/mixins/rollback-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    disableRollback: false,
    //rollback properties is a list of strings giving properties (can be nested/arrays) to rollback
    rollbackProperties: [],

    rollbackProperty: function rollbackProperty(propertyName, object) {
      var rollbackObj = object.get(propertyName);
      if (!rollbackObj) {
        return;
      }
      if (rollbackObj.toArray) {
        rollbackObj.toArray().forEach(function (o) {
          if (o) {
            o.rollbackAttributes();
          }
        });
      } else if (rollbackObj.content) {
        rollbackObj.content.rollbackAttributes();
      } else if (rollbackObj.rollbackAttributes) {
        rollbackObj.rollbackAttributes();
      }
    },
    _rollbackAllProperties: function _rollbackAllProperties() {
      var _this = this;

      this.get('rollbackProperties').forEach(function (p) {
        _this.rollbackNestedProperty(p, _this);
      });
    },
    rollbackNestedProperty: function rollbackNestedProperty(propertyName, object) {
      var _this2 = this;

      if (propertyName.indexOf('.') < 0) {
        this.rollbackProperty(propertyName, object);
      } else {
        var firstPropertyName = propertyName.substr(0, propertyName.indexOf('.'));
        var nextObj = object.get(firstPropertyName);
        if (!nextObj) {
          return;
        }
        var nextProperty = propertyName.substr(propertyName.indexOf('.') + 1);
        if (nextObj.toArray) {
          nextObj.toArray().forEach(function (o) {
            if (o) {
              _this2.rollbackNestedProperty(nextProperty, o);
            }
          });
        } else {
          this.rollbackNestedProperty(nextProperty, nextObj);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('disableRollback')) {
        this._rollbackAllProperties();
      }
    }
  });
});