define('frontend/pods/components/reporting/flag-filter-selector/component', ['exports', 'frontend/pods/components/reporting/filter-selector/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    selectorClassName: 'report-flags-selector',

    flagsSelected: Ember.computed.gt('selectedFlags.length', 0),

    selectedFlags: Ember.computed('flags.[]', 'flagIds.[]', function () {
      var flagIds = this.get("flagIds");

      return this.getWithDefault("flags").filter(function (flag) {
        return flagIds.indexOf(flag.get('id')) >= 0;
      });
    }),

    actions: {
      toggleFlag: function toggleFlag(flag) {
        var _this = this;

        var current = this.get('flagIds');
        var flagId = flag.get('id');
        if (current.indexOf(flagId) >= 0) {
          current.removeObject(flagId);
        } else {
          current.addObject(flagId);
        }

        if (this.get("filterChanged")) {
          this.get("filterChanged")();
        }

        Ember.run.once(function () {
          return _this.send('toggleModal');
        });
      }
    }
  });
});