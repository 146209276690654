define("frontend/pods/components/provider/patients/patient-table/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    store: Ember.inject.service(),
    filter: Ember.inject.service("provider/filter"),
    preload: Ember.inject.service(),
    screen: Ember.inject.service(),
    rowContext: Ember.computed("preload.flags", function () {
      return {
        flags: this.get('preload.flags')
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var allTableColumns = [{
        template: 'components/common/test-patient-indicator',
        title: '',
        mayBeHidden: false
      }, {
        "title": intl.t('model.patient.flags'),
        "template": "components/provider/patients/patient-table/patient-flags"
      }, {
        "propertyName": "user.firstName",
        "title": intl.t('model.patient.firstName'),
        "template": "components/provider/patients/patient-table/first-name"
      }, {
        "propertyName": "user.lastName",
        "title": intl.t('model.patient.lastName'),
        "template": "components/provider/patients/patient-table/last-name",
        "sortPrecedence": 1,
        "sortDirection": "asc"
      }, {
        "propertyName": "provider.name",
        "title": intl.t('common.provider'),
        "disableSorting": true
      }, {
        "propertyName": "gender",
        "title": intl.t('model.patient.gender'),
        "disableSorting": true
      }, {
        "title": '',
        "template": "components/provider/patients/patient-table/report-link",
        mayBeHidden: false
      }, {
        "title": intl.t('common.actions'),
        "template": "components/provider/patients/patient-table/action-buttons"
      }];

      if (!this.get("institute.configuration.hideMrn")) {
        allTableColumns.splice(1, 0, {
          "propertyName": "mrn",
          "title": intl.t('common.mrn')
        });
      }

      this.get('filter').set('clients_patientProviderMode', 'all');
      this.set("fullColumns", allTableColumns);

      var compressedColumns = [{
        "title": intl.t('common.patient'),
        "sortPrecedence": 1,
        "sortDirection": "asc",
        "sortedBy": "user.lastName",
        "template": "components/provider/patients/patient-table/compressed-patient-details",
        mayBeHidden: false
      }, {
        "propertyName": "provider.name",
        "title": intl.t('common.provider'),
        "disableSorting": true
      }];

      this.set('compressedColumns', compressedColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // Note that the patient search term is passed straight through to the component.
      // We pass in the details for the request rather than the request itself to make sure
      // the filtering is done correctly first time round.
      if (!this.get("data")) {
        var data = Ember.Object.create({
          store: this.get("store"),
          modelName: 'patient',
          query: { include: "last-assessment-report,user,patient-stat", sort: "user.lastName" }
        });
        this.set("data", data);
      }
    }
  });
});