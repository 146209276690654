define("frontend/pods/workflow/model", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // Use this to hide params which are sensitive, e.g. all PII, from urls.
    hiddenParams: _emberData.default.attr("object", { defaultValue: function defaultValue() {} }),

    // Set this if you want to redirect to somewhere on completion.
    targetRoute: _emberData.default.attr("string"),

    complete: function complete() {
      this.destroyRecord();
    },
    completeWith: function completeWith(result, router) {
      router.transitionTo(this.get('targetRoute'), result.id);
      this.destroyRecord();
    }
  });
});