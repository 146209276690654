define('frontend/pods/components/common/interval-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ParseRegex = /P([0-9]+)([DWMY])/;

  exports.default = Ember.Component.extend({
    numberOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    unitOptions: ['D', 'W', 'M', 'Y'],

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._parseFromModel();
      this._addObservers();
    },
    _addObservers: function _addObservers() {
      var _this = this;

      Ember.addObserver(this, 'model.' + this.get('property'), function () {
        _this._parseFromModel();
      });
    },


    actions: {
      updateNumber: function updateNumber(value) {
        if (value === 'null') {
          this.set("selectedNumber", null);
        } else {
          this.set("selectedNumber", value);
        }

        this._updateModel();
      },
      updateUnit: function updateUnit(value) {
        if (value === 'null') {
          this.set("selectedUnit", null);
        } else {
          this.set("selectedUnit", value);
        }

        this._updateModel();
      }
    },

    _parseFromModel: function _parseFromModel() {
      var currentValue = this.get("model").get(this.get("property"));

      if (currentValue) {
        var parsed = ParseRegex.exec(currentValue.toString());

        if (parsed) {
          // Hack: 4 weeks comes back as 28 days, so reconvert.
          var number = parseInt(parsed[1]);
          var unit = parsed[2];

          if (unit === 'D' && number % 7 === 0) {
            unit = 'W';
            number = number / 7;
          }

          this.set("selectedNumber", number);
          this.set("selectedUnit", unit);
        }
      } else {
        this.set("selectedNumber", null);
        this.set("selectedUnit", null);
      }
    },
    _updateModel: function _updateModel() {
      var _getProperties = this.getProperties("selectedNumber", "selectedUnit"),
          selectedNumber = _getProperties.selectedNumber,
          selectedUnit = _getProperties.selectedUnit;

      if (selectedNumber && selectedUnit) {
        var newValue = moment.duration('P' + selectedNumber + selectedUnit);
        this.get("model").set(this.get("property"), newValue);
      } else if (this.get("model").get(this.get('property'))) {
        // Don't set if it's not already set, to avoid a nasty loop where you've set
        // the number or unit but not both. 
        this.get("model").set(this.get("property"), null);
      }
    }
  });
});