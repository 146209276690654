define('frontend/pods/provider/patients/lookup/search/route', ['exports', 'frontend/mixins/workflow-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/pods/base-route'], function (exports, _workflowRoute, _authenticatedRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_workflowRoute.default, _authenticatedRouteMixin.default, {
    model: function model() {
      var workflow = this.workflow;

      return this.store.query("patient", { filter: { email: workflow.get("hiddenParams.email") } });
    },
    afterModel: function afterModel(model, transition) {
      if (model.get("length") >= 1) {
        this.completeWorkflowWithRedirect(model.get("firstObject"));
      } else {
        this.transitionTo("provider.patients.lookup.create", { queryParams: transition.queryParams });
      }
    }
  });
});