define('frontend/pods/provider/patients/show/care-episodes/show/edit/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),

    // The current provider isn't guaranteed to be loaded but we need it to work out the organization structure.
    beforeModel: function beforeModel() {
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      var patient = this.modelFor("provider.patients.show");
      var careEpisode = this.modelFor("provider.patients.show.care-episodes.show");

      var organizations = this.get("preload.organizations");
      var allProviders = this.get("preload.clinicians");

      return Ember.RSVP.hash({
        patient: patient,
        careEpisode: careEpisode,
        organizations: organizations,
        allProviders: allProviders
      });
    }
  });
});