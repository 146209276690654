define("frontend/locales/customers/navigate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: {
      "provider.patients.list.careEpisodeFieldList": "SEE Fields"
    }
  };
});