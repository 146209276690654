define("frontend/pods/components/patient/navigation-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    primaryLocale: Ember.computed("intl.primaryLocale", function () {
      return this.get("intl.primaryLocale");
    }),

    showLanguageButton: true,

    actions: {
      exit: function exit() {
        this.get("sessionUser").silentLogout();
        this.get("router").transitionTo("patient.welcome");
      },
      changeLanguage: function changeLanguage(locale) {
        this.get('intl').setLocale([locale || 'en', 'en']);
        this.get("router").transitionTo({ queryParams: { locale: locale } });
      }
    }
  });
});