define('frontend/pods/components/common/filter-component/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    classNames: ['filter-component'],
    filter: Ember.inject.service('provider/filter'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',

    filterNamespace: null,
    selectActive: false,

    extraProperties: Ember.computed('name', 'titleKey', 'optionProperty', function () {
      var intl = this.get('intl');
      return {
        name: this.get('name'),
        title: intl.t(this.get('titleKey'))
      };
    }),

    filterOptions: Ember.computed('options.[]', function () {
      var intl = this.get('intl');
      var baseOptions = [{
        id: 'mine',
        name: intl.t(this.get('showMineKey')),
        orderedName: intl.t(this.get('showMineKey')),
        mode: 'mine'
      }, {
        id: 'all',
        name: intl.t(this.get('showAllKey')),
        orderedName: intl.t(this.get('showAllKey')),
        mode: 'all'
      }];

      return baseOptions.addObjects(this.get('options').toArray());
    }),

    selectedOption: Ember.computed('name', 'selectedMode', 'filterOptions.[]', {
      get: function get() {
        var id = this.get("selectedMode");
        var name = this.get('name');
        if (name) {
          name = name.capitalize();
        }

        if (!id) {
          id = this.getFilterValue('patient' + name + 'Id');
        }

        return this.get('filterOptions').findBy('id', id);
      },
      set: function set(key, value) {
        var mode = Ember.get(value, 'mode');
        var name = this.get('name');
        if (name) {
          name = name.capitalize();
        }

        if (mode) {
          var _get$setProperties;

          this.get('filter').setProperties((_get$setProperties = {}, _defineProperty(_get$setProperties, this.filterKey('patient' + name + 'Mode'), mode), _defineProperty(_get$setProperties, this.filterKey('patient' + name + 'Id'), void 0), _get$setProperties));
        } else {
          var _get$setProperties2;

          this.get('filter').setProperties((_get$setProperties2 = {}, _defineProperty(_get$setProperties2, this.filterKey('patient' + name + 'Mode'), null), _defineProperty(_get$setProperties2, this.filterKey('patient' + name + 'Id'), Ember.get(value, 'id')), _get$setProperties2));
        }

        this.propertyDidChange("filter.filterDidChange");

        return value;
      }
    }),

    filterKey: function filterKey(key) {
      if (this.get('filterNamespace')) {
        return this.get('filterNamespace') + '_' + key;
      } else {
        return key;
      }
    },
    getFilterValue: function getFilterValue(key) {
      return this.get('filter.' + this.filterKey(key));
    },


    filterKeys: Ember.computed("name", "filter.namespaces.[]", function () {
      var name = this.get("name");
      var namespaces = this.get("filter.namespaces");

      var allKeys = [];

      namespaces.map(function (namespace) {
        allKeys.addObject(namespace + 'patient' + name + 'Mode');
        allKeys.addObject(namespace + 'patient' + name + 'Id');
      });

      return allKeys;
    }),

    selectedMode: Ember.computed("filter.filterDidChange", "name", "filterKeys.[]", function () {
      var name = this.get("name");
      if (name) {
        name = name.capitalize();
      }

      return this.getFilterValue('patient' + name + 'Mode');
    }),

    // selectedName: Ember.computed("filter.filterDidChange", "name", "options.[]", "filterKeys.[]", "filterNamespace",
    //   function() {
    //     let name = this.get("name");
    //     if (name) {
    //       name = name.capitalize();
    //     }

    //     const id = this.getFilterValue(`patient${name}Id`);
    //     const mode = this.getFilterValue(`patient${name}Mode`);
    //     const options = this.get('options');

    //     if (id) {
    //       if (options) {
    //         const option = options.find(p => p.id === id);
    //         if (option) {
    //           return option.get(this.get("optionProperty"));
    //         }
    //       }
    //     } else if (mode === 'all') {
    //       return this.get('intl').t(this.get("showAllKey"));
    //     } else if (mode === 'mine') {
    //       return this.get('intl').t(this.get("showMineKey"));
    //     }
    //   }
    // ),
    actions: {
      setMode: function setMode(mode) {
        var _get$setProperties3;

        var name = this.get("name");
        if (name) {
          name = name.capitalize();
        }

        this.get('filter').setProperties((_get$setProperties3 = {}, _defineProperty(_get$setProperties3, this.filterKey('patient' + name + 'Mode'), mode), _defineProperty(_get$setProperties3, this.filterKey('patient' + name + 'Id'), void 0), _get$setProperties3));

        this.propertyDidChange("filter.filterDidChange");
        this.send('hideSelect');
      },
      setOptionId: function setOptionId(id) {
        var _get$setProperties4;

        var name = this.get("name");
        if (name) {
          name = name.capitalize();
        }

        this.get('filter').setProperties((_get$setProperties4 = {}, _defineProperty(_get$setProperties4, this.filterKey('patient' + name + 'Mode'), 'all'), _defineProperty(_get$setProperties4, this.filterKey('patient' + name + 'Id'), id), _get$setProperties4));

        this.propertyDidChange("filter.filterDidChange");
        this.send('hideSelect');
      },
      setValue: function setValue(value) {
        this.set('selectedOption', value);
      }
    }

  });
});