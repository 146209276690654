define('frontend/pods/components/reporting/columns/scale-value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Note these are all 66% lighter than the original severity palette to make them render in the background better.
  var interpretationMap = {
    none: '#ccfdcc',
    low: '#d8f78d',
    mild: '#ffddbb',
    moderate: '#ffc488',
    severe: '#ff9595',
    unknown: '#ddd'
  };

  exports.interpretationMap = interpretationMap;
  exports.default = Ember.Component.extend({
    classBindings: ['scale-column'],
    valueIndicatorStyle: Ember.computed('column.scaleScorer.id', 'value', function () {
      var value = this.get("value");
      var scaleScorer = this.get("column.scaleScorer");

      if (!scaleScorer) {
        return '';
      }

      var min = scaleScorer.get('minValue');
      var max = scaleScorer.get('maxValue');

      if (!value) {
        return '';
      }

      var valuePercent = (value - min) / max * 100;

      return Ember.String.htmlSafe('left: ' + valuePercent + '%;');
    }),

    comparisonIndicatorStyle: Ember.computed('column.scale.id', 'comparisonValue', function () {
      var value = this.get("comparisonValue");
      var scaleScorer = this.get("column.scaleScorer");

      if (!scaleScorer) {
        return '';
      }

      var min = scaleScorer.get('minValue');
      var max = scaleScorer.get('maxValue');

      if (!value) {
        return '';
      }

      var valuePercent = (value - min) / max * 100;

      return Ember.String.htmlSafe('position:absolute; border-left: 2px gray solid; left: ' + valuePercent + '%; height: 12px');
    }),

    percentBarStyle: Ember.computed("column.scaleScorer.id", "value", function () {
      var scaleScorer = this.get("column.scaleScorer");

      if (!scaleScorer) {
        return null;
      }

      var thresholds = scaleScorer.get("thresholdData");

      if (!thresholds) {
        return null;
      }

      var min = scaleScorer.get('minValue');
      var max = scaleScorer.get('maxValue');

      var startSum = 0;
      var endSum = 0;

      var breaks = thresholds.map(function (item) {
        var color = interpretationMap[item.class];
        startSum = endSum;
        endSum = Math.round((item.max_value - min) / max * 100);

        if (startSum === 0) {
          return color + ', ' + color + ' ' + endSum + '%';
        } else if (endSum === 100) {
          return color + ' ' + startSum + '%, ' + color;
        } else {
          return color + ' ' + startSum + '%, ' + color + ' ' + endSum + '%';
        }
      });

      return Ember.String.htmlSafe('min-height: 1em; background:linear-gradient(to right, ' + breaks.join(',') + ')');
    }),

    valueNotApplicable: Ember.computed('value', function () {
      var value = this.get('value');
      return value === undefined || value === null;
    })
  });
});