define('frontend/pods/related-person/assessment/invitation/route', ['exports', 'frontend/pods/base-route', 'frontend/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    session: Ember.inject.service('session'),
    sessionUser: Ember.inject.service(),
    institute: Ember.inject.service(),
    metrics: Ember.inject.service(),
    queryParams: {
      authenticationMethod: {}
    },

    beforeModel: function beforeModel(transition) {
      var _this = this,
          _arguments = arguments;

      var invitationId = this.paramsFor("related-person.assessment.invitation").invitation_id;
      var authenticationMethod = this.paramsFor("related-person.assessment.invitation").authenticationMethod;
      sessionStorage.setItem("authenticationType", "related_person");
      if (!this.get('session.isAuthenticated')) {
        var onSuccess = function onSuccess() {
          return _this._super.apply(_this, _arguments);
        };

        var onFailure = function onFailure() {
          transition.abort();
          _this.set('session.attemptedTransition', transition);

          if (authenticationMethod === 'link' && _environment.default.featureFlags.enableDemoSalesLinkOnExpired) {
            _this.transitionTo('survey.demo-expired');
          } else {
            _this.transitionTo('patient.login', { queryParams: { invitationId: invitationId, mode: authenticationMethod } });
          }
        };

        if (authenticationMethod === 'link') {
          return this.get('session').authenticate('authenticator:devise-related-person', { institute_id: this.get("institute.current.id"), invitation_id: invitationId, automatic: true }).then(onSuccess, onFailure);
        } else {
          onFailure();
        }

        this.transitionTo('related-person.login', { queryParams: { invitationId: invitationId, mode: authenticationMethod } });
      } else {
        // If it turns out we've timed out, we want to end quietly back here.
        this.set('session.attemptedTransition', transition);

        if (this.get("session").get("data.authenticated.user_type") !== "related_person") {
          this.set('session.attemptedTransition', transition);
          this.set("session.invalidationUrl", this.get("router").generate('related-person.login', { queryParams: { invitationId: invitationId, mode: authenticationMethod } }));
          this.get("metrics").trackEvent({ event: "user.logout", reason: "wrongRole" });
          this.get("sessionUser").logout();
        } else {
          return this._super.apply(this, arguments);
        }
      }
    },
    model: function model(params) {
      return this.store.findRecord('assessment/relatedPersonAssessment', params.invitation_id, { reload: true, adapterOptions: { fieldset: 'all' } });
    },
    afterModel: function afterModel() {
      this.set("session.attemptedTransition", null);
    }
  });
});