define('frontend/pods/survey/taking/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Ensure that we set all of the sentry context by loading the user.
      this.get("sessionUser.currentUser");
    }
  });
});