define('frontend/pods/components/common/graphs/single-horizontal-bar/component', ['exports', 'd3', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3'], function (exports, _d, _d3Support, _marginConvention, _d2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    margin: {
      left: 8,
      top: 3,
      bottom: 3,
      right: 8
    },
    xScale: Ember.computed('model', 'contentWidth', function () {
      var scale = this.get("model.scale");
      var domain = 'raw'; // For now, only support raw data on the horiziontal bar.
      var minValue = scale.minValue.call(scale, domain);
      var maxValue = scale.maxValue.call(scale, domain);

      return _d.default.scale.linear().range([0, this.get('contentWidth')]).domain([minValue, maxValue]);
    }),

    thresholdData: Ember.computed('model.scale', function () {
      var scale = this.get("model.scale");
      return scale.filteredThresholds.call(scale, 'raw');
    }),

    graphData: Ember.computed('model', function () {
      return this.transformData(this.get('model'));
    }),

    mainGraph: (0, _d2.join)([0], 'g.horizontal-bar', {
      enter: function enter(selection) {
        selection.append("g").classed("horizontal-bar", true);
      },
      update: function update(selection) {
        //draw the bars and attach event handlers
        var xScale = this.get("xScale");
        var data = this.get('graphData');
        var thresholdData = this.get("thresholdData");
        var height = this.get('contentHeight');
        var width = this.get('contentWidth');

        this.drawGradients(selection, xScale, height, thresholdData);

        this.set("outline", this.drawOutline(selection, height, width, data));

        // draw the dots that show all past values
        var priorDots = this.drawDataPoints(selection, xScale, height / 2, data);
        this.set('priorDots', priorDots);

        this.drawCurrentValueArrow(selection, xScale, height, data);
        this.drawUnansweredMarks(selection, xScale, height / 2, data);
      }
    }),

    drawGradients: function drawGradients(selection, xScale, height, thresholds) {
      var bars = selection.selectAll('rect.thresholdBar').data(thresholds);

      bars.enter().append('rect').classed('thresholdBar', true);

      bars.attr('class', function (d) {
        return 'thresholdBar ' + d.class;
      }).attr('x', function (d) {
        return xScale(d.min_value);
      }).attr('width', function (d) {
        return xScale(d.max_value) - xScale(d.min_value);
      }).attr('y', 0).attr('height', height);

      bars.exit().remove();
    },

    drawOutline: function drawOutline(selection, height, width, data) {
      var component = this;
      var outline = selection.selectAll('rect.outline').data([data]);

      outline.enter().append('rect').classed('outline', true);

      outline.on('click', function (item) {
        component.actions.setCurrentValue.call(component, item.currentItem);
      });

      outline.attr('x', 0).attr('y', 0).attr('width', width).attr('height', height);

      outline.exit().remove();

      return outline;
    },

    drawCurrentValueArrow: function drawCurrentValueArrow(selection, xScale, height, data) {
      var component = this;
      var points = '0,0 8,0 1,10 1,' + (height - 10) + ' 8,' + height + ' -8,' + height + ' -1,' + (height - 10) + ' -1,10 -8,0';
      // const points = `0,0 8,0 0,10 -8,0`; No lines

      var filtered = data.currentItem.resultValid ? [data.currentItem] : [];
      var triangle = selection.selectAll('polygon.currentValue').data(filtered);

      triangle.enter().append('polygon').classed('currentValue', true).attr('points', points).attr('transform', function (d) {
        return 'translate(' + xScale(d.score) + ', 0)';
      });

      triangle.transition(250).attr('transform', function (d) {
        return 'translate(' + xScale(d.score) + ', 0)';
      });

      triangle.on('click', function (item) {
        component.actions.setCurrentValue.call(component, item);
      });

      triangle.exit().remove();
    },


    drawUnansweredMarks: function drawUnansweredMarks(selection, xScale, centerDistance, data) {
      var minXCoord = xScale.range()[0];
      var component = this;

      var filtered = data.currentItem.resultValid ? [] : [data.currentItem];
      var unansweredMarks = selection.selectAll("text.unanswered").data(filtered);

      unansweredMarks.enter().append("text").classed("unanswered", true).on('click', function (d) {
        component.actions.setCurrentValue.call(component, d);
      });
      unansweredMarks.text('');
      unansweredMarks.transition().duration(250).filter(function (d) {
        return d;
      }).attr("x", minXCoord + centerDistance).attr("y", centerDistance + 4).text("?");

      unansweredMarks.exit().remove();
    },

    drawDataPoints: function drawDataPoints(selection, xScale, centerDistance, data) {
      var component = this;

      // The first point should move, so bind to 0, else bind repeatably to the id, so they don't move.
      var pointGroup = selection.selectAll("g.pointGroup").data(function () {
        return [data];
      });
      pointGroup.enter().append("g").classed("pointGroup", true);
      pointGroup.exit().remove();

      var dataPoints = pointGroup.selectAll("g.point")
      // if they're comparing to intake, render oldest items on top
      .data(function (d) {
        return component.get('compareTo') === 'previous' ? d.previousItems : d.previousItems.slice().reverse();
      }, function (d) {
        return d.age === -1 ? d.age : d.reportId;
      });

      dataPoints.enter().append("g").classed("point", true).attr("transform", function (d) {
        return "translate(" + xScale(d.score) + "," + centerDistance + ")";
      }); // New data points come in at the correct location

      dataPoints.on('click', function (item) {
        component.actions.setCurrentValue.call(component, item, this);
      }).each(function () {
        this.parentNode.appendChild(this); // Now we have every item, redraw them in the right order.
      });

      dataPoints.transition().duration(250).attr("transform", function (d) {
        return "translate(" + xScale(d.score) + "," + centerDistance + ")";
      });

      dataPoints.exit().remove();

      var dots = dataPoints.selectAll("circle.dot").data(function (d) {
        return [d];
      });

      dots.enter().append("circle").classed("dot", true);

      dots.attr("cx", 1);

      dots.transition().duration(250).attr("cy", 0).attr("r", function (d) {
        if (d.age === -1 || d.score == null) {
          return 0;
        }
        //make the prior report bigger
        if (d.isCompare) {
          return 7;
        }
        return 4;
      }).attr("class", function (d) {
        if (d.age === -1) {
          return 'current dot';
        }
        if (d.isCompare) {
          return 'prior dot';
        }
        return 'dot';
      });

      dots.exit().remove();
      return dataPoints;
    },

    transformData: function transformData(data) {
      var valueTransform = function valueTransform(x) {
        return x;
      };
      // the index of the report we're comparing to
      var compareIndex = this.get('compareTo') === 'previous' ? Math.max(0, data.items.length - 2) : 0;
      var currentIndex = Math.max(0, data.items.length - 1);
      var length = data.items.length;
      var neverAnswered = true;
      var items = data.items.map(function (cv, j) {
        var thisValue = cv.get("value");
        var datum = {
          datum: cv,
          x: cv.get("report.patientSession.targetDate"),
          score: cv.get('notRespondedTo') ? null : valueTransform(cv.get("value")),
          isCompare: j === compareIndex,
          reportId: cv.get("report.id"),
          age: j - length,
          resultValid: cv.get("resultValid")
        };

        if (thisValue) {
          neverAnswered = false;
        }
        return datum;
      });

      // now compute symptom deltas where appropriate
      var currentScore = null;
      var compareScore = null;
      var sigDelta = false;
      var delta = null;

      if (data.items[currentIndex] && data.items[compareIndex]) {
        currentScore = valueTransform(data.items[currentIndex].get('value'));
        compareScore = valueTransform(data.items[compareIndex].get('value'));
        sigDelta = data.items[currentIndex].isSignificantChangeFrom(data.items[compareIndex]);
        delta = sigDelta ? currentScore - compareScore : 0;
        // goodDelta = (delta < 0) !== isSkills;
      }

      return {
        scale: data.scale,
        isTargeted: data.isTargeted,
        items: items,
        neverAnswered: neverAnswered,
        delta: delta,
        // goodDelta,
        sigDelta: sigDelta,
        currentScore: currentScore,
        currentItem: items[currentIndex],
        previousItems: items.slice(0, currentIndex),
        compareScore: compareScore
      };
    },

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');
      selection.each(function () {
        var selection = _d.default.select(this);
        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;
        context.mainGraph(selection);
      });
    },


    actions: {
      setCurrentValue: function setCurrentValue(item) {
        var outline = this.get("outline");
        var dots = this.get("priorDots");

        dots.selectAll("circle.dot").classed("selected", function (x) {
          return x === item;
        });
        this.set('currentValue', item.datum);
        outline.classed("selected", true);
        this.sendAction('onValueSelect', item.datum, function () {
          outline.classed("selected", false);
          dots.selectAll("circle.dot").classed("selected", false);
        });
      }
    }
  });
});