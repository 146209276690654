define('frontend/pods/components/provider/plan/schedule-calculator/component', ['exports', 'frontend/pods/components/common/multiple-date-picker/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    demographics: Ember.computed(function () {
      return Ember.Object.create({
        age: this.get('age'),
        gender: this.get('gender')
      });
    }),

    hasDemographics: Ember.computed('entityTreeNode.demographicsPresent', 'demographics.age', 'demographics.gender', function () {
      return this.get("entityTreeNode.demographicsPresent") || this.get('demographics.age') && this.get('demographics.gender');
    }),

    startDate: Ember.computed(function () {
      return new Date();
    }),

    minDate: Ember.computed(function () {
      return new Date();
    }),

    repeatMnemonic: 'weekly',

    dates: Ember.computed('startDate', 'repeatMnemonic', 'endDate', function () {
      return (0, _component.generateRecurrenceDates)(this.get('startDate'), this.get('repeatMnemonic'), this.get('endDate'));
    }),

    endDate: Ember.computed(function () {
      return moment(this.get('startDate')).add(3, 'months').toDate();
    }),

    request: Ember.computed('entityTreeNode', 'demographics.age', 'demographics.gender', 'dates', function () {
      return this.get('store').createRecord('care/measureScheduleCalculatorRequest', {
        entityTreeNode: this.get('entityTreeNode'),
        age: this.get('demographics.age'),
        gender: this.get('demographics.gender'),
        dates: this.get('dates')
      });
    }),

    entityChangedObserver: Ember.observer('entityTreeNode', 'demographics.age', 'demographics.gender', function () {
      // Make sure stale results are not left
      this.set("results", null);
    }),

    refreshDataObserver: Ember.observer('refreshData', function () {
      this.set("results", null);
    }),

    actions: {
      onRecurrenceChange: function onRecurrenceChange(values) {
        this.set('dates', values.dates);
      },

      // Use the base as a template and save it.
      onCalculate: function onCalculate(resultPromise) {
        var _this = this;

        var onSuccess = function onSuccess(results) {
          _this.setProperties({ isLoading: false, isError: false, results: results });
        };

        var onError = function onError() {
          _this.setProperties({ isLoading: false, isError: true, results: null });
        };

        this.setProperties({ isLoading: true, isError: false, results: null });

        resultPromise.then(onSuccess, onError);
      },
      hideResults: function hideResults() {
        this.set("results", null);
      }
    }
  });
});