define('frontend/pods/components/provider/assessment/common/fullslider-answer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['response-answer'],
    tagName: 'span',

    leftLabel: Ember.computed('question.displayData.details.leftLabel', 'question.displayData.details.leftValue', function () {
      if (this.get('question.displayData.details.leftLabel')) {
        return this.get('question.displayData.details.leftLabel');
      }

      return this.get('question.displayData.details.leftValue');
    }),

    rightLabel: Ember.computed('question.displayData.details.rightLabel', 'question.displayData.details.rightValue', function () {
      if (this.get('question.displayData.details.rightLabel')) {
        return this.get('question.displayData.details.rightLabel');
      }

      return this.get('question.displayData.details.rightValue');
    }),

    minValue: Ember.computed.alias('question.displayData.details.leftValue'),
    maxValue: Ember.computed.alias('question.displayData.details.rightValue')

  });
});