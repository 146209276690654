define('frontend/pods/components/scale-creation/templated-model/component', ['exports', 'frontend/models/assessment/scale-templates'], function (exports, _scaleTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scaleTemplates: Ember.inject.service(),
    templatesByName: Ember.computed.alias('scaleTemplates.templatesByName'),
    availableTemplates: Ember.computed('scaleTemplates.templates', 'factory.name', function () {
      return this.get('scaleTemplates.templates')[this.get("factory")];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get("selectedTemplate")) {
        this.set("selectedTemplate", this.get('availableTemplates.firstObject'));
      }
    },


    actions: {
      templateSelected: function templateSelected(template) {
        this.set('selectedTemplate', template);
      },
      addObject: function addObject() {
        var template = this.get("selectedTemplate");
        var newObject = _scaleTemplates.FactoryMap[this.get('factory')].createNewFromTemplate(template, this.get('registry'), Ember.getOwner(this));
        this.set("lastCreatedObject", newObject);

        this.set('model', newObject);
      },
      onDelete: function onDelete() {
        if (confirm("Confirm delete?")) {
          this.set('model', null);
        }
      },
      onChangeTemplate: function onChangeTemplate(object, template) {
        var newObject = _scaleTemplates.FactoryMap[this.get('factory')].replaceTemplate(template, object, this.get('registry'), Ember.getOwner(this));

        this.set("model", newObject);
      }
    }
  });
});