define("frontend/pods/components/provider-admin/users/show-user/component", ["exports", "frontend/mixins/error-printer"], function (exports, _errorPrinter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    institute: Ember.inject.service(),
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      resendWelcomeEmail: function resendWelcomeEmail(user) {
        var _this = this;

        var request = this.get("store").createRecord("userConfirmationEmailRequest", { user: user });

        var onSuccess = function onSuccess() /*success*/{
          _this.get("messages").success(_this.get("intl").t("providerAdmin.users.show.resendWelcomeEmailSuccess"));
        };

        var onFailure = function onFailure(reason) {
          _this.get("messages").danger(_this.printError(reason, "user"));
        };

        return request.save().then(onSuccess, onFailure);
      },
      disableProvider: function disableProvider() {
        var _this2 = this;

        var provider = this.get("provider");

        provider.set("statusEvent", "manual_disable");

        var onFailure = function onFailure(reason) {
          _this2.get("messages").danger(_this2.printError(reason, "provider"));
        };
        return provider.save().then(null, onFailure);
      },
      activateProvider: function activateProvider() {
        var _this3 = this;

        var provider = this.get("provider");

        provider.set("statusEvent", "activate");

        var onFailure = function onFailure(reason) {
          _this3.get("messages").danger(_this3.printError(reason, "provider"));
        };
        return provider.save().then(null, onFailure);
      }
    },
    settingsUrlService: Ember.inject.service("settings-url"),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var url = window.location.origin + "/app/settings" + window.location.pathname;
      this.get("settingsUrlService").setUrl(url);
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      this.get("settingsUrlService").clearUrl();
    }
  });
});