define('frontend/pods/provider/patients/show/sessions/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    breadCrumb: {
      title: 'Sessions',
      linkable: false
    },
    model: function model() {
      return Ember.RSVP.hash({
        patient: this.modelFor('provider.patients.show')
      });
    }
  });
});