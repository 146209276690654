define('frontend/pods/provider/admin/users/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    breadCrumb: null,
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('sessionUser').hasPermission(['editProviders'])) {
        return Ember.RSVP.reject('Permission denied');
      }
    }
  });
});