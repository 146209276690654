define('frontend/pods/components/common/graphs/scale-screener-chart/component', ['exports', 'd3', 'lodash', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3'], function (exports, _d, _lodash, _d3Support, _marginConvention, _d2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    opts: {
      maxValue: 5,
      minValue: -4,
      textMargin: 20,
      labelFactor: 0.95
    },
    classNames: ['screener-chart'],
    defaultMargin: 5,
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    requiredProperties: ['model'],
    chartDots: null,
    chartLabels: null,
    chartBars: null,
    currentSymptom: null,
    showDetail: false,
    displayMode: 'dsm',

    model: null, //array of data with score and scoreClass properties

    graphData: Ember.computed('model', function () {
      return this.transformData(this.get('model'));
    }),

    modeData: {
      dsm: {
        minValue: 0,
        maxValue: 4,
        xScale: function xScale() {
          var width = this.get('contentWidth');
          var leftAxisMargin = this.get("currentModeData").leftAxisMargin(width);
          var x = _d.default.scale.ordinal().rangeBands([leftAxisMargin, width], 0.03);

          var _get = this.get("currentModeData"),
              minValue = _get.minValue,
              maxValue = _get.maxValue;

          var pills = [];

          for (var ii = minValue; ii <= maxValue; ii++) {
            pills.addObject(ii);
          }

          x.domain(pills);

          return x;
        },
        xAxis: function xAxis(xScale, selection) {

          var opts = this.opts;
          var labels = selection.selectAll('text.scoreLabel').data(['none', 'slight', 'mild', 'mod', 'severe']);

          labels.enter().append('text').classed('scoreLabel', true);

          labels.attr('transform', function (d, i) {
            return 'translate(' + (xScale(i) + xScale.rangeBand() / 2) + ', ' + opts.textMargin / 2 + ')';
          }).text(function (d) {
            return d;
          }).attr('text-anchor', 'middle');

          labels.exit().remove();
        },
        leftAxisMargin: function leftAxisMargin(width) {
          // We'll use nano mode (i.e. shorter headings) if it's small, so we need less space.
          return width < 450 ? 130 : 170;
        },
        useNanoText: function useNanoText(width) {
          // Use nano text when things are narrow.
          return width < 450;
        },
        valueTransform: function valueTransform(value) {
          return value;
        }
      }
    },

    currentModeData: Ember.computed("modeData", "displayMode", function () {
      return this.get("modeData")[this.get('displayMode')];
    }),

    actions: {
      setCurrentSymptom: function setCurrentSymptom(item, d3this, index) {
        // If the scale wasn't answered its possible for item to be undefined. Most likely a PTPB
        // specific issue if the RelatedPerson hasn't completed their assessment.
        if (!item) {
          return;
        }

        var bars = this.get('chartBars');
        var dots = this.get('chartDots');
        var labels = this.get('chartLabels');
        var datum = item.datum;

        this.set('currentSymptom', datum);

        this.get("metrics").trackEvent({ event: 'provider.assessment.reportScaleClicked', clickedReportId: datum.get("report.id"), value: datum.get("value"), scaleId: datum.get("scale.id") });

        this.sendAction('onValueSelect', item.datum, function () {
          bars.classed('selected', false);
          dots.classed('selected', false);
          labels.classed('selected', false);
        });

        bars.classed("selected", function (x) {
          return x.lastAnswered === item;
        });
        dots.classed("selected", function (x) {
          return x === item;
        });
        labels.classed("selected", function (x, i) {
          return i === index;
        });
      }
    },

    xScale: Ember.computed('contentWidth', 'graphData', 'displayMode', function () {
      return this.get("currentModeData").xScale.call(this);
    }).readOnly(),

    // The yscale has 2 parts: the 'good' side is compressed to take up 1/3, and 2/3rds go to the 'bad' side
    yScale: Ember.computed('contentHeight', function () {
      var height = this.get('contentHeight');
      var yHeight = height - this.opts.textMargin;
      var refData = this.get('graphData');
      var y = _d.default.scale.ordinal().rangeBands([this.opts.textMargin, yHeight], 0.1);
      y.domain(refData);

      return y;
    }).readOnly(),

    axes: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var axis = selection.append("g").classed("axis", true);

        axis.append("rect").attr("class", "background");

        axis.append("g").attr("class", "baraxis y");

        axis.append("g").attr("class", "baraxis x");
      },
      update: function update(selection) {
        var component = this;
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var width = this.get('contentWidth');
        var data = this.get('graphData');
        var offeredScales = this.get('offeredScales');
        var useNanoText = this.get("currentModeData").useNanoText(width);
        var leftAxisMargin = this.get("currentModeData").leftAxisMargin(width);
        this.axisSelection = selection;

        var yAxis = _d.default.svg.axis().scale(yScale).ticks(data.length).orient('left');

        var labelText = selection.selectAll('g.baraxis.y').attr("transform", "translate(" + leftAxisMargin + ",0)").call(yAxis).selectAll("text").attr("dx", "-.2em").attr("class", function (d) {
          var isOffered = true;
          if (offeredScales && offeredScales.length && offeredScales.indexOf(d) < 0) {
            isOffered = false;
          }
          return "barlabel action" + (isOffered ? " offered" : " notOffered");
        }).text("");

        labelText.on('click', function (symptom, i) {
          var symptomData = _lodash.default.find(data, function (d) {
            return d.mnemonic === symptom.mnemonic;
          });
          component.actions.setCurrentSymptom.call(component, symptomData.items[symptomData.items.length - 1], this, i);
        });
        labelText.append('tspan').attr('class', 'icon').text(function (d) {
          var symptomData = _lodash.default.find(data, function (s) {
            return s.datum === d.datum;
          });
          return symptomData.isTargeted ? '\uF192 ' : "";
        });
        labelText.append('tspan').text(function (d) {
          if (useNanoText) {
            return d.datum.get('translatedNanoname');
          } else {
            return d.datum.get('translatedShortname');
          }
        });
        this.set('chartLabels', labelText);

        this.get("currentModeData").xAxis.call(this, xScale, selection);
      }
    }),

    mainGraph: (0, _d2.join)([0], 'g.mainScreenerGraph', {
      enter: function enter(selection) {
        selection.append("g").classed("mainScreenerGraph", true);
      },
      update: function update(selection) {
        //draw the bars and attach event handlers
        var xScale = this.get("xScale");
        var yScale = this.get("yScale");
        var data = this.get('graphData');
        var offeredScales = this.get('offeredScales');

        var centerDistance = yScale.rangeBand() / 2;

        // symptomRegions are the areas of the graph in which each symptom is rendered
        var symptomRegions = this.drawSymptomRegions(data, selection, yScale, offeredScales);

        //draw the bars that show the current value of the symptoms
        var symptomBars = this.drawCurrentValueBars(symptomRegions, xScale, yScale, centerDistance);
        this.set('chartBars', symptomBars);

        //draw the dots that show all past values
        var dataPoints = this.drawDataPoints(symptomRegions, xScale, centerDistance);
        this.set('chartDots', dataPoints);

        //draw the indicators of change
        this.drawDeltaIndicators(symptomRegions, xScale, centerDistance);

        //draw indicators of symptoms with insufficient data to score
        this.drawUnansweredMarks(symptomRegions, xScale, centerDistance);
      }
    }),

    drawSymptomRegions: function drawSymptomRegions(data, selection, yScale, offeredScales) {
      var symptomRegions = selection.selectAll(".screenerLine").data(data);

      symptomRegions.enter().append("g");

      symptomRegions.transition().duration(250).attr("class", function (d) {
        var isOffered = true;

        if (offeredScales && offeredScales.length && offeredScales.indexOf(d.mnemonic) < 0) {
          isOffered = false;
        }
        var offeredClass = isOffered ? " offered" : " notOffered";
        if (d.items.length) {
          var deltaClass = d.sigDelta ? " sigDelta" : "";
          var deltaDirClass = d.goodDelta ? " posDelta" : " negDelta";
          return "screenerLine" + offeredClass + deltaClass + deltaDirClass;
        }
        return "screenerLine" + offeredClass;
      });

      symptomRegions.attr("transform", function (d) {
        return "translate(0, " + yScale(d.datum.get("mnemonic")) + ")";
      });

      symptomRegions.exit().remove();
      return symptomRegions;
    },

    drawCurrentValueBars: function drawCurrentValueBars(symptomRegions, xScale, yScale) {
      var component = this;

      var lines = symptomRegions.selectAll('g.pillLine').data(function (d) {
        return [d];
      });
      lines.enter().append('g').classed('pillLine', true);

      var pills = lines.selectAll('rect.pill').data(function (d) {
        return d.pills;
      }, function (d) {
        return d.value;
      });

      pills.enter().append('rect').classed('pill', true);

      pills.classed("answerNotCurrent", function (d) {
        return d && d.age < -1;
      }).attr('height', yScale.rangeBand()).attr('width', xScale.rangeBand()).attr('x', function (d) {
        return xScale(d.value);
      }).attr('rx', 5).attr('ry', 5).attr('class', function (d) {
        var unfilledClass = d.filled ? '' : 'unfilled';
        return 'pill ' + d.class + ' ' + unfilledClass;
      }).attr('y', 1).on('click', function (d, i, v) {
        component.actions.setCurrentSymptom.call(component, d.parent.lastAnswered, this, v);
      });

      pills.exit().remove();

      lines.exit().remove();

      return lines;
    },

    drawDataPoints: function drawDataPoints(symptomRegions, xScale, centerDistance) {
      var component = this;

      // The first point should move, so bind to 0, else bind repeatably to the id, so they don't move.
      var pointGroup = symptomRegions.selectAll("g.pointGroup").data(function (d) {
        return [d];
      });
      pointGroup.enter().append("g").classed("pointGroup", true);
      pointGroup.exit().remove();

      var xCenter = xScale.rangeBand() / 2;

      var dataPoints = pointGroup.selectAll("g.point")
      // if they're comparing to intake, render oldest items on top
      .data(function (d) {
        if (d.items.length < 2) {
          return [];
        }
        return component.get('compareTo') === 'previous' ? [d.items[d.items.length - 2]] : [d.items[0]];
      });
      // .data((d) => { return component.get('compareTo') === 'previous' ? d.items : d.items.slice().reverse(); },
      //       (d) => { return d.age === -1 ? d.age : d.reportId; }); TODO: render whole history rather than single item

      dataPoints.enter().append("g").classed("point", true).attr("transform", function (d) {
        return "translate(" + (xScale(d.score) + xCenter) + "," + centerDistance + ")";
      }); // New data points come in at the correct location

      dataPoints.on('click', function (item, i, v) {
        component.actions.setCurrentSymptom.call(component, item, this, v);
      }).each(function () {
        this.parentNode.appendChild(this); // Now we have every item, redraw them in the right order.
      });

      dataPoints.transition().duration(250).attr("transform", function (d) {
        return "translate(" + (xScale(d.score) + xCenter) + "," + centerDistance + ")";
      });

      dataPoints.exit().remove();

      var dots = dataPoints.selectAll("circle.dot").data(function (d) {
        return [d];
      });

      dots.enter().append("circle").classed("dot", true);

      dots.attr("cx", 1);

      dots.filter(function (d) {
        return !d.score;
      }).remove();

      dots.transition().duration(250).attr("cy", 0).attr("r", function () {
        return 7;
      }).attr("class", function (d) {
        if (d.age === -1) {
          return 'current dot';
        }
        if (d.isCompare) {
          return 'prior dot';
        }
        return 'dot';
      });

      dots.exit().remove();
      return dataPoints;
    },

    // delta indicators are lines drawn from the most recent value to the comparison point
    // they are drawn inside the comparison dot's point so they are always on top.
    drawDeltaIndicators: function drawDeltaIndicators(symptomRegions, xScale, centerDistance) {
      // MAKE THE DELTA ARROWS
      var rightPointedTriangle = "0,0 10,8 10,-8";
      var leftPointedTriangle = "0,0 -10,8 -10,-8";
      var rangeBandOffset = xScale.rangeBand() / 2;
      var negativeTriangle = rightPointedTriangle,
          positiveTriangle = leftPointedTriangle;

      var deltaGroup = symptomRegions.selectAll("g.deltaGroup").data(function (d) {
        return [d];
      });
      deltaGroup.enter().append('g').classed('deltaGroup', true);
      deltaGroup.exit().remove();

      var polygons = deltaGroup.selectAll("polygon").data(function (d) {
        return [d];
      });

      var drawPoly = function drawPoly(selection) {
        return selection.attr("points", function (d) {
          return d.delta > 0 ? positiveTriangle : negativeTriangle;
        }).attr("transform", function (d) {
          return "translate(" + (xScale(d.currentScore) + rangeBandOffset) + "," + centerDistance + ")";
        }).attr("class", "delta");
      };

      polygons.enter().insert("polygon", ":first-child").filter(function (d) {
        return d.sigDelta;
      }).call(drawPoly);
      polygons.transition().duration(250).call(drawPoly);

      // There does not seem to be a more efficient way to compute this than doing the filter twice.
      polygons.filter(function (d) {
        return !d.sigDelta;
      }).remove();
      polygons.exit().remove();

      //MAKE THE LINES for the arrows
      var deltaLines = deltaGroup.selectAll("line.deltaLine").data(function (d) {
        return [d];
      });

      var drawDeltaLine = function drawDeltaLine(selection) {
        return selection.attr("class", "deltaLine").attr("x1", function (d) {
          var offset = d.goodDelta ? 5 : -5;
          return xScale(d.currentScore) + offset + rangeBandOffset;
        }).attr("y1", centerDistance).attr("x2", function (d) {
          var offset = d.goodDelta ? -6 : 6;
          return xScale(d.compareScore) + offset + rangeBandOffset; // we can't just use xScale(d.delta) since the scale is nonlinear
        }).attr("y2", centerDistance);
      };

      deltaLines.enter().insert('line', ":first-child").call(drawDeltaLine);
      deltaLines.transition().duration(250).filter(function (d) {
        return d.sigDelta;
      }).call(drawDeltaLine);

      deltaLines.filter(function (d) {
        return !d.sigDelta;
      }).remove();
      deltaLines.exit().remove();
    },

    drawUnansweredMarks: function drawUnansweredMarks(symptomRegions, xScale, centerDistance) {
      var minXCoord = xScale.range()[0];
      var component = this;
      var unansweredMarks = symptomRegions.selectAll("text.unanswered").data(function (d) {
        return [d.neverAnswered];
      });

      unansweredMarks.enter().append("text").classed("unanswered", true).on('click', function (d, i, v) {
        component.actions.setCurrentSymptom.call(component, d[d.length - 1], this, v);
      });
      unansweredMarks.text('');
      unansweredMarks.transition().duration(250).filter(function (d) {
        return d;
      }).attr("x", minXCoord + centerDistance).attr("y", centerDistance + 4).text("?");

      unansweredMarks.exit().remove();
    },

    //transform an [time][symtpom] array  into a  [symptom][time] array
    transformData: function transformData(data) {
      var _this = this;

      if (!data[0]) {
        return [];
      }

      var _get2 = this.get("currentModeData"),
          valueTransform = _get2.valueTransform;

      // We can be passed in either one per scale, or one per base scale with a scale for each.


      var dataToMap = data[0].baseMnemonic ? data.flatMap(function (d) {
        return d.scales;
      }) : data;

      // the index of the report we're comparing to
      var compareIndex = this.get('compareTo') === 'previous' ? Math.max(0, dataToMap[0].items.length - 2) : 0;
      var currentIndex = Math.max(0, dataToMap[0].items.length - 1);

      return dataToMap.map(function (scaleData, i) {
        var length = scaleData.items.length;
        var neverAnswered = true;
        var lastAnswered = null;
        var items = scaleData.items.map(function (cv, j) {
          var thisValue = cv.get("value");
          var datum = {
            datum: cv,
            x: cv.get("report.patientSession.targetDate"),
            score: cv.get('notRespondedTo') ? null : valueTransform(cv.get("value")),
            isCompare: j === compareIndex,
            reportId: cv.get("report.id"),
            age: j - length
          };

          if (typeof thisValue === 'number') {
            neverAnswered = false;
            lastAnswered = datum;
          }
          return datum;
        });

        // now compute symptom deltas where appropriate
        var currentScore = null;
        var compareScore = null;
        var delta = null;
        var goodDelta = null;
        var sigDelta = null;

        if (scaleData.items[currentIndex] && scaleData.items[compareIndex]) {
          currentScore = valueTransform(scaleData.items[currentIndex].get('value'));
          compareScore = valueTransform(scaleData.items[compareIndex].get('value'));
          if (currentScore !== null && compareScore !== null) {
            delta = currentScore - compareScore;
            goodDelta = delta < 0;
            sigDelta = delta !== 0;
          } else {
            delta = null;
            goodDelta = false;
            sigDelta = false;
          }
        }

        var transformed = {
          index: i,
          mnemonic: scaleData.scale.get("mnemonic"),
          datum: scaleData.scale,
          isTargeted: _this.get('patientScaleTargets').any(function (t) {
            return t.get('scale.id') === scaleData.scale.get('id');
          }),
          items: items,
          lastAnswered: lastAnswered,
          neverAnswered: neverAnswered,
          delta: delta,
          sigDelta: sigDelta,
          goodDelta: goodDelta,
          currentScore: currentScore,
          compareScore: compareScore,
          toString: function toString() {
            return scaleData.scale.get("mnemonic");
          }
        };

        var pills = [];
        var thresholds = scaleData.scale.get("thresholdData");
        var minValue = Math.min.apply(Math, _toConsumableArray(scaleData.scale.get("thresholdData").map(function (t) {
          return parseInt(t.min_value);
        })));
        var maxValue = Math.max.apply(Math, _toConsumableArray(scaleData.scale.get("thresholdData").map(function (t) {
          return parseInt(t.max_value);
        })));

        var _loop = function _loop(ii) {
          pills.addObject({
            value: ii,
            class: thresholds.find(function (t) {
              return ii >= t.min_value && ii < t.max_value || t.max_value === maxValue && ii === maxValue;
            }).class,
            filled: typeof currentScore === 'number' && ii <= currentScore,
            parent: transformed,
            toString: function toString() {
              return ii;
            }
          });
        };

        for (var ii = minValue; ii <= maxValue; ii++) {
          _loop(ii);
        }
        transformed.pills = pills;

        return transformed;
      });
    },

    //gets the low value for the bar
    getBarMinXCoord: function getBarMinXCoord(d, xscale) {
      return _d.default.min(d, function (d) {
        return xscale(d.score);
      });
    },

    //gets the high value for the bar
    getBarMaxXCoord: function getBarMaxXCoord(d, xscale) {
      return _d.default.max(d, function (d) {
        return xscale(d.score);
      });
    },

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');
      selection.each(function () {
        var selection = _d.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');
        context.chartSelection = selection;

        context.axes(selection);
        context.mainGraph(selection);
      });
    }
  });
});