define('frontend/mixins/drawer-enabled', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    metrics: Ember.inject.service(),
    metricEventName: 'unknown.drawerAction',
    metricEventData: {},
    isDrawerShown: false,
    actions: {
      //selector is an optional argument that specifies where to find the drawer.  other wise it will just find the drawer in the component's root element
      //note that if the component specifies tagName: '', a selector MUST be specified
      drawer: function drawer(selector, overlay) {
        var metricEventName = this.get("metricEventName");

        if (metricEventName && !this.get("isDrawerShown")) {
          var data = this.getWithDefault('metricEventData', {});
          this.get("metrics").trackEvent(Ember.merge(data, { event: metricEventName }));
        }

        this.set('isDrawerShown', !this.get('isDrawerShown'));
        if (selector) {
          $(selector).find('.drawer').toggleClass('active');
        } else {
          this.$().find('.drawer').toggleClass('active');
        }

        if (!overlay) {
          overlay = '.site-overlay.overlay-level-1';
        }

        $(overlay).toggleClass('fade');
      }
    }
  });
});