define('frontend/pods/provider/patients/show/care-episodes/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    metrics: Ember.inject.service(),
    breadCrumb: {
      title: 'Care Episodes',
      linkable: false
    }
  });
});