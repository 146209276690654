define('frontend/models/assessment/report-comparison', ['exports', 'frontend/models/assessment/scale-comparison', 'lodash'], function (exports, _scaleComparison, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    activeReport: null,
    comparisonReport: null,
    population: null,

    comparisons: Ember.computed('activeReport', 'comparisonReport', function () {
      var _getProperties = this.getProperties('activeReport', 'comparisonReport'),
          activeReport = _getProperties.activeReport,
          comparisonReport = _getProperties.comparisonReport;

      var latestValues = activeReport.get("computedValues").filter(function (value) {
        return !value.get('notRespondedTo');
      });
      var comparisonValues = comparisonReport.get("computedValues").filter(function (value) {
        return !value.get('notRespondedTo');
      });

      var pairs = {};

      latestValues.forEach(function (computedValue) {
        pairs[computedValue.get("scale.id")] = { latestValue: computedValue };
      });

      comparisonValues.forEach(function (computedValue) {
        // Only compare values in the current report, drop the others
        if (pairs[computedValue.get("scale.id")]) {
          pairs[computedValue.get("scale.id")].comparisonValue = computedValue;
        }
      });

      return _lodash.default.values(pairs).map(function (values) {
        return _scaleComparison.default.create(values);
      });
    }),

    normedComparisons: Ember.computed('activeReport', 'comparisonReport', 'population', function () {
      var _getProperties2 = this.getProperties('activeReport', 'comparisonReport', 'population'),
          activeReport = _getProperties2.activeReport,
          comparisonReport = _getProperties2.comparisonReport,
          population = _getProperties2.population;

      if (Ember.isEmpty(activeReport)) {
        return [];
      }

      var popId = population ? population.id : null;

      var latestValues = activeReport.getWithDefault("computedValues", []).filter(function (value) {
        if (!popId) {
          return !value.get("population.id") && !value.get('notRespondedTo');
        } else {
          return value.get("population.id") === popId && !value.get('notRespondedTo');
        }
      });
      var comparisonValues = comparisonReport.getWithDefault("computedValues", []).filter(function (value) {
        if (!popId) {
          return !value.get("population.id") && !value.get('notRespondedTo');
        } else {
          return value.get("population.id") === popId && !value.get('notRespondedTo');
        }
      });

      var pairs = {};

      latestValues.forEach(function (computedValue) {
        pairs[computedValue.get("scale.id")] = { latestValue: computedValue };
      });

      comparisonValues.forEach(function (computedValue) {
        // Only compare values in the current report, drop the others
        if (pairs[computedValue.get("scale.id")]) {
          pairs[computedValue.get("scale.id")].comparisonValue = computedValue;
        }
      });

      return _lodash.default.values(pairs).map(function (values) {
        return _scaleComparison.default.create(values);
      });
    })
  });
});