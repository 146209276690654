define('frontend/pods/components/provider/user-consents/administer-consent/component', ['exports', 'frontend/utils/survey/survey-hooks'], function (exports, _surveyHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var assentModes = ['patient_and_guardian_if_child', 'patient_and_guardian'];

  exports.default = Ember.Component.extend({
    classNames: "panel panel-primary",
    // This replicates the logic in local survey adapter to create the appropriate form for the survey
    consentData: Ember.computed('consent', 'user', function () {
      var consent = this.get('consent');
      var agreement = this.get('consentAgreement');
      var user = this.get('user');

      var textSource = 'consent';

      if (user.get("userType") === 'patient' && assentModes.includes(consent.get('mode'))) {
        textSource = 'assent';
      }

      return {
        id: consent.get('id'),
        mnemonic: "consent" + consent.get('id'),
        agreement_id: agreement.get('id'),
        user_id: user.get('id'),
        content: consent.get(textSource + 'Text'),
        allow_defer: true, // Hardcode hide defer on the provider version qqhrb
        agree_text: consent.get(textSource + 'AgreeText'),
        decline_text: consent.get(textSource + 'DeclineText'),
        defer_text: consent.get(textSource + 'DeferText'),
        info_text: consent.get(textSource + 'InfoText'),
        info_button_text: consent.get(textSource + 'InfoButtonText'),
        preamble_text: consent.get(textSource + 'PreambleText'),
        preamble_yes_text: consent.get(textSource + 'PreambleYesText'),
        preamble_no_text: consent.get(textSource + 'PreambleNoText')
      };
    }),
    page: Ember.computed('consentData', function () {
      return _surveyHooks.default._createConsentPage(this.get('consentData'));
    }),
    actions: {
      onSubmit: function onSubmit() {
        this.get("router").transitionTo("provider.patients.show");
      }
    }
  });
});