define('frontend/pods/components/provider/assessment/report-v2/badges/substance-use-badge/component', ['exports', 'frontend/mixins/drawer-enabled', 'lodash'], function (exports, _drawerEnabled, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    tagName: '',
    messageKey: 'substanceUse',
    isBadgeShown: Ember.computed.alias('activeReport.includesComputedSubstanceUse'),

    isRiskPresent: Ember.computed.not('isMinimalRisk'),

    allRiskValues: Ember.computed('activeReport.computedValues.[]', "activeReport", function () {
      var activeReport = this.get('activeReport');
      if (!activeReport) {
        return 0;
      }

      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');

      var combined = computedValuesByScale['sfssSubstanceUseCombined'];

      if (combined && combined.get('length') > 0) {
        return combined;
      } else {
        return [computedValuesByScale['sfssSubstanceUse'], computedValuesByScale['sfssSubstanceUse_C']].compact().flatten().filterBy('domain', 'raw');
      }
    }),

    primaryRiskValue: Ember.computed("allRiskValues", function () {
      var values = this.getWithDefault("allRiskValues", []);
      return _lodash.default.maxBy(values, function (cv) {
        return cv.get('value');
      });
    }),

    isMinimalRisk: Ember.computed("allRiskValues.[]", function () {
      var values = this.getWithDefault("allRiskValues", []);
      return _lodash.default.max(values.mapBy('value')) === 0;
    }),

    isRisk: Ember.computed("allRiskValues", function () {
      if (this.get('isMinimalRisk')) {
        return 'minimal-risk';
      } else {
        return 'possible-risk';
      }
    })
  });
});