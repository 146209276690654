define('frontend/models/user-consent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    consentType: _emberData.default.attr('string'),
    consentAgreement: _emberData.default.belongsTo('consentAgreement', { async: false }),
    consent: _emberData.default.belongsTo('consent', { async: false }),
    accepted: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),

    // Used for persisting when we don't have the relationship
    consentAgreementId: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string')
  });
});