define('frontend/models/survey/calculate-messages-request', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    surveyResponseId: _emberData.default.attr("string"),

    surveyResponse: _emberData.default.belongsTo("survey/response"),
    careEpisodeMessages: _emberData.default.hasMany("careEpisodeMessage")
  });
});