define('frontend/pods/components/common/graphs/scale-overview-legend/component', ['exports', 'd3', 'ember-cli-d3/mixins/d3-support', 'ember-cli-d3/mixins/margin-convention', 'ember-cli-d3/utils/d3'], function (exports, _d, _d3Support, _marginConvention, _d2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_d3Support.default, _marginConvention.default, {
    classNames: ['bar-chart'],
    defaultMargin: 0,

    intl: Ember.inject.service(),
    screen: Ember.inject.service(),

    legend: (0, _d2.join)([0], 'g.axis', {
      enter: function enter(selection) {
        var legend = selection.append("g").classed("legend", true);

        var circleEntry = legend.append('g').attr('class', 'circleEntry');
        circleEntry.append("circle").attr("class", "symbol prior dot");
        circleEntry.append('text').attr('class', 'description');

        var triangleEntry = legend.append('g').attr('class', 'triangleEntry');
        triangleEntry.append("polygon").attr("class", "symbol");
        triangleEntry.append('text').attr('class', 'description');

        var barEntry = legend.append('g').attr('class', 'barEntry');
        barEntry.append("rect").attr("class", "symbol");
        barEntry.append('text').attr('class', 'description');
      },
      update: function update(selection) {
        var intl = this.get('intl');

        selection.selectAll(".barEntry .symbol").attr('x', 5).attr('y', 4).attr('height', 12).attr('width', 18);
        selection.selectAll(".barEntry .description").attr('x', 26).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.bar'));

        selection.selectAll(".circleEntry .symbol").attr('transform', 'translate(135, 0)').attr('cx', 10).attr('cy', 10).attr('r', 6);
        selection.selectAll(".circleEntry .description").attr('transform', 'translate(135, 0)').attr('x', 20).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.dots'));

        if (this.get("screen.isCompressed")) {
          selection.selectAll(".triangleEntry").attr('transform', 'translate(50, 22)');
          var rightPointedTriangle = "9,0 0,7 0,-7";
          selection.selectAll(".triangleEntry .symbol").attr('points', rightPointedTriangle).attr('transform', 'translate(0,10)');
          selection.selectAll(".triangleEntry .description").attr('x', 15).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.arrow'));
        } else {
          // They are put in a single line in larger mode
          selection.selectAll(".triangleEntry").attr('transform', 'translate(245, 0)');
          var _rightPointedTriangle = "9,0 0,7 0,-7";
          selection.selectAll(".triangleEntry .symbol").attr('points', _rightPointedTriangle).attr('transform', 'translate(0,10)');
          selection.selectAll(".triangleEntry .description").attr('x', 15).attr('y', 15).text(intl.t('provider.assessments.report.symptomsOverview.legend.arrow'));
        }
      }
    }),

    call: function call(selection) {
      var context = this;
      var top = this.get('margin.top');
      var left = this.get('margin.left');

      selection.each(function () {
        var selection = _d.default.select(this);

        selection.attr('transform', 'translate(' + left + ' ' + top + ')');

        context.legend(selection);
      });
    }
  });
});