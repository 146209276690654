define('frontend/pods/components/user/update-password-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      validators: [(0, _emberCpValidations.validator)("length", {
        min: 8
      }), (0, _emberCpValidations.validator)("presence", true)]
    },
    passwordConfirmation: {
      validators: [(0, _emberCpValidations.validator)("confirmation", {
        on: "password"
      }), (0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)('format', {
        regex: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/,
        message: '{description} must include at least one upper case letter, one lower case letter, and a number'
      })]
    }
  });

  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    allowSkip: true,
    baseTextKey: "provider",
    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      for (var _len = arguments.length, params = Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      this._super(params);

      this.set('model', Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        password: null,
        passwordConfirmation: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },


    actions: {
      submit: function submit() {
        var _this = this;

        var _get$getProperties = this.get("model").getProperties("password", "passwordConfirmation"),
            password = _get$getProperties.password,
            passwordConfirmation = _get$getProperties.passwordConfirmation;

        var request = this.get("store").createRecord('userPassword', { user: this.get("user"), password: password, passwordConfirmation: passwordConfirmation });
        var onSuccess = function onSuccess() {
          _this.get("messages").success("Your password has been changed");
          _this.get("onComplete")();
        };

        var onError = function onError(error) {
          _this.get("messages").danger(error);
        };

        return request.save().then(onSuccess, onError);
      },
      skip: function skip() {
        this.get("onComplete")();
      }
    }
  });
});