define('frontend/models/billing/payor-contract', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = undefined;


  var Validations = (0, _emberCpValidations.buildValidations)({
    institutePayor: (0, _emberCpValidations.validator)('presence', true),
    name: (0, _emberCpValidations.validator)('presence', true),
    startDateString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        allowBlank: true,
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    endDateString: {
      validators: [(0, _emberCpValidations.validator)('date', {
        allowBlank: true,
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    }
  });

  exports.Validations = Validations;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    institutePayor: _emberData.default.belongsTo('institutePayor', { async: false }),
    contractCodes: _emberData.default.hasMany('billing/payorContractCode', { async: false }),

    name: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),

    startDateString: Ember.computed('startDate', {
      get: function get() {
        if (this.get('startDate')) {
          return moment(this.get('startDate')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("startDate", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    }),

    endDateString: Ember.computed('endDate', {
      get: function get() {
        if (this.get('endDate')) {
          return moment(this.get('endDate')).format("MM/DD/YYYY");
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("endDate", moment(value, "MM/DD/YYYY").toDate());
          return value;
        }
      }
    })
  });
});