define('frontend/pods/provider/patients/show/flags/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    preload: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model() {
      var patient = this.modelFor('provider.patients.show');
      return Ember.RSVP.hash({
        patient: patient,
        patientFlags: patient.get('patientFlags'),
        flags: this.get('preload.flags')
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.flags" });
      }
    }
  });
});