define('frontend/pods/patient/user/confirm-identity/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/mixins/patient-route-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _patientRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, _patientRouteMixin.default, {
    sessionUser: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model() {
      return this.get("sessionUser.currentUser");
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "patient.confirmIdentity" });
      }
    }
  });
});