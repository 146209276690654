define("frontend/mixins/survey-metric", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    metricContext: Ember.computed("response", "response.invite", "response.invite.assessmentInvitationId", function () {
      return {
        responseId: this.get("response.id"),
        inviteId: this.get("response.invite.id"),
        assessmentInvitationId: this.get("response.invite.assessmentInvitationId")
      };
    }),

    trackSurveyMetric: function trackSurveyMetric(params) {
      if (!this.get("previewMode")) {
        this.get("metrics").trackEvent(Ember.Object.create(params, this.get("metricContext")));
      }
    }
  });
});