define('frontend/models/experiment-variant', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    enabled: _emberData.default.attr("boolean"),
    weight: _emberData.default.attr("number"),

    experiment: _emberData.default.belongsTo('experiment'),
    experimentDecisions: _emberData.default.hasMany('experimentDecision')
  });
});