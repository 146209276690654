define('frontend/pods/internal/scales/show/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // You can set data on the controller here to start with an imported survey.
    institute: Ember.inject.service()
  });
});