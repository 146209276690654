define('frontend/services/preload', ['exports', 'frontend/models/entity'], function (exports, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    preloadRequest: Ember.computed(function () {
      return this.get('store').query('preload', { type: 'default' });
    }),
    preloadProvidersRequest: Ember.computed(function () {
      return this.get('store').query('preload', { type: 'providers' });
    }),
    preloadData: Ember.computed.alias('preloadRequest.firstObject'),
    questions: Ember.computed(function () {
      return this.get("store").query('assessment/question', { page: { limit: 500 } });
    }),
    clinicians: Ember.computed(function () {
      return this.get("preloadProvidersRequest");
    }),
    patientSessionTypes: Ember.computed.alias('preloadData.patientSessionTypes'),
    defaultPatientSessionType: Ember.computed('patientSessionTypes.[]', function () {
      return this.get("patientSessionTypes").findBy('mnemonic', 'treatment');
    }),
    scales: Ember.computed.alias('preloadData.scales'),
    scaleMap: Ember.computed('scales.[]', function () {
      return this.getWithDefault("scales", []).reduce(function (result, s) {
        result[s.get('mnemonic')] = s;return result;
      }, Ember.Object.create({}));
    }),
    independentScales: Ember.computed('scales.[]', function () {
      // Can't use a computed property because it's not listened to from the route. But these collections
      // are supposed to be static so it should work fine anyway.
      return this.get("scales").filterBy("independent", true);
    }),
    populations: Ember.computed.alias('preloadData.populations'),
    norms: Ember.computed.alias('preloadData.norms'),
    providers: Ember.computed('preloadProvidersRequest', function () {
      return this.get("preloadProvidersRequest");
    }),
    providerEntities: Ember.computed('providers.[]', function () {
      return this.get('providers').map(function (provider) {
        return provider.toEntity();
      });
    }),
    providerRoles: Ember.computed.alias('preloadData.providerRoles'),
    organizations: Ember.computed(function () {
      return this.get('store').query('organization', { include: 'subtree', page: { limit: 300 }, sort: "name" });
    }),
    enabledPayors: Ember.computed.alias('preloadData.enabledPayors'),
    organizationEntities: Ember.computed('organizations.[]', function () {
      return this.getWithDefault('organizations', []).map(function (org) {
        return org.toEntity();
      });
    }),
    treatmentServiceEntities: Ember.computed(function () {
      return this.get('store').query('entity', { filter: { entityType: 'treatment-service' }, sort: "name", page: { limit: 300 } });
    }),
    treatmentTrackEntities: Ember.computed(function () {
      return this.get('store').query('entity', { filter: { entityType: 'treatment-track' }, sort: "name", page: { limit: 300 } });
    }),
    treatmentTracks: Ember.computed(function () {
      return this.get('store').query('care/treatmentTrack', { page: { limit: 300 }, sort: "name" });
    }),
    panelEntities: Ember.computed(function () {
      if (!this.get("features.enableTeamsAndPanels")) {
        return [];
      }

      return this.get('store').query('entity', { filter: { entityType: 'panel' }, sort: "name", page: { limit: 300 } });
    }),
    // Note that we do it this way rather than going via entity directly because we only want the active ones,
    // and we don't currently have the concept of 'active' for an entity.
    activeTreatmentTrackEntities: Ember.computed('treatmentTracks.[]', function () {
      var _this = this;

      return this.get("treatmentTracks").filterBy('status', 'active').map(function (track) {
        return (0, _entity.fromModel)(track, _this.get("store"));
      });
    }),
    flags: Ember.computed.alias('preloadData.flags'),
    instituteConditions: Ember.computed.alias('preloadData.instituteConditions'),

    careUnitTypes: Ember.computed.alias('preloadData.careUnitTypes'),

    raceMap: Ember.computed.alias('preloadData.raceMap'),
    ensurePreloads: function ensurePreloads() {
      var _this2 = this;

      if (!this.get('session.isAuthenticated')) {
        console.warn("Preloading data before authentication, data will not be loaded");
        return null;
      }

      var request = this.get("preloadRequest");

      request.then(function () {
        _this2.get("questions"); // Trigger preload, but don't wait on it.
        _this2.get("clinicians");
        _this2.get("organizations");

        if (_this2.get("features.enableConditions")) {
          _this2.get("conditions");
        }
      });

      return request;
    },
    ensure: function ensure() {
      return this.ensurePreloads();
    }
  });
});