define('frontend/pods/components/scale-creation/question-display-data/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    configObject: Ember.computed('model', 'property', {
      get: function get() {
        return Ember.get(this.get("model"), this.get("property"));
      },
      set: function set(_key, value) {
        return Ember.set(this.get("model"), this.get("property"), value);
      }
    }),
    isAutomatic: Ember.computed.alias('configObject.automatic')
  });
});