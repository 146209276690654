define('frontend/authenticators/sudo', ['exports', 'ember-simple-auth/authenticators/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    tokenAttributeName: 'authentication_token',
    identificationAttributeName: 'username',
    serverTokenEndpoint: "/users/sudo",
    session: Ember.inject.service(),

    authenticate: function authenticate(userId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var useResponse = _this.get('rejectWithResponse');
        var data = {
          'user_id': userId
        };

        var currentAuthData = _this.get("session.data.authenticated");
        var authHeader = 'authentication_token="' + currentAuthData.authentication_token + '", user_type="' + currentAuthData['user_type'] + '", id="' + currentAuthData['id'] + '"';

        var headers = {
          'accept': "application/json",
          'content-type': "application/json",
          'Authorization': 'Token ' + authHeader
        };

        return _this.makeRequest(data, { headers: headers }).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var resourceName = _this.get('resourceName');
                var _json = json[resourceName] ? json[resourceName] : json;
                Ember.run(null, resolve, _json);
              } else {
                Ember.run(null, reject, 'Bad response from server');
              }
            });
          } else {
            if (useResponse) {
              Ember.run(null, reject, response);
            } else {
              response.json().then(function (json) {
                return Ember.run(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return Ember.run(null, reject, error);
        });
      });
    },


    _validate: function _validate(data) {
      var tokenAttributeName = this.get('tokenAttributeName');
      // compared to the original we don't bother with the id attribute name
      var resourceName = this.get('resourceName');
      var _data = data[resourceName] ? data[resourceName] : data;

      return !Ember.isEmpty(_data[tokenAttributeName]);
    }
  });
});