define('frontend/helpers/ordinal-suffix', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      return _analytics.default.ordinalSuffix(params[0]);
    }
  });
});