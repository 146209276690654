define("frontend/locales/customers/test", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    en: {
      "common.patient": "Client"
    }
  };
});