define('frontend/pods/provider/patients/route', ['exports', 'frontend/mixins/provider-route-mixin', 'frontend/pods/base-route'], function (exports, _providerRouteMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel() {
      if (!this._super.apply(this, arguments)) {
        return null;
      }

      return Ember.RSVP.hash({
        preloads: this.get("preload").ensurePreloads('clinicians'),
        sessionUser: this.get('sessionUser.currentUser')
      });
    }
  });
});