define('frontend/models/application', ['exports', 'ember-data', 'ember-data-has-many-query', 'ember-data-autoreload', 'ember-data-storefront/mixins/loadable-model'], function (exports, _emberData, _emberDataHasManyQuery, _emberDataAutoreload, _loadableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_loadableModel.default, _emberDataHasManyQuery.default.ModelMixin, _emberDataAutoreload.default, {
    /*
     * Ember does not like you to 'unload' relationships unless you're planning on renewing them right away. This causes
     * problems when e.g. things change server side but you don't want to actually reload the relationships themselves
     * yet. So by doing this magic trick, we just completely destroy the relationship and it'll build itself up again
     * next time it's requested. Not guaranteed to be stable across version upgrades.
     */
    forceUnloadHasManyRelationship: function forceUnloadHasManyRelationship(name) {
      this.hasMany(name).hasManyRelationship.destroy();
      this.hasMany(name).hasManyRelationship.__loadingPromise = null;
      this.hasMany(name).hasManyRelationship.inverseDidDematerialize();
      this.hasMany(name).hasManyRelationship.linkPromise = null;
    }
  });
});