define('frontend/pods/components/provider-admin/configuration/edit-form/component', ['exports', 'frontend/mixins/error-printer'], function (exports, _errorPrinter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorPrinter.default, {
    classNames: 'configuration-edit-form',
    messages: Ember.inject.service(),

    integerArrayOptions: Ember.computed.oneWay("model.value"),
    stringArrayOptions: Ember.computed.oneWay("model.value"),

    actions: {
      cancelChange: function cancelChange() {
        this.get("onCancel")();
      },
      saveConfig: function saveConfig() {
        var _this = this;

        var onSuccess = function onSuccess(result) {
          _this.get("metrics").trackEvent({ event: "provider.configuration.saved" });
          if (_this.get("onSave")) {
            _this.get("onSave")(result);
          }
        };

        var onFailure = function onFailure(reason) {
          _this.get('messages').danger(_this.printError(reason));
        };

        var model = this.get("model");
        if (model && model.content) {
          model = model.content;
        }

        return model.save().then(onSuccess, onFailure);
      },
      updateIntegerArray: function updateIntegerArray(_ignored, value) {
        var transformed = value.map(function (val) {
          return parseInt(val);
        });
        this.set("model.value", transformed);
      },
      updateStringArray: function updateStringArray(_ignored, value) {
        this.set("model.value", value);
      },
      selectSearch: function selectSearch(value) {
        return [value];
      }
    }
  });
});