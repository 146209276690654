define('frontend/models/assessment/question-option', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    value: _emberData.default.attr('string'),
    normalizedScore: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    reportText: _emberData.default.attr('string'),
    question: _emberData.default.belongsTo('assessment/question', { async: true })
  });
});