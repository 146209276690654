define("frontend/models/user-password", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo("user"),
    password: _emberData.default.attr("string"),
    passwordConfirmation: _emberData.default.attr("string"),
    passwordExpiredAt: _emberData.default.attr("date")
  });
});