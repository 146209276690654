define('frontend/pods/components/related-person/details-login-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    participantFirstName: (0, _emberCpValidations.validator)('presence', true),
    patientLastName: (0, _emberCpValidations.validator)('presence', true),
    patientDobString: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
        before: 'now',
        precision: 'day',
        format: 'MM/DD/YYYY',
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    patientLastSsn: {
      validators: [(0, _emberCpValidations.validator)('length', {
        is: 4,
        allowBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: true,
        integer: true
      })]
    }
  });

  /**
   * expects: invitationId
   */
  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    messages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var relatedPersonDetails = Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        participantFirstName: null,
        patientDob: null,
        patientLastSsn: null,
        patientLastName: null,
        patientDobString: Ember.computed('patientDob', {
          get: function get() {
            if (this.get('patientDob')) {
              return moment(this.get('patientDob')).format("MM/DD/YYYY");
            } else {
              return null;
            }
          },
          set: function set(key, value) {
            if (value) {
              this.set("patientDob", moment(value, "MM/DD/YYYY").toDate());
              return value;
            }
          }
        }),
        patientDobIsoString: Ember.computed('patientDob', function () {
          var dob = this.get('patientDob');

          if (dob) {
            return moment(dob).format('YYYY-MM-DD');
          } else {
            return null;
          }
        })
      }).create(Ember.getOwner(this).ownerInjection());

      this.set("relatedPersonDetails", relatedPersonDetails);
    },

    isInvalid: Ember.computed.alias("relatedPersonDetails.validations.isInvalid"),
    showLastSsn: Ember.computed('institute.current.patientAuthenticationMethod', function () {
      var authenticationMethod = this.get('institute.current.patientAuthenticationMethod');
      return authenticationMethod == "full" || authenticationMethod == "mrn_ssn";
    }),
    actions: {
      submit: function submit() {
        var _this = this;

        var intl = this.get('intl');

        var _get$getProperties = this.get("relatedPersonDetails").getProperties("participantFirstName", "patientLastName", "patientDobIsoString", "patientLastSsn"),
            participantFirstName = _get$getProperties.participantFirstName,
            patientLastName = _get$getProperties.patientLastName,
            patientLastSsn = _get$getProperties.patientLastSsn,
            patientDobIsoString = _get$getProperties.patientDobIsoString;

        var trackLoginEvent = function trackLoginEvent(event) {
          return _this.get('metrics').trackEvent({
            event: 'relatedPerson.kiosk.' + event,
            participantFirstName: participantFirstName, patientLastName: patientLastName, patientLastSsn: patientLastSsn,
            patientDob: patientDobIsoString
          });
        };

        trackLoginEvent('signInAttempt');

        var onSuccess = function onSuccess() {
          trackLoginEvent('signInSucceeded');
        };

        var onFailure = function onFailure(reason) {
          if (reason && reason.error === 'Ambiguous login') {
            _this.get('messages').danger(intl.t('patient.kiosk.ambiguousLogin'));
            trackLoginEvent('ambiguousLogin');
          } else {
            _this.get('messages').danger(intl.t('patient.kiosk.failed'));
            trackLoginEvent('signInFailed');
          }
        };

        var params = {
          institute_id: this.get("institute.current.id"),
          participant_first_name: participantFirstName,
          patient_last_name: patientLastName,
          patient_last_ssn: patientLastSsn,
          patient_dob: patientDobIsoString
        };

        this.get('messages').clear();

        return this.get('session').authenticate('authenticator:devise-related-person', params).then(onSuccess, onFailure);
      }
    }
  });
});