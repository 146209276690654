define('frontend/pods/provider/patients/show/sessions/create/route', ['exports', 'frontend/pods/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    // The current provider isn't guaranteed to be loaded but we need it to work out the organization structure.
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get("sessionUser.currentUser");
    },
    model: function model() {
      var _this = this;

      var activeProvider = this.get("sessionUser").get("activeProvider");
      var defaultSessionType = this.get('preload.defaultPatientSessionType');
      var assessments = this.store.findAll("assessment/assessment");
      var patient = this.modelFor("provider.patients.show");
      var autoplan = this.get('features.enableMeasurementPlans');
      var allProviders = this.get("preload.clinicians");

      // If you query patient.patientSessions, it will reload the relationship and get confused with the new record created, so query directly on the session root.
      var activePatientSessions = this.store.query("patientSession", { filter: { patientId: patient.get('id'), preset: "with_potentially_active" }, include: "assessment-report" }).then(function (result) {
        return result ? result.toArray() : [];
      });

      var provider = null;
      if (Ember.get(activeProvider, 'practicing')) {
        provider = activeProvider;
      } else if (patient.get('provider')) {
        provider = patient.get('provider');
      } else {
        provider = allProviders.get('firstObject');
      }

      var session = this.store.createRecord('patientSession', { patient: patient, provider: provider, autoplan: autoplan, patientSessionType: defaultSessionType });
      var scales = this.get("preload.scales").then(function () {
        return _this.get("preload.independentScales");
      });
      var participants = patient.query("clientParticipants", { include: "user" });
      var activeCareEpisodes = this.store.query("careEpisode", { filter: { patient_id: patient.get('id'), status: "active" }, include: "participants,care-episode-providers" });
      var providerOrganizations = this.get("sessionUser.activeOrganizations");

      var defaultScales = patient.get("defaultScales");

      var report = this.store.createRecord('assessment/report', { patient: patient, patientSession: session });
      this.store.createRecord('scheduling/appointment', { patient: patient, patientSession: session, source: 'manual' });

      return Ember.RSVP.hash({
        patient: patient,
        session: session,
        report: report,
        assessments: assessments,
        scales: scales,
        defaultScales: defaultScales,
        activeCareEpisodes: activeCareEpisodes,
        activePatientSessions: activePatientSessions,
        providerOrganizations: providerOrganizations,
        allProviders: allProviders,
        activeProvider: activeProvider,
        participants: participants
      }).then(function (hash) {
        hash.session.autopopulateCareEpisode(hash.activeCareEpisodes, hash.providerOrganizations, hash.activeProvider);
        if (!autoplan) {
          hash.report.autopopulateScales();
        }
        return hash;
      });
    }
  });
});