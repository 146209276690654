define('frontend/models/metric-datum', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    shortcode: _emberData.default.attr("string"),

    // ember-data date attributes only work with JS Date objects, but we're using Moment to create
    // these. Until we add a moment-date transformer we have to stick with the String type.
    startDate: _emberData.default.attr("string"),
    endDate: _emberData.default.attr("string"),

    group: _emberData.default.attr("string"),
    patient: _emberData.default.belongsTo('patient', { async: true }),
    providerId: _emberData.default.attr("string"),
    organizationId: _emberData.default.attr("string"),
    flagIds: _emberData.default.attr(),
    params: _emberData.default.attr("object"),
    results: _emberData.default.attr("object"),
    interval: _emberData.default.attr("string"),
    preset: _emberData.default.attr("string"),
    mode: _emberData.default.attr("string"),
    summation: _emberData.default.attr("string"),
    atLeastOnceBy: _emberData.default.attr("string"),
    denominator: _emberData.default.attr("string"),
    denominatorSummation: _emberData.default.attr("string"),
    deduplication: _emberData.default.attr("string")
  });
});