define("frontend/locales/es/common", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    exit: "Salir",
    lastSsn: "Últimos 4 dígitos ssn",
    username: "Nombre usario",
    password: "Contraseňa",
    yes: "Si",
    no: "No",
    for: 'para',
    selected: "Actualmente Seleccionado",
    privacyPolicy: "Política de Privacidad",
    about: 'sobre',
    month: 'Mes',
    day: 'Día',
    dayShort: 'DD',
    year: 'Año',
    yearShort: 'YYYY',
    monthsShort: {
      "01": "en.",
      "02": "feb.",
      "03": "mar.",
      "04": "abr.",
      "05": "may.",
      "06": "jun.",
      "07": "jul.",
      "08": "agt.",
      "09": "set.",
      "10": "oct.",
      "11": "nov.",
      "12": "dic."
    },
    dateOfBirth: "Fecha de nacimiento",
    newWindow: "Se abre en una nueva ventana"
  };
});