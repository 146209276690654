define('frontend/pods/provider/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    screen: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    compressedClass: Ember.computed('screen.isCompressed', function () {
      return this.get("screen.isCompressed") ? 'compressed' : null;
    }),
    // Don't show the nav if we're embedded
    showNav: Ember.computed(function () {
      return window.location === window.parent.location;
    })
  });
});