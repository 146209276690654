define('frontend/pods/components/common/controls/simple-expand-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //set this to true if you want separate open/close content.  you will then use {{#if (eq section 'open')}} and {{#if (eq section 'open')}} to put the open/close content in the body
    allowOpenAndClosedContent: false,
    isOpen: false,
    actions: {
      toggle: function toggle() {
        this.set('isOpen', !this.get('isOpen'));
      }
    }
  });
});