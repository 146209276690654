define('frontend/pods/components/provider/experiments/patient-decision-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        propertyName: 'experiment.name',
        title: intl.t('model.experimentDecision.experimentName')
      }, {
        propertyName: 'experimentVariant.name',
        title: intl.t('model.experimentDecision.experimentVariantName')
      }, {
        propertyName: "enabled",
        title: intl.t('model.experimentDecision.enabled'),
        template: "components/common/server-side-table/tick-mark",
        disableSorting: true
      }];

      this.set("tableColumns", tableColumns);
      this.set("pageSize", 10);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        var patient = this.get("patient");

        if (patient.content) {
          patient = patient.content;
        }

        if (patient && typeof patient.query === 'function') {
          var data = Ember.Object.create({
            store: patient,
            modelName: 'experimentDecisions',
            query: { include: 'experiment,experiment-variant' }
          });
          this.set("data", data);
        }
      }
    }
  });
});