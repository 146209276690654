define("frontend/locales/es/patient", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    measure: "medida",
    welcome: {
      title: "¡Bienvenido(a)!",
      description: "Aquí puede ayudarnos a guiar su psicoterapia.",
      backToLanding: "Atrás hacia la página de inicio",
      start: "Comenzar",
      areYouAClient: "Vamos a comenzar.",
      iAmAClient: "Soy un(a) cliente",
      iAmARelatedPerson: "Soy el padre/guardian de el/la cliente"
    },
    kiosk: {
      failed: "No ha sido posible verificar sus detalles personales. Por favor vuelva a intentarlo, si el problema continua, contacte a su terapeuta."
    },
    invitation: {
      title: "Bienvenidos a Mirah",
      description: "Ingrese su información",
      submit: "Continúe",
      failed: "No ha sido posible verificar sus detalles personales. Por favor vuelva a intentarlo, si el problema continúa, contacte a su terapeuta.",
      exit: {
        redirect: "Gracias. Comencemos el instrumento de medida ahora..."
      },
      alreadyComplete: "Este instrumento de medida a sido completado. Por favor cierre la ventana del navegador.",
      "return.complete": "Ha terminado! Gracias por su tiempo. Por favor cierre la ventana del navegador ahora.",
      "return.exit": "Ha terminado la encuesta. Por favor cierre la ventana del navegador ahora.",
      "return.timeout": "Cierre de sesion por inactividad."
    },
    confirmDetails: {
      title: "Confirme sus detalles",
      description: "Dado que es su primera vez",
      confirm: "Si, correcto",
      needEdit: "No, es incorrecto",
      pleaseConfirm: "Por favor ingrese los detalles correctos"
    },
    upToDate: {
      description: "Todo esta al dia. No tiene ningún instrumento de medida que completar en estos momentos. Buen trabajo!",
      logout: "Cerrar sesión"
    },
    forgottenPassword: {
      title: "Olvido su contraseña?",
      description: "Si tiene un correo electrónico vinculado con su cuenta y se le ha olvidado su contraseña, puede pedir restablecer la contraseña.  Se le mandara un correo electronico a su cuenta con las instrucciones para recuperar su contraseña.",
      noEmail: "Si no tiene correo electrónico y se le a olvidado la contraseña, por favor contacte a un miembro del equipo para que lo asista.",
      sendResetEmail: "Mandar correo electronico para recuperar contraseña.",
      emailSent: "Si tiene un correo electrónico vinculado a su cuenta, se le a enviado un correo.",
      backToLogin: "De vuelta a iniciar sesión"
    },
    login: {
      pleaseSignIn: "Por favor registre/ingrese su nombre",
      description: "Seleccione su método de ingreso",
      username: "Ingresar con mi nombre de usuario.",
      noUsername: "Ingresar sin nombre de usuario",
      forgottenPassword: "¿Olvidaste tu contraseña?",
      otherMechanisms: "Otras formas de iniciar sesión",
      submit: "Ingresar"
    },
    logout: "Ha sido desconectado de su sesión"
  };
});