define('frontend/models/assessment/norm', ['exports', 'ember-data', 'frontend/models/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    population: _emberData.default.belongsTo('assessment/population', { async: true }),
    scale: _emberData.default.belongsTo('assessment/scale', { async: true }),

    domain: _emberData.default.attr("string"),
    mean: _emberData.default.attr("number"),
    stddev: _emberData.default.attr("number")
  });
});