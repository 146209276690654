define('frontend/pods/components/reporting/report-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showLimitControls: false,
    // merging of the column views with the data columns
    viewColumns: Ember.computed('report.headers.[]', 'reportDefinition', function () {
      var viewColumnProperties = this.getWithDefault('reportDefinition.columnProperties', {});
      var backendColumns = this.getWithDefault('report.headers', []).map(function (h) {
        return Ember.assign(h, viewColumnProperties[h.translationKey]);
      }).rejectBy('shown', false);
      return backendColumns.concat(this.getWithDefault('reportDefinition.addedViewColumns', []));
    }),

    hasFeatureColumns: Ember.computed('viewColumns.[]', function () {
      return this.get('viewColumns').isAny('featured', true);
    }),

    shownColumns: Ember.computed('viewColumns.[]', function () {
      var cols = this.get('viewColumns');
      if (this.get('hasFeatureColumns')) {
        // return only featured columns
        return this.get('viewColumns').filterBy('featured', true);
      }
      // otherwise show everything
      return cols;
    }),

    hiddenColumns: Ember.computed('viewColumns.[]', function () {
      if (this.get('hasFeatureColumns')) {
        //return non-featured columns, note that undefined is non-featured
        return this.get('viewColumns').rejectBy('featured', true);
      }
      // otherwise nothing
      return [];
    }),

    data: Ember.computed('report.rowData', function () {
      return this.get('report.rowData');
    }),
    footer: Ember.computed('report.footerData', function () {
      return !this.get('reportDefinition.hideFooter') ? this.get('report.footerData') : null;
    }),

    sortOn: undefined,
    sortDirection: undefined,
    isSorting: false,

    actions: {
      sortBy: function sortBy(column) {
        this.onSort(column);
      }
    },

    onSort: function onSort() {
      // Do nothing by default
    }
  });
});