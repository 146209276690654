define('frontend/pods/provider/patients/show/report-v2/route', ['exports', 'frontend/mixins/metrics-mixin', 'frontend/pods/base-route'], function (exports, _metricsMixin, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_metricsMixin.default, {
    breadCrumb: { partial: 'provider/assessment/report-v2/longitudinal-report/bread-crumb', model: {} },
    queryParams: {
      activeReportId: {},
      activeSessionId: {}
    },
    metrics: Ember.inject.service(),
    preload: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get("preload").ensurePreloads();
    },
    model: function model(params) {
      var patient = this.modelFor('provider.patients.show');

      var existingBundle = this.store.peekRecord('assessment/report-bundle', patient.get("id"));

      if (existingBundle) {
        // Make sure that if we're coming back from another page, we have an up to date copy of all data.
        existingBundle.unloadRecord();
      }

      var bundle = this.store.find('assessment/report-bundle', patient.get("id"));

      var activeReport = null;

      activeReport = bundle.then(function (promise) {
        if (params.activeReportId) {
          return promise.get("reports").find(function (r) {
            return r.get("id") === params.activeReportId;
          });
        } else if (params.activeSessionId) {
          return promise.get("reports").find(function (r) {
            return r.get("patientSession.id") === params.activeSessionId;
          });
        } else {
          return promise.get("reports").objectAt(0);
        }
      });

      var patientScaleTargets = patient.get("assessmentPatientScaleTargets");
      var conditions = this.get("features.enableConditions") ? this.get('preload.conditions') : [];

      return Ember.RSVP.hash({
        patient: patient,
        bundle: bundle,
        activeReport: activeReport,
        flags: this.get('preload.flags'),
        conditions: conditions,
        populations: this.store.findAll("assessment/population"),
        patientScaleTargets: patientScaleTargets,
        scales: this.get("preload.scales")
      });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb.model', model);

      if (model.activeReport) {
        this.set("metrics.context.latestPatientSessionId", model.bundle.get('reports.firstObject.patientSession.id'));
        this.set("metrics.context.patientSessionId", model.activeReport.get('patientSession.id'));
        this.set("metrics.context.reportId", model.activeReport.get('id'));
      }
    },

    actions: {
      didTransition: function didTransition() {
        this.trackMetric({ event: "provider.patients.show.longitudinal", patientId: this.currentModel.patient.get("id") });
        var latestReportId = this.currentModel.bundle.get("reports.firstObject.id");
        var activeReportId = this.currentModel.activeReport ? this.currentModel.activeReport.get("id") : null;
        var isPrimaryClinician = this.currentModel.patient.get("doctor.id") === this.get("sessionUser.currentUser.id");
        this.trackMetric({ event: "provider.assessment.reportView", patientId: this.currentModel.patient.get("id"), data: { latestReportId: latestReportId, activeReportId: activeReportId, isPrimaryClinician: isPrimaryClinician } });
      },
      willTransition: function willTransition() {
        var context = this.get("metrics.context");
        delete context.latestPatientSessionId;
        delete context.patientSessionId;
        delete context.reportId;
      }
    }
  });
});