define('frontend/pods/components/provider/assessment/report-v2/badges/therapy-preference-badge/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    messageKey: 'therapyPrefs',

    isRiskPresent: Ember.computed('riskValue', function () {
      return !!this.get('riskValue') && this.get('riskValue.value') !== 0;
    }),

    isBadgeShown: Ember.computed('riskValue', function () {
      return !!this.get('riskValue');
    }),

    isRisk: Ember.computed(function () {
      return 'minimal-risk';
    }),

    riskValue: Ember.computed('activeReport', function () {
      var computedValuesByScale = this.get('reportSettings.selectedReportValuesByScaleBaseMnemonic');

      var results = computedValuesByScale['expressedTherapyNeeds'];
      if (results && results[0] && results[0].get('value') !== null) {
        return results[0];
      }
    })
  });
});