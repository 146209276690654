define('frontend/pods/components/survey/taking-local/page-components/consent-form/component', ['exports', 'frontend/mixins/survey/scrollable', 'frontend/mixins/survey/question-component-mixin'], function (exports, _scrollable, _questionComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_questionComponentMixin.default, _scrollable.default, {
    tagName: 'div',
    classNames: ['question-wrapper', 'full-width'],
    scrollableSelector: '.question-content',

    consentData: Ember.computed.alias('page.properties.data'),

    showPreamble: Ember.computed('consentData.preamble_text', function () {
      return !!this.get("consentData.preamble_text");
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      // Override the behavior in question mixin.
    },


    actions: {
      agree: function agree() {
        return this._submitAnswer('accepted');
      },
      acceptPreamble: function acceptPreamble() {
        this.set('showPreamble', false);
      },
      showInfo: function showInfo() {
        this.set('showInfo', true);
      },
      decline: function decline() {
        return this._submitAnswer('declined');
      },
      defer: function defer() {
        return this._submitAnswer('deferred');
      }
    },
    _submitAnswer: function _submitAnswer(value) {
      var _this = this;

      var userId = this.get('page.properties.data.user_id');
      var consentAgreementId = this.get('page.properties.data.agreement_id');

      var record = this.store.createRecord('userConsent', {
        userId: userId,
        consentAgreementId: consentAgreementId,
        consentType: 'general',
        status: value
      });

      return record.save().then(function () {
        _this.sendAction('next');
      });
    }
  });
});