define('frontend/pods/components/provider/plan/edit-measurement-plan-form/component', ['exports', 'frontend/mixins/drawer-enabled', 'ember-cli-uuid/utils/uuid-helpers', 'frontend/mixins/rollback-mixin'], function (exports, _drawerEnabled, _uuidHelpers, _rollbackMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, _rollbackMixin.default, {
    rollbackProperties: ['measurementPlan.measurementPlanScales', 'measurementPlan'],

    showAdvanced: Ember.computed('measurementPlan.name', 'measurementPlan.automaticName', 'measurementPlan.shared', function () {
      return this.get("name") !== this.get("automaticName") || this.get("shared");
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get("measurementPlan.initialOpenScale")) {
        Ember.run.later(function () {
          _this._showBrowser(_this.get("measurementPlan.initialOpenScale"));
        });
      }
    },


    activeMeasurementPlanScales: Ember.computed.filterBy('measurementPlan.measurementPlanScales', 'isDeleted', false),
    _selectScale: function _selectScale(scale) {
      this.set("activeMeasurementPlanScale.scale", scale);
      this.setProperties({
        showScaleBrowser: false
      });
      this.actions.drawer.call(this);
    },
    _showBrowser: function _showBrowser(measurementPlanScale) {
      this.setProperties({
        activeMeasurementPlanScale: measurementPlanScale,
        showScaleBrowser: true
      });
      this.send('drawer', '#scaleChooserSelectorDrawer');
      Ember.run.later(function () {
        $('.globalSearch input').focus();
      });
    },


    actions: {
      addScale: function addScale() {
        var measurementPlan = this.get('measurementPlan');
        var newScale = this.get('store').createRecord('care/measurementPlanScale', {
          measurementPlan: measurementPlan,
          schedulePreset: 'everySession'
        });
        measurementPlan.get("measurementPlanScales").addObject(newScale);
        this.actions.showScaleBrowser.call(this, newScale);
      },
      addAlternatingScale: function addAlternatingScale() {
        var measurementPlan = this.get('measurementPlan');
        var newScale = this.get('store').createRecord('care/measurementPlanScale', {
          measurementPlan: measurementPlan,
          schedulePreset: 'everySession',
          alternationPriority: 1,
          alternationGroup: (0, _uuidHelpers.default)()
        });
        measurementPlan.get("measurementPlanScales").addObject(newScale);
        this.actions.showScaleBrowser.call(this, newScale);
      },
      scaleDetailSelected: function scaleDetailSelected(scale) {
        this._selectScale(scale);
      },
      onScaleChange: function onScaleChange(scale) {
        this._selectScale(scale);
      },
      onMeasurementPlanScaleDelete: function onMeasurementPlanScaleDelete(deleted) {
        if (this.get('activeMeasurementPlanScale') === deleted) {
          this.set('activeMeasurementPlanScale', null);
        }
      },
      onAlternatingScaleDelete: function onAlternatingScaleDelete(deleted) {
        if (deleted.includes(this.get('activeMeasurementPlanScale'))) {
          this.set('activeMeasurementPlanScale', null);
        }
      },
      showScaleBrowser: function showScaleBrowser(measurementPlanScale) {
        this._showBrowser(measurementPlanScale);
      },
      hideScaleBrowser: function hideScaleBrowser() {
        this.setProperties({
          showScaleBrowser: false
        });
      }
    }
  });
});