define('frontend/utils/assessment/peabody', ['exports', 'frontend/utils/assessment/analytics'], function (exports, _analytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var peabody = {
    GRAPHED_SYMPTOM_MNEMONICS: ['sfssI', 'sfssI_C', 'sfssE', 'sfssE_C', 'cgsq'],
    GRAPHED_SKILL_MNEMONICS: ['taqs', 'ptpbChs', 'bmslss', 'swls', 'sss', 'sss_C', 'myts', 'myts_C', 'ycis', 'toes', 'toes_C'],
    // note that this threshold is expressed in PERCENTILES, not stddevs.
    SIGNIFICANT_CHANGE_THRESHOLD: 10,

    isSignificantChange: function isSignificantChange(computedValue, compareValue) {
      var diff = _analytics.default.GetZPercent(computedValue.get('value')) - _analytics.default.GetZPercent(compareValue.get('value'));
      return Math.abs(diff) > peabody.SIGNIFICANT_CHANGE_THRESHOLD;
    }
  };

  exports.default = peabody;
});