define('frontend/pods/components/common/compressable-server-side-table/component', ['exports', 'frontend/pods/components/common/server-side-table/component', 'frontend/pods/components/common/server-side-table/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    screen: Ember.inject.service(),
    columns: Ember.computed('screen.isCompressed', 'fullColumns', 'compressedColumns', function () {
      return this.get("screen.isCompressed") ? this.get("compressedColumns") : this.get("fullColumns");
    }),

    columnsChangedObserver: Ember.observer('columns.[]', function () {
      this._setupColumns();
    })
  });
});