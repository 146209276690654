define("frontend/services/page-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.reset();
    },
    reset: function reset() {
      this.set("title", null);
      this.set("description", null);
      this.set("visible", false);
      this.set("className", null);
    }
  });
});