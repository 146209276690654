define("frontend/pods/provider/assessment/invitation/wrong-provider/route", ["exports", "frontend/pods/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function model() {
      return this.modelFor("provider.assessment.invitation");
    }
  });
});