define('frontend/validators/required-patient-field', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RequiredPatientField = _base.default.extend({
    institute: Ember.inject.service(),
    validate: function validate(value, options, model, attribute) {

      if (this.getWithDefault("institute.current.requiredPatientFields", Ember.A()).includes(attribute) && !value) {
        return this.createErrorMessage('blank', value, options);
      }

      return true;
    }
  });

  RequiredPatientField.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = RequiredPatientField;
});