define("frontend/pods/patient/assessment/invitation/begin/route", ["exports", "frontend/pods/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    queryParams: {
      referrer: {}
    },
    redirect: function redirect(_, transition) {
      var invitation = this.modelFor("patient.assessment.invitation");

      if (transition.queryParams.referrer) {
        sessionStorage.setItem("referrer", transition.queryParams.referrer);
      }

      if (["complete", "partial"].includes(invitation.get("status"))) {
        this.transitionTo('patient.assessment.invitation.complete');
      } else if (["expired", "canceled", "locked_out"].includes(invitation.get("status"))) {
        this.transitionTo("patient.assessment.invitation.expired");
      } else {
        this.transitionTo('patient.assessment.invitation.exit', { queryParams: transition.queryParams });
      }
    }
  });
});