define('frontend/pods/components/provider/assessment/report-v2/symptoms-graph/component', ['exports', 'lodash', 'frontend/mixins/drawer-enabled', 'frontend/models/assessment/color-settings'], function (exports, _lodash, _drawerEnabled, _colorSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    DEFAULT_ADULT_SCALES: ['alliance', 'resilience'],
    DEFAULT_CHILD_SCALES: ['taqs', 'sfssI', 'sfssE'],

    preload: Ember.inject.service(),
    screen: Ember.inject.service(),
    activeScales: null,
    changedScalesShow: false,
    targetedScalesShow: false,
    domains: ['normed'],
    intl: Ember.inject.service(),
    selectedScale: null,
    showYAxis: true,
    showScaleList: false, //show a static list of the shown scales
    selectedReport: null, // Selected report !== activeReport, because you can choose a data point back in history.

    fixedColors: true,
    forceDisplayMode: false,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      //we initialize active scales like this because we need active scales to be instanced separately for each component instance
      if (this.get('activeScales') === null) {
        this.set('activeScales', []);
      }
      var activeScales = this.get('activeScales');

      //if there's fewer than 7 lines, start by showing them all
      if (!activeScales.length && this.get('allScales.length') <= 6) {
        activeScales.pushObjects(this.get('allScales'));
      } else if (!activeScales.length) {
        // Find the first set of scales with more than one scale and set those as the active scales
        //otherwise show alliance
        ['targetedScales', 'changedScales'].find(function (scaleType) {
          if (_this.getWithDefault(scaleType, []).length > 0) {
            activeScales.pushObjects(_this.get(scaleType));
            _this.set(scaleType + 'Show', true);
            return true;
          }
        });
        if (!activeScales.length) {
          var initialScales = this.get('displayMode') === 'ptpb' ? this.get('DEFAULT_CHILD_SCALES') : this.get('DEFAULT_ADULT_SCALES');

          activeScales.pushObjects(this.get('allScales').filter(function (scale) {
            return initialScales.indexOf(scale.get('mnemonic')) >= 0;
          }));
        }
      }
    },
    didRender: function didRender() {
      var view = this;
      this._super.apply(this, arguments);
      if (this.get('showScaleSelectors')) {
        var colorSettings = this.get("colorSettings");

        // Selectize will trigger on init as well as change and we need a way of working out when it has updated,
        // so set this to false if it's not been set previously as a way of determining init.
        if (this.get('scaleSelectRendered') === undefined) {
          this.set("scaleSelectRendered", false);
        }

        this.$('#customScalesSelect').selectize({
          plugins: ['remove_button'],
          delimiter: '',
          render: {
            item: function item(data) {
              var name = data.value;
              var scales = view.get('allScales');
              var symptom = scales.find(function (s) {
                return s.get('mnemonic') === name;
              });
              var color = colorSettings.colorFor(symptom);
              var text = symptom.get('translatedShortname');

              // TODO: Need to add the name as well.
              // if (colorSettings.get('colorMode') === 'perScaleAndUser') {

              // }
              return '<div style="background:' + color + '">' + text + '</div>';
            }
          },
          onChange: $.proxy(this.updateActiveScales, this),
          maxItems: null,
          persist: false
        });
        if (this.$('#customScalesSelect')[0].selectize.items.length !== this.get('activeScales').length) {
          this.syncScaleSelect();
        }
      }
    },


    graphOptions: Ember.computed('isPercentileGraph', 'showYAxis', function () {
      return {
        isPercentileGraph: this.get('isPercentileGraph'),
        showYAxis: this.get('showYAxis'),
        maxValue: this.get('maxValue'),
        minValue: this.get('minValue')
      };
    }),

    minValue: Ember.computed('allScales.displayData', 'domains.[]', 'displayMode', function () {
      var _this2 = this;

      if (this.get('domains.firstObject') == 'normed' && this.get('displayMode') === 'ptpb') {
        return -3;
      }

      var byScale = this.get("allScales").flatMap(function (scale) {
        return _this2.get("domains").map(function (domain) {
          var displayData = scale.get('displayData');

          if (displayData && displayData[domain] && typeof displayData[domain]['minValue'] === 'number') {
            return displayData[domain]['minValue'];
          }
        }).compact();
      });

      if (byScale.length > 0) {
        return _lodash.default.min(byScale);
      }

      return -4;
    }),

    maxValue: Ember.computed('allScales.displayData', 'domains.[]', 'displayMode', function () {
      var _this3 = this;

      if (this.get('domains.firstObject') == 'normed' && this.get('displayMode') === 'ptpb') {
        return 4;
      }

      var byScale = this.get("allScales").flatMap(function (scale) {
        return _this3.get("domains").map(function (domain) {
          var displayData = scale.get('displayData');

          if (displayData && displayData[domain] && displayData[domain]['maxValue']) {
            return displayData[domain]['maxValue'];
          }
        }).compact();
      });

      if (byScale.length > 0) {
        return _lodash.default.max(byScale);
      }

      return 6;
    }),

    isPercentileGraph: Ember.computed("forceDisplayMode", "allScales.displayData", "domains.[]", function () {
      var _this4 = this;

      // Old school NORSE and PTPB have overrides
      if (this.get('forceDisplayMode')) {
        var norseOrPtpb = this.get("allScales").some(function (scale) {
          return scale.get('measureType') === 'norse' || scale.get('measureType') === 'ptpb';
        });

        if (norseOrPtpb) {
          return true;
        }
      }

      return this.get("allScales").every(function (scale) {
        return _this4.get("domains").every(function (domain) {
          var displayData = scale.get('displayData');

          return displayData && displayData[domain] && displayData[domain]['usePercentileGraph'] === true;
        });
      });
    }),

    activeComputedValue: Ember.computed("selectedValue", "currentPopulation", function () {
      var selectedPopulation = this.get("selectedValue.population.id");
      var currentPopulation = this.get("currentPopulation.id");

      if (selectedPopulation === currentPopulation) {
        return this.get("selectedValue");
      } else {
        return null;
      }
    }),

    selectedReportObserver: Ember.observer("activeReport", function () {
      this.set("selectedReport", null);
      this.set("selectedScale", null);
    }),

    //we wrap the scales passed in as 'allScales' because we need to map colors and values onto them for selection
    //DO NOT DIRECTLY SET THIS FROM A CALLER
    allScales: Ember.computed.alias('scales'),

    colorSettings: Ember.computed('scales.[]', 'reports', function () {
      var scaleIds = this.get('scales').mapBy('id');
      var cvs = this.getWithDefault("reports", []).flatMap(function (r) {
        return r.get('computedValues').filter(function (cv) {
          return scaleIds.includes(cv.get('scale.id'));
        });
      });

      return _colorSettings.default.create({ computedValues: cvs, reportSettings: this.get('reportSettings') });
    }),

    isNorseScales: Ember.computed("allScales", function () {
      return this.get("allScales").any(function (s) {
        return s.get('measureType') === 'norse';
      });
    }),

    showScaleSelectors: Ember.computed("isNorseScales", function () {
      return this.get('allScales.length') > 1;
    }),

    changedScales: Ember.computed("allScales", "reports", "reportComparison", function () {
      var significantComps = this.getWithDefault("reportComparison.normedComparisons", []).filter(function (nc) {
        return nc.get('latestValue').isSignificantChangeFrom(nc.get('comparisonValue'));
      });
      return significantComps.mapBy('scale');
    }),

    recentChanges: Ember.computed("reports", function () {
      var reports = this.getWithDefault("reports", []);
      return reports.map(function (r) {
        return { events: r.getWithDefault("recentChangesList", []), date: r.get("patientSession.targetDate") };
      });
    }),

    currentPopulation: Ember.computed.oneWay('reportSettings.currentPopulation'),

    filterItems: Ember.computed.gt('hiddenItems.length', 0),

    filteredComputedValuesByReport: Ember.computed("activeScales", "activeScales.[]", "reports", "currentPopulation", 'hiddenItems.[]', function () {
      this.get('allScales'); //call to make sure the scales all have colors assigned
      var activeScaleIds = this.get("activeScales").map(function (s) {
        return s.get("id");
      });

      var reports = this.get("reports");

      if (Ember.isEmpty(reports)) {
        return [];
      }

      var currentPopulation = this.get("currentPopulation");
      var popId = currentPopulation ? currentPopulation.get("id") : null;
      var firstDomain = this.get('domains').objectAt(0);

      if (!popId && this.get('displayMode') === 'ptpb' && firstDomain === 'normed') {
        //for now PTPB defaults to outpatient norms (the only ones that exist)
        popId = this.get('preload.populations').findBy('mnemonic', 'intreatment').get('id');
      }

      var firstScaleId = activeScaleIds.objectAt(0);
      var isNormedDisplay = this.get("domains").objectAt(0) === 'normed';

      // If you are using normed data, you must have an active population to norm against.
      if (isNormedDisplay && !popId) {
        return [];
      }

      var filterItems = this.get('filterItems');
      var hiddenItems = this.get('hiddenItems');

      var hiddenItemFilter = function hiddenItemFilter(cv) {
        if (filterItems) {
          return hiddenItems.includes(cv.get('scaleUserPair'));
        } else {
          return false;
        }
      };

      if (this.get('domains.length') === 2) {
        //if two domains are given, we're looking to use the first domain for the first scale, and the second domain for subsequent scales

        var otherScaleIds = activeScaleIds.slice(1);
        var secondDomain = this.get('domains').objectAt(1);

        return reports.map(function (report) {
          return report.get("computedValues").filter(function (cv) {
            return cv.get('domain') === firstDomain && (!isNormedDisplay || popId === cv.get("population.id")) && firstScaleId === cv.get("scale.id") || cv.get('domain') === secondDomain && (!isNormedDisplay || popId === cv.get("population.id")) && otherScaleIds.includes(cv.get("scale.id"));
          }).reject(hiddenItemFilter);
        });
      } else {
        return reports.map(function (report) {
          return report.get("computedValues").filter(function (cv) {
            return cv.get('domain') === firstDomain && (!isNormedDisplay || popId === cv.get("population.id")) && activeScaleIds.includes(cv.get("scale.id"));
          }).reject(hiddenItemFilter);
        });
      }
    }),

    reportData: Ember.computed("filteredComputedValuesByReport.[]", function () {
      var activeScaleIds = this.get("activeScales").map(function (s) {
        return s.get("id");
      });
      var firstScaleId = activeScaleIds.objectAt(0);
      var filtered = this.get("filteredComputedValuesByReport");

      var graphData = _lodash.default.values(_lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.id") + f.get("user.id");
      }));
      return _lodash.default.sortBy(graphData, function (g) {
        return g[0].get('scale.id') !== firstScaleId;
      });
    }),

    showUserLegend: Ember.computed('reportData', function () {
      return Object.keys(_lodash.default.groupBy(_lodash.default.flatten(this.get('reportData')), function (f) {
        return f.get('user.id');
      })).length > 1;
    }),

    //updates the active scales based on a change to the multiselect
    updateActiveScales: function updateActiveScales() {
      if (this.get("scaleSelectRendered")) {
        // Update gets called on init, which we do not want to trigger a metrics event for.
        this.get("metrics").trackEvent({ event: 'provider.report.scaleTrendChanged' });
      } else {
        this.set("scaleSelectRendered", true);
      }

      var selectedOpts = this.$('#customScalesSelect')[0].selectize.items;
      var activeScales = this.get('activeScales');
      activeScales.clear();
      _lodash.default.forEach(this.get('allScales'), function (scale) {
        if (selectedOpts.indexOf(scale.get('mnemonic')) >= 0) {
          activeScales.pushObject(scale);
        }
      });
    },

    //updates the scales shown as selected
    syncScaleSelect: function syncScaleSelect() {
      var scaleSelect = this.$('#customScalesSelect')[0].selectize;
      scaleSelect.clear(true);
      scaleSelect.addItems(this.get('activeScales').map(function (scale) {
        return scale.get('mnemonic');
      }), true);
      scaleSelect.refreshItems();
    },

    actions: {
      computedValueSelected: function computedValueSelected(value) {
        this.set("selectedValue", value);
        this.set("selectedScale", value ? value.get("scale") : null);
        this.set("selectedReport", value ? value.get("report") : null);

        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      },


      toggleChangedScales: function toggleChangedScales() {
        var changedScales = this.get('changedScales');
        var changedScaleMnemonics = changedScales.mapBy('mnemonic');
        this.get('activeScales').removeObjects(_lodash.default.filter(this.get('activeScales'), function (scale) {
          return changedScaleMnemonics.indexOf(scale.get('mnemonic')) >= 0;
        }));
        //hack because checkbox binding didn't work?
        this.set('changedScalesShow', !this.get('changedScalesShow'));
        if (this.get('changedScalesShow')) {
          this.get('activeScales').pushObjects(changedScales);
        }
        this.syncScaleSelect();
      },

      toggleTargetedScales: function toggleTargetedScales() {
        var targetedScaleMnemonics = this.getWithDefault('targetedScales', []).mapBy('mnemonic');
        this.get('activeScales').removeObjects(_lodash.default.filter(this.get('activeScales'), function (scale) {
          return targetedScaleMnemonics.indexOf(scale.get('mnemonic')) >= 0;
        }));
        //hack because checkbox binding didn't work?
        this.set('targetedScalesShow', !this.get('targetedScalesShow'));
        if (this.get('targetedScalesShow')) {
          this.get('activeScales').pushObjects(this.get('targetedScales'));
        }
        this.syncScaleSelect();
      }
    }
  });
});