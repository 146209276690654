define('frontend/pods/components/provider/assessment/report-v2/symptoms-overview/component', ['exports', 'lodash', 'frontend/mixins/drawer-enabled'], function (exports, _lodash, _drawerEnabled) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawerEnabled.default, {
    classNames: ['symptoms-overview'],
    skillMnemonics: ['attachment', 'connectedness', 'resilience', 'socialRole'],
    symptomMnemonics: ['emotionalDistance', 'suicideRisk', 'avoidance', 'psychosis', 'relationalDistress', 'perfectionism', 'eating', 'somaticAnxiety', 'hurtfulRumination', 'negativeAffect', 'demoralization', 'hypervigilance', 'substanceUse'],
    patientScaleTargets: Ember.computed.alias('patient.assessmentPatientScaleTargets'),
    displayMode: 'norse',
    screen: Ember.inject.service(),

    showSkills: Ember.computed.notEmpty("skillMnemonics"),
    showSymptoms: Ember.computed.notEmpty("symptomMnemonics"),

    scalesByMnemonic: Ember.computed("scales", function () {
      var result = {};
      this.getWithDefault("scales", []).forEach(function (scale) {
        // This needs to support the old style base mnemonics
        result[scale.get("baseMnemonic")] = scale;
      });
      return result;
    }),

    offeredAndOpenedMnemonics: Ember.computed('activeReport.allOfferedScales', 'scalesByMnemonic', function () {
      // These are the scales which are guaranteed to be offered based on past answers.
      var mnemonics = this.getWithDefault('activeReport.allOfferedScales', []);
      if (!mnemonics) {
        mnemonics = [];
      }

      // These are scales which triggered due to a screener question
      mnemonics.pushObjects(this.getWithDefault('activeReport.computedValues', []).filter(function (cv) {
        return cv.get('metScreenThreshold') === true && mnemonics.indexOf(cv.get('scale.mnemonic')) < 0;
      }).mapBy('scale.mnemonic'));
      return mnemonics.uniq();
    }),

    skills: Ember.computed("scalesByMnemonic", 'skillMnemonics', function () {
      var byMnemonic = this.get("scalesByMnemonic");
      return (0, _lodash.default)(this.get("skillMnemonics").map(function (mnemonic) {
        return byMnemonic[mnemonic];
      })).compact().value();
    }),

    symptoms: Ember.computed("scalesByMnemonic", 'symptomMnemonics', function () {
      var byMnemonic = this.get("scalesByMnemonic");
      return (0, _lodash.default)(this.get("symptomMnemonics").map(function (mnemonic) {
        return byMnemonic[mnemonic];
      })).compact().value();
    }),

    allReportData: Ember.computed("reports", "reportSettings.currentPopulation", "patientScaleTargets.[]", "reportSettings.compareReportTo", function () {
      var reports = this.get("reports");
      var currentPopulation = this.get("reportSettings.currentPopulation");
      var popId = currentPopulation ? currentPopulation.get("id") : null;
      var component = this;
      if (Ember.isEmpty(reports) || !popId) {
        return [];
      }

      var filtered = reports.map(function (report) {
        return report.get("computedValues").filter(function (cv) {
          return cv.get('domain') === 'normed' && popId === cv.get("population.id");
        });
      });

      var groups = _lodash.default.groupBy(_lodash.default.flatten(filtered), function (f) {
        return f.get("scale.mnemonic");
      });

      var groupWithScale = {};

      this.getWithDefault("scales", []).forEach(function (scale) {
        groupWithScale[scale.get("mnemonic")] = {
          items: groups[scale.get("mnemonic")] || [],
          scale: scale,
          isTargeted: component.get('patientScaleTargets').any(function (pst) {
            return pst.get('scale.id') === scale.get('id');
          })
        };
      });

      return groupWithScale;
    }),

    symptomData: Ember.computed("symptoms", "allReportData", function () {
      var allScaleData = this.getWithDefault("allReportData", {});
      return this.get("symptoms").map(function (scale) {
        return allScaleData[scale.get("mnemonic")];
      });
    }),

    skillData: Ember.computed("skills", "allReportData", function () {
      var allScaleData = this.getWithDefault("allReportData", []);

      return this.get("skills").map(function (scale) {
        return allScaleData[scale.get("mnemonic")];
      });
    }),

    actions: {
      computedValueSelected: function computedValueSelected(value, onDeselected) {
        this.sendAction('onValueSelect', value);

        if (this.get("onDeselected")) {
          this.get("onDeselected")();
        }
        this.set("onDeselected", onDeselected);

        if (this.get("screen.isCompressed")) {
          this.actions.drawer.call(this);
        }
      }
    }

  });
});