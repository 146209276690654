define('frontend/pods/components/survey/response/edit-form/component', ['exports', 'frontend/mixins/rollback-mixin', 'frontend/mixins/dirty-checker', 'frontend/mixins/error-printer', 'frontend/utils/survey/survey-hooks', 'frontend/utils/assessment/rule-evaluator'], function (exports, _rollbackMixin, _dirtyChecker, _errorPrinter, _surveyHooks, _ruleEvaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EDITABLE_DOMAINS = ['assessment', 'modules'];

  // Some old style module make changes to other modules, e.g. cams screeners. To fix this,
  // iterate through these special cases here.
  var MULTI_MODULE_MAP = {
    camsScreeners: ['camsMain']
  };

  exports.default = Ember.Component.extend(_rollbackMixin.default, _errorPrinter.default, _dirtyChecker.default, {
    messages: Ember.inject.service(),
    intl: Ember.inject.service(),
    rollbackProperties: ['response', 'response.invite', 'response.responseItems'],

    unsavedChanges: false,

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var response = this.get("response");

      if (response) {
        response.runAllInitHooks();
        response.runAllAfterHooks();
        response.updateResponseItemMap();
        response.updateAllDisplayLogic();
      } else {
        console.warn("No response for edit form");
      }
    },


    // We can't allow editing of any scales that have been blinded.
    editableModules: Ember.computed("response.allModules", 'invitation.reportScales.[]', function () {
      var blinded = this.get('invitation.reportScales').filterBy('blinded').mapBy('scale.surveyMnemonics').flatten();

      // If the user is a provider, don't filter the blinded scales as they still need to be able to take them. 
      if (this.get('invitation.user.role') === 'provider') {
        blinded = [];
      }

      return this.getWithDefault("response.allModules", []).filter(function (module) {
        return blinded.indexOf(module.mnemonic) < 0 && EDITABLE_DOMAINS.indexOf(module.domain) >= 0;
      });
    }),

    conditionalModulesPresent: Ember.computed('editableModules.[]', 'response.invite.paramData', function () {
      var paramData = this.get("response.invite.paramData");
      return this.get('editableModules').any(function (module) {
        return paramData[module.mnemonic] && paramData[module.mnemonic].conditional_logic;
      });
    }),

    viewableModules: Ember.computed('editableModules.[]', 'response.invite.paramData', function () {
      var _this = this;

      var paramData = this.get("response.invite.paramData");
      return this.get('editableModules').filter(function (module) {
        if (paramData[module.mnemonic] && paramData[module.mnemonic].conditional_logic) {
          var context = {
            response: _this.get('response'),
            module: module
          };

          return _ruleEvaluator.default.evaluateRule(paramData[module.mnemonic].conditional_logic, context);
        } else {
          // Always display
          return true;
        }
      });
    }),

    actions: {
      onChange: function onChange(module) {
        var _this2 = this;

        this._markAsDirty();
        var afterHook = Ember.get(module, 'content.afterModuleHook');
        if (afterHook) {
          _surveyHooks.default.runHook(afterHook, this.get("response"), this.get('response.invite.paramData'));
        }
        module.updateAllDisplayLogic();

        var otherModules = MULTI_MODULE_MAP[module.get("mnemonic")];

        if (otherModules) {
          otherModules.forEach(function (otherModuleMnemonic) {
            var otherModule = _this2.get("editableModules").findBy('mnemonic', otherModuleMnemonic);
            otherModule.updateAllDisplayLogic();
          });
        }

        if (this.get("conditionalModulesPresent")) {
          this.notifyPropertyChange('viewableModules');
        }
      },
      discardChanges: function discardChanges() {
        this._discardChanges();
        var response = this.get('response');
        response.updateResponseItemMap();
        response.updateAllDisplayLogic();
      },
      saveChanges: function saveChanges() {
        return this._saveChanges();
      },
      goToReport: function goToReport() {
        this._goToReport();
      },
      returnToLastPage: function returnToLastPage() {
        this._returnToLastPage();
      },
      saveChangesAndViewReport: function saveChangesAndViewReport() {
        var _this3 = this;

        return this._saveChanges().then(function () {
          return _this3._goToReport();
        });
      },
      saveChangesAndReturn: function saveChangesAndReturn() {
        var _this4 = this;

        return this._saveChanges().then(function () {
          return _this4._returnToLastPage();
        });
      },
      changeLanguage: function changeLanguage(locale) {
        this.set('response.currentLanguage', locale);
      }
    },
    _goToReport: function _goToReport() {
      var patientId = this.get("invitation.patient.id");
      var invitationId = this.get("invitation.id");

      this.get("invitation.report.computedValues").map(function (cv) {
        cv.forceUnloadHasManyRelationship('answers');
      });

      return this.get("router").transitionTo("provider.patients.show.longitudinal", patientId, { queryParams: { activeInvitationId: invitationId } });
    },
    _returnToLastPage: function _returnToLastPage() {
      return this.get("router").transitionTo(this.get('returnUrl'));
    },
    _saveChanges: function _saveChanges() {
      var _this5 = this;

      var response = this.get("response");
      var request = this.store.createRecord('survey/bulkSurveyEdit', {
        response: response
        // Note that the main package is generated in the serializer.
      });

      var onSuccess = function onSuccess() {
        _this5.set("isSaving", false);
        _this5.get('messages').success("Successfully saved");
        _this5._dirtyCheckerSaveSuccess();
        response.updateResponseItemMap();
      };

      var onFailure = function onFailure(error) {
        _this5.set("isSaving", false);
        _this5.get('messages').danger(_this5.printError(error));
      };

      this.set("isSaving", true);

      return request.save().then(onSuccess, onFailure);
    }
  });
});