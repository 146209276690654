define('frontend/helpers/always-never-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var value = params[0].get('value');
    if (value === "1") {
      return "Rarely/Never";
    } else if (value === "3") {
      return "Sometimes";
    } else if (value === "5") {
      return "Often";
    } else if (value === "7") {
      return "Always";
    } else {
      return "~no answer~";
    }
  });
});