define('frontend/pods/provider/assessment/scales/show/preview/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    preload: Ember.inject.service(),
    queryParams: {
      previewMode: {}
    },
    model: function model() {
      var scale = this.modelFor('provider.assessment.scales.show');

      if (scale.get('mnemonic') === 'ptpb') {
        return Ember.RSVP.hash({
          ptpb: true,
          scale: scale,
          ptpbScales: this.get('preload.scales').filterBy('measureType', 'ptpb').filterBy('independent', true).rejectBy('mnemonic', 'ptpb')
        });
      }

      var subtreeMnemonics = scale.get("subtree").mapBy('surveyMnemonics').compact().flatten().uniq();

      var modules = this.store.query('survey/module', { filter: { mnemonic: subtreeMnemonics } });

      return Ember.RSVP.hash({
        scale: scale,
        modules: modules
      });
    }
  });
});