define('frontend/models/payor', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: _emberData.default.attr("string"),
    primaryId: _emberData.default.attr("string"),
    identifiers: _emberData.default.attr(),
    alternateNames: _emberData.default.attr(),
    model: _emberData.default.attr('string')
  });
});