define('frontend/pods/internal/institutes/show/scales/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var institute = this.modelFor("internal.institutes.show");

      var allInternalScales = this.get('store').query('assessment/internalScale', { page: { limit: 500 }, filter: { independent: true } });
      return Ember.RSVP.hash({
        institute: institute,
        instituteScales: institute.get('instituteScales'),
        allInternalScales: allInternalScales
      });
    }
  });
});