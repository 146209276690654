define('frontend/pods/components/provider/assessment/dashboard/dashboard-main/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dashboard', 'page'],
    sessionUser: Ember.inject.service(),
    preload: Ember.inject.service(),

    tabCounts: {
      recentlyCompleted: undefined,
      upcoming: undefined,
      pendingTasks: undefined,
      failedLogins: undefined
    },

    // Ember does not deal well with anchors etc here and it clashes with the standard browser hash/fragment, so
    // we just force it through with jquery.
    activeTabObserver: Ember.observer('activeTab', function () {
      this.$('.nav-tabs a[href="#' + this.get('activeTab') + '"]').tab('show');
    }),

    didRender: function didRender() {
      this.$('.nav-tabs a[href="#' + this.get('activeTab') + '"]').tab('show');
    },


    actions: {
      updateCount: function updateCount(countProperty, numRecords) {
        this.set('tabCounts.' + countProperty, numRecords);
      },
      gotoTab: function gotoTab(name) {
        this.set("activeTab", name);
      }
    }
  });
});