define('frontend/models/care/entity-measurement-plan', ['exports', 'frontend/models/application', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isCoveredBy = exports.PlanCollection = exports.Validations = undefined;


  var validationOptions = {
    entityTreeNode: (0, _emberCpValidations.validator)('presence', true),
    planType: (0, _emberCpValidations.validator)('presence', true),
    endDate: (0, _emberCpValidations.validator)('date', {
      after: Ember.computed.alias("model.startDate"),
      precision: 'day',
      allowBlank: true,
      format: 'MM/DD/YYYY',
      errorFormat: 'MM/DD/YYYY',
      dependentKeys: ['model.startDate']
    }),
    measurementPlan: { validators: [(0, _emberCpValidations.validator)('belongs-to', true), (0, _emberCpValidations.validator)('presence', true)] }
  };

  // Calculates whether one covers the other, i.e. there is no circumstance (assuming the active plan is
  // valid) that the obsolete plan can be administered
  var isCoveredBy = function isCoveredBy(active, obsolete) {
    // // Only core plans can be 'covered'
    if (active.get('planType') !== 'core' || obsolete.get('planType') !== 'core') {
      return false;
    }

    if (active.get('precedence') >= obsolete.get('precedence')) {
      return false;
    }

    var activeStartDate = active.get('startDate');
    var activeEndDate = active.get('endDate');
    var obsoleteStartDate = obsolete.get('startDate');
    var obsoleteEndDate = obsolete.get('endDate');

    if (activeStartDate) {
      if (!obsoleteStartDate || obsoleteStartDate && activeStartDate < obsoleteStartDate) {
        return false;
      }
    }

    if (activeEndDate) {
      if (!obsoleteEndDate || obsoleteEndDate && activeEndDate < obsoleteEndDate) {
        return false;
      }
      if (obsoleteStartDate && activeEndDate < obsoleteStartDate) {
        return false;
      }
    }

    // If the active plan is at the patient level, it must be fully covering.
    if (active.get('entityTreeNode.demographicsPresent')) {
      return true;
    }

    var activeMinAge = active.get('minAge');
    var activeMaxAge = active.get('maxAge');
    var activeGender = active.get('gender');
    var obsoleteMinAge = obsolete.get('minAge');
    var obsoleteMaxAge = obsolete.get('maxAge');
    var obsoleteGender = obsolete.get('gender');

    // If no demographics specified, it's covered.
    var hasNoDemographics = !activeMinAge && !activeMaxAge && !activeGender;

    if (hasNoDemographics) {
      return true;
    }

    // Else we have to check per category.
    if (activeMinAge && obsoleteMinAge && obsoleteMinAge < activeMinAge) {
      return false;
    }

    if (activeMaxAge && obsoleteMaxAge && obsoleteMaxAge > activeMaxAge) {
      return false;
    }

    if (activeGender && activeGender !== obsoleteGender) {
      return false;
    }

    // We made it through - therefore it must be covered
    return true;
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  var PlanCollection = Ember.Object.extend({
    measurementPlan: Ember.computed.alias('activeEntityPlan.measurementPlan'),
    precedence: Ember.computed.alias('activeEntityPlan.precedence'),

    isGrouped: true,

    // Mirror all the fields, so that you can use this or a plan equally in a table.
    id: Ember.computed.alias('activeEntityPlan.id'),
    minAge: Ember.computed.alias('activeEntityPlan.minAge'),
    maxAge: Ember.computed.alias('activeEntityPlan.maxAge'),
    gender: Ember.computed.alias('activeEntityPlan.gender'),
    entityTreeNode: Ember.computed.alias('activeEntityPlan.entityTreeNode'),
    isFuture: Ember.computed.alias('activeEntityPlan.isFuture'),
    startDate: Ember.computed.alias('activeEntityPlan.startDate'),
    endDate: Ember.computed.alias('activeEntityPlan.endDate'),
    active: Ember.computed.alias('activeEntityPlan.active'),
    planType: Ember.computed.alias('activeEntityPlan.planType'),

    needsReplanning: Ember.computed('entityMeasurementPlans.@each.needsReplanning', function () {
      return this.get('entityMeasurementPlans').any(function (emp) {
        return emp.get('needsReplanning');
      });
    }),

    activeEntityPlan: Ember.computed('entityMeasurementPlans.[]', function () {
      return this.get('entityMeasurementPlans').sortBy('precedence').get('firstObject');
    }),

    supersededPlans: Ember.computed('entityMeasurementPlans.[]', function () {
      return this.get('entityMeasurementPlans').sortBy('precedence').slice(1);
    })
  });

  exports.Validations = Validations;
  exports.PlanCollection = PlanCollection;
  exports.isCoveredBy = isCoveredBy;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    intl: Ember.inject.service(),
    measurementPlan: _emberData.default.belongsTo('care/measurementPlan'),

    entityTreeNode: _emberData.default.belongsTo('entityTreeNode'),

    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    planType: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean', { defaultValue: true }),
    minAge: _emberData.default.attr('number'),
    maxAge: _emberData.default.attr('number'),
    gender: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number'),
    specificity: _emberData.default.attr('number'),
    precedence: _emberData.default.attr('number'),

    needsReplanning: _emberData.default.attr('boolean', { defaultValue: false }),

    isFuture: Ember.computed('startDate', function () {
      return this.get('startDate') && this.get('startDate') > new Date();
    }),

    dateSummary: Ember.computed('startDate', 'endDate', function () {
      var intl = this.get('intl');

      var _getProperties = this.getProperties('startDate', 'endDate'),
          startDate = _getProperties.startDate,
          endDate = _getProperties.endDate;
      // TODO: intl dates.

      if (startDate && endDate) {
        return intl.t('model.entityMeasurementPlan.dateSummary.both', { startDate: moment(startDate).format("MM/DD/YYYY"), endDate: moment(endDate).format("MM/DD/YYYY") });
      } else if (startDate) {
        return intl.t('model.entityMeasurementPlan.dateSummary.starting', { startDate: moment(startDate).format("MM/DD/YYYY") });
      } else if (endDate) {
        return intl.t('model.entityMeasurementPlan.dateSummary.ending', { endDate: moment(endDate).format("MM/DD/YYYY") });
      } else {
        return intl.t('model.entityMeasurementPlan.dateSummary.neither');
      }
    }),

    demographicsSummary: Ember.computed('entityTreeNode.demographicsPresent', 'minAge', 'maxAge', 'gender', function () {
      var intl = this.get('intl');
      if (this.get('entityTreeNode.demographicsPresent')) {
        return null;
      }

      var _getProperties2 = this.getProperties('minAge', 'maxAge', 'gender'),
          minAge = _getProperties2.minAge,
          maxAge = _getProperties2.maxAge,
          gender = _getProperties2.gender;

      var ageSummary = null;

      if (minAge && maxAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.minMax', { minAge: minAge, maxAge: maxAge });
      } else if (maxAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.max', { maxAge: maxAge });
      } else if (minAge) {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.min', { minAge: minAge });
      } else {
        ageSummary = intl.t('model.entityMeasurementPlan.ageSummary.all');
      }

      var genderSummary = gender ? intl.t('model.gender.' + gender) : null;

      return [ageSummary, genderSummary].compact().join(", ");
    })
  });
});