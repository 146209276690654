define('frontend/pods/components/internal/institutes/institute-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var intl = this.get('intl');

      var tableColumns = [{
        "propertyName": "shortcode",
        "title": intl.t('model.institute.shortcode')
      }, {
        "propertyName": "name",
        "title": intl.t('model.institute.name')
      }, {
        "propertyName": "isTest",
        "title": intl.t('model.institute.isTest')
      }, {
        "title": intl.t('common.actions'),
        "template": "components/internal/institutes/institute-table/action-buttons"
      }];

      this.set("tableColumns", tableColumns);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get("data")) {
        this.set("data", this.get("store").query("institute", { sort: "shortcode" }));
      }
    }
  });
});