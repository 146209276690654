define('frontend/pods/components/provider/assessment/report-v2/standard-measures/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var groupDisplayComponentMap = {
    interpretedWithSubScales: 'provider/assessment/report-v2/interpreted-scale-group',
    graphWithSubscales: 'provider/assessment/report-v2/graph-scale-group',
    combinedGraph: 'provider/assessment/report-v2/combined-graph',
    vanderbilt3: 'provider/assessment/report-v2/vanderbilt-user',
    UCLAPTSD: 'provider/assessment/report-v2/ucla-ptsd',
    UCLAPTSDReactionIndexchildadolescenttraumahistory: 'provider/assessment/report-v2/ucla-ptsd-trauma-history'
  };

  var rolesToHide = ['intermediate', 'alert'];

  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    classNames: ["standard-measures"],
    compressAll: true,

    // TODO: This is a hack to get genoa scales in a specfic order.
    orderOveride: Ember.computed("institute.current", function () {
      if (this.get("institute.current.shortcode").includes("genoa")) {
        return ["phq9", "gad7", 'waisr4', "auditcplus2", "auditcplus2Audit", "auditcplus2Cannabis", "auditcplus2Drugs", 'dast10', "cssrsSelfReportLastContactRisk"].reduce(function (map, obj, i) {
          map[obj] = i + 1;
          return map;
        }, {});
      }
      return {};
    }),

    //return norse and standard measures in a single collection
    orderedElements: Ember.computed('standardScalesToShow.[]', 'scaleGroups.[]', 'institute', function () {
      return this.get("standardScalesToShow").concat(this.get('scaleGroups')).concat(this.get('orphanedScales')).sortBy('priority');
    }),

    standardScalesToShow: Ember.computed("scales.[]", 'scaleGroups.[]', function () {
      var _this = this;

      return this.getWithDefault('scales', []).filter(function (s) {
        return !s.get("displayData.group") && !rolesToHide.includes(s.get('role'));
      }).map(function (el) {
        var priority = _this.get('orderOveride')[el.get('mnemonic').split('?')[0]] || el.getWithDefault('displayData.order', el.get('mnemonic'));
        return {
          type: 'scale',
          key: el.get("mnemonic"),
          scale: el,
          priority: priority
        };
      });
    }),

    // Assume that exactly one of the scales has instructions on how to display the group.
    scaleGroups: Ember.computed("scales", function () {
      var filtered = this.getWithDefault('scales', []).filter(function (s) {
        return s.get("displayData.group") && !rolesToHide.includes(s.get('role'));
      });

      var groups = _lodash.default.groupBy(_lodash.default.flatten(filtered), function (s) {
        return s.get("displayData.group");
      });

      return Object.values(groups).flatMap(function (group) {
        var displayData = group.mapBy('displayData.groupDisplayData').find(_lodash.default.identity);
        var groupName = group[0].get("displayData.group");

        if (!displayData) {
          return [];
        }

        var priority = displayData.order || 99999;
        return [{
          type: 'group',
          key: groupName,
          component: groupDisplayComponentMap[displayData.type],
          displayData: displayData,
          scales: group,
          priority: priority
        }];
      });
    }),

    // We have scale groups that can now sometimes have subscales administered separately. In this case, we can't find
    // the display data in the scaleGroups above, and so they don't get displayed.
    // Here, if we find scale groups without a lead scale, meaning we don't know how to display them, just put
    // them all on the page as normal items, called 'orphan' scales.
    // Note that in the new clinical report this is handled completely differently, and instead uses scale groups
    // explicitly as a concept to group all the scales together.
    orphanedScales: Ember.computed("scales", function () {
      var filtered = this.getWithDefault('scales', []).filter(function (s) {
        return s.get("displayData.group") && !rolesToHide.includes(s.get('role'));
      });

      var groups = _lodash.default.groupBy(_lodash.default.flatten(filtered), function (s) {
        return s.get("displayData.group");
      });

      return Object.values(groups).flatMap(function (group) {
        var displayData = group.mapBy('displayData.groupDisplayData').find(_lodash.default.identity);

        // If we actually do have display info, we don't need to put it here.
        if (displayData) {
          return [];
        }

        return group.map(function (subscale) {
          return {
            type: 'scale',
            key: subscale.get("mnemonic"),
            scale: subscale,
            priority: 99999
          };
        });
      });
    }),

    noData: Ember.computed('scaleGroups.[]', 'standardScalesToShow', function () {
      return Ember.isEmpty(this.get("scaleGroups")) && Ember.isEmpty(this.get("standardScalesToShow"));
    }),

    dateRange: Ember.computed('reports.[]', function () {
      var minDate = moment(this.get('reports.firstObject.patientSession.targetDate')).subtract(5, 'minutes').toDate();
      var maxDate = moment(this.get('reports.lastObject.patientSession.targetDate')).add(5, 'minutes').toDate();
      return {
        minDate: minDate,
        maxDate: maxDate
      };
    })
  });
});