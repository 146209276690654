define('frontend/pods/components/provider/assessment/report-v2/full-answer-detail/scale-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    abnormalScales: Ember.computed('scaleValues', function () {
      return this.getWithDefault('scaleValues', []).filterBy('isAbnormal');
    }),

    normalScales: Ember.computed('scaleValues', function () {
      return this.getWithDefault('scaleValues', []).filterBy('isNormal');
    }),

    unclassifiedScales: Ember.computed('scaleValues', function () {
      var excludedScales = ['cansasMetNeed', 'cansasUnmetNeed'];
      return this.getWithDefault('scaleValues', []).filter(function (cv) {
        return cv.get('isUnclassified') && excludedScales.indexOf(cv.get('scale.mnemonic')) === -1;
      });
    })
  });
});