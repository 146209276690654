define('frontend/pods/components/provider/conditions/condition-badge/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    actions: {
      expand: function expand() {
        this.set("showDetails", true);
      },
      hideModal: function hideModal() {
        this.set("showDetails", false);
      }
    }
  });
});