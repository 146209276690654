define('frontend/pods/components/provider/flag-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filter: Ember.inject.service('provider/filter'),
    name: 'flags', // Used by common FilterComponent stuff
    flagsSelected: Ember.computed.gt('selectedFlags.length', 0),
    actions: {
      showSelect: function showSelect() {
        var _this = this;

        var handleClick = function handleClick(event) {
          if (!$(event.target).closest('.ember-tether').length) {
            _this.send('hideSelect');
          }
        };

        var registerClick = function registerClick() {
          return $(document).on('click.patient-' + _this.get("name") + '-select-' + Ember.guidFor(_this), handleClick);
        };

        // setTimeout needed or else the click handler will catch the click that called this action
        setTimeout(registerClick);
        this.set('selectActive', true);
      },
      hideSelect: function hideSelect() {
        $(document).off('click.patient-' + this.get("name") + '-select-' + Ember.guidFor(this));
        this.set('selectActive', false);
      },
      toggleSelect: function toggleSelect() {
        if (this.get('selectActive')) {
          this.send('hideSelect');
        } else {
          this.send('showSelect');
        }
      },
      toggleFlag: function toggleFlag(flag) {
        this._toggleFlag(flag);
        this._updateFilter();
      }
    },

    _toggleFlag: function _toggleFlag(flag) {
      var selected = this.get("selectedFlags");
      var existing = selected.findBy('id', flag.get("id"));

      if (existing) {
        selected.removeObject(existing);
      } else {
        selected.addObject(flag);
      }
    },
    _updateFilter: function _updateFilter() {
      var flagIds = this.get("selectedFlags").mapBy('id');
      this.get('filter').set(this.filterKey('patientFlagIds'), flagIds);

      this.propertyDidChange("filter.filterDidChange");
      this.send('hideSelect');
    },
    willDestroyElement: function willDestroyElement() {
      this.send('hideSelect');
    },
    filterKey: function filterKey(key) {
      if (this.get('filterNamespace')) {
        return this.get('filterNamespace') + '_' + key;
      } else {
        return key;
      }
    },
    getFilterValue: function getFilterValue(key) {
      return this.get('filter.' + this.filterKey(key));
    },


    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // We can't set this as a computed property because it relies on a dynamic dependency
      var selectedIds = this.get('filter').get(this.filterKey('patientFlagIds'));

      var selectedFlags = this.getWithDefault("flags", []).filter(function (flag) {
        return selectedIds.indexOf(flag.get('id')) >= 0;
      });

      this.set("selectedFlags", selectedFlags);
    }
  });
});