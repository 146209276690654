define('frontend/pods/components/user/password-reset-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      validators: [(0, _emberCpValidations.validator)("length", {
        min: 8
      }), (0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)('format', {
        regex: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/,
        message: '{description} must include at least one upper case letter, one lower case letter, and a number'
      })]
    },
    passwordConfirmation: {
      validators: [(0, _emberCpValidations.validator)("confirmation", {
        on: "password"
      }), (0, _emberCpValidations.validator)("presence", true)]
    }
  });

  exports.default = Ember.Component.extend({
    messages: Ember.inject.service(),
    baseTextKey: "provider",
    onSuccessRoute: "provider.login",
    didReceiveAttrs: function didReceiveAttrs() {
      for (var _len = arguments.length, params = Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      this._super(params);

      this.set('model', Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        password: null,
        passwordConfirmation: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },

    actions: {
      submit: function submit() {
        var self = this;
        var data = { "user[reset_password_token]": self.get("token"), "user[password]": self.get("model.password"), "user[password_confirmation]": self.get("model.passwordConfirmation") };
        Ember.$.ajax({
          type: 'PUT',
          url: "/users/password",
          dataType: 'json',
          data: data
        }).then(function () /*data*/{
          self.get("messages").success("Your password has been reset");
          self.get('router').transitionTo(self.get("onSuccessRoute"));
        }, function (jqXHR) {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          if (jqXHR.status === 422) {
            if (jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.password) {
              self.get("messages").danger("Your password " + jqXHR.responseJSON.errors.password);
            } else {
              self.get("messages").danger("This token is not valid.");
            }
          } else {
            self.get("messages").danger("An unknown error occurred.");
          }
        });
      }
    }
  });
});