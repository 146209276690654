define('frontend/pods/components/common/stylized-checkbox/component', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    labelId: Ember.computed(function () {
      return (0, _uuidHelpers.default)();
    }),
    classNames: ['checkbox']
  });
});